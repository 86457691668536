import { FormGroup, Input } from '@uniquegood/realworld-studio-design';
import { Controller } from 'react-hook-form';
import { useMemo } from 'react';
import Uploader from '@/components/Uploader';
import { invalidFieldBoilerplate, ModalContentProps } from '@/utils';
import { MarginBottom24 } from '@/styles';

export interface FormData extends Pick<Chatbot, 'name'> {
  profilePicture: [UserFileResponseModel];
}

export type SubmitType = Pick<FormData, 'name'> & Pick<Chatbot, 'profilePictureId'>;

export default function ChatBotModalContent({ control, onSubmit }: ModalContentProps<FormData>) {
  return (
    <form onSubmit={onSubmit} id="modal">
      <Controller
        control={control}
        name="name"
        rules={{ required: true }}
        render={({ field, fieldState: { invalid } }) => (
          <FormGroup
            id="name"
            label="챗봇 이름"
            requiredIndicator
            errorText={invalidFieldBoilerplate(invalid)}
            cssStyle={MarginBottom24}
          >
            <Input {...field} />
          </FormGroup>
        )}
      />
      <Controller
        control={control}
        name="profilePicture"
        rules={{ required: true }}
        render={({ field: { value, onChange }, fieldState: { invalid } }) => {
          const memoedFiles = useMemo(() => value, [value?.[0]?.id]);
          return (
            <FormGroup
              id="profilePicture"
              label="프로필 이미지"
              requiredIndicator
              errorText={invalidFieldBoilerplate(invalid)}
            >
              <Uploader
                id="profilePicture"
                uploadedFiles={memoedFiles}
                getUploadFiles={onChange}
                ratio="square"
                // Hack: !value 로는 제대로 참조가 되지 않았음
                hasSearch={!value?.length}
                useFor="chatbot-profile"
              />
            </FormGroup>
          );
        }}
      />
    </form>
  );
}

export function transformToFormData(chatbot: Chatbot): FormData {
  const { name, profilePictureId, profilePictureUrl } = chatbot;

  return { name, profilePicture: [{ id: profilePictureId, url: profilePictureUrl }] };
}
