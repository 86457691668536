import { css } from '@emotion/react';

import React from 'react';

import {
  Navigation as OriginalNavigation,
  NavigationProps as OriginalNavigationProps,
  Button
} from '@uniquegood/realworld-studio-design';
import { onMobile } from '@/styles/responsive';
import { classNames, keyPressHandler } from '@/utils';

export interface NavigationProps extends OriginalNavigationProps {
  isShowMobileNavigation: boolean;
  onCloseNavigation: () => void;
}

export default function Navigation({
  isShowMobileNavigation,
  onCloseNavigation,
  ...rest
}: NavigationProps) {
  const className = classNames(isShowMobileNavigation && 'active');
  return (
    <>
      {isShowMobileNavigation && (
        <style>
          {`body {
          overflow: hidden;
          }`}
        </style>
      )}
      <div className={className} css={WrapperStyle}>
        <OriginalNavigation {...rest} />
        <Button
          type="plainWhite"
          icon="times_light"
          onClick={onCloseNavigation}
          cssStyle={CancelButtonStyle}
        />
      </div>
      <div
        css={isShowMobileNavigation ? BackdropStyle : []}
        aria-label="네비게이션 닫기"
        tabIndex={0}
        onClick={onCloseNavigation}
        role="button"
        onKeyPress={keyPressHandler(onCloseNavigation)}
      />
    </>
  );
}

export const NAVIGATION_DEFAULT_WIDTH = '233px';
export const NAVIGATION_GAME_EDIT_WIDTH = '66px';

const WrapperStyle = css`
  position: relative;

  ${onMobile} {
    width: 297px;
    left: -297px;

    z-index: 4;
    height: 100%;

    position: fixed;

    transition: transform 0.5s;

    top: 0;

    &.active {
      transform: translateX(297px);
    }
  }
`;

const CancelButtonStyle = css`
  display: none;

  ${onMobile} {
    position: absolute;
    right: 0;
    display: block;
    margin: 18px;
  }
`;

const BackdropStyle = css`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  z-index: 3;

  background-color: rgba(0, 0, 0, 0.25);
`;
