import { Global } from '@emotion/react';
import { AppProvider, Toaster } from '@uniquegood/realworld-studio-design';
import { LinkLikeComponent } from '@uniquegood/realworld-studio-design/dist/types/utils';
import { Ref } from 'react';
import { Link, Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import HomePage from '@pages/home';
import PaymentFail from '@pages/payment/Fail';
import PaymentSuccess from '@pages/payment/Success';
import SettingPage from '@pages/settings';
import globalStyle from '@styles/globalStyle';
import NavigationTopBar from './components/NavigationTopBar';
import RestrictRoute from './components/RestrictRoute';
import ChangeEmailVerify from './pages/ChangeEmailVerify';
import AgreeTermsPage from './pages/agreeTerms';
import Login from './pages/auth/Login';
import LoginCallback from './pages/auth/LoginCallback';
import VerifyEmail from './pages/auth/VerifyEmail';
import ChatBots from './pages/chatbots';
import Comments from './pages/comments';
import CreateAppPage from './pages/createApp';
import Dashboard from './pages/dashboard';
import DetailPage from './pages/detail';
import Files from './pages/files';
import Items from './pages/items';
import Landing from './pages/landing';
import Release from './pages/release';
import VerifyContactPage from './pages/verifyContact';
import TrackProvider from './providers/Track';

import MissionsPage from '@/pages/missions';
import ProjectsCreatePage from '@/pages/projectsCreate';
import ProjectsCreatePageV2 from '@/pages/projectsCreateV2';
import ThemePage from '@/pages/theme';

const customLink: LinkLikeComponent = ({ url, ref, ...rest }) => {
  if (!url) throw Error('url props 가 비어 있습니다.');
  // hack: Type 'LegacyRef<HTMLAnchorElement> | undefined' is not assignable to type 'Ref<HTMLAnchorElement> | undefined'.
  return <Link to={url} ref={ref as Ref<HTMLAnchorElement> | undefined} {...rest} />;
};

function App() {
  return (
    <>
      <Global styles={globalStyle} />
      <AppProvider linkComponent={customLink}>
        <Router>
          <TrackProvider>
            <Switch>
              <Route path="/" exact component={Landing} />
              <Route path="/auth/login" exact component={Login} />
              <Route path="/auth/loginCallback" exact component={LoginCallback} />
              <Route path="/auth/verifyEmail" exact component={VerifyEmail} />
              <Route path="/apps/:appId/changeEmailVerify" exact component={ChangeEmailVerify} />
              <RestrictRoute
                path={['/agreeTerms', '/verifyContact', '/verifyPass', '/createApp', '/apps']}
              >
                <NavigationTopBar>
                  <Route path="/apps/:appId/payment/success" exact component={PaymentSuccess} />
                  <Route path="/apps/:appId/payment/fail" exact component={PaymentFail} />

                  <Switch>
                    <Route path="/agreeTerms" exact component={AgreeTermsPage} />
                    <Route path="/verifyContact" exact component={VerifyContactPage} />

                    <Route path="/createApp" exact component={CreateAppPage} />
                    <Route path="/apps/:appId" exact component={HomePage} />
                    <Route
                      path="/apps/:appId/projects/create"
                      exact
                      component={ProjectsCreatePage}
                    />
                    <Route path="/apps/:appId/settings" exact component={SettingPage} />

                    {/* 게임구조개편용 Route Start */}
                    <Route
                      path="/apps/:appId/projectsV2/create"
                      exact
                      component={ProjectsCreatePageV2}
                    />
                    {/* 게임구조개편용 Route End */}

                    <Redirect
                      exact
                      from="/apps/:appId/projects/:projectId"
                      to="/apps/:appId/projects/:projectId/dashboard"
                    />
                    <Route
                      path="/apps/:appId/projects/:projectId/dashboard"
                      component={Dashboard}
                    />
                    <Route path="/apps/:appId/projects/:projectId/detail" component={DetailPage} />
                    <Route path="/apps/:appId/projects/:projectId/release" component={Release} />
                    <Route path="/apps/:appId/projects/:projectId/comments" component={Comments} />
                    <Route path="/apps/:appId/projects/:projectId/theme" component={ThemePage} />
                    <Route
                      path={[
                        '/apps/:appId/projects/:projectId/scenarios/:scenarioId/missions/:missionId/quests/:questId',
                        '/apps/:appId/projects/:projectId/scenarios/:scenarioId/missions/:missionId',
                        '/apps/:appId/projects/:projectId/scenarios/:scenarioId',
                        '/apps/:appId/projects/:projectId/scenarios'
                      ]}
                      component={MissionsPage}
                    />
                    <Route path="/apps/:appId/projects/:projectId/items" component={Items} />
                    <Route path="/apps/:appId/projects/:projectId/files" component={Files} />
                    <Route
                      path={[
                        '/apps/:appId/projects/:projectId/chatbots/:chatbotId/scenes/:sceneId',
                        '/apps/:appId/projects/:projectId/chatbots/:chatbotId',
                        '/apps/:appId/projects/:projectId/chatbots'
                      ]}
                      component={ChatBots}
                    />
                    <Route path="/apps/:appId/projects/:projectId/files" component={Files} />
                  </Switch>
                </NavigationTopBar>
              </RestrictRoute>
            </Switch>
          </TrackProvider>
          <Toaster />
        </Router>
      </AppProvider>
    </>
  );
}

export default App;
