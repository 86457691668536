import Uploader from '@components/Uploader';
import { css } from '@emotion/react';
import {
  Banner,
  Button,
  Card,
  FormGroup,
  Input,
  spacing,
  RadioButton,
  Typography,
  color,
  ButtonGroup,
  Modal,
  textStyleBody
} from '@uniquegood/realworld-studio-design';
import React, { useEffect, useMemo, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import { Controller, useForm } from 'react-hook-form';
import { ClickEventName, InputEventName, PageViewEventName, track, UploadEventName } from '@/track';
import { coreApi, studioApi } from '@/api';
import LoadingSpinner from '@/components/LoadingSpinner';
import { useModalState, useRequest, useRestrictProjectRoute } from '@/hooks';
import { CardItemGapWithOutHeader, MarginBottom4, MarginTop8 } from '@/styles';
import { container650Style } from '@/styles/containerStyles';
import { helpLink } from '@/utils';
import { centeredStyle } from '@/components/containers';
import { onMobile } from '@/styles/responsive';
import { STATION_URL } from '@/env';
import { authApiWithToken } from '@/apis/auth';

type FormData = Pick<Project, 'name' | 'description' | 'imageId'> & { templateId: string };

type TemplatesRequest = Pick<TemplateProject, 'id' | 'description' | 'name' | 'imageUrl'>[];

function ProjectsCreateV2() {
  useEffect(() => {
    track.onPageView({ pageViewEventName: PageViewEventName.view_createnewgame });
  }, []);

  const { appId } = useParams<AppParam>();
  const history = useHistory();

  const redirectTo = useRestrictProjectRoute();
  const { control, handleSubmit, watch, formState, setValue } = useForm<FormData>();

  const onSubmit = handleSubmit(async (data) => {
    const { data: project } = await coreApi.post<Project>(`/apps/${appId}/projects`, {
      ...data,
      templateId: data.templateId === templateEmptyState.id ? undefined : data.templateId,
      projectEngineVersion: 'V2'
    });
    track.onClick({
      clickEventName: ClickEventName.click_newgame_button_creategame,
      params: { templateId: data.templateId }
    });
    await authApiWithToken.apiAuthLoginPost();
    window.location.href = `https://${STATION_URL}/${appId}/game/${project.id}`;
  });

  if (redirectTo === undefined) return <LoadingSpinner />;

  if (typeof redirectTo === 'string') return <Redirect to={redirectTo} />;

  const [name, imageId] = watch(['name', 'imageId']);

  return (
    <form onSubmit={onSubmit} css={[ContainerLayout, container650Style, centeredStyle]}>
      <Typography as="h1" type="displayLarge">
        새 플레이 플로우 게임 만들기
      </Typography>
      <Card cssStyle={CardItemGapWithOutHeader}>
        <FormGroup label="게임 이름" requiredIndicator>
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                placeholder="예) 태양단의 비밀"
                showCharacterCount
                maxLength={100}
                {...field}
                onChange={(...args) => {
                  field.onChange(...args);
                  track.onInputThrottled({
                    inputEventName: InputEventName.input_newgame_name
                  });
                }}
              />
            )}
          />
        </FormGroup>
        <FormGroup label="게임 대표 이미지" requiredIndicator>
          <Controller
            control={control}
            name="imageId"
            rules={{ required: true }}
            render={({ field: { onChange } }) => (
              <Uploader
                getUploadFiles={(files) => {
                  if (files?.length) {
                    onChange(files?.[0].id);
                    track.onUpload({
                      uploadEventName: UploadEventName.upload_newgame_mainimage
                    });
                  }
                }}
                hasSearch
                ratio="horizontal"
              />
            )}
          />
        </FormGroup>
        <FormGroup label="한 줄 설명">
          <Controller
            control={control}
            name="description"
            render={({ field }) => (
              <Input
                placeholder="예) 한반도를 지키는 아홉개의 국보를 찾아라!"
                showCharacterCount
                maxLength={200}
                {...field}
                onChange={(...args) => {
                  field.onChange(...args);
                  track.onInputThrottled({
                    inputEventName: InputEventName.input_newgame_name
                  });
                }}
              />
            )}
          />
        </FormGroup>
      </Card>
      {NoticeBanner}
      <Button
        type="primary"
        size="large"
        disabled={!name || !imageId || name.replace(/\s/g, '').length === 0}
        htmlType="submit"
        loading={formState.isSubmitting}
      >
        게임 생성
      </Button>
    </form>
  );
}

export default ProjectsCreateV2;

function SameTypeProjectThumbnail({
  imageUrl,
  name,
  id
}: TemplateProject['sameTypeProjects'][number]) {
  return (
    <a target="_blank" rel="noopener noreferrer" href={`https://realworld.to/projects/${id}`}>
      <dl>
        <dt>
          <img src={imageUrl} alt="섬네일 이미지" css={sameTypeProjectThumbnailImage} />
        </dt>
        <dd css={sameTypeProjectThumbnailName}>{name}</dd>
      </dl>
    </a>
  );
}

const sameTypeProjectThumbnailImage = css`
  width: 173px;
  height: 138px;
  object-fit: cover;

  border-radius: ${spacing.borderRadius.xlarge};

  ${onMobile} {
    width: 147px;
    height: 117px;
  }
`;

const sameTypeProjectThumbnailName = css`
  color: initial;
  margin-top: 7px;
`;

const templateDetailsHeader = css`
  display: flex;

  img {
    max-width: 310px;
    height: 248px;

    object-fit: cover;

    border-radius: ${spacing.borderRadius.xlarge};
    margin-right: ${spacing.margin.large};
  }

  dl {
    align-self: center;

    ${onMobile} {
      align-self: initial;
    }
  }

  ${onMobile} {
    flex-direction: column;
  }
`;

const templateDetailsTitle = css`
  margin-bottom: ${spacing.margin.medium};
  overflow-wrap: anywhere;
  align-self: center;

  ${onMobile} {
    margin: ${spacing.margin.small} 0;
  }
`;

const templateDetailsDescription = css`
  overflow-wrap: anywhere;
  white-space: pre-line;

  max-height: 216px;
  overflow-y: auto;

  ${onMobile} {
    max-height: initial;
    overflow-y: initial;
  }
`;

const templateUsedTechs = css`
  display: grid;
  padding: 8px 10px;

  gap: 10px;
  grid-template-columns: repeat(auto-fill, 64px);

  svg {
    path {
      fill: ${color.palette_gray_tint_01};
    }
    rect {
      stroke: ${color.palette_gray_tint_01};
    }

    &.used {
      path {
        fill: ${color.icon_subdued};
      }
      rect {
        stroke: ${color.icon_subdued};
      }
    }
  }
`;

const sameTypeProjects = css`
  margin: 0 -${spacing.margin.xlarge2};
  overflow-x: auto;
  overflow-y: hidden;

  display: flex;

  > :first-of-type {
    margin-left: ${spacing.margin.xlarge2};
  }

  > * {
    margin-right: ${spacing.margin.large};
  }

  ${onMobile} {
    margin: 0 -${spacing.margin.large};
    > :first-of-type {
      margin-left: ${spacing.margin.large};
    }

    > * {
      margin-right: ${spacing.margin.medium};
    }
  }
`;

const chevronRight = css`
  svg {
    transform: rotate(180deg);
  }
`;

const ContainerLayout = css`
  display: flex;
  flex-direction: column;

  button[type='submit'] {
    align-self: center;
  }

  > * {
    margin-bottom: ${spacing.margin.xlarge2};
  }

  margin-bottom: ${spacing.margin.xlarge4};
`;

const NoticeBanner = (
  <Banner
    header={
      <Typography as="h2" type="heading" cssStyle={MarginBottom4}>
        게임 제작 시 아래의 사항을 유의해주세요
      </Typography>
    }
  >
    <>
      <ul
        css={css`
          li:before {
            content: '❌';
            margin-right: 0.25em;
          }
        `}
      >
        <Typography as="li" type="body">
          선정적이거나 불쾌감을 주는 이미지
        </Typography>
        <Typography as="li" type="body">
          타인의 저작권, 개인정보 또는 초상권 침해
        </Typography>
        <Typography as="li" type="body">
          위험한 행동을 조장하는 내용
        </Typography>
      </ul>
      <Typography
        as="p"
        type="body"
        cssStyle={css`
          margin-top: 1em;
        `}
      >
        위 내용에 동의할 경우 [게임 생성] 버튼을 눌러주세요. 위반 시&nbsp;
        <Button url={helpLink.notion.guideline} external>
          가이드라인
        </Button>
        에 따라 제재를 받을 수 있어요.
      </Typography>
    </>
  </Banner>
);

const PAGING_RANGE_ITEM = 6;
const PAGING_RANGE = 3;

const templateSectionContainer = css`
  > div {
    display: flex;
    flex-direction: column;

    align-items: center;
  }
`;

const templateSectionLayout = css`
  > :last-of-type {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;

    padding: ${spacing.padding.xlarge};

    @media (max-width: 632px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 435px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

const buttonGroup = css`
  width: 188px;
  display: flex;
  justify-content: space-between;

  .Item {
    align-self: flex-end;
  }
`;

const selectedPageIndexStyle = css`
  color: ${color.palette_primary_purple_100};
  font-weight: 700;
`;

const templateEmptyState: TemplatesRequest[number] = {
  id: 'DEFAULT',
  name: '템플릿 없이 시작하기',
  description: 'create your own',
  imageUrl: ''
};

type TemplateItemProps = TemplatesRequest[number] & {
  onChange(value?: string): void;
  checked: boolean;
};

function TemplateItem({ id, name, imageUrl, description, onChange, checked }: TemplateItemProps) {
  const isEmptyState = id === templateEmptyState.id;
  const history = useHistory();
  return (
    <div css={[TemplateItemStyle]}>
      <dl
        css={[
          templateItemThumbnail,
          {
            backgroundImage: `url(${imageUrl})`,
            ':hover, :focus-visible': {
              backgroundImage:
                imageUrl &&
                `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${imageUrl})`
            }
          }
        ]}
      >
        {isEmptyState ? (
          <dd css={templateItemThumbnailDescription}>{description}</dd>
        ) : (
          <div className="hover">
            <dd css={templateItemThumbnailDescription}>{description}</dd>
            <Button
              type="primary"
              size="small"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onClick={(e) => {
                e.stopPropagation();
                history.replace({
                  search: new URLSearchParams({ showTemplateDetailsId: id }).toString()
                });
                track.onClick({
                  clickEventName: ClickEventName.click_newgame_button_templatedetail,
                  params: { templateId: id, name }
                });
              }}
            >
              자세히 보기
            </Button>
          </div>
        )}
      </dl>
      <RadioButton
        name="selected-template"
        value={id}
        onChange={onChange}
        checked={checked}
        cssStyle={[MarginTop8, templateItemRadioButton]}
      >
        {name}
      </RadioButton>
    </div>
  );
}

const templateItemRadioButton = css`
  align-items: initial;

  .check-box {
    overflow: initial;
    width: 16px;
    height: 16px;
  }

  label {
    width: 146px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

const TemplateItemStyle = css`
  border-radius: ${spacing.borderRadius.large};
  width: fit-content;
`;

const templateItemThumbnail = css`
  display: grid;
  place-items: center;

  width: 173px;
  height: 138px;

  background-color: #c4c4c4;
  background-position: center;
  background-size: cover;

  border-radius: ${spacing.borderRadius.xlarge};

  .hover {
    display: none;
  }

  :hover > .hover,
  :focus-visible > .hover {
    display: grid;
    place-content: center;
  }

  padding: 0 ${spacing.padding.large};
`;

const templateItemThumbnailDescription = css`
  margin-top: ${spacing.margin.xsmall};
  font-size: 12px;
  text-align: center;
  line-height: 20px;

  margin-bottom: ${spacing.margin.medium};

  color: ${color.text_white};

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
