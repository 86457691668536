import { css } from '@emotion/react';
import { Card, Icon, Button, color, spacing } from '@uniquegood/realworld-studio-design';
import React, { useMemo } from 'react';

import { CardWrap, UpDownButton } from '@/components/ActionHandler/components';
import { IAvailabel, ReactionProps } from '@/components/ActionHandler/types';
import {
  conditionType,
  generateRandomKey,
  getBaseCommandType
} from '@/components/ActionHandler/utils';
import { MarginTop16 } from '@/styles';
import AddButton from './AddButton';
import ConditionsEditor from './ConditionsEditor';
import CommandEditor from './CommandEditor';

export interface ReactionEditorProps extends ReactionProps, IAvailabel {
  params: ActionHandler['reactionDefinitions'];
  setParams: React.Dispatch<React.SetStateAction<ActionHandler['reactionDefinitions']>>;
}

type Props = ReactionEditorProps;

const ReactionEditor = ({ params, setParams, availabelType, ...rest }: Props) => {
  function reorder(from: number, to: number) {
    setParams((value) => {
      const copied = [...value];
      const [reorderedItem] = copied.splice(from, 1);
      copied.splice(to, 0, reorderedItem);

      return copied;
    });
  }

  function remove(index: number) {
    setParams((value) => [...value.slice(0, index), ...value.slice(index + 1)]);
  }

  const isSingleReaction = useMemo(() => params?.length === 1, [params?.length]);

  const addReactionDefinition = () => {
    setParams((value) => [
      ...value,
      {
        key: generateRandomKey(),
        conditions: [
          {
            key: generateRandomKey(),
            type: conditionType.none,
            parameters: {}
          }
        ],
        commands: getBaseCommandType({ availabelType })
      }
    ]);
  };

  return (
    <CardWrap
      title="조건별 리액션"
      description="플레이어의 행동에 따른 리액션을 설정해요. 위에서부터 순차적으로 조건을 검사하여 조건을 충족하는 리액션만 실행되어요."
      cssStyle={ReactionDistance}
    >
      {/* To-Do: card-header 색상 지정 prop 필요 */}
      <>
        {params.map((reaction, index) => {
          const lastIndex = params.length - 1;
          const isFirstIndex = index === 0;
          const isLastIndex = index === lastIndex;

          function setState<
            Key extends Extract<keyof ReactionDefinition, 'conditions' | 'commands'>
          >(key: Key) {
            return (setStateAction: React.SetStateAction<ReactionDefinition[Key]>) => {
              setParams((definitions) => {
                const copied = [...definitions];

                const found = copied.find((definition) => definition.key === reaction.key);

                if (!found)
                  throw Error('상태를 업데이트 할 ReactionDefinition 을 찾지 못했습니다.');

                found[key] =
                  typeof setStateAction === 'function'
                    ? setStateAction(found[key])
                    : setStateAction;

                return copied;
              });
            };
          }

          return (
            <Card
              key={reaction.key}
              // 해당 id를 이용하여 네비게이션 기능이 작동됩니다.
              id={index > 0 ? `reaction-definition-${index + 1}` : undefined}
              header={{
                backgroundColor: color.surface_blue_hovered,
                prefix: isSingleReaction ? (
                  <Icon icon="check_solid" size="20px" />
                ) : (
                  <UpDownButton>
                    <button
                      type="button"
                      disabled={isFirstIndex}
                      onClick={() => reorder(index - 1, index)}
                    >
                      <Icon icon="chevron_up_solid" size="12px" />
                    </button>
                    <button
                      type="button"
                      disabled={isLastIndex}
                      onClick={() => reorder(index + 1, index)}
                    >
                      <Icon icon="chevron_down_solid" size="12px" />
                    </button>
                  </UpDownButton>
                ),
                content: `조건별 리액션 ${!isSingleReaction ? index + 1 : ''}`,
                suffix: isSingleReaction ? null : (
                  <Button type="plainDestructive" onClick={() => remove(index)}>
                    <Icon icon="trash_alt_solid" color={color.icon_critical} size="20px" />
                  </Button>
                )
              }}
            >
              <ConditionsEditor
                conditions={reaction.conditions}
                setConditions={setState('conditions')}
                availabelType={availabelType}
                {...rest}
              />
              <CommandEditor
                commands={reaction.commands}
                setCommands={setState('commands')}
                availabelType={availabelType}
                {...rest}
              />
            </Card>
          );
        })}

        <AddButton
          type="reaction"
          onClick={addReactionDefinition}
          size="large"
          fullWidth
          cssStyle={MarginTop16}
        >
          조건별 리액션 추가
        </AddButton>
      </>
    </CardWrap>
  );
};

export default React.memo(ReactionEditor);

const ReactionDistance = css`
  & > div:not(:last-of-type) {
    margin-bottom: ${spacing.common.medium};
  }
  margin-bottom: ${spacing.common.medium};
`;
