import { css } from '@emotion/react';
import React, { memo } from 'react';

import {
  Card,
  color,
  Icon,
  Modal,
  spacing,
  Tooltip,
  Typography
} from '@uniquegood/realworld-studio-design';

import {
  AlignCenterFlex,
  CursorPointer,
  MarginBottom24,
  MarginBottom8,
  MarginBottomZero,
  MarginLeft8,
  MarginRight4,
  MarginRight8,
  MarginZero
} from '@/styles/index';
import { onMobile } from '@/styles/responsive';
import { useModalState } from '@/hooks';
import { keyPressHandler } from '@/utils';
import { informationByMemberLevelList } from '../const';
import MemberList from './MemberList';
import { InformationByMemberLevelType, MemberCardActionProps, TypeUserLevel } from '../types';
import AddMemberModalContent from './AddMemberModalContent';
import { BaseMemberCardStyle } from './MemberCard';

const OrganizeMembersCardHeaderItem = {
  prefix: <Icon icon="user_friends_solid" size="20px" />,
  content: '구성원 편집'
};

interface OrganizeTeamCardProps extends MemberCardActionProps {
  members: User[];
  handleAddMemberToCurrentApp: (selectedUser: AccountResponseModel, level: TypeUserLevel) => void;
}

function OrganizeTeamCard({
  members,
  handleAddMemberToCurrentApp,
  ...actionProps
}: OrganizeTeamCardProps) {
  const { modal, openModal, closeModal } = useModalState();

  function onClickAddMemberBtn(level: InformationByMemberLevelType) {
    openModal({
      title: `${level.kr} 추가`,
      primaryAction: {
        content: '구성원 추가',
        type: 'primary',
        htmlType: 'submit',
        form: 'modal',
        onAction: closeModal
      },
      secondaryAction: {
        content: '취소',
        onAction: closeModal
      },
      children: (
        <AddMemberModalContent
          level={level}
          handleAddMemberToCurrentApp={handleAddMemberToCurrentApp}
        />
      )
    });
  }

  return (
    <Card header={OrganizeMembersCardHeaderItem}>
      {informationByMemberLevelList.map((level) => {
        const tooltipContentMarkup = (
          <div>
            {level.tooltip.map((t) => {
              return <p key={t.id}>{t.content}</p>;
            })}
          </div>
        );

        const addMemberHandler = () => onClickAddMemberBtn(level);

        return (
          <div key={level.en} css={WrapMemberLevelCardStyle}>
            <div css={[AlignCenterFlex, MarginBottom8]}>
              <Typography as="p" type="body" textColor="initial" cssStyle={MarginRight4}>
                {level.kr}
              </Typography>
              <Tooltip dismissOnMouseOut title={level.kr} content={tooltipContentMarkup}>
                <Icon
                  icon="question_circle_solid"
                  size="16px"
                  color={color.icon_disabled}
                  cssStyle={CursorPointer}
                />
              </Tooltip>
            </div>

            <div css={GridLayout}>
              {members && members.length > 0 && (
                <MemberList members={members} level={level} {...actionProps} />
              )}
              <div
                css={[AlignCenterFlex, AddMemberCardStyle, CursorPointer]}
                tabIndex={0}
                role="button"
                onClick={addMemberHandler}
                onKeyDown={keyPressHandler(addMemberHandler)}
              >
                <Icon icon="plus_solid" size="16px" cssStyle={[MarginZero, MarginRight8]} />
                <Typography as="p" type="body" textColor="initial" cssStyle={MarginLeft8}>
                  {level.kr} 추가
                </Typography>
              </div>
            </div>

            <Typography as="p" type="body" textColor="subdued">
              {level.description}
            </Typography>
          </div>
        );
      })}
      <Modal {...modal} />
    </Card>
  );
}

export default memo(OrganizeTeamCard);

const WrapMemberLevelCardStyle = css`
  ${MarginBottom24};
  &:last-child {
    ${MarginBottomZero};
  }
`;

const GridLayout = css`
  display: grid;
  gap: ${spacing.margin.small};
  grid-template-columns: repeat(auto-fill, 182px);
`;

const AddMemberCardStyle = css`
  ${BaseMemberCardStyle};
  width: fit-content;
  padding: ${spacing.borderedPadding.xlarge} ${spacing.common.medium};
  transition: 0.2s;

  ${onMobile} {
    width: 100%;
    justify-content: initial;
  }

  &:hover {
    background-color: ${color.surface_default_hovered};
  }
`;
