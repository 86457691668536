import { Button, ButtonProps, Icon } from '@uniquegood/realworld-studio-design';
import React, { PropsWithChildren } from 'react';

import { CSSProp } from '@/components/ActionHandler/types';

interface Props extends CSSProp {
  type: 'reaction' | 'wrongReaction';
  disabled?: ButtonProps['disabled'];
  fullWidth?: ButtonProps['fullWidth'];
  size?: ButtonProps['size'];
  onClick: () => void;
}

const AddButton = ({
  type,
  disabled = false,
  fullWidth = false,
  size = 'medium',
  onClick,
  cssStyle,
  children
}: PropsWithChildren<Props>) => {
  return (
    <Button
      type={type === 'reaction' ? 'secondaryBlue' : 'destructive'}
      disabled={disabled}
      fullWidth={fullWidth}
      size={size}
      onClick={onClick}
      cssStyle={cssStyle}
      icon={<Icon size="20px" icon="plus_solid" />}
    >
      {children}
    </Button>
  );
};

export default AddButton;
