import {
  Button,
  CheckBox,
  color,
  Icon,
  Select,
  spacing,
  Tooltip,
  Typography
} from '@uniquegood/realworld-studio-design';
import { useParams } from 'react-router-dom';
import React, { ReactNode } from 'react';
import { css } from '@emotion/react';

import { IReactionCommandDefinition, ReactionProps } from '@/components/ActionHandler/types';
import { getOptionsByType } from '@/components/ActionHandler/utils/getOptions';
import { InputItem } from '@/components/ActionHandler/components/element';
import {
  DisplayStyle,
  ElementWrap,
  FormGroupWrap,
  LabelIcon,
  LabelTooltip,
  Suffix,
  TrashButtonWrap
} from '@/components/ActionHandler/styles';
import { useCopyToClipboard } from '@/components/ActionHandler/hooks';
import { getIsFlex, getIsWidth100 } from '@/components/ActionHandler/utils';
import { MapOrEntries } from '@/hooks/useMap';
import { AlignCenterInlineFlex } from '@/styles';
import BGMUploader from '@/components/BGMUploader';
import { SelectedButtonType } from '@/pages/theme/types';
import { toast } from '@/utils';

import { useRequest } from '@/hooks';
import Chat from '../element/Chat';
import MoveScene from '../element/MoveScene';
import Editor from '@/components/Editor';

interface Props extends ReactionProps {
  command: ReactionCommand;
  setCommands: React.Dispatch<React.SetStateAction<ReactionCommand[]>>;
  commandIndex: number;
  commandDefinition?: IReactionCommandDefinition;
  removeCommand: (selectedNumber: number) => void;
}

const Command = ({
  command,
  setCommands,
  commandIndex,
  commandDefinition,
  removeCommand,
  missions,
  quests,
  items,
  chatbots,
  scenarios
}: Props) => {
  const { appId, projectId } = useParams<AppParam>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, copy] = useCopyToClipboard();

  const { data: themeResponse } = useRequest<CommonResponseModel<ProjectThemeResponseModel>>(
    `/apps/${appId}/projects/${projectId}/theme`,
    undefined,
    {
      headers: {
        'x-rwd-api-version': '1.1'
      }
    }
  );

  const theme = themeResponse?.data.theme.toLowerCase();

  const themeStyle =
    themeResponse && theme === 'dark'
      ? 'https://cdn.realworld.to/general-files/black.png'
      : 'https://cdn.realworld.to/general-files/white.png';

  const EditorStyle =
    themeResponse &&
    css`
      .ql-editor {
        height: ${themeResponse?.data.backgroundImage?.fileUrl ? '100vh' : 'auto'};
        color: ${theme === 'dark' ? 'white' : 'black'};
        background-image: url(${themeResponse.data.backgroundImage?.fileUrl ?? themeStyle});
        background-size: cover;
        background-repeat: no-repeat;
        object-fit: cover;
        display: flex;
        flex-direction: column;
        overflow: auto;
        min-height: 291px;
      }
    `;

  return (
    <div
      css={[
        FormGroupWrap,
        command.type === 'RealWorld.Commands.DisplayHtml' ? editorLineWarpingLayout : ''
      ]}
    >
      <div css={[AlignCenterInlineFlex]}>
        <div className="button-trash-wrap" css={TrashButtonWrap}>
          <Button
            type="plainDestructive"
            icon="trash_alt_solid"
            onClick={() => removeCommand(commandIndex)}
          />
        </div>
        {commandDefinition?.icon && (
          <Icon icon={commandDefinition.icon} size="20px" cssStyle={LabelIcon} />
        )}
        <Typography type="button">{commandDefinition?.label}</Typography>
        {commandDefinition?.tooltip && (
          <Tooltip title={commandDefinition?.label} content={commandDefinition?.tooltip}>
            <Icon
              icon="question_circle_solid"
              size="16px"
              color={color.icon_disabled}
              cssStyle={LabelTooltip}
            />
          </Tooltip>
        )}
      </div>

      {commandDefinition?.parameterDefinitions?.map((parameter, index) => {
        const value = command?.parameters[parameter.key];

        const onChange = (value: unknown) => {
          setCommands((prev) => {
            const copy = [...prev];
            const command = copy[commandIndex];
            if (command) {
              command.parameters[parameter.key] = value;
            }

            return copy;
          });
        };

        const onChangeMoveChatSelect = (value: string, key: string) => {
          setCommands((prev) => {
            const copy = [...prev];
            const command = copy[commandIndex];
            if (command) {
              command.parameters[key] = value;
            }
            return copy;
          });
        };

        const setFadeButtonValue = (selected: SelectedButtonType) => {
          setCommands((prev) => {
            const copy = [...prev];
            const command = copy[commandIndex];
            command.parameters.isFadeIn = selected.isBgmFadeIn;
            command.parameters.isFadeOut = selected.isBgmFadeOut;
            return copy;
          });
        };

        // eslint-disable-next-line consistent-return
        const onChangeBgmUrl = (BGM?: FileResponseModel) => {
          setCommands((prev) => {
            const copy = [...prev];
            const command = copy[commandIndex];
            if (command) {
              command.parameters.fileName = BGM?.fileName ?? '';
              command.parameters[parameter.key] = BGM?.url ?? '';
              command.parameters.isFadeIn = true;
              command.parameters.isFadeOut = true;
            }

            return copy;
          });
        };

        const selectOptions = getOptionsByType({
          missions,
          quests,
          items,
          chatbots,
          source: parameter.source,
          scenarios
        });

        const customElementWidthStyle = css`
          ${(parameter.prefix || parameter.suffix) && `width: 300px;`};
        `;

        const defaultBgmFileName =
          typeof command.parameters.fileName === 'string'
            ? encodeURIComponent(
                command.parameters.fileName.substring(exampleBgmFileNamePrefix.length)
              )
            : undefined;

        const definitionParameterMap: MapOrEntries<string, ReactNode> = new Map([
          [
            'text',
            <InputItem
              cssStyle={customElementWidthStyle}
              params={command}
              setParams={onChange}
              defaultValue={value as string}
              placeholder={parameter?.description}
            />
          ],
          [
            'list',
            <Select
              options={selectOptions}
              cssStyle={customElementWidthStyle}
              onChange={(selected) => onChange(Number(selected))}
              value={(value as number | undefined)?.toString()}
              placeholder={parameter.description}
            />
          ],
          [
            'stringList',
            <Select
              options={selectOptions}
              cssStyle={customElementWidthStyle}
              onChange={onChange}
              value={value as string}
              placeholder={parameter.description}
            />
          ],
          [
            'music',
            <div css={CustomBGMUploaderStyle}>
              <BGMUploader
                propsDefaultBgmFileName={defaultBgmFileName}
                propsDefaultBgmUrl={value as string}
                propsDefaultIsFadeIn={command.parameters.isFadeIn as boolean}
                propsDefaultIsFadeOut={command.parameters.isFadeOut as boolean}
                onChangeFadeButton={setFadeButtonValue}
                onChangeUploadedBGM={onChangeBgmUrl}
                isHideFadeButton={parameter?.isHideFadeButton}
              />
            </div>
          ],
          [
            'chatbot-message',
            <Chat params={command} setCommands={setCommands} commandIndex={commandIndex} />
          ],
          [
            'editor',
            <Editor
              css={EditorStyle}
              onChange={onChange}
              // Hack: value can be null, but Codemirror cannot accept null value
              value={(value as string | null | undefined) ?? undefined}
              minHeight="358px"
            />
          ],
          [
            'check',
            <CheckBox
              defaultChecked={value as boolean}
              onChange={(event) => onChange(event.target.checked)}
            >
              {parameter?.description}
            </CheckBox>
          ],
          [
            'move-scene',
            <MoveScene chatbots={chatbots} params={command} setParams={onChangeMoveChatSelect} />
          ]
        ]);

        const isWidth100 = getIsWidth100(parameter.style);
        const isFlex = getIsFlex(parameter?.suffix, parameter?.prefix);

        const element = (
          <div className="form-element">
            {
              Array.from(definitionParameterMap.entries()).find(
                ([key]) => key === parameter.type
              )?.[1]
            }
          </div>
        );

        const prefixMarkup = parameter.prefix ? (
          <Typography type="body" cssStyle={Suffix} textColor="subdued">
            {parameter.prefix}
          </Typography>
        ) : null;

        const suffixMarkup = parameter.suffix ? (
          <Typography type="body" cssStyle={Suffix} textColor="subdued">
            {parameter.suffix}
          </Typography>
        ) : null;

        return (
          <div
            key={`${parameter.key.toString() + index.toString()}`}
            className="form-element-wrap"
            css={[DisplayStyle({ isWidth100, isFlex }), ElementWrap({ isWidth100, isFlex })]}
          >
            {prefixMarkup}
            {element}
            {suffixMarkup}
            {commandDefinition?.copyable && parameter.type === 'text' && parameter.key === 'name' && (
              <Tooltip content="변수 복사">
                <Button
                  type="basic"
                  icon={<Icon icon="copy_solid" size="20px" />}
                  onClick={() =>
                    copy(`{${projectId}.$${command?.parameters[parameter.key]}}`).then(() =>
                      toast({ message: '변수를 복사했어요' })
                    )
                  }
                />
              </Tooltip>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Command;

const CustomBGMUploaderStyle = css`
  padding: ${spacing.common.medium};
  border-radius: ${spacing.common.small};
  border: 1px solid ${color.border_default_subdued};
  background-color: ${color.surface_default_subdued};
`;

const exampleBgmFileNamePrefix = 'KuVqAz9syezuxNcKqTiyVw-';

const editorLineWarpingLayout = css`
  display: grid;
  grid-template-columns: minmax(0, 100%);
`;
