/* eslint-disable no-nested-ternary */
import { css } from '@emotion/react';
import { color, spacing } from '@uniquegood/realworld-studio-design';

import { onMobile } from '@/styles/responsive';

export const CustomFormElementStyle = css`
  width: '300px';
`;

export const FormGroupWrap = css`
  min-height: 44px;
  position: relative;
  border-radius: ${spacing.common.small};
  padding: ${spacing.common.small};
  transition: background-color 0.2s linear;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  align-items: center;

  &:hover {
    .button-trash-wrap {
      visibility: visible;
      opacity: 1;
    }
    background-color: ${color.surface_default_hovered};
  }
`;

export const TrashButtonWrap = css`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  right: 0px;
  padding: 4px 12px;
  border-top-right-radius: ${spacing.common.small};
  border-bottom-right-radius: ${spacing.common.small};
  transition: opacity 0.2s linear;

  ${onMobile} {
    padding: 0px;
    background: initial;
    opacity: 1;
  }
`;

export const LabelIcon = css`
  margin: 0;
  margin-right: ${spacing.common.small};
`;

export const LabelTooltip = css`
  margin: 0 0 0 ${spacing.common.xsmall};
  cursor: pointer;
`;

// leftElementWidth: navigation + list
export const BottomFloatingBarExtendStyle = ({
  leftElementWidth
}: {
  leftElementWidth: string;
}) => css`
  width: calc(100% - ${leftElementWidth});

  ${onMobile} {
    width: 100%;
  }
`;

export const DisplayStyle = ({
  isWidth100,
  isFlex
}: {
  isWidth100: boolean;
  isFlex: boolean;
}) => css`
  width: ${isWidth100 ? '100%' : !isWidth100 && !isFlex ? '300px' : 'auto'};
  ${isFlex && 'display: flex; align-items: center;'};
  ${(!isWidth100 || isFlex) && `margin-left: ${spacing.common.xsmall};`};

  ${onMobile} {
    margin-left: 0px;
    display: block;
    width: 100%;
  }
`;

export const ElementWrap = ({
  isWidth100,
  isFlex
}: {
  isWidth100: boolean;
  isFlex: boolean;
}) => css`
  ${(isWidth100 || isFlex) && `margin-top: ${spacing.common.small}`};

  ${onMobile} {
    display: block;
  }
`;

export const Suffix = css`
  margin: 0px ${spacing.common.small};

  ${onMobile} {
    margin: ${spacing.common.small} 0px;
  }
`;

export const LastElementMarginBottom24 = css`
  & > div:last-of-type {
    margin-bottom: ${spacing.common.large};
  }
`;

export const PopoverListItem = css`
  width: 100%;
  /* max-width: 224px; */
  border-radius: ${spacing.borderRadius.small};
  padding: ${spacing.common.small};
  background-color: transparent;
  display: flex;
  align-items: center;

  & > span:first-of-type {
    margin: 0px;
    margin-right: ${spacing.common.small};
  }
  & > span:last-of-type {
    margin: 0px;
    margin-left: auto;
  }

  &:hover {
    background-color: ${color.surface_default_pressed};
  }

  ${onMobile} {
    width: 100%;
  }
`;

export const MultilineInputStyle = css`
  textarea {
    min-height: 116px;
    resize: vertical;
  }
`;

export const OverlayBackground = css`
  ${onMobile} {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 100%;
  }
`;
