/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css } from '@emotion/react';
import {
  ActionList,
  ActionListProps,
  color,
  Icon,
  Popover,
  spacing,
  Tooltip,
  Typography
} from '@uniquegood/realworld-studio-design';
import React, { useMemo } from 'react';

import { MarginLeft4, MarginRight8 } from '@/styles';
import { useIsAdminSelector, useToggle } from '@/hooks';
import { IActionTypeDefinition } from '@/components/ActionHandler/types';
import { actionTypeGroups } from '@/components/ActionHandler/utils';
import { actionTypeDefinitions } from '@/components/ActionHandler/utils/ActionDefinition';

interface IProps {
  actionDefinition?: IActionTypeDefinition;
  setActionType(actionType: ActionHandler['actionType']): void;
}

const ActionTypeSelectorWidthOverrideStyle = css`
  max-width: 300px;
  border-radius: 0;

  .Actions {
    border-radius: 0;
  }
`;

const PopoverScroll = css`
  overflow: hidden;
  .Content {
    overflow-y: auto;
  }
`;

// To-Do: expand_solid 아이콘 추가해야 함.
const ActionTypeSelector = ({ actionDefinition, setActionType }: IProps) => {
  const { value: popoverActive, toggle: popoverToggle } = useToggle(false);

  const { data: isAdmin } = useIsAdminSelector();

  const generalListItems: ActionListProps['items'] = useMemo(
    () =>
      actionTypeGroups.General.map((item) => ({
        id: item.type,
        content: item.label,
        prefix: <Icon icon={item.icon} size="20px" />,
        onAction: () => {
          popoverToggle();
          setActionType(item.type);
        }
      })),
    [setActionType]
  );

  const interactiveListItems: ActionListProps['items'] = useMemo(
    () =>
      actionTypeGroups.Interactive.map((item) => ({
        id: item.type,
        content: item.label,
        prefix: <Icon icon={item.icon} size="20px" />,
        onAction: () => {
          popoverToggle();
          setActionType(item.type);
        }
      })),
    [setActionType]
  );

  const advancedListItems: ActionListProps['items'] = useMemo(
    () =>
      actionTypeGroups.Advanced.map((item) => ({
        id: item.type,
        content: item.label,
        prefix: <Icon icon={item.icon} size="20px" />,
        onAction: () => {
          popoverToggle();
          setActionType(item.type);
        }
      })),
    [setActionType]
  );

  return (
    <>
      <Popover
        contentCssStyle={PopoverScroll}
        active={popoverActive}
        onClose={popoverToggle}
        activator={
          <button type="button" css={QuestButtonWrap} onClick={popoverToggle}>
            {actionDefinition?.icon && <Icon icon={actionDefinition.icon} size="20px" />}
            <Typography as="p" type="button">
              {actionDefinition?.label}
            </Typography>

            {actionDefinition?.label !== '액션 없음' && (
              <Tooltip title={actionDefinition?.label} content={actionDefinition?.tooltip}>
                <Icon
                  cssStyle={MarginLeft4}
                  icon="question_circle_solid"
                  size="16px"
                  color={color.icon_disabled}
                />
              </Tooltip>
            )}

            <Typography as="p" type="button" textColor="subdued">
              변경
            </Typography>
          </button>
        }
      >
        <Popover.Section>기본</Popover.Section>
        <ActionList items={generalListItems} cssStyle={ActionTypeSelectorWidthOverrideStyle} />
        <Popover.Section>인터랙티브</Popover.Section>
        <ActionList items={interactiveListItems} cssStyle={ActionTypeSelectorWidthOverrideStyle} />
        <Popover.Section>고급 기능</Popover.Section>
        <ActionList
          items={advancedListItems?.filter(
            (item) =>
              !actionTypeDefinitions.find((def) => def.type === item.id)?.disabled || isAdmin
          )}
          cssStyle={ActionTypeSelectorWidthOverrideStyle}
        />
      </Popover>
    </>
  );
};

export default ActionTypeSelector;

const QuestButtonWrap = css`
  display: flex;
  align-items: center;
  padding: 12px ${spacing.common.small};
  border: none;
  outline: none;
  margin: 0px;
  cursor: pointer;
  width: 100%;
  border-radius: ${spacing.common.small};
  background-color: transparent;

  &:hover {
    background-color: ${color.surface_default_hovered};
  }

  & > .IconWrap {
    margin: 0px;
    ${MarginRight8};
  }

  & > p:last-of-type {
    margin-left: auto;
    margin-right: 4px;
  }
`;
