import { Popover } from '@uniquegood/realworld-studio-design';
import { useMemo } from 'react';

import { LastElementMarginBottom24, OverlayBackground } from '@/components/ActionHandler/styles';
import { IAvailabel, ReactionProps } from '@/components/ActionHandler/types';
import filterReactionDefs from '@/components/ActionHandler/utils/filterReactionDefs';
import { useIsAdminSelector, useToggle } from '@/hooks';
import { OverflowInitial } from '@/styles';
import AddButton from './AddButton';
import Command from './Command';
import IncardHeading from './IncardHeading';
import CommandPopover from './CommandPopover';
import { generateRandomKey } from '../../utils';

interface Props extends ReactionProps, IAvailabel {
  commands: ReactionCommand[];
  setCommands(setStateAction: React.SetStateAction<ReactionCommand[]>): void;
}

const CommandEditor = ({ commands, setCommands, availabelType, ...rest }: Props) => {
  const { value: popoverActive, toggle: popoverToggle } = useToggle(false);
  const { data: isAdmin } = useIsAdminSelector();

  function remove(index: number) {
    setCommands((commands) => [...commands.slice(0, index), ...commands.slice(index + 1)]);
  }

  function addCommand(selectedType: string) {
    setCommands((commands) => [
      ...commands,
      {
        key: generateRandomKey(),
        type: selectedType,
        parameters: {}
      }
    ]);
  }

  const definitions = useMemo(
    () => filterReactionDefs({ type: 'command', availabelType }),
    [availabelType]
  );

  return (
    <div>
      <IncardHeading title="리액션" subTitle="사용자의 액션으로 인해 일어나는 반응" />

      <div css={LastElementMarginBottom24}>
        {commands.map((command, index) => {
          if (command.type === 'RealWorld.Commands.MakeCall' && !isAdmin) return null;

          return (
            <Command
              key={command.key}
              command={command}
              setCommands={setCommands}
              commandIndex={index}
              commandDefinition={definitions.find((item) => item.type === command.type)}
              removeCommand={() => remove(index)}
              {...rest}
            />
          );
        })}
      </div>

      {popoverActive && <div className="overlay-background" css={OverlayBackground} />}
      <Popover
        contentCssStyle={OverflowInitial}
        active={popoverActive}
        onClose={popoverToggle}
        activator={
          <AddButton onClick={popoverToggle} type="reaction">
            리액션 추가
          </AddButton>
        }
      >
        <CommandPopover
          availabelType={availabelType}
          addCommand={addCommand}
          popoverToggle={popoverToggle}
        />
      </Popover>
    </div>
  );
};

export default CommandEditor;
