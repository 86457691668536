import { css } from '@emotion/react';
import { color, Typography } from '@uniquegood/realworld-studio-design';
import { ColumnFlex } from '@/styles';

interface SummaryCardProps {
  title: string;
  count: number;
  color: string;
}

export default function SummaryCard({ title, count, color }: SummaryCardProps) {
  return (
    <dl css={WrapperStyle}>
      <Typography as="dt" type="body">
        {title}
      </Typography>
      <Typography as="dd" type="displaySmall" style={{ color }}>
        {count}명
      </Typography>
    </dl>
  );
}

const WrapperStyle = css`
  margin: 0;
  padding: 20px;

  width: 160px;

  ${ColumnFlex}

  justify-content: space-between;

  background-color: ${color.palette_gray_tintz_1};
  border-radius: 16px;

  dt,
  dd {
    color: ${color.palette_secondary_dark_blue_100};
    margin: 0;
  }
`;
