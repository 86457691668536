import { useState } from 'react';
import type { ModalProps } from '@uniquegood/realworld-studio-design';

export default function useModalState() {
  const initialState = { open: false, onClose: closeModal };

  const [modal, setModal] = useState<ModalProps>(initialState);

  function closeModal() {
    setModal(initialState);
  }

  function openModal(
    props: Omit<ModalProps, 'open' | 'onClose'> & Partial<Pick<ModalProps, 'onClose'>>
  ) {
    setModal({ ...initialState, ...props, open: true });
  }

  return { modal, openModal, closeModal };
}
