import { css } from '@emotion/react';
import {
  ActionList,
  ActionListProps,
  color,
  Icon,
  Popover,
  spacing
} from '@uniquegood/realworld-studio-design';
import React, { useMemo } from 'react';

import {
  IAvailabel,
  IReactionConditionDefinition,
  ReactionProps
} from '@/components/ActionHandler/types';
import { LastElementMarginBottom24 } from '@/components/ActionHandler/styles';
import { conditionType, generateRandomKey } from '@/components/ActionHandler/utils';
import filterReactionDefs from '@/components/ActionHandler/utils/filterReactionDefs';
import { useToggle } from '@/hooks';
import { onMobile } from '@/styles/responsive';
import AddButton from './AddButton';
import Condition from './Condition';
import IncardHeading from './IncardHeading';
import { reactionConditionDefinitions } from '../../utils/ReactionDefinitions';

interface Props extends ReactionProps, IAvailabel {
  conditions: ReactionCondition[];
  setConditions(setStateAction: React.SetStateAction<ReactionCondition[]>): void;
}

const RealWorldConditionsNone = 'RealWorld.Conditions.None';

const ConditionsEditor = ({ conditions, setConditions, availabelType, ...rest }: Props) => {
  const { value: popoverActive, toggle: popoverToggle } = useToggle(false);

  function remove(index: number) {
    setConditions((conditions) => {
      if (conditions.length === 1)
        return [
          {
            key: generateRandomKey(),
            type: RealWorldConditionsNone,
            parameters: {}
          }
        ];

      return [...conditions.slice(0, index), ...conditions.slice(index + 1)];
    });
  }

  function addCondition(selectedType: IReactionConditionDefinition['type']) {
    const newCondition = {
      key: generateRandomKey(),
      type: selectedType,
      parameters: {}
    };

    setConditions((conditions) => [
      ...conditions.filter((condition) => condition.type !== RealWorldConditionsNone),
      newCondition
    ]);
  }

  const conditionPopoverItems: ActionListProps['items'] = useMemo(
    () =>
      filterReactionDefs({
        type: 'condition',
        availabelType
      })
        .filter((definition) => definition.type !== conditionType.none)
        .map((definition) => ({
          id: definition.type,
          content: definition.label,
          prefix: <Icon icon={definition.icon} size="20px" />,
          onAction: () => {
            popoverToggle();
            addCondition(definition.type);
          }
        }))
        .filter(Boolean),
    []
  );

  return (
    <div>
      <IncardHeading title="리액션 실행 조건" />

      <div css={LastElementMarginBottom24}>
        {conditions.map((condition, index) => (
          <Condition
            key={condition.key}
            params={condition}
            setParams={setConditions}
            conditionDefinition={reactionConditionDefinitions.find(
              (definition) => definition.type === condition.type
            )}
            conditionIndex={index}
            removeCondition={() => remove(index)}
            {...rest}
          />
        ))}
      </div>

      <Popover
        active={popoverActive}
        onClose={popoverToggle}
        activator={
          <AddButton type="reaction" onClick={popoverToggle}>
            실행 조건 추가
          </AddButton>
        }
      >
        <ActionList items={conditionPopoverItems} />
      </Popover>

      <div css={Divider} />
    </div>
  );
};

export default React.memo(ConditionsEditor);

const Divider = css`
  height: 8px;
  background-color: ${color.surface_default_disabled};
  margin: ${spacing.common.large} -${spacing.common.large};

  ${onMobile} {
    margin: ${spacing.common.large} -${spacing.common.medium};
  }
`;
