import React from 'react';
import getNiceToken, { NiceToken } from '@/apis/account';

type UsePassAuthParams = {
  onSuccess?: () => unknown;
  onFail?: (errorTitle: string) => unknown;
};

export default function usePassAuth({ onSuccess, onFail }: UsePassAuthParams) {
  const accessToken = localStorage.getItem('accessToken');

  if (!accessToken) {
    throw new Error('accessToken is required');
  }

  const getToken = async (accessToken: string) => {
    try {
      const { data } = await getNiceToken(accessToken);

      return data;
    } catch (e) {
      console.error(e);

      throw e;
    }
  };

  const callNiceAuthPopup = (niceTokenData: NiceToken, window: Window | null) => {
    const form = document.createElement('form');
    form.name = 'form';
    form.id = 'form';
    form.action = 'https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb';
    form.target = 'pass-popup';

    form.innerHTML = `
      <input type="hidden" id="m" name="m" value="service" />
      <input type="hidden" id="token_version_id" name="token_version_id" />
      <input type="hidden" id="enc_data" name="enc_data" />
      <input type="hidden" id="integrity_value" name="integrity_value" />
    `;

    document.body.appendChild(form);

    form.token_version_id.value = niceTokenData.tokenVersionId;
    form.enc_data.value = niceTokenData.encData;
    form.integrity_value.value = `${niceTokenData.integrityValue}`;
    form.submit();
  };

  const initNiceAuth = async () => {
    try {
      const popupWindow = window.open('', 'pass-popup', 'width=450,height=700');
      const data = await getToken(accessToken);
      if (data) {
        callNiceAuthPopup(data, popupWindow);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleGetAuthResult = (e: MessageEvent) => {
    if (e.data.type === 'pass-auth') {
      if (e.data.data.status) {
        onSuccess?.();

        window.removeEventListener('message', handleGetAuthResult);
      } else {
        onFail?.(e.data.data.errorTitle);
      }
    }
  };

  React.useEffect(() => {
    window.addEventListener('message', handleGetAuthResult);

    return () => {
      window.removeEventListener('message', handleGetAuthResult);
    };
  }, []);

  return {
    openNiceAuth: initNiceAuth
  };
}
