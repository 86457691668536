import React, { useMemo } from 'react';

import { Banner, FormGroup, Icon, Typography } from '@uniquegood/realworld-studio-design';

import Uploader from '@components/Uploader';
import { css } from '@emotion/react';
import { Flex, Gap, MarginRight24, MarginVertical24 } from '@/styles';

import { mockup } from '@/components/ActionHandler/assets';

import type { ActionParametersEditorProps } from '../ActionParametersEditor';

interface ThemeImages {
  background: string;
  compass: string;
  marker: string;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
interface ThemeProps extends ActionParametersEditorProps {
  actionParameter: {
    themeImages?: ThemeImages;
  };
}

export default function Theme({ actionParameter, setActionParameters }: ThemeProps) {
  function getUploaderProps(imageType: keyof ThemeImages) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const uploadedImageUrl: string = actionParameter.themeImages?.[imageType];
    return {
      uploadedFiles: uploadedImageUrl ? [{ id: '', url: uploadedImageUrl }] : undefined,
      getUploadFiles: (files?: UserFileResponseModel[]) => {
        // Hack: possible to undefined
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setActionParameters((parameters) => ({
          ...parameters,
          themeImages: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...(parameters.themeImages ?? {}),
            [imageType]: files?.length === 1 ? files[0].url : undefined
          }
        }));
      }
    };
  }

  const backgroundUploaderProps = useMemo(() => getUploaderProps('background'), []);
  const compassUploaderProps = useMemo(() => getUploaderProps('compass'), []);
  const markerUploaderProps = useMemo(() => getUploaderProps('marker'), []);

  const backgroundImage = css`
    background-image: url(${mockup}), url(${actionParameter.themeImages?.background});
  `;

  return (
    <>
      <Banner iconComponent={<Icon icon="info_circle_solid" />} cssStyle={MarginVertical24}>
        <ul>
          <Typography as="li" type="body">
            10MB 이하의 jpg, png 파일만 업로드할 수 있어요. 권장 이미지 사이즈를 참고해주세요.
          </Typography>
          <Typography as="li" type="body">
            배경 이미지는 기기 비율에 따라 일부 잘릴 수 있으며, 미리보기 화면은 실제 화면과 다를 수
            있어요.
          </Typography>
        </ul>
      </Banner>
      <section css={Flex}>
        <FormGroup label="미리보기" cssStyle={MarginRight24}>
          <div
            css={css`
              position: relative;
            `}
          >
            <div css={[PreviewImageLayout, backgroundImage]} />
            {actionParameter.themeImages?.marker && (
              <img
                src={actionParameter.themeImages.marker}
                width="20px"
                height="20px"
                alt="marker"
                css={MarkerPosition}
              />
            )}
            {actionParameter.themeImages?.compass && (
              <img
                src={actionParameter.themeImages.compass}
                width="132px"
                height="132px"
                alt="compass"
                css={CompassPosition}
              />
            )}
          </div>
        </FormGroup>
        <div css={Gap}>
          <FormGroup id="background" label="배경 이미지 (1080x1920 권장)">
            <Uploader
              id="background"
              ratio="vertical"
              useFor="gps-background"
              {...backgroundUploaderProps}
            />
          </FormGroup>
          <FormGroup id="compass" label="방향 안내용 이미지 (660x660 권장)">
            <Uploader
              size="small"
              id="compass"
              ratio="square"
              useFor="gps-compass"
              {...compassUploaderProps}
            />
          </FormGroup>
          <FormGroup id="marker" label="목적지 표시용 이미지 (150x150 권장)">
            <Uploader
              size="small"
              id="marker"
              ratio="square"
              useFor="gps-marker"
              {...markerUploaderProps}
            />
          </FormGroup>
        </div>
      </section>
    </>
  );
}

const PreviewImageLayout = css`
  width: 244px;
  height: 494px;

  background-position: center;
  background-size: cover;

  clip-path: path(
    'M2 39C2 17.4609 19.4609 0 41 0H202C223.539 0 241 17.4609 241 39V125.398H242C243.105 125.398 244 126.293 244 127.398V180.872C244 181.977 243.105 182.872 242 182.872H241V453C241 474.539 223.539 492 202 492H41C19.4609 492 2 474.539 2 453L2 196.386C1.15229 196.154 0.529297 195.378 0.529297 194.457V161.883C0.529297 160.961 1.15229 160.185 2 159.953V149.433C0.895432 149.433 0 148.537 0 147.433V115.903C0 114.799 0.895431 113.903 2 113.903L2 96.8033C0.89543 96.8033 0 95.9079 0 94.8033V80.8399C0 79.7353 0.895431 78.8399 2 78.8399V39Z'
  );
`;

const MarkerPosition = css`
  position: absolute;
  bottom: 261px;

  margin: 0 auto;
  left: 0;
  right: 0;
`;

const CompassPosition = css`
  position: absolute;
  bottom: 100px;

  margin: 0 auto;
  left: 0;
  right: 0;
`;
