import {
  Banner,
  Button,
  Card,
  CheckBox,
  color,
  FormGroup,
  Icon,
  Input,
  Modal,
  textStyleBody,
  Typography
} from '@uniquegood/realworld-studio-design';
import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import { Controller, useForm } from 'react-hook-form';
import { coreApi } from '@/api';
import {
  CardItemGap,
  Gap,
  ListStyleTypeDisc,
  MarginBottom16,
  MarginBottom24,
  MarginTop24,
  MarginTop8
} from '@/styles';
import BottomFloatingBar, { SpacingForBottomFloatingBar } from '@/components/BottomFloatingBar';
import { Container650 } from '@/components/containers';
import LoadingSpinner from '@/components/LoadingSpinner';
import Uploader from '@/components/Uploader';
import {
  useInput,
  useToggle,
  useRequest,
  useDebounce,
  useModalState,
  useRestrictProjectRoute
} from '@/hooks';
import { toast } from '@/utils';
import { levelToKorean } from '@/const';
import OrganizeTeamCard from './components/OrganizeTeamCard';
import { TypeUserLevel } from './types';
import FailImageModal from './components/FailImageModal';
import ContactMailForm from './components/ContactMailForm';
import { PageViewEventName, track } from '@/track';

const ChannelInfoCardHeaderItem = {
  content: '채널 정보',
  isRequired: true,
  prefix: <Icon icon="home_solid" size="20px" />
};

const NAME_MAX_LENGTH = 30;
const DESCRIPTION_MAX_LENGTH = 80;

function Settings() {
  const { appId } = useParams<AppParam>();

  const { data: me } = useRequest<MyInfo>('/api/me');
  const { data: app, mutate, isValidating } = useRequest<RealWorldApp>(`/apps/${appId}`);

  const [name, setName] = useState('');
  const debouncedName = useDebounce(name, 200);
  const [isAvailableName, setIsAvailableName] = useState<boolean | 'validating'>(true);
  const [isUploaded, onChangeUploadStatus] = useState(false);
  const [uploadedFiles, onChangeUploadedFiles] = useState<UserFileResponseModel[]>();
  const [description, onChangeDescription] = useState('');
  const [unsubscribeCommunityEmail, setUnsubscribeCommunityEmail] = useState(false);

  const [isSaveLoading, handleIsSaveLoading] = useInput(false);

  const { modal, openModal, closeModal } = useModalState();

  const history = useHistory();

  const redirectTo = useRestrictProjectRoute();

  const {
    value: isFailImageModal,
    setTrue: openFailImageModal,
    setFalse: closeFailImageModal
  } = useToggle(false);
  const [failImageModalTitle, handleFailImageModalTitle] = useInput('');
  const [failImageModalContent, handleFailImageModalContent] = useInput('');

  useEffect(() => {
    track.onPageView({ pageViewEventName: PageViewEventName.view_channel_setting });
  }, []);

  useEffect(() => {
    async function checkAvailable() {
      if (app === undefined || !debouncedName) return;

      setIsAvailableName('validating');

      try {
        const isAvailableChannelName = await checkAvailableChannelName(name);
        setIsAvailableName(isAvailableChannelName || app.name === name);
      } catch {
        toast({ message: '중복 체크에 실패했습니다. 다시 시도해주세요!', type: 'error' });
      }
    }

    checkAvailable();
  }, [debouncedName]);

  const onChangeUnsubscribeCommunityEmail = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setUnsubscribeCommunityEmail(!target.checked);
  };

  const handleUpdateMemberFromCurrentApp = useCallback(
    (member: User, level: TypeUserLevel) => {
      if (!app?.members.length) throw Error('채널 멤버 정보를 찾지 못했습니다.');

      const owners = app.members.filter((m) => m.level === 'Owner');

      if (!me) throw Error('me 값이 정의되어 있지 않습니다.');
      const isSelf = me.id === member.id;

      if (isSelf && member.level === 'Owner' && owners.length < 2) {
        openFailImageModal();
        handleFailImageModalTitle('최소 한 명 이상의 소유자가 필요해요');
        handleFailImageModalContent('다시 시도해주세요');

        return;
      }

      if (member.id === me.id) {
        openModal({
          title: '정말 본인을 소유자에서 제외하시겠어요?',
          children:
            '등급을 소유자에서 편집자로 변경하면 더 이상 이 페이지에 접근할 수 없어요.  [변경하고 나가기] 버튼을 누르면 즉시 등급이 변경되며 게임 목록 페이지로 이동해요.',
          primaryAction: {
            onAction: async () => {
              await onAction();

              history.push('/apps');
            },
            icon: 'sign_out_alt_solid',
            type: 'destructive',
            content: '변경하고 나가기'
          },
          secondaryAction: {
            content: '취소',
            onAction: closeModal
          }
        });

        return;
      }

      onAction();
      toast({ message: `${member.name}님을 ${levelToKorean[level]}로 변경했어요!` });

      async function onAction() {
        await mutate(async (app) => {
          if (!app) throw Error('채널 정보를 찾지 못했습니다.');

          const members = await updateUser(appId, member.id, level);
          return { ...app, members };
        });
      }
    },
    [app]
  );

  const handleAddMemberToCurrentApp = useCallback(
    async (selectedUser: AccountResponseModel, level: TypeUserLevel) => {
      if (appId === undefined) return;

      try {
        await mutate(async (app) => {
          if (!app) throw Error('채널 정보를 찾지 못했습니다.');

          const members = await addUser(appId, selectedUser.id, level);
          return { ...app, members };
        });

        toast({ message: `${selectedUser.name}님을 ${levelToKorean[level]}로 추가했어요!` });
      } catch {
        openFailImageModal();
        handleFailImageModalTitle('구성원을 추가할 수 없어요');
        handleFailImageModalContent('이미 등록된 사용자인지 확인해주세요');
      }
    },
    [appId]
  );

  const handleRemoveMemberFromCurrentApp = useCallback(
    (member: User) => {
      if (!app?.members.length) throw Error('채널 멤버 정보를 찾지 못했습니다.');
      const owners = app.members.filter((member) => member.level === 'Owner');

      if (!me) throw Error('me 값이 정의되어 있지 않습니다.');
      const isSelf = me.id === member.id;

      if (isSelf && member.level === 'Owner' && owners.length < 2) {
        openFailImageModal();
        handleFailImageModalTitle('최소 한 명 이상의 소유자가 필요해요');
        handleFailImageModalContent('다시 시도해주세요');

        return;
      }

      if (member.id === me.id) {
        if (!app) throw Error('현재 채널 정보를 얻지 못했습니다.');
        openModal({
          title: '이 채널을 나가시겠어요?',
          children: `채널을 나가면 내 채널 목록에서 ‘${app.name}'가 삭제되며 소유자의 초대 없이는 다시 들어올 수 없어요.`,
          primaryAction: {
            onAction: async () => {
              await onAction();
              history.push('/apps');
            },
            icon: 'sign_out_alt_solid',
            type: 'destructive',
            content: '나가기'
          },
          secondaryAction: {
            content: '취소',
            onAction: closeModal
          }
        });

        return;
      }

      openModal({
        isSmallScreen: true,
        title: '구성원을 삭제하시겠어요?',
        primaryAction: { content: '삭제', onAction, icon: 'trash_alt_solid', type: 'destructive' },
        secondaryAction: { content: '취소', onAction: closeModal },
        children: '삭제한 구성원은 더 이상 이 채널에 접근할 수 없어요'
      });

      async function onAction() {
        await mutate(async (app) => {
          if (!app) throw Error('채널 정보를 찾지 못했습니다.');

          const members = await deleteUser(appId, member.id);
          return { ...app, members };
        });

        toast({ message: `${member.name}님을 ${levelToKorean[member.level]}에서 삭제했어요!` });

        closeModal();
      }
    },
    [app]
  );

  const onSubmit = useCallback(async () => {
    if (app === undefined || uploadedFiles === undefined) return;

    if (isUploaded) {
      toast({ message: '파일 업로드를 완료해주세요!', type: 'error' });

      return;
    }

    if (name === '') {
      openFailImageModal();
      handleFailImageModalTitle('필수 항목을 모두 채워주세요');
      handleFailImageModalContent('채널 이름이 비워져있어요');

      return;
    }

    if (isAvailableName === 'validating') {
      toast({ message: '잠시 후 다시 시도해주세요', type: 'error' });

      return;
    }

    if (isAvailableName === true) {
      const bodyData = {
        countryCode: null,
        description,
        id: app.id,
        imageId: uploadedFiles[0]?.id ?? '',
        imageUrl: uploadedFiles[0]?.url ?? '',
        localNumber: '',
        members: app.members,
        name,
        unsubscribeCommunityEmail
      };

      handleIsSaveLoading(true);

      try {
        await coreApi.put(`/apps/${app.id}`, bodyData);
        toast({ message: '저장 완료!' });
      } finally {
        handleIsSaveLoading(false);
      }
    } else {
      openFailImageModal();
      handleFailImageModalTitle('채널 이름을 확인해주세요');
      handleFailImageModalContent(
        '이미 존재하거나 잘못된 채널 이름이에요. 채널 이름을 다시 입력해 주세요.'
      );
    }
  }, [
    name,
    uploadedFiles,
    description,
    unsubscribeCommunityEmail,
    isUploaded,
    isAvailableName,
    app
  ]);

  useEffect(() => {
    if (app === undefined) return;

    setName(app.name);

    const uploadedFiles: UserFileResponseModel[] = !app.imageId
      ? []
      : [
          {
            id: app.imageId,
            url: app.imageUrl
          }
        ];
    onChangeUploadedFiles(uploadedFiles);
    onChangeDescription(app.description ?? '');
    setUnsubscribeCommunityEmail(app.unsubscribeCommunityEmail ?? true);
  }, [app]);

  if (!app || redirectTo === undefined) return <LoadingSpinner />;

  if (typeof redirectTo === 'string') return <Redirect to={redirectTo} />;

  return (
    <Container650 cssStyle={[MarginTop24, Gap, SpacingForBottomFloatingBar]} centered>
      <Modal {...modal} primaryAction={{ ...modal.primaryAction, loading: isValidating }} />
      <FailImageModal
        isFailImageModal={isFailImageModal}
        closeFailImageModal={closeFailImageModal}
        title={failImageModalTitle}
        content={failImageModalContent}
      />
      <Typography type="displayLarge" as="h1" cssStyle={MarginBottom24}>
        채널 설정
      </Typography>

      <Card header={ChannelInfoCardHeaderItem} cssStyle={CardItemGap}>
        <FormGroup
          id="name"
          label="채널 이름"
          errorText={isAvailableName === false ? '이미 사용 중인 이름입니다' : ''}
          helpText="게임을 공개할 채널의 이름을 설정해주세요"
          requiredIndicator
          cssStyle={Gap}
        >
          <Input
            id="name"
            error={isAvailableName === false}
            placeholder="예) 윤익월드"
            maxLength={NAME_MAX_LENGTH}
            onChange={setName}
            showCharacterCount
            value={name}
          />
        </FormGroup>
        <FormGroup label="채널 대표 이미지">
          <Uploader
            getUploadLoadingStatus={onChangeUploadStatus}
            getUploadFiles={onChangeUploadedFiles}
            uploadedFiles={uploadedFiles}
            size="small"
            ratio="square"
          />
        </FormGroup>
        <FormGroup
          id="description"
          label="채널 한 줄 소개"
          helpText="채널을 한 마디로 표현해주세요"
        >
          <Input
            id="description"
            placeholder="예) 내가 만든 게임으로 세상을 윤익하게!"
            maxLength={DESCRIPTION_MAX_LENGTH}
            onChange={onChangeDescription}
            showCharacterCount
            value={description}
          />
        </FormGroup>
        <ContactMailForm css={MarginTop24} />
        <CheckBox
          defaultChecked={!unsubscribeCommunityEmail}
          onChange={onChangeUnsubscribeCommunityEmail}
          cssStyle={MarginTop8}
        >
          커뮤니티 업데이트 소식 받아보기
        </CheckBox>
        {unsubscribeCommunityEmail && (
          <Banner
            header="커뮤니티 업데이트 소식을 받아보면 이런 점이 좋아요!"
            type="informational"
            cssStyle={MarginTop8}
          >
            <ol>
              <li>커뮤니티에 등록된 글을 놓치지 않게 알려줘요.</li>
              <li>플레이어의 피드백과 제안을 메일로 받아볼 수 있어요.</li>
            </ol>
          </Banner>
        )}
      </Card>

      <OrganizeTeamCard
        members={app.members}
        handleUpdateMemberFromCurrentApp={handleUpdateMemberFromCurrentApp}
        handleRemoveMemberFromCurrentApp={handleRemoveMemberFromCurrentApp}
        handleAddMemberToCurrentApp={handleAddMemberToCurrentApp}
      />

      {app.channelType !== 'Realworld' && <DisableChannel name={app.name} />}

      <BottomFloatingBar onSave={onSubmit} loading={isSaveLoading} />
    </Container650>
  );
}

export const manageChannelTypeCardId = 'channel-type-and-release-permission';

function DisableChannel({ name }: Pick<RealWorldApp, 'name'>) {
  const { handleSubmit, formState, reset, control } = useForm<Pick<RealWorldApp, 'name'>>({
    mode: 'onChange'
  });
  const history = useHistory();
  const { appId } = useParams<AppParam>();

  const { mutate } = useRequest<RealWorldApp[]>('/apps');

  const [isDeleting, setIsDeleting] = useState(false);

  const { modal, openModal, closeModal } = useModalState();

  const onSubmit = handleSubmit(() => {
    openModal({
      title: '정말 채널을 비활성화하시겠어요?',
      cssStyle: textStyleBody,
      children: (
        <>
          <p css={MarginBottom16}>
            채널 구성원이 모두 제거되고, 생성했던 모든 게임이 삭제되어요!
            <br />
            비활성화 이후에는 채널을 복구할 수 없어요!
          </p>
          <p>본 내용에 동의하신다면 비활성화를 진행해주세요</p>
        </>
      ),
      primaryAction: {
        type: 'destructive',
        content: '비활성화',
        icon: 'trash_alt_solid',
        onAction: async () => {
          setIsDeleting(true);

          await coreApi.delete(`/apps/${appId}/disable`);

          const defaultChannelID = localStorage.getItem('defaultChannelID');

          if (appId === defaultChannelID) {
            localStorage.removeItem('defaultChannelID');
          }

          const apps = await mutate((apps) => apps?.filter((app) => app.id !== appId));

          setIsDeleting(false);

          if (apps?.length) {
            history.push(`/apps/${apps[0].id}`);
          } else {
            history.push('/createApp');
          }
        }
      },
      secondaryAction: {
        content: '취소',
        onAction: () => {
          reset({ name: '' });
          closeModal();
        }
      }
    });
  });

  return (
    <Card
      header={{
        content: '채널 비활성화',
        prefix: <Icon icon="times_regular" color={color.icon_critical} />,
        textColor: color.text_critical,
        backgroundColor: color.surface_critical_subdued_hovered
      }}
    >
      <Modal {...modal} primaryAction={{ ...modal.primaryAction, loading: isDeleting }} />
      <form css={textStyleBody} onSubmit={onSubmit}>
        <p css={MarginBottom16}>
          <b>&apos;{name}&apos;</b> 채널의{' '}
          <Typography as="span" type="body" textColor="critical">
            모든 내용이 즉시 삭제되며, 비활성화 이후에는 복구할 수 없어요!
          </Typography>
        </p>
        <b>유의사항을 확인해주세요!</b>
        <ul css={[ListStyleTypeDisc, MarginBottom16]}>
          <li>소유자, 편집자, 테스터로 지정된 채널 구성원이 모두 제거되어요!</li>
          <li>공개한 게임을 포함하여 생성했던 모든 게임이 일괄 삭제되어요!</li>
        </ul>
        <p css={MarginBottom24}>정말 채널을 비활성화하려면 채널의 이름을 입력해주세요.</p>
        <Controller
          control={control}
          name="name"
          rules={{ required: true, validate: (typing) => typing === name }}
          render={({ field }) => <Input cssStyle={MarginBottom24} {...field} placeholder={name} />}
        />
        <Button
          type="destructive"
          disabled={!formState.isValid}
          icon="trash_alt_solid"
          htmlType="submit"
        >
          비활성화
        </Button>
      </form>
    </Card>
  );
}

export default Settings;

async function updateUser(appId: string | undefined, memberId: string, level: TypeUserLevel) {
  return coreApi
    .put<User[]>(`/apps/${appId}/members/${memberId}`, {
      level
    })
    .then(({ data }) => data);
}

async function deleteUser(appId: string | undefined, memberId: string) {
  return coreApi.delete<User[]>(`/apps/${appId}/members/${memberId}`).then(({ data }) => data);
}

async function addUser(appId: string, userId: string, level: TypeUserLevel) {
  return coreApi
    .post<User[]>(`/apps/${appId}/members`, {
      userId,
      level
    })
    .then(({ data }) => data);
}

async function checkAvailableChannelName(name: string) {
  return coreApi
    .get<boolean>('api/apps/checkName', {
      params: {
        name
      }
    })
    .then(({ data }) => data);
}
