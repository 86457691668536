import React, { useCallback } from 'react';
import { Navigation, NavigationProps } from '@uniquegood/realworld-studio-design';
import { useLocation } from 'react-router-dom';

import { operation, gameEdit, SectionItem } from '../pages';

interface SectionWrapperProps {
  type: NavigationProps['type'];
  paths: {
    operation: string[];
    gameEdit: string[];
  };
  onClick: SectionItems[0]['onClick'];
}

type SectionItems = React.ComponentProps<typeof Navigation.Section>['items'];
type MapperType = keyof SectionWrapperProps['paths'];

export default function SectionWrapper({ type, paths, onClick }: SectionWrapperProps) {
  const { pathname } = useLocation();

  const mapper = useCallback(
    (sectionItems: SectionItem[], type: MapperType): SectionItems =>
      sectionItems.map((section, index) => {
        const url = paths[type][index];
        return {
          ...section,
          url,
          onClick,
          selected: pathname.startsWith(url)
        };
      }),
    [paths.gameEdit, paths.operation]
  );

  const operationSection = mapper(Object.values(operation), 'operation');
  const gameEditSection = mapper(Object.values(gameEdit), 'gameEdit');

  const isGameEdit = type === 'gameEdit';

  return (
    <>
      <Navigation.Section title="게임 운영" isAllHidden={isGameEdit} items={operationSection} />
      <Navigation.Section title="게임 편집" isTitleHidden={isGameEdit} items={gameEditSection} />
    </>
  );
}
