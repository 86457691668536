import { css, keyframes } from '@emotion/react';
import { color, spacing } from '@uniquegood/realworld-studio-design';
import React from 'react';

const SkeletonUI = () => {
  return <div css={SkeletonItem} />;
};

export default SkeletonUI;

const loading = keyframes`
  0% {
    transform: translateX(0);
  }

  50%, 100% {
    transform: translateX(300px);
  }
`;

const SkeletonItem = css`
  list-style: none;
  width: 300px;
  height: 36px;
  background: ${color.surface_default_subdued};
  border: 1px solid ${color.border_default_subdued};
  border-radius: ${spacing.borderRadius.small};
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(165, 165, 165, 0.1),
      rgba(165, 165, 165, 0.3),
      rgba(165, 165, 165, 0.1)
    );
    animation: ${loading} 1s infinite linear;
  }
`;
