import { useParams } from 'react-router-dom';

import React from 'react';
import { useRequest } from '@/hooks';

export default function useUser() {
  const { appId } = useParams<AppParam>();

  const { data: me, ...meRest } = useRequest<MyInfo>('/api/me');
  const { data: app, ...appsRest } = useRequest<RealWorldApp>(`/apps/${appId}`);

  const user = React.useMemo(
    () => me && app?.members.find((member) => member.id === me.id),
    [me, app]
  );

  return React.useMemo(
    () => ({ data: user, isValidating: meRest.isValidating || appsRest.isValidating }),
    [user, meRest.isValidating, appsRest.isValidating]
  );
}
