import { css } from '@emotion/react';
import { Avatar, Button, spacing } from '@uniquegood/realworld-studio-design';
import React, { useState } from 'react';

import { onMobile } from '@/styles/responsive';
import { toast } from '@/utils';
import { InformationByMemberLevelType, TypeUserLevel } from '../types';
import { BaseMemberCardStyle } from './MemberCard';
import AddUserForm from './AddUserForm';

interface AddMemberModalContentProps {
  level: InformationByMemberLevelType | undefined;
  handleAddMemberToCurrentApp: (selectedUser: AccountResponseModel, level: TypeUserLevel) => void;
}

function AddMemberModalContent({ level, handleAddMemberToCurrentApp }: AddMemberModalContentProps) {
  const [selectedUser, setSelectedUser] = useState<AccountResponseModel>();

  const onClickAddMemberButton: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    if (level === undefined) return;

    if (selectedUser === undefined) {
      toast({
        type: 'error',
        message: '추가하려는 유저를 선택해주세요!'
      });

      return;
    }

    handleAddMemberToCurrentApp(selectedUser, level.en);
  };

  return (
    <form id="modal" onSubmit={onClickAddMemberButton}>
      {selectedUser ? (
        <div css={MemberInfoCardStyle}>
          <Avatar userName={selectedUser.name ?? ''} source={selectedUser?.profileImage ?? ''} />
          <Button
            icon="times_regular"
            size="small"
            type="plain"
            onClick={() => setSelectedUser(undefined)}
          />
        </div>
      ) : (
        <AddUserForm onClickSearchUserCard={setSelectedUser} />
      )}
    </form>
  );
}

export default AddMemberModalContent;

const MemberInfoCardStyle = css`
  ${BaseMemberCardStyle};
  width: 182px;
  padding: ${spacing.borderedPadding.small};
  margin-right: ${spacing.common.small};
  display: flex;
  justify-content: space-between;

  ${onMobile} {
    width: 100%;
    margin-right: 0px;
  }
`;
