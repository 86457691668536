const fontFamilies = [
  'notosansl',
  'notosans',
  'notosansb',
  'chosunsm',
  'uhbeegenwoo',
  'midschool',
  'yeoseel',
  'yeosee',
  'yeoseeb',
  'yangpyeongl',
  'yangpyeong',
  'yangpyeongb',
  'dosmyungjo',
  'fatherlove',
  'mapobackpack'
];

export { fontFamilies };
