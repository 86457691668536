import Quill from 'quill';

import './audio.css';

const BlockEmbed = Quill.import('blots/block/embed');

class AudioBlot extends BlockEmbed {
  static create(value: { src: string }) {
    const node: Element = super.create();

    if (value.src === 'loading') {
      node.classList.add('audio-uploading');
    } else {
      node.setAttribute('src', value.src);
    }

    node.setAttribute('type', 'audio/mp3');
    node.setAttribute('preload', 'none');
    node.toggleAttribute('controls');
    return node;
  }

  static blotName = 'audio';

  static tagName = 'audio';

  static value(node: Element) {
    return {
      src: node.getAttribute('src'),
      type: node.getAttribute('type'),
      autoplay: node.getAttribute('autoplay'),
      controls: node.getAttribute('controls'),
      controlsList: node.getAttribute('controlsList')
    };
  }
}

Quill.register(AudioBlot);
