import { css } from '@emotion/react';
import { spacing, Typography } from '@uniquegood/realworld-studio-design';
import React from 'react';

const IncardHeading = ({ title, subTitle }: { title: string; subTitle?: string }) => {
  return (
    <div css={IncardHeadingWrap}>
      <Typography as="strong" type="heading">
        {title}
      </Typography>
      {subTitle && (
        <Typography as="p" type="body" textColor="subdued">
          {subTitle}
        </Typography>
      )}
    </div>
  );
};

export default IncardHeading;

const IncardHeadingWrap = css`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.common.large};

  & > p {
    margin-left: ${spacing.common.small};
  }
`;
