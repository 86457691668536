import { css } from '@emotion/react';
import React from 'react';
import { Modal } from '@uniquegood/realworld-studio-design';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import ContainerHeader from '@/components/ContainerHeader';
import { useDidUpdateEffect, useModalState } from '@/hooks';
import { coreApi } from '@/api';
import { toast } from '@/utils';
import useChatbot from '../hooks/useChatbot';
import ChatBotModalContent, {
  FormData,
  SubmitType,
  transformToFormData
} from './ChatBotModalContent';

export default function Header() {
  const { appId, projectId, chatbotId } = useParams<AppParam>();

  const { item, mutate } = useChatbot();
  const { modal, openModal, closeModal } = useModalState();

  const defaultValues = item && transformToFormData(item);
  const { control, handleSubmit, formState, reset } = useForm<FormData>({ defaultValues });

  useDidUpdateEffect(() => {
    if (modal.open) {
      reset(defaultValues);
    }
  }, [modal.open]);

  const onSubmit = handleSubmit(async ({ name, profilePicture }) => {
    const body: SubmitType = {
      name,
      profilePictureId: profilePicture[0].id
    };

    await coreApi.put(`/apps/${appId}/projects/${projectId}/chatbots/${chatbotId}`, body);
    await mutate();

    closeModal();
    toast({ message: '챗봇을 저장했어요' });
  });

  function onClick() {
    openModal({
      title: '챗봇 정보 수정',
      size: 'medium',
      children: <ChatBotModalContent control={control} onSubmit={onSubmit} />,
      primaryAction: {
        icon: 'save_solid',
        content: '저장',
        htmlType: 'submit',
        form: 'modal'
      },
      secondaryAction: {
        content: '취소',
        onAction: closeModal
      }
    });
  }

  return (
    <>
      <ContainerHeader
        editButtonText="챗봇 정보 수정"
        cssStyle={ContainerHeaderLayout}
        onClick={onClick}
      >
        {item?.name}
      </ContainerHeader>
      <Modal
        {...modal}
        primaryAction={{ ...modal.primaryAction, loading: formState.isSubmitting }}
      />
    </>
  );
}

const ContainerHeaderLayout = css`
  min-width: 155px;
`;
