import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { coreApi, studioApi } from '@/api';
import { authApiWithToken } from '@/apis/auth';
import { getAccessToken } from '@/auth';

export default function LoginCallback() {
  const history = useHistory();

  const { code, redirect } = useMemo(() => {
    const searchParams = new URLSearchParams(history.location.search);

    return { code: searchParams.get('code'), redirect: searchParams.get('redirect') };
  }, [history.location.search]);

  useEffect(() => {
    (async () => {
      const { data: tokens } = await studioApi.get<LoginResponseModel>('/auth/loginCallback', {
        params: { code }
      });

      localStorage.setItem('refreshToken', tokens.refreshToken);
      localStorage.setItem('accessToken', tokens.accessToken);

      getAccessToken.clear();
      await Promise.all([
        (async () => {
          await authApiWithToken.apiAuthLoginPost();
        })(),
        (async () => {
          const [{ data: apps }, { data: me }] = await Promise.all([
            coreApi.get<RealWorldApp[]>('/apps'),
            coreApi.get<MyInfo>('/api/me')
          ]);

          const isAdmin = me.role === 'RealWorld.Roles.Admin';

          if (!isAdmin && apps.length > 0) {
            localStorage.setItem('defaultChannelID', apps[0].id);
          }
        })()
      ]);

      window.location.href = redirect ?? '/apps';
    })();
  }, []);

  return null;
}
