import { useEffect, useRef } from 'react';

const useDidUpdateEffect: typeof useEffect = (effect, deps) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
      return;
    }
    effect();
  }, deps);
};

export default useDidUpdateEffect;
