import Quill from 'quill';

const Parchment = Quill.import('parchment');

const lineHeights = [
  '0.1',
  '0.2',
  '0.3',
  '0.4',
  '0.5',
  '0.6',
  '0.7',
  '0.8',
  '0.9',
  '1.1',
  '1.2',
  '1.3',
  '1.4',
  '1.5',
  '1.6',
  '1.7',
  false,
  '1.9',
  '2.0',
  '2.1',
  '2.2',
  '2.3',
  '2.4',
  '2.5',
  '2.6',
  '2.7',
  '2.8'
];

const config = {
  scope: Parchment.Scope.BLOCK,
  whitelist: lineHeights as string[]
};

const LineHeightStyle = new Parchment.Attributor.Style('lineheight', 'line-height', config);

Quill.register(LineHeightStyle, true);

export { lineHeights };
