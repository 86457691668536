import lightPreviewOverlayImageSetting from './lightPreviewOverlayImageSetting.png';
import lightPreviewOverlayImageContent from './lightPreviewOverlayImageContent.png';
import darkPreviewOverlayImageSetting from './darkPreviewOverlayImageSetting.png';
import darkPreviewOverlayImageContent from './darkPreviewOverlayImageContent.png';

import tooltipDisplay from './tooltipDisplay.png';
import tooltipLock from './tooltipLock.png';

import helpImage from '@/assets/images/helpImage.png';
import tooltipLabel from './tooltipLabel.png';
import tooltipVisible from './tooltipVisible.png';
import tooltipLocked from './tooltipLocked.png';
import step1 from './step1.png';
import step2 from './step2.png';
import onBoardingQuest1 from './onBoardingQuest1.png';
import onBoardingQuest2 from './onBoardingQuest2.png';
import onBoardingQuest3 from './onBoardingQuest3.png';
import previewQuest from './previewQuest.png';

export const previewOverlayImage = {
  dark: { setting: darkPreviewOverlayImageSetting, content: darkPreviewOverlayImageContent },
  light: { setting: lightPreviewOverlayImageSetting, content: lightPreviewOverlayImageContent }
};

export const tooltipImage = {
  display: tooltipDisplay,
  lock: tooltipLock
};

export const onBoardingQuestImage = {
  step1: onBoardingQuest1,
  step2: onBoardingQuest2,
  step3: onBoardingQuest3,
  help: helpImage
};

export const onBoardingMissionImage = {
  step1,
  step2,
  help: helpImage
};

export const QuestModalImage = {
  preview: previewQuest,
  label: tooltipLabel,
  visible: tooltipVisible,
  locked: tooltipLocked
};
