import React, { useState } from 'react';

import { css } from '@emotion/react';
import { useParams } from 'react-router-dom';
import { color, spacing } from '@uniquegood/realworld-studio-design';
import FloatingNavigation, { FloatingNavigationProps } from '@components/FloatingNavigation';
import { coreApi } from '@/api';
import { Identity, PaddingZero } from '@/styles';
import { container650Style } from '@/styles/containerStyles';
import {
  useChatbot,
  useMission,
  useScenarios,
  useQuest,
  useItem,
  useDidUpdateEffect
} from '@/hooks';
import { toast } from '@/utils';
import BottomFloatingBar, { BOTTOM_FLOATING_BAR_HEIGHT } from '../BottomFloatingBar';
import { ActionEditor, ReactionEditor, FailureReactionEditor } from './components';
import { useActionHandler } from './hooks';
import { IAvailabel } from './types';
import NumberPreview from './components/NumberPreview';
import { BottomFloatingBarExtendStyle } from './styles';
import RouteModal from '../RouteModal';

const ActionHandler = ({ availabelType }: IAvailabel) => {
  const { appId, questId, projectId, chatbotId, sceneId } = useParams<AppParam>();

  const { scenarios } = useScenarios();
  const { missions } = useMission();
  const { items } = useItem();
  const { items: chatbots, mutate: mutateChatbots } = useChatbot();
  const { quests, mutate: mutateQuests } = useQuest();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { actionHandler, setActionHandler } = useActionHandler({
    availabelType
  });

  const reactionDefinitions = actionHandler.reactionDefinitions ?? [];
  const failureCommands = actionHandler.failureCommands ?? [];

  const [isDirty, setIsDirty] = useState(false);

  useDidUpdateEffect(() => {
    setIsDirty(false);
  }, [questId, sceneId]);

  const setReactionDefinitions: React.Dispatch<
    React.SetStateAction<ActionHandler['reactionDefinitions']>
  > = (setStateAction) => {
    setActionHandler((actionHandler) => {
      setIsDirty(true);
      if (typeof setStateAction === 'function')
        return {
          ...actionHandler,
          reactionDefinitions: setStateAction(actionHandler.reactionDefinitions ?? [])
        };

      return { ...actionHandler, reactionDefinitions: setStateAction };
    });
  };

  const setFailureCommands: React.Dispatch<React.SetStateAction<ActionHandler['failureCommands']>> =
    (setStateAction) => {
      setIsDirty(true);
      setActionHandler((actionHandler) => {
        if (typeof setStateAction === 'function')
          return {
            ...actionHandler,
            failureCommands: setStateAction(actionHandler.failureCommands ?? [])
          };

        return { ...actionHandler, failureCommands: setStateAction };
      });
    };

  const onSubmit = async () => {
    setIsSubmitting(true);
    const requestUrl =
      availabelType === 'quest'
        ? `/apps/${appId}/quests/${questId}/action`
        : `/apps/${appId}/projects/${projectId}/chatbots/${chatbotId}/scenes/${sceneId}/action`;

    const payload: Omit<ActionHandler, 'id'> = {
      ...actionHandler,
      failureCommands,
      reactionDefinitions
    };

    try {
      await coreApi.put(requestUrl, payload);

      setIsDirty(false);

      if (availabelType === 'chatbot') {
        await mutateChatbots();
      } else {
        await mutateQuests();
      }

      toast({ message: '저장 완료!' });
    } catch (error) {
      toast({ message: '서버 오류로 저장에 실패했어요' });
    } finally {
      setIsSubmitting(false);
    }
  };

  const leftElementWidth = availabelType === 'quest' ? '306px' : '186px';

  const navigationReactionItems = reactionDefinitions.map((_, index) => {
    const isSingleReaction = reactionDefinitions.length === 1;
    return {
      content: `조건별 리액션 ${!isSingleReaction ? index + 1 : ''}`,
      fragment: `reaction-definition-${index + 1}`,
      color: `${color.palette_secondary_blue_shade_02}`
    };
  });

  const floatingNavigationItems: FloatingNavigationProps['items'] = [
    {
      content: '플레이어 액션',
      fragment: 'player-action',
      color: `${color.palette_primary_purple_100}`
    },
    ...navigationReactionItems,
    {
      content: '오답 시 리액션',
      fragment: 'wrong-reaction',
      color: `${color.action_critical_default}`
    }
  ];

  return (
    <>
      <RouteModal when={isDirty} loading={isSubmitting} onSubmit={onSubmit} />
      {availabelType === 'quest' && (
        <FloatingNavigation
          items={floatingNavigationItems}
          cssStyle={FloatingNavigationBarStyle}
          offset={24}
        />
      )}
      <div
        css={[
          container650Style,
          PaddingZero,
          CardSpacing,
          // Hack
          availabelType === 'chatbot'
            ? css`
                && {
                  margin-bottom: calc(${BOTTOM_FLOATING_BAR_HEIGHT} + ${spacing.margin.xlarge2});
                }
              `
            : Identity
        ]}
      >
        {availabelType === 'quest' && (
          <NumberPreview availabelType={availabelType} type="action" id="player-action">
            <ActionEditor
              setIsDirty={setIsDirty}
              actionParameters={actionHandler?.actionParameters}
              actionType={actionHandler?.actionType}
              setActionHandler={setActionHandler}
              items={items}
            />
          </NumberPreview>
        )}

        <NumberPreview availabelType={availabelType} type="reaction" id="reaction-definition-1">
          <ReactionEditor
            params={reactionDefinitions}
            setParams={setReactionDefinitions}
            scenarios={scenarios}
            missions={missions}
            quests={quests}
            items={items}
            chatbots={chatbots}
            availabelType={availabelType}
          />
        </NumberPreview>

        <NumberPreview availabelType={availabelType} type="failReaction">
          <FailureReactionEditor
            commands={failureCommands}
            setCommands={setFailureCommands}
            scenarios={scenarios}
            missions={missions}
            quests={quests}
            items={items}
            chatbots={chatbots}
            availabelType={availabelType}
          />
        </NumberPreview>
      </div>

      <BottomFloatingBar
        loading={isSubmitting}
        disabled={isSubmitting}
        cssStyle={BottomFloatingBarExtendStyle({ leftElementWidth })}
        onSave={onSubmit}
      />
    </>
  );
};

export default ActionHandler;

const CardSpacing = css`
  & > div:not(:last-of-type) {
    margin-bottom: 48px;
  }
`;

const FloatingNavigationBarStyle = css`
  @media (max-width: 1150px) {
    display: none;
  }
  display: block;
  position: fixed;
  left: 1054px;
  width: 160px;
`;
