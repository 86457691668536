import { useParams } from 'react-router-dom';
import { useRequest } from '@/hooks';

export default function useScenarios() {
  const { appId, projectId } = useParams<AppParam>();

  const {
    data: scenarios,
    isValidating,
    mutate
  } = useRequest<Scenario[]>(`/apps/${appId}/projects/${projectId}/scenarios`);

  return { scenarios, isValidating, mutate };
}
