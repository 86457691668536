import { useLayoutEffect, useState } from 'react';

interface IWindowSize {
  width: number;
  height: number;
}

const getWindowSize = () => {
  const windowSize: IWindowSize = { width: window.innerWidth, height: window.innerHeight };

  return windowSize;
};

export default function useWindowSize(duration?: number): IWindowSize {
  const [windowSize, setWindowSize] = useState<IWindowSize>(getWindowSize);

  useLayoutEffect(() => {
    let timeoutId: number;

    const handleResize = () => {
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        setWindowSize(getWindowSize());
      }, duration ?? 150) as unknown as number;
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}
