import { css } from '@emotion/react';
import IntroduceImage from '../assets/introduce.jpg';

export default function HowToUseImageTooltip() {
  return (
    <div css={containerStyle}>
      <h3 css={titleStyle}>소개 이미지</h3>
      <div css={contentStyle}>
        <ul css={listStyle}>
          <li>이미지는 JPG, PNG 형식만 업로드할 수 있어요.</li>
          <li>이미지 크기는 가로 750px, 세로는 최대 3000px로 제작해주세요.</li>
          <li>이미지 용량은 최대 4MB까지 올릴 수 있어요.</li>
          <li>
            <a href="https://imagecompressor.com/ko/" target="blank" css={linkStyle}>
              이미지 최적화 도구
            </a>
            로 이미지 용량을 줄이면 더욱 빠르게 이미지를 불러올 수 있어요!
          </li>
          <li>이미지는 업로드 순으로 보여지며, 드래그로 순서를 변경할 수 있습니다.</li>
        </ul>
        <img src={IntroduceImage} alt="이미지 업로드 설명 이미지" css={imageStyle} />
      </div>
    </div>
  );
}

const containerStyle = css`
  width: 288px;
  padding: 8px 0%;
  cursor: default;
`;

const titleStyle = css`
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 8px;
`;

const contentStyle = css`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

const listStyle = css`
  list-style-type: disc;
  padding-inline-start: 16px;

  li::marker {
    font-size: 10px;
  }
`;

const imageStyle = css`
  width: 100%;
  margin-top: 16px;
`;

const linkStyle = css`
  color: inherit;
  text-decoration: underline;
  text-underline-offset: 3px;
`;
