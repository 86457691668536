import useSWRImmutable from 'swr/immutable';

import { SWRConfiguration, SWRResponse } from 'swr';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { coreApi } from '@/api';

interface Return<Data, Error>
  extends Omit<SWRResponse<Data, AxiosError<Error>>, 'data' | 'mutate'> {
  data?: Data;
}

export type Config<Data = unknown, Error = unknown> = SWRConfiguration<Data, AxiosError<Error>>;

export default function useRequestImmutable<Data = unknown, Error = unknown>(
  route: string,
  swrConfig: Config<Data, Error> = {},
  axiosConfig?: AxiosRequestConfig,
  axiosInstance = coreApi
): Return<Data, Error> {
  const { data, error, isValidating } = useSWRImmutable<Data, AxiosError<Error>>(
    route,
    (route: string) => axiosInstance.get<Data>(route, axiosConfig).then(({ data }) => data),
    swrConfig
  );

  return {
    data,
    error,
    isValidating
  };
}
