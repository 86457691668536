import { FormGroup, Input } from '@uniquegood/realworld-studio-design';
import { Control, Controller, UseFormClearErrors, UseFormSetError } from 'react-hook-form';
import { useParams, useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { useRequest } from '@/hooks';
import { FormData } from '../Files';

interface CreateDirectoryFormProps {
  // eslint-disable-next-line @typescript-eslint/ban-types
  control: Control<FormData, object>;
  setError: UseFormSetError<FormData>;
  clearErrors: UseFormClearErrors<FormData>;
  onSubmit(e: unknown): void;
}

export default function CreateDirectoryForm({
  control,
  setError,
  clearErrors,
  onSubmit
}: CreateDirectoryFormProps) {
  const { appId, projectId } = useParams<AppParam>();

  const location = useLocation();
  const currentPageRoute = `/apps/${appId}/projects/${projectId}/files`;

  const { data } = useRequest<FileResponseModel[]>(`${currentPageRoute}${location.search}`);
  const ref = useRef<Element>(null);

  function isAlreadyHaveDir(dirname: string) {
    if (!data) throw Error('현재 경로의 파일을 찾지 못했습니다');

    return data.findIndex((file) => file.fileName === dirname) !== -1;
  }

  useEffect(() => {
    if (ref.current && ref.current instanceof HTMLInputElement) {
      ref.current.focus();
    }
  }, [ref.current]);

  return (
    <form id="modal" onSubmit={onSubmit}>
      <Controller
        control={control}
        name="name"
        rules={{ required: '필수 입력 항목이에요' }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <FormGroup
            label="폴더 이름"
            id="single-input-field"
            requiredIndicator
            helpText="한글, 영문, 숫자, -, _ 입력이 가능해요"
            errorText={error?.message}
          >
            <Input
              id="single-input-field"
              value={value}
              onChange={(value) => {
                const replaced = value.replace(invalidFolderNamePattern, '');
                onChange(replaced);

                if (isAlreadyHaveDir(replaced)) {
                  setError('name', { type: 'value', message: '이미 같은 이름의 폴더가 있어요' });
                  return;
                }
                clearErrors('name');
              }}
              ref={ref}
            />
          </FormGroup>
        )}
      />
    </form>
  );
}

const invalidFolderNamePattern = /[^ㄱ-ㅎ가-힣\w-_]/g;
