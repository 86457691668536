import { useEffect, useMemo } from 'react';
import { color, Divider, spacing } from '@uniquegood/realworld-studio-design';
import { css } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import Footer from '@pages/home/components/Footer';
import { PageViewEventName, track } from '@/track';

import { onMobile } from '@/styles/responsive';

import LoadingSpinner from '@/components/LoadingSpinner';
import { useIsAdminSelector, useIsOwner } from '@/hooks';
import { container960style } from '@/styles/containerStyles';
import { centeredStyle } from '@/components/containers';
import BeltBanner from '@/components/BeltBanner';
import Header from './components/Header';
import ProjectList from './sections/ProjectList';
import Feeds from './sections/Feeds';
import Community from './sections/Community';

type SelectedTabItem = 'games' | 'feeds' | 'community' | 'statistics';

const selectedTabItems: SelectedTabItem[] = ['games', 'feeds', 'community'];

const selectedContentToLocalString: Record<SelectedTabItem, string> = {
  games: '만든 게임',
  feeds: '피드',
  community: '커뮤니티',
  statistics: '통계'
};

function Home() {
  const { data: isAdmin } = useIsAdminSelector();
  const { data: isOwner } = useIsOwner();

  const history = useHistory();

  const selectedTabItem = useMemo(
    () => (new URLSearchParams(history.location.search).get('tab') ?? 'games') as SelectedTabItem,
    [history.location.search]
  );

  useEffect(() => {
    switch (selectedTabItem) {
      case 'games':
        track.onPageView({ pageViewEventName: PageViewEventName.view_channel_createdgames });
        break;
      case 'feeds':
        track.onPageView({ pageViewEventName: PageViewEventName.view_channel_feed });
        break;
      case 'community':
        track.onPageView({ pageViewEventName: PageViewEventName.view_community });
        break;
      default:
        console.error('unknown tab item', selectedTabItem);
    }
  }, [selectedTabItem]);

  function setSelectedTabItem(tab: SelectedTabItem) {
    history.push({ search: new URLSearchParams({ tab }).toString() });
  }

  useEffect(() => {
    const tab = new URLSearchParams(history.location.search).get('tab');

    if (tab === null) {
      history.replace({ search: new URLSearchParams({ tab: 'games' }).toString() });
    }
  }, [history.location.search]);

  if (isAdmin === undefined || isOwner === undefined) return <LoadingSpinner />;

  const selectedContent: Record<SelectedTabItem, JSX.Element> = {
    games: <ProjectList />,
    feeds: <Feeds />,
    community: <Community />,
    statistics: <></>
  };

  return (
    <>
      <BeltBanner
        backgroundColor={color.palette_primary_purple_100}
        href="https://studio-helpcenter.oopy.io/10a18b2e-ed60-80b1-87b2-e24902b8e455"
      >
        플랫폼 사용 목적에 따른 요금제 안내
      </BeltBanner>
      <div css={[ContainerLayout, container960style, centeredStyle]}>
        <Header showSettingButton={isOwner || isAdmin} />
        <div role="group" css={tabNavigationStyle}>
          {selectedTabItems.map((item) => (
            <button
              key={item}
              type="button"
              aria-pressed={selectedTabItem === item}
              onClick={() => setSelectedTabItem(item)}
            >
              {selectedContentToLocalString[item]}
            </button>
          ))}
        </div>
        <Divider cssStyle={dividerSpacing} />
        {selectedContent[selectedTabItem]}
      </div>
      <Footer />
    </>
  );
}

export default Home;

const ContainerLayout = css`
  width: 100%;
  margin-top: 16px;
  margin-bottom: 80px;

  flex: 1;

  ${onMobile} {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

const tabNavigationStyle = css`
  display: flex;

  > button {
    cursor: pointer;
    font-size: 26px;
    line-height: 32px;
    font-weight: bold;

    border: none;
    background-color: transparent;

    :not(:last-of-type) {
      margin-right: ${spacing.margin.large};
    }

    color: ${color.palette_gray_tint_04};

    &[aria-pressed='true'] {
      cursor: default;
      color: ${color.palette_primary_purple_100};
    }
  }
`;

const dividerSpacing = css`
  margin-top: ${spacing.margin.xlarge2};
  margin-bottom: 40px;
`;
