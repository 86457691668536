import { toast as originalToast, ToastItem, ModalProps } from '@uniquegood/realworld-studio-design';
import axios, { AxiosError } from 'axios';
import React from 'react';

import type { DOMAttributes } from 'react';
import type { UseFormReturn } from 'react-hook-form';

import type { SerializedStyles } from '@emotion/react';
import { BadgeProps } from '@uniquegood/realworld-studio-design/dist/types/atoms/Badge/Badge';
import { BOTTOM_FLOATING_BAR_HEIGHT } from '@/components/BottomFloatingBar';
import { unsplashAccessKey } from './env';

type Falsy = boolean | undefined | null | 0;

export function classNames(...classes: (string | Falsy)[]) {
  return classes.filter(Boolean).join(' ');
}

export function getUnsplashAccessKey() {
  return unsplashAccessKey;
}

const commonServerErrorMessage = '알 수 없는 서버 오류가 발생했습니다.';

export function emitServerErrorToast(error?: unknown) {
  toast({
    message: axios.isAxiosError(error)
      ? (error as AxiosError<{ message: string }>).response?.data.message ??
        error.message ??
        commonServerErrorMessage
      : commonServerErrorMessage,
    type: 'error'
  });
}

export function numberWithCommas(value: number) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function inputNumberWithCommaFormatter(value?: string) {
  if (value === '0') return '0';

  return (
    value
      ?.replace(/[^0-9]/g, '')
      .replace(/^0+/, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',') ?? '0'
  );
}

export function toast({ bottom = BOTTOM_FLOATING_BAR_HEIGHT, ...rest }: ToastItem) {
  originalToast({
    bottom,
    ...rest
  });
}

export function makeFormData(body: Record<string, string | Blob>) {
  const data = new FormData();

  for (const [key, value] of Object.entries(body)) {
    data.append(key, value);
  }

  return data;
}

export const invalidFieldBoilerplate = (invalid: boolean) =>
  invalid ? '필수 항목이에요' : undefined;

// eslint-disable-next-line consistent-return
export const keyPressHandler =
  (fn: (event: React.KeyboardEvent) => void) => (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      fn(event);
    }
  };

export function replaceItem<T>(array: T[], targetIndex: number, replaceData: T) {
  return [...array.slice(0, targetIndex), replaceData, ...array.slice(targetIndex + 1)];
}

export function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
  const result = Array.from(list);
  const [reorderedItem] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, reorderedItem);

  return result;
}

// Hack: @types/utils.d.ts 에서 읽어드리는데에 실패해서 이렇게 놓음
export type ModalContentProps<TFieldValues extends Record<string, any>> = Pick<
  DOMAttributes<HTMLFormElement>,
  'onSubmit'
> &
  Pick<UseFormReturn<TFieldValues>, 'control'>;

export interface CssStyle {
  cssStyle?: SerializedStyles | SerializedStyles[];
}

export const identity = <T>(arg: T) => arg;

export const helpLink = {
  community: 'https://discord.com/invite/mjJkRDUpD5',
  notion: {
    helpCenter: 'https://rwd.to/realworldstudio-helpcenter',
    guideline: 'https://rwd.to/realworldstudio-guideline'
  },
  bizPlan: {
    notice: 'https://rwd.to/notice_biztype',
    apply: 'https://rwd.to/apply_bizplan',
    faq: 'https://rwd.to/faq_biztype'
  }
};

export const emailAddressPattern =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

export type ModalContent = { title: ModalProps['title']; message: ModalProps['children'] };

type CheckFileSizeProps =
  | {
      files: File[] | FileList;
      file?: undefined;
      maxFileSize?: number;
    }
  | { files?: undefined; file: File; maxFileSize?: number };

/**
 * @description: 지정된 사이즈를 초과한 파일이 하나라도 있을 경우 true와 modal message를 반환합니다.
 */
export function checkFileSize({
  file,
  files,
  maxFileSize = maxDefaultFileSize
}: CheckFileSizeProps) {
  if (file) return file.size > maxFileSize;

  if (files instanceof FileList)
    return [...files].findIndex((file) => file.size > maxFileSize) !== -1;

  // Hack: error TS2532: Object is possibly 'undefined'. ???
  if (!files) throw Error();
  return files.findIndex((file) => file.size > maxFileSize) !== -1;
}

export const maxDefaultFileSize = 10_485_760;

export const badgeProps: Record<Project['status'], BadgeProps> = {
  Unreleased: { children: '미공개', type: 'gray' },
  Preparing: { children: '준비중', type: 'blue' },
  Released: { children: '공개됨', type: 'purple' }
};

export const debounceFn = (callback: (...args: any) => unknown, delay: number) => {
  let timer: ReturnType<typeof setTimeout>;
  return (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => callback(...args), delay);
  };
};

export const throttleFn = (callback: (...args: any) => unknown, delay: number) => {
  let isWaiting = false;
  return (...args: any) => {
    if (!isWaiting) {
      callback(...args);
      isWaiting = true;

      setTimeout(() => {
        isWaiting = false;
      }, delay);
    }
  };
};
