import { FormGroup, Input, Select } from '@uniquegood/realworld-studio-design';
import { Control, Controller } from 'react-hook-form';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FormData, getDirQuery, ModifyFileFormFields } from '../Files';
import { useRequest } from '@/hooks';
import { pathResolve } from '../utils';

export interface ModifyFileFormProps extends Pick<ModifyFileFormFields, 'type'> {
  // eslint-disable-next-line @typescript-eslint/ban-types
  control: Control<FormData, object>;
  onSubmit(e: unknown): void;
}

export default function FileModifyForm({ control, onSubmit, type }: ModifyFileFormProps) {
  const ref = useRef<Element>(null);
  const { appId, projectId } = useParams<AppParam>();

  const location = useLocation();

  const { data: files } = useRequest<FileResponseModel[]>(
    `/apps/${appId}/projects/${projectId}/files${location.search}`
  );

  const [selectedFolder, setSelectedFolder] = useState<string>();

  const directory = useMemo(() => getDirQuery(location.search), [location.search]);
  const directoryDepth = directory === '' ? 0 : directory.match(/\//g)?.length ?? 0 + 1;
  const parentPaths = directoryDepth > 0 ? [{ value: '../', label: '📂 상위 폴더' }] : [];

  const folders = type === 'virtualDirectory' ? files?.filter((file) => file.isDirectory) : [];
  const folderNames = folders?.map((folder) => ({
    value: folder.fileName,
    label: folder.fileName
  }));

  useEffect(() => {
    if (ref.current && ref.current instanceof HTMLInputElement) {
      ref.current.focus();
    }
  }, [ref.current]);

  return (
    <form id="modal" onSubmit={onSubmit}>
      <Controller
        control={control}
        name={type}
        rules={{
          validate: (value) => (value === directory ? '이동할 폴더를 선택해주세요' : true)
        }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <FormGroup
            label={type === 'metadata' ? '설명' : '폴더 선택'}
            id="single-input-field"
            requiredIndicator={type === 'virtualDirectory'}
            errorText={error?.message}
          >
            {type === 'virtualDirectory' && folderNames ? (
              <Select
                placeholder="이동할 폴더"
                value={selectedFolder}
                options={[...parentPaths, ...folderNames]}
                onChange={(folderName) => {
                  setSelectedFolder(folderName);
                  onChange(pathResolve(directory, folderName));
                }}
              />
            ) : (
              <Input ref={ref} id="single-input-field" value={value} onChange={onChange} />
            )}
          </FormGroup>
        )}
      />
    </form>
  );
}
