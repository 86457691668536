import { css } from '@emotion/react';

import { color, Icon } from '@uniquegood/realworld-studio-design';

interface AddButtonProps {
  onClick(): void;
}

export default function AddButton({ onClick }: AddButtonProps) {
  return (
    <button css={ButtonStyle} aria-label="퍼소나 만들기" type="button" onClick={onClick}>
      <Icon icon="plus_solid" size="24px" />
    </button>
  );
}

const ButtonStyle = css`
  background-color: ${color.action_gray_default};

  :hover {
    background-color: ${color.action_gray_hovered};
  }

  :active {
    background-color: ${color.action_gray_pressed};
  }

  :focus-visible {
    outline: 4px solid ${color.interactive_primary_subdued};
  }

  cursor: pointer;

  border: none;
  border-radius: 100%;
  width: 72px;
  height: 72px;
`;
