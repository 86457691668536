import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { css } from '@emotion/react';
import {
  Banner,
  Button,
  Card,
  color,
  FormGroup,
  Icon,
  Input,
  Modal,
  spacing,
  toast,
  typography
} from '@uniquegood/realworld-studio-design';

import { set } from 'react-hook-form';
import { emailAddressPattern } from '@/utils';
import { Container650 } from '@/components/containers';
import { TextAlignCenter } from '@/styles';
import { onMobile } from '@/styles/responsive';
import useValidatableInput from '@/hooks/useValidatableInput';
import { useRequest } from '@/hooks';
import { logout, studioApi } from '@/api';
import useEmailVerification from './hooks/useEmailVerification';

import AuthenticationCard from './components/AuthenticationCard';
import { ClickEventName, InputEventName, PageViewEventName, track } from '@/track';
import { OAUTH_LOGIN_URI } from '@/auth';
import usePassAuth from './hooks/usePassAuth';
import { NiceAuthError } from '@/apis/account';
import ProgressTab from './components/ProgressTab';
import DagooImage from './assets/phone.svg';
import DagooSpeakerImage from './assets/speaker.svg';

const padNumber = (value: number, size: number) => {
  const numString = String(value);
  return '0'.repeat(size - numString.length) + numString;
};

const formatTimeSpan = (ms: number) => {
  let time = ms / 1000;
  const seconds = Math.floor(time) % 60;
  time /= 60;
  const minutes = Math.floor(time) % 60;
  time /= 60;
  const hours = Math.floor(time) % 24;

  return `${hours !== 0 ? `${padNumber(hours, 2)}:` : ''}${padNumber(minutes, 2)}:${padNumber(
    seconds,
    2
  )}`;
};

const refineEmail = (val: string) => val.replace(/\s/g, '');
const validateEmail = (val: string) => emailAddressPattern.test(val);

export default function VerifyContactPage() {
  const history = useHistory();
  useEffect(() => {
    track.onPageView({ pageViewEventName: PageViewEventName.view_verifycontact });
  }, []);

  const { data: onboardingStepStatus, mutate } = useRequest<OnboardingStepStatus>(
    '/api/creators/me',
    {},
    {},
    studioApi
  );
  const pageStep = getPageStep(onboardingStepStatus);

  const [email, isValidEmail, setEmail] = useValidatableInput('', validateEmail, refineEmail);

  const [showCodeErrorModal, setShowCodeErrorModal] = useState(false);
  const [showEmailValidationErrorModal, setShowEmailValidationErrorModal] = useState(false);
  const [showAlreadyVerifiedModal, setShowAlreadyVerifiedModal] = useState(false);
  const [showCommonErrorModal, setShowCommonErrorModal] = useState(false);
  const [showAlreadyPhoneCertifiedUserModal, setShowAlreadyPhoneCertifiedUserModal] =
    useState(false);

  const { data: me } = useRequest<MyInfo>('/api/me');

  const { openNiceAuth } = usePassAuth({
    onSuccess: () => {
      toast({
        type: 'default',
        message: '전화번호를 인증했어요!'
      });
      mutate();
    },
    onFail: (errorTitle: string) => {
      // NotFoundPhoneCertification
      // 토큰 발행 시 토큰 ID가 발급되고 그 정보를 저장하는데 콜백에서 그 정보를 찾을 수 없을 때 나오는 에러
      // InternalServerError
      // nice와의 통신 실패 또는 알 수 없는 에러
      // NotSupportedType
      // returnPath 가 지원하지 않는 path 일 때 (스튜디오에서는 받을 수 없는 에러)
      // NiceCertificationFailed
      // nice 본인인증 실패
      // AlreadyExistPhoneCertification
      // 이미 인증된 번호 일 때
      // AlreadyPhoneCertifiedUser
      // 이미 인증된 사용자 일 때
      // InvalidPhoneNumber
      // ^010[0-9]{8}\$  해당 정규식에 어긋날 때
      switch (errorTitle) {
        case NiceAuthError.AlreadyExistPhoneCertification:
          setShowAlreadyVerifiedModal(true);
          break;
        case NiceAuthError.AlreadyPhoneCertifiedUser:
          setShowAlreadyPhoneCertifiedUserModal(true);
          break;
        case NiceAuthError.NotFoundPhoneCertification:
        case NiceAuthError.InternalServerError:
        case NiceAuthError.NiceCertificationFailed:
        case NiceAuthError.InvalidPhoneNumber:
          setShowCommonErrorModal(true);
          break;
        default:
          setShowCodeErrorModal(true);
          break;
      }
    }
  });

  const onVerifyEmailResult = useCallback((verified: boolean) => {
    if (verified) {
      toast({
        type: 'default',
        message: '이메일을 인증했어요!'
      });
      mutate();
    } else {
      setShowEmailValidationErrorModal(true);
    }
  }, []);

  const [sendEmail, verifyEmail, isBusySendEmail, emailSent, emailVerifyTimer] =
    useEmailVerification(onVerifyEmailResult);

  const onSendEmail = useCallback(() => {
    sendEmail(email);
  }, [sendEmail, email]);

  const onVerifyImmediateEmail = useCallback(() => verifyEmail(email), [verifyEmail, email]);

  useEffect(() => {
    const email = me?.credentials?.[0].provider === 'Self' && me.credentials[0].providerId;
    if (email) {
      setEmail(email);
    }
  }, [me?.credentials]);

  const disabledEmailAddressSubmit = !isValidEmail || (emailSent && emailVerifyTimer > 0);

  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
      `}
    >
      <Container650
        centered
        cssStyle={css`
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          margin-left: 0;
          margin-right: 0;
        `}
      >
        <main css={verifyContactPageStyle}>
          {pageStep === 'VERIFY_PHONE' && (
            <>
              {!onboardingStepStatus?.emailVerified && <ProgressTab step={pageStep || ''} />}
              <div css={contentContainerStyle}>
                <div css={dagooImageWrapperStyle}>
                  <img src={DagooImage} alt="다구 이미지" />
                </div>
                <h1>본인인증을 진행해 주세요</h1>
                <p>
                  {onboardingStepStatus?.emailVerified && (
                    <>
                      2024.04.18 부터
                      <br />
                    </>
                  )}
                  리얼월드는 스튜디오를 통해 제작되는 콘텐츠의 원활한 관리를 위해
                  <br /> 크리에이터에게 본인 인증을 받고 있어요.
                </p>
                <div css={buttonWrapperStyle}>
                  <Button
                    onClick={openNiceAuth}
                    type="primary"
                    cssStyle={css`
                      padding: 12px 24px;
                      border-radius: 999px;
                    `}
                  >
                    본인 인증하기
                  </Button>
                </div>
              </div>
            </>
          )}
          {pageStep === 'VERIFY_EMAIL' && (
            <>
              {!onboardingStepStatus?.emailVerified && <ProgressTab step={pageStep || ''} />}
              <div css={contentContainerStyle}>
                <div css={dagooImageWrapperStyle}>
                  <img src={DagooSpeakerImage} alt="다구 이미지" />
                </div>
                <h1>이메일을 인증해 주세요</h1>
                <p>
                  제작하신 게임에 대한 문의 및 스튜디오 업데이트 소식을 전달받을
                  <br /> 이메일을 입력해 주세요.
                </p>
                <Card
                  header={{
                    content: '이메일 인증',
                    prefix: <Icon icon="envelope_solid" size="20px" />
                  }}
                  cssStyle={[
                    LargeGapStyle,
                    css`
                      width: 100%;
                      max-width: 460px;
                    `
                  ]}
                >
                  <FormGroup
                    label="이메일 입력"
                    cssStyle={emailSent ? LargeGapStyle : undefined}
                    helpText={
                      emailSent && emailVerifyTimer > 0
                        ? `${formatTimeSpan(emailVerifyTimer)}후에 재전송할 수 있어요`
                        : undefined
                    }
                    errorText={!isValidEmail ? '올바른 형식의 이메일을 입력해주세요' : undefined}
                  >
                    <form
                      className="input-group"
                      onSubmit={(e) => {
                        e.preventDefault();
                        if (!disabledEmailAddressSubmit) {
                          onSendEmail();
                        }
                      }}
                    >
                      <Input
                        cssStyle={ContactInputStyle}
                        placeholder="example@email.com"
                        value={email}
                        type="email"
                        onChange={(value) => {
                          setEmail(value);
                          track.onInputThrottled({
                            inputEventName: InputEventName.input_verifycontactpage_email
                          });
                        }}
                      />
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={disabledEmailAddressSubmit}
                        loading={isBusySendEmail}
                      >
                        {emailSent ? '인증 메일 재전송' : '인증 메일 받기'}
                      </Button>
                    </form>
                  </FormGroup>
                  {emailSent && (
                    <Banner
                      type="default"
                      iconComponent={<Icon icon="spinner_solid" spinning />}
                      footer={
                        <Button
                          onClick={() => {
                            onVerifyImmediateEmail();
                            track.onClick({
                              clickEventName:
                                ClickEventName.click_verifycontactpage_button_checkstatus
                            });
                          }}
                        >
                          인증 상태 확인
                        </Button>
                      }
                    >
                      인증 링크가 이메일로 전송되었습니다. 메일함(혹은 스팸메일함)을 확인해주세요.
                    </Banner>
                  )}
                </Card>
              </div>
            </>
          )}
          {pageStep !== 'VERIFY_PHONE' && (
            <div css={TextAlignCenter}>
              <Button
                type="primary"
                size="large"
                disabled={pageStep !== 'ALL_VERIFIED'}
                onClick={() => {
                  track.onClick({
                    clickEventName:
                      ClickEventName.click_verifycontactpage_button_registercreatercomplete
                  });
                  history.push('/createApp');
                }}
              >
                크리에이터 등록 완료!
              </Button>
            </div>
          )}
          {/* 
          {pageStep === 'VERIFY_PHONE' && (
            <Card
              header={{
                content: '전화번호 인증',
                prefix: <Icon icon="phone_alt_solid" size="20px" />
              }}
              cssStyle={LargeGapStyle}
            >
              <Button fullWidth onClick={openNiceAuth}>
                인증하기
              </Button>
            </Card>
          )}
          {pageStep === 'VERIFY_EMAIL' && (
            <Banner type="success" header="전화번호 인증 완료" cssStyle={LargeGapStyle}>
              이제 이메일을 인증 해주세요.
            </Banner>
          )}
          {pageStep === 'ALL_VERIFIED' && (
            <Banner type="success" header="전화번호 인증 완료" cssStyle={LargeGapStyle}>
              {' '}
            </Banner>
          )} */}

          {/* {onboardingStepStatus?.emailVerified ? undefined : (
            <>
              {pageStep === 'VERIFY_PHONE' && (
                <AuthenticationCard icon="envelope_solid" cssStyle={LargeGapStyle}>
                  이메일 인증
                </AuthenticationCard>
              )}
              {pageStep === 'VERIFY_EMAIL' && (
                <Card
                  header={{
                    content: '이메일 인증',
                    prefix: <Icon icon="envelope_solid" size="20px" />
                  }}
                  cssStyle={LargeGapStyle}
                >
                  <FormGroup
                    label="이메일 입력"
                    cssStyle={emailSent ? LargeGapStyle : undefined}
                    helpText={
                      emailSent && emailVerifyTimer > 0
                        ? `${formatTimeSpan(emailVerifyTimer)}후에 재전송할 수 있어요`
                        : undefined
                    }
                    errorText={!isValidEmail ? '올바른 형식의 이메일을 입력해주세요' : undefined}
                  >
                    <form
                      className="input-group"
                      onSubmit={(e) => {
                        e.preventDefault();
                        if (!disabledEmailAddressSubmit) {
                          onSendEmail();
                        }
                      }}
                    >
                      <Input
                        cssStyle={ContactInputStyle}
                        placeholder="example@email.com"
                        value={email}
                        type="email"
                        onChange={(value) => {
                          setEmail(value);
                          track.onInputThrottled({
                            inputEventName: InputEventName.input_verifycontactpage_email
                          });
                        }}
                      />
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={disabledEmailAddressSubmit}
                        loading={isBusySendEmail}
                      >
                        {emailSent ? '인증 메일 재전송' : '인증 메일 받기'}
                      </Button>
                    </form>
                  </FormGroup>
                  {emailSent && (
                    <Banner
                      type="default"
                      iconComponent={<Icon icon="spinner_solid" spinning />}
                      footer={
                        <Button
                          onClick={() => {
                            onVerifyImmediateEmail();
                            track.onClick({
                              clickEventName:
                                ClickEventName.click_verifycontactpage_button_checkstatus
                            });
                          }}
                        >
                          인증 상태 확인
                        </Button>
                      }
                    >
                      인증 링크가 이메일로 전송되었습니다. 메일함(혹은 스팸메일함)을 확인해주세요.
                    </Banner>
                  )}
                </Card>
              )} */}
          {/* {pageStep === 'ALL_VERIFIED' && (
                <Banner type="success" header="이메일 인증 완료" cssStyle={LargeGapStyle}>
                  {' '}
                </Banner>
              )} */}
          {/* </>
          )} */}
        </main>
      </Container650>
      <Modal
        hasHeader
        onClose={() => setShowCodeErrorModal(false)}
        open={showCodeErrorModal}
        cssStyle={TextAlignCenter}
        title="전화번호 인증에 실패했어요"
        primaryAction={{
          content: '확인',
          onAction: () => setShowCodeErrorModal(false),
          type: 'primary'
        }}
      >
        <Icon
          icon="times_circle_regular"
          color={color.interactive_critical_default}
          size="83px"
          cssStyle={SmallGapStyle}
        />
        문자로 전송된 인증번호를 정확하게 입력해 주세요
      </Modal>
      <Modal
        hasHeader
        cssStyle={TextAlignCenter}
        onClose={() => setShowEmailValidationErrorModal(false)}
        open={showEmailValidationErrorModal}
        title="이메일 인증에 실패했어요"
        primaryAction={{
          content: '확인',
          onAction: () => setShowEmailValidationErrorModal(false),
          type: 'primary'
        }}
      >
        <Icon
          icon="times_circle_regular"
          color={color.interactive_critical_default}
          size="83px"
          cssStyle={SmallGapStyle}
        />
        <div css={TextAlignCenter}>
          이메일에 포함된 링크를 눌러 해 주세요.
          <br />
          메일이 오지 않았을 시 스팸메일함을 확인해주세요.
        </div>
      </Modal>
      <Modal
        hasHeader
        cssStyle={TextAlignCenter}
        onClose={() => setShowAlreadyVerifiedModal(false)}
        open={showAlreadyVerifiedModal}
        title="이미 등록된 전화번호예요."
        primaryAction={{
          content: '로그인 페이지로 이동',
          onAction: async () => {
            setShowAlreadyVerifiedModal(false);

            await logout();

            window.location.href = OAUTH_LOGIN_URI;
          },
          type: 'primary'
        }}
      >
        <Icon
          icon="times_circle_regular"
          color={color.interactive_critical_default}
          size="83px"
          cssStyle={SmallGapStyle}
        />
        <div css={TextAlignCenter}>등록된 전화번호로 가입한 계정으로 로그인해 주세요.</div>
      </Modal>
      <Modal
        hasHeader
        cssStyle={TextAlignCenter}
        onClose={() => setShowAlreadyPhoneCertifiedUserModal(false)}
        open={showAlreadyPhoneCertifiedUserModal}
        title="이미 본인인증 완료된 계정이에요."
        primaryAction={{
          content: '로그인 페이지로 이동',
          onAction: async () => {
            setShowAlreadyPhoneCertifiedUserModal(false);

            await logout();

            window.location.href = OAUTH_LOGIN_URI;
          },
          type: 'primary'
        }}
      >
        <Icon
          icon="times_circle_regular"
          color={color.interactive_critical_default}
          size="83px"
          cssStyle={SmallGapStyle}
        />
        <div css={TextAlignCenter}>로그인을 진행해주세요.</div>
      </Modal>
      <Modal
        hasHeader
        cssStyle={TextAlignCenter}
        onClose={() => setShowCommonErrorModal(false)}
        open={showCommonErrorModal}
        title="알 수 없는 오류가 발생했어요."
        primaryAction={{
          content: '다시 시도하기',
          onAction: async () => {
            setShowCommonErrorModal(false);
          },
          type: 'primary'
        }}
      >
        <Icon
          icon="times_circle_regular"
          color={color.interactive_critical_default}
          size="83px"
          cssStyle={SmallGapStyle}
        />
        <div css={TextAlignCenter}>등록된 전화번호로 가입한 계정으로 로그인해 주세요.</div>
      </Modal>
    </div>
  );
}

function getPageStep(onboardingStepStatus?: OnboardingStepStatus) {
  if (!onboardingStepStatus) return undefined;

  if (!onboardingStepStatus.identityVerified) return 'VERIFY_PHONE';

  if (!onboardingStepStatus.emailVerified) return 'VERIFY_EMAIL';

  return 'ALL_VERIFIED';
}

const verifyContactPageStyle = css`
  width: 100%;
  max-width: 650px;
  margin-top: 80px;

  h1 {
    text-align: center;
    font-size: ${typography.size.medium};
    font-weight: ${typography.weight.bold};

    margin-bottom: 16px;
  }

  p {
    margin-bottom: 40px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
  }

  .input-group {
    display: flex;
  }

  button {
    flex-shrink: 0;
  }
`;

const contentContainerStyle = css`
  width: 100%;
  max-width: 460px;
  margin: 150px auto 40px;
`;

const SmallGapStyle = css`
  margin-bottom: ${spacing.margin.small};
`;

const LargeGapStyle = css`
  margin-bottom: ${spacing.margin.xlarge2};
`;

const ContactInputStyle = css`
  min-width: none;
  flex-grow: 1;
  margin-right: ${spacing.margin.small};
`;
const dagooImageWrapperStyle = css`
  position: relative;
  width: 160px;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;

  & > img {
    width: 100%;
    height: 100%;
    margin-bottom: 16px;
    object-fit: cover;
  }
`;

const buttonWrapperStyle = css`
  text-align: center;
`;
