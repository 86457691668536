import React, { memo } from 'react';

import { spacing, textStyleButton, Typography } from '@uniquegood/realworld-studio-design';
import { css, SerializedStyles } from '@emotion/react';

interface BeltBannerProps {
  cssStyle?: SerializedStyles | SerializedStyles[];
  backgroundColor: string;
  color?: string;
  href?: string;
}

function BeltBanner({
  backgroundColor,
  color,
  href,
  children,
  cssStyle
}: React.PropsWithChildren<BeltBannerProps>) {
  const styles = [
    textStyleButton,
    contentStyle,
    css`
      background-color: ${backgroundColor};
      color: ${color};
      ${cssStyle}
    `
  ];

  if (href)
    return (
      <a href={href} target="_blank" rel="noreferrer" css={[styles, linkBlockStyle]}>
        {children}
      </a>
    );

  return (
    <Typography type="button" as="header" cssStyle={styles}>
      {children}
    </Typography>
  );
}

export default memo(BeltBanner);

const linkBlockStyle = css`
  display: inline-block;
  width: 100%;
`;

const contentStyle = css`
  color: white;
  text-align: center;
  padding: ${spacing.padding.small} ${spacing.padding.large};
`;
