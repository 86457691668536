import { Card, color, Divider, Icon, Typography } from '@uniquegood/realworld-studio-design';
import React from 'react';

import { DividerStyle, MarginBottom8, MarginVertical24 } from '@/styles';
import { actionTypeDefinitions } from '@/components/ActionHandler/utils/ActionDefinition';
import CardWrap from '@/components/ActionHandler/components/CardWrap';
import ActionTypeSelector from './ActionTypeSelector';
import ActionParametersEditor from './ActionParametersEditor';

export interface ActionEditorProps {
  actionType?: string;
  actionParameters?: ActionHandler['actionParameters'];
  setActionHandler: React.Dispatch<React.SetStateAction<Partial<ActionHandler>>>;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  items?: GameItem[];
}

const ActionEditor = ({
  actionType,
  actionParameters,
  setActionHandler,
  setIsDirty,
  items
}: ActionEditorProps) => {
  if (!actionType || !actionParameters) return null;

  function setActionType(actionType: ActionHandler['actionType']) {
    setIsDirty(true);
    setActionHandler((actionHandler) => ({ ...actionHandler, actionType }));
  }

  const setActionParameters: React.Dispatch<
    React.SetStateAction<ActionHandler['actionParameters']>
  > = (setStateAction) => {
    setIsDirty(true);
    setActionHandler((actionHandler) => {
      if (typeof setStateAction === 'function')
        return {
          ...actionHandler,
          actionParameters: setStateAction(actionHandler.actionParameters)
        };

      return { ...actionHandler, actionParameters: setStateAction };
    });
  };

  const actionDefinition = actionTypeDefinitions.find((action) => action.type === actionType);

  return (
    <CardWrap
      title="플레이어 액션"
      description="퀘스트 버튼을 누르고 나서 플레이어가 수행해야 하는 행동을 설정해요"
    >
      <Card
        header={{
          prefix: <Icon icon="running_solid" size="20px" />,
          content: '액션 설정',
          backgroundColor: color.surface_primary_default
        }}
      >
        <>
          <Typography as="h3" type="heading" cssStyle={MarginBottom8}>
            액션 종류
          </Typography>
          {actionDefinition && (
            <>
              <ActionTypeSelector
                actionDefinition={actionDefinition}
                setActionType={setActionType}
              />
              {!!actionDefinition?.parameterDefinitions?.length && (
                <Divider cssStyle={[DividerStyle, MarginVertical24]} />
              )}
              <ActionParametersEditor
                actionDefinition={actionDefinition}
                actionParameter={actionParameters}
                setActionParameters={setActionParameters}
                items={items}
              />
            </>
          )}
        </>
      </Card>
    </CardWrap>
  );
};

export default React.memo(ActionEditor);
