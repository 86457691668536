import { decodeJwt } from 'jose';
import moize from 'moize';
import { APP_ENV } from '@/env';
import { authApi } from './apis/auth';
import { postRefreshAccessToken } from '@/apis/core/postRefreshAccessToken';

const OAUTH_LOGIN_HOST = {
  production: 'ui.realworld.to',
  development: 'api-test.realworld.to'
}[APP_ENV];

const CLIENT_ID = 'CreatorsSpace';

export const OAUTH_LOGIN_URI = `https://${OAUTH_LOGIN_HOST}/ui/auth/oauth?response_type=code&CLIENT_ID=${CLIENT_ID}&redirect_uri=${window.location.origin}/auth/loginCallback`;

export function goToLoginPageWithCurrentUriRedirect() {
  window.location.href = `${OAUTH_LOGIN_URI}${encodeURIComponent(
    `?redirect=${window.location.pathname}${window.location.search}`
  )}`;
}
async function getAccessTokenOriginal() {
  const localStorageAccessToken = localStorage.getItem('accessToken');
  // SSO 세션이 있을 수 있으므로 시도
  try {
    if (localStorageAccessToken) {
      const expiresAt = parseJwtExpToISOString(localStorageAccessToken);

      if (Date.parse(expiresAt) - 1000 * 60 < Date.now()) {
        const refreshToken = localStorage.getItem('refreshToken');

        if (!refreshToken) throw Error();

        const refreshed = await postRefreshAccessToken({
          accessToken: localStorageAccessToken,
          refreshToken
        });

        localStorage.setItem('accessToken', refreshed.token);
        localStorage.setItem('refreshToken', refreshed.refreshToken);
        localStorage.setItem('expiresAt', parseJwtExpToISOString(refreshed.token));

        return refreshed.token;
      }

      return localStorageAccessToken;
    }
    throw new Error('No token');
  } catch (e) {
    console.error(e);
    try {
      const {
        data: { token: newAccessToken, refreshToken: newRefreshToken }
      } = await authApi.apiAuthReissueGet();

      if (newAccessToken && newRefreshToken) {
        localStorage.setItem('accessToken', newAccessToken);
        localStorage.setItem('refreshToken', newRefreshToken);
        localStorage.setItem('expiresAt', parseJwtExpToISOString(newAccessToken));
      } else {
        throw new Error('No token or refreshToken');
      }

      return newAccessToken;
    } catch (e) {
      console.error(e);
    }
  }

  return null;
}
export const getAccessToken = moize(getAccessTokenOriginal, {
  maxAge: 5000
});

export function parseJwtExpToISOString(accessToken: string) {
  const { exp } = decodeJwt(accessToken);
  if (!exp) throw Error('JWT exp is undefined. except type is string');

  return new Date(exp * 1000).toISOString();
}
