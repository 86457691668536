import { css } from '@emotion/react';

import React, { useRef } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';

import { Avatar, color, Modal, shadow, spacing } from '@uniquegood/realworld-studio-design';

import { useForm } from 'react-hook-form';

import { KeyboardFocusStyle, textShortening } from '@/styles';
import { onMobile } from '@/styles/responsive';

import { useDidUpdateEffect, useIsMobile, useModalState } from '@/hooks';
import { coreApi } from '@/api';
import { keyPressHandler, toast } from '@/utils';
import SquareButton from '../../../components/SquareButton';
import useChatbot from '../hooks/useChatbot';
import ChatBotModalContent, { FormData, SubmitType } from './ChatBotModalContent';
import { ClickEventName, track } from '@/track';

export default function ChatBotListBar() {
  const { items } = useChatbot();
  const { appId, projectId, chatbotId } = useParams<AppParam>();

  const scrollContainerRef = useRef<HTMLUListElement>(null);
  const itemsRef = useRef<HTMLElement[]>([]);

  const isMobile = useIsMobile();

  return (
    <section>
      <ul css={ListLayout} ref={scrollContainerRef}>
        {items?.map((item, index) => {
          function onClick() {
            if (scrollContainerRef.current && itemsRef.current.length > index) {
              const { vertical, horizontal } = smoothScroll(
                scrollContainerRef.current,
                itemsRef.current[index]
              );

              if (isMobile) {
                horizontal();
              } else {
                vertical();
              }
            }
          }

          const setItemsRef: React.Ref<HTMLElement> = (element) => {
            if (element) {
              itemsRef.current[index] = element;
            }
          };

          const isSelected = item.id === chatbotId;
          const avatarMarkup = (
            <Avatar
              name={item.name}
              userName={item.name}
              source={item.profilePictureUrl}
              isVertical
              selected={isSelected}
              size="xLarge"
              cssStyle={[AvatarSize, CustomTitleSize]}
            />
          );

          return (
            <Link
              onClick={onClick}
              onKeyPress={keyPressHandler(onClick)}
              ref={setItemsRef}
              role="listitem"
              key={item.id}
              to={`/apps/${appId}/projects/${projectId}/chatbots/${item.id}`}
              type="plainWhite"
              css={isSelected ? [KeyboardFocusStyle, KeyboardFocusBorderRadius, DisableLink] : []}
            >
              {avatarMarkup}
            </Link>
          );
        })}
        {ButtonTopSpacing}
        <CreateChatBot />
      </ul>
    </section>
  );
}

function smoothScroll(scrollContainer: HTMLElement, { offsetTop, offsetLeft }: HTMLElement) {
  const itemHeight = 100;
  const itemWidth = 72;

  const gap = 16;

  function vertical() {
    // Hack: 왜 아래 값을 더해야 아바타 사진의 절반정도 위치에 스크롤이 멈추는지 파악하지 못함
    const titleHeight = 20;
    const offset = itemHeight + titleHeight + gap;

    scrollContainer.scrollTo({
      top: offsetTop - offset,
      behavior: 'smooth'
    });
  }

  function horizontal() {
    const offset = itemWidth / 2 + gap;

    scrollContainer.scrollTo({
      left: offsetLeft - offset,
      behavior: 'smooth'
    });
  }

  return { vertical, horizontal };
}

function CreateChatBot() {
  const { appId, projectId } = useParams<AppParam>();

  const { mutate } = useChatbot();
  const { modal, openModal, closeModal } = useModalState();

  const { control, handleSubmit, formState, reset, watch } = useForm<FormData>();

  const history = useHistory();

  const photos = watch('profilePicture');

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const isUploaded = photos?.length > 0;

  useDidUpdateEffect(() => {
    if (!modal.open) {
      reset();
    }
  }, [modal.open]);

  const onSubmit = handleSubmit(async ({ name, profilePicture }) => {
    const body: SubmitType = {
      name,
      profilePictureId: profilePicture[0].id
    };

    const { data: createdChatbot } = await coreApi.post<Chatbot>(
      `/apps/${appId}/projects/${projectId}/chatbots`,
      body
    );

    if (!createdChatbot.id) return;

    const { data: newScene } = await coreApi.post<ChatScene>(
      `/apps/${appId}/projects/${projectId}/chatbots/${createdChatbot.id}/scenes`,
      { name: '첫 장면' }
    );

    await mutate();
    closeModal();

    history.push(
      `/apps/${appId}/projects/${projectId}/chatbots/${createdChatbot.id}/scenes/${newScene.id}`
    );
    toast({ message: '챗봇을 생성했어요' });
  });

  function onClick() {
    openModal({
      size: 'medium',
      title: '챗봇 생성',
      children: <ChatBotModalContent control={control} onSubmit={onSubmit} />,
      primaryAction: {
        icon: 'save_solid',
        content: '저장',
        htmlType: 'submit',
        form: 'modal'
      },
      secondaryAction: {
        content: '취소',
        onAction: closeModal
      }
    });
  }
  return (
    <>
      <SquareButton
        icon="plus_solid"
        onClick={() => {
          onClick();
          track.onClick({
            clickEventName: ClickEventName.click_editgame_mission_chatbot_newchatbot
          });
        }}
        cssStyle={[ButtonPosition, ButtonStyle]}
      >
        새 챗봇
      </SquareButton>
      <Modal
        {...modal}
        primaryAction={{
          ...modal.primaryAction,
          disabled: !isUploaded,
          loading: formState.isSubmitting
        }}
      />
    </>
  );
}

const ListLayout = css`
  overflow-y: auto;
  background-color: ${color.background_default};

  display: flex;
  flex-direction: column;

  padding-top: ${spacing.padding.xlarge2};

  height: 100%;
  width: 120px;
  align-items: center;

  > * {
    flex-shrink: 0;
    margin-bottom: ${spacing.margin.large};
  }

  ${onMobile} {
    overflow-x: auto;

    width: 100%;
    padding: ${spacing.margin.large} 0 !important;

    flex-direction: row;
    align-items: flex-start;

    > * {
      margin-bottom: initial !important;
      margin-right: ${spacing.margin.large};
    }
  }
`;

const ButtonPosition = css`
  position: absolute;
  bottom: ${spacing.margin.xlarge2};

  ${onMobile} {
    position: initial;
    bottom: initial;
    margin-right: ${spacing.margin.large};
  }
`;

const ButtonStyle = css`
  ${shadow.popup};
`;

const ButtonTopSpacing = (
  <div
    css={css`
      width: 1px;
      height: 320px;
      visibility: hidden;

      ${onMobile} {
        display: none;
      }
    `}
  />
);

const AvatarSize = css`
  width: 72px;
  height: auto;
`;

const CustomTitleSize = css`
  align-items: center;
  > :nth-of-type(1) > div {
    width: calc(70px + ${spacing.margin.small} * 2);
    text-align: center;
    word-wrap: anywhere;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    ${onMobile} {
      display: initial;
      -webkit-line-clamp: initial;
      -webkit-box-orient: initial;
      ${textShortening}
    }
  }
`;

const KeyboardFocusBorderRadius = css`
  :focus-visible {
    border-radius: ${spacing.borderRadius.small};
  }
`;

const DisableLink = css`
  pointer-events: none;
`;
