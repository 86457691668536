import { css } from '@emotion/react';
import { Button, ButtonProps, color, spacing } from '@uniquegood/realworld-studio-design';
import { Identity } from '@/styles';

export type SquareButtonProps = Omit<ButtonProps, 'type'>;

export default function SquareButton({ cssStyle, ...rest }: SquareButtonProps) {
  return (
    <Button
      type="basic"
      cssStyle={[ButtonStyle, ...(Array.isArray(cssStyle) ? cssStyle : [cssStyle ?? Identity])]}
      {...rest}
    />
  );
}

const ButtonStyle = css`
  > :first-of-type {
    flex-direction: column;
  }

  width: 88px;
  height: 88px;

  background-color: ${color.surface_default_default};

  .Icon {
    margin: 0 0 ${spacing.margin.xsmall} ${spacing.margin.xsmall};
  }

  .IconWrap {
    width: 28px;
    height: 28px;
  }

  border-radius: ${spacing.borderRadius.medium};

  :hover {
    background-color: ${color.surface_default_hovered};
  }

  :active {
    background-color: ${color.surface_default_pressed};
  }
`;
