import { useParams } from 'react-router-dom';
import { useRequest } from '@/hooks';

export default function useMission() {
  const { appId, scenarioId, missionId } = useParams<AppParam>();

  const {
    data: missions,
    isValidating,
    mutate
  } = useRequest<Mission[]>(`/apps/${appId}/scenarios/${scenarioId}/missions`);

  const missionIndex = missions?.findIndex((mission) => mission.id.toString() === missionId);
  const mission = missions && typeof missionIndex === 'number' ? missions[missionIndex] : undefined;

  return { missions, mission, missionIndex, isValidating, mutate };
}
