import React from 'react';
import { css } from '@emotion/react';
import { Button } from '@uniquegood/realworld-studio-design';
import { useHistory, useParams } from 'react-router-dom';

function PaymentFail() {
  const query = new URLSearchParams(window.location.search);

  const message = query.get('message');
  const history = useHistory();
  const { appId } = useParams<AppParam>();

  const returnUrl = React.useMemo(() => {
    const query = new URLSearchParams(window.location.search);
    const returnUrl = query.get('returnUrl');
    return returnUrl || `/apps/${appId}`;
  }, [appId]);

  return (
    <div css={mainContainer}>
      <div css={titleWrap}>결제 실패</div>
      <div css={messageWrap}>{message}</div>
      <Button
        type="primary"
        onClick={() => {
          history.push(returnUrl);
        }}
      >
        돌아가기
      </Button>
    </div>
  );
}

export default PaymentFail;

const mainContainer = css`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 58px);
`;

const titleWrap = css`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
`;

const messageWrap = css`
  font-size: 16px;
  margin-bottom: 16px;
  text-align: center;
`;
