/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import { color, Icon, shadow, spacing, textStyleButton } from '@uniquegood/realworld-studio-design';
import React from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { css } from '@emotion/react';
import { onMobile } from '@styles/responsive';
import { KeyboardFocusStyle } from '@/styles';
import { ClickEventName, track } from '@/track';

interface ProjectItemProps {
  checkPermissionForCreateProject: (e: React.MouseEvent) => boolean;
}

function CreateProject({ checkPermissionForCreateProject }: ProjectItemProps) {
  const { appId } = useParams<AppParam>();
  const history = useHistory();

  const handleClickCreate = async (targetEngine: 'V1' | 'V2', e: React.MouseEvent) => {
    track.onClick({
      clickEventName: ClickEventName.click_channel_button_creategame,
      params: {
        engineVersion: targetEngine
      }
    });
    const isChecked = checkPermissionForCreateProject(e);

    if (!isChecked) {
      return;
    }

    if (targetEngine === 'V1') {
      history.push(`/apps/${appId}/projects/create`);
    } else if (targetEngine === 'V2') {
      history.push(`/apps/${appId}/projectsV2/create`);
    }
  };

  return (
    <button
      type="button"
      css={[LinkTagStyle, MakeGameContainerStyle, AspectRatio, KeyboardFocusStyle]}
      onClick={(e) => {
        handleClickCreate('V1', e);
      }}
    >
      <Icon icon="gamepad_solid" cssStyle={IconGamePadStyle} />
      <p css={[textStyleButton, GamePadTextStyle]}>게임 만들기</p>
    </button>
  );
}

export default CreateProject;

const LinkTagStyle = css`
  display: block;

  ${onMobile} {
    width: 100%;
    height: 140px;
  }
`;

const MakeGameContainerStyle = css`
  border-radius: ${spacing.borderRadius.xlarge};
  border: 1px solid ${color.border_default_subdued};
  background-color: ${color.surface_default_default};
  ${shadow.card};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const AspectRatio = css`
  aspect-ratio: 310/340;
`;

const IconGamePadStyle = css`
  width: 150px;
  height: auto;
  margin: 0px;

  ${onMobile} {
    width: 56px;
    height: auto;
  }
`;

const GamePadTextStyle = css`
  margin: 0;
  color: ${color.text_subdued};
  margin-top: -${spacing.common.large};

  ${onMobile} {
    margin-top: 0px;
  }
`;
