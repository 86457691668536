import { css } from '@emotion/react';
import {
  Button,
  Card,
  color,
  spacing,
  textStyleButton,
  typography,
  Typography
} from '@uniquegood/realworld-studio-design';
import { Bar } from 'react-chartjs-2';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import queryString from 'query-string';
import Flatpickr from 'react-flatpickr';
import { Korean } from 'flatpickr/dist/l10n/ko';
import download from 'downloadjs';
import { useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import {
  RowFlex,
  JustifySpaceBetween,
  MarginBottom16,
  MarginRight16,
  KeyboardFocusStyle
} from '@/styles';

import { useIsAdminSelector, useRequest, useRestrictProjectRoute } from '@/hooks';

import { containerStyle } from '@/styles/containerStyles';
import LoadingSpinner from '@/components/LoadingSpinner';
import SummaryCard from './components/SummaryCard';
import LabelGroup from './components/LabelGroup';

import '@styles/flatpickr.css';
import { coreApi } from '@/api';
import { ClickEventName, PageViewEventName, track } from '@/track';

export default function Dashboard() {
  useEffect(() => {
    track.onPageView({ pageViewEventName: PageViewEventName.view_dashboard });
  }, []);

  const { appId, projectId } = useParams<AppParam>();
  const history = useHistory();

  const path = `/apps/${appId}/projects/${projectId}/statistics`;
  const { data } = useRequest<Dashboard>(`${path}${history.location.search}`);
  const { data: app } = useRequest<RealWorldApp>(`/apps/${appId}`);

  const redirectTo = useRestrictProjectRoute();

  const { data: isAdmin } = useIsAdminSelector();

  const flatPickrRef = useRef<Flatpickr>(null);

  const { startDate, endDate } = queryString.parse(history.location.search);
  if (typeof startDate !== 'string' || typeof endDate !== 'string') {
    history.replace({ search: queryString.stringify(getDefaultDateQuery()) });
    return null;
  }

  if (!data || !app || redirectTo === undefined)
    return (
      <div css={containerStyle}>
        <Typography as="h1" type="displayMedium">
          게임 플레이 현황
        </Typography>
        <LoadingSpinner />
      </div>
    );

  if (typeof redirectTo === 'string') return <Redirect to={redirectTo} />;

  const dates = [startDate, endDate];

  return (
    <div css={containerStyle}>
      <Typography as="h1" type="displayMedium" cssStyle={MarginBottom16}>
        게임 플레이 현황
      </Typography>
      <Card cssStyle={CardLayout}>
        <section css={TopSectionLayout}>
          <div css={[RowFlex, SummaryCardGroupLayout]}>
            <SummaryCard
              title="누적 플레이 인원"
              count={data.totalPlayUsers}
              color={color.palette_primary_purple_100}
            />
            <SummaryCard
              title="누적 완료 인원"
              count={data.totalCompleteUsers}
              color={color.palette_gray_shade_03}
            />
            <SummaryCard
              title="기간 내 플레이 인원"
              count={data.newStarted.reduce(sum, 0)}
              color={color.palette_secondary_yellow_shade_01}
            />
            <SummaryCard
              title="기간 내 완료 인원"
              count={data.newAccomplished.reduce(sum, 0)}
              color={color.palette_secondary_blue_tint_05}
            />
          </div>
          <div css={[RowFlex, JustifySpaceBetween, LineBreakpoint]}>
            <div css={[RowFlex, BreakpointMargin]}>
              <Flatpickr
                css={[PickerInputStyle, MarginRight16]}
                ref={flatPickrRef}
                options={{
                  mode: 'range',
                  minDate: getDateOneYearAgo(),
                  maxDate: 'today',
                  locale: Korean
                }}
                value={dates}
                onClose={([startDate, endDate]) => {
                  // TODO: 클릭을 체크하지 못해 변경시에만 체크함
                  track.onClick({
                    clickEventName: ClickEventName.click_dashboard_input_daterange,
                    params: {
                      projectId
                    }
                  });
                  history.push({
                    search: queryString.stringify({
                      startDate: dayjs(startDate).format(),
                      endDate: dayjs(endDate).format()
                    })
                  });
                }}
              />
              {(app.channelType === 'Business' || isAdmin) && (
                <Button
                  icon="download_solid"
                  cssStyle={[MarginRight16]}
                  type="plain"
                  onClick={async () => {
                    const { data } = await coreApi.get<string>(
                      `${path}/download${history.location.search}`
                    );
                    download(data, `${projectId}_${dates.join('-')}.csv`);
                    track.onClick({
                      clickEventName: ClickEventName.click_dashboard_button_download,
                      params: {
                        projectId
                      }
                    });
                  }}
                >
                  다운로드
                </Button>
              )}
            </div>
            <LabelGroup />
          </div>
        </section>
        <Bar
          options={chartOption}
          data={{
            labels: data.labels.map((time) =>
              new Date(time).toLocaleString('en', {
                month: '2-digit',
                day: '2-digit'
              })
            ),
            datasets: [
              {
                type: 'line',
                label: '누적 플레이 인원',
                borderWidth: 3,
                borderColor: color.palette_primary_purple_100,
                data: data.totalStarted,
                yAxisID: 'line',
                pointBackgroundColor: color.palette_primary_purple_100
              },
              {
                type: 'line',
                label: '누적 완료 인원',
                borderWidth: 3,
                borderColor: color.palette_gray_shade_03,
                data: data.totalAccomplished,
                yAxisID: 'line',
                pointBackgroundColor: color.palette_gray_shade_03
              },
              {
                type: 'bar',
                label: '새 플레이 인원',
                backgroundColor: color.palette_secondary_yellow_100,
                barThickness: 24,
                data: data.newStarted,
                borderRadius: 4,
                yAxisID: 'bar'
              },
              {
                type: 'bar',
                label: '새 완료 인원',
                backgroundColor: color.palette_secondary_blue_tint_05,
                barThickness: 24,
                data: data.newAccomplished,
                borderRadius: 4,
                yAxisID: 'bar'
              }
            ]
          }}
        />
      </Card>
    </div>
  );
}

function getDateOneYearAgo() {
  return dayjs().subtract(1, 'year').toDate();
}

function getDefaultDateQuery() {
  return {
    startDate: dayjs().subtract(13, 'day').format(),
    endDate: dayjs().format()
  };
}

const sum = (previousValue: number, currentValue: number) => previousValue + currentValue;

const CardLayout = css`
  max-width: 1300px;
  min-width: 740px;
`;

const SummaryCardGroupLayout = css`
  > :not(:last-of-type) {
    margin-right: ${spacing.margin.large};
  }
`;

const lineBreakpoint = '@media (max-width: 1263px)';

const LineBreakpoint = css`
  ${lineBreakpoint} {
    display: block;
  }
`;

const BreakpointMargin = css`
  ${lineBreakpoint} {
    margin: ${spacing.margin.large} 0;
  }
`;

const PickerInputStyle = css`
  padding: ${spacing.padding.xsmall};
  width: 190px;
  border: 0;
  ${textStyleButton};
  outline: none;
  ${KeyboardFocusStyle};

  :hover {
    background-color: ${color.surface_default_hovered};
  }

  :active {
    background-color: ${color.surface_default_pressed};
  }

  background: transparent
    url("data:image/svg+xml;utf8, <svg viewBox='0 0 24 24' width='16px' height='16px'  fill='%23929292' xmlns='http://www.w3.org/2000/svg'><path d='M11.223 17.747L2.322 8.846a1.1 1.1 0 010-1.555L3.36 6.253a1.1 1.1 0 011.553-.002L12 13.305l7.087-7.054a1.1 1.1 0 011.553.002l1.038 1.038a1.1 1.1 0 010 1.555l-8.9 8.9a1.1 1.1 0 01-1.555 0z'></path></svg>")
    right no-repeat;
  padding-right: 17px;

  transition: all 150ms ease-out;
  border-radius: ${spacing.borderRadius.medium};

  cursor: pointer;
`;

const TopSectionLayout = css`
  > *:last-of-type {
    margin-top: ${spacing.margin.medium};
  }
  margin-bottom: ${spacing.margin.xlarge4};
  align-items: flex-end;
`;

const chartPrimaryFont = {
  ticks: {
    font: {
      size: 14,
      family: typography.family.primary
    }
  }
};

const chartOption: React.ComponentProps<typeof Bar>['options'] = {
  scales: {
    x: {
      grid: {
        display: false
      },
      ...chartPrimaryFont
    },
    line: { ...chartPrimaryFont, position: 'left' },
    bar: { ...chartPrimaryFont, position: 'right' }
  },
  plugins: {
    legend: {
      display: false
    }
  }
};
