import React, { useCallback } from 'react';
import { Navigation, NavigationProps } from '@uniquegood/realworld-studio-design';
import { useLocation } from 'react-router-dom';

import { gameEdit, SectionItem, operationV2 } from '../pages';

interface SectionWrapperProps {
  paths: {
    operation: string[];
  };
  onClick: SectionItems[0]['onClick'];
}

type SectionItems = React.ComponentProps<typeof Navigation.Section>['items'];
type MapperType = keyof SectionWrapperProps['paths'];

export default function SectionWrapperV2({ paths, onClick }: SectionWrapperProps) {
  const { pathname } = useLocation();

  const mapper = useCallback(
    (sectionItems: SectionItem[], type: MapperType): SectionItems =>
      sectionItems.map((section, index) => {
        const url = paths[type][index];
        return {
          ...section,
          url,
          onClick,
          selected: pathname.startsWith(url)
        };
      }),
    [paths.operation]
  );

  const operationSection = mapper(Object.values(operationV2), 'operation');

  return (
    <>
      <Navigation.Section title="게임 상세" items={operationSection} />
    </>
  );
}
