import { useEffect } from 'react';

interface IntersectionObserverProps {
  // target을 감싸는 element 지정
  root: HTMLDivElement | null;
  target: HTMLDivElement | null;
  // Element 배열인 entries와 자기 자신인 observer 가 매개 변수로 전달, entries 는 target 으로 지정한 DOM element 객체
  onIntersect: IntersectionObserverCallback;
  // target element 가 root 와 몇 % 교차했을 때, callback 을 실행할지 결정하는 값
  threshold?: number;
  // root 요소를 감싸는 margin 값을 지정 (px or %단위)
  rootMargin?: string;
}

// infinite scroll & lazy load 구현에서 자주 쓰이는 훅입니다.
export default ({
  root,
  target,
  onIntersect,
  threshold = 0.2,
  rootMargin = '0px'
}: IntersectionObserverProps) => {
  useEffect(() => {
    if (!root) return;

    const observer = new IntersectionObserver(onIntersect, {
      root,
      rootMargin,
      threshold
    });

    if (!target) return;

    observer.observe(target);

    // eslint-disable-next-line consistent-return
    return () => {
      observer.unobserve(target);
    };
  }, [target, root, rootMargin, onIntersect, threshold]);
};
