import { css } from '@emotion/react';

import { Button, color } from '@uniquegood/realworld-studio-design';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { TableStyle } from '@/styles';

import { useRequest } from '@/hooks';

interface TableProps {
  onEdit(code: string): void;
  onDelete(code: string): void;
  onToggleActive(code: string): void;
  isUpdating?: string;
}

export default function Table({ onEdit, onDelete, onToggleActive, isUpdating }: TableProps) {
  const { appId, projectId } = useParams<AppParam>();
  const { data } = useRequest<RedeemCode[]>(`/apps/${appId}/codes/projects/${projectId}`);

  return (
    <table css={[TableStyle, RootStyle]}>
      <thead>
        <tr>
          <th>코드</th>
          <th>설명</th>
          <th>생성 일시</th>
          <th>만료 일시 설정</th>
          <th>사용 개수</th>
          <th>관리</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((redeemCode) => (
          <tr key={redeemCode.code} css={redeemCode.revoked && DisabledStyle}>
            <td>{redeemCode.code}</td>
            <td>{redeemCode.note}</td>
            <td>
              <time dateTime={redeemCode.createdAt}>
                {dayjs(redeemCode.createdAt).format(formatTemplate)}
              </time>
            </td>
            <td>
              <time dateTime={redeemCode.expiresAt}>
                {redeemCode.expiresAt
                  ? dayjs(redeemCode.expiresAt).format(formatTemplate)
                  : '만료 일시 없음'}
              </time>
            </td>
            <td>
              {redeemCode.usedCount}{' '}
              {typeof redeemCode.count === 'number' &&
                `/ ${redeemCode.count + redeemCode.usedCount}`}
            </td>
            <td>
              <Button type="basic" onClick={() => onEdit(redeemCode.code)}>
                수정
              </Button>
              <Button
                type={redeemCode.revoked ? 'outlinePrimary' : 'basic'}
                cssStyle={MiddleButtonStyle}
                onClick={() => onToggleActive(redeemCode.code)}
                loading={redeemCode.code === isUpdating}
              >
                {redeemCode.revoked ? '재활성화' : '비활성화'}
              </Button>
              <Button type="plainDestructive" onClick={() => onDelete(redeemCode.code)}>
                삭제
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

const formatTemplate = 'YYYY-MM-DD HH:mm';

const RootStyle = css`
  width: 980px;
`;

const MiddleButtonStyle = css`
  margin: 0 4px;
`;

const DisabledStyle = css`
  color: ${color.text_disabled};
  background-color: ${color.surface_default_subdued};
`;
