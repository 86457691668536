/* eslint-disable @typescript-eslint/no-explicit-any */
import { css } from '@emotion/react';
import { Select, spacing, Typography } from '@uniquegood/realworld-studio-design';
import { SelectOption } from '@uniquegood/realworld-studio-design/dist/types/atoms/Select/Select';
import React, { useEffect, useState } from 'react';

import { onMobile } from '@/styles/responsive';
import SkeletonUI from './SkeletonUI';

interface Props {
  chatbots?: Chatbot[];
  params: any;
  setParams: (text: string, key: string) => void;
}

const MoveScene = ({ chatbots, params, setParams }: Props) => {
  if (!chatbots) return <SkeletonUI />;

  const [botOptions, setBotOptions] = useState<SelectOption[]>([]);
  const [selectedBotId, setSelectedBotId] = useState<string>(params?.parameters?.botId);
  const [sceneOptions, setSceneOptions] = useState<SelectOption[]>([]);
  const [scene, setScene] = useState<string>(params?.parameters?.sceneId);

  const setDefaultValue = (params: any) => {
    setSelectedBotId(params?.parameters?.botId);
    setScene(params?.parameters?.sceneId);
  };

  const findChatbot = (bots?: Chatbot[]) => {
    const currentBot = bots?.find((bot) => bot.id === selectedBotId);

    return currentBot;
  };

  const setChatBotOptions = (bots?: Chatbot[]) => {
    const options =
      bots?.map((bot) => ({
        label: bot.name,
        value: bot.id
      })) ?? [];

    setBotOptions(options);
  };

  const setChatbotSceneOptions = (bots?: Chatbot[]) => {
    const bot = findChatbot(bots);
    const options =
      bot?.scenes.map((s) => ({
        label: s.name,
        value: s.id
      })) ?? [];

    setSceneOptions(options);
  };

  useEffect(() => {
    setDefaultValue(params);
  }, []);

  useEffect(() => {
    setChatBotOptions(chatbots);
  }, [chatbots]);

  useEffect(() => {
    setChatbotSceneOptions(chatbots);
  }, [selectedBotId]);

  return (
    <div css={Wrap}>
      <Select
        cssStyle={CustomSelectWidth}
        value={selectedBotId}
        placeholder="이동할 챗봇 선택"
        options={botOptions}
        onChange={(selected: string) => {
          setSelectedBotId(selected);
          setParams(selected, 'botId');
        }}
      />
      <Typography as="span" cssStyle={Prefix} type="body" textColor="subdued">
        의 장면을
      </Typography>
      <Select
        cssStyle={CustomSelectWidth}
        value={scene}
        placeholder="장면 선택"
        options={sceneOptions}
        onChange={(selected: string) => {
          setScene(selected);
          setParams(selected, 'sceneId');
        }}
      />
      <Typography as="span" cssStyle={Suffix} type="body" textColor="subdued">
        로 이동
      </Typography>
    </div>
  );
};

export default MoveScene;

const Wrap = css`
  display: flex;
  align-items: center;

  ${onMobile} {
    display: block;
  }
`;

const CustomSelectWidth = css`
  width: 200px;
`;

const Prefix = css`
  margin: 0 ${spacing.common.small};
`;

const Suffix = css`
  margin-left: ${spacing.common.small};
`;
