import { css } from '@emotion/react';
import { color, shadow, spacing } from '@uniquegood/realworld-studio-design';
import React, { memo } from 'react';
import { KeyboardInnerFocusStyle } from '@/styles';
import { SearchUserListItemActionProps } from '../types';

import SearchUserListItem from './SearchUserListItem';

interface SearchUserListProps extends SearchUserListItemActionProps {
  searchUsers: AccountResponseModel[];
  loadMoreUsers: () => void;
  isHideMoreButton: boolean;
}

function SearchUserList({
  searchUsers,
  loadMoreUsers,
  isHideMoreButton,
  ...actionProps
}: SearchUserListProps) {
  return (
    <ul css={[SearchMemberListWrapStyle, KeyboardInnerFocusStyle]}>
      {searchUsers.map((m) => (
        <SearchUserListItem key={m.id} user={m} {...actionProps} />
      ))}

      {!isHideMoreButton && (
        <li>
          <button
            type="button"
            onClick={loadMoreUsers}
            css={[SearchMemberListCardStyle, KeyboardInnerFocusStyle, MoreLoadButtonLayout]}
          >
            더 불러오기
          </button>
        </li>
      )}
    </ul>
  );
}

const MoreLoadButtonLayout = css`
  text-align: left;
`;

export default memo(SearchUserList);

const SearchMemberListWrapStyle = css`
  padding: ${spacing.borderedPadding.small};
  margin: 0px;
  margin-top: ${spacing.common.xxsmall};
  list-style: none;
  overflow: auto;
  max-height: 322px;

  background-color: ${color.white};
  border: 1px solid ${color.border_default_subdued};
  ${shadow.card};
  border-radius: ${spacing.borderRadius.medium};
`;

export const SearchMemberListCardStyle = css`
  border: none;

  padding: ${spacing.common.small};
  width: 100%;
  cursor: pointer;

  background-color: ${color.surface_default_default};
  border-radius: ${spacing.borderRadius.medium};

  &:hover {
    background-color: ${color.surface_default_hovered};
  }
`;
