import { css } from '@emotion/react';

import {
  Button,
  color,
  Card,
  Icon,
  Modal,
  textStyleBody
} from '@uniquegood/realworld-studio-design';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import React, { useRef, useState } from 'react';
import { MarginBottom10, MarginBottom24 } from '@/styles';

import { useModalState, useRequest } from '@/hooks';
import { coreApi } from '@/api';
import LoadingSpinner from '@/components/LoadingSpinner';
import { emitServerErrorToast } from '@/utils';
import { HorizonScrollWrapper, ModalChildrenStyles } from '../styles';
import Table from './Table';
import UnlockCodeForm from './UnlockCodeForm';
import { ClickEventName, track } from '@/track';

export interface FormData extends Pick<RedeemCode, 'code' | 'note'> {
  expiresAt: Date;
  count: string;
}

export default function UnlockCode() {
  const { appId, projectId } = useParams<AppParam>();
  const { data, mutate } = useRequest<RedeemCode[]>(`/apps/${appId}/codes/projects/${projectId}`);

  const { modal, openModal, closeModal } = useModalState();

  const { control, handleSubmit, reset, formState } = useForm<FormData>();

  const typeRef = useRef<'post' | 'put' | null>(null);

  const [isUpdating, setIsUpdating] = useState<string>();

  const onSubmit = handleSubmit(async (data) => {
    const formattedData = {
      ...data,
      code: (data.code as string | undefined) ?? '',
      count: parseInt(data.count, 10),
      expiresAt: data.expiresAt?.toISOString()
    };
    try {
      if (typeRef.current === 'put') {
        const { code, ...modified } = formattedData;
        await coreApi.put(`/apps/${appId}/codes/${code}`, modified);
      } else {
        await coreApi.post(`/apps/${appId}/codes/project/${projectId}`, formattedData);
      }
      await mutate();

      openModal({
        title: `언락코드를 ${typeRef.current === 'put' ? '수정' : '생성'}했어요`,
        // TODO: 배열 타입도 넣을 수 있게 타입 정의 수정하기
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        cssStyle: ModalChildrenStyles,
        primaryAction: {
          content: '확인',
          onAction: closeModal
        },
        children: (
          <>
            <Icon
              cssStyle={MarginBottom10}
              icon="check_circle_regular"
              size="100px"
              color={color.interactive_primary_subdued}
            />
            리얼월드 앱 계정 탭에서 언락코드를 입력하면, 내 게임 탭에서 게임을 확인하실 수 있어요.
          </>
        )
      });
    } catch (error) {
      emitServerErrorToast(error);
    }
  });

  async function onDelete(code: string) {
    openModal({
      title: '언락코드를 삭제하시겠어요?',
      // TODO: 배열 타입도 넣을 수 있게 타입 정의 수정하기
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      cssStyle: ModalChildrenStyles,
      primaryAction: {
        content: '삭제',
        type: 'destructive',
        icon: 'trash_alt_solid',
        onAction: async () => {
          setIsUpdating(code);

          await coreApi.delete(`/apps/${appId}/codes/${code}`);
          await mutate();

          setIsUpdating(undefined);

          openModal({
            title: '언락코드를 삭제했어요',
            // TODO: 배열 타입도 넣을 수 있게 타입 정의 수정하기
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            cssStyle: ModalChildrenStyles,
            primaryAction: {
              content: '확인',
              onAction: closeModal
            },
            children: (
              <>
                <Icon
                  cssStyle={MarginBottom10}
                  icon="check_circle_regular"
                  size="100px"
                  color={color.interactive_primary_subdued}
                />
                언락코드를 삭제해도 언락코드로 연 게임은 내 게임 탭에서 사라지지 않고 유지되어요
              </>
            )
          });
        }
      },
      secondaryAction: {
        content: '취소',
        onAction: closeModal
      },
      children: (
        <>
          <Icon
            cssStyle={MarginBottom10}
            icon="check_circle_regular"
            size="100px"
            color={color.interactive_primary_subdued}
          />
          언락코드를 삭제해도 언락코드로 연 게임은 내 게임 탭에서 사라지지 않고 유지되어요
        </>
      )
    });
  }

  async function onToggleActive(code: string) {
    const toggleTarget = data?.find((unlockCode) => unlockCode.code === code);

    if (!toggleTarget) throw Error('선택한 언락코드를 찾을 수 없습니다.');

    const toggledTarget = {
      note: toggleTarget.note,
      expiresAt: toggleTarget.expiresAt,
      count: toggleTarget.count,
      revoked: !toggleTarget.revoked
    };
    const placeholder = toggledTarget.revoked ? '비활성화' : '활성화';

    try {
      setIsUpdating(code);

      await coreApi.put(`/apps/${appId}/codes/${code}`, toggledTarget);
      await mutate();

      setIsUpdating(undefined);

      openModal({
        title: `언락코드를 ${placeholder}했어요`,
        // TODO: 배열 타입도 넣을 수 있게 타입 정의 수정하기
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        cssStyle: ModalChildrenStyles,
        primaryAction: {
          content: '확인',
          onAction: closeModal
        },
        children: (
          <>
            <Icon
              cssStyle={MarginBottom10}
              icon="check_circle_regular"
              size="100px"
              color={color.interactive_primary_subdued}
            />
            {toggledTarget.revoked
              ? '다시 사용하시려면 재활성화 버튼을 눌러주세요'
              : '리얼월드 앱 계정 탭에서 언락코드를 입력하면, 내 게임 탭에서 게임을 확인하실 수 있어요.'}
          </>
        )
      });
    } catch {
      // Empty
    }
  }

  function onCreate() {
    reset({});

    typeRef.current = 'post';
    openModal({
      size: 'medium',
      title: '언락 코드 생성',
      primaryAction: {
        content: '확인',
        form: 'modal',
        htmlType: 'submit',
        // Hack: form event 로 onSubmit 이 실행되지 않아서 직접 핸들러를 버튼에 넣었습니다.
        onAction: onSubmit
      },
      secondaryAction: {
        content: '취소',
        onAction: closeModal
      },
      children: <UnlockCodeForm type="post" control={control} onSubmit={onSubmit} />
    });
  }

  function onEdit(code: string) {
    const redeemCode = data?.find((item) => item.code === code);

    if (!redeemCode) throw Error(`${code} 명을 갖는 리딤코드를 찾지 못했습니다`);

    reset({
      code: redeemCode.code,
      note: redeemCode.note,
      expiresAt: redeemCode.expiresAt ? new Date(redeemCode.expiresAt) : undefined,
      count: redeemCode.count === 0 ? undefined : redeemCode.count?.toString()
    });

    typeRef.current = 'put';
    openModal({
      size: 'medium',
      title: '언락 코드 수정',
      primaryAction: {
        content: '확인',
        form: 'modal',
        htmlType: 'submit',
        // Hack: form event 로 onSubmit 이 실행되지 않아서 직접 핸들러를 버튼에 넣었습니다.
        onAction: onSubmit
      },
      secondaryAction: {
        content: '취소',
        onAction: closeModal
      },
      children: <UnlockCodeForm type="put" control={control} onSubmit={onSubmit} />
    });
  }

  return (
    <>
      <Card
        header={{ content: '언락코드 관리', prefix: <Icon icon="key_solid" /> }}
        id="unlock-code"
      >
        <div css={[HorizonScrollWrapper, EmptyStateStyle, textStyleBody, MarginBottom24]}>
          {data === undefined && <LoadingSpinner />}

          {!!data && data.length > 0 ? (
            <Table
              onEdit={onEdit}
              onDelete={onDelete}
              onToggleActive={onToggleActive}
              isUpdating={isUpdating}
            />
          ) : (
            '생성한 언락 코드가 없어요'
          )}
        </div>
        <Button
          type="primary"
          icon="plus_solid"
          onClick={() => {
            onCreate();
            track.onClick({
              clickEventName: ClickEventName.click_gamesetting_unlockcode_button_makeunlockcode
            });
          }}
        >
          언락 코드 생성
        </Button>
      </Card>
      <Modal
        {...modal}
        primaryAction={{ ...modal.primaryAction, loading: formState.isSubmitting || !!isUpdating }}
      />
    </>
  );
}

const EmptyStateStyle = css`
  > div:first-of-type {
    color: ${color.text_disabled};
  }
`;
