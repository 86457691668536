import { css } from '@emotion/react';
import { color, textStyleBody } from '@uniquegood/realworld-studio-design';

import React from 'react';
import { ListStyleNone, MarginZero, PaddingZero } from '@/styles';
import { EmojiRightSpace } from '../styles';

interface FeaturesProps {
  features: Record<string, boolean>;
}

export default function Features({ features }: FeaturesProps) {
  return (
    <ul css={[ListStyleNone, MarginZero, PaddingZero]}>
      {Object.entries(features).map(([feature, isActive]) => {
        const firstSpaceIndex = [...feature].findIndex((character) => character === ' ');
        const endOffset = firstSpaceIndex + 1;

        const emoji = isActive ? feature.substring(0, endOffset) : '❌';
        const featureOmitEmoji = feature.substring(endOffset);
        return (
          <li key={feature} css={[isActive ? ActiveStyle : InActiveStyle, textStyleBody]}>
            <span css={EmojiRightSpace}>{emoji}</span>
            {featureOmitEmoji}
          </li>
        );
      })}
    </ul>
  );
}

const ActiveStyle = css`
  color: ${color.palette_secondary_dark_blue_100};
`;

const InActiveStyle = css`
  color: ${color.palette_status_red_100};
`;
