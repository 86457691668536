import React, { useEffect, useState } from 'react';

import {
  Button,
  Card,
  Icon,
  Modal,
  spacing,
  Typography
} from '@uniquegood/realworld-studio-design';
import { useSWRConfig } from 'swr';

import { Redirect, useParams } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { css } from '@emotion/react';
import { Container650 } from '@/components/containers';
import { studioApi } from '@/api';

import {
  useRequest,
  useModalState,
  useDidUpdateEffect,
  useRestrictProjectRoute,
  useRequestImmutable
} from '@/hooks';
import { toast } from '@/utils';

import { ColumnFlex, MarginBottom24, MarginTop24 } from '@/styles';

import LoadingSpinner from '@/components/LoadingSpinner';
import PersonaAvatar from './components/PersonaAvatar';
import AddButton from './components/AddButton';

import PersonaForm from './components/PersonaForm';
import type { CommentType, PersonaFormData } from './types';
import WriteComment from './components/WriteComment';
import Comment from './components/Comment';
import useRequestInfinite from './hooks/useRequestInfinite';
import { PageViewEventName, track } from '@/track';
import SwitchTab from '@/components/HeaderSwitchTab';

const tabs: { value: CommentType; label: string }[] = [
  { value: 'normal', label: '도움글' },
  { value: 'review', label: '후기글' }
];

const selectedTabToLocalString: Record<CommentType, string> = {
  normal: '도움글',
  review: '후기글'
};

export default function Comments() {
  useEffect(() => {
    track.onPageView({ pageViewEventName: PageViewEventName.view_communitymanage });
  }, []);

  const { appId, projectId } = useParams<AppParam>();
  if (!appId || !projectId) throw Error('필요한 라우트 파라미터가 비여 있습니다.');

  const { control, handleSubmit, setValue, reset, formState } = useForm<PersonaFormData>();
  const { modal, openModal, closeModal } = useModalState();

  const [isDeleting, setIsDeleting] = useState(false);

  const [selectedTab, setSelectedTab] = useState<CommentType>('normal');

  const { mutate } = useSWRConfig();

  const personaEndpoint = `/api/apps/${appId}/projects/${projectId}/personas`;

  const { data: me } = useRequestImmutable<MyInfo>('/api/me');
  const { data: personas } = useRequest<Persona[]>(personaEndpoint, {}, undefined, studioApi);

  const redirectTo = useRestrictProjectRoute();

  const {
    data: comments,
    setSize,
    isReachedEnd,
    mutate: mutateComments
  } = useRequestInfinite<Comment[]>(getKey, {}, { params: { pageSize: 50 } });

  const { data: apps } = useRequestImmutable<Project[]>('/apps');

  const isAdminMode =
    me &&
    apps &&
    me.role === 'RealWorld.Roles.Admin' &&
    apps.findIndex((app) => app.id === appId) === -1;

  function getKey(page: number, previousPageData: Comment[] | null) {
    if (previousPageData && previousPageData?.length === 0) return null;

    return `/api/projects/${projectId}/comments?filter=${selectedTab}&page=${page}`;
  }

  useDidUpdateEffect(() => {
    setSize(1);
  }, [selectedTab]);

  useEffect(() => {
    if (!modal.open) {
      reset();
    }
  }, [modal.open]);

  if (!me || !personas || redirectTo === undefined || isAdminMode === undefined)
    return <LoadingSpinner />;

  if (typeof redirectTo === 'string') return <Redirect to={redirectTo} />;

  const onSubmit = handleSubmit(async ({ type, ...data }) => {
    const lastRoute = type === 'post' ? '' : `/${data.id}`;
    const endpoint = `${personaEndpoint}${lastRoute}`;

    await studioApi[type](endpoint, data);
    mutate(personaEndpoint);

    submittedPersona(type);
  });

  async function onCreatePersona() {
    setValue('type', 'post');
    openModal({
      size: 'medium',
      title: '페르소나 생성',
      children: <PersonaForm onSubmit={onSubmit} control={control} />,
      primaryAction: {
        content: '저장',
        onAction: onSubmit,
        icon: 'save_solid'
      }
    });
  }

  async function onUpdatePersona(persona: Persona) {
    setValue('type', 'put');
    setValue('id', persona.id);
    openModal({
      size: 'medium',
      title: '페르소나 수정',
      children: <PersonaForm persona={persona} onSubmit={onSubmit} control={control} />,
      primaryAction: {
        content: '저장',
        onAction: onSubmit,
        icon: 'save_solid'
      },
      leftAction: {
        content: '페르소나 삭제',
        onAction: () => onDeletePersona(persona.id)
      }
    });
  }

  function submittedPersona(type: 'post' | 'put') {
    const toLocalString = ({ post: '저장', put: '수정' } as const)[type];
    toast({ message: `페르소나를 ${toLocalString}했어요` });
    closeModal();
  }

  function onDeletePersona(personaId: string) {
    openModal({
      size: 'small',
      title: `정말 삭제하시겠어요?`,
      children: '삭제한 페르소나는 되돌릴 수 없어요',
      primaryAction: {
        content: '삭제',
        onAction: async () => {
          setIsDeleting(true);
          try {
            await studioApi.delete(`${personaEndpoint}/${personaId}`);
            await mutate(personaEndpoint);
            closeModal();
            toast({ message: '페르소나를 삭제했어요' });
          } finally {
            setIsDeleting(false);
          }
        }
      }
    });
  }

  const myInfoToPersona: Persona = {
    ...me,
    profileImageUrl: me.profileImage
  };

  const mergedPersona = [myInfoToPersona, ...personas];

  return (
    <>
      <Container650 cssStyle={[ColumnFlex, CardGap, MarginBottom24]}>
        {!isAdminMode && (
          <Card
            header={{ prefix: <Icon icon="user_solid" />, content: '페르소나 관리' }}
            cssStyle={PersonaCardLayout}
          >
            <PersonaAvatar source={me.profileImage} userName={me.name} />
            {personas.map((persona) => (
              <PersonaAvatar
                key={persona.id}
                source={persona.profileImageUrl}
                userName={persona.name}
                isEditable
                onClick={() => onUpdatePersona(persona)}
              />
            ))}
            <AddButton onClick={onCreatePersona} />
          </Card>
        )}
        <Card
          header={{
            prefix: (
              <SwitchTab<CommentType>
                tabs={tabs}
                setSelectedTab={setSelectedTab}
                selectedTab={selectedTab}
              />
            ),
            content: ''
          }}
          cssStyle={[CommentCardHeaderLayout, CommentCardContentLayout]}
        >
          {selectedTab === 'normal' && (
            <WriteComment
              type="new"
              refetchComments={mutateComments}
              {...(isAdminMode
                ? { isAdminMode }
                : { isAdminMode, personas: [myInfoToPersona, ...personas] })}
            />
          )}
          {comments === undefined && <LoadingSpinner />}

          {!!comments && comments.length === 0 && (
            <Typography type="body" textColor="disabled" cssStyle={EmptyStateStyle}>
              아직 올라온 {selectedTabToLocalString[selectedTab]}이 없어요
            </Typography>
          )}

          {!!comments &&
            comments.length > 0 &&
            (isAdminMode
              ? comments.map((comment) => (
                  <Comment
                    isAdminMode
                    key={comment?.id}
                    isRootComment
                    comment={comment}
                    type={selectedTab}
                    refetchComments={mutateComments}
                  />
                ))
              : comments.map((comment) => (
                  <Comment
                    isAdminMode={false}
                    personas={mergedPersona}
                    key={comment?.id}
                    isRootComment
                    comment={comment}
                    type={selectedTab}
                    refetchComments={mutateComments}
                  />
                )))}
        </Card>

        {!isReachedEnd && (
          <Button
            icon="plus_solid"
            type="basic"
            size="large"
            onClick={() => setSize((size) => size + 1)}
            cssStyle={[MarginTop24, ButtonLayout]}
          >
            게시글 더 불러오기
          </Button>
        )}
      </Container650>
      <Modal
        {...modal}
        primaryAction={{ ...modal.primaryAction, loading: formState.isSubmitting || isDeleting }}
      />
    </>
  );
}

const CardGap = css`
  > div {
    margin-bottom: ${spacing.margin.xlarge2};
  }
`;

const CommentCardHeaderLayout = css`
  > div:first-of-type {
    padding-bottom: 0;
  }
`;

const CommentCardContentLayout = css`
  > div:last-of-type {
    padding: 0;
  }
`;

const PersonaCardLayout = css`
  > div:last-of-type {
    display: grid;
    grid-template-columns: repeat(auto-fill, 72px);
    gap: ${spacing.margin.xlarge2};
    align-items: flex-start;
  }
`;

const ButtonLayout = css`
  align-self: center;
`;

const EmptyStateStyle = css`
  margin: 60px 0;
  text-align: center;
`;
