import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { coreApi } from '@/api';

interface Return<Data, Error> extends Omit<SWRResponse<Data, AxiosError<Error>>, 'data'> {
  data?: Data;
}

export type Config<Data = unknown, Error = unknown> = SWRConfiguration<Data, AxiosError<Error>>;

export default function useRequest<Data = unknown, Error = unknown>(
  route: string,
  swrConfig: Config<Data, Error> = {},
  axiosConfig?: AxiosRequestConfig,
  axiosInstance = coreApi
): Return<Data, Error> {
  const { data, error, isValidating, mutate } = useSWR<Data, AxiosError<Error>>(
    route,
    (route: string) => axiosInstance.get<Data>(route, axiosConfig).then(({ data }) => data),
    { ...disableRevalidation, revalidateIfStale: true, ...swrConfig }
  );

  return {
    data,
    error,
    isValidating,
    mutate
  };
}

const disableRevalidation = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false
};
