import { Dispatch, SetStateAction, useCallback, useState } from 'react';

export default function useInput<T>(
  initValue: T
): [T, (value: T) => void, Dispatch<SetStateAction<T>>] {
  const [value, setter] = useState<typeof initValue>(initValue);

  const handler = useCallback((newValue) => {
    setter(newValue);
  }, []);

  return [value, handler, setter];
}
