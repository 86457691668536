import React from 'react';
import { css } from '@emotion/react';
import { FormGroup, Input } from '@uniquegood/realworld-studio-design';
import { Controller, useFormContext } from 'react-hook-form';
import Uploader from '@/components/Uploader';

type StartAreaInfoProps = {
  project?: ProjectV2;
};

export default function StartAreaInfo({ project }: StartAreaInfoProps) {
  const { control } = useFormContext<ProjectFormData>();
  const placeholder = `시작 장소에 대한 안내가 필요할 경우 추가해 주세요.\n예) 외부 건물 바깥의 주황색 문으로 입장할 수 있습니다.`;

  return (
    <FormGroup label="시작 장소 안내" isHorizontal>
      <div css={containerStyle}>
        <div css={uploaderWrapperStyle}>
          <Controller
            control={control}
            name="offlineInfo.startAddressImage"
            defaultValue={project?.offlineInfo?.startAddressImage}
            render={({ field: { onChange, value } }) => {
              const memoedFiles = React.useMemo(
                () => value?.map((file) => ({ id: file?.userFileId, url: file?.fileUrl })),
                [value?.length]
              );
              return (
                <Uploader
                  uploadedFiles={memoedFiles}
                  getUploadFiles={(files) => {
                    onChange(
                      files?.map((file) => ({
                        userFileId: file.id,
                        fileUrl: file.url
                      })) || null
                    );
                  }}
                  ratio="fullWidth"
                  size="small"
                />
              );
            }}
          />
        </div>
        <Controller
          name="offlineInfo.startAddressImageDescription"
          render={({ field }) => (
            <Input
              {...field}
              placeholder={placeholder}
              multiline
              showCharacterCount
              maxLength={46}
              onChange={(value) => {
                field.onChange(value);
              }}
              cssStyle={css`
                flex-grow: 1;
                height: 100px;

                & > textarea {
                  height: 100px !important;
                }
                & > div:nth-child(2) {
                  position: absolute;
                  right: 0;
                  bottom: 0;
                  padding-bottom: 8px;
                }
              `}
            />
          )}
        />
      </div>
    </FormGroup>
  );
}

const containerStyle = css`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const uploaderWrapperStyle = css`
  width: 100px;
`;
