import { css } from '@emotion/react';
import {
  Avatar,
  Button,
  ButtonGroup,
  Card,
  Input,
  spacing,
  textStyleCaption
} from '@uniquegood/realworld-studio-design';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import { useRequest, useWrapFormToConsiderWhitespacesAsEmpty } from '@/hooks';
import LoadingSpinner from '@/components/LoadingSpinner';
import Uploader from '@/components/Uploader';
import { Flex } from '@/styles';
import { coreApi } from '@/api';
import { toast } from '@/utils';
import { InputEventName, track } from '@/track';

interface FormFields {
  content?: string;
  images?: UserFileResponseModel[];
}

export interface WritePostProps {
  feedsType: FeedsType;
  post?: FeedPost;
  onClose?(): void;
  mutate(): unknown;
}

export default function WritePost({ feedsType, post, onClose, mutate }: WritePostProps) {
  const { appId } = useParams<AppParam>();
  const { data: app } = useRequest<RealWorldApp>(`/apps/${appId}`);

  const { control, handleSubmit, reset, formState } = useWrapFormToConsiderWhitespacesAsEmpty(
    useForm<FormFields>(
      post && {
        defaultValues: { content: post.content, images: post.images }
      }
    )
  );
  const onSubmit = handleSubmit(async (data) => {
    if (post) {
      await coreApi.patch(`/api/${appId}/community/${feedsType}/${post.id}`, {
        ...data,
        isCreator: true
      });
    } else {
      await coreApi.post(`/api/${appId}/community/${feedsType}`, {
        ...data,
        isCreator: true
      });
    }

    await mutate();

    toast({ message: '게시물이 저장 되었어요!' });
  });

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({ content: '', images: undefined });

      if (onClose) {
        onClose();
      }
    }
  }, [formState.isSubmitSuccessful]);

  if (!app)
    return (
      <Card>
        <LoadingSpinner />;
      </Card>
    );

  return (
    <Card>
      <div css={Flex}>
        <Avatar source={app.imageUrl} isCreator size="medium" />
        <form css={formLayout} onSubmit={onSubmit}>
          <div aria-label="작성자" css={textStyleCaption}>
            {app.name}
          </div>
          <Controller
            control={control}
            name="content"
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                {...field}
                onChange={(...args) => {
                  field.onChange(...args);

                  track.onInputThrottled({
                    inputEventName:
                      feedsType === 'creatorFeeds'
                        ? InputEventName.input_channel_input_feed
                        : InputEventName.input_channel_input_community
                  });
                }}
                placeholder="피드에 게시하기"
                multiline
                cssStyle={inputMinHeight}
              />
            )}
          />
          <div css={buttonsLayout}>
            <Controller
              control={control}
              name="images"
              render={({ field: { value, onChange } }) => {
                const memoedValue = useMemo(() => value, [value?.length]);
                return (
                  <Uploader
                    size="small"
                    limit={4}
                    isMultiple
                    uploadedFiles={memoedValue}
                    getUploadFiles={onChange}
                  />
                );
              }}
            />
            <ButtonGroup cssStyle={submitButtonLayout}>
              {post && (
                <Button
                  htmlType="button"
                  size="small"
                  type="basic"
                  cssStyle={submitButtonLayout}
                  onClick={onClose}
                >
                  취소
                </Button>
              )}
              <Button
                loading={formState.isSubmitting}
                htmlType="submit"
                size="small"
                type="primary"
              >
                게시
              </Button>
            </ButtonGroup>
          </div>
        </form>
      </div>
    </Card>
  );
}

const formLayout = css`
  margin-left: ${spacing.margin.large};
  flex: 1;
  > :not(:last-of-type) {
    margin-bottom: ${spacing.margin.small};
  }
`;

const inputMinHeight = css`
  textarea {
    min-height: 116px;
  }
`;

const buttonsLayout = css`
  display: flex;
  justify-content: space-between;
`;

const submitButtonLayout = css`
  align-self: flex-end;
`;
