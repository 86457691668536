import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useChatbot, useQuest } from '@/hooks';
import { IAvailabel } from '@/components/ActionHandler/types';
import { generateRandomKey } from '../utils';

export default function useActionHandler({ availabelType }: IAvailabel) {
  const { questId, sceneId } = useParams<AppParam>();

  const { quest } = useQuest();
  const { scene } = useChatbot();
  const [actionHandler, setActionHandler] = useState<Partial<ActionHandler>>({});

  useEffect(() => {
    if (questId && availabelType === 'quest' && quest) {
      setActionHandler(keyMapper(quest.actionHandler));
    } else if (sceneId && availabelType === 'chatbot' && scene) {
      setActionHandler(keyMapper(scene.actionHandler));
    }
  }, [questId, sceneId, availabelType]);

  return { actionHandler, setActionHandler };
}

function keyMapper(actionHandler: ActionHandler): ActionHandler {
  return {
    ...actionHandler,
    reactionDefinitions: actionHandler.reactionDefinitions.map((definition) => {
      return {
        ...definition,
        commands: definition.commands.map((command) => ({ ...command, key: generateRandomKey() })),
        conditions: definition.conditions.map((condition) => ({
          ...condition,
          key: generateRandomKey()
        })),
        key: generateRandomKey()
      };
    }),
    failureCommands: actionHandler.failureCommands.map((command) => ({
      ...command,
      key: generateRandomKey()
    }))
  };
}
