import Quill from 'quill';
import { fontFamilies } from './extensions/font';
import { lineHeights } from './extensions/line-height';
import { fontSizes } from './extensions/size';
import './extensions/audio';

const FontStyle = Quill.import('formats/font');
FontStyle.whitelist = fontFamilies;
Quill.register(FontStyle, true);

const COLORS = [
  false,
  '#000000',
  '#e60000',
  '#ff9900',
  '#ffff00',
  '#008a00',
  '#0066cc',
  '#9933ff',
  '#ffffff',
  '#facccc',
  '#ffebcc',
  '#ffffcc',
  '#cce8cc',
  '#cce0f5',
  '#ebd6ff',
  '#bbbbbb',
  '#f06666',
  '#ffc266',
  '#ffff66',
  '#66b966',
  '#66a3e0',
  '#c285ff',
  '#888888',
  '#a10000',
  '#b26b00',
  '#b2b200',
  '#006100',
  '#0047b2',
  '#6b24b2',
  '#444444',
  '#5c0000',
  '#663d00',
  '#666600',
  '#003700',
  '#002966',
  '#3d1466',
  'custom-picker'
];

export const container = [
  [{ font: fontFamilies }],
  [{ size: fontSizes }, { lineheight: lineHeights }],
  ['bold', 'italic', 'underline', 'strike'],
  [{ color: COLORS }, { background: COLORS }],
  [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
  [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
  ['image', 'video', 'audio', 'blockquote'],
  ['clean']
];

const titleValues = {
  font: '글꼴',
  size: '글꼴 크기',
  lineheight: '행간 크기',
  bold: '굵게',
  italic: '기울임',
  underline: '밑줄',
  strike: '취소선',
  color: '글꼴 색상',
  background: '배경 색상',
  align: {
    '': '왼쪽 맞춤',
    center: '가운데 맞춤',
    right: '오른쪽 맞춤',
    justify: '양쪽 맞춤'
  },
  list: {
    ordered: '번호 매기기',
    bullet: '글머리 기호 넣기'
  },
  indent: {
    '-1': '내어쓰기',
    '+1': '들여쓰기'
  },
  image: '이미지',
  video: '비디오',
  audio: '오디오',
  blockquote: '인용',
  clean: '서식 지우기'
};

export function fillToolbarTitle(formElement: HTMLFormElement) {
  const mapped = Object.entries(titleValues).flatMap(([format, value]) => {
    if (typeof value === 'string') {
      const button = formElement.querySelector(`.ql-${format}`);

      return { button, title: value };
    }

    return Object.entries(value).map(([value, title]) => {
      const button = formElement.querySelector(`[value="${value}"].ql-${format}`);

      return { button, title };
    });
  });

  for (const { button, title } of mapped) {
    button?.setAttribute('title', title);
  }
}

export function insertAudioIcon(formElement: HTMLFormElement) {
  const audioButton = formElement.querySelector('.ql-audio');

  if (audioButton) {
    audioButton.innerHTML =
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M369.941 97.941l-83.882-83.882A48 48 0 0 0 252.118 0H48C21.49 0 0 21.49 0 48v416c0 26.51 21.49 48 48 48h288c26.51 0 48-21.49 48-48V131.882a48 48 0 0 0-14.059-33.941zM332.118 128H256V51.882L332.118 128zM48 464V48h160v104c0 13.255 10.745 24 24 24h104v288H48zm144-76.024c0 10.691-12.926 16.045-20.485 8.485L136 360.486h-28c-6.627 0-12-5.373-12-12v-56c0-6.627 5.373-12 12-12h28l35.515-36.947c7.56-7.56 20.485-2.206 20.485 8.485v135.952zm41.201-47.13c9.051-9.297 9.06-24.133.001-33.439-22.149-22.752 12.235-56.246 34.395-33.481 27.198 27.94 27.212 72.444.001 100.401-21.793 22.386-56.947-10.315-34.397-33.481z"/></svg>';
  }
}
