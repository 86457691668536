import { css } from '@emotion/react';
import {
  Badge,
  color,
  RadioButton,
  spacing,
  Typography
} from '@uniquegood/realworld-studio-design';
import React, { SetStateAction } from 'react';
import { onMobile } from '@/styles/responsive';
import {
  AlignCenterFlex,
  CursorPointer,
  DefaultCursor,
  JustifyCenterFlex,
  MarginBottom24,
  MarginLeft8,
  RowFlex,
  MarginBottom8
} from '@/styles';
import type { Status } from '../types';

type Setter = SetStateAction<Status>;

interface RadioItemProps {
  value: string;
  title: string;
  selected: boolean;
  serverSideSelected: boolean;
  onChange: (value: Setter) => void;
}

export default function RadioItem({
  value,
  onChange,
  title,
  selected,
  serverSideSelected,
  children
}: React.PropsWithChildren<RadioItemProps>) {
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label css={[WrapperStyle, ...(selected ? [SelectedStyle, DefaultCursor] : [CursorPointer])]}>
      <RadioButton
        name="status"
        value={value}
        cssStyle={MarginBottom8}
        // Hack TODO: value prop 상수 타입으로 onChange value 타입 추론하기
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onChange={onChange}
      />
      <div css={[RowFlex, JustifyCenterFlex, AlignCenterFlex, HeaderLayout, MarginBottom24]}>
        <Typography as="h2" type="displaySmall">
          {title}
        </Typography>
        {serverSideSelected && (
          <Badge cssStyle={MarginLeft8} type="purple">
            현재 상태
          </Badge>
        )}
      </div>
      {children}
    </label>
  );
}

const WrapperStyle = css`
  text-align: center;
  padding: ${spacing.padding.xlarge2};
  min-width: 250px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .check-box {
    display: none;

    ${onMobile} {
      display: block;
    }
  }

  border: 2px solid ${color.border_default_subdued};
  border-radius: ${spacing.borderRadius.medium};

  background-color: ${color.surface_default_subdued};

  :not(:first-of-type, :last-of-tpe) {
    margin: ${spacing.margin.xlarge2} 0;
  }
`;

const SelectedStyle = css`
  background-color: ${color.surface_blue_default};
  border: 2px solid ${color.palette_secondary_blue_100};
`;

const HeaderLayout = css`
  h2 {
    margin: 0;
  }
`;
