import { Card, Icon, Popover, color } from '@uniquegood/realworld-studio-design';
import React, { useMemo } from 'react';

import { OverflowInitial } from '@/styles';
import { CardWrap } from '@/components/ActionHandler/components';

import { IAvailabel, ReactionProps } from '@/components/ActionHandler/types';
import { LastElementMarginBottom24, OverlayBackground } from '@/components/ActionHandler/styles';
import { generateRandomKey } from '@/components/ActionHandler/utils';
import AddButton from './AddButton';
import Command from './Command';
import IncardHeading from './IncardHeading';
import CommandPopover from './CommandPopover';
import { useToggle } from '@/hooks';
import filterReactionDefs from '../../utils/filterReactionDefs';

interface Props extends ReactionProps, IAvailabel {
  commands: ReactionCommand[];
  setCommands(setStateAction: React.SetStateAction<ReactionCommand[]>): void;
}

const FailureReactionEditor = ({ commands, setCommands, availabelType, ...rest }: Props) => {
  const { value: popoverActive, toggle: popoverToggle } = useToggle(false);

  function remove(index: number) {
    setCommands((commands) => [...commands.slice(0, index), ...commands.slice(index + 1)]);
  }

  function addCommand(selectedType: string) {
    setCommands((commands) => [
      ...commands,
      {
        key: generateRandomKey(),
        type: selectedType,
        parameters: {}
      }
    ]);
  }

  const definitions = useMemo(
    () => filterReactionDefs({ type: 'command', availabelType }),
    [availabelType]
  );

  return (
    <CardWrap
      id="wrong-reaction"
      title="오답 시 리액션"
      description="조건을 충족하지 않아 리액션 정의들이 모두 실행되지 않을 경우 실행되어요"
    >
      <Card
        header={{
          prefix: <Icon icon="times_regular" size="20px" color={color.icon_critical} />,
          content: '오답 시 리액션 설정',
          textColor: color.text_critical,
          backgroundColor: color.surface_critical_subdued_hovered
        }}
      >
        <IncardHeading title="리액션" subTitle="사용자의 액션으로 인해 일어나는 반응" />

        <div css={LastElementMarginBottom24}>
          {commands.map((command, index) => (
            <Command
              key={command.key}
              command={command}
              setCommands={setCommands}
              commandIndex={index}
              commandDefinition={definitions.find((item) => item.type === command.type)}
              removeCommand={() => remove(index)}
              {...rest}
            />
          ))}
        </div>

        {popoverActive && <div className="overlay-background" css={OverlayBackground} />}
        <Popover
          contentCssStyle={OverflowInitial}
          active={popoverActive}
          onClose={popoverToggle}
          activator={
            <AddButton onClick={popoverToggle} type="wrongReaction">
              리액션 추가
            </AddButton>
          }
        >
          <CommandPopover
            availabelType={availabelType}
            addCommand={addCommand}
            popoverToggle={popoverToggle}
          />
        </Popover>
      </Card>
    </CardWrap>
  );
};

export default React.memo(FailureReactionEditor);
