import { css } from '@emotion/react';
import { color, spacing } from '@uniquegood/realworld-studio-design';
import React from 'react';

interface Props {
  children: React.ReactNode;
}

const UpDownButton = ({ children }: Props) => {
  return <div css={Button}>{children}</div>;
};

export default UpDownButton;

const Button = css`
  display: flex;
  flex-direction: column;
  border-radius: ${spacing.common.small};
  border: 1px solid ${color.border_default_default};

  & > button {
    border: none;
    outline: none;
    padding: 3px 9px;
    margin: 0px;
    background-color: ${color.surface_default_default};
    cursor: pointer;

    &:hover {
      background-color: ${color.surface_default_hovered};
    }
    &:active {
      background-color: ${color.surface_default_pressed};
    }
    &:focus-visible {
      outline: 2px solid ${color.focused_default};
    }
    &:disabled {
      cursor: not-allowed;
      background-color: ${color.surface_default_default};
      & > svg {
        fill: ${color.icon_disabled};
        color: ${color.icon_disabled};
      }
    }
  }

  & > button:first-of-type {
    border-top-right-radius: ${spacing.common.small};
    border-top-left-radius: ${spacing.common.small};
    border-bottom: 1px solid ${color.border_default_default};
  }

  & > button:last-of-type {
    border-bottom-right-radius: ${spacing.common.small};
    border-bottom-left-radius: ${spacing.common.small};
  }
`;
