import { css, SerializedStyles } from '@emotion/react';
import { color, ListItem, typography } from '@uniquegood/realworld-studio-design';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useIsAdminSelector, useRequestImmutable } from '@/hooks';
import { TOP_BAR_HEIGHT_NUMBER } from './TopBar/Topbar';

export interface FloatingNavigationProps {
  items: FloatingNavigationItem[];
  cssStyle: SerializedStyles | SerializedStyles[];
  offset?: number;
}

interface FloatingNavigationItem {
  content: string;
  fragment: string;
  color?: string;
}

// eslint-disable-next-line camelcase
const { interactive_primary_default } = color;

export default function FloatingNavigation({
  items,
  offset = 0,
  cssStyle
}: FloatingNavigationProps) {
  const { appId } = useParams<AppParam>();
  const { data: isAdmin } = useIsAdminSelector();
  const { data: apps } = useRequestImmutable<RealWorldApp[]>('/apps');

  const isAdminVisitThirdPartyChannel =
    isAdmin && apps?.findIndex((app) => app.id === appId) === -1;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const sections = items.map(({ fragment }) => document.getElementById(fragment)!);

    function getOverlapArea(r1: Rect, r2: Rect) {
      const yOverlap = Math.max(
        0,
        Math.min(r1.y - offset - TOP_BAR_HEIGHT_NUMBER + r1.height, r2.y + r2.height) -
          Math.max(r1.y, r2.y)
      );
      return yOverlap;
    }

    function percentInView(div: Element) {
      const rect = div.getBoundingClientRect();

      const viewport = { x: 0, y: 0, height: window.innerHeight };
      const overlapArea = getOverlapArea(rect, viewport);

      return overlapArea / rect.height;
    }

    const handler = toFit(() => {
      const intersectingSections = sections.filter(percentInView);

      for (const { id } of sections) {
        const target = getFragmentAnchor(id);
        target?.classList.remove('active');
      }

      if (intersectingSections.length > 0) {
        const { id } = intersectingSections[0];
        const target = getFragmentAnchor(id);
        target?.classList.add('active');
      }
    });

    handler();

    window.addEventListener('scroll', handler, { passive: true });

    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, []);

  return (
    <nav
      css={[
        rootStyle,
        cssStyle,
        css`
          top: ${80 + (isAdminVisitThirdPartyChannel ? 32 : 0)}px;
        `
      ]}
    >
      <ul>
        {items.map(({ content, fragment, color }) => (
          <ListItem
            className="disabled"
            key={fragment}
            url={`#${fragment}`}
            onAction={(event) => {
              event.preventDefault();

              const location = document.getElementById(fragment);
              const offsetTop = location?.offsetTop;

              if (!offsetTop) return;
              window.scrollTo({ top: offsetTop - offset, behavior: 'smooth' });
            }}
            content={content}
            // eslint-disable-next-line camelcase
            cssStyle={[ItemStyle, generateFontStyle(color ?? interactive_primary_default)]}
          />
        ))}
      </ul>
    </nav>
  );
}

function toFit(cb: () => void) {
  let tick = false;
  return () => {
    if (tick) return;

    tick = true;
    // eslint-disable-next-line consistent-return
    return requestAnimationFrame(() => {
      tick = false;
      return cb();
    });
  };
}

function getFragmentAnchor(id: string) {
  const target = document.querySelector(`a[href="#${id}"]`);
  return target;
}

const generateFontStyle = (color: string) => css`
  &.active {
    color: ${color};
    font-weight: ${typography.weight.medium};
  }
`;

const rootStyle = css`
  background-color: ${color.surface_default_default};
  padding: 8px;

  > ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  border-radius: 16px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.07);
`;

const ItemStyle = css`
  &:hover {
    background-color: ${color.surface_default_hovered};
  }

  &.active:not(:hover) {
    background-color: transparent !important;
  }

  &:focus {
    box-shadow: none;
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px ${color.focused_default} inset;
  }

  font-weight: ${typography.weight.regular};

  color: ${color.text_disabled};
`;

type Rect = Pick<DOMRect, 'y' | 'height'>;
