import { css } from '@emotion/react';

export default function Tooltip() {
  return (
    <div css={containerStyle}>
      <h3 css={titleStyle}>난이도</h3>
      <div css={contentStyle}>
        <ul css={listStyle}>
          <li>매우 쉬움 : 어린이도 할 수 있어요.</li>
          <li>쉬움 : 가볍게 즐길 수 있어요.</li>
          <li>보통 : 쉽지 않을 거예요!</li>
          <li>어려움 : 꽤나 어려울지도?</li>
          <li>매우 어려움 : 엄청난 집중이 필요해요!</li>
        </ul>
      </div>
    </div>
  );
}

const containerStyle = css`
  width: 288px;
  padding: 8px 0%;
  cursor: default;
`;

const titleStyle = css`
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 8px;
`;

const contentStyle = css`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

const listStyle = css`
  list-style-type: disc;
  padding-inline-start: 16px;

  li::marker {
    font-size: 10px;
  }
`;
