import { css } from '@emotion/react';
import {
  Avatar,
  color,
  Popover,
  spacing,
  TopBar as OriginalTopBar,
  TopBarProps as OriginalTopBarProps
} from '@uniquegood/realworld-studio-design';
import React, { useCallback, useState } from 'react';

import { onMobile } from '@/styles/responsive';
import useRequest from '@/hooks/useRequest';
import { useIsMobile, useParamsWithMatchPath } from '@/hooks';
import { helpLink } from '@/utils';
import { Identity } from '@/styles';
import ViewOnAppModal from './components/ViewOnAppModal';
import UserMenu from './components/UserMenu';
import { useEventListener } from '../ActionHandler/hooks';

export type TopBarProps = Omit<
  OriginalTopBarProps,
  'items' | 'logoMoveUrl' | 'missionButton' | 'profileSrc' | 'userMenu'
> & { profileSrc?: string };

export default function TopBar(props: TopBarProps) {
  const { type, cssStyle } = props;

  const styles = [
    ...(Array.isArray(cssStyle) ? cssStyle : [cssStyle ?? Identity]),
    css`
      height: ${TOP_BAR_HEIGHT};
    `
  ];

  const [showUserMenu, setShowUserMenu] = useState(false);
  const toggleShowUserMenu = useCallback(() => {
    setShowUserMenu((showUserMenu) => !showUserMenu);
  }, []);

  const [isModalOpen, setModalOpen] = useState(false);
  const toggleModal = useCallback(() => {
    setModalOpen((isModalOpen) => !isModalOpen);
  }, []);

  useEventListener('click', () => setShowUserMenu(false));

  const { data: me } = useRequest<MyInfo>('/api/me');

  const { appId } = useParamsWithMatchPath();

  const isMobile = useIsMobile();

  const AvatarStateStyle = css`
    ${type === 'mission' && `margin-left: ${spacing.margin.xlarge2};`};
  `;

  return (
    <>
      <ViewOnAppModal toggleModal={toggleModal} isOpen={isModalOpen} />
      <OriginalTopBar
        items={[
          {
            content: '매뉴얼',
            icon: 'question_circle_solid',
            url: helpLink.notion.helpCenter,
            external: true
          },
          {
            content: '피드백 보내기',
            icon: 'envelope_solid',
            url: 'https://rwd.to/realworldstudio-feedback',
            external: true
          }
        ]}
        logoMoveUrl={`/apps/${appId}`}
        missionButton={{
          content: '앱에서 확인하기',
          mobileContent: '미리보기',
          onAction: toggleModal
        }}
        profileSrc={me?.profileImage}
        {...props}
        cssStyle={styles}
      >
        {!(type === 'logoOnly') && (
          <Popover
            active={showUserMenu}
            activator={
              <button
                type="button"
                onClick={(event) => {
                  event.stopPropagation();
                  toggleShowUserMenu();
                }}
                css={[AvatarStyle, AvatarStateStyle]}
              >
                <Avatar source={me?.profileImage} size={isMobile ? 'small' : 'medium'} />
              </button>
            }
            onClose={() => {
              // 팝오버를 열때에도 호출이 되어서 빈 함수로 둠
              // setShowUserMenu(false);
            }}
          >
            <UserMenu onClose={toggleShowUserMenu} cssStyle={UserMenuLayout} />
          </Popover>
        )}
      </OriginalTopBar>
    </>
  );
}

export const TOP_BAR_HEIGHT_NUMBER = 58;
export const TOP_BAR_HEIGHT = `${TOP_BAR_HEIGHT_NUMBER}px`;

const AvatarStyle = css`
  border: none;
  background: none;
  cursor: pointer;
  vertical-align: middle;
`;

const UserMenuLayout = css`
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.2);
  width: 393px;

  ${onMobile} {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: ${color.surface_default_default};
    width: 100%;
    height: 100%;

    && {
      box-shadow: none;
    }
  }
`;
