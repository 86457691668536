export function getMaxPageNumber(length: number, offset: number) {
  return Math.floor(length / offset);
}

export function checkBorder(currentPage: number, maxPage: number) {
  return [currentPage === 0, currentPage === maxPage];
}

export function getSlotRange(currentPage: number, range: number, maxPage: number) {
  const startPage = Math.floor(currentPage / range) * range;

  return Array.from(new Array(range).keys())
    .map((index) => index + startPage)
    .filter((page) => page <= maxPage);
}
