import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  ActionList,
  Popover,
  Typography,
  spacing,
  color,
  Icon,
  Divider,
  Modal
} from '@uniquegood/realworld-studio-design';
import { useParams } from 'react-router-dom';
import { css } from '@emotion/react';
import {
  AlignCenterFlex,
  ColumnFlex,
  Flex,
  Identity,
  JustifySpaceBetween,
  Margin24,
  MarginBottom10,
  MarginRight16,
  MarginVertical24
} from '@/styles';

import { coreApi } from '@/api';
import { useIsOwner, useModalState, useRequest } from '@/hooks';

import useIsAdminSelector from '@/hooks/useIsAdminSelector';

import { toast } from '@/utils';
import WriteComment from './WriteComment';
import type { CommentType, RefetchComments } from '../types';
import ModifyComment from './ModifyComment';

type CommentProps = {
  comment: Comment;
  type: CommentType;
  isRootComment?: boolean;
  refetchComments: RefetchComments;
} & (
  | {
      isAdminMode: true;
      personas?: undefined;
    }
  | {
      isAdminMode: false;
      personas: Persona[];
    }
);

export default function Comment({
  isAdminMode,
  comment,
  type,
  isRootComment = false,
  personas,
  refetchComments
}: CommentProps) {
  const { data: me } = useRequest<MyInfo>('/api/me');

  const { appId, projectId } = useParams<AppParam>();
  const [showActionList, setShowActionList] = useState(false);
  const [showWriteComment, setShowWriteComment] = useState(false);
  const { data: isAdmin } = useIsAdminSelector();
  const { data: isOwner } = useIsOwner();

  const [isDeleting, setIsDeleting] = useState(false);
  const [isModifyOpen, setIsModifyOpen] = useState(false);

  const { modal, openModal, closeModal } = useModalState();

  const [clientSideState, setClientSideState] = useState({
    like: comment?.like,
    likeCount: comment?.likeCount
  });

  useEffect(() => {
    if (isRootComment) {
      setShowWriteComment(false);
    }
  }, [comment?.children?.length]);

  function onModify() {
    setIsModifyOpen(true);

    setShowActionList(false);
  }

  function onDelete() {
    openModal({
      size: 'small',
      title: '정말 삭제하시겠어요?',
      children: '삭제한 댓글은 되돌릴 수 없어요',
      cssStyle: ModalContentLayout,
      primaryAction: {
        onAction: async () => {
          setIsDeleting(true);

          await coreApi.delete(`/api/projects/${projectId}/comments/${comment.id}`);
          await refetchComments();

          setIsDeleting(false);

          closeModal();
          toast({ message: '댓글을 삭제했어요' });
        },
        content: '삭제',
        icon: 'trash_alt_solid',
        type: 'destructive'
      },
      secondaryAction: {
        onAction: closeModal,
        content: '취소'
      }
    });
  }

  async function toggleSpoiler() {
    toast({ message: '댓글을 스포일러 처리했어요' });
    await coreApi.post(`/apps/${appId}/projects/${projectId}/comments/${comment.id}/blind`);
    refetchComments();

    setIsModifyOpen(false);
  }

  async function onClickLikeButton() {
    setClientSideState(({ like, likeCount }) => ({
      like: !like,
      likeCount: like ? likeCount - 1 : likeCount + 1
    }));

    await coreApi.post(`/api/projects/${projectId}/comments/${comment.id}/likes`);
    refetchComments();
  }

  if (!comment) {
    return <> </>;
  }

  if (isModifyOpen) {
    return isAdminMode ? (
      <ModifyComment
        type={type}
        isAdminMode
        isRootComment={isRootComment}
        commentId={comment?.id}
        refetchComments={refetchComments}
        setIsModifyOpen={setIsModifyOpen}
        comment={comment}
      />
    ) : (
      <ModifyComment
        type={type}
        isAdminMode={false}
        isRootComment={isRootComment}
        commentId={comment?.id}
        refetchComments={refetchComments}
        setIsModifyOpen={setIsModifyOpen}
        comment={comment}
      />
    );
  }

  return (
    <>
      {isRootComment && (
        <Divider cssStyle={[DividerStyle, type === 'review' ? HideDivider : Identity]} />
      )}
      <section css={[ColumnFlex, Margin24]}>
        <header css={[Flex, JustifySpaceBetween]}>
          <Avatar
            size="large"
            userName={comment?.writer.name}
            source={comment?.writer.profileImage}
            userEmail={new Date(comment?.createdAt)
              .toLocaleDateString('ko', {
                year: '2-digit',
                month: '2-digit',
                day: '2-digit'
              })
              .substring(0, 10)}
          />
          <Popover
            active={showActionList}
            preferredAlignment="left"
            activator={
              <Button
                onClick={() => setShowActionList((showActionList) => !showActionList)}
                type="plain"
                icon="ellipsis_v_regular"
                size="large"
              />
            }
            onClose={() => setShowActionList(false)}
            contentCssStyle={PopoverForActionListLayout}
          >
            <ActionList
              items={[
                ...(comment?.writer.id === me?.id || comment?.isPersona
                  ? [
                      {
                        content: '수정',
                        onAction: onModify
                      }
                    ]
                  : []),
                ...(isOwner || isAdmin
                  ? [
                      {
                        content: '삭제',
                        onAction: onDelete,
                        destructive: true
                      }
                    ]
                  : []),
                {
                  content: comment?.hasSpoilers ? '스포일러 해제' : '스포일러 처리',
                  onAction: toggleSpoiler
                }
              ]}
            />
          </Popover>
        </header>
        <Typography as="article" type="body" cssStyle={[MarginVertical24, ColumnFlex]}>
          {comment.hasSpoilers && spoilerMarkup}
          {comment.content}
          <figure role="group" css={ImageGroupLayout}>
            {comment.pictures?.map((picture, index) => (
              <img key={picture} src={picture} alt={`${index}번째 사진`} />
            ))}
          </figure>
        </Typography>
        <footer css={[Flex, FooterLayout]}>
          <Button
            type="plain"
            icon={clientSideState.like ? 'heart_solid' : 'heart_regular'}
            size="small"
            onClick={onClickLikeButton}
            cssStyle={[LikeButtonStyle, AlignCenterFlex, MarginRight16]}
          >
            {clientSideState.likeCount > 0 ? clientSideState.likeCount : '0'}
          </Button>
          {isRootComment && (
            <Button onClick={() => setShowWriteComment(true)} disabled={showWriteComment}>
              댓글 달기
            </Button>
          )}
        </footer>
      </section>
      {showWriteComment &&
        (isAdminMode ? (
          <WriteComment
            isAdminMode
            type="reply"
            parentId={comment?.id}
            refetchComments={refetchComments}
          />
        ) : (
          <WriteComment
            isAdminMode={false}
            personas={personas}
            type="reply"
            parentId={comment?.id}
            refetchComments={refetchComments}
          />
        ))}
      {!!comment?.children && (
        <div css={ChildCommentLayout}>
          {isAdminMode
            ? comment.children.map((childComment) => (
                <>
                  <Divider cssStyle={ChildCommentDividerStyle} />
                  <Comment
                    isAdminMode
                    key={childComment.id}
                    comment={childComment}
                    type={type}
                    refetchComments={refetchComments}
                  />
                </>
              ))
            : comment.children.map((childComment) => (
                <>
                  <Divider cssStyle={ChildCommentDividerStyle} />
                  <Comment
                    isAdminMode={false}
                    personas={[]}
                    key={childComment.id}
                    comment={childComment}
                    type={type}
                    refetchComments={refetchComments}
                  />
                </>
              ))}
        </div>
      )}
      <Modal {...modal} primaryAction={{ ...modal.primaryAction, loading: isDeleting }} />
    </>
  );
}

const FooterLayout = css`
  align-self: flex-start;
`;

const ChildCommentLayout = css`
  margin-left: ${spacing.margin.xlarge2};
`;

const PopoverForActionListLayout = css`
  margin-top: ${spacing.margin.small};

  > .Content {
    height: initial !important;
  }
`;

const LikeButtonStyle = css`
  font-size: 14px;
  svg {
    color: ${color.interactive_primary_default};
  }
`;

const DividerStyle = css`
  height: 8px;
  background-color: ${color.surface_default_disabled};
`;

const HideDivider = css`
  &:first-of-type {
    display: none;
  }
`;

const ChildCommentDividerStyle = css`
  background-color: ${color.surface_default_disabled};
  margin-right: ${spacing.margin.xlarge2};
`;

const ModalContentLayout = css`
  > div {
    align-items: flex-start;
  }
`;

const spoilerMarkup = (
  <Typography
    as="label"
    type="button"
    cssStyle={[
      css`
        display: inline-flex;
        color: ${color.interactive_primary_default};
      `,
      MarginBottom10
    ]}
  >
    <Icon
      icon="check_solid"
      size="20px"
      color={color.interactive_primary_default}
      cssStyle={css`
        margin: 0;
      `}
    />{' '}
    스포일러
  </Typography>
);

const ImageGroupLayout = css`
  width: 100%;
  overflow-x: auto;
  margin-top: ${spacing.margin.small};

  display: flex;
  flex-wrap: no-warp;

  > img {
    height: 200px;

    &:not(:last-of-type) {
      margin-right: ${spacing.margin.small};
    }
  }
`;
