import { css } from '@emotion/react';
import { color, spacing } from '@uniquegood/realworld-studio-design';
import React from 'react';

const PopoverItemLayout = ({
  children,
  hasSection = true
}: {
  children: React.ReactNode;
  hasSection?: boolean;
}) => {
  const borderStyle = css`
    ${hasSection
      ? `border-radius-bottom-right: ${spacing.borderRadius.medium}; border-radius-bottom-left: ${spacing.borderRadius.medium};`
      : `border-radius: ${spacing.borderRadius.medium};`}
  `;

  return <div css={[PopoverLayout, borderStyle]}>{children}</div>;
};

export default PopoverItemLayout;

const PopoverLayout = css`
  background-color: ${color.white};
  padding: ${spacing.common.medium};
`;
