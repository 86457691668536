import { css, keyframes } from '@emotion/react';
import { color } from '@uniquegood/realworld-studio-design';
import React from 'react';

function LoadingSpinner() {
  return (
    <div css={ElementCenter} aria-label="불러오는 중…" aria-busy="true" aria-live="polite">
      <div css={Spinner}>
        <div css={BaseDot} />
        <div css={[BaseDot, SecondDot]} />
      </div>
    </div>
  );
}

export default LoadingSpinner;

const rotateAnimation = keyframes`
  100% {
    transform: transform: rotate(360deg); -webkit-transform: rotate(360deg) }
`;

const bounceAnimation = keyframes`
   0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
`;

const ElementCenter = css`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: wait;
`;

const Spinner = css`
  margin: 80px auto;
  width: 80px;
  height: 80px;
  position: relative;

  animation: ${rotateAnimation} 2s infinite linear;
`;

const BaseDot = css`
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: ${color.surface_navy_default};
  border-radius: 100%;

  animation: ${bounceAnimation} 2s infinite ease-in-out;
`;

const SecondDot = css`
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
`;
