import React, { useState } from 'react';
import { Button, Modal, Typography } from '@uniquegood/realworld-studio-design';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AlignCenterFlex, MarginRight8, RowFlex } from '@/styles';
import { coreApi } from '@/api';
import { useModalState } from '@/hooks';
import { toast } from '@/utils';
import useChatbot from '../hooks/useChatbot';

import SceneModalContent, { FormData, transformToFormData } from './SceneModalContent';

export default function SceneTitle() {
  const { appId, projectId, chatbotId, sceneId } = useParams<AppParam>();

  const { modal, openModal, closeModal } = useModalState();
  const { item, mutate } = useChatbot();

  const sceneIndex = item?.scenes.findIndex((scene) => scene.id === sceneId);
  const scene =
    typeof sceneIndex === 'number' && sceneIndex !== -1 ? item?.scenes[sceneIndex] : undefined;

  const defaultValues = scene && transformToFormData(scene);

  const { control, handleSubmit, formState, reset } = useForm<FormData>({ defaultValues });

  const [isDeleting, setIsDeleting] = useState(false);

  const history = useHistory();

  const onSubmit = handleSubmit(async (data) => {
    await coreApi.put(
      `/apps/${appId}/projects/${projectId}/chatbots/${chatbotId}/scenes/${sceneId}`,
      data
    );
    await mutate();

    closeModal();
    toast({ message: '장면을 저장했어요' });
  });

  function onClick() {
    setIsDeleting(false);
    reset(defaultValues);

    openModal({
      title: '장면 정보 수정',
      size: 'medium',
      children: <SceneModalContent control={control} onSubmit={onSubmit} />,
      primaryAction: {
        icon: 'save_solid',
        content: '저장',
        form: 'modal',
        htmlType: 'submit'
      },
      secondaryAction: {
        content: '취소',
        onAction: closeModal
      },
      leftAction: {
        content: '장면 삭제',
        onAction: onDelete
      }
    });
  }

  async function onDelete() {
    openModal({
      title: '장면을 삭제하시겠어요?',
      children:
        '삭제를 누르면 장면과 장면 안의 리액션이 모두 삭제되고 삭제한 장면은 다시 복구할 수 없어요.',
      primaryAction: {
        content: '삭제',
        icon: 'trash_alt_solid',
        type: 'destructive',
        onAction: deleteScene
      },
      secondaryAction: {
        content: '취소',
        onAction: closeModal
      }
    });

    async function deleteScene() {
      setIsDeleting(true);
      const previousScene =
        typeof sceneIndex === 'number' && sceneIndex - 1 >= 0 && item?.scenes[sceneIndex - 1];
      const nextScene =
        typeof sceneIndex === 'number' &&
        sceneIndex + 1 < (item?.scenes.length ?? 0) &&
        item?.scenes[sceneIndex + 1];

      try {
        const baseUrl = `/apps/${appId}/projects/${projectId}/chatbots/${chatbotId}/scenes`;
        await coreApi.delete(`${baseUrl}/${sceneId}`);

        await mutate();

        if (previousScene) {
          history.replace(`${baseUrl}/${previousScene.id}`);
        } else if (nextScene) {
          history.replace(`${baseUrl}/${nextScene.id}`);
        } else {
          history.replace(`${baseUrl}`);
        }

        closeModal();
        toast({ message: '장면을 삭제했어요' });
      } catch {
        setIsDeleting(false);
      }
    }
  }

  if (!scene) return <></>;

  const isRequesting = formState.isSubmitting || isDeleting;

  return (
    <>
      <header css={[RowFlex, AlignCenterFlex]}>
        <Typography as="h2" type="displaySmall" cssStyle={MarginRight8}>
          {scene?.name}
        </Typography>
        <Button icon="pen_solid" onClick={onClick}>
          장면 정보 수정
        </Button>
      </header>
      <Modal {...modal} primaryAction={{ ...modal.primaryAction, loading: isRequesting }} />
    </>
  );
}
