/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Divider } from '@uniquegood/realworld-studio-design';
import { RequestRequireProps } from '../../../types';
import CustomizeHeader from './CustomizeHeader';
import CustomizeContents from './CustomizeContents';
import { Metadata } from '../../WebviewCustomize';

const CustomWebViewStyle = css`
  background: #ffffff;
  border: 1px solid #ededed;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.07);
`;

interface M extends RequestRequireProps {
  editedParams: any;
  metadata: Metadata[];
  onChange: (params: any) => void;
  onComplete: () => void;
}

const CustomizeArea = ({
  editedParams,
  metadata,
  onChange,
  onComplete,
  ...requestRequireProps
}: M) => {
  return (
    <div css={CustomWebViewStyle}>
      <CustomizeHeader onClick={onComplete} />
      <Divider />
      <CustomizeContents
        editedParams={editedParams}
        metadata={metadata}
        onChange={onChange}
        {...requestRequireProps}
      />
    </div>
  );
};

export default CustomizeArea;
