import { css } from '@emotion/react';
import { color, globalStyle } from '@uniquegood/realworld-studio-design';
import { TOP_BAR_HEIGHT } from '@/components/TopBar/Topbar';

export default [
  globalStyle,
  css`
    :root {
      font-size: 16px;
    }

    img {
      user-select: none;
    }

    html,
    body {
      font-family: 'Pretendard';
      margin: 0;
      -webkit-overflow-scrolling: none;
      background-color: ${color.background_default};
    }

    #root {
      height: calc(100vh - ${TOP_BAR_HEIGHT});
    }

    a {
      text-decoration: none;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    dd,
    dl {
      margin: 0px;
      padding: 0px;
    }

    ol {
      padding-left: 1em;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    figure {
      margin: 0;
    }

    [data-studio-overlay='true']:not(.Popover) {
      z-index: 519;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .air-datepicker {
      width: fit-content;
    }

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: never;

    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  `
];
