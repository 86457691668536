import { useParams } from 'react-router-dom';

import useMission from '@/hooks/useMission';

export default function useQuest() {
  const { questId } = useParams<AppParam>();
  const { missions, mission, mutate } = useMission();

  const allQuests = missions && missions.flatMap((mission) => mission.quests);
  const quests = mission && mission.quests;
  const questIndex = quests && quests.findIndex((quest) => quest.id.toString() === questId);
  const quest = quests && typeof questIndex === 'number' ? quests[questIndex] : undefined;

  return { allQuests, quests, quest, questIndex, mutate };
}
