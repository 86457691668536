import { InformationByMemberLevelType } from './types';

export const informationByMemberLevelList: InformationByMemberLevelType[] = [
  {
    en: 'Owner',
    kr: '소유자',
    description: '팀 구성 편집을 포함하여 이 채널을 수정할 수 있는 모든 권한을 가져요',
    tooltip: [
      {
        id: 'ownerEditProject',
        content: '✏️ 게임 편집'
      },
      {
        id: 'ownerUpdateProject',
        content: '✏️ 게임 생성 / 삭제'
      },
      {
        id: 'ownerUpdateMember',
        content: '✏️ 팀 구성원 추가, 편집'
      },
      {
        id: 'ownerEditChannelInfo',
        content: '✏️ 채널 정보 편집'
      }
    ]
  },
  {
    en: 'Author',
    kr: '편집자',
    description: '게임을 추가하고 수정 할 수 있어요',
    tooltip: [
      {
        id: 'authorEditProject',
        content: '✏️ 게임 편집'
      },
      {
        id: 'authorUpdateProject',
        content: '❌ 게임 생성 / 삭제'
      },
      {
        id: 'authorUpdateMember',
        content: '❌ 팀 구성원 추가, 편집'
      },
      {
        id: 'authorEditChannelInfo',
        content: '❌ 채널 정보 편집'
      }
    ]
  },
  {
    en: 'Tester',
    kr: '테스터',
    description: '게임을 테스트할 수 있어요',
    tooltip: [
      {
        id: 'testerContent',
        content: '리얼월드 앱 > 메인 > 만든 게임 탭에서 테스트 플레이를 할 수 있어요.'
      },
      { id: 'testerContent2', content: '채널 홈 외에 다른 화면은 보여지지 않아요.' }
    ]
  }
];
