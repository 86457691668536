import { css } from '@emotion/react';
import { shadow, spacing, color, typography } from '@uniquegood/realworld-studio-design';
import { onMobile } from './responsive';

export const RowFlex = css`
  display: flex;
`;

export const ColumFlexOnMobile = css`
  display: flex;

  ${onMobile} {
    flex-direction: column;
  }
`;

export const PositionRelative = css`
  position: relative;
`;

export const Width100 = css`
  width: 100%;
`;

export const Height100 = css`
  height: 100%;
`;

export const ContainerStyle = css`
  .margin_0 {
    margin: 0px;
  }
`;
export const Flex = css`
  display: flex;
`;

export const FlexWrap = css`
  display: flex;
  flex-wrap: wrap;
`;

export const FlexContentCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AlignCenterFlex = css`
  display: flex;
  align-items: center;
`;

export const AlignCenterInlineFlex = css`
  display: inline-flex;
  align-items: center;
`;

export const InlineFlex = css`
  display: inline-flex;
`;

export const ColumnFlex = css`
  display: flex;
  flex-direction: column;
  align-items: initial;
`;

export const AlignCenter = css`
  align-items: center;
`;

export const JustifyCenterFlex = css`
  display: flex;
  justify-content: center;
`;

export const JustifySpaceBetween = css`
  justify-content: space-between;
`;

export const OnMobileFlexColumn = css`
  ${onMobile} {
    ${ColumnFlex};
  }
`;

export const CardShadowStyle = css`
  ${shadow.card};
`;

export const FormSpacing = css`
  margin-bottom: ${spacing.common.large};
`;

export const MarginTop4 = css`
  margin-top: ${spacing.common.xsmall};
`;

export const Margin24 = css`
  margin: ${spacing.common.large};
`;

export const MarginTop8 = css`
  margin-top: ${spacing.common.small};
`;

export const MarginTop16 = css`
  margin-top: ${spacing.margin.large};
`;

export const MarginTop10 = css`
  margin-top: ${spacing.margin.small2};
`;

export const MarginBottom4 = css`
  margin-bottom: ${spacing.common.xsmall};
`;

export const MarginBottom6 = css`
  margin-bottom: ${spacing.margin.xsmall2};
`;

export const MarginBottom8 = css`
  margin-bottom: ${spacing.common.small};
`;

export const MarginBottom10 = css`
  margin-bottom: ${spacing.margin.small2};
`;

export const MarginBottom16 = css`
  margin-bottom: ${spacing.margin.large};
`;

export const MarginBottom24 = css`
  margin-bottom: ${spacing.margin.xlarge2};
`;

export const MarginBottom64 = css`
  margin-bottom: 64px;
`;

export const MarginVertical24 = css`
  margin: ${spacing.margin.xlarge2} 0;
`;

export const MarginTop24 = css`
  margin-top: ${spacing.margin.xlarge2};
`;

export const MarginLeft4 = css`
  margin-left: ${spacing.margin.xsmall};
`;

export const MarginLeft16 = css`
  margin-left: ${spacing.margin.large};
`;

export const MarginLeft24 = css`
  margin-left: ${spacing.margin.xlarge2};
`;

export const MarginLeft8 = css`
  margin-left: ${spacing.common.small};
`;

export const MarginRight4 = css`
  margin-right: ${spacing.common.xsmall};
`;

export const MarginRight8 = css`
  margin-right: ${spacing.common.small};
`;

export const MarginRight16 = css`
  margin-right: ${spacing.common.medium};
`;

export const MarginRight24 = css`
  margin-right: ${spacing.margin.xlarge2};
`;

export const MarginLeftAuto = css`
  margin-left: auto;
`;

export const CursorPointer = css`
  cursor: pointer;
`;

export const DefaultCursor = css`
  cursor: default !important;
`;

export const keepAll = css`
  word-break: keep-all;
`;

export const TableStyle = css`
  text-align: left;

  background-color: ${color.surface_default_default};

  border-collapse: collapse;
  border-style: hidden;
  border-top: 1px solid ${color.border_default_subdued};

  border-radius: 8px;
  color: ${color.text_default};
  font-family: ${typography.family.primary};
  font-size: ${typography.size.s2};

  thead {
    background-color: ${color.surface_default_subdued};
  }

  tbody {
    background-color: ${color.surface_default_default};
  }

  th,
  td {
    box-shadow: inset 0px -1px 0px #e1e3e5;
    padding: 16px;
  }

  th {
    font-weight: ${typography.weight.medium};
  }

  td {
    font-weight: ${typography.weight.regular};
  }
`;

export const MarginZero = css`
  margin: 0;
`;

export const MarginBottomZero = css`
  margin-bottom: 0;
`;

export const PaddingZero = css`
  padding: 0;

  ${onMobile} {
    padding: 0;
  }
`;

export const ButtonResetStyle = css`
  transition: all 150ms ease-out;
  margin: 0px;
  padding: 0px;
  border: none;
  ${CursorPointer};
`;

export const DividerStyle = css`
  background-color: ${color.border_default_subdued};
`;

export const ListStyleNone = css`
  list-style: none;
`;

export const TextAlignLeft = css`
  text-align: left;
`;

export const TextAlignCenter = css`
  text-align: center;
`;

export const HoverStyle = css`
  :hover {
    background-color: ${color.surface_default_hovered};
  }
`;

export const KeyboardFocusStyle = css`
  :focus-visible {
    outline: 4px solid ${color.focused_default};
  }
`;

export const KeyboardInnerFocusStyle = css`
  :focus-visible {
    outline: 0;
    ${shadow.focus} inset;
  }
`;

export const FocusStyle = css`
  :focus {
    outline: 4px solid ${color.focused_default};
  }
`;

export const textShortening = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const textShorteningFromTwoLine = css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const ImageCover = css`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ImageContain = css`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const Identity = css``;

export const HideElementStyle = css`
  position: absolute !important;
  top: 0px;
  clip: rect(1px, 1px, 1px, 1px) !important;
  overflow: hidden !important;
  height: 1px !important;
  width: 1px !important;
  padding: 0px !important;
  border: 0px !important;
  margin: 0px !important;
`;

export const CardItemGap = css`
  > div:nth-of-type(2) > *:not(:first-of-type) {
    margin-top: 24px;
  }
`;

export const CardItemGapWithOutHeader = css`
  > * > *:not(:last-of-type) {
    margin-bottom: ${spacing.margin.xlarge2};
  }
`;

export const Gap = css`
  > *:not(:last-of-type) {
    margin-bottom: ${spacing.margin.xlarge2};
  }
`;

export const DisplayBlock = css`
  display: block;
`;

export const DisplayNone = css`
  display: none;
`;

export const OverflowInitial = css`
  overflow: initial !important;
`;

export const EmptyStateEmoji = css`
  ::before {
    content: '👈';
    margin-right: 0.25em;
    ${onMobile} {
      content: '☝️';
    }
  }
`;

export const CenteringLayout = css`
  display: grid;
  place-content: center;
`;

export const ActionListBorder = css`
  border: 1px solid ${color.border_default_subdued};
  border-radius: ${spacing.borderRadius.medium};

  > :not(:last-of-type) {
      border-bottom: solid 1px #ededed;
    }
  }
`;

export const ATagStyle = css`
  text-decoration: underline;
  color: ${color.palette_secondary_dark_blue_100};
  &:hover {
    color: ${color.interactive_darkblue_hovered};
  }
`;

export const FlexGrow = css`
  flex: 1 0;
`;

export const ListStyleTypeDisc = css`
  padding-left: 1em;
  list-style-type: disc;
`;

export const AlignItemsCenter = css`
  align-items: center;
`;
