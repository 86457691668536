/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { css } from '@emotion/react';
import { Icon, Label, Tooltip, color } from '@uniquegood/realworld-studio-design';
import { MarginLeft4 } from '../../../../styles';

interface Props {
  label: string;
  defaultValue: string;
  description?: string;
  onChange: (data: any) => void;
}

const InputColor = ({ label, defaultValue, description, onChange }: Props) => {
  const [defaultColor, setDefaultColor] = useState<string>(defaultValue);

  function handleColor(e: React.ChangeEvent<HTMLInputElement>) {
    setDefaultColor(e.target.value);
    onChange(e.target.value);
  }

  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        padding-top: 24px;
      `}
    >
      <Label
        id={label}
        cssStyle={css`
          > :nth-of-type(1) {
            display: flex;
            align-items: center;
            margin-right: 24px;
          }
        `}
      >
        <Icon
          icon="paint_brush_solid"
          size="16px"
          cssStyle={css`
            margin-right: 6px;
          `}
        />
        {label}
        {description && (
          <Tooltip content={description}>
            <Icon
              cssStyle={MarginLeft4}
              icon="question_circle_solid"
              size="16px"
              color={color.icon_disabled}
            />
          </Tooltip>
        )}
      </Label>
      <input
        css={css`
          height: 36px;
          border: none;
          width: 173px;
        `}
        id={label}
        value={defaultColor}
        type="color"
        onChange={(e) => handleColor(e)}
      />
    </div>
  );
};

export default InputColor;
