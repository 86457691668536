import { css } from '@emotion/react';
import { spacing } from '@uniquegood/realworld-studio-design';

import type { ReactElement } from 'react';
import { ReactComponent as SortAlphaUp } from './svgs/sort-alpha-up.svg';
import { ReactComponent as SortAlphaDown } from './svgs/sort-alpha-down.svg';
import { ReactComponent as SortNumericUp } from './svgs/sort-numeric-up.svg';
import { ReactComponent as SortNumericDown } from './svgs/sort-numeric-down.svg';
import { SortBy } from '../Files';

const IconStyle = css`
  width: 24px;
  color: #626262;
  vertical-align: bottom;

  margin-right: ${spacing.margin.small2};
`;

export const folder = (
  <img
    width="20px"
    height="20px"
    src={`data:image/svg+xml;utf8, <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="css-1hpevqh"><path d="M21.885 13.253l-2.515 4.311a2.222 2.222 0 01-1.919 1.103H3.563a.833.833 0 01-.72-1.254l2.515-4.31A2.223 2.223 0 017.278 12h13.887a.833.833 0 01.72 1.253zM7.278 10.89h11.389V9.222c0-.92-.747-1.666-1.667-1.666h-5.556L9.222 5.333H3.667C2.747 5.333 2 6.08 2 7v9.654l2.399-4.111a3.345 3.345 0 012.879-1.654z" fill="currentColor"></path></svg>`}
    alt="folder open icon"
    css={IconStyle}
  />
);

export const file = (
  <img
    src={`data:image/svg+xml;utf8, <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="css-1hpevqh"><path d="M18.948 5.827L15.67 2.551A1.875 1.875 0 0014.347 2h-7.97A1.881 1.881 0 004.5 3.879v16.246c0 1.035.84 1.875 1.875 1.875h11.248c1.035 0 1.875-.84 1.875-1.875V7.155c0-.496-.2-.976-.551-1.328zm-1.476 1.176h-2.973V4.03l2.973 2.972zM6.376 20.125V3.88h6.249V7.94c0 .52.418.938.937.938h4.062v11.247H6.376z" fill="currentColor"></path></svg>`}
    alt="file icon"
    css={IconStyle}
  />
);

export const sortBy: Record<SortBy, ReactElement> = {
  'name-ascending': <SortAlphaDown />,
  'name-descending': <SortAlphaUp />,
  'created-date-ascending': <SortNumericDown />,
  'created-date-descending': <SortNumericUp />
};
