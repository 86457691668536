export const cardCodeToLabel: Record<string, string> = {
  '3K': '기업 BC',
  '46': '광주은행',
  '71': '롯데카드',
  '30': 'KDB산업은행',
  '31': 'BC카드',
  '51': '삼성카드',
  '38': '새마을금고',
  '41': '신한카드',
  '62': '신협',
  '36': '씨티카드',
  '33': '우리BC카드',
  W1: '우리카드',
  '37': '우체국예금보험',
  '39': '저축은행중앙회',
  '35': '전북은행',
  '42': '제주은행',
  '15': '카카오뱅크',
  '3A': '케이뱅크',
  '24': '토스뱅크',
  '21': '하나카드',
  '61': '현대카드',
  '11': 'KB국민카드',
  '91': 'NH농협카드',
  '34': 'Sh수협은행',

  '6D': '다이너스 클럽',
  '4M': '마스터카드',
  '3C': '유니온페이',
  '7A': '아메리칸 익스프레스',
  '4J': 'JCB',
  '4V': 'VISA'
};
