import React from 'react';
import { css } from '@emotion/react';
import { Button } from '@uniquegood/realworld-studio-design';
import { useHistory, useParams } from 'react-router-dom';
import { useAsync } from 'react-use';
import { StudioMembershipProductResponseResponse } from '@uniquegood/realworld-pay-interface/dist/model/studio-membership-product-response-response';
import { loadTossPayments, TossPaymentsInstance } from '@tosspayments/payment-sdk';
import { MembershipBillingDataResponse } from '@uniquegood/realworld-pay-interface/dist/model';
import {
  membershipApiWithToken,
  membershipCardsApiWithToken,
  membershipProductApiWithToken
} from '@/apis/pay';
import { TOSSPAYMENT_KEY } from '@/env';

function PaymentSuccess() {
  const history = useHistory();
  const { appId } = useParams<AppParam>();
  const tossPaymentsRef = React.useRef<TossPaymentsInstance>();

  React.useEffect(() => {
    (async () => {
      tossPaymentsRef.current = await loadTossPayments(TOSSPAYMENT_KEY);
    })();
  }, []);

  const sendPaymentState = useAsync(async () => {
    if (!appId) return;

    const query = new URLSearchParams(window.location.search);
    const productId = query.get('productId');
    const customerKey = query.get('customerKey');
    const authKey = query.get('authKey');

    const { data: productData } =
      await membershipProductApiWithToken.getAvailableMembershipProductAsync();
    const products = productData as StudioMembershipProductResponseResponse;

    if (!productId || !customerKey || !authKey) throw new Error('잘못된 접근입니다.');

    const product = products.data.find(
      (product) =>
        product.id === productId &&
        (product.membershipProductType as unknown as string) === 'Subscription'
    );

    if (!product) throw new Error('잘못된 접근입니다.');

    const { data: billingData } =
      await membershipCardsApiWithToken.registerMembershipBillingDataAsync({
        customerKey,
        authKey
      });

    const currentBilling = billingData as MembershipBillingDataResponse;

    await membershipApiWithToken.submitOrderAsync({
      studioProductIds: [product.id!],
      billingId: currentBilling.id!,
      applicationId: appId,
      calculatedPrice: product.price!
    });
  }, [appId]);

  const returnUrl = React.useMemo(() => {
    const query = new URLSearchParams(window.location.search);
    const returnUrl = query.get('returnUrl');
    return returnUrl || `/apps/${appId}`;
  }, [appId]);

  return (
    <div css={mainContainer}>
      <div css={titleWrap}>{sendPaymentState.loading ? '결제중' : '결제 완료'}</div>
      {sendPaymentState.loading && (
        <div>
          결제 정보를 전송중입니다.
          <br />
          페이지를 벗어나지 마세요...
        </div>
      )}
      {!sendPaymentState.loading && !sendPaymentState.error && (
        <>
          <div css={messageWrap}>요금제가 구독되었다구! 마음껏 즐겨달라구!</div>
          <Button
            type="primary"
            onClick={() => {
              history.push(returnUrl);
            }}
          >
            돌아가기
          </Button>
        </>
      )}
      {sendPaymentState.error && (
        <>
          <div css={messageWrap}>결제가 실패했습니다. 다시 시도해주세요.</div>
          <Button
            type="primary"
            onClick={() => {
              history.push(returnUrl);
            }}
          >
            돌아가기
          </Button>
        </>
      )}
    </div>
  );
}

export default PaymentSuccess;

const mainContainer = css`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 58px);
`;

const titleWrap = css`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
`;

const messageWrap = css`
  font-size: 16px;
  margin-bottom: 16px;
  text-align: center;
`;
