import { useState, useCallback, useRef } from 'react';
import { toast } from '@uniquegood/realworld-studio-design';
import { studioApi } from '@/api';
import { makeFormData } from '@/utils';

const expireTimeout = 1000 * 60 * 5;

export default function useEmailVerification(onVerifyResult: (verified: boolean) => void) {
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(expireTimeout);
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const intervalRef = useRef<number | null>(null);

  const checkEmailVerified = useCallback(async (email: string, isAuto: boolean) => {
    try {
      await studioApi.get('/auth/checkEmailVerify', {
        params: { email, immediate: !isAuto }
      });

      onVerifyResult(true);
    } catch {
      if (isAuto) {
        await checkEmailVerified(email, isAuto);
        return;
      }
      onVerifyResult(false);
    }
  }, []);

  const sendEmail = useCallback(
    (contact: string) => {
      (async () => {
        setIsBusy(true);
        setTimer(expireTimeout);

        if (typeof intervalRef.current === 'number') {
          clearInterval(intervalRef.current);
        }

        try {
          const { status } = await studioApi.post(
            '/auth/sendCodeApi',
            makeFormData({
              contact,
              method: 'Email',
              url: `${window.location.origin}/auth/verifyEmail`
            })
          );

          setIsBusy(false);

          if (status === 200) {
            toast({
              type: 'default',
              message: '인증 메일을 전송했어요!'
            });

            setEmailSent(true);

            const startTime = new Date().getTime();
            const intervalId = window.setInterval(() => {
              const now = new Date().getTime();
              const diff = now - startTime;

              if (diff > expireTimeout) {
                setTimer(0);
                window.clearInterval(intervalId);
              } else {
                setTimer(expireTimeout - diff);
              }
            }, 100);

            intervalRef.current = intervalId;

            setTimeout(() => {
              checkEmailVerified(contact, true);
            }, 1000);
          } else {
            throw new Error();
          }
        } catch {
          setIsBusy(false);

          toast({
            type: 'error',
            message: '인증 메일 전송에 실패했습니다. 다시 시도해주세요.'
          });
        }
      })();
    },
    [onVerifyResult]
  );

  const verifyNow = useCallback(
    (email: string) => checkEmailVerified(email, false),
    [onVerifyResult]
  );

  return [sendEmail, verifyNow, isBusy, emailSent, timer] as const;
}
