import React from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { Button, ButtonProps, color, spacing } from '@uniquegood/realworld-studio-design';
import { onMobile } from '@/styles/responsive';
import { NAVIGATION_DEFAULT_WIDTH, NAVIGATION_GAME_EDIT_WIDTH } from './Navigation';
import useNavigationType from './NavigationTopBar/hooks/useNavigationType';

interface BottomFloatingBarProps extends Pick<ButtonProps, 'form' | 'disabled' | 'loading'> {
  children?: React.ReactNode;
  onSave?(): unknown;
  cssStyle?: SerializedStyles | SerializedStyles[];
  form?: string;
}

export default function BottomFloatingBar({
  children,
  onSave,
  cssStyle,
  ...buttonProps
}: BottomFloatingBarProps) {
  const { navigation } = useNavigationType();

  const leftPosition =
    navigation.type &&
    css`
      width: calc(
        100% -
          ${navigation.type === 'default' ? NAVIGATION_DEFAULT_WIDTH : NAVIGATION_GAME_EDIT_WIDTH}
      );
    `;

  return (
    <nav aria-label="하단 플로팅 바" css={[WrapperStyle, leftPosition, cssStyle]}>
      {children ?? ''}
      <Button icon="save_solid" type="primary" onClick={onSave} htmlType="submit" {...buttonProps}>
        저장
      </Button>
    </nav>
  );
}

export const BOTTOM_FLOATING_BAR_HEIGHT = '62px';

const WrapperStyle = css`
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 2;

  background-color: ${color.surface_default_default};
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.2);

  height: ${BOTTOM_FLOATING_BAR_HEIGHT};
  width: 100%;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  padding: 0 24px;

  ${onMobile} {
    padding: 0 16px;
    width: 100%;
  }

  > *:not(:last-of-type) {
    margin-right: ${spacing.margin.xlarge2};
  }
`;

export const SpacingForBottomFloatingBar = css`
  margin-bottom: calc(${BOTTOM_FLOATING_BAR_HEIGHT} + ${spacing.common.large});

  ${onMobile} {
    margin-bottom: calc(${BOTTOM_FLOATING_BAR_HEIGHT} + ${spacing.common.medium});
  }
`;
