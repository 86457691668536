import { css } from '@emotion/react';
import {
  CheckBox,
  color,
  FormGroup,
  Icon,
  Input,
  Modal,
  shadow,
  spacing,
  ToggleButton,
  Tooltip,
  Typography
} from '@uniquegood/realworld-studio-design';
import React, { useEffect, useState } from 'react';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';

import { QuestModalImage } from '@/pages/missions/assets';
import { coreApi } from '@/api';
import { Flex, MarginBottom16, MarginBottom24, MarginRight16 } from '@/styles';
import { useQuest, useModalState } from '@/hooks';
import { invalidFieldBoilerplate, toast } from '@/utils';
import { onMobile } from '@/styles/responsive';
import type { QuestRequestBody } from '@/pages/missions/types';
import { BOTTOM_FLOATING_BAR_HEIGHT } from '@/components/BottomFloatingBar';
import ContainerHeader from '@/components/ContainerHeader';
import ActionHandler from '@/components/ActionHandler';
import TooltipContent from '../TooltipContent';
import { checkboxGroupLayout } from '../../styles';
import { PageViewEventName, SaveEventName, track } from '@/track';

interface FormData extends QuestRequestBody {
  forceChangeIsLocked?: boolean;
  forceChangeIsVisible?: boolean;
  isCustomizeButtonText: boolean;
}

const EditQuestPage = () => {
  const history = useHistory();
  const { appId, projectId, scenarioId, missionId, questId } = useParams<AppParam>();
  const { quest, mutate } = useQuest();

  const { modal, openModal, closeModal } = useModalState();

  const [isDeleting, setIsDeleting] = useState(false);

  const { control, handleSubmit, reset, formState } = useForm<FormData>();

  useEffect(() => {
    track.onPageView({ pageViewEventName: PageViewEventName.view_editgame_editmission_quest });
  }, []);

  useEffect(() => {
    const defaultValues: FormData | undefined = quest && {
      name: quest.name,
      description: quest.description,
      label: quest.label,
      isVisibleDefault: quest.isVisibleDefault,
      isLockedDefault: quest.isLockedDefault,
      isCustomizeButtonText: quest.name !== quest.label
    };

    reset(defaultValues);
  }, [quest?.id]);

  const onClickDeleteButton = () => {
    openModal({
      size: 'small',
      title: '퀘스트를 삭제하시겠어요?',
      children: (
        <Typography as="p" type="body">
          삭제된 퀘스트는 다시 복구할 수 없어요
        </Typography>
      ),
      primaryAction: {
        content: '삭제',
        type: 'destructive',
        icon: 'trash_alt_solid',
        onAction: onDeleteQuest
      },
      secondaryAction: {
        content: '취소',
        onAction: closeModal
      }
    });
  };

  const onDeleteQuest = async () => {
    setIsDeleting(true);

    try {
      await coreApi.delete(`/apps/${appId}/quests/${questId}`);

      history.push(
        `/apps/${appId}/projects/${projectId}/scenarios/${scenarioId}/missions/${missionId}`
      );
      toast({ message: '퀘스트를 삭제했어요' });
    } finally {
      setIsDeleting(false);
      mutate();
      closeModal();
    }
  };

  const onSubmit = handleSubmit(async ({ isCustomizeButtonText, ...rest }) => {
    const label = isCustomizeButtonText ? rest.label ?? rest.name : rest.name;

    if (rest.forceChangeIsVisible || rest.forceChangeIsLocked) {
      openModal({
        title: '전체 강제 적용이 포함되어 있어요',
        children: (
          <>
            <Typography as="p" type="body" cssStyle={MarginBottom16}>
              전체 강제 적용이 앱에 반영되려면 시간이 걸려요.
              <br />
              정말 전체 강제 적용을 포함해서 저장하시겠어요?
            </Typography>
            <Typography as="p" type="body">
              (누적 플레이 인원 기준 1,000명당 대략 1분씩 소요)
            </Typography>
          </>
        ),
        primaryAction: {
          icon: 'save_solid',
          content: '저장',
          onAction,
          loading: formState.isSubmitting
        },
        secondaryAction: {
          content: '취소',
          onAction: closeModal
        }
      });
      return;
    }
    await onAction();

    async function onAction() {
      const {
        data: { name, description, isVisibleDefault, isLockedDefault }
      } = await coreApi.put<Quest>(`/apps/${appId}/quests/${questId}`, {
        ...rest,
        label
      });
      track.onSave({ saveEventName: SaveEventName.save_editgame_mission_quest });

      reset({
        name,
        description,
        isVisibleDefault,
        isLockedDefault,
        isCustomizeButtonText,
        forceChangeIsLocked: false,
        forceChangeIsVisible: false
      });
      await mutate();
      closeModal();
    }
  });

  const onClickEditQuest = () => {
    openModal({
      size: 'medium',
      title: '퀘스트 정보 수정',
      cssStyle: QuestModalLayout,
      children: <EditQuestContent control={control} onSubmit={onSubmit} />,
      primaryAction: {
        content: '저장',
        icon: 'save_solid',
        htmlType: 'submit',
        form: 'modal'
      },
      secondaryAction: {
        content: '닫기',
        onAction: closeModal
      },
      leftAction: {
        content: '퀘스트 삭제',
        destructive: true,
        onAction: onClickDeleteButton
      }
    });
  };

  useEffect(() => {
    if (!modal.open) {
      reset();
    }
  }, [modal.open]);

  return (
    <div css={Layout}>
      <Modal
        {...modal}
        primaryAction={{
          ...modal.primaryAction,
          loading: isDeleting || formState.isSubmitting,
          disabled: isDeleting
        }}
      />
      <ContainerHeader
        editButtonText="퀘스트 정보 수정"
        onClick={onClickEditQuest}
        cssStyle={[MarginBottom16, ContainerHeaderLayout]}
      >
        {quest?.name} {quest?.name !== quest?.label && `[${quest?.label}]`}
      </ContainerHeader>

      {quest && <ActionHandler key={questId} availabelType="quest" />}
    </div>
  );
};

export default EditQuestPage;

const Layout = css`
  width: 100%;
  background-color: ${color.white};
  padding: 0 ${spacing.common.large} calc(${spacing.margin.xlarge2} + ${BOTTOM_FLOATING_BAR_HEIGHT});
  border: 1px solid ${color.border_default_subdued};
  ${shadow.card};
  overflow-y: auto;

  ${onMobile} {
    padding: ${spacing.common.medium};
    padding-bottom: 50px;
  }
`;

const ContainerHeaderLayout = css`
  button {
    min-width: 155px;
  }
`;

const QuestModalLayout = css`
  & .preview-img {
    width: 183px;
    height: 293px;
    object-fit: cover;
  }

  ${onMobile} {
    & .preview-img {
      display: none;
    }
  }
`;

const QuestModalFormGroup = css`
  margin-left: ${spacing.common.medium};
  width: 100%;

  ${onMobile} {
    margin-left: 0px;
  }
`;

const CheckBoxWrapStyle = css`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.common.small};

  & > span:last-of-type {
    margin-left: ${spacing.common.xsmall};
  }

  & > div {
    width: 100%;
  }
`;

const QuestButtonTextWrapStyle = css`
  border-radius: ${spacing.borderRadius.medium};
`;

const ToggleWrapStyle = css`
  display: flex;
  & > div {
    & > div {
      margin-bottom: ${spacing.common.small};
      & > span:first-of-type {
        margin-right: ${spacing.common.small};
      }
    }
    width: 50%;
  }

  ${onMobile} {
    display: initial;

    & > div {
      &:first-of-type {
        margin-bottom: ${spacing.common.medium};
      }
      width: 100%;
    }
  }
`;

interface EditQuestContentProps extends Pick<UseFormReturn<FormData>, 'control'> {
  onSubmit: React.FormEventHandler<HTMLFormElement>;
}

function EditQuestContent({ control, onSubmit }: EditQuestContentProps) {
  return (
    <form id="modal" onSubmit={onSubmit}>
      <div css={[Flex, MarginBottom24]}>
        <img alt="퀘스트 프리뷰" src={QuestModalImage.preview} className="preview-img" />
        <div css={QuestModalFormGroup}>
          <Controller
            control={control}
            name="name"
            rules={{ required: '퀘스트 이름' }}
            render={({ field: { value, onChange }, fieldState: { invalid } }) => (
              <FormGroup
                cssStyle={MarginBottom16}
                errorText={invalidFieldBoilerplate(invalid)}
                label="퀘스트 이름"
                requiredIndicator
              >
                <Input value={value ?? ''} onChange={onChange} error={invalid} />
              </FormGroup>
            )}
          />
          <FormGroup cssStyle={MarginBottom16} label="퀘스트 설명">
            <Controller
              control={control}
              name="description"
              render={({ field: { value, onChange } }) => (
                <Input multiline value={value} onChange={onChange} />
              )}
            />
          </FormGroup>

          <Controller
            control={control}
            name="isCustomizeButtonText"
            render={({ field: { value, onChange } }) => (
              <>
                <div css={CheckBoxWrapStyle}>
                  <CheckBox checked={value} onChange={onChange}>
                    퀘스트 버튼 문구를 다르게 설정
                  </CheckBox>
                  <Tooltip
                    content={
                      <TooltipContent
                        title="퀘스트 버튼 문구를 다르게 설정"
                        description="퀘스트 화면 상단에 보이는 '퀘스트 이름'과, 미션 화면에서 보이는 '퀘스트 버튼'의 문구를 다르게 설정하고 싶을 때 사용해요."
                        src={QuestModalImage.label}
                      />
                    }
                  >
                    <Icon icon="question_circle_solid" color={color.icon_disabled} />
                  </Tooltip>
                </div>
                {value && (
                  <div css={QuestButtonTextWrapStyle}>
                    <FormGroup label="퀘스트 버튼 문구">
                      <Controller
                        control={control}
                        name="label"
                        render={({ field: { value, onChange } }) => (
                          <Input value={value ?? ''} onChange={onChange} />
                        )}
                      />
                    </FormGroup>
                  </div>
                )}
              </>
            )}
          />
        </div>
      </div>
      <div css={ToggleWrapStyle}>
        <FormGroup
          label="퀘스트 버튼 기본 표시 여부"
          cssStyle={checkboxGroupLayout}
          labelTooltip={{
            content: (
              <TooltipContent
                title="퀘스트 버튼 표시 / 미표시"
                description="미션 화면에서 퀘스트 버튼의 공개 여부를 설정해요"
                src={QuestModalImage.visible}
              />
            )
          }}
        >
          <Controller
            control={control}
            name="isVisibleDefault"
            render={({ field: { value, onChange } }) => (
              <ToggleButton checked={value} onChange={onChange} cssStyle={MarginRight16}>
                {value ? '표시' : '미표시'}
              </ToggleButton>
            )}
          />
          <Controller
            control={control}
            name="forceChangeIsVisible"
            render={({ field: { value, onChange } }) => (
              <CheckBox onChange={onChange} checked={value}>
                전체 강제 적용
              </CheckBox>
            )}
          />
        </FormGroup>
        <FormGroup
          label="퀘스트 버튼 기본 잠금 여부"
          cssStyle={checkboxGroupLayout}
          labelTooltip={{
            content: (
              <TooltipContent
                title="퀘스트 버튼 잠금 / 미잠금"
                description="미션 화면에서 퀘스트 버튼의 잠금 여부를 설정할 수 있어요"
                src={QuestModalImage.locked}
              />
            )
          }}
        >
          <Controller
            control={control}
            name="isLockedDefault"
            render={({ field: { value, onChange } }) => (
              <ToggleButton checked={value} onChange={onChange} cssStyle={MarginRight16}>
                {value ? '잠금' : '미잠금'}
              </ToggleButton>
            )}
          />
          <Controller
            control={control}
            name="forceChangeIsLocked"
            render={({ field: { value, onChange } }) => (
              <CheckBox onChange={onChange} checked={value}>
                전체 강제 적용
              </CheckBox>
            )}
          />
        </FormGroup>
      </div>
    </form>
  );
}
