import moize from 'moize';
import { coreApi } from '@/api';

export interface PostRefreshAccessTokenProps {
  accessToken: string;
  refreshToken: string;
}

interface PostRefreshAccessTokenResponse {
  refreshToken: string;
  token: string;
}

export async function postRefreshAccessToken(props: PostRefreshAccessTokenProps) {
  const { data } = await coreApi.post<PostRefreshAccessTokenResponse>(`/api/auth/refresh`, props);

  return data;
}
