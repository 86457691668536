import { FormGroup, Input } from '@uniquegood/realworld-studio-design';
import { Controller } from 'react-hook-form';
import { invalidFieldBoilerplate, ModalContentProps } from '@/utils';
import { MarginBottom24 } from '@/styles';

export type FormData = Pick<ChatScene, 'name' | 'description'>;

export default function SceneModalContent({ control, onSubmit }: ModalContentProps<FormData>) {
  return (
    <form id="modal" onSubmit={onSubmit}>
      <Controller
        control={control}
        name="name"
        rules={{ required: true }}
        render={({ field, fieldState: { invalid } }) => (
          <FormGroup
            id="name"
            label="장면 이름"
            errorText={invalidFieldBoilerplate(invalid)}
            requiredIndicator
            cssStyle={MarginBottom24}
          >
            <Input id="name" {...field} />
          </FormGroup>
        )}
      />
      <Controller
        control={control}
        name="description"
        render={({ field }) => (
          <FormGroup id="description" label="장면 설명">
            <Input id="description" {...field} />
          </FormGroup>
        )}
      />
    </form>
  );
}

export function transformToFormData(chatbot: ChatScene): FormData {
  const { name, description } = chatbot;

  return { name, description };
}
