import React, { useRef, memo } from 'react';
import { css } from '@emotion/react';
import { color, Modal, shadow, spacing } from '@uniquegood/realworld-studio-design';

import { useHistory, useParams } from 'react-router-dom';
import { onMobile } from '@/styles/responsive';
import {
  DraggableMissionList,
  DraggableQuestList,
  MissionCard
} from '@/pages/missions/components/missionList';
import SquareButton from '@/components/SquareButton';

import { MissionViewType } from '@/pages/missions/types';
import { useIsMobile, useModalState, useRequest } from '@/hooks';
import { createNewMission } from '../../utils';
import { ClickEventName, track } from '@/track';

interface CardListProps {
  missionView: MissionViewType;
  root: HTMLElement | null;
}

const CardList = ({ root, missionView }: CardListProps) => {
  const { appId, projectId, scenarioId, missionId, questId } = useParams<AppParam>();

  const { modal, openModal, closeModal } = useModalState();
  const history = useHistory();

  const isMobile = useIsMobile();
  const { data: missions, mutate } = useRequest<Mission[]>(
    `/apps/${appId}/scenarios/${scenarioId}/missions`
  );

  const createMissionPage = `/apps/${appId}/projects/${projectId}/scenarios/${scenarioId}/missions/new`;
  const isCreateNewMission = history.location.pathname === createMissionPage;

  const openChangeMissionReOrderModal = () => {
    openModal({
      size: 'medium',
      title: '미션 순서 변경',
      children: <DraggableMissionList missions={missions} />,
      secondaryAction: { content: '닫기', onAction: closeModal }
    });
  };

  const itemsRef = useRef<HTMLElement[]>([]);

  async function onCreateMission() {
    const currentIndex =
      missions?.findIndex((mission) => mission.id.toString() === missionId) ?? -1;
    const order = currentIndex >= 0 ? currentIndex + 1 : undefined;

    const mission = await createNewMission({ appId, scenarioId, order });
    track.onClick({
      clickEventName: ClickEventName.click_editgame_mission_new_mission
    });
    await mutate();
    history.push(
      `/apps/${appId}/projects/${projectId}/scenarios/${scenarioId}/missions/${mission.id}`
    );
  }

  return (
    <div css={MissionDirection}>
      <Modal {...modal} />

      {missions &&
        missions.length > 0 &&
        missions.map((mission, index) => {
          const isSelected = questId ? false : missionId === mission.id.toString();
          const { quests } = mission;

          const onClickMissionCard = () => {
            if (root && itemsRef.current.length > index) {
              const { vertical, horizontal } = smoothScroll(root, itemsRef.current[index]);

              if (isMobile) {
                horizontal();
              } else {
                vertical();
              }
            }
          };

          const setTargetRef = (el: HTMLDivElement | null) => {
            if (el) {
              itemsRef.current[index] = el;
            }
          };

          return (
            <div key={mission.id} css={MissionInfoCardStyle} ref={(el) => setTargetRef(el)}>
              <MissionCard
                onClick={onClickMissionCard}
                hasImage={missionView === 'CardView'}
                mission={mission}
                isSelected={isSelected}
              />
              <DraggableQuestList quests={quests} mission={mission} />
            </div>
          );
        })}
      <div css={SquareButtonGroupLayout}>
        <SquareButton icon="plus_solid" onClick={onCreateMission} disabled={isCreateNewMission}>
          새 미션
        </SquareButton>
        <SquareButton
          icon="exchange_alt_solid"
          onClick={openChangeMissionReOrderModal}
          disabled={!!missions && missions.length === 0}
        >
          순서 변경
        </SquareButton>
      </div>
    </div>
  );
};

function smoothScroll(scrollContainer: HTMLElement, { offsetTop, offsetLeft }: HTMLElement) {
  function vertical() {
    const headerHeight = 58;
    const gap = 24;

    const offset = headerHeight + gap;

    scrollContainer.scrollTo({
      top: offsetTop - offset,
      behavior: 'smooth'
    });
  }

  function horizontal() {
    const itemPadding = 16;
    const gap = 8;

    const offset = itemPadding + gap;

    scrollContainer.scrollTo({
      left: offsetLeft - offset,
      behavior: 'smooth'
    });
  }

  return { vertical, horizontal };
}

export default memo(CardList);

const MissionDirection = css`
  width: 192px;
  margin-bottom: ${spacing.common.xsmall};

  ${onMobile} {
    display: flex;
    margin-bottom: 0px;
    width: initial;
  }
`;

const MissionInfoCardStyle = css`
  background-color: ${color.palette_gray_tint_01};
  ${shadow.popup};
  border-radius: ${spacing.common.medium};
  padding: ${spacing.common.medium};
  display: flex;
  flex-direction: column;
  margin-top: ${spacing.common.medium};
  cursor: pointer;
  &:first-of-type {
    margin-top: 0px;
  }

  ${onMobile} {
    flex-direction: row;
    margin-top: 0px;
    margin-right: ${spacing.common.small};

    &:last-of-type {
      margin-right: 0px;
    }
  }
`;

const SquareButtonGroupLayout = css`
  position: fixed;
  bottom: 28px;

  & > button:last-of-type {
    margin-left: ${spacing.common.small};
  }

  ${onMobile} {
    position: initial;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 ${spacing.common.medium} 0 ${spacing.common.small};

    & > button:last-of-type {
      margin-top: ${spacing.common.small};
      margin-left: 0px !important;
    }
  }
`;
