import React, { memo, useEffect, useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { css } from '@emotion/react';
import {
  CheckBox,
  color,
  FormGroup,
  Icon,
  Input,
  Modal,
  spacing,
  textStyleButton,
  Tooltip
} from '@uniquegood/realworld-studio-design';
import { useSWRConfig } from 'swr';

import { coreApi } from '@/api';
import {
  ButtonResetStyle,
  Flex,
  KeyboardFocusStyle,
  MarginBottom16,
  MarginBottom24
} from '@/styles';
import { onMobile } from '@/styles/responsive';
import { QuestButton } from '@/pages/missions/components/missionList';
import { IReOrder, QuestRequestBody } from '@/pages/missions/types';
import { MISSION_NAV_CARD_WIDTH } from '@/pages/missions/utils';
import { toast, invalidFieldBoilerplate, reorder } from '@/utils';
import { useDidUpdateEffect, useModalState } from '@/hooks';

import { QuestModalImage } from '../../assets';
import TooltipContent from '../TooltipContent';
import { SaveEventName, track } from '@/track';

interface DraggableQuestListProps {
  quests: Quest[];
  mission: Mission;
}

type FormData = QuestRequestBody & { isCustomizeButtonText: boolean };

function DraggableQuestList({ quests, mission }: DraggableQuestListProps) {
  const { appId, projectId, scenarioId, questId } = useParams<AppParam>();
  const history = useHistory();

  const { mutate } = useSWRConfig();

  const missionKey = `/apps/${appId}/scenarios/${scenarioId}/missions`;

  const { control, handleSubmit, reset, formState } = useForm<FormData>();
  const { modal, openModal, closeModal } = useModalState();

  const [_quests, setQuests] = useState<Quest[]>(quests);

  const onDragEndQuests = ({ destination, source }: DropResult) => {
    if (!destination || !_quests) return;

    const newQuests = reorder(_quests, source.index, destination.index);

    setQuests(newQuests);

    handleChangeQuestOrder({ from: source.index, to: destination.index });
  };

  const handleChangeQuestOrder = async (data: IReOrder) => {
    await coreApi.put(`/apps/${appId}/missions/${mission.id}/quests/order`, data);
    await mutate(missionKey);
  };

  const onSubmit = handleSubmit(async (data) => {
    const { data: createdQuest } = await coreApi.post<QuestResponseModel>(
      `/apps/${appId}/missions/${mission.id}/quests`,
      {
        ...data,
        label: data.label ?? data.name
      }
    );
    track.onSave({ saveEventName: SaveEventName.save_editgame_mission_new_quest });
    await mutate(missionKey);
    closeModal();

    history.push(
      `/apps/${appId}/projects/${projectId}/scenarios/${scenarioId}/missions/${mission.id}/quests/${createdQuest.id}`
    );
    toast({ message: '퀘스트를 생성했어요' });
  });

  const openCreateQuestModal = () => {
    openModal({
      size: 'medium',
      title: '퀘스트 생성',
      cssStyle: QuestModalLayout,
      children: <CreateQuestContent control={control} onSubmit={onSubmit} />,
      primaryAction: {
        content: '저장',
        icon: 'save_solid',
        htmlType: 'submit',
        form: 'modal',
        loading: formState.isSubmitting,
        disabled: formState.isSubmitting
      },
      secondaryAction: {
        content: '닫기',
        onAction: closeModal
      }
    });
  };

  useDidUpdateEffect(() => {
    setQuests(quests);
  }, [quests]);

  useEffect(() => {
    if (!modal.open) {
      reset();
    }
  }, [modal.open]);

  return (
    <div css={QuestButtonGroupStyle}>
      <Modal
        {...modal}
        primaryAction={{
          ...modal.primaryAction,
          loading: formState.isSubmitting,
          disabled: formState.isSubmitting
        }}
      />

      <DragDropContext onDragEnd={onDragEndQuests}>
        <Droppable droppableId="quest-list">
          {(provided) => (
            <ul ref={provided.innerRef} {...provided.droppableProps}>
              {_quests.map((quest, index) => {
                const isQuestSelected = questId === quest.id.toString();

                return (
                  <QuestButton
                    key={quest.id}
                    mission={mission}
                    quest={quest}
                    index={index}
                    isHidden={!quest.isVisibleDefault}
                    isLocked={quest.isLockedDefault}
                    selected={isQuestSelected}
                  />
                );
              })}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>

      <button
        type="button"
        onClick={openCreateQuestModal}
        css={[ButtonResetStyle, QuestButtonStyle, textStyleButton, KeyboardFocusStyle]}
      >
        <Icon icon="plus_solid" size="16px" color={color.action_primary_default} />새 퀘스트
      </button>
    </div>
  );
}

export default memo(DraggableQuestList);

const QuestButtonGroupStyle = css`
  display: flex;
  flex-direction: column;
  width: ${MISSION_NAV_CARD_WIDTH};

  ${onMobile} {
    margin-left: 12px;
  }
`;

const QuestButtonStyle = css`
  margin-top: ${spacing.common.small};
  padding: ${spacing.common.small} 12px;
  border-radius: ${spacing.common.medium2};
  background-color: ${color.surface_default_default};
  color: ${color.text_default};
  width: 100%;
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${color.surface_default_hovered};
  }
  &:active {
    background-color: ${color.surface_default_pressed};
  }

  & > .IconWrap {
    margin: 0px;
    margin-right: ${spacing.common.xsmall};
  }

  color: ${color.action_primary_default};

  &:disabled {
    background-color: ${color.surface_default_default};
    cursor: not-allowed;
    & > span > svg,
    p {
      color: ${color.text_disabled};
    }
  }
`;

const QuestModalLayout = css`
  & .preview-img {
    width: 183px;
    height: 293px;
    object-fit: cover;
  }

  ${onMobile} {
    & .preview-img {
      display: none;
    }
  }
`;

const QuestModalFormGroup = css`
  margin-left: ${spacing.common.medium};
  width: 100%;

  ${onMobile} {
    margin-left: 0px;
  }
`;

const CheckBoxWrapStyle = css`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.common.small};

  & > span:last-of-type {
    margin-left: ${spacing.common.xsmall};
  }

  & > div {
    width: 100%;
  }
`;

const QuestButtonTextWrapStyle = css`
  border-radius: ${spacing.borderRadius.medium};
`;

const ToggleWrapStyle = css`
  display: flex;
  & > div {
    & > div {
      margin-bottom: ${spacing.common.small};
      & > span:first-of-type {
        margin-right: ${spacing.common.small};
      }
    }
    width: 50%;
  }

  ${onMobile} {
    display: initial;

    & > div {
      &:first-of-type {
        margin-bottom: ${spacing.common.medium};
      }
      width: 100%;
    }
  }
`;

interface CreateQuestContentProps extends Pick<UseFormReturn<FormData>, 'control'> {
  onSubmit: React.FormEventHandler<HTMLFormElement>;
}

function CreateQuestContent({ control, onSubmit }: CreateQuestContentProps) {
  return (
    <form id="modal" onSubmit={onSubmit}>
      <div css={[Flex, MarginBottom24]}>
        <img alt="퀘스트 프리뷰" src={QuestModalImage.preview} className="preview-img" />
        <div css={QuestModalFormGroup}>
          <Controller
            control={control}
            name="name"
            rules={{ required: '퀘스트 이름' }}
            render={({ field: { value, onChange }, fieldState: { invalid } }) => (
              <FormGroup
                cssStyle={MarginBottom16}
                errorText={invalidFieldBoilerplate(invalid)}
                label="퀘스트 이름"
                requiredIndicator
              >
                <Input value={value ?? ''} onChange={onChange} error={invalid} />
              </FormGroup>
            )}
          />
          <FormGroup cssStyle={MarginBottom16} label="퀘스트 설명">
            <Controller
              control={control}
              name="description"
              render={({ field: { value, onChange } }) => (
                <Input multiline value={value} onChange={onChange} />
              )}
            />
          </FormGroup>

          <Controller
            control={control}
            name="isCustomizeButtonText"
            render={({ field: { value, onChange } }) => (
              <>
                <div css={CheckBoxWrapStyle}>
                  <CheckBox checked={value} onChange={onChange}>
                    퀘스트 버튼 문구를 다르게 설정
                  </CheckBox>
                  <Tooltip
                    content={
                      <TooltipContent
                        title="퀘스트 버튼 문구를 다르게 설정"
                        description="퀘스트 화면 상단에 보이는 '퀘스트 이름'과, 미션 화면에서 보이는 '퀘스트 버튼'의 문구를 다르게 설정하고 싶을 때 사용해요."
                        src={QuestModalImage.label}
                      />
                    }
                  >
                    <Icon icon="question_circle_solid" color={color.icon_disabled} />
                  </Tooltip>
                </div>
                {value && (
                  <div css={QuestButtonTextWrapStyle}>
                    <FormGroup label="퀘스트 버튼 문구">
                      <Controller
                        control={control}
                        name="label"
                        render={({ field: { value, onChange } }) => (
                          <Input value={value ?? ''} onChange={onChange} />
                        )}
                      />
                    </FormGroup>
                  </div>
                )}
              </>
            )}
          />
        </div>
      </div>
      <div css={ToggleWrapStyle}>
        <FormGroup
          label="퀘스트 버튼 기본 표시 여부"
          labelTooltip={{
            content: (
              <TooltipContent
                title="퀘스트 버튼 표시 / 미표시"
                description="미션 화면에서 퀘스트 버튼의 공개 여부를 설정해요"
                src={QuestModalImage.visible}
              />
            )
          }}
        >
          <Controller
            control={control}
            name="isVisibleDefault"
            defaultValue
            render={({ field: { value, onChange } }) => (
              <CheckBox onChange={onChange} checked={value}>
                표시
              </CheckBox>
            )}
          />
        </FormGroup>
        <FormGroup
          label="퀘스트 버튼 기본 잠금 여부"
          labelTooltip={{
            content: (
              <TooltipContent
                title="퀘스트 버튼 잠금 / 미잠금"
                description="미션 화면에서 퀘스트 버튼의 잠금 여부를 설정할 수 있어요"
                src={QuestModalImage.locked}
              />
            )
          }}
        >
          <Controller
            control={control}
            name="isLockedDefault"
            defaultValue={false}
            render={({ field: { value, onChange } }) => (
              <CheckBox onChange={onChange} checked={value}>
                잠금
              </CheckBox>
            )}
          />
        </FormGroup>
      </div>
    </form>
  );
}
