export function pathResolve(...paths: string[]) {
  const parsedPaths = paths.flatMap((path) => path.split('/')).filter(Boolean);

  const resolvedPaths = parsedPaths.reduce((previousValue, path) => {
    if (path === '..') {
      previousValue.pop();
    } else {
      previousValue.push(path);
    }

    return previousValue;
  }, [] as string[]);

  return resolvedPaths.join('/');
}
