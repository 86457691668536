export const duringHours = Array.from({ length: 1000 }).map((_, i) => ({
  label: `${i}`,
  value: `${i}`
}));

export const duringMinutes = Array.from({ length: 12 }).map((_, i) => ({
  label: `${i * 5}`,
  value: `${i * 5}`
}));

export const duringTimes = [
  { label: '15분 내외', value: '15분' },
  { label: '30분 내외', value: '30분' },
  { label: '1시간 내외', value: '1시간 내외' },
  { label: '1시간 30분 내외', value: '1시간 30분 내외' },
  { label: '2시간 내외', value: '2시간 내외' },
  { label: '2시간 30분 내외', value: '2시간 30분 내외' },
  { label: '3시간 내외', value: '3시간 내외' },
  { label: '3시간 30분 내외', value: '3시간 30분 내외' },
  { label: '4시간 이상', value: '4시간 이상' }
];

export const duringTimeValues = duringTimes.map(({ value }) => value);

export const gameTypeHelpText: Record<Project['projectGameType'], string | undefined> = {
  Offline: '야외(특정 장소)에서 플레이하는 게임이에요',
  Online: '핸드폰만으로 플레이하는 게임이에요',
  OfflineKit:
    '키트를 사용하여 야외(특정 장소)에서 플레이하는 게임이에요. 직접 제작한 키트의 다운로드 링크는 ‘키트 수령 방법’에 넣어주세요.',
  OnlineKit:
    '장소 관계 없이 앱과 게임 키트를 사용하여 플레이하는 게임이에요. 직접 제작한 키트의 다운로드 링크는 ‘키트 수령 방법’에 넣어주세요.',
  Etc: undefined
};

const indoorOutdoorPercentage = [...Array(11).keys()]
  .map((item) => (item * 10).toString())
  .reverse();

export const defaultOption = { label: '선택 안 함', value: '선택 안 함' } as const;

export const indoorOption = [
  defaultOption,
  ...indoorOutdoorPercentage.map((value) => ({
    label: `🏢 실내 ${value}`,
    value
  }))
];

export const outdoorOption = [
  defaultOption,
  ...indoorOutdoorPercentage.map((value) => ({
    label: `🌳 실외 ${value}`,
    value
  }))
];

export const genres: { key: Genre; name: string }[] = [
  { key: 'drama', name: '드라마' },
  { key: 'touching', name: '감성' },
  { key: 'comic', name: '코믹' },
  { key: 'fantasy', name: '판타지' },
  { key: 'thriller', name: '스릴러' },
  { key: 'horror', name: '공포' },
  { key: 'detective', name: '추리' },
  { key: 'sci-fi', name: 'SF' },
  { key: 'historic', name: '역사' },
  { key: 'educational', name: '교육' }
];

export const playersCount = Array.from({ length: 51 }).map((_, i) => ({
  label: `${i === 0 ? '제한 없음' : i}`,
  value: `${i}`
}));
