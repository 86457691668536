/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { css } from '@emotion/react';
import { Icon, Label, CheckBox, Tooltip, color } from '@uniquegood/realworld-studio-design';
import useDidUpdateEffect from '../../../../hooks/useDidUpdateEffect';
import { MarginLeft4 } from '../../../../styles';

interface Props {
  label: string;
  defaultValue: boolean;
  description?: string;

  onChange: (data: boolean) => void;
}

const Checkbox = ({ label, defaultValue, description, onChange }: Props) => {
  const [isChecked, setIsChecked] = useState<boolean>(defaultValue);

  useDidUpdateEffect(() => {
    onChange(isChecked);
  }, [isChecked]);

  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      `}
    >
      <Label
        id={label}
        cssStyle={css`
          padding-top: 24px;
          > :nth-of-type(1) {
            display: flex;
            align-items: center;
            margin-right: 24px;
          }
        `}
      >
        <Icon
          icon="check_solid"
          size="16px"
          cssStyle={css`
            margin-right: 6px;
          `}
        />
        {label}
        {description && (
          <Tooltip content={description}>
            <Icon
              cssStyle={MarginLeft4}
              icon="question_circle_solid"
              size="16px"
              color={color.icon_disabled}
            />
          </Tooltip>
        )}
      </Label>
      <CheckBox defaultChecked={defaultValue} onClick={() => setIsChecked(!isChecked)} />
    </div>
  );
};

export default Checkbox;
