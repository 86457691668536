import { css } from '@emotion/react';

import React from 'react';

import { Avatar, AvatarProps, color } from '@uniquegood/realworld-studio-design';
import { CursorPointer, DefaultCursor, textShorteningFromTwoLine } from '@/styles';

interface PersonaProps extends Omit<AvatarProps, 'isVertical'> {
  isEditable?: boolean;
  onClick?(): void;
}

export default function Persona({ onClick, isEditable = false, ...rest }: PersonaProps) {
  return (
    <button
      css={[WrapperStyle, !isEditable ? DefaultCursor : CursorPointer]}
      onClick={onClick}
      type="button"
      aria-label="퍼소나 수정하기"
      disabled={!isEditable}
    >
      <Avatar
        {...rest}
        isVertical
        icon={isEditable ? 'pen_solid' : undefined}
        size="xLarge"
        cssStyle={AvatarStyle}
      />
    </button>
  );
}

const WrapperStyle = css`
  padding: 0;
  border: none;
  background: none;

  :focus-visible {
    outline: 4px solid ${color.interactive_primary_subdued};
    border-radius: 8px;
  }
`;

const AvatarStyle = css`
  > *:last-of-type {
    ${textShorteningFromTwoLine};
    overflow-wrap: anywhere;
  }
`;
