import { css } from '@emotion/react';
import {
  ActionList,
  Avatar,
  Button,
  color,
  Icon,
  shadow,
  spacing,
  Typography,
  usePopover
} from '@uniquegood/realworld-studio-design';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useInView } from 'react-cool-inview';
import { AlignCenterFlex, Flex, MarginLeft8, MarginVertical24, TextAlignCenter } from '@/styles';

import { timeForToday } from '../utils';
import useRequestInfinite from '../hooks/useRequestInfinite';
import LoadingSpinner from '@/components/LoadingSpinner';
import { coreApi } from '@/api';
import WriteComment from './WriteComment';

type CommentsProps = (
  | {
      feedId: string;
      replyParentId?: undefined;
      replyParentName?: undefined;
      feedsType: FeedsType;
      depths?: undefined;
      mutateParentComments?: undefined;
      mutateFeeds(): unknown;
    }
  | {
      feedId: string;
      replyParentId: string;
      replyParentName: string;
      feedsType: FeedsType;
      depths: number;
      mutateParentComments(): unknown;
      mutateFeeds(): unknown;
    }
) &
  React.ComponentPropsWithoutRef<'ul'>;

const pageSize = 10;
const maxDepth = 1;

export default function Comments({
  feedId,
  replyParentId,
  replyParentName,
  feedsType,
  depths = 0,
  mutateParentComments,
  mutateFeeds,
  ...ulProps
}: CommentsProps) {
  const { appId } = useParams<AppParam>();

  const {
    data: comments,
    size,
    setSize,
    mutate,
    isReachedEnd
  } = useRequestInfinite<FeedComments, FeedComment>(
    getKey,
    {},
    { params: { pageSize, filteringBlocks: false } },
    undefined,
    (data) => data.comments,
    (data) => data.count
  );

  function getKey(page: number) {
    const route = replyParentId
      ? `/api/${appId}/community/${feedsType}/${feedId}/comments/${replyParentId}/replies`
      : `/api/${appId}/community/${feedsType}/${feedId}/comments`;

    return `${route}?page=${page + 1}`;
  }

  const { observe, inView } = useInView();

  useEffect(() => {
    if (inView && !isReachedEnd) {
      setSize(size + 1);
    }
  }, [inView]);

  if (!comments) return <LoadingSpinner />;

  if (comments.length === 0 && depths === 0)
    return (
      <>
        {' '}
        <WriteComment
          css={writerCommentStyle}
          feedId={feedId}
          feedsType={feedsType}
          mutate={mutate}
          mutateParentComments={mutateParentComments}
          mutateFeeds={mutateFeeds}
        />
        <Typography type="body" textColor="disabled" cssStyle={TextAlignCenter}>
          아직 올라온 댓글이 없어요
        </Typography>
      </>
    );

  return (
    <>
      <WriteComment
        css={[writerCommentStyle, bottomDividerStyle]}
        feedId={feedId}
        feedsType={feedsType}
        replyParentId={replyParentId}
        replyParentName={replyParentName}
        mutate={mutate}
        mutateParentComments={mutateParentComments}
        mutateFeeds={mutateFeeds}
      />
      <ul {...ulProps}>
        {comments.length === 0 && depths === 0 && (
          <Typography type="body" textColor="disabled" cssStyle={TextAlignCenter}>
            아직 올라온 댓글이 없어요
          </Typography>
        )}
        {comments.map((comment, index) => (
          <IsOpenReplyComment key={comment.id}>
            {({ isOpenReplyComment }) => (
              <>
                <Comment
                  ref={depths === 0 && index === comments.length - 1 ? observe : undefined}
                  feedId={feedId}
                  feedsType={feedsType}
                  comment={comment}
                  mutate={mutate}
                  mutateFeeds={mutateFeeds}
                  mutateParentComments={mutateParentComments}
                  replyParentName={replyParentName}
                  depths={depths}
                  isOpenReplyComment={isOpenReplyComment}
                >
                  {depths === 1 && index === comments.length - 1 && !isReachedEnd && (
                    <button
                      type="button"
                      onClick={() => setSize((size) => size + 1)}
                      css={[AlignCenterFlex, showReplyCommentStyle]}
                    >
                      {line}
                      <span css={MarginLeft8}>답글 더 불러오기</span>
                    </button>
                  )}
                </Comment>
                {depths <= maxDepth && isOpenReplyComment.value && (
                  <div id={comment.id} css={replyCommentStyle}>
                    <Comments
                      feedsType={feedsType}
                      depths={depths + 1}
                      feedId={feedId}
                      replyParentId={comment.id}
                      replyParentName={comment.writer.name}
                      mutateFeeds={mutateFeeds}
                      mutateParentComments={mutate}
                    />
                  </div>
                )}
              </>
            )}
          </IsOpenReplyComment>
        ))}
      </ul>
    </>
  );
}

type IsOpenReplyComment = { value: boolean; toggle: () => void };

interface IsOpenReplyCommentProps {
  children(props: { isOpenReplyComment: IsOpenReplyComment }): JSX.Element;
}

function IsOpenReplyComment({ children }: IsOpenReplyCommentProps) {
  const [isOpenReplyComment, setIsOpenReplyComment] = useState(false);

  const toggle = useCallback(() => setIsOpenReplyComment((value) => !value), []);

  return (
    <>
      {children({
        isOpenReplyComment: {
          value: isOpenReplyComment,
          toggle
        }
      })}
    </>
  );
}

const line = (
  <svg width="52" height="1" viewBox="0 0 52 1" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line y1="0.75" x2="52" y2="0.75" stroke="#797979" strokeWidth="0.5" />
  </svg>
);

interface CommentProps extends React.ComponentPropsWithoutRef<'li'>, MutateComments {
  feedId: string;
  feedsType: FeedsType;
  comment: FeedComment;
  replyParentName?: string;
  depths: number;
  isOpenReplyComment: IsOpenReplyComment;
}

const Comment = React.forwardRef<HTMLLIElement, React.PropsWithChildren<CommentProps>>(
  (
    {
      feedId,
      feedsType,
      comment,
      mutate,
      mutateParentComments,
      mutateFeeds,
      replyParentName,
      depths,
      isOpenReplyComment,
      children,
      ...liProps
    }: CommentProps,
    ref
  ) => {
    const { appId } = useParams<AppParam>();

    const [isOpenPopover, setIsOpenPopover] = useState(false);
    const handleClosePopover = useCallback(() => {
      setIsOpenPopover(false);
    }, []);

    const { popover, anchor } = usePopover({
      positionX: 'right',
      positionY: 'bottom',
      isOpen: isOpenPopover,
      onClose: handleClosePopover
    });

    const [isEditMode, setIsEditMode] = useState(false);

    const replyCommentLabel = useMemo(() => {
      if (comment.replyCount > 0)
        return isOpenReplyComment.value ? '답글 숨기기' : `답글 ${comment.replyCount}개 보기`;

      return isOpenReplyComment.value ? '작성 취소' : '답글 달기';
    }, [comment.replyCount, isOpenReplyComment.value]);

    if (isEditMode)
      return (
        <WriteComment
          css={[writerCommentStyle, bottomDividerStyle]}
          onClose={() => setIsEditMode(false)}
          feedId={feedId}
          feedsType={feedsType}
          comment={comment}
          mutate={mutate}
          mutateFeeds={mutateFeeds}
          replyParentName={replyParentName}
        />
      );

    return (
      <li css={commentDivider} ref={ref} {...liProps}>
        <div css={headerLayout}>
          <div css={Flex}>
            <Avatar
              userName={comment.writer.name}
              source={comment.writer.profileUrl}
              userEmail={timeForToday(comment.createdAt)}
              size={depths === 0 ? 'medium' : 'small'}
              isCreator={comment.isCreator}
            />
            {comment.isCreator &&
              'channelType' in comment.writer &&
              comment.writer.channelType === 'Realworld' && (
                <Icon icon="check_circle_solid" size="20px" cssStyle={officialIconLayout} />
              )}
          </div>

          <Button
            ariaExpanded={anchor['aria-expanded']}
            ref={anchor.ref}
            icon="ellipsis_v_regular"
            type="plain"
            onClick={() => setIsOpenPopover(!isOpenPopover)}
          />
          <div {...popover}>
            <ActionList
              onActionAnyItem={handleClosePopover}
              items={[
                ...(comment.isCreator
                  ? [
                      {
                        content: '수정',
                        onAction: () => {
                          setIsEditMode(true);
                        }
                      }
                    ]
                  : []),
                {
                  content: '삭제',
                  onAction: async () => {
                    await coreApi.delete(
                      'parentId' in comment
                        ? `/api/${appId}/community/${feedsType}/${feedId}/comments/${comment.parentId}/replies/${comment.id}`
                        : `/api/${appId}/community/${feedsType}/${feedId}/comments/${comment.id}`,
                      { params: { isCreator: true } }
                    );
                    await mutate();
                    // 대댓글 추가 시 부모 댓글의 comment.replyCount 를 +1 하기 위한 mutate 함수 호출입니다.
                    if (mutateParentComments) {
                      await mutateParentComments();
                    }
                    await mutateFeeds();
                  },
                  destructive: true
                }
              ]}
            />
          </div>
        </div>
        {replyParentName && (
          <Typography as="span" type="body" cssStyle={replyLabelStyle}>
            @{replyParentName}
          </Typography>
        )}
        <article css={[MarginVertical24, articleLayout]}>{comment.content}</article>
        {depths === 0 && (
          <div css={AlignCenterFlex}>
            {line}
            <button
              type="button"
              aria-expanded={isOpenReplyComment.value}
              aria-controls={comment.id}
              onClick={isOpenReplyComment.toggle}
              css={showReplyCommentStyle}
            >
              {replyCommentLabel}
            </button>
          </div>
        )}
        {children}
      </li>
    );
  }
);

const headerLayout = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const showReplyCommentStyle = css`
  background-color: transparent;
  color: ${color.palette_gray_tint_06};
  border: none;
  cursor: pointer;

  font-size: 12px;
  margin-left: ${spacing.margin.small};
  border-radius: ${spacing.borderRadius.small};

  :hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  :focus-visible {
    outline: 0;
    ${shadow.focus2}
  }
`;

const replyCommentStyle = css`
  margin-left: ${spacing.margin.xlarge4};

  &,
  .username,
  .username + div {
    font-size: 12px;
  }
`;

const officialIconLayout = css`
  margin-top: 0;
  margin-left: ${spacing.margin.small};
`;

const replyLabelStyle = css`
  display: inline-block;
  margin-top: ${spacing.margin.xlarge2};
  color: ${color.action_dark_blue_default};

  font-size: 12px;

  & + article {
    margin-top: 0;
  }
`;

const bottomDividerStyle = css`
  border-bottom: 1px solid ${color.border_default_subdued};
`;

const commentDivider = css`
  ${bottomDividerStyle}

  padding: ${spacing.padding.large} 0;
`;

const writerCommentStyle = css`
  padding: ${spacing.padding.large} 0;
`;

const articleLayout = css`
  overflow-wrap: anywhere;
  white-space: pre-line;
`;
