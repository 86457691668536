import { ButtonGroup, Button } from '@uniquegood/realworld-studio-design';
import { DefaultCursor } from '@/styles';

type Difficulty = ProjectV2['difficulty'];

interface SelectDifficultyProps {
  selectedItem: Difficulty;
  setSelectedItem(action: unknown): void;
}

export default function SelectDifficulty({ selectedItem, setSelectedItem }: SelectDifficultyProps) {
  function applySelectedStyle(difficulty: Difficulty) {
    const isSelected = selectedItem === difficulty;
    return isSelected ? DefaultCursor : [];
  }

  return (
    <ButtonGroup segmented>
      <Button
        css={applySelectedStyle(1)}
        onClick={() => setSelectedItem(1)}
        type={selectedItem === 1 ? 'primary' : 'outlineBasic'}
        pressed={selectedItem === 1}
      >
        매우 쉬움
      </Button>
      <Button
        css={applySelectedStyle(2)}
        onClick={() => setSelectedItem(2)}
        type={selectedItem === 2 ? 'primary' : 'outlineBasic'}
        pressed={selectedItem === 2}
      >
        쉬움
      </Button>
      <Button
        css={applySelectedStyle(3)}
        onClick={() => setSelectedItem(3)}
        type={selectedItem === 3 ? 'primary' : 'outlineBasic'}
        pressed={selectedItem === 3}
      >
        보통
      </Button>
      <Button
        css={applySelectedStyle(4)}
        onClick={() => setSelectedItem(4)}
        type={selectedItem === 4 ? 'primary' : 'outlineBasic'}
        pressed={selectedItem === 4}
      >
        어려움
      </Button>
      <Button
        css={applySelectedStyle(5)}
        onClick={() => setSelectedItem(5)}
        type={selectedItem === 5 ? 'primary' : 'outlineBasic'}
        pressed={selectedItem === 5}
      >
        매우 어려움
      </Button>
    </ButtonGroup>
  );
}
