import type { KeyLoader } from 'swr';
import type { SWRInfiniteConfiguration } from 'swr/infinite';
import useSWRInfinite from 'swr/infinite';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { useState } from 'react';
import { coreApi } from '@/api';

export type Config<Data = unknown, Error = unknown> = SWRInfiniteConfiguration<
  Data,
  AxiosError<Error>
>;

export default function useRequestInfinite<Data, Mapped extends ArrayElement<unknown[]>>(
  route: KeyLoader<Data>,
  swrConfig: Config<Data, Error> = {},
  axiosConfig?: AxiosRequestConfig,
  axiosInstance = coreApi,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  map: (data: Data) => Mapped[],
  lengthMap: (data: Data) => number
) {
  const [totalCount, setTotalCount] = useState<number>();

  const { data: pagingItems, ...rest } = useSWRInfinite<Data, AxiosError<Error>>(
    route,
    async (route: string) => {
      const { data } = await axiosInstance.get<Data>(route, axiosConfig);

      setTotalCount(lengthMap(data));

      return data;
    },
    swrConfig
  );

  const data = pagingItems?.flatMap(map);

  return {
    data,
    isReachedEnd: data?.length === totalCount,
    ...rest
  };
}
