import { Button, Modal } from '@uniquegood/realworld-studio-design';
import { useHistory, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import OnBoardingModalContent, { OnBoardingStep } from '@/components/OnBoardingModalContent';
import { useModalState, useRequest } from '@/hooks';
import { TextAlignCenter } from '@/styles';
import { helpLink } from '@/utils';
import { onBoardingMissionImage } from '../assets';
import { createNewMission } from '../utils';
import { ClickEventName, track } from '@/track';

export default function MissionOnboarding() {
  const { appId, scenarioId, projectId } = useParams<AppParam>();
  const history = useHistory();

  const { data: missions, mutate } = useRequest<Mission[]>(
    `/apps/${appId}/scenarios/${scenarioId}/missions`
  );

  const { modal, openModal, closeModal } = useModalState();

  useEffect(() => {
    if (missions?.length === 0) {
      openModal({
        size: 'big',
        title: '나만의 게임을 만들어보세요!',
        cssStyle: TextAlignCenter,
        children: <OnBoardingModalContent steps={missionOnBoardingSteps} />,
        primaryAction: {
          content: '미션 만들기',
          onAction: async () => {
            const mission = await createNewMission({ appId, scenarioId });
            await mutate();
            closeModal();
            history.push(
              `/apps/${appId}/projects/${projectId}/scenarios/${scenarioId}/missions/${mission.id}`
            );
            track.onClick({
              clickEventName: ClickEventName.click_editgame_mission_landingpopup_createmission
            });
          }
        },
        secondaryAction: {
          content: '닫기',
          onAction: () => {
            closeModal();
            track.onClick({
              clickEventName: ClickEventName.click_editgame_mission_landingpopup_close
            });
          }
        }
      });
    }
  }, [missions]);

  return <Modal {...modal} />;
}

const missionOnBoardingSteps: OnBoardingStep[] = [
  {
    title: '1. 먼저 미션을 만들고 리얼월드 앱에서 확인해보세요',
    description: (
      <>
        리얼월드 앱의 [내 게임] 탭에 들어가면, 내가 만든 게임을 바로 확인할 수 있어요.
        <br />
        [내 게임] 탭에서 보이지 않을 시에는 스튜디오와 동일한 계정으로 로그인했는지 확인해주세요.
      </>
    ),
    imageUrl: onBoardingMissionImage.step1
  },
  {
    title: '2. 미션 안에 보여줄 퀘스트를 생성해보세요',
    description: '퀘스트 버튼을 누르면 각 퀘스트를 편집할 수 있어요',
    imageUrl: onBoardingMissionImage.step2
  },
  {
    title: (
      <>
        3. 도움이 필요하다면{' '}
        <Button url={helpLink.notion.helpCenter} external>
          헬프센터
        </Button>
        를 참고해보세요
      </>
    ),
    imageUrl: onBoardingMissionImage.help
  }
];
