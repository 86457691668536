import { IActionTypeGroups, IAvailabel, IReactionParameter } from '../types';

export const generateRandomKey = () => Math.random().toString(36).substr(2, 11);

export const getIsWidth100 = (styleType: IReactionParameter['style']) => {
  return Boolean(styleType === 'wrap');
};

export const getIsFlex = (suffix: React.ReactNode, prefix: React.ReactNode) => {
  return Boolean(suffix || prefix);
};

export const conditionType = {
  none: 'RealWorld.Conditions.None'
};

export const getBaseCommandType = ({ availabelType }: IAvailabel) => {
  if (availabelType === 'quest') {
    return [
      {
        key: generateRandomKey(),
        type: 'RealWorld.Commands.DisplayHtml',
        parameters: {}
      },
      {
        key: generateRandomKey(),
        type: 'RealWorld.Commands.GoBack',
        parameters: {}
      }
    ];
  }
  if (availabelType === 'chatbot') {
    return [
      {
        key: generateRandomKey(),
        type: 'RealWorld.Commands.SendChatbotMessage',
        parameters: {}
      }
    ];
  }
  return [
    {
      key: generateRandomKey(),
      type: 'RealWorld.Commands.GoBack',
      parameters: {}
    }
  ];
};

export const actionTypeGroups: IActionTypeGroups = {
  General: [
    {
      type: 'RealWorld.ActionTypes.Dummy',
      label: '액션 없음',
      icon: 'times_regular'
    },
    {
      type: 'RealWorld.ActionTypes.Text',
      label: '텍스트 입력',
      icon: 'pen_solid'
    },
    {
      type: 'RealWorld.ActionTypes.DirectionSequence',
      label: '방향 입력',
      icon: 'arrows_alt_solid'
    },
    {
      type: 'RealWorld.ActionTypes.Password',
      label: '4자리 비밀번호 입력',
      icon: 'unlock_solid'
    },
    {
      type: 'RealWorld.ActionTypes.Button',
      label: '버튼 클릭',
      icon: 'arrow_circle_right_solid'
    }
  ],
  Interactive: [
    {
      type: 'RealWorld.ActionTypes.AR',
      label: '[카메라] AR 이미지 인식',
      icon: 'expand_solid'
    },
    {
      type: 'RealWorld.ActionTypes.QR',
      label: '[카메라] QR 코드 인식',
      icon: 'qrcode_solid'
    },
    {
      type: 'RealWorld.ActionTypes.FrameShot',
      label: '[카메라] 프레임 사진 촬영',
      icon: 'camera_solid'
    },
    {
      type: 'RealWorld.ActionTypes.Nfc',
      label: '[NFC] NFC 태그 인식',
      icon: 'tags_solid'
    },
    {
      type: 'RealWorld.ActionTypes.GpsRadar',
      label: '[GPS] 특정 장소로 이동',
      icon: 'compass_solid'
    },
    {
      type: 'RealWorld.ActionTypes.ColorFilter',
      label: '[카메라] 컬러 필터 렌즈',
      icon: 'camera_solid'
    },
    {
      type: 'RealWorld.ActionTypes.CameraOverlap',
      label: '[카메라] 오버랩',
      icon: 'camera_solid'
    }
  ],
  Advanced: [
    {
      type: 'RealWorld.ActionTypes.WebView',
      label: '웹뷰 페이지 활용',
      icon: 'code_solid'
    },
    {
      type: 'RealWorld.ActionTypes.FrameShotV2',
      label: '다기능 프레임 사진 촬영',
      icon: 'camera_solid'
    },
    {
      type: 'RealWorld.ActionTypes.GpsMap',
      label: '[GPS] 특정 여러 장소로 이동',
      icon: 'compass_solid'
    },
    {
      type: 'RealWorld.ActionTypes.ImageDetection',
      label: '[카메라] 이미지 비교',
      icon: 'camera_solid'
    },
    {
      type: 'RealWorld.ActionTypes.ImageDetection.Scratch',
      label: '[카메라] 스크래치 이미지 비교',
      icon: 'camera_solid'
    }
  ]
};
