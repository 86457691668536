import { css } from '@emotion/react';
import { Button, Typography } from '@uniquegood/realworld-studio-design';
import React, { memo } from 'react';

import { onMobile } from '@/styles/responsive';
import { MissionViewType } from '@/pages/missions/types';

interface MissionViewButtonProps {
  missionView: MissionViewType;
  onChangeMissionView: (value: MissionViewType) => void;
}

function MissionViewButton({ missionView, onChangeMissionView }: MissionViewButtonProps) {
  const toggleViewType: MissionViewType = missionView === 'CardView' ? 'ListView' : 'CardView';

  return (
    <>
      <Button
        cssStyle={ChangeButtonStyle}
        type="plain"
        icon={toggleViewType === 'CardView' ? 'th_large_solid' : 'th_list_solid'}
        size="small"
        onClick={() => onChangeMissionView(toggleViewType)}
      >
        <Typography as="p" type="caption" textColor="subdued">
          {toggleViewType === 'CardView' ? '카드뷰' : '목록뷰'}로
        </Typography>
      </Button>
    </>
  );
}

export default memo(MissionViewButton);

const ChangeButtonStyle = css`
  ${onMobile} {
    display: none;
  }
`;
