import { css } from '@emotion/react';
import {
  Button,
  Popover,
  spacing,
  color,
  Icon,
  Typography,
  ListItem,
  shadow,
  textStyleBody
} from '@uniquegood/realworld-studio-design';
import React, { useMemo, useState } from 'react';

import { IconType } from '@uniquegood/realworld-studio-design/dist/types/atoms/Icon/Icon';
import { onMobile } from '@/styles/responsive';

import { reactionCommandDefinitionGroup } from '@/components/ActionHandler/utils/ReactionDefinitions';
import {
  commandKeys,
  IAvailabel,
  IReactionCommandDefinition
} from '@/components/ActionHandler/types';

import { useIsAdminSelector, useIsMobile } from '@/hooks';

import { PopoverListItem } from '@/components/ActionHandler/styles';
import { CursorPointer } from '@/styles';
import { keyPressHandler } from '@/utils';

export interface CommandArray {
  addCommand: (selectedType: IReactionCommandDefinition['type']) => void;
}

export interface CommandPopoverProps extends CommandArray, IAvailabel {
  popoverToggle: () => void;
}

const CommandPopover = ({
  availabelType,
  popoverToggle,
  addCommand,
  ...rest
}: CommandPopoverProps) => {
  const { data: isAdmin } = useIsAdminSelector();
  const commandListGroup = useMemo(
    () => (isAdmin === undefined ? [] : getFilteredCommandKeys(availabelType, isAdmin)),
    [availabelType, isAdmin]
  );
  const [commands, setCommands] = useState<IReactionCommandDefinition[]>();

  return (
    /* eslint-disable */
    <div
      css={AddReactionWrapper}
      onClick={(event) => {
        event.stopPropagation();
      }}
      /* eslint-enable */
    >
      <Button
        type="plainWhite"
        size="large"
        icon="times_regular"
        onClick={popoverToggle}
        cssStyle={PopoverCloseButton}
      />
      <Popover.Section>리액션</Popover.Section>
      {commands ? (
        <>
          <ListItem
            onAction={() => setCommands(undefined)}
            content="이전으로"
            icon="arrow_left_regular"
            cssStyle={css`
              background-color: white;
              border-radius: 0;
            `}
          />
          <ul css={CommandListGroupStyle}>
            {commands.map((command) => (
              <Command
                command={command}
                addCommand={addCommand}
                popoverToggle={popoverToggle}
                availabelType={availabelType}
              />
            ))}
          </ul>
        </>
      ) : (
        <ul css={CommandListGroupStyle}>
          {commandListGroup.map((commandList) => (
            <CommandList
              key={commandList.key}
              items={commandList.items}
              icon={commandList?.icon}
              availabelType={availabelType}
              popoverToggle={popoverToggle}
              onClick={() => setCommands(commandList.items)}
              addCommand={addCommand}
              {...rest}
            >
              {commandList.label}
            </CommandList>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CommandPopover;

const commandKeyArr = Object.keys(reactionCommandDefinitionGroup);

function getFilteredCommandKeys(availabelType: string, isAdmin: boolean) {
  const keys = commandKeyArr.map((key) => {
    const listItem = reactionCommandDefinitionGroup[key];
    const availabelCommandKeys: commandKeys[] = [];

    listItem.items.filter((item) => {
      if (item.disabled && !isAdmin) return null;

      if (item.availableActionAdapters.includes(availabelType)) {
        availabelCommandKeys.push(key);
      }
      return null;
    });

    const set = new Set(availabelCommandKeys);
    const notDuplicateKeys = [...set];

    return notDuplicateKeys;
  });

  const filteredCommandKeys = keys
    .filter((item) => item.length !== 0)
    .map((key) => key[0])
    .map((key) => ({
      ...reactionCommandDefinitionGroup[key],
      items: reactionCommandDefinitionGroup[key].items.filter((item) => !item.disabled || isAdmin)
    }));

  return filteredCommandKeys;
}

const AddReactionWrapper = css`
  ${onMobile} {
    z-index: 2;
    width: calc(100vw - 48px);
    position: fixed;
    left: 16px;
    top: 0;
    transform: translateY(calc((100vh - 100%) / 2 - 32px));
  }

  .Section {
    border-top-left-radius: ${spacing.borderRadius.medium};
    border-top-right-radius: ${spacing.borderRadius.medium};
  }

  li {
    list-style: none;
  }
`;

const PopoverCloseButton = css`
  display: none;

  ${onMobile} {
    display: block;
    margin-bottom: ${spacing.common.small};
  }
`;

const CommandListGroupStyle = css`
  background-color: white;
  ${onMobile} {
    padding: ${spacing.common.small};
  }

  border-bottom-left-radius: ${spacing.borderRadius.medium};
  border-bottom-right-radius: ${spacing.borderRadius.medium};
`;

interface CommandListProps
  extends Pick<CommandPopoverProps, 'popoverToggle' | 'addCommand' | 'availabelType'> {
  items: IReactionCommandDefinition[];
  icon?: IconType;
  children: React.ReactNode;
  onClick?(): void;
}

function CommandList({ onClick, items, icon, children, ...rest }: CommandListProps) {
  const isMobile = useIsMobile();

  return (
    <li
      css={[PopoverListItem, isMobile ? '' : HoverStyle, textStyleBody]}
      onClick={(event) => {
        event.stopPropagation();
        onClick?.();
      }}
      onKeyPress={onClick ? keyPressHandler(onClick) : undefined}
    >
      <ul css={CommandWrap}>
        {items.map((command) => (
          <Command key={command.label} command={command} {...rest} />
        ))}
      </ul>
      {icon && <Icon icon={icon} size="20px" />}
      {children}
      <Icon icon="chevron_right_regular" size="20px" />
    </li>
  );
}

const CommandWrap = css`
  display: none;
  position: absolute;

  padding: ${spacing.common.small};
  border-radius: ${spacing.borderRadius.medium};
  ${shadow.toast};
  background-color: ${color.white};
`;

const HoverStyle = css`
  position: relative;
  &:hover > ul {
    display: block;
    right: -100%;
    top: 0;
    width: 100%;
  }
`;

interface CommandProps
  extends Pick<CommandPopoverProps, 'popoverToggle' | 'addCommand' | 'availabelType'> {
  command: IReactionCommandDefinition;
}

function Command({ popoverToggle, addCommand, command, availabelType }: CommandProps) {
  if (!command.availableActionAdapters.includes(availabelType)) return null;

  function toggler() {
    addCommand(command.type);
    popoverToggle();
  }

  return (
    <li onKeyDown={toggler} onClick={toggler} css={[PopoverListItem, CursorPointer]}>
      <Typography type="body" as="p">
        {command.label}
      </Typography>
    </li>
  );
}
