/* eslint-disable no-param-reassign */
import axios from 'axios';
import {
  MembershipProductsApi,
  MembershipCardsApi,
  MembershipApi,
  Configuration as AuthInterfaceConfiguration
} from '@uniquegood/realworld-pay-interface';
import { APP_ENV } from '@/env';
import { getAccessToken } from '@/auth';

export const AUTH_SERVER_HOST = {
  production: 'realpay.realworld.to',
  development: 'realpay-test.realworld.to'
}[APP_ENV];

const configuration = new AuthInterfaceConfiguration({
  basePath: `https://${AUTH_SERVER_HOST}`
});

const axiosInstance = axios.create();
const axiosInstanceWithToken = axios.create();

axiosInstanceWithToken.interceptors.request.use(async (config) => {
  if (!config.headers) return config;

  const token = await getAccessToken();

  if (!token) throw new Error('No token');
  // 인증서버는 호출하는 시점이 로그인 직후일 때만 토큰이 필요하므로 만료를 체크할 필요가 없다.

  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

export const membershipCardsApiWithToken = new MembershipCardsApi(
  configuration,
  undefined,
  axiosInstanceWithToken
);
export const membershipCardsApi = new MembershipCardsApi(configuration, undefined, axiosInstance);

export const membershipProductApiWithToken = new MembershipProductsApi(
  configuration,
  undefined,
  axiosInstanceWithToken
);
export const membershipProductApi = new MembershipProductsApi(
  configuration,
  undefined,
  axiosInstance
);

export const membershipApiWithToken = new MembershipApi(
  configuration,
  undefined,
  axiosInstanceWithToken
);
export const membershipApi = new MembershipApi(configuration, undefined, axiosInstance);
