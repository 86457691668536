/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { Divider, Typography, spacing, color } from '@uniquegood/realworld-studio-design';
import { RequestRequireProps } from '../../../types';
import { Metadata } from '../../WebviewCustomize';
import InputText from './CustomInput/InputText';
import InputNumber from './CustomInput/InputNumber';
import InputColor from './CustomInput/InputColor';
import InputList from './CustomInput/InputList';
import FileUploader from './CustomInput/FileUploader';
import Checkbox from './CustomInput/Checkbox';

const CustomizeContentsStyle = css`
  height: calc(100% - 48px);
  overflow-y: scroll;
`;

const MarginVertical24 = css`
  margin-top: ${spacing.margin.xlarge2} 0;
`;

const DividerStyle = css`
  background-color: ${color.palette_gray_tintz_2};
  height: 8px;
`;

interface M extends RequestRequireProps {
  editedParams: any;
  metadata: Metadata[];
  onChange: (params: any) => void;
}

const CustomizeContents = ({ editedParams, metadata, onChange, ...requestRequireProps }: M) => {
  function makeKeyValueObject(key: string, value?: string | string[] | boolean) {
    onChange({ [key]: value });
  }

  const elements = metadata.map((m) => {
    return (
      <React.Fragment key={m.label}>
        <div
          css={css`
            background-color: #fff;
            padding: 24px;
          `}
        >
          <Typography type="heading">{m.label}</Typography>
          {m.parameterDefinitions.map((paramDef) => {
            if (paramDef.type === 'text') {
              return (
                <InputText
                  key={paramDef.key}
                  label={paramDef.label}
                  defaultValue={
                    editedParams[paramDef.key] ? editedParams[paramDef.key] : paramDef.defaultValue
                  }
                  description={paramDef.description}
                  placeholder={paramDef.placeholder}
                  onChange={(data) => makeKeyValueObject(paramDef.key, data)}
                />
              );
            }
            if (paramDef.type === 'number') {
              return (
                <InputNumber
                  key={paramDef.key}
                  label={paramDef.label}
                  defaultValue={
                    editedParams[paramDef.key] ? editedParams[paramDef.key] : paramDef.defaultValue
                  }
                  description={paramDef.description}
                  placeholder={paramDef.placeholder}
                  isListItem={false}
                  onChange={(data) => makeKeyValueObject(paramDef.key, data)}
                />
              );
            }
            if (paramDef.type === 'color') {
              return (
                <InputColor
                  key={paramDef.key}
                  defaultValue={
                    editedParams[paramDef.key] ? editedParams[paramDef.key] : paramDef.defaultValue
                  }
                  label={paramDef.label}
                  description={paramDef.description}
                  onChange={(data) => makeKeyValueObject(paramDef.key, data)}
                />
              );
            }
            if (paramDef.type === 'file') {
              return (
                <FileUploader
                  key={paramDef.key}
                  label={paramDef.label}
                  description={paramDef.description}
                  onChange={(data) => makeKeyValueObject(paramDef.key, data)}
                  url={editedParams[paramDef.key]}
                  type={paramDef.key === 'soundUrl' ? 'sound' : 'image'}
                  {...requestRequireProps}
                />
              );
            }
            if (paramDef.type === 'checkbox') {
              return (
                <Checkbox
                  key={paramDef.key}
                  defaultValue={
                    editedParams[paramDef.key] ? editedParams[paramDef.key] : paramDef.defaultValue
                  }
                  label={paramDef.label}
                  description={paramDef.description}
                  onChange={(data) => makeKeyValueObject(paramDef.key, data)}
                />
              );
            }
            if (paramDef.type === 'list') {
              return (
                <InputList
                  key={paramDef.key}
                  label={paramDef.label}
                  description={paramDef.description}
                  defaultValue={
                    editedParams[paramDef.key] ? editedParams[paramDef.key] : paramDef.defaultValue
                  }
                  elementDefinitions={paramDef.elementDefinitions}
                  onChange={(data) => makeKeyValueObject(paramDef.key, data)}
                />
              );
            }
            return <div>존재하지 않는 타입입니다.</div>;
          })}
        </div>
        <Divider cssStyle={[DividerStyle, MarginVertical24]} />
      </React.Fragment>
    );
  });

  return (
    <>
      <div css={CustomizeContentsStyle}>{elements}</div>
    </>
  );
};

export default CustomizeContents;
