import { css } from '@emotion/react';
import {
  ActionList,
  Avatar,
  Button,
  color,
  Popover,
  shadow,
  spacing
} from '@uniquegood/realworld-studio-design';
import React, { useState } from 'react';

import { Height100, MarginLeftAuto, textShortening } from '@/styles';
import { MemberCardActionProps } from '../types';

interface MemberCardProps extends MemberCardActionProps {
  member: User;
}

function MemberCard({
  member,
  handleUpdateMemberFromCurrentApp,
  handleRemoveMemberFromCurrentApp
}: MemberCardProps) {
  const [isMemberToggle, setMemberToggle] = useState(false);

  const allActionListItems = [
    {
      id: 'Owner',
      content: '소유자로 변경',
      onAction: () => {
        handleUpdateMemberFromCurrentApp(member, 'Owner');
        setMemberToggle(false);
      }
    },
    {
      id: 'Author',
      content: '편집자로 변경',
      onAction: () => {
        handleUpdateMemberFromCurrentApp(member, 'Author');
        setMemberToggle(false);
      }
    },
    {
      id: 'Tester',
      content: '테스터로 변경',
      onAction: () => {
        handleUpdateMemberFromCurrentApp(member, 'Tester');
        setMemberToggle(false);
      }
    },
    {
      id: 'destructive',
      content: '삭제',
      onAction: () => handleRemoveMemberFromCurrentApp(member),
      destructive: true
    }
  ];

  const actionListButtonMarkup = (
    <Button
      icon="ellipsis_v_regular"
      type="plain"
      size="small"
      cssStyle={Height100}
      onClick={() => setMemberToggle(true)}
    />
  );

  return (
    <div css={MemberInfoCardStyle}>
      <Avatar userName={member.name} source={member.profileImage} cssStyle={AvatarStyle} />
      <Popover
        active={isMemberToggle}
        activator={actionListButtonMarkup}
        onClose={() => setMemberToggle(false)}
        contentCssStyle={MarginLeftAuto}
      >
        <ActionList size="small" items={allActionListItems} />
      </Popover>
    </div>
  );
}

export default MemberCard;

export const BaseMemberCardStyle = css`
  background-color: ${color.surface_default_default};
  border: 1px solid ${color.border_default_subdued};
  ${shadow.card};
  border-radius: ${spacing.common.small};
`;

const MemberInfoCardStyle = css`
  ${BaseMemberCardStyle};
  padding: ${spacing.borderedPadding.small};
  display: flex;
`;

const AvatarStyle = css`
  > *:last-of-type > .username {
    width: 96px;
    ${textShortening}
  }
`;
