import { Badge, Icon } from '@uniquegood/realworld-studio-design';

export type SectionItem = {
  prefix: JSX.Element;
  suffix?: JSX.Element;
  content: string;
};

export const operation: Record<string, SectionItem> = {
  dashboard: {
    prefix: <Icon icon="chart_bar_solid" size="24px" />,
    content: '대시보드'
  },
  detail: {
    prefix: <Icon icon="info_circle_solid" size="24px" />,
    content: '게임 설정'
  },
  release: {
    prefix: <Icon icon="rocket_solid" size="24px" />,
    content: '출시 관리'
  },
  comments: {
    prefix: <Icon icon="user_friends_solid" size="24px" />,
    content: '커뮤니티 관리'
  }
};

export const operationV2: Record<string, SectionItem> = {
  dashboard: {
    prefix: <Icon icon="chart_bar_solid" size="24px" />,
    content: '대시보드'
  },
  detail: {
    prefix: <Icon icon="info_circle_solid" size="24px" />,
    content: '게임 정보 설정'
  },
  comments: {
    prefix: <Icon icon="user_friends_solid" size="24px" />,
    content: '커뮤니티 관리'
  },
  station: {
    prefix: <Icon icon="gamepad_solid" size="24px" />,
    content: '게임 수정'
  }
};

export const gameEdit: Record<string, SectionItem> = {
  theme: {
    prefix: <Icon icon="paint_brush_solid" size="24px" />,
    content: '테마 편집'
  },
  scenarios: {
    prefix: <Icon icon="gamepad_solid" size="24px" />,
    content: '미션 편집'
  },
  items: {
    prefix: <Icon icon="gift_solid" size="24px" />,
    content: '아이템 편집'
  },
  chatbots: {
    prefix: <Icon icon="comment_dots_solid" size="24px" />,
    suffix: (
      <Badge size="small" type="gray">
        beta
      </Badge>
    ),
    content: '챗봇 편집'
  },
  files: {
    prefix: <Icon icon="folder_open_solid" size="24px" />,
    content: '파일 보관함'
  }
};
