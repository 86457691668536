import { css } from '@emotion/react';
import {
  Button,
  Card,
  CheckBox,
  color,
  Divider,
  FormGroup,
  Icon,
  Input,
  Modal,
  Select,
  SelectInput,
  spacing,
  textStyleBody,
  Typography
} from '@uniquegood/realworld-studio-design';
import ReactTagInput from '@pathofdev/react-tag-input';
import './tagInput.scss';

import { Redirect, useParams } from 'react-router-dom';
import React, { useEffect, useMemo } from 'react';
import { Controller, FieldError, FormProvider, useForm } from 'react-hook-form';
import BottomFloatingBar, { SpacingForBottomFloatingBar } from '@components/BottomFloatingBar';
import { coreApi } from '@/api';
import LoadingSpinner from '@/components/LoadingSpinner';

import { CardItemGap, Gap, MarginBottom10, MarginBottom24, MarginTop16 } from '@/styles';
import Uploader from '@/components/Uploader';
import { invalidFieldBoilerplate, toast } from '@/utils';
import { container650Style } from '@/styles/containerStyles';
import useIsAdminSelector from '@/hooks/useIsAdminSelector';
import useRequest from '@/hooks/useRequest';
import {
  useDidUpdateEffect,
  useModalState,
  useRestrictProjectRoute,
  useWrapFormToConsiderWhitespacesAsEmpty
} from '@/hooks';
import RouteModal from '@/components/RouteModal';

import SelectGameType from './components/SelectGameType';
import SelectDifficulty from './components/SelectDifficulty';
import DifficultyTooltip from './components/DifficultyTooltip';

import {
  indoorOption,
  outdoorOption,
  genres,
  defaultOption,
  duringHours,
  duringMinutes,
  playersCount
} from './const';
import { ModalChildrenStyles } from './styles';
import UnlockCode from './components/UnlockCode';
import { toggler } from './utils';
import FloatingNavigation, { FloatingNavigationProps } from '@/components/FloatingNavigation';
import {
  ClickEventName,
  InputEventName,
  PageViewEventName,
  SaveEventName,
  track,
  UploadEventName
} from '@/track';
import HowToUseImageTooltip from './components/HowToUseImageTooltip';
import SelectKitType from './components/SelectKitType';
import SearchAddress from './components/SearchAddress';
import StartAreaInfo from './components/StartAreaInfo';
import SelectLanguage from './components/SelectLanguage';

const prefix = <Icon icon="info_circle_solid" />;

export default function DetailsPage() {
  useEffect(() => {
    track.onPageView({ pageViewEventName: PageViewEventName.view_gamesetting });
  }, []);

  const { appId, projectId } = useParams<AppParam>();

  const { modal, openModal, closeModal } = useModalState();

  const { data: isAdmin } = useIsAdminSelector();

  const { data: app } = useRequest<RealWorldApp>(`/apps/${appId}`);
  const { data: projectData, mutate } = useRequest<CommonResponseModel<ProjectV2>>(
    `/apps/${appId}/projects/${projectId}`,
    {},
    {
      headers: {
        'x-rwd-api-version': '1.1'
      }
    }
  );
  const { data: releaseStatusData } = useRequest<CommonResponseModel<ReleaseStatus>>(
    `/apps/${appId}/projects/${projectId}/releaseStatus`,
    {},
    { headers: { 'x-rwd-api-version': '1.1' } }
  );

  const project = projectData?.data;
  const releaseStatus = releaseStatusData?.data;

  const { data: templateInfo, isValidating: templateInfoIsValidating } =
    useRequest<ProjectFromTemplate>(`/api/statistics/templates?projectId=${projectId}`);

  const [isRangeSelected, setIsRangeSelected] = React.useState(() => {
    const { minPlayers, maxPlayers } = project ?? {};

    if (minPlayers && maxPlayers) {
      return minPlayers !== maxPlayers;
    }

    return false;
  });
  const [isSameStartEndPoint, setIsSameStartEndPoint] = React.useState(false);

  const methods = useWrapFormToConsiderWhitespacesAsEmpty(useForm<ProjectFormData>());
  const {
    control,
    handleSubmit,
    formState,
    reset,
    setValue,
    setError,
    watch,
    unregister,
    register
  } = methods;

  const kitRequired = watch('kitRequired');

  useEffect(() => {
    if (project) {
      setValue('projectType', project.projectType);
      setValue('kitRequired', project.projectType.toLowerCase().includes('kit'));
      setValue(
        'playTimeInMinutes',
        project.playTimeInMinutes
          ? {
              hours: Math.floor((project.playTimeInMinutes as number) / 60),
              minutes: (project.playTimeInMinutes as number) % 60
            }
          : undefined
      );
      setValue('minPlayers', project.minPlayers);
      setValue('maxPlayers', project.maxPlayers);
      setValue('indoorPercentage', project.offlineInfo?.indoorPercentage);
      setValue('offlineInfo', project.offlineInfo);
      setValue('kitInfo', project.kitInfo || null);
      setValue('images', project.images);

      setIsRangeSelected(project.minPlayers !== project.maxPlayers);
      setIsSameStartEndPoint(
        Boolean(project.offlineInfo?.startAddress) &&
          Boolean(project.offlineInfo?.endAddress) &&
          project.offlineInfo?.startAddress === project.offlineInfo?.endAddress &&
          project.offlineInfo?.startAddressDetail === project.offlineInfo?.endDetailAddress
      );
    }
  }, [project]);

  const gameType = watch('projectType');
  const isOutDoorPlayType = gameType === 'Offline' || gameType === 'OfflineKit';

  const startLatitude = watch('offlineInfo.startLatitude');
  const startLongitude = watch('offlineInfo.startLongitude');

  const minPlayers = Number(watch('minPlayers'));
  const maxPlayers = Number(watch('maxPlayers'));

  const isShowRecommendedPlayers = minPlayers > 0 || maxPlayers > 0;

  useDidUpdateEffect(() => {
    unregister('maxPlayers');
    register('maxPlayers', { value: minPlayers, validate: () => isValid() });
  }, [isRangeSelected]);

  const redirectTo = useRestrictProjectRoute();

  if (!project || !app || redirectTo === undefined || templateInfoIsValidating)
    return <LoadingSpinner />;

  if (typeof redirectTo === 'string') return <Redirect to={redirectTo} />;

  const floatingNavigationItems: FloatingNavigationProps['items'] = [
    { content: '게임 기본 정보', fragment: 'game-basic-settings' },
    { content: '게임 소개', fragment: 'about-game' },
    { content: '게임 상세 정보', fragment: 'detailed-settings' },
    ...(isAdmin ? [{ content: '언락코드 관리', fragment: 'unlock-code' }] : []),
    {
      content: '플레이 내역 삭제',
      fragment: 'delete-all-play-history',
      color: color.action_critical_default
    }
  ];

  const onSubmit = handleSubmit(
    async (data) => {
      const minPlayers = Number(data.minPlayers);
      const maxPlayers = Number(data.maxPlayers);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (data.videoYoutubeId === false) {
        setError('videoYoutubeId', { type: 'pattern' });
        return;
      }

      if (isRangeSelected && minPlayers > maxPlayers && maxPlayers !== 0) {
        setError('minPlayers', { type: 'validate' }, { shouldFocus: true });
        setError('maxPlayers', { type: 'validate' }, { shouldFocus: true });
        return;
      }

      if (data.minPlayers)
        if (
          data.playTimeInMinutes?.hours &&
          data.playTimeInMinutes?.minutes &&
          data.playTimeInMinutes?.hours * 60 + data.playTimeInMinutes?.minutes <= 0
        ) {
          setError('playTimeInMinutes', { type: 'required' });
          return;
        }

      if (Number.isNaN(minPlayers) || (isRangeSelected && Number.isNaN(maxPlayers))) {
        setError('minPlayers', { type: 'pattern' });
        setError('maxPlayers', { type: 'pattern' });
        return;
      }

      if (data.playTimeInMinutes?.hours && data.playTimeInMinutes?.hours > 999) {
        setError('playTimeInMinutes', { type: 'pattern' });
      }

      const playTimeInMinutes =
        (data.playTimeInMinutes?.hours || 0) * 60 + (data.playTimeInMinutes?.minutes || 0);

      const offlineInfo = {
        ...data.offlineInfo,
        startAddressImageId: data.offlineInfo?.startAddressImage?.[0]?.userFileId,
        cityCountyCode: data.offlineInfo?.cityCountyCode,
        endAddress: isSameStartEndPoint
          ? data.offlineInfo?.startAddress
          : data.offlineInfo?.endAddress,
        endDetailAddress: isSameStartEndPoint
          ? data.offlineInfo?.startAddressDetail
          : data.offlineInfo?.endDetailAddress,
        endLatitude: isSameStartEndPoint
          ? data.offlineInfo?.startLatitude
          : data.offlineInfo?.endLatitude,
        endLongitude: isSameStartEndPoint
          ? data.offlineInfo?.startLongitude
          : data.offlineInfo?.endLongitude
      };

      const updatedProject = await coreApi
        .put<CommonResponseModel<ProjectV2>>(
          `/apps/${appId}/projects/${projectId}`,
          {
            ...project,
            ...data,
            description: data.description || undefined,
            notice: data.notice || undefined,
            playableTime: data.playableTime || undefined,
            etc: data.etc || undefined,
            videoYoutubeId: data.videoYoutubeId || null,
            purchaseLink: data.purchaseLink || undefined,
            playTimeInMinutes: playTimeInMinutes || null,
            minPlayers: data.minPlayers || 0,
            maxPlayers: isRangeSelected ? data.maxPlayers || 0 : data.minPlayers || 0,
            playerDescription: isShowRecommendedPlayers
              ? data.playerDescription || undefined
              : undefined,
            offlineInfo: isOutDoorPlayType ? offlineInfo : undefined,
            kitInfo: kitRequired ? data.kitInfo : undefined,
            images: data.images?.map((image) => image.userFileId)
          },
          {
            params: { discardTheme: true },
            headers: {
              'x-rwd-api-version': '1.1'
            }
          }
        )
        .then(({ data }) => data);
      track.onSave({ saveEventName: SaveEventName.save_gamesetting });

      mutate(updatedProject);
      // reset 메서드를 어떤 목적으로 사용하고 있는지 모르겠습니다.
      // 일단 주석 처리 후 논의해보는 걸로 하겠습니다.
      // formState를 초기화하는 역할인 것 같습니다만 폼 내용이 초기화 되는 문제가 있어서 주석 처리 후 데이터는 유지하도록 작성했습니다.
      // reset({
      //   ...updatedProject.data,
      //   images: updatedProject.data.images?.map((image) => ({
      //     userFileId: image.userFileId,
      //     fileUrl: image.fileUrl
      //   })),
      //   playTimeInMinutes: {
      //     hours: Math.floor((updatedProject.data.playTimeInMinutes as number) / 60),
      //     minutes: (updatedProject.data.playTimeInMinutes as number) % 60
      //   }
      // });

      reset(
        {},
        {
          keepValues: true
        }
      );
      toast({ message: '저장 완료' });
    },
    (errors) => {
      const requiredInvalidFields = (Object.values(errors) as FieldError[])
        .filter((error) => error.type === 'required')
        .map((error) => error.message);

      if (requiredInvalidFields.length > 0) {
        openModal({
          title: '필수 항목을 모두 채워주세요',
          // TODO: 배열 타입도 넣을 수 있게 타입 정의 수정하기
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          cssStyle: ModalChildrenStyles,
          primaryAction: {
            content: '확인',
            onAction: closeModal
          },
          children: (
            <>
              <Icon
                cssStyle={MarginBottom10}
                icon="times_circle_regular"
                size="100px"
                color={color.interactive_critical_default}
              />
              {`${requiredInvalidFields.join(', ')}이(가) 비어있어요`}
            </>
          )
        });
      }
    }
  );

  const getPlayerCountErrorText = () => {
    if (minPlayers < 0 || minPlayers > 50 || maxPlayers < 0 || maxPlayers > 50) {
      return '최대 50까지 입력할 수 있어요';
    }
    if (isRangeSelected && minPlayers > maxPlayers && maxPlayers !== 0) {
      return '최소 인원보다 최대 인원이 적을 수 없어요';
    }
    if (
      Number.isNaN(minPlayers) ||
      (isRangeSelected && (Number.isNaN(minPlayers) || Number.isNaN(maxPlayers)))
    ) {
      return '숫자를 입력해주세요';
    }
    if (
      minPlayers === undefined ||
      minPlayers === null ||
      maxPlayers === undefined ||
      maxPlayers === null
    ) {
      return '필수 항목이에요';
    }

    return undefined;
  };

  const isValid = () => {
    if (minPlayers < 0 || minPlayers > 50 || maxPlayers < 0 || maxPlayers > 50) {
      return false;
    }
    if (isRangeSelected && minPlayers > maxPlayers && maxPlayers !== 0) {
      return false;
    }
    if (Number.isNaN(minPlayers) || (isRangeSelected && Number.isNaN(maxPlayers))) {
      return false;
    }
    return true;
  };

  return (
    <>
      <FloatingNavigation
        items={floatingNavigationItems}
        cssStyle={FloatingNavigationBarStyle}
        offset={24}
      />
      <RouteModal
        when={formState.isDirty}
        isValid={formState.isValid}
        loading={formState.isSubmitting}
        onSubmit={onSubmit}
      />
      <div css={[WrapperStyle, container650Style, SpacingForBottomFloatingBar, Gap]}>
        <form onSubmit={onSubmit} css={[Gap, MarginBottom24]}>
          <Card
            header={{
              isRequired: true,
              content: '게임 기본 정보',
              prefix
            }}
            cssStyle={CardItemGap}
            id="game-basic-settings"
          >
            <Controller
              control={control}
              name="name"
              defaultValue={project.name}
              rules={{ required: '게임 이름' }}
              render={({ field, fieldState: { invalid } }) => (
                <FormGroup
                  label="게임 이름"
                  requiredIndicator
                  isHorizontal
                  errorText={invalidFieldBoilerplate(invalid)}
                >
                  <Input
                    {...field}
                    error={invalid}
                    placeholder="예) 태양단의 비밀"
                    showCharacterCount
                    maxLength={100}
                  />
                </FormGroup>
              )}
            />
            <FormGroup label="게임 ID" isHorizontal>
              <Input disabled value={projectId} />
            </FormGroup>
            <FormProvider {...methods}>
              <SelectGameType />
            </FormProvider>
            <Controller
              control={control}
              name="language"
              defaultValue={project.language}
              rules={{ required: '게임 언어' }}
              render={({ field: { value, onChange }, fieldState: { invalid } }) => (
                <FormGroup
                  label="게임 언어"
                  requiredIndicator
                  isHorizontal
                  errorText={invalidFieldBoilerplate(invalid)}
                >
                  <SelectLanguage
                    selectedItem={value || 'Korean'}
                    setSelectedItem={(...args) => {
                      onChange(...args);
                    }}
                  />
                </FormGroup>
              )}
            />
            <FormProvider {...methods}>
              {isOutDoorPlayType && (
                <>
                  <FormGroup label="게임 시작 지점" requiredIndicator isHorizontal>
                    <Controller
                      control={control}
                      name="offlineInfo.startAddress"
                      rules={{ required: '게임 시작 지점' }}
                      render={({ field, fieldState }) => (
                        <SearchAddress
                          type="start"
                          defaultAddress={project.offlineInfo?.startAddress}
                          defaultDetailAddress={project.offlineInfo?.startAddressDetail}
                          onChange={field.onChange}
                          isError={fieldState.invalid}
                        />
                      )}
                    />
                  </FormGroup>
                  {isAdmin && startLatitude && startLongitude && (
                    <FormGroup label="" isHorizontal>
                      <Typography type="body">
                        위도: {startLatitude}, 경도: {startLongitude}
                      </Typography>
                    </FormGroup>
                  )}
                  <FormProvider {...methods}>
                    <StartAreaInfo project={project} />
                  </FormProvider>
                  <FormGroup label="게임 종료 지점" requiredIndicator isHorizontal>
                    <CheckBox
                      checked={isSameStartEndPoint}
                      onChange={(e) => setIsSameStartEndPoint(e.target.checked)}
                    >
                      시작 지점과 종료 지점이 같아요
                    </CheckBox>
                    {!isSameStartEndPoint && (
                      <Controller
                        control={control}
                        name="offlineInfo.endAddress"
                        rules={{ required: '게임 종료 지점' }}
                        render={({ field, fieldState }) => (
                          <SearchAddress
                            type="end"
                            defaultAddress={project.offlineInfo?.endAddress}
                            defaultDetailAddress={project.offlineInfo?.endDetailAddress}
                            onChange={field.onChange}
                            isError={fieldState.invalid}
                          />
                        )}
                      />
                    )}
                  </FormGroup>
                </>
              )}
            </FormProvider>
            <FormProvider {...methods}>
              <SelectKitType project={project} releaseStatus={releaseStatus} />
            </FormProvider>

            {!templateInfoIsValidating && (
              <FormGroup label="템플릿" isHorizontal>
                <Typography type="body">{templateInfo?.templateProjectName ?? '없음'}</Typography>
              </FormGroup>
            )}
            <Divider cssStyle={DividerStyle} />
            <Controller
              control={control}
              name="genres"
              rules={{ required: '장르' }}
              defaultValue={project.genres}
              render={({ field: { value, onChange }, fieldState: { invalid } }) => {
                function toggle(key: Genre) {
                  // Hack: 첫 마운트 시 값이 빈 채로 실행된다??
                  onChange(toggler(value ?? [], key, 3));

                  track.onClick({
                    clickEventName: ClickEventName.click_gamesetting_basicinformation_button_genre,
                    params: { genre: key }
                  });
                }

                return (
                  <FormGroup
                    label="장르 (최대 3개)"
                    requiredIndicator
                    isHorizontal
                    errorText={invalidFieldBoilerplate(invalid)}
                  >
                    <div role="group" css={GenresLayout}>
                      {genres.map(({ key, name }) => (
                        <Button
                          key={key}
                          size="small"
                          // 첫 마운트 시 genres 필드 값이 비어 있어 optional chain 을 사용했습니다.
                          type={value?.includes(key) ? 'primary' : 'outlineBasic'}
                          onClick={() => toggle(key)}
                        >
                          {name}
                        </Button>
                      ))}
                    </div>
                  </FormGroup>
                );
              }}
            />
            <Controller
              control={control}
              name="playTimeInMinutes"
              defaultValue={{
                hours: project.playTimeInMinutes
                  ? Math.floor((project.playTimeInMinutes as number) / 60)
                  : undefined,
                minutes: project.playTimeInMinutes
                  ? (project.playTimeInMinutes as number) % 60
                  : undefined
              }}
              rules={{
                required: '소요 시간',
                validate: (value) => {
                  if (value?.hours === undefined && value?.minutes === undefined) return false;

                  const hours = Number(value.hours);
                  const minutes = Number(value?.minutes);

                  if (
                    ((Number.isNaN(hours) || Number.isNaN(minutes)) &&
                      (hours !== 0 || minutes !== 0)) ||
                    (hours * 60 + minutes > 0 && hours >= 0 && hours <= 999)
                  ) {
                    return true;
                  }
                  return false;
                }
              }}
              render={({
                field: { value, onChange },
                fieldState: { invalid },
                formState: { submitCount }
              }) => {
                const getErrorText = () => {
                  if (value === undefined && submitCount !== 0) return '필수 항목이에요';
                  if (Number.isNaN(value?.hours) || Number.isNaN(value?.minutes)) {
                    return '숫자를 입력해주세요';
                  }
                  if (value?.hours === 0 && value?.minutes === 0) {
                    return '소요 시간을 다시 설정해주세요';
                  }
                  if (value?.hours && value?.hours > 999) {
                    return '시간 단위는 최대 999까지 입력할 수 있어요';
                  }
                  return undefined;
                };

                return (
                  <>
                    <FormGroup
                      label="소요 시간"
                      requiredIndicator
                      isHorizontal
                      errorText={getErrorText()}
                    >
                      <div css={PlayTimeContainerStyle}>
                        <div css={PlayTimeItemStyle}>
                          <div css={PlayTimeSelectWrapperStyle}>
                            <SelectInput
                              type="number"
                              placeholder="숫자 입력"
                              value={String(value?.hours) || undefined}
                              onChange={(...args) => {
                                onChange({
                                  ...value,
                                  hours: Number(args[0])
                                });
                                track.onClick({
                                  clickEventName:
                                    ClickEventName.click_gamesetting_basicinformation_button_playtime,
                                  params: { playtime: args[0] }
                                });
                              }}
                              error={invalid}
                              options={duringHours}
                            />
                          </div>
                          <span>시간</span>
                        </div>
                        <div css={PlayTimeItemStyle}>
                          <div css={PlayTimeSelectWrapperStyle}>
                            <Select
                              error={invalid}
                              placeholder="단위 선택"
                              value={
                                value &&
                                (value?.minutes === 0 || (value.minutes && value?.minutes > 0))
                                  ? String(value?.minutes)
                                  : undefined
                              }
                              onChange={(...args) => {
                                onChange({
                                  ...value,
                                  minutes: Number(args[0])
                                });
                                track.onClick({
                                  clickEventName:
                                    ClickEventName.click_gamesetting_basicinformation_button_playtime,
                                  params: { playtime: args[0] }
                                });
                              }}
                              options={duringMinutes}
                            />
                          </div>
                          <span>분</span>
                        </div>
                      </div>
                    </FormGroup>
                  </>
                );
              }}
            />
            <Controller
              control={control}
              name="difficulty"
              defaultValue={project.difficulty === 0 ? undefined : project.difficulty}
              rules={{ required: '난이도' }}
              render={({ field: { value, onChange }, fieldState: { invalid } }) => (
                <FormGroup
                  label="난이도"
                  labelTooltip={{
                    content: <DifficultyTooltip />
                  }}
                  requiredIndicator
                  isHorizontal
                  errorText={invalidFieldBoilerplate(invalid)}
                >
                  <SelectDifficulty
                    selectedItem={value}
                    setSelectedItem={(...args) => {
                      onChange(...args);
                      track.onClick({
                        clickEventName:
                          ClickEventName.click_gamesetting_basicinformation_button_difficulty,
                        params: {
                          // eslint-disable-next-line no-nested-ternary
                          difficulty: args[0] === 1 ? '쉬움' : args[0] === 2 ? '보통' : '어려움'
                        }
                      });
                    }}
                  />
                </FormGroup>
              )}
            />
            <Divider cssStyle={DividerStyle} />
            <Controller
              control={control}
              name="storySummary"
              defaultValue={project.storySummary}
              rules={{ required: '스토리' }}
              render={({ field, fieldState: { invalid } }) => (
                <FormGroup
                  label="스토리"
                  requiredIndicator
                  isHorizontal
                  errorText={invalidFieldBoilerplate(invalid)}
                >
                  <Input
                    error={invalid}
                    {...field}
                    onChange={(...args) => {
                      field.onChange(...args);
                      track.onInputThrottled({
                        inputEventName: InputEventName.input_gamesetting_basicinformation_story
                      });
                    }}
                    multiline
                    cssStyle={MultiInputStyle}
                    placeholder="게임의 스토리를 간략하게 적어주세요"
                  />
                </FormGroup>
              )}
            />
            <Controller
              control={control}
              name="tags"
              defaultValue={project.tags}
              render={({ field: { value, onChange }, fieldState: { invalid } }) => (
                <FormGroup
                  label="태그 (최대 5개)"
                  isHorizontal
                  errorText={invalidFieldBoilerplate(invalid)}
                  helpText="특수문자는 언더바(_)만 사용할 수 있어요"
                  cssStyle={css`
                    input {
                      ${textStyleBody}
                    }
                  `}
                >
                  <ReactTagInput
                    tags={value ?? []}
                    onChange={(...args) => {
                      onChange(...args);
                      track.onInputThrottled({
                        inputEventName: InputEventName.input_gamesetting_basicinformation_tag
                      });
                    }}
                    maxTags={5}
                    editable
                    removeOnBackspace
                    placeholder="입력하고 엔터키를 눌러주세요"
                    validator={(value) => {
                      if (value.length > 20) return false;

                      return tagRegexPattern.test(value);
                    }}
                  />
                </FormGroup>
              )}
            />
          </Card>
          <Card
            header={{
              content: '게임 소개',
              prefix
            }}
            cssStyle={CardItemGap}
            id="about-game"
          >
            <FormGroup label="게임 한줄 설명" isHorizontal>
              <Controller
                control={control}
                name="description"
                defaultValue={project.description}
                render={({ field }) => (
                  <Input
                    {...field}
                    onChange={(...args) => {
                      field.onChange(...args);
                      track.onInputThrottled({
                        inputEventName: InputEventName.input_gamesetting_gameintroduction_oneline
                      });
                    }}
                    placeholder="예) 한반도를 지키는 아홉 개의 국보를 찾아라!"
                    showCharacterCount
                    maxLength={200}
                  />
                )}
              />
            </FormGroup>
            {divider}
            <Typography type="heading">게임 미리보기</Typography>
            <Controller
              control={control}
              name="videoYoutubeId"
              defaultValue={project.videoYoutubeId}
              rules={{
                pattern: youtubeIdPattern
              }}
              render={({ field: { value, onChange }, fieldState: { invalid } }) => {
                const showEmbedVideo =
                  youtubeIdPattern.test(value || '') && value?.indexOf('https://youtu.be/') === -1;

                return (
                  <FormGroup
                    label="유튜브 동영상 주소"
                    errorText={invalid ? '유효하지 않은 동영상 주소에요' : undefined}
                    isHorizontal
                  >
                    <Input
                      error={invalid}
                      cssStyle={VideoInputStyle}
                      value={showEmbedVideo ? `https://youtu.be/${value}` : value}
                      onChange={(value) => {
                        onChange(extractYoutubeVideoId(value));
                        track.onInputThrottled({
                          inputEventName:
                            InputEventName.input_gamesetting_gameintroduction_youtubeurl
                        });
                      }}
                      placeholder="예) https://www.youtube.com/watch?v=Sample"
                    />
                    {showEmbedVideo && (
                      <iframe
                        width="300"
                        height="225"
                        src={`https://www.youtube.com/embed/${value}`}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    )}
                  </FormGroup>
                );
              }}
            />
            <Controller
              control={control}
              name="images"
              render={({ field: { value, onChange } }) => {
                const memoedFiles = useMemo(() => {
                  return value?.map((item) => ({
                    id: item.userFileId || '',
                    url: item.fileUrl || ''
                  }));
                }, [value?.length]);
                return (
                  <FormGroup
                    label={`이미지 (${value?.length ?? 0}/10)`}
                    isHorizontal
                    labelTooltip={{
                      content: <HowToUseImageTooltip />
                    }}
                    cssStyle={HorizonScrollStyle}
                  >
                    <Uploader
                      isMultiple
                      hasSearch
                      uploadedFiles={memoedFiles}
                      getUploadFiles={(files) => {
                        onChange(
                          files?.map((file) => ({
                            userFileId: file.id,
                            fileUrl: file.url
                          }))
                        );
                        track.onUpload({
                          uploadEventName:
                            UploadEventName.upload_gamesetting_gameintroduction_previewimage
                        });
                      }}
                      maxFileSize={4 * 1024 ** 2}
                      isVerticalLayout
                      limit={10}
                      useFor="project-preview"
                    />
                  </FormGroup>
                );
              }}
            />
          </Card>
          <Card
            header={{
              content: '게임 상세 정보',
              prefix,
              caption: '해당하는 항목만 입력해주세요. 비워둔 칸은 노출되지 않아요.'
            }}
            cssStyle={CardItemGap}
            id="detailed-settings"
          >
            <FormGroup label="공지사항" isHorizontal>
              <Controller
                control={control}
                name="notice"
                defaultValue={project.notice}
                render={({ field: { value, onChange } }) => (
                  <Input
                    value={value}
                    onChange={(...args) => {
                      onChange(...args);
                      track.onInputThrottled({
                        inputEventName: InputEventName.input_gamesetting_gamedetail_announcement
                      });
                    }}
                    multiline
                    showCharacterCount
                    maxLength={150}
                    placeholder="게임 정보 최상단에 노출 될 정보로, 게임 이용 장애 등 중요한 공지만 간략하게 작성해주세요"
                    cssStyle={NoticeInputStyle}
                  />
                )}
              />
            </FormGroup>
            {divider}
            <FormGroup
              label="권장 인원수"
              requiredIndicator
              isHorizontal
              errorText={getPlayerCountErrorText()}
            >
              <div css={PlayerCountContainerStyle}>
                <Controller
                  control={control}
                  name="minPlayers"
                  defaultValue={project.minPlayers}
                  rules={{
                    validate: () => {
                      return isValid();
                    }
                  }}
                  render={({ field: { value, onChange }, fieldState: { invalid } }) => (
                    <div>
                      <div css={PlayerCountWrapperStyle}>
                        <SelectInput
                          error={invalid}
                          type="text"
                          placeholder="숫자 입력"
                          value={String(value)}
                          onChange={(...args) => {
                            if (!isRangeSelected) {
                              setValue('maxPlayers', Number(args[0]));
                            }
                            onChange(...args);
                            track.onInputThrottled({
                              inputEventName:
                                InputEventName.input_gamesetting_gamedetail_recommandplayernumber
                            });
                          }}
                          options={playersCount}
                        />
                      </div>
                    </div>
                  )}
                />
                <span>명</span>
                {isRangeSelected && (
                  <Controller
                    control={control}
                    name="maxPlayers"
                    defaultValue={project.maxPlayers}
                    rules={{
                      validate: () => {
                        return isValid();
                      }
                    }}
                    render={({ field: { value, onChange }, fieldState: { invalid } }) => (
                      <>
                        <span>~</span>
                        <div css={PlayerCountWrapperStyle}>
                          <SelectInput
                            error={invalid}
                            type="text"
                            placeholder="숫자 입력"
                            value={String(value)}
                            onChange={(...args) => {
                              onChange(...args);
                              track.onInputThrottled({
                                inputEventName:
                                  InputEventName.input_gamesetting_gamedetail_recommandplayernumber
                              });
                            }}
                            options={playersCount}
                          />
                        </div>
                        <span>명</span>
                      </>
                    )}
                  />
                )}
              </div>
              <CheckBox
                checked={isRangeSelected}
                onChange={(e) => {
                  setIsRangeSelected(e.target.checked);

                  if (!e.target.checked) {
                    setValue('maxPlayers', minPlayers);
                  }
                }}
              >
                범위 설정
              </CheckBox>
            </FormGroup>
            {isShowRecommendedPlayers && (
              <FormGroup label="인원수 추가 안내" isHorizontal>
                <Controller
                  control={control}
                  name="playerDescription"
                  defaultValue={project.playerDescription}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder="예) 키트당 1~3명, 5인 권장 등"
                      cssStyle={MultiInputStyle}
                    />
                  )}
                />
              </FormGroup>
            )}
            {isOutDoorPlayType && (
              <>
                <FormGroup label="이동 거리" isHorizontal>
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                    `}
                  >
                    <Controller
                      control={control}
                      name="offlineInfo.distance"
                      defaultValue={project.offlineInfo?.distance}
                      render={({ field }) => (
                        <Input
                          {...field}
                          value={field.value?.toString()}
                          type="number"
                          placeholder="숫자 입력"
                          cssStyle={css`
                            width: 80px;
                            margin-right: 8px;
                          `}
                        />
                      )}
                    />
                    <span
                      css={css`
                        margin-right: 16px;
                      `}
                    >
                      km
                    </span>
                    <Controller
                      control={control}
                      name="offlineInfo.isNeedPublicTransport"
                      render={({ field }) => (
                        <CheckBox
                          ref={field.ref}
                          name={field.name}
                          onBlur={field.onBlur}
                          defaultChecked={field.value}
                          onChange={field.onChange}
                        >
                          대중교통 이용 필요
                        </CheckBox>
                      )}
                    />
                  </div>
                </FormGroup>
                <FormGroup label="실내외 비율" cssStyle={OutdoorIndoorSelects} isHorizontal>
                  <Controller
                    control={control}
                    name="offlineInfo.indoorPercentage"
                    defaultValue={project.offlineInfo?.indoorPercentage}
                    render={({ field: { value, onChange } }) => {
                      const indoorPercentage = value ?? defaultOption.value;

                      const outdoorPercentage =
                        indoorPercentage === defaultOption.value
                          ? defaultOption.value
                          : (100 - Number(indoorPercentage)).toString();

                      function onIndoorChange(value: string, id: string) {
                        onChange(value === '선택 안 함' ? undefined : value, id);
                      }

                      function onOutdoorChange(value: string, id: string) {
                        onIndoorChange(
                          value === '선택 안 함' ? value : (100 - Number(value)).toString(),
                          id
                        );
                      }

                      return (
                        <>
                          <Select
                            value={indoorPercentage?.toString()}
                            options={indoorOption}
                            onChange={onIndoorChange}
                          />
                          <Select
                            value={outdoorPercentage}
                            options={outdoorOption}
                            onChange={onOutdoorChange}
                          />
                        </>
                      );
                    }}
                  />
                </FormGroup>
              </>
            )}
            <FormGroup label="준비물" isHorizontal>
              <div role="group" css={GenresLayout}>
                <Controller
                  control={control}
                  name="isNeedWritingInstrument"
                  defaultValue={project.isNeedWritingInstrument}
                  render={({ field: { value, onChange }, fieldState: { invalid } }) => {
                    function toggle(key: boolean) {
                      onChange(key);
                    }

                    return (
                      <>
                        <Button
                          size="small"
                          // 첫 마운트 시 genres 필드 값이 비어 있어 optional chain 을 사용했습니다.
                          type={value ? 'primary' : 'outlineBasic'}
                          onClick={() => toggle(!value)}
                        >
                          필기구
                        </Button>
                      </>
                    );
                  }}
                />
                <Controller
                  control={control}
                  name="offlineInfo.isNeedRunningShoes"
                  defaultValue={project.offlineInfo?.isNeedRunningShoes}
                  render={({ field: { value, onChange }, fieldState: { invalid } }) => {
                    function toggle(key: boolean) {
                      onChange(key);
                    }

                    return (
                      <>
                        {(gameType === 'Offline' || gameType === 'OfflineKit') && (
                          <Button
                            size="small"
                            type={value ? 'primary' : 'outlineBasic'}
                            onClick={() => toggle(!value)}
                          >
                            운동화
                          </Button>
                        )}
                      </>
                    );
                  }}
                />
              </div>
            </FormGroup>
            {kitRequired && (
              <>
                {divider}
                <Typography type="heading">키트 안내</Typography>
                <FormGroup label="수령 방법" isHorizontal>
                  <Controller
                    control={control}
                    name="kitInfo.howToPickUp"
                    // defaultValue={project.kitInfo?.howToPickUp}
                    render={({ field }) => {
                      return (
                        <Input
                          {...field}
                          onChange={(value) => {
                            field.onChange(value || undefined);
                          }}
                          multiline
                          placeholder="예) 택배 수령 또는 방문 수령"
                          cssStyle={MultiInputStyle}
                        />
                      );
                    }}
                  />
                </FormGroup>
                <FormGroup label="키트 구성" isHorizontal>
                  <Controller
                    control={control}
                    name="kitInfo.description"
                    // defaultValue={project.kitInfo?.description}
                    render={({ field }) => (
                      <Input
                        {...field}
                        onChange={(value) => {
                          field.onChange(value || undefined);
                        }}
                        multiline
                        placeholder="예) 스승님의 수첩 x1, 트레이싱지x1"
                        cssStyle={MultiInputStyle}
                      />
                    )}
                  />
                </FormGroup>
                {(isAdmin ||
                  app?.channelType === 'Realworld' ||
                  app?.channelType === 'Business') && (
                  <FormGroup label="구매 링크" isHorizontal>
                    <Controller
                      control={control}
                      name="purchaseLink"
                      defaultValue={project.purchaseLink}
                      render={({ field }) => (
                        <Input {...field} placeholder="키트를 판매할 URL을 입력해주세요." />
                      )}
                    />
                  </FormGroup>
                )}
              </>
            )}
            {divider}
            <Typography type="heading">게임 안내</Typography>
            {isOutDoorPlayType && (
              <FormGroup label="플레이 가능 시간" isHorizontal>
                <Controller
                  control={control}
                  name="offlineInfo.playableTime"
                  defaultValue={project.offlineInfo?.playableTime}
                  render={({ field }) => (
                    <Input
                      {...field}
                      onChange={(...args) => {
                        field.onChange(...args);
                        track.onInputThrottled({
                          inputEventName:
                            InputEventName.input_gamesetting_gamedetail_availableplaytime
                        });
                      }}
                      multiline
                      placeholder="예) 화~금 : 9~17시, 토~일 : 10~19시"
                      cssStyle={MultiInputStyle}
                    />
                  )}
                />
              </FormGroup>
            )}
            <FormGroup label="기타" isHorizontal>
              <Controller
                control={control}
                name="etc"
                defaultValue={project.etc}
                render={({ field: { value, onChange } }) => (
                  <Input
                    value={value}
                    onChange={(...args) => {
                      onChange(...args);
                      track.onInputThrottled({
                        inputEventName: InputEventName.input_gamesetting_gamenotice_etc
                      });
                    }}
                    multiline
                    cssStyle={MultiInputStyle}
                  />
                )}
              />
            </FormGroup>
          </Card>
        </form>
        {isAdmin && <UnlockCode />}
        <DeletePlayData name={project.name} />
      </div>
      <BottomFloatingBar onSave={onSubmit} loading={formState.isSubmitting} />
      <Modal {...modal} />
    </>
  );
}

function DeletePlayData({ name }: Pick<RealWorldApp, 'name'>) {
  const { handleSubmit, formState, reset, control } = useForm<Pick<RealWorldApp, 'name'>>({
    mode: 'onChange'
  });

  const { appId, projectId } = useParams<AppParam>();

  const { modal, openModal, closeModal } = useModalState();

  useEffect(() => {
    reset({ name: '' });
  }, [formState.isSubmitted]);

  const onSubmit = handleSubmit(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    deleteAllPlayHistory(appId!, projectId!);

    openModal({
      title: '플레이 내역을 삭제했어요',
      // TODO: 배열 타입도 넣을 수 있게 타입 정의 수정하기
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      cssStyle: ModalChildrenStyles,
      primaryAction: {
        content: '확인',
        onAction: closeModal
      },
      children: (
        <>
          <Icon
            cssStyle={MarginBottom10}
            icon="check_circle_regular"
            size="100px"
            color={color.interactive_primary_subdued}
          />
          이전에 게임을 플레이한 모든 사람의 플레이내역을 삭제했어요. 대시보드 반영까지는 1시간 정도
          걸릴 수 있어요.
        </>
      )
    });
  });

  return (
    <Card
      header={{
        content: '플레이 내역 삭제',
        prefix: <Icon icon="user_tag_solid" color={color.icon_critical} />,
        textColor: color.text_critical,
        backgroundColor: color.surface_critical_subdued_hovered
      }}
      id="delete-all-play-history"
    >
      <Modal {...modal} />
      <form css={Gap} onSubmit={onSubmit}>
        <Typography type="body">
          <p>
            <b>&apos;{name}&apos;</b>의{' '}
            <span css={CriticalText}>
              모든 플레이 내역이 즉시 삭제 되며, 삭제 이후에는 복구할 수 없어요.
            </span>{' '}
            게임 제작 및 테스트 중 다시 플레이를 하기 위한 용도로 사용해주세요.
          </p>
          <p css={MarginTop16}>정말 삭제하시려면 게임 이름을 입력해주세요.</p>
        </Typography>
        <Controller
          control={control}
          name="name"
          rules={{ required: true, validate: (typing) => typing === name }}
          render={({ field }) => (
            <Input
              {...field}
              onChange={(...args) => {
                field.onChange(...args);
                track.onInputThrottled({
                  inputEventName: InputEventName.input_gamesetting_deleteplayhistory_writegamename
                });
              }}
              placeholder={name}
              cssStyle={MarginBottom24}
            />
          )}
        />
        <Button
          type="destructive"
          disabled={!formState.isValid}
          icon="trash_alt_solid"
          htmlType="submit"
          onClick={() => {
            track.onClick({
              clickEventName: ClickEventName.click_gamesetting_deleteplayhistory_button_delete
            });
          }}
        >
          삭제
        </Button>
      </form>
    </Card>
  );
}

const WrapperStyle = css`
  max-width: 650px;
`;

const GenresLayout = css`
  margin-bottom: calc(-1 * ${spacing.margin.xsmall});
  > button {
    margin-right: ${spacing.margin.xsmall};
    margin-bottom: ${spacing.margin.xsmall};
  }
`;

const DividerStyle = css`
  background-color: ${color.border_default_subdued} !important;
`;

const divider = <Divider cssStyle={DividerStyle} />;

const HorizonScrollStyle = css`
  > div:last-of-type {
    overflow-x: auto;
    overflow-y: hidden;
  }
`;

const MultiInputStyle = css`
  textarea {
    min-height: 116px;
  }
`;

const NoticeInputStyle = css`
  textarea {
    height: 94px !important;
  }
`;

const OutdoorIndoorSelects = css`
  > div:last-of-type {
    display: flex;
    width: 100%;
    > div:first-of-type {
      margin-right: 4px;
    }
    > div {
      flex-grow: 1;
    }
  }
`;

const VideoInputStyle = css`
  margin-bottom: 4px;
`;

const CriticalText = css`
  color: ${color.text_critical};
`;

const FloatingNavigationBarStyle = css`
  position: fixed;
  left: 931px;
  width: 160px;

  @media (max-width: 1013px) {
    display: none;
  }
`;

const PlayTimeContainerStyle = css`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const PlayTimeItemStyle = css`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-weight: 400px;
`;

const PlayTimeSelectWrapperStyle = css`
  width: 104px;
`;

const PlayerCountContainerStyle = css`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 14px;
  font-size: 14px;
`;

const PlayerCountWrapperStyle = css`
  width: 100px;
`;

async function deleteAllPlayHistory(appId: string, projectId: string) {
  const scenarioIds = await coreApi
    .get<Scenario[]>(`/apps/${appId}/projects/${projectId}/scenarios`)
    .then(({ data }) => data.map(({ id }) => id));

  await Promise.all(
    scenarioIds.map((scenarioId) =>
      coreApi.delete(`/apps/${appId}/scenarios/${scenarioId}/players`)
    )
  );
}

function extractYoutubeVideoId(url?: string) {
  const match = url?.match(youtubeUrlPattern);
  if (match && match[5].length === 11) {
    return match[5];
  }
  return url;
}

const youtubeUrlPattern = /(youtu\.be\/|youtube\.com\/(watch\?(.*&)?v=|(embed|v)\/))([^?&"'>]+)/;
const youtubeIdPattern = /[\w-\d]{11}/;
const tagRegexPattern = /^[가-힣ㄱ-ㅎ-ㅏ-ㅣa-zA-Z0-9]([가-힣ㄱ-ㅎ-ㅏ-ㅣa-zA-Z0-9_])+$/;
