import { css } from '@emotion/react';
import { FormGroup, Input, Typography } from '@uniquegood/realworld-studio-design';
import { Controller, Control } from 'react-hook-form';
import React, { useEffect, useRef } from 'react';

import flatpickr from 'flatpickr';
import { Korean } from 'flatpickr/dist/l10n/ko';
import { Instance } from 'flatpickr/dist/types/instance';
import { FormData } from './UnlockCode';

interface UnlockCodeFormProps {
  // eslint-disable-next-line @typescript-eslint/ban-types
  control: Control<FormData, object>;
  onSubmit(e: unknown): void;
  type: 'put' | 'post';
}

export default function UnlockCodeForm({ control, onSubmit, type }: UnlockCodeFormProps) {
  const containerRef = useRef<HTMLFormElement>(null);
  const inputRef = useRef<Element>(null);
  const datepickerRef = useRef<ReturnType<typeof flatpickr> | null>(null);
  return (
    <form id="form" ref={containerRef} css={FormStyle} onSubmit={onSubmit}>
      <Typography type="heading" as="h2">
        기본 설정
      </Typography>
      <Controller
        control={control}
        name="code"
        render={({ field: { value, onChange } }) => (
          <FormGroup id="modal-form-code" label="코드" helpText="영문, - , 숫자 입력이 가능해요.">
            <Input
              readOnly={type === 'put'}
              id="modal-form-code"
              value={value}
              onChange={(value) =>
                onChange(value.toUpperCase().replace(invalidUnlockCodePattern, ''))
              }
              placeholder="예) SAMPLE-123"
            />
          </FormGroup>
        )}
      />
      <FormGroup id="modal-form-description" label="설명">
        <Controller
          control={control}
          name="note"
          render={({ field }) => (
            <Input {...field} id="modal-form-description" placeholder="예) 클로즈드 베타용" />
          )}
        />
      </FormGroup>
      <Typography type="heading">사용 제한 설정</Typography>
      <FormGroup id="modal-form-expired-at" label="만료 일시 설정">
        <Controller
          control={control}
          name="expiresAt"
          render={({ field: { onChange, value } }) => {
            useEffect(() => {
              if (inputRef.current instanceof HTMLInputElement && containerRef.current) {
                datepickerRef.current = flatpickr(inputRef.current, {
                  defaultDate: value,
                  enableTime: true,
                  locale: Korean,
                  onChange: ([date]) => {
                    onChange(date);
                  }
                });
              }
            }, []);

            return (
              <Input
                ref={inputRef}
                clearButton={!!value}
                onClearButtonClick={() => {
                  if (!datepickerRef.current) throw Error('datepicker 인스턴스를 찾지 못했습니다.');

                  (datepickerRef.current as Instance)?.clear();
                }}
              />
            );
          }}
        />
      </FormGroup>
      <FormGroup id="modal-form-count" label="개수 제한 설정">
        <Controller
          control={control}
          name="count"
          render={({ field }) => (
            <Input
              {...field}
              id="modal-form-count"
              type="number"
              placeholder={type === 'post' ? '개수 제한 없음' : undefined}
            />
          )}
        />
      </FormGroup>
    </form>
  );
}

const invalidUnlockCodePattern = /[^A-Z-\d]/g;

const FormStyle = css`
  > div:not(:last-of-type) {
    margin-bottom: 24px;
  }
`;
