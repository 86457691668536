import React, { useMemo } from 'react';
import {
  CheckBox,
  color,
  FormGroup,
  Icon,
  Image,
  Input,
  spacing,
  Tooltip,
  Typography
} from '@uniquegood/realworld-studio-design';
import { Control, Controller } from 'react-hook-form';
import { css } from '@emotion/react';
import Uploader, { UploaderProps } from '@/components/Uploader';
import { ColumFlexOnMobile, FlexGrow, InlineFlex, MarginBottom10 } from '@/styles';
import { onMobile } from '@/styles/responsive';
import { inventoryPreview } from '../assets/images';
import { FormData } from '../Items';

export interface FormProps {
  onSubmit(e: unknown): void;
  // eslint-disable-next-line @typescript-eslint/ban-types
  control: Control<FormData, object>;
}

export default function Form({ onSubmit, control }: FormProps) {
  return (
    <form id="modal" css={Gaps} onSubmit={onSubmit}>
      <div css={ColumFlexOnMobile}>
        <Controller
          control={control}
          name="name"
          rules={{ required: true }}
          render={({ field: { value, onChange }, fieldState: { invalid } }) => (
            <FormGroup
              cssStyle={FlexGrow}
              label="아이템 이름"
              requiredIndicator
              errorText={invalid ? '필수 항목이에요' : undefined}
            >
              <Input value={value} onChange={onChange} />
            </FormGroup>
          )}
        />
        <Controller
          control={control}
          name="id"
          render={({ field: { value } }) =>
            value ? (
              <FormGroup label="아이템 ID" cssStyle={[FlexGrow, ItemIdFieldStyle]}>
                <Input value={value} disabled />
              </FormGroup>
            ) : (
              <></>
            )
          }
        />
      </div>
      <Controller
        control={control}
        name="icon"
        render={({ field: { value, onChange } }) => {
          function setChecked() {
            onChange(value ? undefined : {});
          }

          return (
            <>
              <FormGroup label="아이템 이미지">
                <MakeUploaderBoilerPlate control={control} name="detailImage" useFor="item-image" />
                <div css={InlineFlex}>
                  <CheckBox
                    checked={!!value}
                    onChange={setChecked}
                    cssStyle={CheckboxLabelStyle}
                    aria-describedby="about-icon-image"
                  >
                    아이콘 이미지를 별도로 설정
                  </CheckBox>
                  {ToolTipForIconImage}
                </div>
              </FormGroup>
              {value && (
                <FormGroup label="아이콘 이미지">
                  <MakeUploaderBoilerPlate control={control} name="icon" useFor="item-icon" />
                </FormGroup>
              )}
            </>
          );
        }}
      />

      <FormGroup label="아이템 설명">
        <Controller
          control={control}
          name="description"
          render={({ field: { value, onChange } }) => (
            <Input multiline value={value} onChange={onChange} cssStyle={MultiInputStyle} />
          )}
        />
      </FormGroup>
      <Controller
        control={control}
        name="isSingleOwnable"
        render={({ field: { value, onChange } }) => (
          <div css={InlineFlex}>
            <CheckBox
              checked={value}
              onChange={onChange}
              cssStyle={CheckboxLabelStyle}
              aria-describedby="about-multi-ownable"
            >
              아이템 획득을 1회로 한정 (권장하지 않음)
            </CheckBox>
            {tooltipForMultiOwnable}
          </div>
        )}
      />
    </form>
  );
}

const MultiInputStyle = css`
  textarea {
    min-height: 116px;
  }
`;

const ItemIdFieldStyle = css`
  margin-left: ${spacing.margin.xlarge2};

  ${onMobile} {
    margin-top: ${spacing.margin.xlarge2};
    margin-left: 0;
  }
`;

const CheckboxLabelStyle = css`
  > label:last-of-type {
    display: inline-flex;

    > span {
      margin-left: ${spacing.margin.xsmall};
    }
  }
  margin-top: 4px;
`;

const ToolTipForIconImage = (
  <Tooltip
    cssStyle={css`
      margin-left: ${spacing.margin.xsmall};
      align-self: end;
    `}
    title="아이콘 이미지를 별도로 지정"
    content={
      <div
        id="about-icon-image"
        css={css`
          width: 260px;
        `}
      >
        <Typography as="p" type="body" cssStyle={MarginBottom10}>
          아이콘 이미지는 인벤토리 목록에서 보여지는 이미지를 말해요
        </Typography>
        <Image source={inventoryPreview} alt="인벤토리 예시" width="100%" />
      </div>
    }
  >
    <Icon
      icon="question_circle_solid"
      size="20px"
      color={color.icon_disabled}
      cssStyle={css`
        margin-left: ${spacing.margin.xsmall};
        align-self: end;
      `}
    />
  </Tooltip>
);

const Gaps = css`
  > *:not(:last-of-type) {
    margin-bottom: ${spacing.margin.xlarge2};
  }
`;

const tooltipForMultiOwnable = (
  <Tooltip
    cssStyle={css`
      margin-left: ${spacing.margin.xsmall};
      align-self: end;
    `}
    content={
      <div
        id="about-multi-ownable"
        css={css`
          width: 240px;
        `}
      >
        아이템 획득을 ID당 1회로 설정할 경우, 플레이어가 게임을 재시작하거나 아이템을 소모해도
        아이템을 다시 획득할 수 없어요.
      </div>
    }
  >
    <Icon icon="question_circle_solid" size="20px" color={color.icon_disabled} />
  </Tooltip>
);

interface MakeUploaderBoilerPlateProps extends Pick<UploaderProps, 'useFor'> {
  // eslint-disable-next-line @typescript-eslint/ban-types
  control: Control<FormData, object>;
  name: Extract<keyof FormData, 'detailImage' | 'icon'>;
}

const MakeUploaderBoilerPlate = ({ control, name, useFor }: MakeUploaderBoilerPlateProps) => (
  <Controller
    control={control}
    name={name}
    render={({ field: { value, onChange } }) => {
      const memoedFiles = useMemo(() => (value?.id ? [value] : undefined), [value?.id]);
      return (
        <Uploader
          ratio="square"
          hasSearch={!value?.id}
          uploadedFiles={memoedFiles}
          getUploadFiles={(files) => onChange(files?.[0])}
          useFor={useFor}
        />
      );
    }}
  />
);
