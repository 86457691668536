/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import axios from 'axios';
import {
  Icon,
  Label,
  DropZone,
  Tooltip,
  color,
  Button,
  RatioStyle,
  IDropZoneProps,
  Modal
} from '@uniquegood/realworld-studio-design';
import { useState } from 'react';
import { RequestRequireProps } from '../../../../types';
import { MarginLeft4, TextAlignCenter } from '../../../../styles';
import useModalState from '../../../../hooks/useModalState';

interface Props extends RequestRequireProps {
  url?: string;
  label: string;
  description?: string;
  onChange: (data?: string) => void;
  type: 'sound' | 'image';
}

const FileUploader = ({
  label,
  description,
  onChange,
  appId,
  coreApiEndPoint,
  token,
  url,
  type,
  projectId
}: Props) => {
  const [isUploading, setIsUploading] = useState(false);
  const {
    modal: notAcceptModal,
    openModal: openNotAcceptModal,
    closeModal: closeNotAcceptModal
  } = useModalState();
  const {
    modal: fileSizeExceedModal,
    openModal: openFileSizeExceedModal,
    closeModal: closeFileSizeExceedModal
  } = useModalState();
  function makeFormData(body: Record<string, string | Blob>) {
    const data = new FormData();

    for (const [key, value] of Object.entries(body)) {
      data.append(key, value);
    }

    return data;
  }

  async function uploadFile(files: File[]) {
    if (checkFileSizeIsExceed({ files })) {
      openFileSizeExceedModal({
        title: `더 작은 크기의 ${type === 'sound' ? '사운드 파일을' : '이미지 파일을'} 올려주세요`,
        cssStyle: TextAlignCenter,
        children: (
          <>
            <Icon
              color={color.interactive_critical_default}
              icon="times_circle_regular"
              size="100px"
            />
            최대 {maxDefaultFileSize / 1024 / 1024}MB까지 업로드 할 수 있어요
          </>
        ),
        primaryAction: { content: '확인', onAction: closeFileSizeExceedModal }
      });
      return;
    }

    setIsUploading(true);

    try {
      const res = await axios.post(
        `/apps/${appId}/projects/${projectId}/files`,
        // 미정: 이 디렉토리 숨김 아니면 보여주게 할까?
        makeFormData({ file: files[0], dir: 'webview-builder' }),
        {
          headers: {
            Authorization: token,
            'Content-Type': 'multipart/form-data'
          },
          baseURL: coreApiEndPoint
        }
      );
      onChange(res.data.url);
    } catch (err) {
      setIsUploading(false);
      console.error(err);
    }
  }

  function onDelete() {
    setIsUploading(false);
    onChange(undefined);
  }

  const DeleteButton = () => (
    <Button
      type="plainDestructive"
      icon="trash_alt_solid"
      onClick={onDelete}
      size="medium"
      cssStyle={MarginLeft4}
    >
      삭제
    </Button>
  );

  return (
    <div
      css={[
        css`
          margin-top: 24px;
        `,
        url &&
          css`
            display: flex;
            justify-content: space-between;
          `
      ]}
    >
      <Label
        id={label}
        cssStyle={css`
          > :nth-of-type(1) {
            display: flex;
            align-items: center;
            margin-right: 20px;
          }
        `}
      >
        <Icon
          icon="file_plus_solid"
          size="16px"
          cssStyle={css`
            margin-right: 6px;
          `}
        />
        {label}
        {description && (
          <Tooltip content={description}>
            <Icon
              cssStyle={MarginLeft4}
              icon="question_circle_solid"
              size="16px"
              color={color.icon_disabled}
            />
          </Tooltip>
        )}
      </Label>
      {(url &&
        (type === 'image' ? (
          <DeleteButton />
        ) : (
          <div
            css={css`
              display: flex;
            `}
          >
            <audio src={url} controls>
              <track kind="captions" />
              <code>audio</code>를 지원하지 않는 브라우저예요.
            </audio>
            <DeleteButton />
          </div>
        ))) ||
        (isUploading && <UploadLoadingSpinner ratio="fullWidth" />) || (
          <DropZone
            notAcceptFiles={(files) => {
              if (files.length) {
                openNotAcceptModal({
                  title: '형식에 맞는 파일을 업로드해주세요',
                  cssStyle: TextAlignCenter,
                  children: (
                    <>
                      <Icon
                        color={color.interactive_critical_default}
                        icon="times_circle_regular"
                        size="100px"
                      />
                      올바른 파일 형식을 업로드해주세요
                    </>
                  ),
                  primaryAction: { content: '확인', onAction: closeNotAcceptModal }
                });
                setIsUploading(false);
              }
            }}
            accepts={type === 'sound' ? ['.mp3'] : undefined}
            onChangeFile={uploadFile}
          />
        )}
      <Modal {...notAcceptModal} />
      <Modal {...fileSizeExceedModal} />
    </div>
  );
};

function UploadLoadingSpinner({ ratio }: Required<Pick<IDropZoneProps, 'ratio'>>) {
  return (
    <div css={[RatioStyle[ratio], CenterLayout]}>
      <Icon icon="spinner_solid" size="48px" spinning />
    </div>
  );
}

type CheckFileSizeProps =
  | {
      files: File[] | FileList;
      file?: undefined;
      maxFileSize?: number;
    }
  | { files?: undefined; file: File; maxFileSize?: number };

function checkFileSizeIsExceed({
  file,
  files,
  maxFileSize = maxDefaultFileSize
}: CheckFileSizeProps) {
  if (file) return file.size > maxFileSize;

  if (files instanceof FileList)
    return [...files].findIndex((file) => file.size > maxFileSize) !== -1;

  // Hack: error TS2532: Object is possibly 'undefined'. ???
  if (!files) throw Error();
  return files.findIndex((file) => file.size > maxFileSize) !== -1;
}

const maxDefaultFileSize = 10_485_760;

const CenterLayout = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default FileUploader;
