/* eslint-disable no-param-reassign */
import axios from 'axios';
import {
  AuthApi,
  Configuration as AuthInterfaceConfiguration
} from '@uniquegood/realworld-auth-interface';
import { APP_ENV } from '@/env';
import { getAccessToken } from '@/auth';

export const AUTH_SERVER_HOST = {
  production: 'auth.realworld.to', // FIXME: 아닐수도 있음
  development: 'auth-test.realworld.to'
}[APP_ENV];

const configuration = new AuthInterfaceConfiguration({
  basePath: `https://${AUTH_SERVER_HOST}`
});

const axiosInstance = axios.create({ withCredentials: true });
const axiosInstanceWithToken = axios.create({ withCredentials: true });

axiosInstanceWithToken.interceptors.request.use(async (config) => {
  if (!config.headers) return config;

  const token = await getAccessToken();

  if (!token) throw new Error('No token');

  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

export const authApiWithToken = new AuthApi(configuration, undefined, axiosInstanceWithToken);
export const authApi = new AuthApi(configuration, undefined, axiosInstance);
