import { css } from '@emotion/react';
import { color, FormGroup, spacing } from '@uniquegood/realworld-studio-design';
import React, { PropsWithChildren } from 'react';

import { IFormGroupProps } from '@uniquegood/realworld-studio-design/dist/types/organisms/FormGroup/FormGroup';
import Uploader, { UploaderProps } from '@/components/Uploader';
import { HideElementStyle } from '@/styles';

interface PreviewUploaderProps
  extends Omit<UploaderProps, 'hasSearch' | 'getUploadFiles' | 'isVerticalLayout'>,
    Pick<IFormGroupProps, 'requiredIndicator'> {
  label: string;
  imageSrc: string;
  onChange: UploaderProps['getUploadFiles'];
}

export default function PreviewUploader({
  label,
  requiredIndicator,
  imageSrc,
  onChange,
  children,
  ...uploaderProps
}: PropsWithChildren<PreviewUploaderProps>) {
  return (
    <FormGroup
      cssStyle={FormGroupFlex}
      id={label}
      label={label}
      requiredIndicator={requiredIndicator}
    >
      <div css={OverlayImageWrap}>
        <img alt={imageSrc} src={imageSrc} />
        {children}
      </div>
      <Uploader hasSearch getUploadFiles={onChange} isVerticalLayout {...uploaderProps} />
    </FormGroup>
  );
}

const FormGroupFlex = css`
  & > div:last-of-type {
    display: flex;
  }
`;

const OverlayImageWrap = css`
  position: relative;
  width: 180px;
  min-width: 180px;
  height: 304px;
  margin-right: ${spacing.common.large};
  /* border-radius: ${spacing.common.small}; */

  img {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: ${spacing.common.small};
    border: 1px solid ${color.border_default_subdued};
  }

  @media (max-width: 897px) {
    ${HideElementStyle};
  }
`;
