import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import React, { memo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { mutate } from 'swr';

import { coreApi } from '@/api';
import { IReOrder } from '@/pages/missions/types';
import { reorder, toast } from '@/utils';
import { ActionListBorder } from '@/styles';
import DraggableMissionListItem from './DraggableMissionListItem';

interface DraggableMissionListProps {
  missions?: Mission[];
}

function DraggableMissionList({ missions }: DraggableMissionListProps) {
  const { appId, scenarioId } = useParams<AppParam>();

  // Hack: 해당 컴포넌트가 정상적으로 상태 업데이트를 반영하지 않고 있어서 별도의 상태를 정의했습니다.
  const [_missions, setMissions] = useState<Mission[]>();

  const onDragEndMissions = ({ destination, source }: DropResult) => {
    if (!destination || !_missions) return;

    const newMissions = reorder(_missions, source.index, destination.index);

    setMissions(newMissions);

    handleChangeQuestOrder({ from: source.index, to: destination.index });
  };

  const handleChangeQuestOrder = async (data: IReOrder) => {
    await coreApi.put(`/apps/${appId}/scenarios/${scenarioId}/missions/order`, data);
    await mutate(`/apps/${appId}/scenarios/${scenarioId}/missions`);
    toast({ message: '미션 순서를 변경했어요' });
  };

  useEffect(() => {
    if (missions) {
      setMissions(missions);
    }
  }, [missions]);

  return (
    <div>
      <DragDropContext onDragEnd={onDragEndMissions}>
        <Droppable droppableId="mission-list">
          {(provided) => (
            <ul ref={provided.innerRef} {...provided.droppableProps} css={ActionListBorder}>
              {_missions?.map((mission, index) => (
                <DraggableMissionListItem index={index} mission={mission} key={mission.id} />
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default memo(DraggableMissionList);
