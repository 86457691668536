import {
  Banner,
  Button,
  ButtonGroup,
  Card,
  color,
  FormGroup,
  Icon,
  Input,
  Modal,
  spacing,
  textStyleBody,
  textStyleDisplaySmall,
  Typography,
  typography
} from '@uniquegood/realworld-studio-design';
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Controller, useForm } from 'react-hook-form';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import useModalState from '@/hooks/useModalState';
import {
  MarginTop24,
  MarginBottom24,
  MarginLeft16,
  RowFlex,
  MarginBottom10,
  MarginVertical24,
  OnMobileFlexColumn,
  TextAlignCenter,
  ATagStyle
} from '@/styles';
import BottomFloatingBar, { SpacingForBottomFloatingBar } from '@/components/BottomFloatingBar';
import { emitServerErrorToast, helpLink, inputNumberWithCommaFormatter } from '@/utils';
import { coreApi } from '@/api';

import { container960style } from '@/styles/containerStyles';
import LoadingSpinner from '@/components/LoadingSpinner';
import { onMobile } from '@/styles/responsive';

import useRequest from '@/hooks/useRequest';
import {
  useIsAdminSelector,
  useRestrictProjectRoute,
  useWrapFormToConsiderWhitespacesAsEmpty
} from '@/hooks';
import useIsReadyToRelease from './hooks/useIsReadyToRelease';

import RadioItem from './components/RadioItem';
import { preparing, released, unreleased } from './images';
import type { Status } from './types';
import Features from './components/Features';
import { EmojiRightSpace } from './styles';
import { manageChannelTypeCardId } from '../settings/Settings';
import { ClickEventName, InputEventName, PageViewEventName, SaveEventName, track } from '@/track';
import { ModalChildrenStyles } from '../detail/styles';

const payButtonUrl = 'https://rwd.to/studio_paybutton_2208';

const statusToMessage: Record<Status, string> = {
  Unreleased: '게임이 완성되었나요? 리얼월드 앱에 공개해 보세요! 🤗',
  Preparing: '두구두구🥁 출시 준비 중이에요!',
  Released: '리얼월드에 공개된 게임이에요 🎉'
};

interface FormData extends Pick<Project, 'status' | 'preparationMessage'> {
  price?: string;
  originalPrice?: string;
  pointPrice?: string;
  originalPointPrice?: string;
}

export default function Release() {
  useEffect(() => {
    track.onPageView({ pageViewEventName: PageViewEventName.view_releasemanage });
  }, []);

  const { appId, projectId } = useParams<AppParam>();

  const readyToRelease = useIsReadyToRelease();
  const isFetchingReadyToRelease = Object.values(readyToRelease).some(
    (value) => value === undefined
  );
  const isReadyToRelease =
    readyToRelease.isRequiredInfosFilled &&
    readyToRelease.hasFullScenario &&
    readyToRelease.hasVisibleMission &&
    readyToRelease.isPlayed;

  const { data: app } = useRequest<RealWorldApp>(`/apps/${appId}`);

  const { modal, openModal, closeModal } = useModalState();

  const { data: releaseStatus, mutate: mutateReleaseStatus } = useRequest<
    CommonResponseModel<ReleaseStatus>
  >(
    `/apps/${appId}/projects/${projectId}/releaseStatus`,
    {},
    {
      headers: {
        'x-rwd-api-version': '1.1'
      }
    }
  );
  const { data: isAdmin } = useIsAdminSelector();

  const [isHavePrice, setHavePrice] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const methods = useForm<FormData>();
  const { control, handleSubmit, formState } = useWrapFormToConsiderWhitespacesAsEmpty(methods);

  const history = useHistory();

  const redirectTo = useRestrictProjectRoute();

  const isKitRequired = releaseStatus?.data.projectType.includes('Kit');
  const priceFieldRules = isKitRequired ? undefined : { min: 20 };

  const onSubmit = handleSubmit(async (data) => {
    if (data.status === 'Released') {
      openModal({
        title: '게임을 공개하시겠어요?',
        children: (
          <>
            <p css={MarginBottom1em}>
              공개 즉시 <strong>리얼월드 앱</strong>에 노출되어요.
            </p>
            <p>
              공개 전 게임이 <strong>완성 상태</strong>인지 또{' '}
              <Button url={helpLink.notion.guideline} external>
                커뮤니티 가이드라인
              </Button>
              을 위반하지 않았는지 마지막으로 확인해주세요!
            </p>
          </>
        ),
        primaryAction: {
          content: '게임 출시!',
          onAction: async () => {
            try {
              await submit();
              publishedModal();
            } catch (error) {
              emitServerErrorToast(error);
            }
          }
        },
        secondaryAction: { content: '취소', onAction: closeModal }
      });
      return;
    }

    await submit();

    openModal({
      title: '출시 정보를 수정했어요',
      cssStyle: TextAlignCenter,
      children: (
        <>
          <Icon
            cssStyle={MarginBottom10}
            icon="check_circle_regular"
            size="100px"
            color={color.interactive_primary_subdued}
          />
          수정된 정보가 잘 반영되었는지 리얼월드 앱에서 확인해주세요
        </>
      ),
      primaryAction: { content: '확인', onAction: closeModal }
    });

    async function submit() {
      setIsSubmitting(true);

      const body = (() => {
        if (isKitRequired) {
          if (isHavePrice)
            return {
              price: removeCommaAndParseInt(data.price),
              originalPrice: removeCommaAndParseInt(data.originalPrice)
            };

          return { price: 0, originalPrice: 0 };
        }
        if (isHavePrice)
          return {
            price: removeCommaAndParseInt(data.price),
            originalPrice: removeCommaAndParseInt(data.originalPrice),
            pointPrice: removeCommaAndParseInt(data.pointPrice),
            originalPointPrice: removeCommaAndParseInt(data.originalPointPrice)
          };
        return { price: 0, originalPrice: 0, pointPrice: 0, originalPointPrice: 0 };
      })();

      await coreApi.put<FormData>(
        `/apps/${appId}/projects/${projectId}/releaseStatus`,
        {
          ...data,
          ...body
        },
        {
          headers: {
            'x-rwd-api-version': '1.1'
          }
        }
      );
      track.onSave({
        saveEventName: SaveEventName.save_releasemanage,
        params: { status: data.status }
      });

      await mutateReleaseStatus();

      setIsSubmitting(false);

      function removeCommaAndParseInt(value: string | undefined) {
        if (value) {
          const parsedValue = parseInt(value.replaceAll(',', ''), 10);
          return Number.isNaN(parsedValue) ? 0 : parsedValue;
        }

        return 0;
      }
    }

    function publishedModal() {
      openModal({
        title: '게임을 공개했어요 🎉',
        cssStyle: TextAlignCenter,
        children: (
          <>
            <Icon
              cssStyle={MarginBottom10}
              icon="check_circle_regular"
              size="100px"
              color={color.interactive_primary_subdued}
            />
            리얼월드 앱에서 정상적으로 출시되었는지 확인해주세요. 게임을 검색하면 쉽게 확인할 수
            있어요.
          </>
        ),
        primaryAction: { content: '확인', onAction: closeModal }
      });
    }
  });

  const isBeforeUseReleasePermission =
    !!app?.releasePermission && dayjs().isBefore(app.releasePermission.startsAt);

  useEffect(() => {
    if (app?.channelType === 'Business') {
      if (!app.releasePermission) {
        openModal({
          title: '비즈니스 요금제에 가입해주세요!',
          cssStyle: textStyleBody,
          children: (
            <>
              <p>비즈니스 채널에서 게임을 출시하려면 요금제 가입이 필요해요.</p>
              <p>
                자세한 내용은{' '}
                <Button url={payButtonUrl} external>
                  공지
                </Button>
                를 확인해주세요!
              </p>
            </>
          ),
          primaryAction: {
            content: '공지 확인하기',
            type: 'basic',
            onAction: () => window.open(payButtonUrl, '_blank')
          }
        });
        return;
      }

      if (isBeforeUseReleasePermission) {
        const formatTemplate = 'YYYY-MM-DD';
        const formattedTime = dayjs(app.releasePermission.startsAt).format(formatTemplate);
        openModal({
          title: '출시 가능한 기간이 아니에요!',
          cssStyle: textStyleBody,
          children: (
            <>
              아직 게임을 출시할 수 있는 기간이 아닙니다!
              <br />
              <Typography as="span" type="body" textColor="subdued">
                (이용 시작 예정일: <time dateTime={formattedTime}>{formattedTime}</time>)
              </Typography>
            </>
          ),
          primaryAction: {
            content: '닫기',
            onAction: closeModal
          }
        });
        return;
      }
    }

    if (isReadyToRelease === false) {
      openModal({
        title: '출시 조건을 확인해주세요!',
        children: (
          <>
            <ul css={MarginBottom1em}>
              <Typography as="li" type="body">
                {readyToRelease.isRequiredInfosFilled ? (
                  <>
                    <span css={EmojiRightSpace}>ℹ️️</span>
                    <b>게임 필수 정보</b>를 입력했어요
                  </>
                ) : (
                  <>
                    <span css={EmojiRightSpace}>❌</span>
                    <b>게임 필수 정보</b>를 입력하셨나요?
                  </>
                )}
              </Typography>
              <Typography as="li" type="body">
                {readyToRelease.hasFullScenario ? (
                  <>
                    <span css={EmojiRightSpace}>🎮</span>
                    <b>게임 시작과 게임 종료 리액션</b>을 넣었어요
                  </>
                ) : (
                  <>
                    <span css={EmojiRightSpace}>❌</span>
                    <b>게임 시작과 게임 종료 리액션</b>을 넣으셨나요?
                  </>
                )}
              </Typography>
              <Typography as="li" type="body">
                {readyToRelease.hasVisibleMission ? (
                  <>
                    <span css={EmojiRightSpace}>🎮</span>
                    <b>한 개 이상의 미션이 &apos;표시 상태&apos;</b>에요
                  </>
                ) : (
                  <>
                    <span css={EmojiRightSpace}>❌</span>
                    <b>한 개 이상의 미션이 &apos;표시 상태&apos;</b>인가요?
                  </>
                )}
              </Typography>
              <Typography as="li" type="body">
                {readyToRelease.isPlayed ? (
                  <>
                    <span css={EmojiRightSpace}>🎮</span>
                    <b>게임을 시작부터 마지막</b>까지 플레이했어요
                  </>
                ) : (
                  <>
                    <span css={EmojiRightSpace}>❌</span>
                    <b>게임을 시작부터 마지막</b>까지 플레이해보셨나요?
                  </>
                )}
              </Typography>
            </ul>
            <Typography as="span" type="body">
              출시 조건에 대한 더 자세한 정보는{' '}
              <Button url={helpLink.notion.helpCenter} external>
                헬프센터
              </Button>
              에서 확인해주세요.
            </Typography>
          </>
        ),
        primaryAction: { content: '확인', onAction: closeModal },
        secondaryAction: {
          content: '헬프센터 바로가기',
          onAction: () => window.open(helpLink.notion.helpCenter, '_blank')?.focus()
        }
      });
    }
  }, [app, isBeforeUseReleasePermission, isReadyToRelease]);

  useEffect(() => {
    if (
      releaseStatus?.data.price ||
      releaseStatus?.data.originalPrice ||
      releaseStatus?.data.pointPrice ||
      releaseStatus?.data.originalPointPrice
    ) {
      setHavePrice(true);
    }
  }, [releaseStatus?.data]);

  useEffect(() => {
    if (formState.errors.preparationMessage) {
      openModal({
        title: '필수 항목을 모두 채워주세요',
        cssStyle: TextAlignCenter,
        children: (
          <>
            <Icon
              cssStyle={MarginBottom10}
              icon="times_circle_regular"
              size="100px"
              color={color.interactive_critical_default}
            />
            준비 안내 문구가 비어있어요
          </>
        ),
        primaryAction: { content: '확인', onAction: closeModal }
      });
    }

    if (formState.errors.pointPrice || formState.errors.originalPointPrice) {
      openModal({
        title: '판매 가격을 다시 설정해주세요',
        cssStyle: ModalChildrenStyles,
        primaryAction: {
          content: '확인',
          onAction: closeModal
        },
        children: (
          <>
            <Icon
              cssStyle={MarginBottom10}
              icon="times_circle_regular"
              size="100px"
              color={color.interactive_critical_default}
            />
            최소 20 츄로 이상으로 설정해주세요.
          </>
        )
      });
    }
  }, [formState.submitCount]);

  if (isFetchingReadyToRelease || !releaseStatus?.data || redirectTo === undefined || !app)
    return <LoadingSpinner />;

  if (typeof redirectTo === 'string') return <Redirect to={redirectTo} />;

  return (
    <>
      {((app.channelType === 'Business' &&
        (!app.releasePermission || isBeforeUseReleasePermission)) ||
        isReadyToRelease === false) && <div css={Backdrop} />}
      <form
        css={[container960style, SpacingLayout, SpacingForBottomFloatingBar]}
        onSubmit={onSubmit}
      >
        <Banner
          header={
            <span>
              출시 전{' '}
              <a href={helpLink.notion.guideline} target="_blank" rel="noreferrer" css={ATagStyle}>
                커뮤니티 가이드라인
              </a>
              을 확인해주세요!
            </span>
          }
        >
          선정적이거나 불쾌감을 주는 이미지, 타인의 저작권이나 개인정보 또는 초상권을 침해하는 내용,
          위험한 행동을 조장하는 내용은 게시할 수 없으며 위반할 경우 관리자에게 제재를 받을 수
          있어요.
        </Banner>
        <Card>
          <header css={[HeaderStyle, textStyleDisplaySmall, MarginBottom24]}>
            {statusToMessage[releaseStatus?.data.status]}
          </header>
          <Controller
            control={control}
            name="status"
            defaultValue={releaseStatus?.data.status}
            render={({ field: { value, onChange } }) => (
              <section css={RadioGroupStyle} role="radiogroup">
                <RadioItem
                  value="Released"
                  title="게임 공개!"
                  serverSideSelected={releaseStatus?.data.status === 'Released'}
                  selected={value === 'Released'}
                  onChange={(...args) => {
                    onChange(...args);
                    track.onClick({
                      clickEventName: ClickEventName.click_releasemanage_select_openornot,
                      params: { status: '게임 공개' }
                    });
                  }}
                >
                  <img src={released} alt="COOK UP 무료" />
                  <p css={[textStyleBody, MarginVertical24]}>
                    개봉박두!
                    <br />
                    🎊 리얼월드 앱에 게임을 공개해요.
                  </p>
                  <Features
                    features={{
                      '🔎 검색 시 리스트에 노출': true,
                      '🗒 게임 상세정보 확인 가능': true,
                      '🕹 게임 플레이 가능': true
                    }}
                  />
                </RadioItem>
                <RadioItem
                  value="Preparing"
                  title="준비중"
                  serverSideSelected={releaseStatus?.data.status === 'Preparing'}
                  selected={value === 'Preparing'}
                  onChange={(...args) => {
                    onChange(...args);
                    track.onClick({
                      clickEventName: ClickEventName.click_releasemanage_select_openornot,
                      params: { status: '준비중' }
                    });
                  }}
                >
                  <img src={preparing} alt="준비 중이에요. 준비 안내 문구가 표시되는 영역입니다." />
                  <p css={[textStyleBody, MarginVertical24]}>
                    커밍쑨! 출시 임박한 게임을 미리 선보이거나, 공개한 게임의 운영을 잠시 중단해야
                    할 때 사용해요.
                  </p>
                  <Features
                    features={{
                      '🔎 검색 시 리스트에 노출': true,
                      '🗒 게임 상세정보 확인 가능': true,
                      '🕹 게임 플레이 가능': false
                    }}
                  />
                  {value === 'Preparing' && (
                    <Controller
                      control={control}
                      name="preparationMessage"
                      defaultValue={releaseStatus?.data.preparationMessage}
                      rules={{ required: true }}
                      render={({ field: { value, onChange }, fieldState: { invalid } }) => (
                        <FormGroup
                          label="준비 안내 문구 (필수)"
                          requiredIndicator
                          errorText={invalid ? '필수 항목이에요' : undefined}
                          cssStyle={[DividerStyle, MarginTop24]}
                        >
                          <Input
                            cssStyle={MultilineInputStyle}
                            multiline
                            maxLength={150}
                            value={value}
                            onChange={(...args) => {
                              onChange(...args);
                              track.onInputThrottled({
                                inputEventName:
                                  InputEventName.input_releasemanage_ready_readyannouncement
                              });
                            }}
                            error={invalid}
                            placeholder="예) 장소 변경으로 리뉴얼 중이에요"
                          />
                        </FormGroup>
                      )}
                    />
                  )}
                </RadioItem>
                <RadioItem
                  value="Unreleased"
                  title="미공개"
                  serverSideSelected={releaseStatus?.data.status === 'Unreleased'}
                  selected={value === 'Unreleased'}
                  onChange={(...args) => {
                    onChange(...args);
                    track.onClick({
                      clickEventName: ClickEventName.click_releasemanage_select_openornot,
                      params: { status: '미공개' }
                    });
                  }}
                >
                  <img
                    src={unreleased}
                    alt="준비 중이에요. 준비 안내 문구가 표시되는 영역입니다."
                  />
                  <p css={[textStyleBody, MarginVertical24]}>게임을 제작중일 때 사용해요</p>
                  <Features
                    features={{
                      '🔎 검색 시 리스트에 노출': false,
                      '🗒 게임 상세정보 확인 가능': false,
                      '🕹 게임 플레이 가능': false
                    }}
                  />
                </RadioItem>
              </section>
            )}
          />
        </Card>
        <Card header={{ content: '판매 정보 설정', prefix: <Icon icon="tags_solid" /> }}>
          <FormGroup label="출시 가격 선택">
            <ButtonGroup segmented cssStyle={RowFlex}>
              <Button
                type={!isHavePrice ? 'primary' : 'outlinePrimary'}
                onClick={() => {
                  setHavePrice(false);
                  track.onClick({
                    clickEventName: ClickEventName.click_releasemanage_salesetting_select_price,
                    params: { priceType: '무료' }
                  });
                }}
              >
                무료
              </Button>
              <Button
                type={isHavePrice ? 'primary' : 'outlinePrimary'}
                onClick={() => {
                  track.onClick({
                    clickEventName: ClickEventName.click_releasemanage_salesetting_select_price,
                    params: { priceType: '유료' }
                  });
                  if (isAdmin || app.channelType === 'Realworld') {
                    setHavePrice(true);
                    return;
                  }

                  if (app.channelType === 'Normal') {
                    openModal({
                      title: '비즈니스 채널로 변경해주세요!',
                      cssStyle: textStyleBody,
                      children:
                        '유료 게임은 비즈니스 채널 전용 기능입니다. 비즈니스 채널로 변경 후 비즈니스 요금제에 가입하여 유료로 게임을 출시 해주세요',
                      primaryAction: {
                        content: '채널 설정 페이지로 이동',
                        onAction: () => {
                          history.push(`/apps/${appId}/settings/#${manageChannelTypeCardId}`);
                        }
                      }
                    });
                  }
                }}
              >
                유료
              </Button>
            </ButtonGroup>
          </FormGroup>
          {isHavePrice && (
            <div css={[RowFlex, OnMobileFlexColumn, MarginTop24]}>
              <FormGroup label="판매가" cssStyle={FormGroupContentLayout}>
                <Controller
                  control={control}
                  rules={priceFieldRules}
                  name={isKitRequired ? 'price' : 'pointPrice'}
                  defaultValue={(isKitRequired
                    ? releaseStatus?.data.price
                    : releaseStatus?.data.pointPrice
                  )?.toString()}
                  render={({ field: { value, onChange } }) => (
                    <Input
                      inputMode="decimal"
                      value={inputNumberWithCommaFormatter(value)}
                      onChange={(...args) => {
                        onChange(...args);
                        track.onInputThrottled({
                          inputEventName: InputEventName.input_releasemanage_salesetting_payprice,
                          params: { price: args[0] }
                        });
                      }}
                    />
                  )}
                />
                {isKitRequired ? (
                  <Button
                    cssStyle={WonButtonStyle}
                    size="large"
                    icon="won_sign_solid"
                    type="basic"
                    disabled
                  />
                ) : (
                  <span className="currency" css={textStyleBody}>
                    츄로
                  </span>
                )}
              </FormGroup>
              <Controller
                control={control}
                rules={{
                  validate: (value) => {
                    if (isKitRequired) return true;
                    if (value === undefined) return true;

                    const parsedPrice = parseInt(value, 10);
                    if (Number.isNaN(parsedPrice) || parsedPrice === 0) return true;

                    return parsedPrice >= 20;
                  }
                }}
                name={isKitRequired ? 'originalPrice' : 'originalPointPrice'}
                defaultValue={(isKitRequired
                  ? releaseStatus?.data.originalPrice
                  : releaseStatus?.data.originalPointPrice
                )?.toString()}
                render={({ field: { value, onChange } }) => (
                  <FormGroup
                    label="할인 전 가격 (할인 중일 시에만 등록)"
                    cssStyle={[MarginLeft16, FormGroupContentLayout]}
                  >
                    <Input
                      inputMode="decimal"
                      value={inputNumberWithCommaFormatter(value)}
                      onChange={(...args) => {
                        onChange(...args);
                        track.onInputThrottled({
                          inputEventName:
                            InputEventName.input_releasemanage_salesetting_discountprice,
                          params: { originalPrice: args[0] }
                        });
                      }}
                    />
                    {isKitRequired ? (
                      <Button
                        cssStyle={WonButtonStyle}
                        size="large"
                        icon="won_sign_solid"
                        type="basic"
                        disabled
                      />
                    ) : (
                      <span className="currency" css={textStyleBody}>
                        츄로
                      </span>
                    )}
                  </FormGroup>
                )}
              />
            </div>
          )}
        </Card>
      </form>
      <BottomFloatingBar onSave={onSubmit} loading={formState.isSubmitting} />
      <Modal
        {...modal}
        primaryAction={{
          ...modal.primaryAction,
          loading: isSubmitting
        }}
      />
    </>
  );
}

const Backdrop = css`
  position: fixed;
  z-index: 3;
  background: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
`;

const SpacingLayout = css`
  > *:not(:last-of-type) {
    margin-bottom: ${spacing.margin.xlarge2};
  }
`;

const HeaderStyle = css`
  background-color: ${color.palette_secondary_blue_tint_02};
  padding: ${spacing.padding.large};

  font-weight: ${typography.weight.medium};
  border-radius: 8px;
`;

const DividerStyle = css`
  border-top: 1px solid ${color.border_default_subdued};
  padding-top: ${spacing.padding.xlarge2};
  width: 100%;
`;

const MultilineInputStyle = css`
  textarea {
    height: 126px !important;
  }
`;

const RadioGroupStyle = css`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

  align-items: flex-start;

  gap: ${spacing.margin.xlarge2};

  > label {
    flex: 1 1;
  }

  img {
    width: 100%;
  }
`;

const FormGroupContentLayout = css`
  flex: 1 0;

  ${onMobile} {
    margin-top: ${spacing.margin.large};
    margin-left: 0;
  }

  > div:last-of-type {
    display: flex;
    align-items: center;

    > div {
      width: 100%;
    }

    button,
    .currency {
      margin-left: ${spacing.margin.small};
    }
  }
`;

const WonButtonStyle = css`
  width: 32px;
  height: 32px;
`;

const MarginBottom1em = css`
  margin-bottom: 1em;
`;
