/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { css } from '@emotion/react';
import { Icon, Label, Button, Tooltip, color } from '@uniquegood/realworld-studio-design';
import { MarginLeft4 } from '../../../../styles';
import InputNumber from './InputNumber';
import InputText from './InputText';
import FileUploader from './FileUploader';
import useDidUpdateEffect from '../../../../hooks/useDidUpdateEffect';

interface Props {
  label: string;
  description?: string;
  defaultValue: any;
  elementDefinitions?: elementDefinition[];
  onChange: (data: string | boolean | string[]) => void;
}

interface elementDefinition {
  label: string;
  key: string;
  type: string;
}

const InputList = ({ label, defaultValue, description, elementDefinitions, onChange }: Props) => {
  const [list, setList] = useState<any[]>(defaultValue);

  function addListItem() {
    setList([...list, { value: 0 }]);
  }

  function removeListItem(index: number) {
    const newList = [...list];
    newList.splice(index, 1);
    setList(newList);
  }

  function updateValueToInputList(index: number, value: string) {
    const newList = [...list];
    newList[index] = { value: parseInt(value, 10) };

    setList(newList);
  }

  useDidUpdateEffect(() => {
    onChange(list);
  }, [list]);

  const elements = list.map((listItem, index) => {
    return elementDefinitions?.map((elementDefinition) => {
      if (elementDefinition.type === 'number') {
        return (
          <>
            <InputNumber
              value={listItem.value}
              label={elementDefinition.label}
              onChange={(value) => updateValueToInputList(index, value)}
              onClick={() => removeListItem(index)}
              isListItem
            />
          </>
        );
      }
      // if (elementDefinition.type === 'text') {
      //   return (
      //     <InputText
      //       value={listItem.value}
      //       label={elementDefinition.label}
      //       onChange={(value) => updateValueToInputList(index, value)}
      //       onClick={() => removeListItem(index)}
      //       isListItem
      //     />
      //   );
      // }
      if (elementDefinition.type === 'file') {
        // return <FileUploader />;
      }
      return null;
    });
  });

  return (
    <>
      <div
        css={css`
          padding-top: 8px;
        `}
      >
        <Label
          id={label}
          cssStyle={css`
            padding-top: 24px;
            > :nth-of-type(1) {
              display: flex;
              align-items: center;
              margin-right: 12px;
            }
          `}
        >
          <Icon
            icon="bars_solid"
            size="16px"
            cssStyle={css`
              margin-right: 6px;
            `}
          />
          {label}
          {description && (
            <Tooltip content={description}>
              <Icon
                cssStyle={MarginLeft4}
                icon="question_circle_solid"
                size="16px"
                color={color.icon_disabled}
              />
            </Tooltip>
          )}
        </Label>
        <Button
          icon="plus_solid"
          onClick={() => addListItem()}
          size="small"
          cssStyle={css`
            span {
              width: 16px;
              height: 16px;
            }
          `}
        />
      </div>
      <div
        css={css`
          padding: 0px 24px;
        `}
      >
        {elements}
      </div>
    </>
  );
};

export default InputList;
