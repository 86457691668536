/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import PreviewHeader from './PreviewHeader';

const IframeStyle = css`
  width: 100%;
  height: 597px;
  border: none;
`;

interface Props {
  filledTemplate: string;
  templateName: string;
  onGoBack(): void;
}

const PreviewArea: React.FC<Props> = ({ filledTemplate, templateName, onGoBack }) => {
  return (
    <div>
      <PreviewHeader onGoBack={onGoBack} templateName={templateName} />
      <div
        css={css`
          width: 350px;
          margin: 0 auto;
          padding: 20px 0px;
        `}
      >
        <iframe
          css={IframeStyle}
          title="미리보기영역"
          src={`data:text/html,<meta charset="UTF-8" />${window.encodeURIComponent(
            filledTemplate
          )}`}
        />
      </div>
    </div>
  );
};

export default PreviewArea;
