import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getAccessToken, OAUTH_LOGIN_URI } from '@/auth';

export default function OAuth() {
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessToken();

      if (accessToken) {
        history.push('/apps');
        return;
      }

      window.location.href = OAUTH_LOGIN_URI;
    })();
  }, []);

  return null;
}
