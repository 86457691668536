import { unsplashApi } from '@/api';

interface UnsplashPhotoParams {
  query: string;
  page: number;
}

interface UnsplashPhotoDownloadResponse {
  url: string;
}

export const unsplash = {
  search: (params: UnsplashPhotoParams) =>
    unsplashApi
      .get<Unsplash>('/search/photos', {
        params: {
          ...params,
          per_page: 30
        }
      })
      .then(({ data }) => data),
  getUrl: (photo: UnsplashResultsEntity) =>
    unsplashApi
      .get<UnsplashPhotoDownloadResponse>(`/photos/${photo.id}/download`)
      .then(({ data }) => data)
};
