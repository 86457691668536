import React, { memo } from 'react';

import { InformationByMemberLevelType, MemberCardActionProps } from '../types';
import MemberCard from './MemberCard';

interface MemberListProps extends MemberCardActionProps {
  members: User[];
  level: InformationByMemberLevelType;
}

function MemberList({ members, level, ...actionProps }: MemberListProps) {
  return (
    <>
      {members
        .filter((m) => m.level === level.en || (level.en === 'Author' && m.level === 'Developer'))
        .map((m) => (
          <MemberCard key={m.id} member={m} {...actionProps} />
        ))}
    </>
  );
}

export default memo(MemberList);
