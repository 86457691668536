import { css } from '@emotion/react';

import React, { useEffect } from 'react';
import { useHistory, useParams, Route } from 'react-router-dom';

import { Button, color, spacing } from '@uniquegood/realworld-studio-design';
import { CardShadowStyle, ColumnFlex, OnMobileFlexColumn, RowFlex, FlexGrow } from '@/styles';
import { onMobile } from '@/styles/responsive';

import BeltBanner from '@/components/BeltBanner';

import ActionHandler from '@/components/ActionHandler';
import LoadingSpinner from '@/components/LoadingSpinner';
import SceneList from './components/SceneList';
import ChatBotListBar from './components/ChatBotListBar';
import Header from './components/Header';
import SceneTitle from './components/SceneTitle';

import useChatbot from './hooks/useChatbot';
import EmptyState from './components/EmptyState';
import { TOP_BAR_HEIGHT } from '@/components/TopBar/Topbar';
import { PageViewEventName, track } from '@/track';

export default function ChatBots() {
  const { appId, projectId, chatbotId } = useParams<AppParam>();

  const { items } = useChatbot();
  const history = useHistory();

  useEffect(() => {
    track.onPageView({ pageViewEventName: PageViewEventName.view_editgame_editchatbot });
  }, []);

  useEffect(() => {
    const { pathname } = history.location;

    const basePathname = `/apps/${appId}/projects/${projectId}/chatbots`;

    if (!items || items.length === 0) return;

    const chatbotRootPathname = `${basePathname}/${chatbotId}`;
    const isChatbotRootPathname = pathname === chatbotRootPathname;

    if (pathname === basePathname || isChatbotRootPathname) {
      const targetChatbot = isChatbotRootPathname
        ? items.find((item) => item.id === chatbotId)
        : items[0];

      if (!targetChatbot) throw Error('기대하지 않은 경우입니다');

      if (targetChatbot.scenes.length > 0) {
        const scene = targetChatbot.scenes[0];
        history.replace(`${basePathname}/${targetChatbot.id}/scenes/${scene.id}`);
      } else {
        const replaceTo = `${basePathname}/${targetChatbot.id}`;
        if (pathname !== replaceTo) {
          history.replace(replaceTo);
        }
      }
    }
  }, [items, appId, projectId, chatbotId]);

  if (!items)
    return (
      <div css={FlexGrow}>
        <LoadingSpinner />
      </div>
    );

  return (
    <>
      <div css={[RowFlex, FlexGrow, OnMobileFlexColumn, ContainerLayout]}>
        <ChatBotListBar />
        <Main />
      </div>
    </>
  );
}

export const ChatbotContainerMarginLeft = css`
  margin-left: 120px;
`;

const ContainerLayout = css`
  > *:nth-of-type(1) {
    position: fixed;
    height: calc(100vh - ${TOP_BAR_HEIGHT});
    top: ${TOP_BAR_HEIGHT};

    ${onMobile} {
      position: initial;
      height: initial;
    }
  }

  > *:nth-of-type(2) {
    ${ChatbotContainerMarginLeft}

    ${onMobile} {
      overflow-y: initial;
      margin-left: initial;
    }
  }
`;

function SceneEditor() {
  const { item } = useChatbot();
  const history = useHistory();
  const { appId, projectId, chatbotId, sceneId } = useParams<AppParam>();

  const scene = item?.scenes.find((s) => s.id === sceneId);
  if (!scene) {
    history.replace(`/apps/${appId}/projects/${projectId}/chatbots/${chatbotId}`);
    return null;
  }
  return <ActionHandler key={scene.id} availabelType="chatbot" />;
}

function Main() {
  const { items } = useChatbot();

  if (items?.length === 0)
    return (
      <section css={[MainStyle, CardShadowStyle, ColumnFlex]}>
        {beltBanner}
        <EmptyState />
      </section>
    );

  return (
    <section css={[MainStyle, CardShadowStyle]}>
      {beltBanner}
      <Header />
      <SceneList />
      <SceneTitle />
      <Route
        path="/apps/:appId/projects/:projectId/chatbots/:chatbotId/scenes/:sceneId"
        render={() => <SceneEditor />}
      />
    </section>
  );
}

const beltBanner = (
  <BeltBanner
    backgroundColor={color.palette_secondary_yellow_100}
    color={color.text_default}
    cssStyle={css`
      flex: 0 1;
    `}
  >
    챗봇 사용법이 궁금하다면 📝
    <Button url="https://rwd.to/realworldstudio-manual-chatbot" external>
      매뉴얼
    </Button>
    📝을 참고해 주세요. 버그나 피드백은 🚨
    <Button url="https://rwd.to/realworldstudio-feedback-chatbot" external>
      여기
    </Button>
    🚨로 제보해 주세요!
  </BeltBanner>
);

const MainStyle = css`
  background-color: white;
  width: 100%;
  overflow-y: auto;

  && {
    > * {
      padding-left: ${spacing.padding.xlarge2};
      padding-right: ${spacing.padding.xlarge2};

      ${onMobile} {
        padding-left: ${spacing.padding.large};
        padding-right: ${spacing.padding.large};
      }
    }
  }

  > *:nth-of-type(3) {
    margin-bottom: ${spacing.margin.xlarge2};
  }
`;
