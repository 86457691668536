import React from 'react';
import { decodeJwt } from 'jose';
import { setUserId } from '@hackler/javascript-sdk';
import { track } from '@/track';

export default function TrackProvider({ children }: { children: JSX.Element }) {
  const isInitialized = React.useRef(false);

  React.useEffect(() => {
    if (isInitialized.current) return;
    isInitialized.current = true;
    // 딱히 갱신된 코드가 필요하지 않아 로컬스토리지를 직접 거치도록 함
    const token = localStorage.getItem('accessToken');

    if (!token) {
      track.initialize({});
    } else {
      const { sub } = decodeJwt(token);
      if (sub) {
        track.initialize({ userId: sub });

        setUserId(sub);
      }
    }
  }, []);

  return children;
}
