import React, { useCallback, useEffect, useRef } from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';

const Confetti = () => {
  const refAnimationInstance: any = useRef(null);

  const defaultConfettiOptions = {
    particleCount: 200,
    spread: 190,
    origin: {
      y: 0.7
    },
    ticks: 90,
    startVelocity: 50
  };

  const setConfettiInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const startConfettiAnimation = () => {
    if (refAnimationInstance.current) {
      refAnimationInstance.current(defaultConfettiOptions);
    }
  };

  // 컴포넌트가 마운트되면 애니메이션 시작
  useEffect(() => {
    startConfettiAnimation();
  }, []);

  return (
    <>
      <ReactCanvasConfetti
        refConfetti={setConfettiInstance}
        style={{
          position: 'fixed',
          pointerEvents: 'none',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0
        }}
      />
    </>
  );
};

export default Confetti;
