/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Icon, Typography } from '@uniquegood/realworld-studio-design';

const PreviewHeaderStyle = css`
  width: 100%;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.07);
`;

const PreviewHeaderContentsStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding: 0 24px;
`;

interface Props {
  templateName: string;
  onGoBack(): void;
}

const PreviewHeader: React.FC<Props> = ({ templateName, onGoBack }) => {
  return (
    <div css={PreviewHeaderStyle}>
      <div css={PreviewHeaderContentsStyle}>
        <div
          css={css`
            display: flex;
            gap: 8px;
          `}
        >
          <Icon
            icon="arrow_left_regular"
            size="24px"
            onClick={onGoBack}
            cssStyle={css`
              cursor: pointer;
            `}
          />
          <Typography type="heading">{templateName}</Typography>
        </div>
      </div>
    </div>
  );
};

export default PreviewHeader;
