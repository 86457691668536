import { css } from '@emotion/react';
import { Slider } from '@reach/slider';
import { Typography, color, shadow, spacing } from '@uniquegood/realworld-studio-design';

import '@reach/slider/styles.css';

interface RangeInputProps extends React.ComponentProps<typeof Slider> {
  multiplyToShow?: number;
  unit?: string;
}

export default function RangeInput({ unit, multiplyToShow = 1, ...rangeProps }: RangeInputProps) {
  return (
    <div css={rangeWarpStyle}>
      <Slider css={rangeStyle} {...rangeProps} />
      {unit && (
        <Typography as="label" type="button" aria-describedby="radius">
          {Math.floor((rangeProps.value ?? 0) * multiplyToShow)}
          {unit}
        </Typography>
      )}
    </div>
  );
}

const rangeWarpStyle = css`
  display: flex;
  align-items: baseline;

  gap: ${spacing.margin.large};
`;

const rangeStyle = css`
  flex: 1;

  [data-reach-slider-track] {
    height: 8px;
    background-color: ${color.surface_gray_default};
    cursor: pointer;
    border-radius: ${spacing.borderRadius.small};
  }

  [data-reach-slider-range] {
    background-color: ${color.action_primary_default};
    border-radius: ${spacing.borderRadius.small};
  }

  [data-reach-slider-handle] {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${color.action_primary_default};
    cursor: pointer;
    border: none;
    ${shadow.toast};
  }
`;
