import { FormGroup, Input } from '@uniquegood/realworld-studio-design';
import { Control, Controller } from 'react-hook-form';
import { useMemo } from 'react';
import Uploader from '@/components/Uploader';
import { MarginBottom24 } from '@/styles';
import type { PersonaFormData } from '../types';

interface PersonaFormProps {
  persona?: Persona;
  onSubmit(e: unknown): void;
  // eslint-disable-next-line @typescript-eslint/ban-types
  control: Control<PersonaFormData, object>;
}

export default function PersonaForm({ persona, onSubmit, control }: PersonaFormProps) {
  return (
    <form onSubmit={onSubmit}>
      <Controller
        control={control}
        name="name"
        rules={{ required: true }}
        defaultValue={persona?.name}
        render={({ field: { value, onChange }, fieldState: { invalid } }) => (
          <FormGroup
            label="페르소나 이름"
            errorText={invalid ? '필수 항목이에요' : undefined}
            cssStyle={MarginBottom24}
            requiredIndicator
          >
            <Input
              value={value}
              onChange={onChange}
              defaultValue={persona?.name}
              maxLength={30}
              showCharacterCount
            />
          </FormGroup>
        )}
      />
      <Controller
        control={control}
        name="profileImageUrl"
        rules={{ required: '필수 항목이에요' }}
        defaultValue={persona?.profileImageUrl}
        render={({ field: { value: url, onChange }, fieldState: { error } }) => {
          const memoedFiles = useMemo(() => (url ? [{ url, id: '' }] : undefined), [url]);
          return (
            <FormGroup
              label="페르소나 이미지"
              // Hack: 페르소나 등록 이후 첫 수정 시 사진이 있는데도 오류 메세지가 보여서 url 이 없는 경우에만 오류 메세지를 보여주도록 했습니다.
              errorText={url ? undefined : error?.message}
              requiredIndicator
            >
              <Uploader
                hasSearch={!url}
                ratio="square"
                // Hack: id를 사용하지 않습니다.
                uploadedFiles={memoedFiles}
                getUploadFiles={(files) => onChange(files?.[0]?.url)}
              />
            </FormGroup>
          );
        }}
      />
    </form>
  );
}
