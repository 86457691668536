import { css, SerializedStyles } from '@emotion/react';
import { Button, spacing, Typography } from '@uniquegood/realworld-studio-design';
import { BaseButton } from '@uniquegood/realworld-studio-design/dist/types/interface';
import { textShortening } from '@/styles';

interface ContainerHeaderProps extends Pick<BaseButton, 'onClick'> {
  editButtonText?: string;
  cssStyle?: SerializedStyles | SerializedStyles[];
}

export default function ContainerHeader({
  editButtonText,
  onClick,
  cssStyle,
  children
}: React.PropsWithChildren<ContainerHeaderProps>) {
  return (
    <header css={[WrapStyle, cssStyle]}>
      <Typography as="h1" type="displayMedium" cssStyle={textShortening}>
        {children}
      </Typography>
      {editButtonText && (
        <Button onClick={onClick} icon="pen_solid">
          {editButtonText}
        </Button>
      )}
    </header>
  );
}

const WrapStyle = css`
  display: flex;
  align-items: center;

  > h1 {
    line-height: 64px;
  }
  > button {
    flex-shrink: 0;
    margin-left: ${spacing.common.medium};
  }
`;
