/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React from 'react';

import { css } from '@emotion/react';
import { cardCodeToLabel } from '@pages/payment/const';
import { MembershipBillingDataResponse } from '@uniquegood/realworld-pay-interface/dist/model/membership-billing-data-response';

interface PaymentMethodModalProps {
  cardList: MembershipBillingDataResponse[];
  onClose: () => unknown;
  selectMethod: (billingId: string) => unknown;
  addMethodAndBilling: () => unknown;
}
function PaymentMethodModal({
  cardList,
  onClose,
  selectMethod,
  addMethodAndBilling
}: PaymentMethodModalProps) {
  const handleSelectMethod = (billingId: string) => {
    selectMethod(billingId);
    onClose();
  };

  const handleClickAddMethod = () => {
    addMethodAndBilling();
    onClose();
  };

  return (
    <div css={modalContainer} onClick={onClose}>
      <div onClick={(e) => e.stopPropagation()} css={modalWrap}>
        <div css={title}>결제 방식 선택</div>
        {cardList.map((method) => (
          <div key={method.id} css={cardWrap} onClick={() => handleSelectMethod(method.id!)}>
            {method.cardIssuerCode ? cardCodeToLabel[method.cardIssuerCode] : '기타'} /{' '}
            {method.cardOwnerType || '기타'} /{' '}
            {method.cardNumber ? method.cardNumber.slice(-4) : '****'}
          </div>
        ))}
        <div css={cardWrap} onClick={handleClickAddMethod}>
          + 다른 카드 추가하고 결제하기
        </div>
      </div>
    </div>
  );
}

export default PaymentMethodModal;

const modalContainer = css`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;

  align-items: center;
`;

const modalWrap = css`
  width: min(400px, 90vw);
  max-height: min(800px, 90vh);
  background-color: white;
  border-radius: 10px;
  padding: 24px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
`;

const title = css`
  font-size: 24px;
  font-weight: bold;
`;

const cardWrap = css`
  width: 100%;
  height: 80px;
  min-height: 80px;
  border-radius: 10px;
  border: 1px solid #ededed;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #ededed;
  }
`;
