import mixpanel from 'mixpanel-browser';
import { isDev } from '@/env';
import { throttleFn } from './utils';

export const PageViewEventName = {
  view_channel_createdgames: 'view_channel_createdgames',
  view_channel_feed: 'view_channel_feed',
  view_dashboard: 'view_dashboard',
  view_gamesetting: 'view_gamesetting',
  view_releasemanage: 'view_releasemanage',
  view_communitymanage: 'view_communitymanage',
  view_landing: 'view_landing',
  view_community: 'view_community',
  view_channel_setting: 'view_channel_setting',
  view_createnewgame: 'view_createnewgame',
  view_editgame_edittheme: 'view_editgame_edittheme',
  view_editgame_editmission: 'view_editgame_editmission',
  view_editgame_editmission_quest: 'view_editgame_editmission_quest',
  view_editgame_edititem: 'view_editgame_edititem',
  view_editgame_editchatbot: 'view_editgame_editchatbot',
  view_editgame_filestorage: 'view_editgame_filestorage',
  view_agreetermpage: 'view_agreetermpage',
  view_verifycontact: 'view_verifycontact',
  view_welcomestudiopage: 'view_welcomestudiopage',
  view_onboardingpage: 'view_onboardingpage',
  view_newchannelpage: 'view_newchannelpage'
} as const;
export type PageViewEventName = typeof PageViewEventName[keyof typeof PageViewEventName];

export const ClickEventName = {
  click_editgame_theme_select_lightordark: 'click_editgame_theme_select_lightordark',
  click_editgame_theme_delete_horizontalimage: 'click_editgame_theme_delete_horizontalimage',
  click_editgame_theme_delete_verticallimage: 'click_editgame_theme_delete_verticallimage',
  click_editgame_theme_input_imagesearch: 'click_editgame_theme_input_imagesearch',
  click_editgame_theme_backgroundimage_gamebackgroundimage_input_searchimage:
    'click_editgame_theme_backgroundimage_gamebackgroundimage_input_searchimage',
  click_editgame_theme_backgroundimage_missionbackgroundimage_input_searchimage:
    'click_editgame_theme_backgroundimage_missionbackgroundimage_input_searchimage',
  click_editgame_mission_landingpopup_close: 'click_editgame_mission_landingpopup_close',
  click_editgame_mission_landingpopup_createmission:
    'click_editgame_mission_landingpopup_createmission',
  click_editgame_mission_new_mission: 'click_editgame_mission_new_mission',
  click_editgame_mission_quest_webviewbuilder: 'click_editgame_editmission_quest_webviewbuilder',
  click_editgame_mission_quest_webviewbuilder_close:
    'click_editgame_mission_quest_webviewbuilder_close',
  click_editgame_mission_quest_webviewbuilder_template:
    'click_editgame_mission_quest_webviewbuilder_template',
  click_editgame_mission_quest_webviewbuilder_template_exit:
    'click_editgame_mission_quest_webviewbuilder_template_exit',
  click_editgame_mission_quest_webviewbuilder_notapply:
    'click_editgame_mission_quest_webviewbuilder_notapply',
  click_editgame_mission_item_close: 'click_editgame_mission_item_close',
  click_editgmae_mission_item_createitem: 'click_editgmae_mission_item_createitem',
  click_editgame_mission_item_newitem: 'click_editgame_mission_item_newitem',
  click_editgame_mission_chatbot_close: 'click_editgame_mission_chatbot_close',
  click_editgame_mission_chatbot_createchatbot: 'click_editgame_mission_chatbot_createchatbot',
  click_editgame_mission_chatbot_newchatbot: 'click_editgame_mission_chatbot_newchatbot',
  click_editgame_mission_file_newfolder: 'click_editgame_mission_file_newfolder',
  click_landing_button_startnow: 'click_landing_button_startnow',
  click_landing_button_startfree: 'click_landing_button_startfree',
  click_landing_button_makegame: 'click_landing_button_makegame',
  click_landing_button_exploregame: 'click_landing_button_exploregame',
  click_landing_button_challangecreator: 'click_landing_button_challangecreator',
  click_landing_menu_contest: 'click_landing_menu_contest',
  click_landing_menu_community: 'click_landing_menu_community',
  click_landing_menu_helpcenter: 'click_landing_menu_helpcenter',
  click_landing_footer_updates: 'click_landing_footer_updates',
  click_landing_footer_community: 'click_landing_footer_community',
  click_landing_footer_helpcenter: 'click_landing_footer_helpcenter',
  click_channel_input_feed: 'click_channel_input_feed',
  click_channel_input_community: 'click_channel_input_community',
  click_channel_button_creategame: 'click_channel_button_creategame',
  click_channel_button_settings: 'click_channel_button_settings',
  click_newgame_select_notemplate: 'click_newgame_select_notemplate',
  click_newgame_select_template: 'click_newgame_select_template',
  click_newgame_button_templatedetail: 'click_newgame_button_templatedetail',
  click_newgame_button_relativegame: 'click_newgame_button_relativegame',
  click_newgame_button_selecttemplate: 'click_newgame_button_selecttemplate',
  click_newgame_button_creategame: 'click_newgame_button_creategame',
  click_dashboard_input_daterange: 'click_dashboard_input_daterange',
  click_dashboard_button_download: 'click_dashboard_button_download',
  click_gamesetting_basicinformation_button_gametype:
    'click_gamesetting_basicinformation_button_gametype',
  click_gamesetting_basicinformation_button_gamekit:
    'click_gamesetting_basicinformation_button_gamekit',
  click_gamesetting_basicinformation_button_genre:
    'click_gamesetting_basicinformation_button_genre',
  click_gamesetting_basicinformation_button_playtime:
    'click_gamesetting_basicinformation_button_playtime',
  click_gamesetting_basicinformation_button_difficulty:
    'click_gamesetting_basicinformation_button_difficulty',
  click_gamesetting_unlockcode_button_makeunlockcode:
    'click_gamesetting_unlockcode_button_makeunlockcode',
  click_gamesetting_deleteplayhistory_button_delete:
    'click_gamesetting_deleteplayhistory_button_delete',
  click_releasemanage_select_openornot: 'click_releasemanage_select_openornot',
  click_releasemanage_salesetting_select_price: 'click_releasemanage_salesetting_select_price',
  click_agreetermpage_agree: 'click_agreetermpage_agree',
  click_verifycontactpage_button_check: 'click_verifycontactpage_button_check',
  click_verifycontactpage_button_checkstatus: 'click_verifycontactpage_button_checkstatus',
  click_verifycontactpage_button_registercreatercomplete:
    'click_verifycontactpage_button_registercreatercomplete',
  click_onboardingpage_button_personal: 'click_onboardingpage_button_personal',
  click_onboardingpage_button_biz: 'click_onboardingpage_button_biz',
  click_onboardingpage_button_next: 'click_onboardingpage_button_next',
  click_newchannelpage_button_start: 'click_newchannelpage_button_start',
  click_newchannelpage_button_gotochannel: 'click_newchannelpage_button_gotochannel'
} as const;
export type ClickEventName = typeof ClickEventName[keyof typeof ClickEventName];

export const UploadEventName = {
  upload_editgame_theme_mainimage_horizontal: 'upload_editgame_theme_mainimage_horizontal',
  upload_editgame_theme_mainimage_vertical: 'upload_editgame_theme_mainimage_vertical',
  upload_editgame_theme_backgroundimage_gamebackgroundimage:
    'upload_editgame_theme_backgroundimage_gamebackgroundimage',
  upload_editgame_theme_backgroundimage_missionbackgroundimage:
    'upload_editgame_theme_backgroundimage_missionbackgroundimage',
  upload_editgame_theme_mainbgm: 'upload_editgame_theme_mainbgm',
  upload_editgame_mission_file: 'upload_editgame_mission_file',
  upload_newgame_mainimage: 'upload_newgame_mainimage',
  upload_gamesetting_gameintroduction_previewimage:
    'upload_gamesetting_gameintroduction_previewimage'
} as const;
export type UploadEventName = typeof UploadEventName[keyof typeof UploadEventName];

export const InputEventName = {
  input_editgame_mission_missiondetail: 'input_editgame_mission_missiondetail',
  input_channel_searchgames: 'input_channel_searchgames',
  input_channel_input_feed: 'input_channel_input_feed',
  input_channel_input_community: 'input_channel_input_community',
  input_newgame_name: 'input_newgame_name',
  input_newgame_imagesearch: 'input_newgame_imagesearch',
  input_newgame_description: 'input_newgame_description',
  input_gamesetting_basicinformation_story: 'input_gamesetting_basicinformation_story',
  input_gamesetting_basicinformation_tag: 'input_gamesetting_basicinformation_tag',
  input_gamesetting_gameintroduction_oneline: 'input_gamesetting_gameintroduction_oneline',
  input_gamesetting_gameintroduction_concept: 'input_gamesetting_gameintroduction_concept',
  input_gamesetting_gameintroduction_youtubeurl: 'input_gamesetting_gameintroduction_youtubeurl',
  input_gamesetting_gamedetail_announcement: 'input_gamesetting_gamedetail_announcement',
  input_gamesetting_gamedetail_recommandplayernumber:
    'input_gamesetting_gamedetail_recommandplayernumber',
  input_gamesetting_gamedetail_availableplaytime: 'input_gamesetting_gamedetail_availableplaytime',
  input_gamesetting_gamenotice_etc: 'input_gamesetting_gamenotice_etc',
  input_gamesetting_deleteplayhistory_writegamename:
    'input_gamesetting_deleteplayhistory_writegamename',
  input_releasemanage_ready_readyannouncement: 'input_releasemanage_ready_readyannouncement',
  input_releasemanage_salesetting_payprice: 'input_releasemanage_salesetting_payprice',
  input_releasemanage_salesetting_discountprice: 'input_releasemanage_salesetting_discountprice',
  input_verifycontactpage_phonenumber: 'input_verifycontactpage_phonenumber',
  input_verifycontactpage_checknumber: 'input_verifycontactpage_checknumber',
  input_verifycontactpage_email: 'input_verifycontactpage_email',
  input_newchannelpage_channelname: 'input_newchannelpage_channelname',
  input_newchannelpage_channelsummery: 'input_newchannelpage_channelsummery'
} as const;
export type InputEventName = typeof InputEventName[keyof typeof InputEventName];

export const SaveEventName = {
  save_gamesetting: 'save_gamesetting',
  save_releasemanage: 'save_releasemanage',
  save_editgame_theme: 'save_editgame_theme',
  save_editgame_mission: 'save_editgame_mission',
  save_editgame_mission_quest: 'save_editgame_mission_quest',
  save_editgame_mission_new_quest: 'save_editgame_mission_new_quest',
  save_editgame_mission_quest_webviewbuilder: 'save_editgame_mission_quest_webviewbuilder'
} as const;

export type SaveEventName = typeof SaveEventName[keyof typeof SaveEventName];

export const GAEventName = {
  page_view: 'page_view'
} as const;
export type GAEventName = typeof GAEventName[keyof typeof GAEventName];

export type TrackEventName =
  | PageViewEventName
  | GAEventName
  | ClickEventName
  | UploadEventName
  | InputEventName
  | SaveEventName;

const TrackEventNameToGAPageView: Record<PageViewEventName, { title: string }> = {
  view_landing: {
    title: '랜딩페이지'
  },
  view_channel_createdgames: {
    title: '만든 게임 목록'
  },
  view_channel_feed: {
    title: '피드'
  },
  view_community: {
    title: '커뮤니티'
  },
  view_channel_setting: {
    title: '채널설정'
  },
  view_createnewgame: {
    title: '새 게임 만들기'
  },
  view_dashboard: {
    title: '대시보드'
  },
  view_gamesetting: {
    title: '게임설정'
  },
  view_releasemanage: {
    title: '출시관리'
  },
  view_communitymanage: {
    title: '커뮤니티 관리'
  },
  view_editgame_edittheme: {
    title: '테마 편집'
  },
  view_editgame_editmission: {
    title: '미션 편집'
  },
  view_editgame_editmission_quest: {
    title: '미션 편집 쿼스트'
  },
  view_editgame_edititem: {
    title: '아이템 편집'
  },
  view_editgame_editchatbot: {
    title: '챗봇 편집'
  },
  view_editgame_filestorage: {
    title: '파일 보관함'
  },
  view_agreetermpage: {
    title: '이용약관'
  },
  view_verifycontact: {
    title: '연락처 인증'
  },
  view_welcomestudiopage: {
    title: '환영'
  },
  view_onboardingpage: {
    title: '온보딩'
  },
  view_newchannelpage: {
    title: '채널 생성'
  }
};

function initialize({ userId }: { userId?: string }) {
  if (isDev) {
    console.log(
      `개발서버에서 userId : ${userId || '비로그인'} (으)로 트래킹이 initialize 되었습니다.`
    );
    return;
  }
  try {
    window.gtag('js', new Date());
    window.gtag('config', 'G-VBEFHCW62Z', {
      send_page_view: false,
      user_id: userId
    });

    mixpanel.init('72ab7113942c2efc3a73911379f8f2e0', { debug: isDev });
    if (userId) mixpanel.identify(userId);
  } catch (e) {
    console.error(e);
  }
}

function sendGAEvent(trackEventName: TrackEventName, params?: object) {
  try {
    window.gtag('event', trackEventName, params);
  } catch (e) {
    console.error(e);
  }
}

function sendMixpanelEvent(trackEventName: TrackEventName, params?: object) {
  try {
    mixpanel.track(trackEventName, params);
  } catch (e) {
    console.error(e);
  }
}

function onPageView({ pageViewEventName }: { pageViewEventName: PageViewEventName }) {
  if (isDev) {
    console.log(
      `개발서버에서 pageViewEventName : ${pageViewEventName} (으)로 onPageView가 호출 되었습니다.`
    );
    return;
  }
  try {
    sendGAEvent(GAEventName.page_view, {
      page_title: TrackEventNameToGAPageView[pageViewEventName].title,
      page_location: pageViewEventName
    });
    sendGAEvent(pageViewEventName);
    sendMixpanelEvent(pageViewEventName);
  } catch (e) {
    console.error(e);
  }
}

function onClick({ clickEventName, params }: { clickEventName: ClickEventName; params?: object }) {
  if (isDev) {
    console.log(
      `개발서버에서 clickEventName : ${clickEventName} (으)로 onClick이 호출 되었습니다.`
    );
    return;
  }
  try {
    sendGAEvent(clickEventName, params);
    sendMixpanelEvent(clickEventName, params);
  } catch (e) {
    console.error(e);
  }
}

function onUpload({
  uploadEventName,
  params
}: {
  uploadEventName: UploadEventName;
  params?: object;
}) {
  if (isDev) {
    console.log(
      `개발서버에서 uploadEventName : ${uploadEventName} (으)로 onUpload가 호출 되었습니다.`
    );
    return;
  }
  try {
    sendGAEvent(uploadEventName, params);
    sendMixpanelEvent(uploadEventName, params);
  } catch (e) {
    console.error(e);
  }
}

function onInput({ inputEventName, params }: { inputEventName: InputEventName; params?: object }) {
  if (isDev) {
    console.log(
      `개발서버에서 inputEventName : ${inputEventName} (으)로 onInput가 호출 되었습니다.`
    );
    return;
  }
  try {
    sendGAEvent(inputEventName, params);
    sendMixpanelEvent(inputEventName, params);
  } catch (e) {
    console.error(e);
  }
}

function onSave({ saveEventName, params }: { saveEventName: SaveEventName; params?: object }) {
  if (isDev) {
    console.log(`개발서버에서 saveEventName : ${saveEventName} (으)로 onSave가 호출 되었습니다.`);
    return;
  }
  try {
    sendGAEvent(saveEventName, params);
    sendMixpanelEvent(saveEventName, params);
  } catch (e) {
    console.error(e);
  }
}

const onInputThrottled: typeof onInput = throttleFn(onInput, 5000);

export const track = {
  initialize,
  onPageView,
  onClick,
  onUpload,
  onInput,
  onInputThrottled,
  onSave
};
