import { createInstance, HackleProvider as OriginalHackleProvider } from '@hackler/react-sdk';
import React from 'react';
import { HackleReactSDKClient } from '@hackler/react-sdk/lib/client';
import { hackleKey, isDev } from '@/env';

const HackleContext = React.createContext<{
  hackleClient?: HackleReactSDKClient;
}>({});
export const useHackleContext = () => React.useContext(HackleContext);

interface HackleProviderProps {
  children?: React.ReactNode;
}

export function HackleProvider({ children }: HackleProviderProps) {
  const hackleClient = createInstance(hackleKey, {
    debug: isDev,
    pollingIntervalMillis: 30000,
    auto_track_page_view: true
  });

  return (
    <OriginalHackleProvider hackleClient={hackleClient}>
      <HackleContext.Provider value={{ hackleClient }}>{children}</HackleContext.Provider>
    </OriginalHackleProvider>
  );
}
