import {
  Avatar,
  Button,
  Divider,
  Icon,
  Input,
  ToggleButton,
  Typography,
  color,
  spacing,
  toast
} from '@uniquegood/realworld-studio-design';
import { css } from '@emotion/react';
import { Controller, useForm } from 'react-hook-form';
import React from 'react';
import { useParams } from 'react-router-dom';
import Comment from './Comment';
import {
  ColumnFlex,
  Flex,
  Identity,
  JustifySpaceBetween,
  Margin24,
  MarginBottom10,
  MarginLeft16,
  MarginVertical24
} from '@/styles';
import { useIsAdminSelector, useIsOwner } from '@/hooks';
import { CommentType, RefetchComments } from '../types';
import { coreApi } from '@/api';

interface FormData {
  content: string;
  hasSpoilers: boolean;
}

type ModifyCommentProps = {
  comment: Comment;
  type: CommentType;
  isAdminMode: boolean;
  commentId: string;
  replyId?: string;
  isRootComment?: boolean;
  refetchComments: RefetchComments;
  setIsModifyOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ModifyComment({
  isAdminMode,
  type,
  comment,
  commentId,
  isRootComment,
  refetchComments,
  setIsModifyOpen
}: ModifyCommentProps) {
  const { appId, projectId } = useParams<AppParam>();

  const { handleSubmit, control, reset, formState, setValue } = useForm<FormData>();

  const handleModifySubmit = handleSubmit(async (data) => {
    if (isAdminMode) {
      const response = await coreApi.put<CommonResponseModel<Comment>>(
        `/api/projects/${projectId}/comments/${commentId}`,
        data
      );
    } else {
      const formData = {
        ...data
      };

      const response = await coreApi.patch<CommonResponseModel<Comment>>(
        `/apps/${appId}/projects/${projectId}/comments/${commentId}`,
        formData
      );
    }

    setIsModifyOpen(false);

    await refetchComments();

    toast({ type: 'default', message: '게시 완료!' });

    reset({ hasSpoilers: false });
    // Hack: content 리셋이 안되어서 추가함
    setValue('content', '', { shouldDirty: false });
  });

  return (
    <>
      {isRootComment && <Divider cssStyle={[DividerStyle, Identity]} />}
      <section css={[ColumnFlex, Margin24]}>
        <header css={[Flex, JustifySpaceBetween]}>
          <Avatar
            size="large"
            userName={comment.writer.name}
            source={comment.writer.profileImage}
            userEmail={new Date(comment.createdAt)
              .toLocaleDateString('ko', {
                year: '2-digit',
                month: '2-digit',
                day: '2-digit'
              })
              .substring(0, 10)}
          />
        </header>
        <form onSubmit={handleModifySubmit}>
          <Typography as="article" type="body" cssStyle={[MarginVertical24, ColumnFlex]}>
            {comment.hasSpoilers && spoilerMarkup}
            <Controller
              control={control}
              name="content"
              defaultValue={comment.content}
              rules={{ required: true }}
              render={({ field }) => <Input {...field} defaultValue={field.value} multiline />}
            />

            <figure role="group" css={ImageGroupLayout}>
              {comment.pictures?.map((picture, index) => (
                <img key={picture} src={picture} alt={`${index}번째 사진`} />
              ))}
            </figure>
          </Typography>
          <footer
            css={css`
              display: flex;
              justify-content: space-between;
            `}
          >
            <Button
              type="basic"
              htmlType="button"
              size="large"
              onClick={() => setIsModifyOpen(false)}
            >
              취소
            </Button>
            <div>
              <Controller
                control={control}
                name="hasSpoilers"
                defaultValue={comment.hasSpoilers}
                render={({ field: { value, onChange } }) => (
                  <ToggleButton onChange={onChange} checked={value}>
                    스포일러
                  </ToggleButton>
                )}
              />
              <Button type="primary" htmlType="submit" size="large" cssStyle={MarginLeft16}>
                게시
              </Button>
            </div>
          </footer>
        </form>
      </section>
      {!!comment.children && (
        <div css={ChildCommentLayout}>
          {isAdminMode
            ? comment.children.map((childComment) => (
                <>
                  <Divider cssStyle={ChildCommentDividerStyle} />
                  <Comment
                    isAdminMode
                    key={childComment.id}
                    comment={childComment}
                    type={type}
                    refetchComments={refetchComments}
                  />
                </>
              ))
            : comment.children.map((childComment) => (
                <>
                  <Divider cssStyle={ChildCommentDividerStyle} />
                  <Comment
                    isAdminMode={false}
                    personas={[]}
                    key={childComment.id}
                    comment={childComment}
                    type={type}
                    refetchComments={refetchComments}
                  />
                </>
              ))}
        </div>
      )}
    </>
  );
}

const DividerStyle = css`
  height: 8px;
  background-color: ${color.surface_default_disabled};
`;

const ChildCommentLayout = css`
  margin-left: ${spacing.margin.xlarge2};
`;

const ChildCommentDividerStyle = css`
  background-color: ${color.surface_default_disabled};
  margin-right: ${spacing.margin.xlarge2};
`;

const spoilerMarkup = (
  <Typography
    as="label"
    type="button"
    cssStyle={[
      css`
        display: inline-flex;
        color: ${color.interactive_primary_default};
      `,
      MarginBottom10
    ]}
  >
    <Icon
      icon="check_solid"
      size="20px"
      color={color.interactive_primary_default}
      cssStyle={css`
        margin: 0;
      `}
    />{' '}
    스포일러
  </Typography>
);

const ImageGroupLayout = css`
  width: 100%;
  overflow-x: auto;
  margin-top: ${spacing.margin.small};

  display: flex;
  flex-wrap: no-warp;

  > img {
    height: 200px;

    &:not(:last-of-type) {
      margin-right: ${spacing.margin.small};
    }
  }
`;
