import { useCallback, useState } from 'react';

export default function useValidatableInput(
  initValue: string,
  validator: (value: string) => boolean,
  refiner?: (value: string) => string,
  onUpdate?: (value: string) => void
): [string, boolean, (value: string) => void] {
  const [value, setter] = useState<string>(initValue);
  const [isValid, setIsValid] = useState<boolean>(false);

  const handler = useCallback(
    (newValue: string) => {
      const refinedValue = refiner?.(newValue) ?? newValue;

      setIsValid(validator(refinedValue));
      setter(refinedValue);

      onUpdate?.(refinedValue);
    },
    [refiner, validator, onUpdate]
  );

  return [value, isValid, handler];
}
