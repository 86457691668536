import { css } from '@emotion/react';
import { onMobile } from '@styles/responsive';
import {
  Button,
  color,
  DropZone,
  fileRejectionsType,
  FormGroup,
  Icon,
  IDropZoneProps,
  Input,
  Modal,
  RatioStyle,
  shadow,
  SizeStyle,
  spacing
} from '@uniquegood/realworld-studio-design';
import React, { useCallback, useEffect, useRef, useState, memo } from 'react';
import { useParams } from 'react-router-dom';

import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { reorder, makeFormData, checkFileSize, ModalContent, maxDefaultFileSize } from '@/utils';
import { unsplash } from '@/unsplash';
import { ColumnFlex, MarginBottom10, PositionRelative, TextAlignCenter, Width100 } from '@/styles';
import {
  useDebounce,
  useInput,
  useIntersectionObserver,
  useDidUpdateEffect,
  useToggle,
  useModalState
} from '@/hooks';
import { coreApi } from '@/api';

export interface UploaderProps extends Pick<IDropZoneProps, 'ratio' | 'size' | 'accepts'> {
  /**
   * unsplash 검색 인풋 렌더링 여부입니다.
   * @default false
   */
  hasSearch?: boolean;
  /**
   * 세로 레이아웃 여부입니다.
   * @default false
   */
  isVerticalLayout?: boolean;
  /**
   * 다중 이미지 업로드 여부입니다.
   * @default false
   */
  isMultiple?: boolean;
  // UserFileResponseModel을 반환합니다.
  getUploadFiles: (files?: UserFileResponseModel[]) => void;
  /**
   * 업로드 로딩 상태를 반환합니다.
   */
  getUploadLoadingStatus?: (isUploaded: boolean) => void;
  /**
   * 이미 업로드 되어 있는 파일을 받아옵니다.
   */
  uploadedFiles?: UserFileResponseModel[];
  // 최대 업로드 가능한 이미지 갯수입니다.
  limit?: number;
  id?: string;
  /**
   * 업로드 용량 제한입니다.
   */
  maxFileSize?: number;
  useFor?: string;
}

interface UploadImagePreviewMarkupProps extends Required<Pick<IDropZoneProps, 'ratio' | 'size'>> {
  uploadFile: UserFileResponseModel;
  onDeletePhoto: (selectFileId: string) => void;
  isMultiple?: boolean;
}

const MULTIPLE = {
  WIDTH: {
    small: '100px',
    medium: '138px'
  },
  HEIGHT: {
    small: '100px',
    medium: '180px'
  }
};

const UploadImagePreviewMarkup = ({
  uploadFile,
  onDeletePhoto,
  isMultiple,
  ratio,
  size
}: UploadImagePreviewMarkupProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const MultipleImageSizeStyle = css`
    min-width: ${MULTIPLE.WIDTH[size]};
    max-width: 225px;
    height: ${MULTIPLE.HEIGHT[size]};
  `;

  const SizeStyle = (() => {
    if (isMultiple && size === 'small') return [MultipleImageSizeStyle, RatioStyle[ratio]];
    if (isMultiple) return MultipleImageSizeStyle;

    return RatioStyle[ratio];
  })();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const toggleDialog = (event?: unknown) => {
    setIsDialogOpen((isDialogOpen) => !isDialogOpen);
  };

  const handlers = {
    onClick: toggleDialog,
    onKeyDown: toggleDialog
  };

  return (
    <>
      <img
        css={[BaseImageStyle, SizeStyle]}
        alt={uploadFile.id}
        src={uploadFile.url}
        {...handlers}
      />
      {isDialogOpen && (
        <div role="dialog" data-studio-overlay="true" css={imagePreviewDialog} {...handlers}>
          <div>
            <img
              alt={uploadFile.id}
              src={uploadFile.url}
              tabIndex={-1}
              onKeyDown={stopPropagation}
              onClick={stopPropagation}
            />
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div css={DeleteButtonStyle} onKeyDown={stopPropagation} onClick={stopPropagation}>
              <Button
                cssStyle={PosIconButtonStyle}
                icon="times_regular"
                type="plainWhite"
                size="medium"
                {...handlers}
              />
            </div>
          </div>
        </div>
      )}
      <div css={[SizeStyle, DeleteButtonStyle]}>
        <Button
          cssStyle={PosIconButtonStyle}
          onClick={() => onDeletePhoto(uploadFile.id)}
          icon="times_regular"
          type="plainWhite"
          size="medium"
        />
      </div>
    </>
  );
};

function stopPropagation(event: React.KeyboardEvent | React.MouseEvent) {
  event.stopPropagation();
}

const Uploader = ({
  hasSearch = false,
  isVerticalLayout = false,
  isMultiple = false,
  getUploadLoadingStatus,
  getUploadFiles,
  uploadedFiles,
  limit,
  ratio = 'fullWidth',
  size = 'medium',
  id,
  accepts,
  maxFileSize,
  useFor
}: UploaderProps) => {
  const { appId } = useParams<AppParam>();

  const [shouldMoreUnsplashFetch, setShouldMoreUnsplashFetch] = useState(false);

  const [uploadFiles, setUploadFiles] = useState(uploadedFiles);
  const [acceptedFilesLength, setAcceptedFilesLength] = useState(0);
  const [rejectedFiles, setRejectedFiles] = useState<fileRejectionsType>([]);
  const { modal, openModal, closeModal } = useModalState();

  const { value: isLoading, setTrue: startLoading, setFalse: completeLoading } = useToggle(false);
  const { value: isUploaded, setTrue: completeUploaded, setFalse: failUploaded } = useToggle(false); // upload 완료 여부

  const [alertContent, setAlertContent] = useState<ModalContent>();
  const isAlertOpen = alertContent !== undefined;

  const [unsplashSearchQuery, setUnsplashSearchQuery] = useInput('');
  const unsplashPageIndex = useRef(1);
  const unsplashTotalPage = useRef(0);
  const {
    value: isImageLoading,
    setTrue: starImageLoading,
    setFalse: completeImageLoading
  } = useToggle(false);

  const rootRef = useRef<HTMLDivElement>(null);
  const targetRef = useRef<HTMLDivElement>(null);
  const [unsplashSearchResult, setUnsplashSearchResult] = useState<UnsplashResultsEntity[]>([]);

  const debounceSearchPhotoTerm = useDebounce(unsplashSearchQuery, 500);

  function closeAlert() {
    setAlertContent(undefined);
  }

  function openImageErrorAlert() {
    setAlertContent({
      title: '업로드한 이미지를 확인해 주세요.',
      message: (
        <div css={openImageErrorAlertStyle}>
          이미지는 <strong>JPG, PNG</strong> 형식으로 <strong>최대 4MB까지</strong>만 업로드할 수
          있어요. <br />
          <br />
          <a href="https://imagecompressor.com/ko/" target="blank">
            이미지 최적화 도구
          </a>
          로 이미지 용량을 줄이면 더욱 빠르게 이미지를 불러올 수 있어요!
        </div>
      )
    });
  }

  const onChangeFile = useCallback(
    async (files: File[]) => {
      if (!isMultiple && files.length > 1) {
        setAlertContent({ title: '하나의 이미지만 올릴 수 있어요', message: '다시 시도해주세요' });
        return;
      }

      if (isMultiple && limit && (uploadFiles?.length ?? 0) + files.length > limit) {
        setAlertContent({
          title: `이미지는 ${limit}개까지 올릴 수 있어요`,
          message: '다시 시도해주세요'
        });
        return;
      }

      function fetchUploadImage(file: File) {
        startLoading();
        failUploaded();

        return coreApi.post<UserFileResponseModel>(
          `/apps/${appId}/images/uploadFile`,
          makeFormData({ file }),
          { params: { useFor } }
        );
      }

      // 한개의 파일만 업로드 할 때 허용하지 않은 형식인 경우 빈 배열입니다.
      if (files.length === 0) return;

      const filteredFiles = [...files].filter((file) => !checkFileSize({ file, maxFileSize }));

      if (filteredFiles.length === 0) {
        openImageErrorAlert();
      }

      setAcceptedFilesLength(files.length);

      try {
        const uploadedFiles = await (
          await Promise.all(filteredFiles.map(fetchUploadImage))
        ).map(({ data }) => data);
        setUploadFiles((uploadFiles) => [...(uploadFiles ?? []), ...uploadedFiles]);
        completeLoading();
        if (files.length > 0 && uploadedFiles.length > 0) completeUploaded();
      } catch (error) {
        completeLoading();
        failUploaded();
      }
    },
    [isMultiple, uploadFiles, isLoading, isUploaded]
  );

  useDidUpdateEffect(() => {
    if (uploadedFiles !== uploadFiles) {
      setUploadFiles(uploadedFiles);
    }
  }, [uploadedFiles]);

  useEffect(() => {
    if (getUploadLoadingStatus === undefined) return;

    getUploadLoadingStatus(isLoading);
  }, [isLoading]);

  useDidUpdateEffect(() => {
    getUploadFiles(uploadFiles);
  }, [uploadFiles]);

  useEffect(() => {
    if (rejectedFiles.length) {
      failUploaded();
      openImageErrorAlert();
    }
  }, [rejectedFiles]);

  const onDeletePhoto = (selectFileId: string) => {
    const tempArr = uploadFiles?.filter((file) => file.id !== selectFileId);
    setUploadFiles(tempArr);
    failUploaded();
  };

  const SearchInputStyle = css`
    margin-left: ${isVerticalLayout ? 0 : spacing.common.medium};

    ${onMobile} {
      margin-left: 0px;
    }
  `;

  useEffect(() => {
    if (hasSearch && debounceSearchPhotoTerm) {
      searchImage(debounceSearchPhotoTerm);
    }
  }, [hasSearch && debounceSearchPhotoTerm]);

  const searchImage = useCallback(
    (query: string) => {
      if (!query) return;

      setUnsplashSearchQuery(query);
      unsplashPageIndex.current = 1;
      getUnsplashPhoto({ query, page: 1 }).then((res) => {
        setUnsplashSearchResult(res.results ?? []);
      });
    },
    [unsplashSearchQuery, unsplashPageIndex.current, unsplashSearchResult]
  );

  const getUnsplashPhoto = useCallback(
    ({ query, page }: { query: string; page: number }) => {
      starImageLoading();

      const params = {
        query,
        page
      };

      const data = unsplash.search(params).then((data: Unsplash) => {
        unsplashTotalPage.current = data.total_pages;
        completeImageLoading();

        return data;
      });

      return data;
    },
    [isImageLoading, unsplashTotalPage.current]
  );

  useIntersectionObserver({
    root: rootRef.current,
    target: targetRef.current,
    onIntersect: ([{ isIntersecting }]) => {
      if (
        shouldMoreUnsplashFetch &&
        isIntersecting &&
        !isImageLoading &&
        unsplashPageIndex.current < unsplashTotalPage.current
      ) {
        loadMoreImage();
      }
    },
    threshold: 0.0
  });

  const onChangeIsRenderPhotoList = useCallback(
    (node) => {
      if (node !== null) {
        setShouldMoreUnsplashFetch(true);
      } else {
        setShouldMoreUnsplashFetch(false);
      }
    },
    [shouldMoreUnsplashFetch]
  );

  const loadMoreImage = useCallback(() => {
    if (unsplashSearchResult.length > 0) {
      unsplashPageIndex.current += 1;

      getUnsplashPhoto({ query: unsplashSearchQuery, page: unsplashPageIndex.current }).then(
        (res) => {
          setUnsplashSearchResult((prevState) => [...prevState, ...(res.results || [])]);
        }
      );
    }
  }, [unsplashPageIndex.current, unsplashSearchResult]);

  const searchInputMarkup = (
    <FormGroup
      label="혹은 이미지 검색"
      helpText="Search photos by Unsplash"
      cssStyle={[GrowStyle, SearchInputStyle]}
    >
      <Input
        name="unsplashSearchQuery"
        placeholder="영문 키워드로 검색"
        onChange={setUnsplashSearchQuery}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
          }
        }}
        value={unsplashSearchQuery}
        prefix={<Icon icon="search_solid" size="20px" />}
      />
    </FormGroup>
  );

  const statePhotoListContainerStyle = css`
    margin-left: ${isVerticalLayout ? 0 : spacing.common.medium};

    ${onMobile} {
      margin-left: 0px;
    }
  `;

  async function onClickUploadUrl(uploadPhoto: UnsplashResultsEntity) {
    startLoading();
    failUploaded();

    try {
      const { url } = await unsplash.getUrl(uploadPhoto);

      const { data: uploadedFile } = await coreApi.post<UserFileResponseModel>(
        `/apps/${appId}/images/uploadUrl`,
        makeFormData({ url }),
        { params: { useFor } }
      );

      if (!isMultiple) {
        setUploadFiles([uploadedFile]);
      } else {
        setUploadFiles((uploadFiles) => [...(uploadFiles ?? []), uploadedFile]);
      }

      completeLoading();
      completeUploaded();
    } catch {
      completeLoading();
      failUploaded();
    }
  }

  const photoListMarkup = (
    <section css={[PhotoListContainerStyle, statePhotoListContainerStyle]} ref={rootRef}>
      {unsplashSearchResult.map((photo) => {
        const statePhotoImageStyle = css`
          ${`background-image: url(${photo.urls.thumb})`};
        `;

        return (
          <div
            key={photo.id}
            css={PhotoCardStyle}
            onClick={() => onClickUploadUrl(photo)}
            onKeyDown={() => onClickUploadUrl(photo)}
            role="button"
            tabIndex={0}
          >
            <div css={[FullContents, PhotoImgStyle, statePhotoImageStyle]} />
          </div>
        );
      })}
      <div ref={targetRef} />
      <div ref={onChangeIsRenderPhotoList} />
    </section>
  );

  const SingleContainerStateStyle = css`
    ${isVerticalLayout && !isMultiple && `flex-direction: column;`};
  `;

  const MultipleDropZoneHeightStyle = css`
    height: ${MULTIPLE.HEIGHT[size]};
    margin-right: ${spacing.common.small};
    width: ${MULTIPLE.WIDTH[size]};

    .DragDrop-label {
      height: ${MULTIPLE.HEIGHT[size]};
      width: ${MULTIPLE.WIDTH[size]};
    }
  `;

  function onDragEnd({ destination, source }: DropResult) {
    if (!destination || !uploadFiles) return;

    const reordered = reorder(uploadFiles, source.index, destination.index);

    setUploadFiles(reordered);
  }

  const imagePreviewMarkups = uploadFiles?.map((uploadFile, index) =>
    isMultiple ? (
      <Draggable
        key={uploadFile.id ?? uploadFile.url}
        draggableId={uploadFile.id ?? uploadFile.url}
        index={index}
      >
        {(provided, snapshot) => (
          <li
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={snapshot.isDragging ? 'dragging' : 'no'}
            css={[
              PositionRelative,
              SizeStyle[size],
              imagePreviewWrapStyle,
              size === 'small' && SmallSizeLayout
            ]}
          >
            <UploadImagePreviewMarkup
              uploadFile={uploadFile}
              onDeletePhoto={onDeletePhoto}
              isMultiple={isMultiple}
              ratio={ratio}
              size={size}
            />
          </li>
        )}
      </Draggable>
    ) : (
      <div
        css={[
          PositionRelative,
          SizeStyle[size],
          size === 'small' && SmallSizeLayout,
          imagePreviewWrapStyle
        ]}
        key={uploadFile.id ?? uploadFile.url}
      >
        <UploadImagePreviewMarkup
          uploadFile={uploadFile}
          onDeletePhoto={onDeletePhoto}
          isMultiple={isMultiple}
          ratio={ratio}
          size={size}
        />
      </div>
    )
  );

  const isEmptyState = !isUploaded && (uploadFiles === undefined || uploadFiles.length === 0);

  const uploaderLayout = isMultiple ? (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId={id ? `multiple-uploader-${id}` : 'multiple-uploader'}
        direction="horizontal"
      >
        {(provided) => (
          <ul
            ref={provided.innerRef}
            {...provided.droppableProps}
            css={MultipleUploaderContainerStyle}
            id={id}
          >
            {(isLoading && <UploadLoadingSpinner isMultiple />) ||
              ((limit === undefined || uploadFiles === undefined || limit > uploadFiles.length) && (
                <DropZone
                  cssStyle={MultipleDropZoneHeightStyle}
                  ratio={ratio}
                  size={size}
                  onChangeFile={onChangeFile}
                  multiple
                  notAcceptFiles={setRejectedFiles}
                  accepts={accepts}
                />
              ))}

            {imagePreviewMarkups}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  ) : (
    <>
      {!isUploaded && isLoading ? (
        <UploadLoadingSpinner ratio={ratio} size={size} isMultiple={false} />
      ) : (
        <div css={[BaseSingleUploaderContainer, SingleContainerStateStyle]} id={id}>
          {isEmptyState && (
            <DropZone
              ratio={ratio}
              size={size}
              onChangeFile={onChangeFile}
              notAcceptFiles={setRejectedFiles}
              accepts={accepts}
            />
          )}
          {(isUploaded || (uploadFiles && uploadFiles.length > 0)) && imagePreviewMarkups}

          {isEmptyState && !isLoading && (
            <div css={[ColumnFlex, Width100, searchPhotoInputPosStyle]}>
              {hasSearch && searchInputMarkup}
              {unsplashSearchResult.length > 0 && photoListMarkup}
            </div>
          )}
        </div>
      )}
    </>
  );

  return (
    <>
      <section>
        <Modal {...modal} />
        {uploaderLayout}
      </section>
      <Modal
        open={isAlertOpen}
        title={alertContent?.title}
        onClose={closeAlert}
        primaryAction={{ content: '확인', type: 'primary', onAction: closeAlert }}
        cssStyle={TextAlignCenter}
      >
        {alertContent?.message}
      </Modal>
    </>
  );
};

export default memo(Uploader);

interface UploadLoadingSpinnerProps extends Pick<IDropZoneProps, 'ratio' | 'size'> {
  isMultiple: boolean;
}

export function UploadLoadingSpinner({
  ratio = 'fullWidth',
  size = 'medium',
  isMultiple
}: UploadLoadingSpinnerProps) {
  const MultipleDropZoneLoadingSize = css`
    min-width: ${MULTIPLE.WIDTH[size]};
  `;

  return (
    <div
      css={[
        RatioStyle[ratio],
        SizeStyle[size],
        CenterLayout,
        isMultiple && MultipleDropZoneLoadingSize
      ]}
    >
      <Icon icon="spinner_solid" size="48px" spinning />
    </div>
  );
}

const imagePreviewWrapStyle = css`
  width: fit-content;
  margin-right: ${spacing.common.small};

  &:last-of-type {
    margin-right: 0px;
  }
`;

const CenterLayout = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FullContents = css`
  width: 100%;
  height: 100%;
`;

const GrowStyle = css`
  flex-grow: 1;

  ${onMobile} {
    flex-grow: initial;
  }
`;

const BaseSingleUploaderContainer = css`
  display: flex;
  flex-direction: row;

  ${onMobile} {
    flex-direction: column;
  }
`;

const MultipleUploaderContainerStyle = css`
  display: flex;
  flex-wrap: nowrap;
  padding: ${spacing.common.small};
  border: 1px solid ${color.surface_default_subdued};
  overflow-x: auto;
  background-color: ${color.surface_default_subdued};
  border-radius: ${spacing.common.xsmall};
`;

const BaseImageStyle = css`
  display: block;
  flex-shrink: 0;
  object-fit: cover;
  border-radius: ${spacing.common.small};
  cursor: pointer;
  ${shadow.toast};
`;

const DeleteButtonStyle = css`
  position: absolute;
  top: 0;
  height: 42px;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
  display: flex;
  align-items: baseline;
  justify-content: end;

  border-top-right-radius: ${spacing.common.small};
  border-top-left-radius: ${spacing.common.small};
`;

const PosIconButtonStyle = css`
  margin-right: ${spacing.common.xsmall};
  margin-top: ${spacing.common.xsmall};
`;

const PhotoListContainerStyle = css`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${spacing.common.small};
  padding: ${spacing.common.small};
  border: 1px solid ${color.border_default_subdued};
  border-radius: ${spacing.common.xsmall};

  height: 408px;
  overflow-y: auto;
`;

const PhotoCardStyle = css`
  width: 33.3333%;
  height: 33.3333%;
  padding: ${spacing.common.small};
  cursor: pointer;
`;

const PhotoImgStyle = css`
  background-size: cover;
  background-position: center;
`;

const searchPhotoInputPosStyle = css`
  ${onMobile} {
    margin-top: ${spacing.common.medium};
  }
`;

const SmallSizeLayout = css`
  height: 100%;
`;

const imagePreviewDialog = css`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;

  /* 디자인시스템 모달 백드롭 스타일에서 스타일 시트를 가져왔습니다. */
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  animation: fadeIn 200ms 1 forwards;
  opacity: 1;
  will-change: opacity;
  cursor: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.9095 12.0003L19.6786 6.2311L20.8684 5.04138C21.0439 4.86587 21.0439 4.58067 20.8684 4.40517L19.5954 3.13219C19.4199 2.95669 19.1347 2.95669 18.9592 3.13219L12.0003 10.0911L5.04138 3.13163C4.86587 2.95612 4.58067 2.95612 4.40517 3.13163L3.13163 4.40461C2.95612 4.58011 2.95612 4.86531 3.13163 5.04081L10.0911 12.0003L3.13163 18.9592C2.95612 19.1347 2.95612 19.4199 3.13163 19.5954L4.40461 20.8684C4.58011 21.0439 4.86531 21.0439 5.04081 20.8684L12.0003 13.9095L17.7695 19.6786L18.9592 20.8684C19.1347 21.0439 19.4199 21.0439 19.5954 20.8684L20.8684 19.5954C21.0439 19.4199 21.0439 19.1347 20.8684 18.9592L13.9095 12.0003Z' fill='white' /%3E%3C/svg%3E"),
    auto;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  > div {
    position: relative;
    cursor: default;
    user-select: none;

    img {
      max-width: 80vh;
      max-height: 90vh;
      border-radius: ${spacing.common.small};
    }
  }
`;

const openImageErrorAlertStyle = css`
  font-size: 14;
  font-weight: 400;
  line-height: 20px;
  text-align: left;

  a {
    color: inherit;
    text-decoration: underline;
    text-underline-offset: 3px;
  }
`;
