import { ButtonGroup, Button } from '@uniquegood/realworld-studio-design';
import { DefaultCursor } from '@/styles';

interface SelectLanguageProps {
  selectedItem: Language;
  setSelectedItem(action: unknown): void;
}

export default function SelectLanguage({ selectedItem, setSelectedItem }: SelectLanguageProps) {
  function applySelectedStyle(language: Language) {
    const isSelected = selectedItem === language;
    return isSelected ? DefaultCursor : [];
  }

  return (
    <ButtonGroup segmented>
      <Button
        css={applySelectedStyle('Korean')}
        onClick={() => setSelectedItem('Korean')}
        type={selectedItem === 'Korean' ? 'primary' : 'outlineBasic'}
        pressed={selectedItem === 'Korean'}
      >
        한국어
      </Button>
      <Button
        css={applySelectedStyle('English')}
        onClick={() => setSelectedItem('English')}
        type={selectedItem === 'English' ? 'primary' : 'outlineBasic'}
        pressed={selectedItem === 'English'}
      >
        영어
      </Button>
      <Button
        css={applySelectedStyle('Japanese')}
        onClick={() => setSelectedItem('Japanese')}
        type={selectedItem === 'Japanese' ? 'primary' : 'outlineBasic'}
        pressed={selectedItem === 'Japanese'}
      >
        일본어
      </Button>
      <Button
        css={applySelectedStyle('Chinese')}
        onClick={() => setSelectedItem('Chinese')}
        type={selectedItem === 'Chinese' ? 'primary' : 'outlineBasic'}
        pressed={selectedItem === 'Chinese'}
      >
        중국어
      </Button>
    </ButtonGroup>
  );
}
