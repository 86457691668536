import { spacing, Typography } from '@uniquegood/realworld-studio-design';
import { css } from '@emotion/react';
import React from 'react';

import { MarginBottom16, MarginBottom8 } from '@/styles';
import { onMobile } from '@/styles/responsive';
import useIsMobile from '@/hooks/useIsMobile';

export interface OnBoardingStep {
  title: React.ReactNode;
  description?: React.ReactNode;
  imageUrl?: string;
}

interface OnBoardingSteps {
  steps: OnBoardingStep[];
}
const OnBoardingModalContent = ({ steps }: OnBoardingSteps) => {
  const isMobile = useIsMobile();

  return (
    <div css={ModalWrap}>
      {steps.map((step) => (
        <figure key={step.imageUrl}>
          <Typography
            as="h2"
            type={isMobile ? 'heading' : 'displayLarge'}
            cssStyle={isMobile ? MarginBottom8 : MarginBottom16}
          >
            {step.title}
          </Typography>
          {step.description && (
            <Typography
              as="p"
              type={isMobile ? 'body' : 'heading'}
              textColor="subdued"
              cssStyle={isMobile ? MarginBottom8 : MarginBottom16}
            >
              {step.description}
            </Typography>
          )}
          {step.imageUrl && <img src={step.imageUrl} alt="온보딩 이미지" width="100%" />}
        </figure>
      ))}
    </div>
  );
};

export default OnBoardingModalContent;

const ModalWrap = css`
  text-align: center;
  word-break: keep-all;
  padding: ${spacing.common.large};

  ${onMobile} {
    padding: 0px;
  }

  figure {
    margin: 0px;
  }

  & > figure:not(:last-of-type) {
    margin-bottom: 64px;

    ${onMobile} {
      margin-bottom: 40px;
    }
  }
`;
