import { css } from '@emotion/react';

import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import { color, Navigation as OriginalNavigation } from '@uniquegood/realworld-studio-design';
import { useHistory } from 'react-router-dom';
import SectionWrapperV2 from '@components/NavigationTopBar/components/SectionWrapperV2';
import useParamsWithMatchPath from '@/hooks/useParamsWithMatchPath';
import useRequest from '@/hooks/useRequest';

import { onMobile } from '@/styles/responsive';
import Navigation, { NAVIGATION_DEFAULT_WIDTH, NAVIGATION_GAME_EDIT_WIDTH } from '../Navigation';
import TopBar from '../TopBar';
import SectionWrapper from './components/SectionWrapper';
import useNavigationType from './hooks/useNavigationType';

import { operation, gameEdit, operationV2 } from './pages';
import { TOP_BAR_HEIGHT } from '../TopBar/Topbar';
import BeltBanner from '../BeltBanner';
import { useIsAdminSelector, useRequestImmutable } from '@/hooks';
import { ColumnFlex, Identity } from '@/styles';
import { MissionContainerMarginLeft } from '@/pages/missions/Missions';
import { ChatbotContainerMarginLeft } from '@/pages/chatbots/ChatBots';

interface NavigationTopBarProps {
  children: ReactNode;
}

const { Header } = OriginalNavigation;

export default function NavigationTopBar({ children }: NavigationTopBarProps) {
  const [isShowNavigation, setShowNavigation] = useState(false);
  const history = useHistory();

  const { data: isAdmin } = useIsAdminSelector();
  const { data: apps } = useRequestImmutable<RealWorldApp[]>('/apps');

  const ref = useRef<HTMLElement>(null);

  const { appId, projectId } = useParamsWithMatchPath();

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [history.location.pathname, history.location.search]);

  const toggleShowNavigation = useCallback(
    () => setShowNavigation((isShowNavigation) => !isShowNavigation),
    []
  );

  const { parentPath, navigation, topBarType, pageKey, isV2 } = useNavigationType();
  const pageName = isV2 ? getPageNameV2(pageKey) : getPageName(pageKey);

  const { data: basicInformation } = useRequest<CommonResponseModel<BasicInformation>>(
    (isV2 ? checkIncludeProjectsPathV2(parentPath) : checkIncludeProjectsPath(parentPath))
      ? `${parentPath.replace('projectsV2', 'projects')}/basic`
      : '',
    {},
    {
      headers: {
        'x-rwd-api-version': '1.1'
      }
    }
  );

  const isMissionPage = history.location.pathname.startsWith(
    `/apps/${appId}/projects/${projectId}/scenarios`
  );
  const isChatbotPage = history.location.pathname.startsWith(
    `/apps/${appId}/projects/${projectId}/chatbots`
  );

  const isCreateAppPage = history.location.pathname.startsWith('/createApp');

  const haveActionHandlerPage = pageKey === 'scenarios' || pageKey === 'chatbots';

  const RightAreaLayout =
    navigation.type !== undefined ? rightAreaLayout[navigation.type] : rightAreaLayout.undefined;

  const isAdminVisitThirdPartyChannel =
    isAdmin && appId && apps?.findIndex((app) => app.id === appId) === -1;

  return (
    <>
      {navigation.type !== undefined && (
        <Navigation
          type={navigation.type}
          isShowMobileNavigation={isShowNavigation}
          onCloseNavigation={toggleShowNavigation}
          cssStyle={NavigationPosition}
        >
          <Header
            type={navigation.type}
            onClick={() => history.push(`${parentPath}/dashboard`)}
            // TODO: source, title props 옵셔널로 바꾸고 undefined 일 때 스켈레톤 보여주기
            source={basicInformation?.data.horizontalImageUrl ?? ''}
            title={basicInformation?.data.name ?? ''}
            url={appId ? `/apps/${appId}` : '/'}
            custom
          />
          {isV2 ? (
            <SectionWrapperV2 {...navigation} onClick={() => setShowNavigation(false)} />
          ) : (
            <SectionWrapper {...navigation} onClick={() => setShowNavigation(false)} />
          )}
        </Navigation>
      )}
      <TopBar
        cssStyle={[RightAreaLayout, TopBarPosition]}
        type={topBarType}
        title={pageName}
        onClickBurger={toggleShowNavigation}
      />
      {isCreateAppPage && CreateAppBackgroundImage}
      <main
        ref={ref}
        css={[
          RightAreaLayout,
          ColumnFlex,
          ContentContainerStyle,
          isCreateAppPage ? LayoutForCreateAppPage : undefined,
          haveActionHandlerPage ? WhiteBackground : undefined
        ]}
      >
        {isAdminVisitThirdPartyChannel && (
          <BeltBanner
            backgroundColor={color.palette_status_red_tint_04}
            cssStyle={[
              isMissionPage ? MissionContainerMarginLeft : Identity,
              isChatbotPage ? ChatbotContainerMarginLeft : Identity
            ]}
          >
            🚧 관리자 모드로 탐색 중이에요 🚧
          </BeltBanner>
        )}
        {children}
      </main>
    </>
  );
}

const NavigationPosition = css`
  position: fixed;
  top: 0;
`;

const TopBarPosition = css`
  position: fixed;
  z-index: 2;
  top: 0;
`;

function getPageName(pageKey?: string) {
  if (!pageKey) return;

  /* eslint-disable consistent-return */
  if (pageKey in operation) return operation[pageKey].content;

  if (pageKey in gameEdit) return gameEdit[pageKey].content;
  /* eslint-enable consistent-return */

  throw Error('라우트 목록에 벗어난 경로입니다.');
}

function getPageNameV2(pageKey?: string) {
  if (!pageKey) return;

  /* eslint-disable consistent-return */
  if (pageKey in operationV2) return operationV2[pageKey].content;

  throw Error('라우트 목록에 벗어난 경로입니다.');
}

function checkIncludeProjectsPath(path: string) {
  return path.indexOf('projects/') !== -1;
}

function checkIncludeProjectsPathV2(path: string) {
  return path.indexOf('projectsV2/') !== -1;
}

const ContentContainerStyle = css`
  min-height: 100%;
  position: relative;
  margin-top: ${TOP_BAR_HEIGHT};

  ${onMobile} {
    margin-left: 0;
    width: 100%;
  }
`;

const WhiteBackground = css`
  background-color: white;
`;

const LayoutForCreateAppPage = css`
  margin-top: 0;
  height: 100%;
`;

const CreateAppBackgroundImage = (
  <style>
    {`
    body {
      height: calc(100vh);
    }

    #root {
      height: 100%;
    }
  `}
  </style>
);

const resetRightAreaLayout = css`
  margin-left: 0;
  width: 100%;
`;

const rightAreaLayout = {
  default: css`
    margin-left: ${NAVIGATION_DEFAULT_WIDTH};
    width: calc(100% - ${NAVIGATION_DEFAULT_WIDTH});

    ${onMobile} {
      ${resetRightAreaLayout}
    }
  `,
  gameEdit: css`
    margin-left: ${NAVIGATION_GAME_EDIT_WIDTH};
    width: calc(100% - ${NAVIGATION_GAME_EDIT_WIDTH});

    ${onMobile} {
      ${resetRightAreaLayout}
    }
  `,
  undefined: resetRightAreaLayout
};
