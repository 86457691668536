import { FormGroup, Icon, Input } from '@uniquegood/realworld-studio-design';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { coreApi } from '@/api';
import { useDebounce, useInput } from '@/hooks';
import SearchUserList from './SearchUserList';
import { SearchUserListItemActionProps } from '../types';

function AddUserForm({ ...actionProps }: SearchUserListItemActionProps) {
  const [searchUserQuery, setSearchUserQuery] = useState('');
  const debounceSearchUserQuery = useDebounce(searchUserQuery, 500);

  const [isHideMoreButton, handleIsHideMoreButton] = useInput(false);

  const [searchUsers, setSearchUsers] = useState<AccountResponseModel[]>([]);
  const searchUserPageIndex = useRef(0);

  const onChangeSearchUserQuery = (value: string) => {
    setSearchUserQuery(value);
  };

  const startSearchUsers = (query: string) => {
    if (!query) return;

    onChangeSearchUserQuery(query);
    searchUserPageIndex.current = 0;
    getSearchUsers({ query, page: 0 }).then((res) => {
      setSearchUsers(res ?? []);
    });
  };

  const getSearchUsers = ({ query, page }: { query: string; page: number }) => {
    const params = {
      q: query,
      page
    };

    const data = coreApi
      .get<AccountResponseModel[]>('/api/users', {
        params: {
          ...params
        }
      })
      .then(({ data }) => {
        handleIsHideMoreButton(Boolean(data.length < 20));

        return data;
      });

    return data;
  };

  const loadMoreUsers = useCallback(() => {
    if (searchUsers.length > 0) {
      searchUserPageIndex.current += 1;

      getSearchUsers({ query: searchUserQuery, page: searchUserPageIndex.current }).then((res) => {
        setSearchUsers((prev) => [...prev, ...(res ?? [])]);
      });
    }
  }, [searchUsers, searchUserPageIndex.current]);

  useEffect(() => {
    if (debounceSearchUserQuery) {
      startSearchUsers(debounceSearchUserQuery);
    }
  }, [debounceSearchUserQuery]);

  return (
    <>
      <FormGroup label="사용자 검색">
        <Input
          value={searchUserQuery}
          onChange={onChangeSearchUserQuery}
          prefix={<Icon icon="search_solid" size="20px" />}
          placeholder="사용자 검색"
          onKeyPress={preventEnterKeyPress}
        />
      </FormGroup>

      {searchUsers.length > 0 && (
        <SearchUserList
          searchUsers={searchUsers}
          loadMoreUsers={loadMoreUsers}
          isHideMoreButton={isHideMoreButton}
          {...actionProps}
        />
      )}
    </>
  );
}

const preventEnterKeyPress: React.KeyboardEventHandler<Element> = (event) => {
  if (event.key === 'Enter') {
    event.preventDefault();
  }
};

export default AddUserForm;
