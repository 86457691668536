import { useHistory, useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { css } from '@emotion/react';
import { Controller, useFormContext } from 'react-hook-form';
import { Button, ButtonGroup, FormGroup, Icon, color } from '@uniquegood/realworld-studio-design';
import { useModalState, useRequest } from '@/hooks';
import { ClickEventName, track } from '@/track';
import { MarginBottom10, TextAlignCenter } from '@/styles';

type SelectKitTypeProps = {
  project?: ProjectV2;
  releaseStatus?: ReleaseStatus;
};

type GameTypeLabel = '온라인' | '오프라인';

export default function SelectKitType({ project, releaseStatus }: SelectKitTypeProps) {
  const { appId, projectId } = useParams<AppParam>();

  const { watch, setValue, getValues, control } = useFormContext<ProjectFormData>();

  const { modal, openModal, closeModal } = useModalState();

  const history = useHistory();

  const gameType = watch('projectType');

  const selectedGameTypeLabel: GameTypeLabel | undefined = useMemo(() => {
    if (!gameType) return undefined;

    if (gameType === 'Online' || gameType === 'OnlineKit') return '온라인';
    if (gameType === 'Offline' || gameType === 'OfflineKit') return '오프라인';

    setValue('projectType', 'Online');

    throw Error('예상하는 게임 타입에서 벗어난 값입니다.');
  }, [gameType]);

  function setKitRequired(kitRequired: boolean) {
    track.onClick({
      clickEventName: ClickEventName.click_gamesetting_basicinformation_button_gamekit,
      params: { kitRequired: kitRequired ? 'Y' : 'N' }
    });

    const priceFromTextField = project?.projectType.toLowerCase().includes('Kit');
    const priceToSelectField = !kitRequired;

    if (priceFromTextField && priceToSelectField && releaseStatus?.price) {
      openModal({
        title: '게임 키트 여부를 변경할 수 없어요',
        children: (
          <>
            <Icon
              cssStyle={MarginBottom10}
              icon="times_circle_regular"
              size="100px"
              color={color.interactive_critical_default}
            />
            게임 키트 여부를 변경하고 싶다면 먼저 출시 관리 페이지에서 출시 가격을 [무료]로 설정한
            후 다시 시도해주세요.
          </>
        ),
        cssStyle: TextAlignCenter,
        primaryAction: {
          onAction: () => history.push(`/apps/${appId}/projects/${projectId}/release`),
          content: '출시 관리로 이동'
        },
        secondaryAction: {
          onAction: closeModal,
          content: '취소'
        }
      });
      return;
    }

    if (selectedGameTypeLabel === '온라인') {
      setValue('projectType', kitRequired ? 'OnlineKit' : 'Online');
    } else if (selectedGameTypeLabel === '오프라인') {
      setValue('projectType', kitRequired ? 'OfflineKit' : 'Offline');
    }

    setValue('kitRequired', kitRequired);
  }
  return (
    <Controller
      control={control}
      name="kitRequired"
      render={({ field: { value: kitRequired } }) => (
        <FormGroup label="게임 키트 여부" isHorizontal requiredIndicator>
          <ButtonGroup segmented>
            <Button
              cssStyle={!kitRequired ? undefined : defaultIconColor}
              icon="times_regular"
              type={!kitRequired ? 'primary' : 'outlineBasic'}
              onClick={() => setKitRequired(false)}
            >
              핸드폰만으로 충분해요
            </Button>
            <Button
              cssStyle={kitRequired ? undefined : defaultIconColor}
              icon="map_solid"
              type={kitRequired ? 'primary' : 'outlineBasic'}
              onClick={() => setKitRequired(true)}
            >
              게임 키트가 필요해요
            </Button>
          </ButtonGroup>
        </FormGroup>
      )}
    />
  );
}

const defaultIconColor = css`
  svg {
    color: ${color.icon_default};
    fill: ${color.icon_default};
  }
`;
