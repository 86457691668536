import { css, SerializedStyles } from '@emotion/react';
import { color, spacing, typography } from '@uniquegood/realworld-studio-design';
import React from 'react';

import { FlexContentCenter } from '@/styles';
import { IAvailabel } from '@/components/ActionHandler/types';

type handlerType = 'action' | 'reaction' | 'failReaction';
type handlerStyle = Record<handlerType, SerializedStyles>;

interface NumberPreviewProps extends IAvailabel, React.HTMLAttributes<HTMLDivElement> {
  type: handlerType;
  children: React.ReactNode;
}

const NumberPreview = ({ availabelType, type, children, ...rest }: NumberPreviewProps) => {
  if (availabelType !== 'quest') {
    return <>{children}</>;
  }

  return (
    <div css={HandlerLayout} {...rest}>
      <div css={LineWrap}>
        <ReactionNumbering type={type} />
        <div css={Line} />
      </div>
      {children}
    </div>
  );
};

export default NumberPreview;

const ReactionNumbering = ({ type }: { type: handlerType }) => {
  const ThemeStyle: handlerStyle = {
    action: css`
      background-color: ${color.palette_primary_purple_tint_01};
    `,
    reaction: css`
      background-color: ${color.palette_secondary_blue_tint_02};
    `,
    failReaction: css`
      background-color: ${color.palette_status_red_tintz_1};
    `
  };

  const FontStyle: handlerStyle = {
    action: css`
      color: ${color.interactive_primary_hovered};
      &:before {
        content: '1';
      }
    `,
    reaction: css`
      color: ${color.palette_secondary_blue_shade_02};
      &:before {
        content: '2';
      }
    `,
    failReaction: css`
      color: ${color.palette_status_red_tint_04};
      &:before {
        content: '3';
      }
    `
  };

  return (
    <div css={[Numbering, ThemeStyle[type]]}>
      <p css={[FontStyle[type], NumberingTypography]} />
    </div>
  );
};

const HandlerLayout = css`
  display: flex;
  & > div:nth-of-type(1) {
    flex-shrink: 0;
    width: 24px;
  }
  & > div:nth-of-type(2) {
    width: 100%;
  }
`;

const LineWrap = css`
  margin-right: ${spacing.common.medium};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Numbering = css`
  width: 100%;
  height: 24px;
  min-height: 24px;
  border-radius: 50%;
  ${FlexContentCenter};
`;

const NumberingTypography = css`
  &:before {
    font-family: ${typography.family.primary};
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }
`;

const Line = css`
  width: 1px;
  border: 1px solid ${color.palette_gray_tintz_2};
  margin-top: ${spacing.common.medium};
  height: 100%;
`;
