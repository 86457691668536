import React, { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { color, spacing, Typography } from '@uniquegood/realworld-studio-design';

import { useParams } from 'react-router-dom';
import { onMobile } from '@/styles/responsive';
import { CardList, MissionViewButton } from '@/pages/missions/components/missionList';
import { MissionViewType } from '@/pages/missions/types';
import { useIsMobile, useRequest } from '@/hooks';
import { FlexGrow } from '@/styles';
import { TOP_BAR_HEIGHT } from '@/components/TopBar/Topbar';

function MissionListBar() {
  const { appId, scenarioId } = useParams<AppParam>();
  const isMobile = useIsMobile();

  const rootRef = useRef<HTMLDivElement>(null);
  const [missionView, setMissionView] = useState<MissionViewType>('CardView');

  const { data: missions } = useRequest<Mission[]>(
    `/apps/${appId}/scenarios/${scenarioId}/missions`
  );

  useEffect(() => {
    if (isMobile) {
      setMissionView('CardView');
    }
  }, [isMobile]);

  return (
    <nav css={[MissionCardListStyle, FlexGrow]} ref={rootRef}>
      <header css={[MissionCardHeaderStyle, isMobile ? MobileStyle : DesktopStyle]}>
        <Typography as="h4" type="button">
          미션 {missions?.length ?? 0}개
        </Typography>
        <MissionViewButton missionView={missionView} onChangeMissionView={setMissionView} />
      </header>

      <div css={ListLayout}>
        <CardList root={rootRef.current} missionView={missionView} />
      </div>
    </nav>
  );
}

export default MissionListBar;

const MissionCardHeaderStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ListLayout = css`
  padding: ${spacing.common.large} ${spacing.common.large} 240px;

  ${onMobile} {
    display: flex;
    padding: 0px;
  }
`;

export const MissionListBarMobileHeight = '252px';

const MissionCardListStyle = css`
  position: fixed;
  height: calc(100vh - ${TOP_BAR_HEIGHT});
  top: 58px;

  background-color: ${color.background_default};
  && {
    flex: 0 0 240px;
  }

  overflow-y: auto;

  ${onMobile} {
    position: initial;
    overflow-x: auto;
    overflow-y: initial;
    height: initial;

    padding: ${spacing.common.medium};
    width: 100%;
  }
`;

const DesktopStyle = css`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${color.background_default};
  padding: ${spacing.common.large};
  padding-bottom: ${spacing.common.small};
  z-index: 1;
`;

const MobileStyle = css`
  margin-bottom: ${spacing.common.medium};
`;
