import ThemeDark from './ThemeDark.png';
import ThemeLight from './ThemeLight.png';
import OverlayHorizontal from './OverlayHorizontal.png';
import OverlayVertical from './OverlayVertical.png';
import OverlayWallpaper from './OverlayWallpaper.png';
import OverlayMissionList from './OverlayMissionList.png';
import OverlayDynamicKeyVisual from './OverlayDynamicKeyVisual.png';

export const assetThemeImage = {
  light: ThemeLight,
  dark: ThemeDark
};

export const assetOverlayImage = {
  horizontal: OverlayHorizontal,
  vertical: OverlayVertical,
  wallPaper: OverlayWallpaper,
  missionList: OverlayMissionList,
  dynamicKeyVisual: OverlayDynamicKeyVisual
};
