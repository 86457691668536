import { css, SerializedStyles } from '@emotion/react';
import React, { ReactNode } from 'react';
import {
  container460Style,
  container650Style,
  container960style,
  containerStyle
} from '@/styles/containerStyles';

const containerStyles = {
  960: container960style,
  650: container650Style,
  460: container460Style,
  0: containerStyle
};

export const centeredStyle = css`
  margin-left: auto;
  margin-right: auto;
`;

interface IContainerProps {
  children?: ReactNode;
  centered?: boolean;
  cssStyle?: SerializedStyles | SerializedStyles[];
}

const makeContainer = (width: 960 | 650 | 460 | 0) => {
  const fn = ({ children, centered, cssStyle }: IContainerProps) => {
    return (
      <div
        css={[
          containerStyles[width],
          centered && centeredStyle,
          [...(Array.isArray(cssStyle) ? cssStyle : [cssStyle])]
        ]}
      >
        {children}
      </div>
    );
  };

  return fn;
};

export const Container960 = makeContainer(960);
export const Container650 = makeContainer(650);
export const Container460 = makeContainer(460);
export const Container = makeContainer(0);
