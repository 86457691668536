import React, { DOMAttributes, useEffect } from 'react';

import {
  ActionList,
  Badge,
  color,
  Typography,
  Popover,
  Avatar,
  Button,
  spacing
} from '@uniquegood/realworld-studio-design';

import { css } from '@emotion/react';
import useLocalStorage from 'react-use-localstorage';

import { Link, useHistory } from 'react-router-dom';
import { breakpoint, onMobile } from '@/styles/responsive';

import { KeyboardFocusStyle, RowFlex } from '@/styles';
import LoadingSpinner from '@/components/LoadingSpinner';
import { useParamsWithMatchPath, useRequest, useRequestImmutable } from '@/hooks';

import { levelToKorean } from '@/const';
import { CssStyle } from '@/utils';
import { logout } from '@/api';

interface UserMenuProps extends CssStyle {
  onClose?(): void;
}

export default function UserMenu({ cssStyle, onClose }: UserMenuProps) {
  const { data: me } = useRequestImmutable<MyInfo>('/api/me');
  const history = useHistory();

  const email =
    me?.credentials?.[0].provider === 'Self'
      ? me.credentials[0].providerId
      : me?.credentials?.[0].provider;

  const avatarContent = me ? (
    <>
      <div css={itemStyle}>
        <Avatar size="large" userName={me.name} userEmail={email} source={me.profileImage}/>
      </div>
      <Popover.Section>내 채널</Popover.Section>
      <div css={[itemStyle, ChannelLabel]}>
        <Typography type="body">
          {me.name}님이 소유하고 있거나 구성원으로 추가된 채널입니다.
        </Typography>
      </div>
    </>
  ) : (
    <LoadingSpinner />
  );

  return (
    <>
      <style>
        {`body {
          ${onMobile} {
            overflow: hidden;
          }
    }`}
      </style>
      {/* eslint-disable-next-line */}
      <div
        css={[cssStyle, UserMenuContentWrapper]}
        onClick={stopPropagation}
        onTouchStart={stopPropagation}
      >
        <div css={TitleWrapper}>
          <Popover.Section isTitle>계정 및 설정</Popover.Section>
          <Button
            type="plainWhite"
            icon="times_light"
            size="medium"
            onClick={onClose}
            cssStyle={CloseButton}
          />
        </div>
        <section css={MobileScrollContainer}>
          <Popover.Section>내 계정</Popover.Section>
          {avatarContent}
          <Channels onClick={onClose} />
          <ActionList
            cssStyle={ActionListStyle}
            size="big"
            onActionAnyItem={onClose}
            items={[
              {
                content: '새 채널 추가',
                icon: 'plus_solid',
                onAction: () => history.push('/createApp')
              },
              {
                content: '공지사항',
                icon: 'bullhorn_solid',
                onAction: () => window.open('https://rwd.to/realworldstudio-notice')
              },
              {
                content: '업데이트 소식',
                icon: 'lightbulb_solid',
                onAction: () => window.open('https://rwd.to/realworldstudio-updates')
              },
              {
                content: '문의하기',
                icon: 'comment_dots_solid',
                onAction: () => {
                  if (ChannelIO) {
                    ChannelIO('showMessenger');
                  }
                }
              },
              {
                content: '로그아웃',
                icon: 'sign_out_alt_solid',
                onAction: async () => {
                  await logout();
                  window.location.href = '/';
                }
              }
            ]}
          />
        </section>
      </div>
    </>
  );
}

function stopPropagation(event: React.MouseEvent | React.TouchEvent) {
  event.stopPropagation();
}

const UserMenuContentWrapper = css`
  border-radius: ${spacing.borderRadius.xlarge};
  ${onMobile} {
    border-radius: 0px;
    overflow-y: auto;
  }
`;

const MobileScrollContainer = css`
  ${onMobile} {
    overflow-y: scroll;
    height: calc(100% - 53px);
  }
`;

const CloseButton = css`
  display: none;
  ${onMobile} {
    display: block;
    color: black;
    position: absolute;
    top: ${spacing.margin.medium};
    right: ${spacing.margin.medium};
  }
`;

const TitleWrapper = css`
  border-radius: 16px;
  > div {
    border-radius: ${spacing.borderRadius.xlarge} ${spacing.borderRadius.xlarge} 0 0;
    border-bottom: 0;
  }
`;

const itemStyle = css`
  padding: ${spacing.padding.medium} ${spacing.padding.large};
  background-color: ${color.surface_default_subdued};

  :hover {
    background-color: ${color.surface_default_hovered};
  }
`;

const ItemSelected = css`
  background-color: ${color.surface_primary_default};
`;

const ItemList = css`
  ${itemStyle}

  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  box-shadow: inset 0px -1px 0px #ededed;
`;

const ChannelStyle = css`
  flex: 1 0;
  :focus-visible {
    border-radius: ${spacing.borderRadius.small};
  }

  margin-right: ${spacing.margin.large};
`;

const ActionListStyle = css`
  ul,
  ul:hover {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  .Section-withoutTitle {
    @media (width: ${breakpoint + 1}) {
      width: 368px;
    }
  }
`;

interface Channel extends Omit<RealWorldApp, 'members'> {
  level: User['level'];
}

function Channels({ onClick }: Pick<DOMAttributes<HTMLAnchorElement>, 'onClick'>) {
  const { appId } = useParamsWithMatchPath();

  const { data: me } = useRequest<MyInfo>('/api/me');
  const { data: apps } = useRequest<RealWorldApp[]>('/apps');

  const channels = me && apps ? channelsMapper(apps, me.id) : undefined;

  const [defaultChannelId, setDefaultChannelId] = useLocalStorage('defaultChannelID');

  useEffect(() => {
    const defaultChannelIdIsInvalid = !channels?.find(
      (channel) => channel?.id === defaultChannelId
    );

    if (channels && defaultChannelIdIsInvalid) {
      setDefaultChannelId(channels[0]?.id);
    }
  }, [channels === undefined]);

  if (!channels) return <LoadingSpinner />;

  const targetIndex = channels.findIndex((channel) => channel?.id === defaultChannelId);

  const sortedChannels =
    targetIndex === -1
      ? channels
      : [
          channels[targetIndex],
          ...channels.slice(0, targetIndex),
          ...channels.slice(targetIndex + 1)
        ];

  return (
    <ul css={ChannelsWrapper} role="menu" aria-label="팝오버" tabIndex={-1}>
      {sortedChannels.map((channel) => {
        const isDefault = defaultChannelId === channel.id;
        const channelBadgeProps = isDefault
          ? ({ type: 'purple' } as const)
          : ({ type: 'gray', isOutline: true } as const);

        const isSelectedChannel = appId === channel.id;

        return (
          <li
            key={channel.id}
            role="menuitem"
            tabIndex={-1}
            css={[ItemList, isSelectedChannel ? ItemSelected : undefined]}
          >
            <Link
              to={`/apps/${channel.id}`}
              onClick={onClick}
              css={[RowFlex, KeyboardFocusStyle, ChannelStyle]}
            >
              <Avatar size="large" isCreator source={channel.imageUrl} />
              <div css={ChannelProfile}>
                <Typography type="body" cssStyle={ChannelName}>
                  {channel.name}
                </Typography>
                <Badge>{levelToKorean[channel.level]}</Badge>
              </div>
            </Link>
            <Button
              type="plainWhite"
              onClick={() => setDefaultChannelId(channel.id)}
              cssStyle={BadgeButtonStyle}
            >
              <Badge {...channelBadgeProps}>기본</Badge>
            </Button>
          </li>
        );
      })}
    </ul>
  );
}

export function channelsMapper(apps: RealWorldApp[], userId: string): Channel[] {
  return apps.map((channel) => {
    const { members, ...forwardedProps } = channel;
    const user = members.find((member) => member.id === userId);

    if (!user) throw Error('userId 를 갖는 사용자를 못 찾았습니다.');

    return { level: user.level, ...forwardedProps };
  });
}

const AlwaysShowScroll = css`
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 11px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

const ChannelsWrapper = css`
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 192px;
  overflow-y: auto;

  ${AlwaysShowScroll}

  ${onMobile} {
    max-height: initial;
    overflow-y: initial;
  }
`;

const ChannelProfile = css`
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ChannelName = css`
  margin-bottom: ${spacing.margin.small};
`;

const ChannelLabel = css`
  > div {
    color: ${color.text_subdued};
  }
  padding: 10px ${spacing.padding.large};
`;

const BadgeButtonStyle = css`
  border: 0;
  padding: 0 !important;
  border-radius: 100px;
`;
