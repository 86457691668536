import React, { useEffect } from 'react';
import { Button, Modal, Typography } from '@uniquegood/realworld-studio-design';

import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useModalState, useDidUpdateEffect } from '@/hooks';

import { coreApi } from '@/api';
import { helpLink, toast } from '@/utils';

import { CenteringLayout, EmptyStateEmoji, FlexGrow, TextAlignCenter } from '@/styles';

import OnBoardingModalContent, { OnBoardingStep } from '@/components/OnBoardingModalContent';
import ChatBotModalContent, { FormData, SubmitType } from './ChatBotModalContent';

import useChatbot from '../hooks/useChatbot';

import images from '../assets';
import { ClickEventName, track } from '@/track';

export default function OnboardingModal() {
  const { appId, projectId } = useParams<AppParam>();

  const { mutate } = useChatbot();
  const { modal, openModal, closeModal } = useModalState();

  const { control, handleSubmit, formState, reset } = useForm<FormData>();

  useEffect(() => {
    openModal({
      title: '챗봇을 만들고 게임에 활용하세요',
      size: 'big',
      children: <OnBoardingModalContent steps={steps} />,
      primaryAction: {
        content: '챗봇 만들기',
        onAction: () => {
          onAction();
          track.onClick({
            clickEventName: ClickEventName.click_editgame_mission_chatbot_createchatbot
          });
        }
      },
      secondaryAction: {
        content: '닫기',
        onAction: () => {
          closeModal();
          track.onClick({
            clickEventName: ClickEventName.click_editgame_mission_chatbot_close
          });
        }
      }
    });
  }, []);

  useDidUpdateEffect(() => {
    if (!modal.open) {
      reset();
    }
  }, [modal.open]);

  function onAction() {
    openModal({
      size: 'medium',
      title: '챗봇 생성',
      children: <ChatBotModalContent control={control} onSubmit={onSubmit} />,
      primaryAction: {
        icon: 'save_solid',
        content: '저장',
        // Hack: 모달 콘텐츠 마운트 시 자동으로 from submit 이벤트가 발생해서 직접 클릭 핸들러를 등록했음
        onAction: onSubmit
      },
      secondaryAction: {
        content: '닫기',
        onAction: closeModal
      }
    });
  }
  const onSubmit = handleSubmit(async ({ name, profilePicture }) => {
    const body: SubmitType = {
      name,
      profilePictureId: profilePicture[0]?.id
    };

    await coreApi.post(`/apps/${appId}/projects/${projectId}/chatbots`, body);
    await mutate();

    closeModal();
    toast({ message: '챗봇을 생성했어요' });
  });

  return (
    <div css={[FlexGrow, CenteringLayout]}>
      <Typography
        as="aside"
        textColor="disabled"
        type="displayMedium"
        cssStyle={[TextAlignCenter, EmptyStateEmoji]}
      >
        챗봇을 추가하고 장면을 만들어보세요
      </Typography>
      <Modal
        {...modal}
        primaryAction={{ ...modal.primaryAction, loading: formState.isSubmitting }}
      />
    </div>
  );
}

const steps: OnBoardingStep[] = [
  { title: '1. 게임에 사용할 챗봇을 생성해요' },
  {
    title: '2. 챗봇이 사용될 장면을 만들어요',
    description: '여러 장면을 사용하면 같은 질문에도 상황별로 다른 답장을 보낼 수 있어요'
  },
  { title: '3. 퀘스트 리액션으로 플레이어에게 메시지를 보낼 수 있어요' },
  { title: '4. 플레이어의 대답에 따라 전송할 메시지를 설정하세요' },
  {
    title: (
      <>
        5. 도움이 필요하다면{' '}
        <Button url={helpLink.notion.helpCenter} external>
          헬프센터{' '}
        </Button>
        를 참고해보세요
      </>
    )
  }
].map((step, index) => ({ ...step, imageUrl: images[index] }));
