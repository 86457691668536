import { textStyleBody } from '@uniquegood/realworld-studio-design';
import { css } from '@emotion/react';
import { TextAlignCenter, keepAll } from '@/styles';

export const ModalChildrenStyles = [TextAlignCenter, keepAll, textStyleBody];

export const HorizonScrollWrapper = css`
  width: 100%;
  overflow-x: auto;
`;
