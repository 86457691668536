import { Typography } from '@uniquegood/realworld-studio-design';
import React, { PropsWithChildren, ReactNode } from 'react';

import { FlexGrow, MarginBottom16, MarginBottom4 } from '@/styles';
import { CSSProp } from '../types';

interface Props extends CSSProp {
  title: ReactNode;
  description: ReactNode;
  id?: string;
}
const CardWrap = ({ title, description, children, cssStyle, id }: PropsWithChildren<Props>) => {
  return (
    <section css={[cssStyle, FlexGrow]} id={id}>
      <Typography as="h2" type="displaySmall" cssStyle={MarginBottom4}>
        {title}
      </Typography>
      <Typography as="p" type="caption" textColor="subdued" cssStyle={MarginBottom16}>
        {description}
      </Typography>
      {children}
    </section>
  );
};

export default CardWrap;
