/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Divider } from '@uniquegood/realworld-studio-design';
import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';
import { EditorView } from '@codemirror/view';
import CustomizeHeader from './CustomizeHeader';

const CustomWebViewStyle = css`
  background: #ffffff;
  border: 1px solid #ededed;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.07);
  display: flex;
  flex-direction: column;
`;

interface Props {
  existingWebviewCode: string;
  onChange: (value: string) => void;
  onComplete: () => void;
}

const CustomWithTextarea = ({ existingWebviewCode, onChange, onComplete }: Props) => {
  return (
    <div css={CustomWebViewStyle}>
      <CustomizeHeader onClick={onComplete} />
      <Divider />
      <CodeMirror
        css={css`
          .cm-scroller {
            height: 642px;
            resize: initial;
          }
        `}
        value={existingWebviewCode}
        onChange={(value) => onChange(value)}
        extensions={[html(), EditorView.lineWrapping]}
      />
    </div>
  );
};

export default CustomWithTextarea;
