import { css } from '@emotion/react';
import { spacing, Typography } from '@uniquegood/realworld-studio-design';
import { useParams } from 'react-router-dom';
import { useInView } from 'react-cool-inview';
import { Fragment, useEffect } from 'react';
import { container960style } from '@/styles/containerStyles';
import PinnedPost from './components/PinnedPost';
import WritePost from '../components/WritePost';
import useRequestInfinite from '../hooks/useRequestInfinite';
import Post from '../components/Post';
import LoadingSpinner from '@/components/LoadingSpinner';
import { MarginVertical24, TextAlignCenter } from '@/styles';

export default function Feeds() {
  const { appId } = useParams<AppParam>();

  const {
    data: posts,
    setSize,
    size,
    isReachedEnd,
    mutate
  } = useRequestInfinite<CreatorFeeds, CreatorFeedPost>(
    getKey,
    {},
    { params: { pageSize: 10, filteringBlocks: false } },
    undefined,
    (data) => data.contents,
    (data) => data.count
  );

  function getKey(page: number) {
    return `/api/${appId}/community/creatorFeeds?page=${page + 1}`;
  }

  const { observe, inView } = useInView();

  useEffect(() => {
    if (inView && !isReachedEnd) {
      setSize(size + 1);
    }
  }, [inView, isReachedEnd]);

  if (!posts) return <LoadingSpinner />;

  return (
    <section css={[spacingBetweenItems, container960style]}>
      <PinnedPost mutate={mutate} />
      <WritePost feedsType="creatorFeeds" mutate={mutate} />
      {posts.length > 0 ? (
        posts
          .filter((post) => !post.isPinned)
          .map((post, index) => (
            <Fragment key={post.id}>
              {index === posts.length - 1 && <div ref={observe} />}
              <Post feedsType="creatorFeeds" post={post} mutate={mutate} />
            </Fragment>
          ))
      ) : (
        <Typography type="body" textColor="disabled" cssStyle={[TextAlignCenter, MarginVertical24]}>
          아직 올라온 게시글이 없어요
        </Typography>
      )}
    </section>
  );
}

const spacingBetweenItems = css`
  > :not(:last-of-type) {
    margin-bottom: ${spacing.margin.large};
  }
`;
