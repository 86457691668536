import { css } from '@emotion/react';
import {
  Avatar,
  Button,
  color,
  Input,
  spacing,
  textStyleCaption,
  Typography
} from '@uniquegood/realworld-studio-design';
import { useParams } from 'react-router-dom';
import React, { useState } from 'react';
import { useRequest } from '@/hooks';
import LoadingSpinner from '@/components/LoadingSpinner';
import { Flex, MarginRight16 } from '@/styles';
import { coreApi } from '@/api';
import { toast } from '@/utils';

export interface WritePostProps extends React.ComponentPropsWithoutRef<'div'>, MutateComments {
  feedId: string;
  feedsType: FeedsType;
  /** 댓글 수정 시에 필요한 정보 */
  comment?: FeedComment;
  replyParentId?: string;
  replyParentName?: string;
  onClose?(): void;
}

export default function WriteComment({
  feedId,
  feedsType,
  comment,
  replyParentId,
  replyParentName,
  onClose,
  mutate,
  mutateFeeds,
  mutateParentComments,
  ...divProps
}: WritePostProps) {
  const { appId } = useParams<AppParam>();
  const { data: app } = useRequest<RealWorldApp>(`/apps/${appId}`);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // HACK: react-hook-form 을 이 컴포넌트에서 사용하면 답글 작성 이후에 작성 영역이 초기화되지 않고 그대로여서 네이티브 이벤트를 활용을 했습니다.
  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    if (
      !(
        event.target instanceof HTMLFormElement &&
        event.target.content instanceof HTMLTextAreaElement
      )
    )
      throw Error();

    const { content } = event.target;

    if (content.value.trim().length === 0) {
      content.focus();
      return;
    }

    setIsSubmitting(true);

    if (comment) {
      await coreApi.patch(
        'parentId' in comment
          ? `/api/${appId}/community/${feedsType}/${feedId}/comments/${comment.parentId}/replies/${comment.id}`
          : `/api/${appId}/community/${feedsType}/${feedId}/comments/${comment.id}`,
        { isCreator: true, content: content.value }
      );

      await mutate();
      mutateFeeds();

      toast({
        message: 'parentId' in comment ? '답글이 수정되었어요!' : '댓글이 수정되었어요!'
      });

      if (onClose) {
        onClose();
      }

      event.target.reset();

      setIsSubmitting(false);

      return;
    }

    if (replyParentId) {
      await coreApi.post(
        `/api/${appId}/community/${feedsType}/${feedId}/comments/${replyParentId}/replies`,
        { isCreator: true, content: content.value, taggedWriterId: replyParentId }
      );

      // 대댓글 추가 시 부모 댓글의 comment.replyCount 를 +1 하기 위한 mutate 함수 호출입니다.
      if (!mutateParentComments) throw Error();
      await mutateParentComments();
    } else {
      await coreApi.post(`/api/${appId}/community/${feedsType}/${feedId}/comments`, {
        isCreator: true,
        content: content.value
      });
    }

    await mutate();
    mutateFeeds();

    toast({
      message: (replyParentId && '답글이 작성되었어요!') || '댓글이 작성되었어요!'
    });

    if (onClose) {
      onClose();
    }

    event.target.reset();

    setIsSubmitting(false);
  };

  if (!app) return <LoadingSpinner />;

  return (
    <div css={Flex} {...divProps}>
      <Avatar source={app.imageUrl} isCreator size="medium" cssStyle={MarginRight16} />
      <form css={formLayout} onSubmit={onSubmit}>
        <div aria-label="작성자" css={textStyleCaption}>
          {app.name}
        </div>
        {replyParentName && (
          <Typography as="span" type="body" cssStyle={replyLabelStyle}>
            @{replyParentName}
          </Typography>
        )}

        <Input
          id="content"
          name="content"
          defaultValue={comment?.content}
          multiline
          cssStyle={inputMinHeight}
          maxLength={1000}
          showCharacterCount
        />
        <div css={buttonsLayout}>
          <Button
            loading={isSubmitting}
            htmlType="submit"
            size="small"
            type="primary"
            cssStyle={submitButtonLayout}
          >
            게시
          </Button>
        </div>
      </form>
    </div>
  );
}

const formLayout = css`
  flex: 1;
  > :not(:last-of-type) {
    margin-bottom: ${spacing.margin.small};
  }
`;

const inputMinHeight = css`
  textarea {
    min-height: 118px;
  }
`;

const buttonsLayout = css`
  display: flex;
  justify-content: flex-end;
`;

const submitButtonLayout = css`
  align-self: flex-end;
`;

const replyLabelStyle = css`
  display: inline-block;
  margin-bottom: ${spacing.margin.xsmall};
  color: ${color.action_dark_blue_default};

  font-size: 12px;
`;
