import styled from '@emotion/styled';
import { color } from '@uniquegood/realworld-studio-design';

/**
 * 본인인증일 때: 본인인증 보라색, 나머지 검은색
 * 이메일 인증일 때: 본인인증 회색, 이메일 인증 보라색, 가입 완료 검은색
 *
 */

export default function ProgressTab({ step }: { step: string }) {
  const getEmailColor = () => {
    if (step === 'VERIFY_EMAIL') {
      return color.action_primary_default;
    }
    if (step === 'ALL_VERIFIED') {
      return color.text_disabled;
    }
    if (step === 'VERIFY_PHONE') {
      return color.text_default;
    }
    return color.text_default;
  };

  return (
    <Container pageStep={step}>
      <Item>
        <Dot
          selected={step === 'VERIFY_PHONE' || step === 'VERIFY_EMAIL' || step === 'ALL_VERIFIED'}
        />
        <h3
          style={{
            color: step === 'VERIFY_PHONE' ? color.palette_primary_purple_100 : color.text_disabled
          }}
        >
          본인인증
        </h3>
      </Item>
      <Item>
        <Dot selected={step === 'VERIFY_EMAIL' || step === 'ALL_VERIFIED'} />
        <h3
          style={{
            color: getEmailColor()
          }}
        >
          이메일 인증
        </h3>
      </Item>
      <Item>
        <Dot selected={step === 'ALL_VERIFIED'} />
        <h3
          style={{
            color: step === 'ALL_VERIFIED' ? color.action_primary_default : color.text_default
          }}
        >
          가입 완료
        </h3>
      </Item>
    </Container>
  );
}

const Container = styled.div<{ pageStep: string }>`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 7px;
  border-top: 2px solid #e9ebf1;

  &::before {
    content: '';
    position: absolute;
    bottom: 100%;
    width: ${({ pageStep }) => {
      switch (pageStep) {
        case 'VERIFY_PHONE':
          return '0%';
        case 'VERIFY_EMAIL':
          return '50%';
        case 'ALL_VERIFIED':
          return '100%';
        default:
          return '0%';
      }
    }};
    height: 2px;
    background-color: ${color.action_primary_default};
  }
`;

const Item = styled.div`
  position: absolute;
  top: 0;
  text-align: left;

  & > h3 {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  &:nth-child(1) {
    left: 0;
    transform: translate(-50%, -7px);
  }

  &:nth-child(2) {
    left: 50%;
    transform: translate(-50%, -7px);
  }

  &:nth-child(3) {
    right: 0;
    transform: translate(50%, -7px);
  }
`;

const Dot = styled.div<{ selected: boolean }>`
  position: relative;
  left: 50%;
  top: -50%;
  width: 12px;
  height: 12px;
  margin-bottom: 2px;
  border-radius: 99px;
  background-color: ${({ selected }) => (selected ? color.action_primary_default : '#fff')};
  border: ${({ selected }) => (selected ? 'none' : `2px solid #626262`)};
  transform: translateX(-50%);
`;
