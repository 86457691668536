import { css } from '@emotion/react';
import { spacing } from '@uniquegood/realworld-studio-design';

export const MarginLeft4 = css`
  margin-left: ${spacing.margin.xsmall};
`;

export const TextAlignCenter = css`
  text-align: center;
`;
