import { reactionCommandDefinitionGroup } from '@/components/ActionHandler/utils/ReactionDefinitions';

export const combineCommandDefinitions = reactionCommandDefinitionGroup.Process.items
  .concat(reactionCommandDefinitionGroup.View.items)
  .concat(reactionCommandDefinitionGroup.Button.items)
  .concat(reactionCommandDefinitionGroup.Mission.items)
  .concat(reactionCommandDefinitionGroup.Item.items)
  .concat(reactionCommandDefinitionGroup.Chatbot.items)
  .concat(reactionCommandDefinitionGroup.BGM.items)
  .concat(reactionCommandDefinitionGroup.Advanced.items);
