/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Icon, Card, Button, color, Modal } from '@uniquegood/realworld-studio-design';
import { RequestRequireProps } from '../types';
import useModalState from '../hooks/useModalState';
import WebviewCustomize, { ActionParameterContent } from './WebviewCustomize';
import WebviewSelect from './SelectWebview/WebviewSelect';

interface Props extends React.ComponentPropsWithoutRef<'div'>, RequestRequireProps {
  data: ActionParameterContent;
  updateData(changedData: ActionParameterContent): void;
  onComplete: () => void;
  onClose: () => void;
  onSelectTemplate(templateId: string): void;
  onDeSelectTemplate(templateId: string): void;
}

export type { ActionParameterContent };

const Container = ({
  data,
  updateData,
  onComplete,
  onClose,
  onSelectTemplate,
  onDeSelectTemplate,
  coreApiEndPoint,
  studioApiEndPoint,
  appId,
  token,
  projectId,
  ...rest
}: Props) => {
  const { modal, openModal, closeModal } = useModalState();

  // 웹뷰 선택창에서 웹뷰 편집창으로 이동
  function selectToCustomize(id: string) {
    onSelectTemplate(id);
    updateData({
      templateId: id,
      editedParams: {},
      content: ''
    });
  }

  // 웹뷰 편집창에서 웹뷰 선택창으로 이동 (뒤로가기 버튼 클릭 시 동작)
  function onGoBack() {
    openModal({
      title: '웹뷰를 편집중이에요',
      children: '웹뷰 편집창을 벗어나면 변경사항이 사라져요. 정말 나가시겠어요?',
      primaryAction: {
        content: '계속 편집하기',
        icon: 'magic_solid',
        onAction: () => {
          closeModal();
        }
      },
      leftAction: {
        content: '나가기',
        onAction: () => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          onDeSelectTemplate(data!.templateId!);
          closeModal();
          updateData(null);
        }
      }
    });
  }

  return (
    <div
      {...rest}
      css={css`
        max-width: 1180px;
        min-width: 1122px;
        padding: 0 40px;
        margin: 40px 40px 0px;
        margin-left: auto;
        margin-right: auto;

        @media only screen and (max-width: 768px) {
          padding: 0;
          margin-left: auto;
          margin-right: auto;
        }
      `}
    >
      <Card
        cssStyle={css`
          > :nth-of-type(2) {
            padding: 0px 0px;
          }
          overflow: hidden;
        `}
        header={{
          content: '웹뷰 빌더',
          prefix: <Icon icon="magic_solid" size="20px" />,
          backgroundColor: color.surface_primary_default,
          suffix: <Button type="plain" icon="times_light" onClick={onClose} size="medium" />
        }}
      >
        {/* 저장된 데이터가 있는지 여부로 조건부 렌더랑 */}
        {/* 웹뷰 선택창 -> 웹뷰 편집창 */}
        {/* dataForUserInterface에 templateId를 넣어줌으로써 이동시킴 - 선택한 템플릿의 id 또는 null */}
        {data ? (
          <WebviewCustomize
            templateId={data.templateId}
            editedParams={data.editedParams}
            content={data.content}
            onGoBack={onGoBack}
            onComplete={onComplete}
            updateData={updateData}
            coreApiEndPoint={coreApiEndPoint}
            studioApiEndPoint={studioApiEndPoint}
            projectId={projectId}
            appId={appId}
            token={token}
          />
        ) : (
          <WebviewSelect
            token={token}
            selectToCustomize={selectToCustomize}
            studioApiEndPoint={studioApiEndPoint}
          />
        )}
      </Card>
      <Modal {...modal} />
    </div>
  );
};
export default Container;
