import { css } from '@emotion/react';
import { Typography } from '@uniquegood/realworld-studio-design';
import React from 'react';

import { tooltipImage } from '@/components/ActionHandler/assets';
import { helpLink } from '@/utils';
import { AlignCenter, ATagStyle, ColumnFlex, ListStyleTypeDisc } from '@/styles';

const None = () => {
  return <StringTooltip>아무런 조건 없이 아래의 리액션이 실행되어요</StringTooltip>;
};

const InputData = () => {
  return (
    <StringTooltip>
      플레이어가 위의 값을 입력했을 경우 아래 리액션이 실행되어요. 콤마(,)를 이용해 여러 개의 값을
      지정할 수 있으며, 영문 대소문자와 띄어쓰기는 반영되지 않아요.
    </StringTooltip>
  );
};

const QuestAccomplished = () => {
  return <StringTooltip>지정한 퀘스트를 완료했을 경우 아래 리액션이 실행되어요</StringTooltip>;
};

const HasItem = () => {
  return <StringTooltip>지정한 아이템을 보유했을 경우 아래 리액션이 실행되어요</StringTooltip>;
};

const ConditionWebhook = () => {
  return (
    <StringTooltip>
      플레이어의 액션을 특정한 서버로 전송하여 조건에 만족하는지 직접 판단할 수 있도록 하는 고급
      기능이에요. 자세한 사용방법은{' '}
      <a
        href="https://rwd.to/realworldstudio-manual-WebhookCondition"
        target="_blank"
        rel="noreferrer"
        css={ATagStyle}
      >
        매뉴얼
      </a>
      과{' '}
      <a href={helpLink.community} target="_blank" css={ATagStyle} rel="noreferrer">
        커뮤니티
      </a>
      에서 확인해주세요.
    </StringTooltip>
  );
};

export const ReactionConditionTooltip = {
  None,
  InputData,
  QuestAccomplished,
  HasItem,
  ConditionWebhook
};

const AccomplishScenario = () => {
  return (
    <Typography as="ul" type="body" cssStyle={[TooltipWidth, ListStyleTypeDisc]}>
      <li> 버튼을 누르면 게임이 종료되고 상세페이지로 이동해요.</li>
      <li>게임 완료 기록이 자동으로 저장되며 완료 인원은 대시보드에서 확인할 수 있어요.</li>
    </Typography>
  );
};

const GotoMission = () => {
  return <StringTooltip>버튼을 누르면 지정한 미션으로 이동해요.</StringTooltip>;
};

const StartScenario = () => {
  return (
    <Typography as="ul" type="body" cssStyle={[TooltipWidth, ListStyleTypeDisc]}>
      <li>게임 플레이 인원 카운트를 위한 리액션이에요.</li>
      <li>게임이 시작되는 지점에 넣어주세요. 플레이 인원은 대시보드에서 확인할 수 있어요.</li>
    </Typography>
  );
};

const AccomplishScenario2 = () => {
  return (
    <Typography as="ul" type="body" cssStyle={[TooltipWidth, ListStyleTypeDisc]}>
      <li>게임 플레이 인원 카운트를 위한 리액션이에요.</li>
      <li>게임이 종료되는 지점에 넣어주세요. 완료 인원은 대시보드에서 확인할 수 있어요.</li>
    </Typography>
  );
};

const DisplayHtml = () => {
  return <StringTooltip>리액션 본문을 추가해요</StringTooltip>;
};

const ShareGame = () => {
  return (
    <div css={[ColumnFlex, AlignCenter]}>
      화면 하단에 아래 이미지와 같은 박스를 추가해요
      <img css={SNSImageStyle} alt="공유 툴팁 이미지" src={tooltipImage.shareSNS} />
    </div>
  );
};

const PlayVideo = () => {
  return (
    <StringTooltip>
      https://www.youtube.com/watch?v= 이하의 주소를 적어주세요. 버튼을 누르면 영상이 재생되어요.
    </StringTooltip>
  );
};

const GotoReview = () => {
  return (
    <Typography as="ul" type="body" cssStyle={[TooltipWidth, ListStyleTypeDisc]}>
      <li>단독으로 사용되었을 때에는 바로 후기 작성 화면을 보여줘요.</li>
      <li>다른 화면 요소와 함께 사용되었을 때에는 후기 작성 버튼(문구 변경 가능)을 표시해줘요.</li>
    </Typography>
  );
};

const GoBack = () => {
  return <StringTooltip>버튼을 누르면 이전 화면으로 돌아가요</StringTooltip>;
};

const OpenBrowser = () => {
  return (
    <StringTooltip>버튼을 누르면 지정한 URL을 열어요. 내장 브라우저 사용을 권장해요.</StringTooltip>
  );
};

const RevealMission = () => {
  return (
    <StringTooltip>
      선택한 미션의 &apos;미션 카드 표시 여부&apos;를 &apos;표시&apos;로 변경해요
    </StringTooltip>
  );
};

const AccomplishMission = () => {
  return (
    <StringTooltip>해당 미션을 완료한 플레이어의 수를 카운트하기 위한 리액션이에요</StringTooltip>
  );
};

const LockMission = () => {
  return (
    <StringTooltip>
      선택한 미션의 &apos;미션 카드 잠금 여부&apos;를 &apos;잠금&apos;으로 변경해요
    </StringTooltip>
  );
};

const UnlockMission = () => {
  return (
    <StringTooltip>
      선택한 미션의 &apos;미션 카드 잠금 여부&apos;를 &apos;미잠금&apos;으로 변경해요
    </StringTooltip>
  );
};

const RevealQuest = () => {
  return (
    <StringTooltip>
      선택한 퀘스트의 &apos;퀘스트 버튼 표시 여부&apos;를 &apos;표시&apos; 로 변경해요
    </StringTooltip>
  );
};

const AccomplishQuest = () => {
  return <StringTooltip>선택한 퀘스트를 완료 처리해요</StringTooltip>;
};

const LockQuest = () => {
  return (
    <StringTooltip>
      선택한 퀘스트의 &apos;퀘스트 버튼 잠금 여부&apos;를 &apos;잠금&apos;으로 변경해요
    </StringTooltip>
  );
};

const UnlockQuest = () => {
  return (
    <StringTooltip>
      선택한 퀘스트의 &apos;퀘스트 버튼 잠금 여부&apos;를 &apos;미잠금&apos;으로 변경해요
    </StringTooltip>
  );
};

const QuestLabel = () => {
  return <StringTooltip>선택한 퀘스트 버튼의 문구를 변경해요</StringTooltip>;
};

const AcquireItem = () => {
  return (
    <Typography as="ul" type="body" cssStyle={[TooltipWidth, ListStyleTypeDisc]}>
      <li>선택한 아이템을 획득해요.</li>
      <li>챗봇에서 아이템 획득을 사용하면 획득 메시지가 보이지 않아요.</li>
      <li>자세한 사용방법은 매뉴얼에서 확인해주세요.</li>
    </Typography>
  );
};

const DecreaseItem = () => {
  return <StringTooltip>선택한 아이템을 소모해요</StringTooltip>;
};

const SendChatbotMessage = () => {
  return <StringTooltip>선택한 챗봇으로 메시지를 전송해요</StringTooltip>;
};

const MoveScene = () => {
  return <StringTooltip>선택한 챗봇을 선택한 장면으로 이동시켜요</StringTooltip>;
};

const ChangeBgm = () => {
  return <StringTooltip>해당 리액션 이후의 BGM을 업로드한 mp3파일로 변경해요</StringTooltip>;
};

const StopBgm = () => {
  return <StringTooltip>해당 리액션 이후로 BGM을 정지해요</StringTooltip>;
};

const SetStringVariableInput = () => {
  return (
    <Typography as="ul" type="body" cssStyle={[TooltipWidth, ListStyleTypeDisc]}>
      <li>플레이어가 입력한 텍스트를 입력한 이름의 변수로 저장해요.</li>
      <li>‘변수 복사’ 버튼을 눌러 ‘내용 추가’ 리액션에 삽입하면 적용된 값을 볼 수 있어요.</li>
      <li>
        자세한 사용방법은{' '}
        <a
          href="https://rwd.to/realworldstudio-manual-variable"
          target="_blank"
          rel="noreferrer"
          css={ATagStyle}
        >
          매뉴얼
        </a>
        에서 확인해주세요.
      </li>
    </Typography>
  );
};

const SetStringVariableValue = () => {
  return (
    <Typography as="ul" type="body" cssStyle={[TooltipWidth, ListStyleTypeDisc]}>
      <li>특정 텍스트를 입력한 이름의 변수로 저장해요.</li>
      <li>‘변수 복사’ 버튼을 눌러 ‘내용 추가’ 리액션에 삽입하면 적용된 값을 볼 수 있어요.</li>
      <li>
        자세한 사용방법은{' '}
        <a
          href="https://rwd.to/realworldstudio-manual-variable"
          target="_blank"
          rel="noreferrer"
          css={ATagStyle}
        >
          매뉴얼
        </a>
        에서 확인해주세요.
      </li>
    </Typography>
  );
};

const NextMission = () => {
  return (
    <StringTooltip>
      특정 미션으로 이동 버튼 추가, 미션 표시, 그 이후의 미션 표시를 한 번에 설정하는 리액션이에요
    </StringTooltip>
  );
};

const PlayAudio = () => {
  return <StringTooltip>리액션 화면 상단에 오디오 플레이어를 추가해요.</StringTooltip>;
};

const CommandWebhook = () => {
  return (
    <Typography as="ul" type="body" cssStyle={[TooltipWidth, ListStyleTypeDisc]}>
      <li>
        플레이어의 액션을 특정한 서버로 전송하여 서버에서 직접 리액션을 생성할 수 있도록 하는 고급
        기능이에요.
      </li>
      <li>
        자세한 사용방법은{' '}
        <a
          href="https://rwd.to/realworldstudio-manual-WebhookReaction"
          target="_blank"
          rel="noreferrer"
          css={ATagStyle}
        >
          매뉴얼
        </a>
        과{' '}
        <a href={helpLink.community} target="_blank" rel="noreferrer" css={ATagStyle}>
          커뮤니티
        </a>
        에서 확인해주세요.
      </li>
    </Typography>
  );
};

const AdvancedAcquireItem = () => {
  return (
    <Typography as="ul" type="body" cssStyle={[TooltipWidth, ListStyleTypeDisc]}>
      <li>팝업에 노출되는 버튼을 커스텀 가능한 아이템입니다.</li>
      <li>B2G 전용으로 개발되었으며 [GPS] 특정 여러 장소로 이동 액션과 같이 사용되어야 합니다.</li>
    </Typography>
  );
};

export const ReactionCommandTooltip = {
  AccomplishScenario,
  GotoMission,
  StartScenario,
  AccomplishScenario2,
  DisplayHtml,
  ShareGame,
  PlayVideo,
  PlayAudio,
  GotoReview,
  GoBack,
  OpenBrowser,
  RevealMission,
  AccomplishMission,
  LockMission,
  UnlockMission,
  RevealQuest,
  AccomplishQuest,
  LockQuest,
  UnlockQuest,
  QuestLabel,
  AcquireItem,
  DecreaseItem,
  SendChatbotMessage,
  MoveScene,
  ChangeBgm,
  StopBgm,
  SetStringVariableInput,
  SetStringVariableValue,
  NextMission,
  CommandWebhook,
  AdvancedAcquireItem
};

const SNSImageStyle = css`
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 320px;
`;

const TooltipWidth = css`
  max-width: 320px;
`;

const TooltipResetCss = css`
  ul {
    list-style: initial !important;
    padding-left: 20px;
  }
`;

const StringTooltip = ({ children }: React.PropsWithChildren<unknown>) => {
  return (
    <div css={TooltipWidth}>
      <Typography as="p" type="body" cssStyle={TooltipResetCss}>
        {children}
      </Typography>
    </div>
  );
};
