import { css } from '@emotion/react';
import { Card, color, Icon, Modal, spacing, Typography } from '@uniquegood/realworld-studio-design';

import FloatingNavigation, { FloatingNavigationProps } from '@components/FloatingNavigation';

import React, { useEffect, useMemo } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import { Controller, FieldError, useForm } from 'react-hook-form';
import { coreApi } from '@/api';
import { assetThemeImage, assetOverlayImage } from '@/pages/theme/assets';
import { onMobile } from '@/styles/responsive';
import { MarginBottom10, MarginTop8, TextAlignCenter } from '@/styles';
import BGMUploader from '@/components/BGMUploader';
import BottomFloatingBar, { SpacingForBottomFloatingBar } from '@/components/BottomFloatingBar';
import { useIsAdminSelector, useModalState, useRequest, useRestrictProjectRoute } from '@/hooks';
import { toast } from '@/utils';
import LoadingSpinner from '@/components/LoadingSpinner';
import { container960style } from '@/styles/containerStyles';
import RadioItem from './components/RadioItem';
import PreviewUploader from './components/PreviewUploader';
import { SelectedButtonType } from './types';
import RouteModal from '@/components/RouteModal';
import { ClickEventName, PageViewEventName, SaveEventName, track, UploadEventName } from '@/track';
import VideoPreviewUploader from './components/VideoPreviewUploader';

interface FormData
  extends Pick<ProjectThemeRequestModel, 'theme' | 'isBgmFadeIn' | 'isBgmFadeOut'> {
  backgroundImage?: CommonFileModel;
  horizontalImage?: CommonFileModel;
  verticalImage?: CommonFileModel;
  dynamicKeyVisualImage?: CommonFileModel;
  missionListBackgroundImage?: CommonFileModel;
  defaultBgmUrl?: string | null;
}

export default function Theme() {
  const { appId, projectId } = useParams<AppParam>();

  const { data: themeData, mutate } = useRequest<CommonResponseModel<ProjectThemeResponseModel>>(
    `/apps/${appId}/projects/${projectId}/theme`,
    {},
    {
      headers: {
        'x-rwd-api-version': '1.1'
      }
    }
  );
  const theme = themeData?.data;

  const { data: isAdmin } = useIsAdminSelector();

  const { handleSubmit, control, setValue, formState, reset, watch, getValues } =
    useForm<FormData>();

  useEffect(() => {
    track.onPageView({ pageViewEventName: PageViewEventName.view_editgame_edittheme });
  }, []);

  useEffect(() => {
    if (theme && !formState.isDirty) {
      const defaultValues: FormData = {
        ...theme,
        backgroundImage: theme.backgroundImage,
        horizontalImage: theme.horizontalImage,
        verticalImage: theme.verticalImage,
        dynamicKeyVisualImage: theme.dynamicKeyVisual,
        missionListBackgroundImage: theme.missionListBackgroundImage,
        defaultBgmUrl: theme.bgmUrl || null
      };
      reset(defaultValues);
    }
  }, [theme !== undefined]);

  const { modal, openModal, closeModal } = useModalState();

  const redirectTo = useRestrictProjectRoute();

  if (!theme || redirectTo === undefined || isAdmin === undefined) return <LoadingSpinner />;

  if (typeof redirectTo === 'string') return <Redirect to={redirectTo} />;

  const onSubmit = handleSubmit(
    async (data) => {
      const transformed: Partial<ProjectThemeRequestModel> = {
        ...data,
        backgroundImageId: data.backgroundImage?.userFileId,
        horizontalImageId: data.horizontalImage?.userFileId,
        verticalImageId: data.verticalImage?.userFileId,
        dynamicKeyVisualId: data.dynamicKeyVisualImage?.userFileId,
        missionListBackgroundImageId: data.missionListBackgroundImage?.userFileId
      };

      await coreApi.put(`/apps/${appId}/projects/${projectId}/theme`, transformed, {
        headers: {
          'x-rwd-api-version': '1.1'
        }
      });
      track.onSave({ saveEventName: SaveEventName.save_editgame_theme });

      await mutate();

      reset(data);
      toast({ message: '저장 완료!' });
    },
    (errors) => {
      openModal({
        size: 'small',
        title: '필수 항목을 모두 채워주세요',
        cssStyle: TextAlignCenter,
        children: (
          <>
            <Icon
              cssStyle={MarginBottom10}
              icon="times_circle_regular"
              size="100px"
              color={color.interactive_critical_default}
            />
            {(errors.dynamicKeyVisualImage as FieldError | undefined)?.message ??
              '대표 이미지를 설정해주세요'}
          </>
        ),
        primaryAction: {
          content: '확인',
          onAction: closeModal
        }
      });
    }
  );

  const [
    horizontalImage,
    verticalImage,
    backgroundImage,
    missionListBackgroundImage,
    dynamicKeyVisualImage
  ] = watch([
    'horizontalImage',
    'verticalImage',
    'backgroundImage',
    'missionListBackgroundImage',
    'dynamicKeyVisualImage'
  ]);

  const InsertImageStyle = {
    image: css`
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 140px;
      background-image: url(${horizontalImage?.fileUrl});
      ${BackgroundImageCenter};
      border-top-left-radius: ${spacing.common.small};
      border-top-right-radius: ${spacing.common.small};
    `,
    vertical: css`
      position: absolute;
      top: 78px;
      left: 8px;
      width: 63px;
      border-radius: ${spacing.common.small};
      height: 85px;
      z-index: 1;
      background-image: url(${verticalImage?.fileUrl});
      ${BackgroundImageCenter};
    `,
    theme: css`
      position: absolute;
      top: 30px;
      left: 0;
      width: 100%;
      height: 274px;
      border-bottom-left-radius: ${spacing.common.small};
      border-bottom-right-radius: ${spacing.common.small};
      background-image: url(${backgroundImage?.fileUrl});
      ${BackgroundImageCenter};
    `,
    cover: css`
      position: absolute;
      top: 30px;
      left: 0;
      width: 100%;
      height: 274px;
      border-bottom-left-radius: ${spacing.common.small};
      border-bottom-right-radius: ${spacing.common.small};
      background-image: url(${missionListBackgroundImage?.fileUrl});
      ${BackgroundImageCenter};
    `
  };

  const defaultBgmFileName = decodeURIComponent(
    getValues('defaultBgmUrl')?.substring(exampleProjectFilesUrl.length) ?? ''
  );

  const imageVerticalIsRequired = !!dynamicKeyVisualImage?.userFileId;

  return (
    <>
      <RouteModal
        when={formState.isDirty}
        isValid={formState.isValid}
        loading={formState.isSubmitting}
        onSubmit={onSubmit}
      />
      <FloatingNavigation
        items={floatingNavigationItems}
        cssStyle={FloatingNavigationBarStyle}
        offset={24}
      />
      <form
        css={[ThemeContainer, SpacingForBottomFloatingBar, container960style]}
        onSubmit={onSubmit}
      >
        <Controller
          control={control}
          name="theme"
          render={({ field: { value, onChange } }) => (
            <Card
              id="choice-theme"
              cssStyle={[CardSpacingStyle, ThemeWrap]}
              header={{
                content: '테마 선택',
                prefix: <Icon icon="paint_brush_solid" size="20px" />
              }}
            >
              <RadioItem
                imageUrl={assetThemeImage.light}
                onChange={onChange}
                // 서버에서 기존에 light로 내려주다가 2024년 홈/상세 개편 이후 Light로 내려줌.
                // 기존 코드와 호환을 위해 toLowerCase() 추가
                checked={value?.toLowerCase() === 'light'}
                value="light"
                onClick={() => {
                  track.onClick({
                    clickEventName: ClickEventName.click_editgame_theme_select_lightordark,
                    params: { theme: 'light' }
                  });
                }}
              >
                <dl>
                  <Typography as="dt" type="heading" className="font_purple">
                    라이트 테마
                  </Typography>
                  <Typography as="dd" type="body" textColor="initial" cssStyle={MarginTop8}>
                    밝은 상단바
                  </Typography>
                  <Typography as="dd" type="body" textColor="initial">
                    색상 밝은 기본 배경
                  </Typography>
                  <Typography as="dd" type="body" textColor="initial">
                    검정 기본 글자색
                  </Typography>
                </dl>
              </RadioItem>
              <RadioItem
                imageUrl={assetThemeImage.dark}
                onChange={onChange}
                checked={value?.toLowerCase() === 'dark'}
                value="dark"
                onClick={() => {
                  track.onClick({
                    clickEventName: ClickEventName.click_editgame_theme_select_lightordark,
                    params: { theme: 'dark' }
                  });
                }}
              >
                <dl>
                  <Typography as="dt" type="heading" className="font_purple">
                    다크 테마
                  </Typography>
                  <Typography as="dl" type="body" textColor="initial" cssStyle={MarginTop8}>
                    어두운 상단바 색상
                  </Typography>
                  <Typography as="dl" type="body" textColor="initial">
                    어두운 기본 배경
                  </Typography>
                  <Typography as="dl" type="body" textColor="initial">
                    흰색 기본 글자색
                  </Typography>
                </dl>
              </RadioItem>
            </Card>
          )}
        />

        <Card
          id="representative-image-setting"
          cssStyle={[CardSpacingStyle, ThemeWrap]}
          header={{
            content: '대표 이미지 설정',
            isRequired: true,
            prefix: <Icon icon="photo_video_solid" size="20px" />
          }}
        >
          <Controller
            control={control}
            name="horizontalImage"
            rules={{ required: true }}
            render={({ field }) => {
              const generatedControlledHandler = useMemo(
                () =>
                  generateControlledHandler({
                    ...field,
                    value: {
                      id: field.value?.userFileId || '',
                      url: field.value?.fileUrl || ''
                    }
                  }),
                [field.value?.userFileId]
              );
              return (
                <PreviewUploader
                  ratio="horizontal"
                  label="가로형 (750X600 권장)"
                  requiredIndicator
                  imageSrc={assetOverlayImage.horizontal}
                  useFor="project-horizontal"
                  {...generatedControlledHandler}
                  onChange={(files) => {
                    generatedControlledHandler.onChange(files);
                    if (files) {
                      track.onUpload({
                        uploadEventName: UploadEventName.upload_editgame_theme_mainimage_horizontal
                      });
                    } else {
                      track.onClick({
                        clickEventName: ClickEventName.click_editgame_theme_delete_horizontalimage
                      });
                    }
                  }}
                >
                  <div css={InsertImageStyle.image} />
                </PreviewUploader>
              );
            }}
          />
          <Controller
            control={control}
            name="verticalImage"
            rules={{ required: imageVerticalIsRequired }}
            render={({ field }) => {
              const generatedControlledHandler = useMemo(
                () =>
                  generateControlledHandler({
                    ...field,
                    value: {
                      id: field.value?.userFileId || '',
                      url: field.value?.fileUrl || ''
                    }
                  }),
                [field.value?.userFileId]
              );
              return (
                <PreviewUploader
                  ratio="vertical"
                  label="세로형 (480X640 권장)"
                  useFor="project-vertical"
                  imageSrc={assetOverlayImage.vertical}
                  requiredIndicator={imageVerticalIsRequired}
                  {...generatedControlledHandler}
                  onChange={(files) => {
                    generatedControlledHandler.onChange(files);
                    if (files) {
                      track.onUpload({
                        uploadEventName: UploadEventName.upload_editgame_theme_mainimage_vertical
                      });
                    } else {
                      track.onClick({
                        clickEventName: ClickEventName.click_editgame_theme_delete_verticallimage
                      });
                    }
                  }}
                >
                  <div css={InsertImageStyle.vertical} />
                </PreviewUploader>
              );
            }}
          />
          {/* <Controller
            control={control}
            name="dynamicKeyVisualImage"
            rules={{
              validate: (value) =>
                value && !verticalImage?.userFileId
                  ? '다이나믹 키 비주얼은 세로형 이미지와 함께 설정이 필요해요'
                  : true
            }}
            render={({ field: { value, onChange } }) =>
              isAdmin ? (
                <VideoPreviewUploader
                  label="다이나믹 키 비주얼 (640x852 권장)"
                  value={value}
                  onChange={onChange}
                />
              ) : (
                <></>
              )
            }
          /> */}
        </Card>
        <Card
          id="background-image-setting"
          cssStyle={[CardSpacingStyle, ThemeWrap]}
          header={{
            content: '배경 이미지 설정',
            prefix: <Icon icon="tree_solid" size="20px" />,
            caption: '배경 이미지는 모바일 기기의 해상도에 맞는 크기로 변환되어 보여져요'
          }}
        >
          <Controller
            control={control}
            name="backgroundImage"
            render={({ field }) => {
              const generatedControlledHandler = useMemo(
                () =>
                  generateControlledHandler({
                    ...field,
                    value: {
                      id: field.value?.userFileId || '',
                      url: field.value?.fileUrl || ''
                    }
                  }),
                [field.value?.userFileId]
              );
              return (
                <PreviewUploader
                  ratio="vertical"
                  label="게임 배경 이미지(jpg 파일)"
                  imageSrc={assetOverlayImage.wallPaper}
                  accepts={['image/jpeg']}
                  useFor="project-background"
                  {...generatedControlledHandler}
                  onChange={(files) => {
                    generatedControlledHandler.onChange(files);
                    if (files) {
                      track.onUpload({
                        uploadEventName:
                          UploadEventName.upload_editgame_theme_backgroundimage_gamebackgroundimage
                      });
                    }
                  }}
                >
                  <div css={InsertImageStyle.theme} />
                </PreviewUploader>
              );
            }}
          />
          <Controller
            control={control}
            name="missionListBackgroundImage"
            render={({ field }) => {
              const generatedControlledHandler = useMemo(
                () =>
                  generateControlledHandler({
                    ...field,
                    value: {
                      id: field.value?.userFileId || '',
                      url: field.value?.fileUrl || ''
                    }
                  }),
                [field.value?.userFileId]
              );
              return (
                <PreviewUploader
                  ratio="vertical"
                  label="미션 목록 배경 이미지(jpg 파일)"
                  accepts={['image/jpeg']}
                  imageSrc={assetOverlayImage.missionList}
                  useFor="project-background"
                  {...generatedControlledHandler}
                  onChange={(files) => {
                    generatedControlledHandler.onChange(files);
                    if (files) {
                      track.onUpload({
                        uploadEventName:
                          UploadEventName.upload_editgame_theme_backgroundimage_missionbackgroundimage
                      });
                    }
                  }}
                >
                  <div css={InsertImageStyle.cover} />
                </PreviewUploader>
              );
            }}
          />
        </Card>
        <Card
          id="default-bgm-setting"
          cssStyle={CardSpacingStyle}
          header={{
            content: '기본 BGM 설정',
            prefix: <Icon icon="music_solid" size="20px" />,
            caption: '10MB 이하의 mp3파일을 업로드해주세요'
          }}
        >
          <BGMUploader
            propsDefaultBgmFileName={defaultBgmFileName}
            propsDefaultBgmUrl={theme?.bgmUrl}
            propsDefaultIsFadeIn={theme?.isBgmFadeIn}
            propsDefaultIsFadeOut={theme?.isBgmFadeOut}
            onChangeFadeButton={({ isBgmFadeIn, isBgmFadeOut }: SelectedButtonType) => {
              setValue('isBgmFadeIn', isBgmFadeIn);
              setValue('isBgmFadeOut', isBgmFadeOut);
            }}
            onChangeUploadedBGM={(BGM) => {
              setValue('defaultBgmUrl', BGM?.url ?? undefined);
              track.onUpload({
                uploadEventName: UploadEventName.upload_editgame_theme_mainbgm
              });
            }}
          />
        </Card>
        <BottomFloatingBar onSave={onSubmit} loading={formState.isSubmitting} />
      </form>
      <Modal {...modal} />
    </>
  );
}

const FloatingNavigationBarStyle = css`
  position: fixed;
  left: 1074px;
  width: 160px;

  @media (max-width: 1159px) {
    display: none;
  }
`;

const ThemeContainer = css`
  p {
    padding: 0px;
    margin: 0px;
  }

  .font_purple {
    color: ${color.palette_primary_purple_100};
  }
`;

const ThemeWrap = css`
  & > div:last-of-type {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: ${spacing.margin.large};

    ${onMobile} {
      grid-template-columns: initial;
      grid-template-rows: repeat(2, auto);
      gap: ${spacing.margin.xlarge2};
    }
  }
`;

const CardSpacingStyle = css`
  margin-bottom: ${spacing.common.large};

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

const BackgroundImageCenter = css`
  background-position: center;
  background-size: cover;
`;

const floatingNavigationItems: FloatingNavigationProps['items'] = [
  { content: '테마 선택', fragment: 'choice-theme' },
  { content: '대표 이미지 설정', fragment: 'representative-image-setting' },
  { content: '배경 이미지 설정', fragment: 'background-image-setting' },
  {
    content: '기본 BGM 설정',
    fragment: 'default-bgm-setting',
    color: color.action_critical_default
  }
];

interface GenerateControlledHandlerProps {
  value?: UserFileResponseModel;
  onChange: (...event: any[]) => void;
}

function generateControlledHandler({ value, onChange }: GenerateControlledHandlerProps) {
  const isDefaultImage =
    value?.url === 'https://cdn.realworld.to/general-files/black.png' ||
    value?.url === 'https://cdn.realworld.to/general-files/white.png';

  return {
    onChange: (files?: UserFileResponseModel[]) =>
      onChange(
        files?.length === 1
          ? {
              userFileId: files?.[0].id,
              fileUrl: files?.[0].url
            }
          : undefined
      ),
    uploadedFiles: isDefaultImage || !value?.url ? undefined : [value]
  };
}

const exampleProjectFilesUrl =
  'https://cdn.realworld.to/project-files/ooWFLhJ1hlWRB4tZ9EFsPQ/D7ZTnAY4X1NIsduQJ7Aqbg-';
