import { Avatar } from '@uniquegood/realworld-studio-design';
import React, { memo } from 'react';

import { Flex, KeyboardInnerFocusStyle } from '@/styles';
import { SearchMemberListCardStyle } from './SearchUserList';
import { SearchUserListItemActionProps } from '../types';

interface SearchUserListItemProps extends SearchUserListItemActionProps {
  user: AccountResponseModel;
}

function SearchUserListItem({ user, onClickSearchUserCard }: SearchUserListItemProps) {
  const handler = () => onClickSearchUserCard(user);
  return (
    <li>
      <button
        type="button"
        onClick={handler}
        css={[Flex, SearchMemberListCardStyle, KeyboardInnerFocusStyle]}
      >
        <Avatar userName={user.name} source={user.profileImage ?? ''} />
      </button>
    </li>
  );
}

export default memo(SearchUserListItem);
