import { css } from '@emotion/react';
import { Image, Modal, Typography } from '@uniquegood/realworld-studio-design';
import { TextAlignCenter } from '@/styles';

import screenshot from '../../../assets/images/Image_CreatedGame.png';

interface ViewOnAppModalProps {
  toggleModal(): void;
  isOpen: boolean;
}

export default function ViewOnAppModal({ toggleModal, isOpen }: ViewOnAppModalProps) {
  return (
    <Modal
      onClose={toggleModal}
      open={isOpen}
      title="앱에서 확인하기"
      cssStyle={TextAlignCenter}
      hasHeader
      primaryAction={{
        content: '확인',
        onAction: toggleModal,
        type: 'primary'
      }}
    >
      <div css={ContentStyle}>
        <Typography type="displayMedium">
          리얼월드 앱에서 크리에이터 님의 게임을 확인해보세요
        </Typography>
        <Typography type="body">
          지금 리얼월드 앱에 로그인 하면 [ 내 게임 ➔ 만든 게임 ] 탭에서 게임을 확인할 수 있어요.
          앱을 최신 버전으로 업데이트한 후 확인해주세요.
        </Typography>
        <Image width="269.87px" source={screenshot} alt="리얼월드 앱 스크린샷" />
      </div>
    </Modal>
  );
}

const ContentStyle = css`
  text-align: center;

  > div {
    margin-bottom: 12px;
  }
`;
