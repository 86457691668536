import { css } from '@emotion/react';
import { spacing, Typography } from '@uniquegood/realworld-studio-design';
import React from 'react';

import { MarginBottom10 } from '@/styles';

interface TooltipContent {
  title: string;
  description: string;
  src: string;
}

function TooltipContent({ title, description, src }: TooltipContent) {
  return (
    <div css={Layout}>
      <Typography cssStyle={MarginBottom10} as="h6" type="subheading">
        {title}
      </Typography>
      <Typography cssStyle={MarginBottom10} as="p" type="body">
        {description}
      </Typography>
      <img css={ImageLayout} src={src} alt="툴팁 이미지" />
    </div>
  );
}

export default TooltipContent;

const Layout = css`
  width: 240px;
  max-width: 240px;
  padding-top: ${spacing.common.small};
  padding-bottom: ${spacing.common.small};
`;

const ImageLayout = css`
  width: 100%;
`;
