import { css } from '@emotion/react';
import {
  Button,
  color,
  FormGroup,
  Icon,
  Input,
  Modal,
  Popover,
  PopoverProps,
  Select,
  shadow,
  spacing,
  Tooltip,
  Typography
} from '@uniquegood/realworld-studio-design';
import { SelectOption } from '@uniquegood/realworld-studio-design/dist/types/atoms/Select/Select';
import { useParams } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';

import { coreApi } from '@/api';
import {
  AlignCenterFlex,
  DisplayNone,
  ImageCover,
  MarginBottom10,
  MarginBottom8,
  MarginRight16
} from '@/styles';
import { useChatbot, useModalState, useToggle } from '@/hooks';
import { IEditElement } from '@/components/ActionHandler/types';
import { makeFormData, checkFileSize, maxDefaultFileSize } from '@/utils';
import PopoverItemLayout from '@/components/PopoverItemLayout';
import { MultilineInputStyle } from '../../styles';

type LinkButtonKey = 'buttonLabel' | 'buttonUrl';

type LinkButtonType = { [k in LinkButtonKey]: string };

interface ChatProps extends Omit<IEditElement, 'setParams' | 'defaultValue'> {
  setCommands: React.Dispatch<React.SetStateAction<ReactionCommand[]>>;
  commandIndex: number;
}

const Chat = ({ params, setCommands, commandIndex }: ChatProps) => {
  const { items: chatbots } = useChatbot();
  const { value: popoverActive, toggle: popoverToggle } = useToggle(false);
  const { value: buttonPopoverActive, toggle: buttonPopoverToggle } = useToggle(false);

  const [botOptions, setBotOptions] = useState<SelectOption[]>([]);
  const [selectedBotId, setSelectedBotId] = useState<string>();

  const [inputValue, setInputValue] = useState<string>();
  const [imageUrl, setImageUrl] = useState<string>();
  const [linkButton, setLinkButton] = useState<LinkButtonType>({
    buttonLabel: params?.parameters?.buttonLabel,
    buttonUrl: params?.parameters?.buttonUrl
  });
  const [hasLink, setHasLink] = useState(false);

  const setChatBotOptions = (bots?: Chatbot[]) => {
    const options =
      bots?.map((bot) => ({
        label: bot.name,
        value: bot.id
      })) ?? [];

    setBotOptions(options);
  };

  const onChangeInput = (value: unknown, key: string) => {
    setCommands((prev) => {
      const copy = prev;
      copy[commandIndex].parameters[key] = value;

      return copy;
    });
  };

  const onDeletePhoto = () => {
    onChangeInput(undefined, 'photoUrl');
    setImageUrl(undefined);
  };

  const addLinkButton = () => {
    onChangeInput(linkButton.buttonLabel, 'buttonLabel');
    onChangeInput(linkButton.buttonUrl, 'buttonUrl');
    setHasLink(!!linkButton.buttonLabel || !!linkButton.buttonUrl);
  };

  const handleRemoveLinkButton = () => {
    onChangeInput('', 'buttonLabel');
    onChangeInput('', 'buttonUrl');
    resetLinkButton();
    setHasLink(false);
  };

  const resetLinkButton = () => {
    setLinkButton({ buttonLabel: '', buttonUrl: '' });
  };

  useEffect(() => {
    setChatBotOptions(chatbots);
  }, [chatbots]);

  useEffect(() => {
    if (!!params?.parameters?.buttonLabel || !!params?.parameters?.buttonUrl) {
      setHasLink(true);
    }

    setInputValue(params?.parameters?.text);
    setImageUrl(params?.parameters?.photoUrl);
    setSelectedBotId(params?.parameters?.botId);
    setLinkButton({
      buttonLabel: params?.parameters?.buttonLabel,
      buttonUrl: params?.parameters?.buttonUrl
    });
  }, [params]);

  const buttonMarkup = (params?.parameters?.buttonLabel || params?.parameters?.buttonUrl) && (
    <Button type="primary" size="small" onClick={buttonPopoverToggle}>
      {params?.parameters?.buttonLabel}
    </Button>
  );

  const imageMarkup = imageUrl && (
    <div css={ImageWrap}>
      <div css={DeleteButtonGradient}>
        <Button
          onClick={onDeletePhoto}
          cssStyle={RemoveImageButton}
          icon="times_regular"
          type="plainWhite"
          size="medium"
        />
      </div>
      <img css={ImageCover} src={imageUrl} alt="테스트용 이미지" />
    </div>
  );

  return (
    <div>
      <Select
        options={botOptions}
        onChange={(selected: string) => {
          setSelectedBotId(selected);
          onChangeInput(selected, 'botId');
        }}
        value={selectedBotId}
        cssStyle={MarginBottom8}
        placeholder="전송할 챗봇 선택"
      />

      <div css={[CustomTextArea, Gap]}>
        <Input
          multiline
          value={inputValue ?? ''}
          onChange={(value) => {
            onChangeInput(value, 'text');
            setInputValue(value);
          }}
          placeholder="메시지를 입력해주세요"
          cssStyle={MultilineInputStyle}
        />

        {imageMarkup}
        <LinkButtonForm
          activator={buttonMarkup}
          linkButton={linkButton}
          setLinkButton={setLinkButton}
          hasLink={hasLink}
          addLinkButton={addLinkButton}
          handleRemoveLinkButton={handleRemoveLinkButton}
          popoverActive={buttonPopoverActive}
          popoverToggle={buttonPopoverToggle}
        />

        <div css={BottomItemWrap}>
          <BottomImageButton setImageUrl={setImageUrl} onChangeInput={onChangeInput} />
          <LinkButtonForm
            activator={
              <Tooltip content="링크 버튼 추가">
                <Button onClick={popoverToggle} icon="link_solid" size="small" type="plain" />
              </Tooltip>
            }
            linkButton={linkButton}
            setLinkButton={setLinkButton}
            hasLink={hasLink}
            addLinkButton={addLinkButton}
            handleRemoveLinkButton={handleRemoveLinkButton}
            popoverActive={popoverActive}
            popoverToggle={popoverToggle}
          />
          <Typography type="body" textColor="subdued">
            전송 딜레이
          </Typography>
          <Input
            defaultValue={params?.parameters?.timeOffset}
            onChange={(value) => onChangeInput(parseInt(value, 10), 'timeOffset')}
            type="number"
            placeholder="0"
            suffix="초"
          />
        </div>
      </div>
    </div>
  );
};

export default Chat;

const CustomTextArea = css`
  width: 100%;
  border-radius: ${spacing.borderRadius.small};
`;

const ImageWrap = css`
  width: 160px;
  height: 160px;
  ${shadow.toast};
  position: relative;

  & > img {
    border-radius: ${spacing.borderRadius.small};
  }
`;

const DeleteButtonGradient = css`
  position: absolute;
  top: 0;
  height: 62px;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
  display: flex;
  align-items: baseline;
  justify-content: end;

  border-top-right-radius: ${spacing.common.small};
  border-top-left-radius: ${spacing.common.small};
`;

const RemoveImageButton = css`
  margin-right: ${spacing.common.xsmall};
  margin-top: ${spacing.common.xsmall};
`;

const BottomItemWrap = css`
  display: flex;
  align-items: center;
  & > :not(:last-child) {
    margin-right: ${spacing.common.small};
  }
`;

const Gap = css`
  & > :not(:last-child) {
    margin-bottom: ${spacing.common.medium};
  }
`;

interface BottomImageButtonProps {
  setImageUrl: React.Dispatch<string>;
  onChangeInput: (value: string | number, key: string) => void;
}

const BottomImageButton = ({ setImageUrl, onChangeInput }: BottomImageButtonProps) => {
  const hiddenFileInputRef = useRef<HTMLInputElement>(null);
  const { appId } = useParams<AppParam>();
  const { modal, openModal, closeModal } = useModalState();

  const handleClickImageButton = () => {
    if (hiddenFileInputRef.current) {
      hiddenFileInputRef.current.click();
    }
  };

  async function fetchUploadImage(file: File) {
    const uploadedFiles = await coreApi.post<UserFileResponseModel>(
      `/apps/${appId}/images/uploadFile`,
      makeFormData({ file }),
      { params: { useFor: 'chatbot-message' } }
    );

    return uploadedFiles;
  }

  const handleChangeFile: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.files === null) return;
    const file = event.target.files[0];

    const isOverSize = checkFileSize({ file });

    if (isOverSize) {
      openModal({
        size: 'small',
        title: '더 작은 이미지를 올려주세요',
        children: (
          <>
            <Icon
              cssStyle={MarginBottom10}
              icon="times_circle_regular"
              size="100px"
              color={color.interactive_critical_default}
            />
            {`최대 ${maxDefaultFileSize / 1024 / 1024}MB까지 업로드 할 수 있어요`}
          </>
        ),
        primaryAction: { content: '확인', type: 'primary', onAction: closeModal }
      });
    } else {
      fetchUploadImage(file).then(({ data }) => {
        setImageUrl(data.url);
        onChangeInput(data.url, 'photoUrl');
      });
    }
  };

  return (
    <>
      <Modal {...modal} />
      <Tooltip content="이미지 추가">
        <Button onClick={handleClickImageButton} icon="image_solid" size="small" type="plain" />
      </Tooltip>
      <input
        ref={hiddenFileInputRef}
        type="file"
        onChange={handleChangeFile}
        accept="image/png, image/jpeg, image/jpg"
        css={DisplayNone}
      />
    </>
  );
};

interface LinkButtonFormProps {
  hasLink: boolean;
  linkButton: LinkButtonType;
  setLinkButton: React.Dispatch<React.SetStateAction<LinkButtonType>>;
  addLinkButton: () => void;
  handleRemoveLinkButton: () => void;
  popoverActive: boolean;
  popoverToggle: () => void;
  activator: PopoverProps['activator'];
}

const LinkButtonForm = ({
  hasLink,
  linkButton,
  setLinkButton,
  handleRemoveLinkButton,
  addLinkButton,
  popoverActive,
  popoverToggle,
  activator
}: LinkButtonFormProps) => {
  const onChangeLinkButton = (text: string, key: LinkButtonKey) => {
    setLinkButton((prev) => ({ ...prev, [key]: text }));
  };

  const AddLinkButtonFormMarkup = (
    <div css={FormLayout}>
      <FormGroup label="버튼 문구" id="button-label">
        <Input
          placeholder="버튼 문구를 입력해주세요."
          defaultValue={linkButton.buttonLabel}
          onChange={(text) => onChangeLinkButton(text, 'buttonLabel')}
        />
      </FormGroup>
      <FormGroup
        label="이동할 URL"
        id="target-to-move-url"
        helpText="http:// 를 포함한 URL을 적어주세요"
      >
        <Input
          id="target-to-move-url"
          placeholder="http://www.realworld.to"
          defaultValue={linkButton.buttonUrl}
          onChange={(text) => onChangeLinkButton(text, 'buttonUrl')}
        />
      </FormGroup>

      <div css={AlignCenterFlex}>
        {hasLink && (
          <Button
            type="plainDestructive"
            size="small"
            cssStyle={MarginRight16}
            onClick={() => {
              handleRemoveLinkButton();
              popoverToggle();
            }}
          >
            삭제
          </Button>
        )}
        <Button
          type="primary"
          size="small"
          onClick={() => {
            addLinkButton();
            popoverToggle();
          }}
        >
          {hasLink ? '확인' : '추가'}
        </Button>
      </div>
    </div>
  );

  return (
    <Popover
      contentCssStyle={PopoverCustomWidth}
      active={popoverActive}
      onClose={popoverToggle}
      activator={activator}
    >
      <Popover.Section>링크 버튼 {hasLink ? '수정' : '추가'}</Popover.Section>
      <PopoverItemLayout>{AddLinkButtonFormMarkup}</PopoverItemLayout>
    </Popover>
  );
};

const PopoverCustomWidth = css`
  width: 287px;
`;

const FormLayout = css`
  & > :not(:last-child) {
    margin-bottom: ${spacing.common.medium};
  }
`;
