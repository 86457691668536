import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  FormGroup,
  Icon,
  Input,
  Typography,
  spacing,
  color,
  Modal,
  Divider,
  Select,
  Button
} from '@uniquegood/realworld-studio-design';

import { css } from '@emotion/react';

import { Controller, useForm } from 'react-hook-form';

import { Loader } from '@googlemaps/js-api-loader';
import { googleMapKey } from '@/env';
import { googleGeocodingApi } from '@/api';

import {
  ColumnFlex,
  DividerStyle,
  FlexGrow,
  Gap,
  MarginBottom24,
  MarginBottom8,
  MarginLeft8,
  MarginRight16,
  MarginRight8,
  MarginVertical24,
  RowFlex,
  TextAlignCenter
} from '@/styles';
import type { ActionParametersEditorProps } from '../ActionParametersEditor';
import { useModalState } from '@/hooks';
import Uploader from '@/components/Uploader';
import RangeInput from '@/components/RangeInput';

interface Marker {
  longitude?: number | string;
  latitude?: number | string;
  range?: number;
  input?: string;
  itemId?: string;
  beforeDiscoverMarkerImageUrl?: string;
  afterDiscoverMarkerImageUrl?: string;
}

interface LocationMapProps extends ActionParametersEditorProps {
  actionParameter: {
    markers?: Marker[];
  };
  setActionParameters: React.Dispatch<
    React.SetStateAction<
      | {
          markers?: Marker[];
        }
      | undefined
    >
  >;
}

interface LocationMapItemProps {
  marker: Marker;
  onChange: (
    setMarker: (marker: Marker) =>
      | Marker
      | (Omit<Marker, 'latitude' | 'longitude'> & {
          latitude?: number | string;
          longitude?: number | string;
        })
  ) => unknown;
  items?: GameItem[];
  renderIndex: number;
  onRemove: () => unknown;
}

const DEFAULT_MARKER: Marker = {
  latitude: undefined,
  longitude: undefined,
  input: undefined,
  itemId: undefined,
  beforeDiscoverMarkerImageUrl: undefined,
  afterDiscoverMarkerImageUrl: undefined,
  range: 20
};

type SearchBarField = { address: string };

function LocationMapItem({ marker, onChange, items, renderIndex, onRemove }: LocationMapItemProps) {
  const mapContainerRef = useRef<HTMLMapElement>(null);
  const [map, setMap] = useState<google.maps.Map>();
  const [localLatLng, setLocalLatLng] = useState<{ lat: string; lng: string }>({
    lat: marker.latitude?.toString() ?? '37.566671',
    lng: marker.longitude?.toString() ?? '126.978386'
  });

  const circleRef = useRef<google.maps.Circle | null>(null);
  const markerRef = useRef<google.maps.Marker | null>(null);

  const { control, handleSubmit } = useForm<SearchBarField>();

  const isFirstRenderRef = useRef(true);

  const { modal, openModal, closeModal } = useModalState();

  const range = marker.range ?? 20;
  const options = useMemo(() => {
    const option = {
      zoom: calculateZoomLevel(range),
      center: latLngFallback(marker)
    };
    return option;
  }, [range, marker.latitude, marker.longitude]);

  useEffect(() => {
    (async () => {
      if (mapContainerRef.current) {
        const loader = new Loader({
          apiKey: googleMapKey,
          version: 'weekly'
        });

        const google = await loader.load();

        const map = new google.maps.Map(mapContainerRef.current, {
          ...options,
          center: {
            lat: Number(options.center.lat),
            lng: Number(options.center.lng)
          },
          mapTypeControl: false,
          fullscreenControl: false
        });

        const marker = new google.maps.Marker({
          position: {
            lat: Number(options.center.lat),
            lng: Number(options.center.lng)
          },
          map
        });

        const circle = new google.maps.Circle({
          center: {
            lat: Number(options.center.lat),
            lng: Number(options.center.lng)
          },
          radius: range,
          fillColor: '#7800ff',
          strokeOpacity: 0,
          map
        });

        setMap(map);

        markerRef.current = marker;
        circleRef.current = circle;
      }
    })();
  }, [mapContainerRef.current]);

  useEffect(() => {
    if (map) {
      const centerChanged = map.addListener('center_changed', () => {
        const center = map.getCenter();
        if (!center) throw Error('runtime type error');

        markerRef.current?.setPosition(center);
        circleRef.current?.setCenter(center);
      });

      const idle = map.addListener('idle', () => {
        const center = map.getCenter();
        if (!center) throw Error('runtime type error');

        const { lat: latitude, lng: longitude } = center.toJSON();

        if (isFirstRenderRef.current) {
          isFirstRenderRef.current = false;
          return;
        }

        setLocalLatLng({
          lat: latitude.toString(),
          lng: longitude.toString()
        });
      });

      return () => {
        idle.remove();
        centerChanged.remove();
      };
    }
    return () => {
      //  Empty
    };
  }, [map]);

  useEffect(() => {
    if (circleRef.current) {
      circleRef.current.setRadius(range);
    }
  }, [range, map]);

  useEffect(() => {
    if (map) {
      map.setZoom(options.zoom);
    }
  }, [options.zoom, map]);

  useEffect(() => {
    if (map) {
      map.setCenter({
        lat: Number(options.center.lat),
        lng: Number(options.center.lng)
      });
    }
  }, [options.center.lat, options.center.lng, map]);

  useEffect(() => {
    if (localLatLng.lat && localLatLng.lng) {
      onChange((params) => ({
        ...params,
        latitude: Number(localLatLng.lat),
        longitude: Number(localLatLng.lng)
      }));
    }
  }, [localLatLng]);

  const onSubmit = handleSubmit(async ({ address }) => {
    const { data } = await googleGeocodingApi.get<GeocoderResponse>('', {
      params: { address }
    });

    if (data.status === 'OK') {
      const {
        results: [
          {
            geometry: {
              location: { lat: latitude, lng: longitude }
            }
          }
        ]
      } = data;

      onChange((params) => ({
        ...params,
        latitude: typeof latitude === 'number' ? latitude : latitude(),
        longitude: typeof longitude === 'number' ? longitude : longitude()
      }));
      return;
    }

    if (data.status === 'ZERO_RESULTS') {
      openModal({
        title: '검색 결과를 찾지 못했어요',
        cssStyle: TextAlignCenter,
        children: (
          <>
            <Icon
              color={color.interactive_critical_default}
              icon="times_circle_regular"
              size="100px"
            />
            다른 검색어를 입력해주세요
          </>
        ),
        primaryAction: { content: '확인', onAction: closeModal }
      });
      return;
    }

    throw Error('기대하지 않은 경우입니다.');
  });

  function getUploaderProps(
    imageKey: 'beforeDiscoverMarkerImageUrl' | 'afterDiscoverMarkerImageUrl'
  ) {
    const uploadedImageUrl = marker[imageKey];
    return {
      uploadedFiles: uploadedImageUrl ? [{ id: '', url: uploadedImageUrl }] : undefined,
      getUploadFiles: (files?: UserFileResponseModel[]) => {
        onChange((prevValue) => ({
          ...prevValue,
          [imageKey]: files?.length === 1 ? files[0].url : undefined
        }));
      }
    };
  }

  const beforeDiscoverMarkerImageUrlUploaderProps = useMemo(
    () => getUploaderProps('beforeDiscoverMarkerImageUrl'),
    []
  );
  const afterDiscoverMarkerImageUrlUploaderProps = useMemo(
    () => getUploaderProps('afterDiscoverMarkerImageUrl'),
    []
  );

  return (
    <>
      <div css={[ColumnFlex, renderIndex === 0 ? MarginBottom24 : MarginVertical24, Gap]}>
        <Typography as="h3" type="heading" cssStyle={MarginBottom8}>
          장소 #{renderIndex + 1}
        </Typography>
        <div css={[RowFlex]}>
          <div css={[Gap, MarginRight16]}>
            <form onSubmit={onSubmit} css={MarginBottom24}>
              <FormGroup id="address" label="목적지 검색">
                <Controller
                  name="address"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="address"
                      prefix={<Icon icon="search_solid" />}
                      placeholder="주소, 검색어 입력"
                      {...field}
                    />
                  )}
                />
              </FormGroup>
            </form>
            <FormGroup id="coordinates" label="좌표 (위도, 경도)" cssStyle={CoordinatesGroupLayout}>
              <Input
                inputMode="decimal"
                value={localLatLng.lat}
                onChange={(latitude) => {
                  if (latitude.split('').filter((item) => item === '.').length > 1) {
                    return;
                  }

                  const setValidLatitude = (latitude: string) => {
                    if (Number(latitude) > 90) {
                      return '90';
                    }
                    if (Number(latitude) < -90) {
                      return '-90';
                    }
                    return latitude;
                  };

                  setLocalLatLng((prev) => ({
                    ...prev,
                    lat: Number.isNaN(Number(latitude)) ? prev.lat : setValidLatitude(latitude)
                  }));
                }}
              />
              ,
              <Input
                inputMode="decimal"
                value={localLatLng.lng}
                onChange={(longitude) => {
                  if (longitude.split('').filter((item) => item === '.').length > 1) {
                    return;
                  }

                  const setValidLongitude = (longitude: string) => {
                    if (Number(longitude) > 180) {
                      return '180';
                    }
                    if (Number(longitude) < -180) {
                      return '-180';
                    }
                    return longitude;
                  };

                  setLocalLatLng((prev) => ({
                    ...prev,
                    lng: Number.isNaN(Number(longitude)) ? prev.lat : setValidLongitude(longitude)
                  }));
                }}
              />
            </FormGroup>
            <FormGroup id="radius" label="인식 허용 반경 (m 단위)">
              <RangeInput
                min={minRange}
                max={maxRange}
                value={range}
                onChange={(range) => {
                  onChange((params) => ({ ...params, range }));
                }}
                unit="m"
              />
            </FormGroup>
          </div>
          <map css={MapContainerLayout} ref={mapContainerRef} />
        </div>
        <div css={[RowFlex]}>
          <div css={[FlexGrow, MarginRight8]}>
            <FormGroup label="허용 반경 진입 시 입력될 텍스트">
              <Input
                inputMode="text"
                value={marker.input}
                onChange={(nextValue) => {
                  onChange((params) => ({ ...params, input: nextValue }));
                }}
                placeholder="예) 마커A"
              />
            </FormGroup>
          </div>
          <div css={[FlexGrow, MarginLeft8]}>
            <FormGroup id="address" label="진입 여부를 판단할 아이템(임시 저장 대용)">
              <Select
                options={items ? items.map((item) => ({ value: item.id, label: item.name })) : []}
                value={marker.itemId}
                onChange={(nextValue) => {
                  onChange((params) => ({ ...params, itemId: nextValue }));
                }}
                placeholder="획득할 아이템"
              />
            </FormGroup>
          </div>
        </div>
        <div css={[RowFlex]}>
          <div css={[FlexGrow, MarginRight8]}>
            <FormGroup id="before-marker" label="방문 이전 마커 이미지">
              <Uploader
                size="small"
                id="before-marker"
                ratio="square"
                useFor="before-gps-marker"
                {...beforeDiscoverMarkerImageUrlUploaderProps}
              />
            </FormGroup>
          </div>
          <div css={[FlexGrow, MarginLeft8]}>
            <FormGroup id="after-marker" label="방문 이후 마커 이미지 ">
              <Uploader
                size="small"
                id="after-marker"
                ratio="square"
                useFor="after-gps-marker"
                {...afterDiscoverMarkerImageUrlUploaderProps}
              />
            </FormGroup>
          </div>
        </div>
        <div
          css={[
            RowFlex,
            css`
              justify-content: flex-end;
            `
          ]}
        >
          <Button size="small" type="destructive" onClick={onRemove}>
            삭제하기
          </Button>
        </div>
      </div>
      <Modal {...modal} />
    </>
  );
}

export default function LocationMap({
  actionParameter,
  setActionParameters,
  items
}: LocationMapProps) {
  const handleAddMarker = React.useCallback(() => {
    setActionParameters((prevActionParameters) => {
      if (prevActionParameters === undefined) {
        return {
          markers: [DEFAULT_MARKER]
        };
      }

      return {
        ...prevActionParameters,
        markers: [...(prevActionParameters.markers ?? []), DEFAULT_MARKER]
      };
    });
  }, []);

  const handleRemoveMarker = React.useCallback(
    (index: number) => {
      setActionParameters((prevActionParameters) => {
        if (!prevActionParameters) {
          return undefined;
        }

        return {
          ...prevActionParameters,
          markers: prevActionParameters.markers
            ? prevActionParameters.markers.filter((_, i) => i !== index)
            : []
        };
      });
    },
    [setActionParameters]
  );

  const handleChangeMarker = React.useCallback(
    (setter: (nextMarker: Marker) => Marker, index: number) => {
      setActionParameters((prevActionParameters) => {
        if (!prevActionParameters) {
          console.error('GPSMap Change: actionParameter is undefined');
          return undefined;
        }

        return {
          ...prevActionParameters,
          markers: prevActionParameters.markers
            ? prevActionParameters.markers.map((marker, i) => {
                if (i !== index) {
                  return marker;
                }

                return setter(marker);
              })
            : []
        };
      });
    },
    [setActionParameters]
  );

  return (
    <>
      <div css={[ColumnFlex]}>
        {(actionParameter.markers ?? []).map((marker, index) => (
          <>
            <LocationMapItem
              // eslint-disable-next-line react/no-array-index-key
              key={`gpsmap-marker-${index}`}
              marker={marker}
              onChange={(setter) => handleChangeMarker(setter, index)}
              items={items}
              renderIndex={index}
              onRemove={() => handleRemoveMarker(index)}
            />
            {index + 1 !== actionParameter.markers?.length && (
              // eslint-disable-next-line react/no-array-index-key
              <Divider key={`gpsmap-divider-${index}`} cssStyle={DividerStyle} />
            )}
          </>
        ))}
        <Button
          type="primary"
          icon={<Icon icon="plus_solid" size="20px" />}
          size="large"
          onClick={handleAddMarker}
        >
          장소 추가하기
        </Button>
      </div>
    </>
  );
}

const minRange = 2;
const maxRange = 50;

const MapContainerLayout = css`
  border-radius: ${spacing.borderRadius.medium};
  width: 100%;
`;

const CoordinatesGroupLayout = css`
  > *:last-of-type {
    display: flex;
    align-items: flex-end;
    > *:last-of-type {
      margin-left: 4px;
    }
  }
`;

function calculateZoomLevel(radius: number) {
  if (radius < 40) return 18;
  if (radius < 160) return 16;
  if (radius < 640) return 14;
  return 12;
}

function latLngFallback({ latitude, longitude }: Marker) {
  return {
    lng: longitude ?? 126.978386,
    lat: latitude ?? 37.566671
  };
}
