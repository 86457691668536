import { css } from '@emotion/react';
import { Route, useHistory, useParams, useLocation, Switch, Redirect } from 'react-router-dom';
import React, { useEffect } from 'react';
import { color, shadow, spacing, Typography } from '@uniquegood/realworld-studio-design';

import { useRequest, useRestrictProjectRoute } from '@/hooks';
import LoadingSpinner from '@/components/LoadingSpinner';
import { onMobile } from '@/styles/responsive';
import { MissionListBar } from '@/pages/missions/components/missionList';
import { EditMissionPage, EditQuestPage } from '@/pages/missions/components/editPage';
import { FlexContentCenter, FlexGrow } from '@/styles';
import { TOP_BAR_HEIGHT } from '@/components/TopBar/Topbar';
import MissionOnboarding from './components/MissionOnboarding';
import QuestOnboarding from './components/QuestOnboarding';
import { MissionListBarMobileHeight } from './components/missionList/MissionListBar';

export default function Missions() {
  const history = useHistory();
  const { pathname } = useLocation();
  const { appId, projectId, scenarioId, missionId, questId } = useParams<AppParam>();

  const scenariosEndpoint = `/apps/${appId}/projects/${projectId}/scenarios`;
  const missionsEndpoint = `/apps/${appId}/scenarios/${scenarioId}/missions`;

  const scenariosPath = `/apps/${appId}/projects/${projectId}/scenarios`;
  const missionsPath = `/apps/${appId}/projects/${projectId}/scenarios/${scenarioId}/missions`;

  const { data: scenarios } = useRequest<Scenario[]>(scenariosEndpoint);
  const { data: missions } = useRequest<Mission[]>(missionsEndpoint);

  const redirectTo = useRestrictProjectRoute();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [missionId, questId]);

  useEffect(() => {
    if (pathname === scenariosPath && scenarios && scenarios.length > 0) {
      history.replace(`${scenariosPath}/${scenarios[0].id}`);
      return;
    }

    if (!pathname.startsWith(missionsPath) && missions?.[0]?.id) {
      history.replace(`${scenariosPath}/${scenarioId}/missions/${missions[0].id}`);
    }

    if (pathname === missionsPath && missions && missions.length > 0) {
      history.replace(`${scenariosPath}/${scenarioId}/missions/${missions[0].id}`);
    }
  }, [pathname, scenarios, missions]);

  if (!missions || redirectTo === undefined)
    return (
      <div css={FlexGrow}>
        <LoadingSpinner />
      </div>
    );

  if (typeof redirectTo === 'string') return <Redirect to={redirectTo} />;

  return (
    <>
      <div css={MissionPageLayout}>
        <MissionListBar />
        <Switch>
          <Route
            path="/apps/:appId/projects/:projectId/scenarios/:scenarioId/missions/:missionId/quests/:questId"
            component={EditQuestPage}
          />
          <Route
            path="/apps/:appId/projects/:projectId/scenarios/:scenarioId/missions/:missionId"
            component={EditMissionPage}
          />
          <Route
            path={[
              '/apps/:appId/projects/:projectId/scenarios/:scenarioId',
              '/apps/:appId/projects/:projectId/scenarios'
            ]}
            render={() => (
              <>
                {EmptyStateMarkup}
                <MissionOnboarding />
                {/* <QuestOnboarding /> */}
              </>
            )}
          />
        </Switch>
      </div>
    </>
  );
}

export const MissionContainerMarginLeft = css`
  margin-left: 240px;
`;

const MissionPageLayout = css`
  display: flex;
  flex: 1 0;

  ${onMobile} {
    display: block;
  }

  > *:not(nav) {
    flex: 1 1;

    ${MissionContainerMarginLeft}

    ${onMobile} {
      margin-left: initial;
      height: initial;
    }
  }
`;

const EmptyStateStyle = css`
  background-color: ${color.white};
  ${shadow.card};

  padding: ${spacing.padding.large};

  text-align: center;

  ${onMobile} {
    && {
      flex: initial;
      height: calc(100vh - ${MissionListBarMobileHeight} - ${TOP_BAR_HEIGHT});
    }
  }
`;

const EmptyStateEmoji = css`
  ::before {
    content: '👈';
    margin-right: 0.25em;
    ${onMobile} {
      content: '☝️';
    }
  }
`;

const EmptyStateMarkup = (
  <div css={[EmptyStateStyle, FlexContentCenter]}>
    <Typography as="p" type="displayMedium" textColor="disabled" cssStyle={EmptyStateEmoji}>
      새 미션을 만들고 게임 제작을 시작해보세요
    </Typography>
  </div>
);
