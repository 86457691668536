import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  color,
  DropZone,
  fileRejectionsType,
  Icon,
  Modal,
  spacing,
  Typography
} from '@uniquegood/realworld-studio-design';
import { useParams } from 'react-router-dom';

import { coreApi } from '@/api';
import { onMobile } from '@/styles/responsive';
import { MarginBottom10, MarginBottom16, MarginZero, TextAlignCenter } from '@/styles';
import { dropZoneMessage } from '@/messageUtils';
import { toast, makeFormData, checkFileSize, maxDefaultFileSize } from '@/utils';
import { useDidUpdateEffect, useModalState } from '@/hooks';
import type { SelectedButtonType } from '@/pages/theme/types';
import { UploadLoadingSpinner } from './Uploader';

interface DefaultValueProps {
  propsDefaultBgmFileName?: string;
  propsDefaultBgmUrl?: string;
  propsDefaultIsFadeIn?: boolean;
  propsDefaultIsFadeOut?: boolean;
}

interface BGMUploaderProps extends DefaultValueProps {
  onChangeUploadedBGM: (bgm?: FileResponseModel) => void;
  onChangeLoading?: (isLoading: boolean) => void;
  onChangeFadeButton: (selected: SelectedButtonType) => void;
  /**
   * default: false
   */
  isHideFadeButton?: boolean;
}

export default function BGMUploader({
  propsDefaultBgmFileName,
  onChangeUploadedBGM,
  onChangeLoading,
  onChangeFadeButton,
  propsDefaultBgmUrl,
  propsDefaultIsFadeIn = true,
  propsDefaultIsFadeOut = true,
  isHideFadeButton = false
}: BGMUploaderProps) {
  const { appId, projectId } = useParams<AppParam>();

  const { modal, openModal, closeModal } = useModalState();
  const [acceptedFilesLength, setAcceptedFilesLength] = useState(0);
  const [rejectedFiles, setRejectedFiles] = useState<fileRejectionsType>([]);

  const [bgmFile, setBgmFile] = useState<FileResponseModel>();
  const [defaultBgmUrl, setDefaultBgmUrl] = useState(propsDefaultBgmUrl);
  const [isBgmFadeIn, setIsBgmFadeIn] = useState(propsDefaultIsFadeIn);
  const [isBgmFadeOut, setIsBgmFadeOut] = useState(propsDefaultIsFadeOut);
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteBGM = () => {
    if (typeof defaultBgmUrl !== 'undefined') {
      setDefaultBgmUrl(undefined);
      onChangeUploadedBGM(undefined);
    }
    setBgmFile(undefined);
    // BGM 파일 업로드 -> 바로 삭제 -> 저장 클릭 시 BGM URL이 들어간 상태로 전송되는 문제가 있어 주석 처리했습니다.
    // 어떤 의도로 작성한 코드인지 몰라 일단 주석 처리해두겠습니다.
    // onChangeUploadedBGM(bgmFile);
  };

  const onChangeFile = async (files: File[]) => {
    if (files.length > 1) {
      toast({ message: '하나의 파일만 올릴 수 있어요', type: 'error' });
      return;
    }

    const isOverSize = checkFileSize({ file: files[0] });
    if (isOverSize) {
      openModal({
        title: '더 작은 이미지를 올려주세요',
        children: (
          <>
            <Icon
              cssStyle={MarginBottom10}
              icon="times_circle_regular"
              size="100px"
              color={color.interactive_critical_default}
            />
            {`최대 ${maxDefaultFileSize / 1024 / 1024}MB까지 업로드 할 수 있어요`}
          </>
        ),
        primaryAction: { content: '확인', onAction: closeModal }
      });
      return;
    }

    setIsLoading(true);

    setAcceptedFilesLength(files.length);

    try {
      const { data } = await coreApi.post<FileResponseModel>(
        `/apps/${appId}/projects/${projectId}/files`,
        makeFormData({ file: files[0], dir: '.musicfiles' })
      );
      setIsLoading(false);
      setBgmFile(data);
      onChangeUploadedBGM(data);
      onChangeFadeButton({ isBgmFadeIn: true, isBgmFadeOut: true });
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (rejectedFiles.length) {
      openModal({
        title: `${
          acceptedFilesLength > 0
            ? `형식에 맞지 않는 파일이 ${rejectedFiles.length}개 있어요`
            : '형식에 맞는 파일을 업로드해주세요'
        }`,
        cssStyle: TextAlignCenter,
        children: (
          <>
            <Icon
              color={color.interactive_critical_default}
              icon="times_circle_regular"
              size="100px"
            />
            {dropZoneMessage.mp3}
          </>
        ),
        primaryAction: { content: '확인', onAction: closeModal }
      });
    }
  }, [rejectedFiles]);

  useEffect(() => {
    if (typeof onChangeLoading === 'undefined') return;
    onChangeLoading(isLoading);
  }, [isLoading]);

  useDidUpdateEffect(() => {
    onChangeFadeButton({ isBgmFadeIn, isBgmFadeOut });
  }, [isBgmFadeIn, isBgmFadeOut]);

  if (isLoading) return <UploadLoadingSpinner isMultiple={false} />;

  return (
    <>
      <Modal {...modal} />
      {!bgmFile && !defaultBgmUrl && (
        <DropZone
          accepts={['.mp3']}
          ratio="fullWidth"
          cssStyle={FullWidth}
          onChangeFile={onChangeFile}
          notAcceptFiles={setRejectedFiles}
        />
      )}
      {(bgmFile || defaultBgmUrl) && (
        <figure css={MarginZero}>
          <Typography as="figcaption" type="button" cssStyle={MarginBottom16}>
            {((bgmFile && !bgmFile.isDirectory && bgmFile.originalName) ||
              propsDefaultBgmFileName) ??
              ''}
          </Typography>
          <audio css={[PlayerStyle, MarginBottom16]} controls src={bgmFile?.url ?? defaultBgmUrl}>
            <track kind="captions" />
            <code>audio</code>를 지원하지 않는 브라우저예요.
          </audio>
          <div css={ButtonStyle}>
            {!isHideFadeButton && (
              <ButtonGroup segmented>
                <Button
                  type={isBgmFadeIn ? 'primary' : 'outlinePrimary'}
                  pressed={isBgmFadeIn}
                  onClick={() => setIsBgmFadeIn((prev) => !prev)}
                >
                  페이드 인
                </Button>
                <Button
                  type={isBgmFadeOut ? 'primary' : 'outlinePrimary'}
                  pressed={isBgmFadeOut}
                  onClick={() => setIsBgmFadeOut((prev) => !prev)}
                >
                  페이드 아웃
                </Button>
              </ButtonGroup>
            )}
            <Button
              type="destructive"
              icon="times_light"
              cssStyle={DeleteButtonStyle}
              onClick={handleDeleteBGM}
            >
              삭제
            </Button>
          </div>
        </figure>
      )}
    </>
  );
}

const PlayerStyle = css`
  width: 100%;
  height: 52px;

  ${onMobile} {
    width: 300px;
  }
`;

const ButtonStyle = css`
  display: flex;

  ${onMobile} {
    flex-direction: column;
  }
`;

const DeleteButtonStyle = css`
  margin-left: auto;

  ${onMobile} {
    margin-top: ${spacing.common.small};
    margin-left: 0px;
    margin-right: auto;
  }
`;

const FullWidth = css`
  .DragDrop-label {
    width: 100%;
  }
`;
