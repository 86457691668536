import { css, SerializedStyles } from '@emotion/react';
import {
  color,
  Typography,
  Button,
  ButtonProps,
  spacing
} from '@uniquegood/realworld-studio-design';
import { Identity } from '@/styles';
import { itemDefaultImage, transparent } from '../assets/images';

interface ItemProps extends Partial<GameItem>, Pick<ButtonProps, 'onClick'> {
  cssStyle?: SerializedStyles;
}

export default function Item({
  iconUrl,
  detailImageUrl,
  onClick,
  name,
  description,
  cssStyle
}: ItemProps) {
  const imageUrl = iconUrl ?? detailImageUrl ?? itemDefaultImage;
  return (
    <Typography as="li" type="body" cssStyle={[WrapperStyle, cssStyle ?? Identity]}>
      <Button onClick={onClick} type="plainWhite" cssStyle={ButtonStyle}>
        <img
          alt={description}
          src={transparent}
          css={[
            ImageStyle,
            css`
              background-image: url(${imageUrl});
            `
          ]}
        />
      </Button>
      {/* Hack: 텍스트 위에 요소 하나가 있어야 ellipsis 스타일을 보여줌 */}
      <div />
      {name}
    </Typography>
  );
}

const WrapperStyle = css`
  text-align: center;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ButtonStyle = css`
  border: 1px solid ${color.border_default_subdued};
  border-radius: 8px;
  padding: 8px;

  margin-bottom: ${spacing.margin.xsmall};

  > span > span {
    display: flex;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const ImageStyle = css`
  width: 100%;
  height: calc(100% - 20px);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;
