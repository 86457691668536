import { HackleFeature } from '@hackler/react-sdk';
import React from 'react';

interface FeatureProviderProps {
  children: (isFeatureOn: boolean) => React.ReactNode;
  feature: number;
}

export function FeatureProvider({ children, feature }: FeatureProviderProps) {
  return (
    <HackleFeature featureKey={feature}>{(isFeatureOn) => children(isFeatureOn)}</HackleFeature>
  );
}
