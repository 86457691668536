import useRequestImmutable from './useRequestImmutable';

export default function useIsAdminSelector() {
  const { data, ...rest } = useRequestImmutable<MyInfo>('/api/me');

  return {
    data: data && data.role === 'RealWorld.Roles.Admin',
    ...rest
  };
}
