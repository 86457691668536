/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Button, Typography } from '@uniquegood/realworld-studio-design';

const CustomizeHeaderStyle = css`
  width: 100%;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.07);
`;

const CustomizeHeaderContentsStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding: 0 24px;
`;

interface Props {
  onClick: () => void;
}

const CustomizeHeader = ({ onClick }: Props) => {
  return (
    <div css={CustomizeHeaderStyle}>
      <div css={CustomizeHeaderContentsStyle}>
        <Typography type="heading">커스터마이징</Typography>
        <Button type="primary" icon="check_solid" onClick={() => onClick()}>
          완료
        </Button>
      </div>
    </div>
  );
};

export default CustomizeHeader;
