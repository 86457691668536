import { css, SerializedStyles } from '@emotion/react';
import { color, spacing } from '@uniquegood/realworld-studio-design';
import { useParams } from 'react-router-dom';
import { AlignCenterFlex, ColumnFlex } from '@/styles';

import { useRequest } from '@/hooks';
import { previewOverlayImage } from '../assets';
import { defaultCoverImage } from '../const';

interface PhoneMockUpProps {
  cssStyle?: SerializedStyles | SerializedStyles[];
  type: 'setting' | 'content';
}

export default function PhoneMockUp({
  cssStyle,
  children,
  type
}: React.PropsWithChildren<PhoneMockUpProps>) {
  const { appId, projectId } = useParams<AppParam>();
  const { data: themeResponse } = useRequest<CommonResponseModel<ProjectThemeResponseModel>>(
    `/apps/${appId}/projects/${projectId}/theme`,
    undefined,
    {
      headers: {
        'x-rwd-api-version': '1.1'
      }
    }
  );

  const theme = (themeResponse?.data?.theme.toLowerCase() as ProjectThemeEnum) ?? 'light';

  const backgroundImage =
    (type === 'setting'
      ? themeResponse?.data.missionListBackgroundImage?.fileUrl
      : themeResponse?.data.backgroundImage?.fileUrl) ?? defaultCoverImage[theme];

  const backgroundStyle = css`
    background-image: url(${previewOverlayImage[theme][type]})
      ${backgroundImage ? `, url(${backgroundImage})` : ''};

    clip-path: path(
      'M37 1C17.67 1 2 16.67 2 36V110.063C1.13739 110.285 0.5 111.068 0.5 112V142C0.5 142.932 1.13739 143.715 2 143.937V154.268C1.4022 154.613 1 155.26 1 156V187C1 187.74 1.4022 188.387 2 188.732V433C2 452.33 17.67 468 37 468H195C214.33 468 230 452.33 230 433V175.732C230.598 175.387 231 174.74 231 174V123C231 122.26 230.598 121.613 230 121.268V36C230 16.67 214.33 1 195 1H37Z'
    );
  `;

  const themeStyle = css`
    background-color: ${theme === 'light'
      ? color.palette_gray_tintz_2
      : color.palette_gray_shade_03};

    &&,
    #title {
      color: ${theme === 'light' ? 'black' : 'white'};
    }
  `;

  return (
    <div css={[ColumnFlex, AlignCenterFlex, WrapperStyle, backgroundStyle, themeStyle, cssStyle]}>
      {children}
    </div>
  );
}

const WrapperStyle = css`
  width: 233px;
  height: 470px;

  background-repeat: no-repeat;
  background-size: cover;

  padding: 60px ${spacing.padding.medium} ${spacing.padding.medium};
`;
