import { useEffect, useMemo } from 'react';
import { useAsync } from 'react-use';
import { useLocation } from 'react-router-dom';
import { color, Icon, Modal, textStyleBody } from '@uniquegood/realworld-studio-design';
import { studioApi } from '@/api';
import LoadingSpinner from '@/components/LoadingSpinner';
import { toast } from '@/utils';
import { useModalState } from '@/hooks';
import { keepAll, MarginBottom10, TextAlignCenter } from '@/styles';

export default function VerifyEmail() {
  const location = useLocation();

  const token = useMemo(() => new URLSearchParams(location.search).get('token'), [location.search]);
  const state = useAsync(() => studioApi.get(`/auth/verifyEmail`, { params: { token } }), [token]);

  const { modal, openModal } = useModalState();

  useEffect(() => {
    if (state.error) {
      toast({ type: 'error', message: state.error.message });
      return;
    }

    if (!state.loading) {
      openModal({
        title: '메일이 인증되었어요',
        // TODO: 배열 타입도 넣을 수 있게 타입 정의 수정하기
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        cssStyle: [TextAlignCenter, keepAll, textStyleBody],
        primaryAction: {
          content: '확인',
          onAction: window.close
        },
        children: (
          <>
            <Icon
              cssStyle={MarginBottom10}
              icon="check_circle_regular"
              size="100px"
              color={color.interactive_primary_subdued}
            />
            이메일 주소가 성공적으로 인증되었어요! 이제 이 창을 닫고 인증 페이지로 돌아가 주세요.
          </>
        )
      });
    }
  }, [state]);

  if (state.loading) return <LoadingSpinner />;

  return <Modal {...modal} />;
}
