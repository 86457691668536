import { useParams } from 'react-router-dom';
import { useRequest } from '@/hooks';

export default function useChatbot() {
  const { appId, projectId, chatbotId } = useParams<AppParam>();

  const {
    data: items,
    isValidating,
    mutate
  } = useRequest<Chatbot[]>(`/apps/${appId}/projects/${projectId}/chatbots`);

  const itemIndex = items?.findIndex((chatbot) => chatbot.id === chatbotId);
  const item = items && typeof itemIndex === 'number' ? items[itemIndex] : undefined;

  return { items, item, itemIndex, isValidating, mutate };
}
