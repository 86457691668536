import first from './image_step 1.png';
import second from './image_step 2.png';
import third from '@/assets/images/helpImage.png';

import inventoryPreview from './inventoryPreview.png';
import itemDefaultImage from './item_default_image.png';
import transparent from './transparent.png';

export const steps = { first, second, third };

export { inventoryPreview, itemDefaultImage, transparent };
