import { css } from '@emotion/react';
import {
  Badge,
  Button,
  color,
  Icon,
  Input,
  MediaCard,
  Modal,
  shadow,
  spacing,
  textStyleButton,
  Typography
} from '@uniquegood/realworld-studio-design';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';

import CreateProject from '@pages/home/sections/components/CreateProject';
import { onMobile } from '@/styles/responsive';
import {
  AlignCenterFlex,
  CardShadowStyle,
  ColumnFlex,
  JustifyCenterFlex,
  KeyboardFocusStyle,
  MarginTop10,
  MarginZero,
  TextAlignCenter
} from '@/styles';
import {
  useDebounce,
  useDidUpdateEffect,
  useIsAdminSelector,
  useIsOwner,
  useModalState,
  useRequest,
  useUser
} from '@/hooks';
import { keyPressHandler, toast, badgeProps } from '@/utils';
import LoadingSpinner from '@/components/LoadingSpinner';
import { ClickEventName, InputEventName, track } from '@/track';
import { FeatureProvider } from '@/providers/Feature';
import { FeatureFlag } from '@/feature';
import { STATION_URL } from '@/env';
import { authApiWithToken } from '@/apis/auth';

function ProjectList() {
  const { appId } = useParams<AppParam>();

  const { modal, openModal, closeModal } = useModalState();
  const { data: user } = useUser();

  const { data: isAdmin } = useIsAdminSelector();
  const { data: isOwner } = useIsOwner();

  const checkPermissionForCreateProject = React.useCallback(
    (event: React.MouseEvent) => {
      if (user?.level !== 'Owner') {
        event.preventDefault();
        openPermissionModal();

        return false;
      }

      return true;
    },
    [user, openPermissionModal]
  );

  const axiosConfig = {
    baseUrl: 'https://realworld.to',
    headers: {
      'X-Rwd-Api-Version': '1.1',
      from: 'home'
    }
  };

  const { data: projects, error } = useRequest<CommonResponseModel<Project[]>>(
    `/apps/${appId}/projects`,
    {},
    axiosConfig
  );

  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce(searchQuery, 200);

  useDidUpdateEffect(() => {
    setSearchQuery('');
  }, [appId]);

  const projectsWithQuery = useMemo(() => {
    if (!projects) return undefined;

    const filtered = projects?.data.filter((project) =>
      project.name.toLowerCase().includes(debouncedSearchQuery.trimStart().trimEnd().toLowerCase())
    );

    return filtered;
  }, [projects, debouncedSearchQuery, appId]);

  useEffect(() => {
    if (projects?.data.length === 0 && isOwner) {
      toast({ message: '어서오세요! 이제 첫번째 게임을 만들어보세요.' });
    }
  }, [projects, isOwner]);

  if (error?.response?.status === 404) {
    const defaultChannelId = localStorage.getItem('defaultChannelID');

    if (appId === defaultChannelId) {
      localStorage.removeItem('defaultChannelID');
    }

    return <Redirect to="/apps" />;
  }

  if (!projects || !projectsWithQuery) return <LoadingSpinner />;

  function checkPermissionForEnterProject<T extends React.MouseEvent | React.KeyboardEvent>(
    event: T
  ) {
    if (user?.level === 'Tester' && !isAdmin) {
      event.preventDefault();
      openPermissionModal();
      return;
    }
    if (event.target instanceof HTMLAnchorElement) {
      event.target.click();
    }
  }

  function openPermissionModal() {
    openModal({
      // TODO: 배열 타입도 넣을 수 있게 타입 정의 수정하기
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      cssStyle: [TextAlignCenter, ColumnFlex, JustifyCenterFlex],
      title: '현재 등급에서는 접근할 수 없어요',
      primaryAction: {
        content: '확인',
        onAction: closeModal
      },
      children: (
        <>
          <Icon
            icon="times_circle_regular"
            size="100px"
            color={color.interactive_critical_default}
          />
          <Typography as="p" type="body" cssStyle={[MarginZero, MarginTop10, TextAlignCenter]}>
            접근 권한을 얻기 위해서는 채널 소유자에게 문의해주세요.
          </Typography>
          <Button
            url="https://rwd.to/realworldstudio-manual-channel#d79783942c4e44b9ba1e727ddf4cc5f3"
            external
          >
            등급별 권한 정보 보기
          </Button>
        </>
      )
    });
  }

  return (
    <>
      <div css={ChannelListInfoWrap}>
        <Typography as="h4" type="displaySmall" textColor="initial">
          전체 게임 {projects?.data.length}개
        </Typography>
        <Input
          cssStyle={[CardShadowStyle, SearchInputStyle, SearchInputPos]}
          placeholder="게임 이름으로 검색"
          prefix={<Icon size="20px" icon="search_solid" />}
          onChange={(value) => {
            setSearchQuery(value);
            track.onInputThrottled({
              inputEventName: InputEventName.input_channel_searchgames
            });
          }}
          value={searchQuery}
        />
      </div>
      <section css={MediaCardContainerStyle}>
        <FeatureProvider feature={FeatureFlag.V2_GAME}>
          {(isFeature) => {
            if (isFeature) {
              return (
                <>
                  <CreateProject
                    checkPermissionForCreateProject={checkPermissionForCreateProject}
                  />

                  {projectsWithQuery.map((project) => (
                    <MediaCard
                      key={project.id}
                      linkURL={
                        project.projectEngineVersion === 'V2'
                          ? `#`
                          : `/apps/${appId}/projects/${project.id}/dashboard`
                      }
                      onKeyDown={keyPressHandler(checkPermissionForEnterProject)}
                      onClick={
                        project.projectEngineVersion === 'V2'
                          ? async function () {
                              await authApiWithToken.apiAuthLoginPost();
                              window.location.href = `https://${STATION_URL}/${appId}/game/${project.id}`;
                            }
                          : checkPermissionForEnterProject
                      }
                      badge={
                        project.projectEngineVersion === 'V2' ? (
                          <div
                            style={{
                              display: 'flex',
                              gap: 4,
                              alignItems: 'top',
                              justifyContent: 'space-between'
                            }}
                          >
                            <Badge {...badgeProps[project.status]} />
                            <div css={v2IconWrap}>
                              <Icon icon="play_flow_solid" size="24px" color="#fff" />
                            </div>
                          </div>
                        ) : (
                          <Badge {...badgeProps[project.status]} />
                        )
                      }
                      source={project.horizontalImageUrl}
                      title={project.name}
                      summary={project.description}
                    />
                  ))}
                </>
              );
            }

            return (
              <>
                <Link
                  css={[
                    LinkTagStyle,
                    MakeGameContainerStyle,
                    projects?.data.length > 0 && ResetAspectRatio,
                    KeyboardFocusStyle
                  ]}
                  onClick={(...args) => {
                    checkPermissionForCreateProject(...args);
                    track.onClick({
                      clickEventName: ClickEventName.click_channel_button_creategame
                    });
                  }}
                  to={`/apps/${appId}/projects/create`}
                >
                  <Icon icon="gamepad_solid" cssStyle={IconGamePadStyle} />
                  <p css={[textStyleButton, GamePadTextStyle]}>새 게임 만들기</p>
                </Link>

                {projectsWithQuery
                  .filter((project) => project.projectEngineVersion !== 'V2')
                  .map((project) => (
                    <MediaCard
                      key={project.id}
                      linkURL={`/apps/${appId}/projects/${project.id}/dashboard`}
                      onKeyDown={keyPressHandler(checkPermissionForEnterProject)}
                      onClick={checkPermissionForEnterProject}
                      badge={<Badge {...badgeProps[project.status]} />}
                      source={project.horizontalImageUrl}
                      title={project.name}
                      summary={project.description}
                    />
                  ))}
              </>
            );
          }}
        </FeatureProvider>
        <Modal {...modal} />
      </section>
    </>
  );
}

export default memo(ProjectList);

const SearchInputPos = css`
  margin-left: auto;

  ${onMobile} {
    margin-left: initial;
    margin-top: ${spacing.margin.large};
  }
`;

const SearchInputStyle = css`
  min-width: 300px;

  ${onMobile} {
    min-width: 1px;
  }
`;

const ChannelListInfoWrap = css`
  ${AlignCenterFlex};
  margin-bottom: 24px;

  ${onMobile} {
    ${ColumnFlex};

    margin-bottom: 20px;
  }
`;

const MediaCardContainerStyle = css`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-auto-rows: minmax(min-content, 340px);

  @supports (aspect-ratio: 310 / 340) {
    grid-auto-rows: initial;
    > a,
    button {
      aspect-ratio: 310 / 340;
    }
  }

  gap: ${spacing.margin.large};

  ${onMobile} {
    display: block;

    > a,
    button {
      aspect-ratio: initial;
    }

    > button,
    *:not(:last-of-type) {
      margin-bottom: ${spacing.margin.large};
    }
  }
`;

const LinkTagStyle = css`
  display: block;

  ${onMobile} {
    width: initial;
    height: 140px;
  }
`;

const MakeGameContainerStyle = css`
  border-radius: ${spacing.borderRadius.xlarge};
  border: 1px solid ${color.border_default_subdued};
  background-color: ${color.surface_default_default};
  ${shadow.card};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const MakeLegacyGameContainerStyle = css`
  box-shadow: none;
  border: none;
  background-color: transparent;
`;

const ResetAspectRatio = css`
  aspect-ratio: initial;
`;

const IconGamePadStyle = css`
  width: 150px;
  height: auto;
  margin: 0px;

  ${onMobile} {
    width: 56px;
    height: auto;
  }
`;

const GamePadTextStyle = css`
  margin: 0;
  color: ${color.text_subdued};
  margin-top: -${spacing.common.large};

  ${onMobile} {
    margin-top: 0px;
  }
`;

const v2IconWrap = css`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${color.interactive_primary_default};
`;
