import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormGroup, Icon, Input, spacing, color, Modal } from '@uniquegood/realworld-studio-design';

import { css } from '@emotion/react';

import { Controller, useForm } from 'react-hook-form';

import { Loader } from '@googlemaps/js-api-loader';
import { googleMapKey } from '@/env';
import { googleGeocodingApi } from '@/api';

import {
  Gap,
  JustifySpaceBetween,
  MarginBottom24,
  MarginRight16,
  MarginVertical24,
  RowFlex,
  TextAlignCenter
} from '@/styles';
import type { ActionParametersEditorProps } from '../ActionParametersEditor';
import { useModalState } from '@/hooks';
import RangeInput from '@/components/RangeInput';

interface LocationProps extends ActionParametersEditorProps {
  actionParameter: {
    latitude?: number;
    longitude?: number;
    radius?: number;
  };
}

type SearchBarField = { address: string };

export default function Location({ actionParameter, setActionParameters }: LocationProps) {
  const mapContainerRef = useRef<HTMLMapElement>(null);
  const [map, setMap] = useState<google.maps.Map>();
  const [localLatLng, setLocalLatLng] = useState<{ lat: string; lng: string }>({
    lat: actionParameter.latitude?.toString() ?? '37.566671',
    lng: actionParameter.longitude?.toString() ?? '126.978386'
  });

  const circleRef = useRef<google.maps.Circle | null>(null);
  const markerRef = useRef<google.maps.Marker | null>(null);

  const { control, handleSubmit } = useForm<SearchBarField>();

  const isFirstRenderRef = useRef(true);

  const { modal, openModal, closeModal } = useModalState();

  const radius = actionParameter.radius ?? 20;
  const options = useMemo(() => {
    const option = {
      zoom: calculateZoomLevel(radius),
      center: latLngFallback(actionParameter)
    };

    return option;
  }, [radius, actionParameter.latitude, actionParameter.longitude]);

  useEffect(() => {
    (async () => {
      if (mapContainerRef.current) {
        const loader = new Loader({
          apiKey: googleMapKey,
          version: 'weekly'
        });

        const google = await loader.load();

        const map = new google.maps.Map(mapContainerRef.current, {
          ...options,
          mapTypeControl: false,
          fullscreenControl: false
        });

        const marker = new google.maps.Marker({
          position: options.center,
          map
        });

        const circle = new google.maps.Circle({
          center: options.center,
          radius,
          fillColor: '#7800ff',
          strokeOpacity: 0,
          map
        });

        setMap(map);

        markerRef.current = marker;
        circleRef.current = circle;
      }
    })();
  }, [mapContainerRef.current]);

  useEffect(() => {
    if (map) {
      const centerChanged = map.addListener('center_changed', () => {
        const center = map.getCenter();
        if (!center) throw Error('runtime type error');

        markerRef.current?.setPosition(center);
        circleRef.current?.setCenter(center);
      });

      const idle = map.addListener('idle', () => {
        const center = map.getCenter();
        if (!center) throw Error('runtime type error');

        const { lat: latitude, lng: longitude } = center.toJSON();

        if (isFirstRenderRef.current) {
          isFirstRenderRef.current = false;
          return;
        }

        setLocalLatLng({
          lat: latitude.toString(),
          lng: longitude.toString()
        });
      });

      return () => {
        idle.remove();
        centerChanged.remove();
      };
    }
    return () => {
      //  Empty
    };
  }, [map]);

  useEffect(() => {
    if (circleRef.current) {
      circleRef.current.setRadius(radius);
    }
  }, [radius, map]);

  useEffect(() => {
    if (map) {
      map.setZoom(options.zoom);
    }
  }, [options.zoom, map]);

  useEffect(() => {
    if (map) {
      map.setCenter(options.center);
    }
  }, [options.center.lat, options.center.lng, map]);

  useEffect(() => {
    if (localLatLng.lat && localLatLng.lng) {
      setActionParameters((params) => ({
        ...params,
        latitude: Number(localLatLng.lat),
        longitude: Number(localLatLng.lng)
      }));
    }
  }, [localLatLng]);

  const onSubmit = handleSubmit(async ({ address }) => {
    const { data } = await googleGeocodingApi.get<GeocoderResponse>('', {
      params: { address }
    });

    if (data.status === 'OK') {
      const {
        results: [
          {
            geometry: {
              location: { lat: latitude, lng: longitude }
            }
          }
        ]
      } = data;
      setActionParameters((params) => ({ ...params, latitude, longitude }));
      return;
    }

    if (data.status === 'ZERO_RESULTS') {
      openModal({
        title: '검색 결과를 찾지 못했어요',
        cssStyle: TextAlignCenter,
        children: (
          <>
            <Icon
              color={color.interactive_critical_default}
              icon="times_circle_regular"
              size="100px"
            />
            다른 검색어를 입력해주세요
          </>
        ),
        primaryAction: { content: '확인', onAction: closeModal }
      });
      return;
    }

    throw Error('기대하지 않은 경우입니다.');
  });

  return (
    <section css={[RowFlex, JustifySpaceBetween, MarginVertical24]}>
      <div css={[Gap, MarginRight16]}>
        <form onSubmit={onSubmit} css={MarginBottom24}>
          <FormGroup id="address" label="목적지 검색">
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <Input
                  id="address"
                  prefix={<Icon icon="search_solid" />}
                  placeholder="주소, 검색어 입력"
                  {...field}
                />
              )}
            />
          </FormGroup>
        </form>
        <FormGroup id="coordinates" label="좌표 (위도, 경도)" cssStyle={CoordinatesGroupLayout}>
          <Input
            inputMode="decimal"
            value={localLatLng.lat}
            onChange={(latitude) => {
              if (latitude.split('').filter((item) => item === '.').length > 1) {
                return;
              }

              const setValidLatitude = (latitude: string) => {
                if (Number(latitude) > 90) {
                  return '90';
                }
                if (Number(latitude) < -90) {
                  return '-90';
                }
                return latitude;
              };

              setLocalLatLng((prev) => ({
                ...prev,
                lat: Number.isNaN(Number(latitude)) ? prev.lat : setValidLatitude(latitude)
              }));
            }}
          />
          ,
          <Input
            inputMode="decimal"
            value={localLatLng.lng}
            onChange={(longitude) => {
              if (longitude.split('').filter((item) => item === '.').length > 1) {
                return;
              }

              const setValidLongitude = (Longitude: string) => {
                if (Number(Longitude) > 180) {
                  return '180';
                }
                if (Number(Longitude) < -180) {
                  return '-180';
                }
                return Longitude;
              };

              setLocalLatLng((prev) => ({
                ...prev,
                lng: Number.isNaN(Number(longitude)) ? prev.lng : setValidLongitude(longitude)
              }));
            }}
          />
        </FormGroup>
        <FormGroup id="radius" label="인식 허용 반경 (m 단위)">
          <RangeInput
            min={minRange}
            max={maxRange}
            value={radius}
            onChange={(radius) => {
              setActionParameters((params) => ({ ...params, radius }));
            }}
            unit="m"
          />
        </FormGroup>
      </div>
      <map css={MapContainerLayout} ref={mapContainerRef} />
      <Modal {...modal} />
    </section>
  );
}

const minRange = 10;
const maxRange = 50;

const MapContainerLayout = css`
  border-radius: ${spacing.borderRadius.medium};
  width: 100%;
`;

const CoordinatesGroupLayout = css`
  > *:last-of-type {
    display: flex;
    align-items: flex-end;
    > *:last-of-type {
      margin-left: 4px;
    }
  }
`;

function calculateZoomLevel(radius: number) {
  if (radius < 40) return 18;
  if (radius < 160) return 16;
  if (radius < 640) return 14;
  return 12;
}

function latLngFallback({ latitude, longitude }: LocationProps['actionParameter']) {
  return {
    lng: longitude ?? 126.978386,
    lat: latitude ?? 37.566671
  };
}
