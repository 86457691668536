export const APP_ENV = (() => {
  if (import.meta.env.VITE_APP_ENV === 'production') return 'production';
  return 'development';
})();

export const isProduction = APP_ENV === 'production';
export const isDev = APP_ENV === 'development';

const { VITE_UNSPLASH_ACCESS_KEY, VITE_GOOGLE_MAP_KEY } = import.meta.env;

if (!VITE_UNSPLASH_ACCESS_KEY || typeof VITE_GOOGLE_MAP_KEY !== 'string')
  throw new Error('필요한 환경 변수가 할당되지 않았습니다');

export const unsplashAccessKey = VITE_UNSPLASH_ACCESS_KEY;
export const googleMapKey = VITE_GOOGLE_MAP_KEY;

export const hackleKey = isProduction
  ? 'CLPnPYltSDcVGcVAsNrIOMMeK49vJIB4'
  : 'DaQ3SZK32pERirsvzbLY0yNyhlD7GkIk';

export const VITE_SENTRY_RELEASE = import.meta.env.VITE_SENTRY_RELEASE as string | undefined;

export const STATION_URL = isProduction ? 'station.realworld.to' : 'station-test.realworld.to';

export const TOSSPAYMENT_KEY = isProduction
  ? 'live_ck_4vZnjEJeQVxXRxOmBLYVPmOoBN0k'
  : 'test_ck_mnRQoOaPz8LbQP6WpJeVy47BMw6v';

export const ACCOUNT_API_HOST = isProduction
  ? 'api.account.realworld.to'
  : 'api-test.account.realworld.to';
