import { useLayoutEffect, useState } from 'react';

import { breakpoint as mobile } from '@/styles/responsive';
import { useWindowSize } from '@/hooks';

export default function useIsMobile(breakpoint = mobile) {
  const { width } = useWindowSize();

  const [isMobile, setIsMobile] = useState(false);

  useLayoutEffect(() => {
    if (width <= breakpoint) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  return isMobile;
}
