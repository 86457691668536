/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import { Button } from '@uniquegood/realworld-studio-design';
import React from 'react';
import { loadTossPayments, TossPaymentsInstance } from '@tosspayments/payment-sdk';

import { useHistory, useParams } from 'react-router-dom';
import { css } from '@emotion/react';
import PaymentMethodModal from '@pages/payment/MethodModal';
import { useAsyncFn } from 'react-use';
import { MembershipBillingDataResponseResponse } from '@uniquegood/realworld-pay-interface/dist/model';
import { StudioMembershipProductResponseResponse } from '@uniquegood/realworld-pay-interface/dist/model/studio-membership-product-response-response';
import { useIsMobile } from '@/hooks';
import { TOSSPAYMENT_KEY } from '@/env';
import {
  membershipApiWithToken,
  membershipCardsApiWithToken,
  membershipProductApiWithToken
} from '@/apis/pay';

function Payment() {
  const history = useHistory();
  const isMobile = useIsMobile();
  const tossPaymentsRef = React.useRef<TossPaymentsInstance>();
  const { appId } = useParams<AppParam>();
  const selectedMembership = React.useRef<string>();
  const [isOpenMethodModal, setIsOpenMethodModal] = React.useState(false);

  const [getMethodListState, doGetMethodList] = useAsyncFn(async () => {
    if (!appId) return [];
    const { data: cardData } = await membershipCardsApiWithToken.getCustomerCardsAsync(appId);
    const cardList = cardData as MembershipBillingDataResponseResponse;

    return cardList.data;
  }, [appId]);

  const handleCloseMethodModal = React.useCallback(() => {
    setIsOpenMethodModal(false);
  }, []);

  React.useEffect(() => {
    (async () => {
      tossPaymentsRef.current = await loadTossPayments(TOSSPAYMENT_KEY);
    })();
  }, []);

  const handleClickPay = React.useCallback(
    async (productId: string) => {
      if (!appId) return;

      selectedMembership.current = productId;
      await doGetMethodList();
      setIsOpenMethodModal(true);
    },
    [appId]
  );

  const handleClickAddMethod = React.useCallback(async () => {
    if (!tossPaymentsRef.current || !appId) return;

    tossPaymentsRef.current.requestBillingAuth('카드', {
      customerKey: appId,
      successUrl: `${window.location.origin}/apps/${appId}/payment/success?productId=${selectedMembership.current}&returnUrl=${history.location.pathname}`,
      failUrl: `${window.location.origin}/apps/${appId}/payment/fail?productId=${selectedMembership.current}&returnUrl=${history.location.pathname}`
    });
  }, [appId, history]);

  const [selectMethodState, doSelectMethod] = useAsyncFn(
    async (billingId: string) => {
      if (!appId) return;

      try {
        const { data: productData } =
          await membershipProductApiWithToken.getAvailableMembershipProductAsync();
        const products = productData as StudioMembershipProductResponseResponse;

        const product = products.data.find(
          (product) =>
            product.id === selectedMembership.current &&
            product.membershipProductType === 'Subscription'
        );

        if (!product) throw new Error('잘못된 접근입니다.');

        await membershipApiWithToken.submitOrderAsync({
          studioProductIds: [product.id!],
          billingId,
          applicationId: appId,
          calculatedPrice: product.price!
        });

        alert('결제가 완료되었습니다.');
      } catch (e) {
        alert('결제가 실패하였습니다. 다시 시도해주세요.');
      }
    },
    [appId]
  );

  const [isOpenModal, setIsOpenModal] = React.useState(false);
  const [ratePlan, setRatePlan] = React.useState<'normal' | 'business'>('normal');

  const handleClickOpenModal = React.useCallback(() => {
    setIsOpenModal(true);
  }, []);

  const handleClickCloseModal = React.useCallback(() => {
    setIsOpenModal(false);
  }, []);

  return (
    <>
      {isOpenModal && (
        <div css={modalContainer} onClick={handleClickCloseModal}>
          <div onClick={(e) => e.stopPropagation()} css={modalWrap}>
            {getMethodListState.value && isOpenMethodModal && (
              <PaymentMethodModal
                cardList={getMethodListState.value}
                onClose={handleCloseMethodModal}
                selectMethod={doSelectMethod}
                addMethodAndBilling={handleClickAddMethod}
              />
            )}
            <div css={ratePlanTitle}>요금제 설명</div>
            {selectMethodState.loading ? (
              <div css={loadingWrap}>결제중...</div>
            ) : (
              <>
                <div css={ratePlanTypeSelectorContainer}>
                  <div
                    css={ratePlanTypeNormalSelector}
                    className={ratePlan === 'normal' ? 'active' : undefined}
                    onClick={() => setRatePlan('normal')}
                  >
                    일반
                  </div>
                  <div
                    css={ratePlanTypeBusinessSelector}
                    className={ratePlan === 'business' ? 'active' : undefined}
                    onClick={() => setRatePlan('business')}
                  >
                    비즈니스
                  </div>
                </div>
                <div css={ratePlanListContainer}>
                  {ratePlan === 'normal' && (
                    <>
                      <div css={ratePlanItemContainer}>
                        <div css={ratePlanItemTitle}>무료</div>
                        <ul css={ratePlanItemDescribeContainer}>
                          <li>- 직관적이고 편리한 제작 환경</li>
                          <li>- 자유도가 높고 강력한 로직 기능</li>
                          <li>- 만든 콘텐츠 자유롭게 배포 가능</li>
                          <li>- 플레이어와 소통할 수 있는 크리에이터 홈</li>
                          <li>- 콘텐츠로 수익 창출 가능</li>
                          <li>- 기본적인 일별 플레이 현황 분석 제공</li>
                          <li>- 데이터 분석 제공 기간* 2주</li>
                          <li>- 최근 수정 내역* 1일 보관</li>
                          <li>- AR, GPS 등 특수 기능 사용 가능</li>
                        </ul>
                        <div css={ratePlanItemFooter}>
                          <div css={ratePlanItemFooterButton} className="active">
                            현재 요금제
                          </div>
                        </div>
                      </div>
                      <div
                        css={ratePlanItemContainer}
                        style={{
                          borderColor: '#7800ff'
                        }}
                      >
                        <div
                          css={ratePlanItemTitle}
                          style={{
                            color: '#7800ff'
                          }}
                        >
                          파트너
                        </div>
                        <ul css={ratePlanItemDescribeContainer}>
                          <li>
                            - <b>프리 플랜</b>의 모든 혜택 +
                          </li>
                          <li>- 플레이 당 더 많은 수익 창출</li>
                          <li>- 파트너 크리에이터 뱃지 노출</li>
                          <li>- 심화 기능 교육 영상 제공</li>
                          <li>- 사용자 행동 분석 지원</li>
                          <li>- 행동 분석, 플로우, 체류 시간, 입력값 등</li>
                          <li>- 데이터 분석 제공 기간 30일</li>
                          <li>- 최근 수정 내역 3일 보관</li>
                        </ul>
                        <div css={ratePlanItemFooterPrice}>월 ₩14,900 / 연 ₩149,000</div>
                        <div css={ratePlanItemFooterPriceSub}>(vat 포함)</div>
                        <div css={ratePlanItemFooter}>
                          <div
                            css={ratePlanItemFooterButton}
                            onClick={() => handleClickPay('977b544f-2d39-4db5-bc84-a73e71c2c031')}
                          >
                            월간결제
                          </div>
                          <div
                            css={ratePlanItemFooterButton}
                            onClick={() => handleClickPay('977b544f-2d39-4db5-bc84-a73e71c2c034')}
                          >
                            연간결제
                          </div>
                        </div>
                      </div>
                      <div
                        css={ratePlanItemContainer}
                        style={{
                          borderColor: '#1f2993'
                        }}
                      >
                        <div
                          css={ratePlanItemTitle}
                          style={{
                            color: '#1f2993'
                          }}
                        >
                          프로
                        </div>
                        <ul css={ratePlanItemDescribeContainer}>
                          <li>
                            - <b>파트너 플랜</b>의 모든 혜택 +
                          </li>
                          <li>- 광고 없는 무료 게임 출시 가능</li>
                          <li>- (시작 스크린 자체 로고 노출 {'->'} 혜택에서 제외)</li>
                          <li>- 외부 사이트 링크 삽입 가능</li>
                          <li>- 데이터 분석 제공 기간 90일</li>
                          <li>- 최근 수정 내역 7일 보관</li>
                        </ul>
                        <div css={ratePlanItemFooterPrice}>월 ₩49,000 / 연 ₩490,000</div>
                        <div css={ratePlanItemFooterPriceSub}>(vat 포함)</div>
                        <div css={ratePlanItemFooter}>
                          <div
                            css={ratePlanItemFooterButton}
                            onClick={() => handleClickPay('977b544f-2d39-4db5-bc84-a73e71c2c032')}
                          >
                            월간결제
                          </div>
                          <div
                            css={ratePlanItemFooterButton}
                            onClick={() => handleClickPay('977b544f-2d39-4db5-bc84-a73e71c2c035')}
                          >
                            연간결제
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {ratePlan === 'business' && (
                    <>
                      <div
                        css={ratePlanItemContainer}
                        style={{
                          borderColor: '#68c022'
                        }}
                      >
                        <div
                          css={ratePlanItemTitle}
                          style={{
                            color: '#68c022'
                          }}
                        >
                          이벤트/투어 콘텐츠 플랜
                        </div>
                        <ul css={ratePlanItemDescribeContainer}>
                          <li>
                            - <b>프로 플랜</b>의 모든 혜택 +
                          </li>
                          <li>- 데이터 다운로드 지원</li>
                          <li>- 플레이어의 지리적 정보 제공(접속 지역)</li>
                          <li>- 콘텐츠 유입 경로 분석 제공</li>
                          <li>- 플레이 현황 월별 / 시간별 데이터 확인 기능</li>
                          <li>- 데이터 분석 제공 기간 180일</li>
                          <li>- 최근 수정 내역 30일 보관</li>
                          <li>- 비즈니스 특화 템플릿 게임 제공(축제, 행사용)</li>
                        </ul>
                        <div css={ratePlanItemFooterPrice}>월 200,000 / 연 ₩2,000,000</div>
                        <div css={ratePlanItemFooterPriceSub}>(vat 포함)</div>
                        <div css={ratePlanItemFooter}>
                          <div
                            css={ratePlanItemFooterButton}
                            onClick={() => handleClickPay('977b544f-2d39-4db5-bc84-a73e71c2c033')}
                          >
                            월간결제
                          </div>
                          <div
                            css={ratePlanItemFooterButton}
                            onClick={() => handleClickPay('977b544f-2d39-4db5-bc84-a73e71c2c036')}
                          >
                            연간결제
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}

            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                fontSize: '12px',
                padding: '0 10px'
              }}
            >
              <div>
                1달은 30일, 1년은 365일로 계산됩니다.
                <br /> 위 상품의 최대 이용기간은 1년입니다.
              </div>
              <div
                style={{
                  color: '#7800ff',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  window.open(
                    'https://uniquegoodcompany.notion.site/2023-07-b69c85b7a0974abb8df0d3bba10ccfe8#b08d8a4f6bee4330a31638723331d4d6'
                  );
                }}
              >
                환불 규정 안내 {'>'}
              </div>
            </div>
          </div>
        </div>
      )}
      <Button
        type="secondaryBlue"
        size={isMobile ? 'medium' : 'large'}
        onClick={handleClickOpenModal}
      >
        요금제 결제하기
      </Button>
    </>
  );
}

export default Payment;

const modalContainer = css`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;

  align-items: center;
`;

const modalWrap = css`
  width: min(800px, 90vw);
  height: min(550px, 90vh);
  background-color: white;
  border-radius: 10px;
  padding: 16px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;

const ratePlanTitle = css`
  font-size: 24px;
  font-weight: bold;
`;

const ratePlanTypeSelectorContainer = css`
  display: flex;
  gap: 5px;
`;

const ratePlanTypeNormalSelector = css`
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;

  &:hover {
    color: #ffffff;
    background-color: #7800ff;
  }

  &.active {
    color: #ffffff;
    background-color: #7800ff;
  }
`;

const ratePlanTypeBusinessSelector = css`
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;

  &:hover {
    color: #ffffff;
    background-color: #519b1b;
  }

  &.active {
    color: #ffffff;
    background-color: #519b1b;
  }
`;

const ratePlanListContainer = css`
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
`;

const ratePlanItemContainer = css`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 2px solid #d9d9d9;
  border-radius: 5px;
  width: 240px;
  height: 350px;
  gap: 10px;
`;

const ratePlanItemTitle = css`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #707070;
`;

const ratePlanItemDescribeContainer = css`
  font-size: 12px;
  flex-grow: 1;
`;

const ratePlanItemFooter = css`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const ratePlanItemFooterButton = css`
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid #d9d9d9;

  &:hover {
    background-color: #d9d9d9;
  }

  &.active {
    border: 1px solid #b9b9b9;
    background-color: #b9b9b9;
    cursor: default;
  }
`;

const ratePlanItemFooterPrice = css`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
`;

const ratePlanItemFooterPriceSub = css`
  margin-top: -10px;
  font-size: 12px;
  text-align: center;
  color: #b9b9b9;
`;

const loadingWrap = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
