import React, { memo } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { css } from '@emotion/react';
import {
  color,
  Icon,
  iconSize,
  shadow,
  spacing,
  textStyleButton
} from '@uniquegood/realworld-studio-design';

import { useHistory, useParams } from 'react-router-dom';
import { ButtonResetStyle, KeyboardFocusStyle, textShortening } from '@/styles';
import { keyPressHandler } from '@/utils';

interface QuestButtonProps {
  index: number;
  isHidden: boolean;
  isLocked: boolean;
  selected: boolean;
  mission: Mission;
  quest: Quest;
}

function QuestButton({ selected, index, isHidden, isLocked, mission, quest }: QuestButtonProps) {
  const { appId, projectId, scenarioId } = useParams<AppParam>();
  const history = useHistory();
  const questEndpoint = `/apps/${appId}/projects/${projectId}/scenarios/${scenarioId}/missions/${mission.id}/quests/${quest.id}`;

  const goToEditPage = () => {
    history.push(questEndpoint);
  };

  const iconColor = selected ? color.icon_white : undefined;

  return (
    <Draggable draggableId={String(quest.id)} index={index} key={quest.id}>
      {(provided) => (
        <li ref={provided.innerRef} {...provided.draggableProps}>
          <button
            type="button"
            tabIndex={selected ? -1 : 0}
            aria-pressed={selected}
            key={quest.id}
            css={[
              ButtonResetStyle,
              QuestButtonStyle,
              textStyleButton,
              textShortening,
              selected && SelectedStyle,
              KeyboardFocusStyle
            ]}
            onClick={goToEditPage}
            onTouchStart={goToEditPage}
            onKeyDown={keyPressHandler(goToEditPage)}
          >
            <div
              tabIndex={-1}
              draggable
              className="draggableItem"
              {...provided.dragHandleProps}
              css={EllipsisStyle}
            >
              <Icon icon="ellipsis_v_regular" color={iconColor} size={iconSize.small} />
            </div>
            {quest.label ?? quest.name}
            {(isHidden || isLocked) && (
              <div css={StateIconStyle} className="hoverShow">
                {isHidden && (
                  <Icon icon="eye_slash_solid" color={iconColor} size={iconSize.small} />
                )}
                {isLocked && <Icon icon="lock_solid" color={iconColor} size={iconSize.small} />}
              </div>
            )}
          </button>
        </li>
      )}
    </Draggable>
  );
}

export default memo(QuestButton);

const EllipsisStyle = css`
  position: absolute;
  display: flex;
  top: 0;
  left: 0;

  width: 48px;
  border-radius: 0px 18px 18px 0px;
  transform: rotate(-180deg);
`;

const StateIconStyle = css`
  width: 71px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0px 18px 18px 0px;

  & > span {
    margin: 0;
    height: 100%;
  }

  & > span:first-of-type {
    margin-right: ${spacing.common.xsmall};
  }
  & > span:last-of-type {
    margin-right: ${spacing.common.small};
  }
`;

const QuestButtonStyle = css`
  position: relative;
  margin-top: ${spacing.common.small};
  padding: ${spacing.common.small} 12px;
  border-radius: ${spacing.common.medium2};
  background-color: ${color.surface_default_default};
  color: ${color.text_default};
  width: 100%;
  text-align: left;
  ${shadow.card};

  &:hover {
    background-color: ${color.surface_default_hovered};

    .draggableItem,
    .hoverShow {
      background: linear-gradient(270deg, #f4f4f4 59.86%, rgba(244, 244, 244, 0) 87.5%);
      border-radius: 0px 18px 18px 0px;
      visibility: visible;
    }
  }

  &:active {
    box-shadow: 0 0 0 4px ${color.focused_default};

    .draggableItem,
    .hoverShow {
      background: linear-gradient(270deg, #f4f4f4 59.86%, rgba(244, 244, 244, 0) 87.5%);
      border-radius: 0px 18px 18px 0px;
      visibility: visible;
    }
  }

  .draggableItem,
  .hoverShow {
    transition: none;
    visibility: hidden;
    height: 100%;

    &:hover {
      background-color: transparent;
    }
  }
`;

const SelectedStyle = css`
  background-color: ${color.action_primary_default};
  box-shadow: 0 0 0 4px ${color.focused_default};
  color: ${color.text_white};

  .hoverShow {
    background: linear-gradient(270deg, #7800ff 59.86%, rgba(200, 105, 255, 0) 87.5%);
    visibility: visible;
  }

  &:hover {
    background-color: ${color.action_primary_hovered};

    .draggableItem,
    .hoverShow {
      background: linear-gradient(270deg, #6500d6 59.86%, rgba(170, 89, 217, 0) 87.5%);
      border-radius: 0px 18px 18px 0px;
    }
  }

  &:active {
    background-color: ${color.action_primary_pressed};

    .draggableItem,
    .hoverShow {
      background: linear-gradient(270deg, #6500d6 59.86%, rgba(140, 74, 179, 0) 87.5%);
      border-radius: 0px 18px 18px 0px;
      visibility: visible;
    }
  }
`;
