/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { RequestRequireProps } from '../types';
import PreviewArea from './CustomizeWebview/PreviewArea/PreviewArea';
import CustomizeArea from './CustomizeWebview/CustomizeArea/CustomizeArea';
import CustomWithTextarea from './CustomizeWebview/CustomizeArea/CustomWithTextarea';
import useDidUpdateEffect from '../hooks/useDidUpdateEffect';

type EditedParams = Record<string, string | string[] | boolean>;

export type ActionParameterContent = {
  templateId: string | null;
  editedParams: EditedParams;
  content: string;
} | null;

interface Props extends Exclude<ActionParameterContent, null>, RequestRequireProps {
  onGoBack(): void;
  onComplete: () => void;
  updateData(changedData: ActionParameterContent): void;
}

export interface Metadata {
  label: string;
  parameterDefinitions: ParameterDefinition[];
  icon: string;
}

export interface ParameterDefinition {
  label: string;
  key: string;
  type: string;
  description?: string;
  placeholder?: string;
  defaultValue: string;
  elementDefinitions?: elementDefinition[];
}

export interface elementDefinition {
  label: string;
  key: string;
  type: string;
  description: string;
  defaultValue: any;
}

const WebviewCustomize = ({
  onGoBack,
  onComplete,
  updateData,
  templateId,
  editedParams,
  content,
  ...requestRequireProps
}: Props) => {
  type MetadataAndTemplate = {
    id: string;
    name: string;
    metadata: Metadata[];
    template: string;
  };

  const debounceIdRef = useRef<number | null>(null);

  const [metadataAndTemplate, setMetadataAndTemplate] = useState<MetadataAndTemplate | null>(null);

  const [params, setParams] = useState(editedParams);

  const [existingWebviewCode, setExistingWebviewCode] = useState(content);

  function updateParams(newParams: EditedParams) {
    if (debounceIdRef.current) {
      clearTimeout(debounceIdRef.current);
    }

    debounceIdRef.current = window.setTimeout(() => {
      return setParams({
        ...params,
        ...newParams
      });
    }, 300);
  }

  function updateExistingWebviewCode(value: string) {
    setExistingWebviewCode(value);
  }

  let filledTemplate = metadataAndTemplate?.template
    ? metadataAndTemplate?.template
    : existingWebviewCode;

  if (metadataAndTemplate != null) {
    for (let i = 0; i < metadataAndTemplate.metadata.length; i += 1) {
      for (let j = 0; j < metadataAndTemplate.metadata[i].parameterDefinitions.length; j += 1) {
        fillTemplate(i, j);
      }
    }
  }

  function fillTemplate(i: number, j: number) {
    if (metadataAndTemplate === null) {
      return;
    }
    const parameterDefinitions = metadataAndTemplate.metadata[i].parameterDefinitions[j];

    // template에 key값을 채워줄 것임

    // data에 해당하는 key값이 없으면 parameterDefinitions.defaultValue로 바꿔줌
    if (params[parameterDefinitions.key] === undefined) {
      if (typeof parameterDefinitions.defaultValue === 'string') {
        filledTemplate = filledTemplate.replace(
          `{${parameterDefinitions.key}}`,
          `${parameterDefinitions.defaultValue}`
        );
      } else {
        filledTemplate = filledTemplate.replace(
          `{${parameterDefinitions.key}}`,
          JSON.stringify(parameterDefinitions.defaultValue)
        );
      }
      return;
    }

    // data에 해당하는 key값이 있으면 params[parameterDefinitions.key]로 바꿔줌
    if (typeof params[parameterDefinitions.key] === 'string') {
      filledTemplate = filledTemplate.replace(
        `{${parameterDefinitions.key}}`,
        `${params[parameterDefinitions.key]}`
      );
    } else {
      filledTemplate = filledTemplate.replace(
        `{${parameterDefinitions.key}}`,
        JSON.stringify(params[parameterDefinitions.key])
      );
    }
  }

  useEffect(() => {
    async function getMetadataAndTemplate() {
      if (templateId === null) {
        setMetadataAndTemplate(null);
        return;
      }

      try {
        const res = await axios.get(`/api/webviews/${templateId}`, {
          headers: { Authorization: requestRequireProps.token },
          baseURL: requestRequireProps.studioApiEndPoint
        });
        setMetadataAndTemplate(res.data);
      } catch (err) {
        console.error(err);
      }
    }
    getMetadataAndTemplate();
  }, []);

  useDidUpdateEffect(() => {
    updateData({
      templateId,
      editedParams: params,
      content: filledTemplate
    });
  }, [params, filledTemplate]);

  return (
    <div
      css={css`
        display: flex;
        height: 690px;

        > div {
          width: 549px;
        }

        margin-left: auto;
        margin-right: auto;

        @media only screen and (max-width: 768px) {
          padding: 0;
          margin-left: auto;
          margin-right: auto;
        }
      `}
    >
      <PreviewArea
        filledTemplate={filledTemplate}
        onGoBack={onGoBack}
        templateName={metadataAndTemplate?.name ?? '[직접 만들기]'}
      />
      {templateId && metadataAndTemplate && (
        <CustomizeArea
          onComplete={onComplete}
          editedParams={editedParams}
          metadata={metadataAndTemplate?.metadata}
          onChange={updateParams}
          {...requestRequireProps}
        />
      )}
      {!templateId && (
        <CustomWithTextarea
          onComplete={onComplete}
          existingWebviewCode={existingWebviewCode}
          onChange={updateExistingWebviewCode}
        />
      )}
    </div>
  );
};

export default WebviewCustomize;
