import Quill from 'quill';

const fontSizes = [
  '0.55em',
  '0.61em',
  '0.66em',
  '0.75em',
  '0.77em',
  '0.83em',
  '0.88em',
  '0.94em',
  false,
  '1.05em',
  '1.17em',
  '1.5em',
  '1.75em',
  '2em',
  '2.25em',
  '2.5em',
  '2.75em',
  '3em',
  '3.75em',
  '4em',
  '5em'
];

const SizeStyle = Quill.import('attributors/style/size');
SizeStyle.whitelist = fontSizes;

Quill.register(SizeStyle, true);

export { SizeStyle, fontSizes };
