import { css } from '@emotion/react';
import { Button, Icon, Input } from '@uniquegood/realworld-studio-design';
import React from 'react';
import { Address, useDaumPostcodePopup } from 'react-daum-postcode';
import { useFormContext } from 'react-hook-form';
import { googleGeocodingApi } from '@/api';
import { changeSigunguCodeToBefore } from '../utils';

type SearchAddressProps = {
  type: 'start' | 'end';
  defaultAddress?: string;
  defaultDetailAddress?: string;
  onChange?: (...e: unknown[]) => unknown;
  isError?: boolean;
};

export default function SearchAddress({
  type,
  defaultAddress,
  defaultDetailAddress,
  onChange,
  isError
}: SearchAddressProps) {
  const { setValue } = useFormContext<ProjectFormData>();
  const openPostcodePopup = useDaumPostcodePopup();

  const [selectedAddressInfo, setSelectedAddressInfo] = React.useState<string>(
    defaultAddress || ''
  );

  const handleComplete = async (data: Address) => {
    const { data: geocodingResponse } = await googleGeocodingApi.get<GeocoderResponse>('', {
      params: { address: data.roadAddress }
    });

    if (geocodingResponse.status === 'OK') {
      const {
        results: [
          {
            geometry: {
              location: { lat: latitude, lng: longitude }
            }
          }
        ]
      } = geocodingResponse;

      setValue(`offlineInfo.${type}Latitude`, Number(latitude));
      setValue(`offlineInfo.${type}Longitude`, Number(longitude));
      setValue(`offlineInfo.${type}Address`, `${data.roadAddress}`);
      setValue(`offlineInfo.cityCountyCode`, `Kr${changeSigunguCodeToBefore(data.sigunguCode)}`);

      setSelectedAddressInfo(data.address);

      onChange?.(data.roadAddress);
    }
  };

  const handleDetailChange = (value: string) => {
    if (type === 'start') {
      setValue(`offlineInfo.startAddressDetail`, value || undefined);
    } else if (type === 'end') {
      setValue(`offlineInfo.endDetailAddress`, value || undefined);
    }
  };

  return (
    <>
      <div css={searchInputStyle}>
        <Input
          prefix={<Icon icon="search_solid" />}
          cssStyle={css`
            flex-grow: 1;
          `}
          value={selectedAddressInfo}
          error={isError}
          readOnly
        />
        <Button type="primary" onClick={() => openPostcodePopup({ onComplete: handleComplete })}>
          주소 검색
        </Button>
      </div>
      <Input
        defaultValue={defaultDetailAddress}
        onChange={handleDetailChange}
        placeholder="장소or건물명을 적어주세요. / 예) 북서울 꿈의숲, 메리히어 1층"
      />
    </>
  );
}

const searchInputStyle = css`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 8px;
`;
