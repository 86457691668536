import { color, Icon, Modal } from '@uniquegood/realworld-studio-design';
import React from 'react';
import { TextAlignCenter } from '@/styles';

interface FailImageModalProps {
  closeFailImageModal: () => void;
  isFailImageModal: boolean;
  title: string;
  content: string;
}

const FailImageModal = ({
  closeFailImageModal,
  isFailImageModal,
  title,
  content
}: FailImageModalProps) => {
  return (
    <Modal
      cssStyle={TextAlignCenter}
      onClose={closeFailImageModal}
      open={isFailImageModal}
      title={title}
      primaryAction={{
        content: '확인',
        onAction: closeFailImageModal
      }}
    >
      <Icon icon="times_circle_regular" size="100px" color={color.interactive_critical_default} />
      {content}
    </Modal>
  );
};

export default FailImageModal;
