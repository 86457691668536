import { ButtonGroup, Button } from '@uniquegood/realworld-studio-design';
import React, { useEffect } from 'react';

import { IEditElement, sourceOption } from '@/components/ActionHandler/types';

interface ISegmentedItemProps extends Omit<IEditElement, 'placeholder' | 'params' | 'setParams'> {
  value?: string;
  setValue(value: string): void;
  options?: sourceOption[];
}

const SegmentedItem = ({
  options,
  value,
  setValue,
  defaultValue,
  cssStyle
}: ISegmentedItemProps) => {
  useEffect(() => {
    if (!value && defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const renderOptions = (option: sourceOption): React.ReactNode => {
    const isSelected = (value ?? defaultValue) === option.value;

    return (
      <Button
        key={option.value}
        size={option?.size ?? 'large'}
        type={isSelected ? 'primary' : 'outlinePrimary'}
        pressed={isSelected}
        onClick={() => {
          setValue(option.value);
        }}
        disabled={option?.disabled}
      >
        {option.label}
      </Button>
    );
  };

  return (
    <ButtonGroup segmented cssStyle={cssStyle}>
      {options?.map(renderOptions)}
    </ButtonGroup>
  );
};

export default SegmentedItem;
