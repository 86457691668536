import React, { useEffect } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { useParamsWithMatchPath, useRequestImmutable } from '@/hooks';

import { FlexGrow } from '@/styles';
import LoadingSpinner from './LoadingSpinner';
import { logout, studioApi } from '@/api';
import { OAUTH_LOGIN_URI } from '@/auth';

export default function RestrictRoute({
  children,
  ...rest
}: Omit<RouteProps, 'render' | 'component'>) {
  const { data: onboardingStepStatus, error: onboardingStepStatusError } =
    useRequestImmutable<OnboardingStepStatus>('/api/creators/me', {}, {}, studioApi);
  const { data: apps } = useRequestImmutable<RealWorldApp[]>('/apps');
  const { data: me } = useRequestImmutable<MyInfo>('/api/me');

  const isAdmin = me && me.role === 'RealWorld.Roles.Admin';

  const { appId } = useParamsWithMatchPath();
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    if (onboardingStepStatusError) {
      if (onboardingStepStatusError.response?.status === 404) {
        (async () => {
          await logout();
          window.location.href = OAUTH_LOGIN_URI;
        })();
      }
    }
  }, [onboardingStepStatusError]);

  useEffect(() => {
    if (ChannelIO && me) {
      ChannelIO('boot', {
        pluginKey: 'd7a0ab75-47ba-410e-b6b8-7cc506a4d702',
        memberId: me.id,
        profile: {
          name: me.name,
          email: me.email,
          source: '스튜디오'
        },
        hideChannelButtonOnBoot: true
      });
    }
  }, [me]);

  return (
    <Route
      {...rest}
      render={() => {
        if (!onboardingStepStatus || !apps || isAdmin === undefined) return loadingSpinner;

        if (!onboardingStepStatus.termsAgreed)
          return pathname.startsWith('/agreeTerms') ? children : <Redirect to="/agreeTerms" />;

        if (
          (!onboardingStepStatus.phoneVerified ||
            !onboardingStepStatus.emailVerified ||
            !onboardingStepStatus.identityVerified) &&
          me?.role !== 'RealWorld.Roles.Admin'
        )
          return pathname.startsWith('/verifyContact') ? (
            children
          ) : (
            <Redirect to="/verifyContact" />
          );

        const allowNavigate =
          appId && (isAdmin || apps.findIndex((app) => app.id === appId) !== -1);
        if (allowNavigate || pathname.startsWith('/createApp')) return children;

        return (
          <Redirect to={apps.length > 0 ? `/apps/${getDefaultChannelId(apps)}` : '/createApp'} />
        );
      }}
    />
  );
}

const loadingSpinner = (
  <div css={FlexGrow}>
    <LoadingSpinner />
  </div>
);

function getDefaultChannelId(apps: RealWorldApp[]) {
  const defaultChannelId = localStorage.getItem('defaultChannelID');
  const channelId =
    defaultChannelId && apps.some((app) => app.id === defaultChannelId)
      ? defaultChannelId
      : apps[0].id;

  if (!defaultChannelId) {
    localStorage.setItem('defaultChannelID', channelId);
  }

  return channelId;
}
