import {
  Avatar,
  Button,
  color,
  Input,
  Select,
  spacing,
  textStyleBody,
  toast,
  ToggleButton
} from '@uniquegood/realworld-studio-design';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { css } from '@emotion/react';
import { coreApi } from '@/api';
import { ColumnFlex, Flex, Margin24, MarginLeft16 } from '@/styles';
import type { RefetchComments } from '../types';
import { useRequestImmutable } from '@/hooks';
import LoadingSpinner from '@/components/LoadingSpinner';

type WriteCommentProps = {
  refetchComments: RefetchComments;
} & (
  | ({
      type: 'new';
      parentId?: undefined;
    } & ({ isAdminMode: true; personas?: undefined } | { isAdminMode: false; personas: Persona[] }))
  | ({
      type: 'reply';
      parentId: string;
    } & ({ isAdminMode: true; personas?: undefined } | { isAdminMode: false; personas: Persona[] }))
);

interface FormData {
  content: string;
  hasSpoilers: boolean;
}

export default function WriteComment({
  isAdminMode,
  personas,
  parentId,
  refetchComments,
  type
}: WriteCommentProps) {
  const { appId, projectId } = useParams<AppParam>();
  const { data: me } = useRequestImmutable<MyInfo>('/api/me');

  const [selectedPersonaId, setSelectedPersonaId] = useState(personas && personas[0].id);
  const { handleSubmit, control, reset, formState, setValue } = useForm<FormData>();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const selectedPersona =
    personas && (personas.find((persona) => persona.id === selectedPersonaId) ?? personas[0]);
  const options = personas?.map((persona) => ({ value: persona.id, label: persona.name }));

  const onSubmit = handleSubmit(async (data) => {
    const lastRoute = parentId ? `/${parentId}/replies` : '';

    let newComment: Comment;

    if (isAdminMode) {
      const response = await coreApi.post<CommonResponseModel<Comment>>(
        `/api/projects/${projectId}/comments${lastRoute}`,
        data
      );

      newComment = response.data.data;
    } else {
      const formData = {
        ...data,
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        personaId: selectedPersona!.id,
        overrideName: selectedPersona!.name,
        overrideProfileImageUrl: selectedPersona!.profileImageUrl,
        isPersona: selectedPersonaId !== me?.id
        /* eslint-enable @typescript-eslint/no-non-null-assertion */
      };

      const response = await coreApi.post<CommonResponseModel<Comment>>(
        `/apps/${appId}/projects/${projectId}/comments${lastRoute}`,
        formData,
        {
          headers: {
            'x-rwd-api-version': '1.1'
          }
        }
      );
      newComment = response.data.data;
    }

    await refetchComments((items) => {
      if (items === undefined) throw Error('기대하지 않은 경우입니다.');

      if (type === 'new') {
        return [[newComment], ...items];
      }

      for (const comments of items) {
        for (const comment of comments) {
          if (comment.id === parentId) {
            if (Array.isArray(comment.children)) {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              comment.children!.push(newComment);
            } else {
              // eslint-disable-next-line no-param-reassign
              comment.children = [newComment];
            }
            break;
          }
        }
      }
      return items;
    });

    toast({ type: 'default', message: '게시 완료!' });

    reset({ hasSpoilers: false });
    // Hack: content 리셋이 안되어서 추가함
    setValue('content', '', { shouldDirty: false });
  });

  if (me === undefined) return <LoadingSpinner />;

  return (
    <form onSubmit={onSubmit} css={[ColumnFlex, Margin24, FormLayout]}>
      <header css={Flex}>
        {/* TODO: 60px  */}
        {isAdminMode ? (
          <Avatar
            source={me.profileImage}
            userName={me.name}
            size="xLarge"
            cssStyle={AvatarStyle}
          />
        ) : (
          <>
            <Avatar
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              source={selectedPersona!.profileImageUrl}
              size="xLarge"
              cssStyle={AvatarStyle}
            />
            <div css={ColumnFlex}>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="select-persona" css={textStyleBody}>
                페르소나 선택
              </label>
              <Select
                cssStyle={SelectStyle}
                id="select-persona"
                options={options}
                value={selectedPersonaId}
                onChange={setSelectedPersonaId}
              />
            </div>
          </>
        )}
      </header>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <Controller
        control={control}
        name="content"
        rules={{ required: true }}
        render={({ field }) => <Input {...field} multiline cssStyle={TextAreaStyle} />}
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <footer css={FooterLayout}>
        <Controller
          control={control}
          name="hasSpoilers"
          defaultValue={false}
          render={({ field: { value, onChange } }) => (
            <ToggleButton onChange={onChange} checked={value}>
              스포일러
            </ToggleButton>
          )}
        />
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          cssStyle={MarginLeft16}
          loading={formState.isSubmitting}
        >
          게시
        </Button>
      </footer>
    </form>
  );
}

const AvatarStyle = css`
  > div > span {
    width: 60px;
    height: 60px;
  }
  margin-right: ${spacing.margin.large};
`;

const SelectStyle = css`
  width: 212px;
`;

const TextAreaStyle = css`
  textarea {
    min-height: 116px;
    max-height: 116px;
  }
  margin: ${spacing.margin.large} 0;
`;

const FooterLayout = css`
  align-self: flex-end;
`;

const FormLayout = css`
  &:not(:first-of-type) {
    border-top: 1px solid ${color.border_default_subdued};
  }

  padding-top: ${spacing.margin.xlarge2};

  &:first-of-type {
    margin-top: 0;
  }
`;
