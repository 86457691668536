import {
  Button,
  color,
  Modal,
  shadow,
  spacing,
  Tooltip,
  Typography
} from '@uniquegood/realworld-studio-design';
import React from 'react';
import { useParams, useHistory, Link, LinkProps } from 'react-router-dom';
import { css } from '@emotion/react';
import { useForm } from 'react-hook-form';

import {
  CardShadowStyle,
  HoverStyle,
  MarginBottom4,
  MarginRight8,
  RowFlex,
  textShortening
} from '@/styles';
import { onMobile } from '@/styles/responsive';

import { useDidUpdateEffect, useModalState } from '@/hooks';
import { toast } from '@/utils';
import { coreApi } from '@/api';
import useChatbot from '../hooks/useChatbot';

import ChangeSceneOrder from './ChangeSceneOrder';

import SceneModalContent, { FormData } from './SceneModalContent';

export default function SceneListContainer() {
  return (
    <section>
      <SceneListTitle />
      <SceneList />
    </section>
  );
}

function SceneListTitle() {
  const { modal, openModal, closeModal } = useModalState();
  const { item } = useChatbot();

  function onClick() {
    openModal({
      title: '장면 순서 변경',
      size: 'medium',
      children: <ChangeSceneOrder />,
      primaryAction: {
        content: '닫기',
        type: 'basic',
        onAction: closeModal
      }
    });
  }

  const disabledModal = item && item.scenes.length < 2;

  return (
    <>
      <header css={RowFlex}>
        <Typography as="h2" type="displaySmall" css={MarginRight8}>
          장면 목록
        </Typography>
        <Tooltip preferredPosition="above" content="순서변경" dismissOnMouseOut>
          <Button
            disabled={disabledModal}
            icon="exchange_alt_solid"
            size="medium"
            onClick={onClick}
          />
        </Tooltip>
      </header>
      <Modal {...modal} />
    </>
  );
}

function SceneList() {
  const { item } = useChatbot();
  const { appId, projectId, chatbotId, sceneId } = useParams<AppParam>();
  return (
    <div css={ListLayout} tabIndex={-1}>
      {item?.scenes.map((scene) => {
        const selected = scene.id === sceneId;

        return (
          <SceneItem
            key={scene.id}
            name={scene.name}
            description={scene.description}
            selected={selected}
            to={`/apps/${appId}/projects/${projectId}/chatbots/${chatbotId}/scenes/${scene.id}`}
          />
        );
      })}
      <NewSceneButton />
    </div>
  );
}

const ListLayout = css`
  display: flex;
  padding: ${spacing.margin.large} 0;
  overflow-y: auto;

  > * {
    margin-right: ${spacing.margin.small};
    flex-shrink: 0;
  }

  ${onMobile} {
    overflow-y: auto;
  }
`;

interface SceneItemProps extends Pick<LinkProps, 'to'> {
  name: string;
  description: string;
  selected: boolean;
}

function SceneItem({ name, description, selected, to }: SceneItemProps) {
  const content = (
    <>
      <Typography as="dt" type="heading" cssStyle={[MarginBottom4, textShortening]}>
        {name}
      </Typography>
      <Typography
        as="dd"
        type="caption"
        textColor="subdued"
        cssStyle={[textShortening, SceneItemDescriptionStyle]}
      >
        {description}
      </Typography>
    </>
  );

  return selected ? (
    <dl role="listitem" css={[SceneItemLayout, SceneItemSelected]}>
      {content}
    </dl>
  ) : (
    <Link role="listitem" to={to} css={[SceneItemLayout, HoverStyle, SceneItemKeyBoardFocus]}>
      <dl>{content}</dl>
    </Link>
  );
}

function NewSceneButton() {
  const { appId, projectId, chatbotId } = useParams<AppParam>();

  const { modal, openModal, closeModal } = useModalState();
  const { mutate } = useChatbot();

  const { control, handleSubmit, formState, reset } = useForm<FormData>();

  const history = useHistory();

  useDidUpdateEffect(() => {
    if (!modal.open) {
      reset();
    }
  }, [modal.open]);

  const onSubmit = handleSubmit(async (data) => {
    if (!chatbotId) return;

    const { data: newScene } = await coreApi.post<ChatScene>(
      `/apps/${appId}/projects/${projectId}/chatbots/${chatbotId}/scenes`,
      data
    );
    await mutate();

    closeModal();
    toast({ message: '장면을 저장했어요' });
    history.push(
      `/apps/${appId}/projects/${projectId}/chatbots/${chatbotId}/scenes/${newScene.id}`
    );
  });

  function onClick() {
    openModal({
      title: '장면 생성',
      size: 'medium',
      children: <SceneModalContent control={control} onSubmit={onSubmit} />,
      primaryAction: {
        icon: 'save_solid',
        content: '저장',
        form: 'modal',
        htmlType: 'submit'
      },
      secondaryAction: {
        content: '취소',
        onAction: closeModal
      }
    });
  }

  return (
    <>
      <Button
        role="listitem"
        icon="plus_solid"
        type="plain"
        cssStyle={[SceneItemLayout, NewSceneButtonLayout, SceneItemKeyBoardFocus]}
        onClick={onClick}
      >
        새 장면
      </Button>
      <Modal
        {...modal}
        primaryAction={{ ...modal.primaryAction, loading: formState.isSubmitting }}
      />
    </>
  );
}

const SceneItemLayout = css`
  width: 160px;
  height: 76px;

  ${CardShadowStyle};
  border-radius: ${spacing.borderRadius.xlarge};
  border: 1px solid ${color.border_default_subdued};

  padding: ${spacing.padding.large};
`;

const SceneItemSelected = css`
  box-shadow: 0 0 0 4px ${color.border_primary_default} inset;
`;

const SceneItemDescriptionStyle = css`
  color: ${color.text_subdued};
`;

const SceneItemKeyBoardFocus = css`
  :focus-visible {
    outline: none;
    border-radius: ${spacing.borderRadius.xlarge};
    ${shadow.focus} inset;
  }
`;

const NewSceneButtonLayout = css`
  justify-content: flex-start;
`;
