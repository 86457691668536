import { useParams } from 'react-router-dom';
import { useRequest } from '@/hooks';

export default function useItem() {
  const { appId, projectId } = useParams<AppParam>();

  const {
    data: items,
    isValidating,
    mutate
  } = useRequest<GameItem[]>(`/apps/${appId}/projects/${projectId}/items`);

  return { items, isValidating, mutate };
}
