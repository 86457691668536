/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Typography } from '@uniquegood/realworld-studio-design';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { RequestRequireProps } from '../../types';
import ExistingWebview from './ExistingWebview';
import WebviewTemplate from './WebviewTemplate';

const SelectWebViewContentsStyle = css`
  max-width: 990px;
  height: 596px;
  overflow: auto;
  background: #f4f4f4;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.07);

  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-items: center;
  gap: 24px 24px;

  padding: 24px 40px;
  margin: 24px;
  margin-left: auto;
  margin-right: auto;
`;

const TitleText = css`
  text-align: center;
  margin: 22px 0;
  font-size: 21px;
`;

interface WebviewSelectProps extends Pick<RequestRequireProps, 'studioApiEndPoint'> {
  token: string;
  selectToCustomize(id: string): void;
}

const WebviewSelect = ({ token, selectToCustomize, studioApiEndPoint }: WebviewSelectProps) => {
  type Webviews = {
    id: string;
    name: string;
    description: string;
    imageUrl: string;
  };

  const [webviews, setWebviews] = useState<Webviews[]>([]);
  useEffect(() => {
    async function getWebviewTemplates() {
      try {
        const res = await axios.get(`/api/webviews`, {
          headers: { Authorization: token },
          baseURL: studioApiEndPoint
        });
        setWebviews(res.data);
      } catch (err) {
        console.error(err);
      }
    }
    getWebviewTemplates();
  }, []);

  return (
    <>
      <Typography type="heading" cssStyle={TitleText}>
        사용할 웹뷰를 선택하세요
      </Typography>
      <div css={SelectWebViewContentsStyle}>
        {webviews &&
          webviews.map((i) => {
            return (
              <WebviewTemplate
                key={i.id}
                name={i.name}
                description={i.description}
                imageUrl={i.imageUrl}
                onClick={() => selectToCustomize(i.id)}
              />
            );
          })}
        <ExistingWebview selectToCustomize={selectToCustomize} />
      </div>
    </>
  );
};

export default WebviewSelect;
