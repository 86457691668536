import {
  Banner,
  Button,
  FormGroup,
  Icon,
  Input,
  Modal,
  spacing,
  Typography
} from '@uniquegood/realworld-studio-design';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import React, { FormEvent, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { onMobile } from '@/styles/responsive';
import { useModalState, useRequest } from '@/hooks';
import { studioApi } from '@/api';
import { ColumnFlex, Gap, Identity, MarginLeft4, MarginTop10 } from '@/styles';
import { emailAddressPattern, toast } from '@/utils';
import { emailModalImage } from '../assets';

interface ChangeEmailVerificationCheck {
  email: string;
  success: boolean;
}

export default function ContactMailForm(props: React.FormHTMLAttributes<HTMLFormElement>) {
  const { appId } = useParams<AppParam>();

  const { data: app } = useRequest<RealWorldApp>(`/apps/${appId}`);

  const { modal, openModal, closeModal } = useModalState();

  const [isActiveResend, setIsActiveResend] = useState(false);
  const [isToggledChange, setToggleChange] = useState(false);

  const { control, handleSubmit, reset, formState, trigger, watch } = useForm<{ email: string }>();

  useEffect(() => {
    if (app?.contactEmail && !formState.isDirty) {
      reset({ email: app?.contactEmail });
    }
  }, [app]);

  useEffect(() => {
    if (formState.isSubmitted) {
      const id = setTimeout(() => {
        setIsActiveResend(true);
      }, 1000 * 60 * 5);

      checkEmailVerification();

      return () => {
        clearTimeout(id);
      };
    }

    return () => {
      // Empty
    };
  }, [formState.isSubmitted]);

  const email = watch('email');

  useEffect(() => {
    trigger('email');
  }, [email]);

  function openCheckModal(event?: FormEvent<HTMLFormElement>) {
    event?.preventDefault();

    openModal({
      size: 'small',
      title: '이메일 수집 및 이용 동의',
      primaryAction: {
        content: '동의',
        onAction: onSubmit
      },
      secondaryAction: {
        content: '취소',
        onAction: closeModal
      },
      cssStyle: Gap,
      children: modalContent
    });
  }

  const onSubmit = handleSubmit(async (data) => {
    await studioApi.post(`/api/apps/${appId}/changeEmailVerification`, {
      ...data,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      appName: app!.name,
      url: `${window.location.origin}/apps/${appId}/changeEmailVerify`
    });
    toast({ message: '인증 메일을 전송했어요!' });
    closeModal();
  });

  async function checkEmailVerification() {
    try {
      const {
        data: { email, success }
      } = await studioApi.get<ChangeEmailVerificationCheck>(
        `/api/apps/${appId}/changeEmailVerificationCheck`
      );

      if (success) {
        toast({ message: '이메일을 인증했어요!' });
        reset({ email });
        setIsActiveResend(false);
        setToggleChange(false);
      }
    } catch (error) {
      toast({ message: '이메일을 인증에 실패했어요!', type: 'error' });
      reset({ email: app?.contactEmail });
    }
  }

  return (
    <form css={ColumnFlex} {...props} onSubmit={openCheckModal}>
      <Controller
        control={control}
        name="email"
        rules={{ pattern: emailAddressPattern }}
        render={({ field, fieldState: { error } }) => (
          <FormGroup
            id="contactEmail"
            label="문의 연락처"
            requiredIndicator
            cssStyle={[Flex, isToggledChange ? ChangeEmailAddressLayout : Identity]}
          >
            <Input
              id="contactEmail"
              placeholder="email@address.com"
              cssStyle={InputStyle}
              disabled={!isToggledChange}
              {...field}
            />
            {isToggledChange ? (
              <div css={SentEmailMarkupStyle}>
                <Button
                  type="primary"
                  size="small"
                  onClick={openCheckModal}
                  loading={formState.isSubmitting}
                  disabled={!!error}
                >
                  {formState.isSubmitted ? '인증 메일 재전송' : '이메일 인증'}
                </Button>
                <Button
                  type="basic"
                  size="small"
                  cssStyle={MarginLeft4}
                  htmlType="reset"
                  onClick={() => setToggleChange(false)}
                >
                  취소
                </Button>
              </div>
            ) : (
              <Button type="primary" size="small" onClick={() => setToggleChange(true)}>
                변경
              </Button>
            )}
          </FormGroup>
        )}
      />
      {formState.isSubmitted && (
        <Banner
          cssStyle={MarginTop10}
          isSmallSize
          iconComponent={<Icon icon="spinner_solid" size="20px" spinning />}
          footer={
            <Button type="basic" disabled={!isActiveResend} onClick={checkEmailVerification}>
              인증 상태 확인
            </Button>
          }
        >
          인증 링크를 이메일로 전송했어요. 메일함(혹은 스팸메일함)을 확인해주세요.
        </Banner>
      )}
      <Modal
        {...modal}
        primaryAction={{ ...modal.primaryAction, loading: formState.isSubmitting }}
      />
    </form>
  );
}
const SentEmailMarkupStyle = css`
  margin-top: 0px;

  ${onMobile} {
    display: flex;
    margin-top: ${spacing.margin.small2};
  }
`;

const Flex = css`
  > *:last-of-type {
    display: flex;
  }
`;

const ChangeEmailAddressLayout = css`
  > *:last-of-type {
    ${onMobile} {
      flex-direction: column;
    }
  }
`;

const InputStyle = css`
  flex-grow: 1;

  max-width: 320px;
  width: 100%;

  margin-right: ${spacing.margin.xsmall};
`;

const modalContent = (
  <>
    <Typography as="p" type="body">
      주식회사 유니크굿컴퍼니가 제공하는 리얼월드 스튜디오 서비스는 아래의 목적으로 개인정보를
      수집하고 이용하며, 회원의 개인정보를 안전하게 처리하는 데 최선을 다합니다.
    </Typography>
    <img alt="이메일 수집 목적" src={emailModalImage.table} width="100%" />
    <ol
      css={css`
        margin: 0;
        padding: 1em;
      `}
    >
      <Typography type="body" as="li">
        {/* Hack: list-style 이 적용이 되지 않아서 숫자를 하드코딩 함 */}
        최초 가입 시 인증하신 이메일의 정보 수집을 동의하셨더라도, 이메일 변경 시에는 변경된
        이메일에 대한 재동의가 필요합니다.
      </Typography>
      <Typography type="body" as="li">
        더 자세한 내용은{' '}
        <Button
          external
          url="https://uniquegoodcompany.notion.site/e2201cbcd269476b90fdf7e812e4fb54?v=1"
        >
          리얼월드 개인정보 처리방침
        </Button>
        을 참고해주세요.
      </Typography>
    </ol>
  </>
);
