import { useLocation, useParams } from 'react-router-dom';
import { useUser } from '.';
import useIsAdminSelector from './useIsAdminSelector';

export default function useRestrictProjectRoute() {
  const { appId } = useParams<AppParam>();
  const { pathname } = useLocation();
  const { data: user } = useUser();

  const { data: isAdmin } = useIsAdminSelector();

  if (isAdmin) return false;

  if (!user || isAdmin === undefined) return undefined;

  const isProjectCreatePageRoute = pathname.startsWith(`/apps/${appId}/projects/create`);
  const isProjectSettingPageRoute = pathname.startsWith(`/apps/${appId}/settings`);

  const isRestrictLevel = user.level === 'Author' || user.level === 'Developer';

  if (
    user.level === 'Tester' ||
    (isProjectCreatePageRoute && isRestrictLevel) ||
    (isProjectSettingPageRoute && isRestrictLevel)
  )
    return `/apps/${appId}`;

  return false;
}
