import { Input } from '@uniquegood/realworld-studio-design';
import React from 'react';

import { IEditElement } from '@/components/ActionHandler/types';

interface Props extends IEditElement {
  multiline?: boolean;
}

const InputItem = ({
  setParams,
  defaultValue,
  placeholder,
  cssStyle,
  multiline = false
}: Props) => {
  return (
    <Input
      multiline={multiline}
      cssStyle={cssStyle}
      type="text"
      defaultValue={defaultValue}
      placeholder={placeholder}
      onChange={(value: string) => {
        setParams(value);
      }}
    />
  );
};

export default InputItem;
