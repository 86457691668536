import { matchPath, useLocation } from 'react-router-dom';

const paramsNotFound = { appId: undefined, projectId: undefined, pageKey: undefined };

export default function useParamsWithMatchPath() {
  const { pathname } = useLocation();

  const { appId } =
    matchPath<AppParam>(pathname, {
      path: '/apps/:appId'
    })?.params ?? paramsNotFound;

  const params =
    matchPath<AppParam & { pageKey?: string }>(pathname, {
      path: `/apps/:appId/projects/:projectId/:pageKey`
    })?.params ?? paramsNotFound;

  const paramsV2 =
    matchPath<AppParam & { pageKey?: string }>(pathname, {
      path: `/apps/:appId/projectsV2/:projectId/:pageKey`
    })?.params ?? paramsNotFound;

  if (params.appId) {
    return { ...params, appId, isV2: Boolean(paramsV2.projectId) };
  }
  if (paramsV2.appId) {
    return { ...paramsV2, appId, isV2: Boolean(paramsV2.projectId) };
  }

  return { appId, isV2: Boolean(paramsV2.projectId) };
}
