import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { ListItem } from '@uniquegood/realworld-studio-design';

export type DraggableMissionListItemProps = {
  mission: Mission;
  index: number;
};

export default function DraggableMissionListItem({
  mission,
  index
}: DraggableMissionListItemProps) {
  return (
    <Draggable draggableId={String(mission.id)} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={snapshot.isDragging ? 'dragging' : 'no'}
        >
          <ListItem icon="ellipsis_v_regular" content={mission.name} />
        </div>
      )}
    </Draggable>
  );
}
