import Quill from 'quill';

import { SnowTheme } from 'quill-color-picker-enhance';
import 'quill-color-picker-enhance/dist/index.css';

import QuillImageDropAndPaste from 'quill-image-drop-and-paste';

import './quill.snow-1.3.6.css';
import './editor.scss';
import './codemirror.css';

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { css } from '@emotion/react';
import { CheckBox, color, Icon, Modal, Tooltip } from '@uniquegood/realworld-studio-design';
import CodeMirror, { ReactCodeMirrorProps } from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';
import { EditorView } from '@codemirror/view';
import { coreApi } from '@/api';
import { makeFormData, maxDefaultFileSize } from '@/utils';

import { useDidUpdateEffect } from '@/hooks';

import { Identity, KeyboardFocusStyle, ListStyleTypeDisc, TextAlignCenter } from '@/styles';
import { haveInlineScript, urlToFile } from './utils';

import { container, fillToolbarTitle, insertAudioIcon } from './toolbar';

import useModalState from '../../hooks/useModalState';

Quill.register('themes/snow-quill-color-picker-enhance', SnowTheme);
Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste);

interface EditorProps extends Omit<ReactCodeMirrorProps, 'extension'> {
  defaultValue?: string;
  value?: string;
  onChange: (value: string) => void;
}

export default function Editor({
  className,
  defaultValue,
  value,
  onChange,
  minHeight,
  ...rest
}: EditorProps) {
  const { appId, projectId } = useParams<AppParam>();
  const ref = useRef<HTMLDivElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const quillRef = useRef<Quill | null>(null);

  const isFirstRenderRef = useRef(true);

  const hasInlineScript = useMemo(() => haveInlineScript(defaultValue), [defaultValue]);

  const [isEditInHTML, setIsEditInHTML] = useState(hasInlineScript);
  const [disableCheckbox, setDisableCheckbox] = useState(hasInlineScript);

  const { openModal, closeModal, modal } = useModalState();

  useDidUpdateEffect(() => {
    if (isEditInHTML) {
      const timeout = setTimeout(() => {
        setDisableCheckbox(haveInlineScript(value));
      }, 300);

      return () => {
        window.clearTimeout(timeout);
      };
    }

    return () => {
      // Empty
    };
  }, [value]);

  useEffect(() => {
    if (!isEditInHTML && quillRef.current) {
      if (value) {
        quillRef.current.root.innerHTML = value;
        return;
      }

      if (defaultValue) {
        quillRef.current.root.innerHTML = defaultValue;
      }
    }
  }, [isEditInHTML]);

  useEffect(() => {
    if (ref.current && ref.current.children.length === 0) {
      const quill = new Quill(ref.current, {
        modules: {
          toolbar: {
            container,
            handlers: {
              audio() {
                const input = createFileInput('audio/*');

                input.onchange = async () => {
                  if (!input.files) return;
                  const file = input.files[0];

                  const range = quill.getSelection(true);

                  quill.insertEmbed(range.index, 'audio', { src: 'loading' }, 'user');

                  const { data } = await coreApi.post<FileResponseModel>(
                    `/apps/${appId}/projects/${projectId}/files`,
                    makeFormData({ file, dir: 'audios' })
                  );

                  quill.deleteText(range.index, 1, 'user');
                  quill.insertEmbed(range.index, 'audio', { src: data.url }, 'user');

                  quill.setSelection({ ...range, index: range.index + 1 }, 'user');
                };

                input.click();
              },
              image(clicked: boolean) {
                if (clicked) {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  // eslint-disable-next-line react/no-this-in-sfc
                  const container = this.container as HTMLDivElement;

                  let fileInput = container.querySelector<HTMLInputElement>(
                    'input.ql-image[type=file]'
                  );
                  if (fileInput === null) {
                    fileInput = document.createElement('input');
                    fileInput.setAttribute('type', 'file');
                    fileInput.setAttribute(
                      'accept',
                      'image/png, image/gif, image/jpeg, image/bmp, image/x-icon'
                    );
                    fileInput.classList.add('ql-image');
                    fileInput.addEventListener('change', (event) => {
                      if (!(event.target instanceof HTMLInputElement)) return;

                      const { files } = event.target;
                      if (files && files.length > 0) {
                        const file = files[0];
                        const reader = new FileReader();
                        reader.onload = (e) => {
                          const dataUrl = e.target?.result;
                          if (typeof dataUrl !== 'string')
                            throw Error('읽어낸 dataUrl 정보가 문자열이 아닙니다.');

                          imageHandler(dataUrl, file.type);
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          fileInput!.value = '';
                        };
                        reader.readAsDataURL(file);
                      }
                    });

                    container.appendChild(fileInput);
                  }
                  fileInput.click();
                }
              }
            }
          },
          imageDropAndPaste: {
            handler: imageHandler
          }
        },
        theme: 'snow-quill-color-picker-enhance'
      });

      quillRef.current = quill;

      if (formRef.current) {
        fillToolbarTitle(formRef.current);
        insertAudioIcon(formRef.current);
      }

      if (defaultValue) {
        quill.root.innerHTML = defaultValue;
      } else if (value) {
        quill.root.innerHTML = value;
      }

      let timeout: number | null = null;

      quill.on('editor-change', () => {
        if (isEditInHTML) return;

        if (timeout) {
          clearTimeout(timeout);
        }

        timeout = window.setTimeout(() => {
          if (isFirstRenderRef.current) {
            isFirstRenderRef.current = false;
            return;
          }

          const newElem = document.createElement('div');
          newElem.style.display = 'none';
          newElem.innerHTML = quill.root.innerHTML;
          newElem.querySelectorAll('.ql-cursor').forEach((e) => e.remove());
          onChange(newElem.innerHTML);

          newElem.remove();
        }, 300);
      });
    }
  }, []);

  async function imageHandler(imageDataUrl: string, type: string) {
    if (!quillRef.current) throw Error('quill 개체를 참조하지 못했습니다.');

    const file = await urlToFile(imageDataUrl, type);

    if (file.size > maxDefaultFileSize) {
      openModal({
        title: '더 작은 이미지를 올려주세요',
        cssStyle: TextAlignCenter,
        children: (
          <>
            <Icon
              color={color.interactive_critical_default}
              icon="times_circle_regular"
              size="100px"
            />
            최대 {maxDefaultFileSize / 1024 / 1024}MB까지 업로드 할 수 있어요
          </>
        ),
        primaryAction: { content: '확인', onAction: closeModal }
      });
      return;
    }

    const { data } = await coreApi.post<UserFileResponseModel>(
      `/apps/${appId}/images/uploadFile`,
      makeFormData({ file })
    );

    let { index } = quillRef.current.getSelection() || {};
    if (index === undefined || index < 0) index = quillRef.current.getLength();
    quillRef.current.insertEmbed(index, 'image', data.url, 'user');
  }

  return (
    <form ref={formRef} className={className} css={[isEditInHTML ? HideQuillToolBar : '']}>
      <Modal {...modal} />
      <div ref={ref} style={{ display: isEditInHTML ? 'none' : undefined }} />

      <CodeMirror
        css={[
          codeMirrorHeight(minHeight),
          isEditInHTML ? Identity : hideTextArea,
          KeyboardFocusStyle
        ]}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        extensions={[html(), EditorView.lineWrapping]}
        {...rest}
      />
      <Tooltip
        title="HTML로 편집"
        cssStyle={css`
          width: 320px;
        `}
        preferredPosition="mostSpace"
        content={
          <ul css={ListStyleTypeDisc}>
            <li>
              HTML 편집 기능을 사용하면 코드를 활용하여 화면을 더 자유롭게 커스텀 할 수 있어요.
            </li>
            <li>
              코드에 {'<script />'}, {'<style />'} 태그가 포함되어있을 경우 &lsquo;HTML로
              편집&lsquo; 모드에서만 편집할 수 있어요.
            </li>
          </ul>
        }
      >
        <CheckBox
          disabled={disableCheckbox}
          checked={isEditInHTML}
          defaultValue={defaultValue}
          onChange={(event) => setIsEditInHTML(event.target.checked)}
        >
          HTML로 편집
        </CheckBox>
      </Tooltip>
    </form>
  );
}

function createFileInput(accept: string) {
  const input = document.createElement('input');
  input.setAttribute('type', 'file');
  input.setAttribute('accept', accept);

  return input;
}

const hideTextArea = css`
  display: none;
`;

export const codeMirrorHeight = (height?: string) => css`
  .cm-scroller {
    height: ${height ?? 'initial'};
  }
`;

const HideQuillToolBar = css`
  .ql-toolbar {
    display: none;
  }
`;
