/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Icon, Label, Input, Button, Tooltip, color } from '@uniquegood/realworld-studio-design';
import { MarginLeft4 } from '../../../../styles';

interface Props {
  label: string;
  defaultValue?: string;
  description?: string;
  placeholder?: string;
  value?: number;
  isListItem?: boolean;

  onChange: (data: any) => void;
  onClick?: any;
}

const InputNumber = ({
  label,
  defaultValue,
  value,
  description,
  placeholder,
  onChange,
  onClick,
  isListItem
}: Props) => {
  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        padding-top: 24px;
      `}
    >
      {isListItem ? (
        <div
          css={css`
            display: flex;
          `}
        >
          <Label
            id={label}
            cssStyle={css`
              > :nth-of-type(1) {
                display: flex;
                align-items: center;
                margin-right: 24px;
              }
            `}
          >
            <Icon
              icon="hashtag_solid"
              size="16px"
              cssStyle={css`
                margin-right: 6px;
              `}
            />
            {label}
          </Label>
          <Input
            cssStyle={css`
              width: 173px;
            `}
            onChange={(value) => onChange(parseInt(value, 10))}
            type="number"
            placeholder={placeholder}
            value={value?.toString()}
            defaultValue={defaultValue}
          />
          <Button type="plain" icon="trash_alt_solid" onClick={onClick} />
        </div>
      ) : (
        <>
          <Label
            id={label}
            cssStyle={css`
              > :nth-of-type(1) {
                display: flex;
                align-items: center;
                margin-right: 24px;
              }
            `}
          >
            <Icon
              icon="hashtag_solid"
              size="16px"
              cssStyle={css`
                margin-right: 6px;
              `}
            />
            {label}
            {description && (
              <Tooltip content={description}>
                <Icon
                  cssStyle={MarginLeft4}
                  icon="question_circle_solid"
                  size="16px"
                  color={color.icon_disabled}
                />
              </Tooltip>
            )}
          </Label>
          <Input
            cssStyle={css`
              flex: 0 0 173px;
            `}
            id={label}
            onChange={(value) => onChange(parseInt(value, 10))}
            type="number"
            placeholder={placeholder}
            value={value?.toString()}
            defaultValue={defaultValue}
          />
        </>
      )}
    </div>
  );
};

export default InputNumber;
