export default function PartyFaceIcon() {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.75 43.5C37.7526 43.5 44.25 34.7696 44.25 24C44.25 13.2304 37.7526 4.5 24.75 4.5C11.7474 4.5 5.25 13.2304 5.25 24C5.25 34.7696 11.7474 43.5 24.75 43.5Z"
        fill="url(#paint0_radial_1970_26885)"
      />
      <path
        d="M24.75 43.5C37.7526 43.5 44.25 34.7696 44.25 24C44.25 13.2304 37.7526 4.5 24.75 4.5C11.7474 4.5 5.25 13.2304 5.25 24C5.25 34.7696 11.7474 43.5 24.75 43.5Z"
        fill="url(#paint1_radial_1970_26885)"
      />
      <path
        d="M24.75 43.5C37.7526 43.5 44.25 34.7696 44.25 24C44.25 13.2304 37.7526 4.5 24.75 4.5C11.7474 4.5 5.25 13.2304 5.25 24C5.25 34.7696 11.7474 43.5 24.75 43.5Z"
        fill="url(#paint2_radial_1970_26885)"
      />
      <path
        d="M24.75 43.5C37.7526 43.5 44.25 34.7696 44.25 24C44.25 13.2304 37.7526 4.5 24.75 4.5C11.7474 4.5 5.25 13.2304 5.25 24C5.25 34.7696 11.7474 43.5 24.75 43.5Z"
        fill="url(#paint3_radial_1970_26885)"
      />
      <path
        d="M24.75 43.5C37.7526 43.5 44.25 34.7696 44.25 24C44.25 13.2304 37.7526 4.5 24.75 4.5C11.7474 4.5 5.25 13.2304 5.25 24C5.25 34.7696 11.7474 43.5 24.75 43.5Z"
        fill="url(#paint4_radial_1970_26885)"
      />
      <path
        d="M24.75 43.5C37.7526 43.5 44.25 34.7696 44.25 24C44.25 13.2304 37.7526 4.5 24.75 4.5C11.7474 4.5 5.25 13.2304 5.25 24C5.25 34.7696 11.7474 43.5 24.75 43.5Z"
        fill="url(#paint5_radial_1970_26885)"
      />
      <path
        d="M24.75 43.5C37.7526 43.5 44.25 34.7696 44.25 24C44.25 13.2304 37.7526 4.5 24.75 4.5C11.7474 4.5 5.25 13.2304 5.25 24C5.25 34.7696 11.7474 43.5 24.75 43.5Z"
        fill="url(#paint6_radial_1970_26885)"
      />
      <path
        d="M24.75 43.5C37.7526 43.5 44.25 34.7696 44.25 24C44.25 13.2304 37.7526 4.5 24.75 4.5C11.7474 4.5 5.25 13.2304 5.25 24C5.25 34.7696 11.7474 43.5 24.75 43.5Z"
        fill="url(#paint7_radial_1970_26885)"
      />
      <path
        d="M24.75 43.5C37.7526 43.5 44.25 34.7696 44.25 24C44.25 13.2304 37.7526 4.5 24.75 4.5C11.7474 4.5 5.25 13.2304 5.25 24C5.25 34.7696 11.7474 43.5 24.75 43.5Z"
        fill="url(#paint8_radial_1970_26885)"
      />
      <path
        d="M24.75 43.5C37.7526 43.5 44.25 34.7696 44.25 24C44.25 13.2304 37.7526 4.5 24.75 4.5C11.7474 4.5 5.25 13.2304 5.25 24C5.25 34.7696 11.7474 43.5 24.75 43.5Z"
        fill="url(#paint9_radial_1970_26885)"
      />
      <path
        d="M24.75 43.5C37.7526 43.5 44.25 34.7696 44.25 24C44.25 13.2304 37.7526 4.5 24.75 4.5C11.7474 4.5 5.25 13.2304 5.25 24C5.25 34.7696 11.7474 43.5 24.75 43.5Z"
        fill="url(#paint10_radial_1970_26885)"
      />
      <path
        d="M24.75 43.5C37.7526 43.5 44.25 34.7696 44.25 24C44.25 13.2304 37.7526 4.5 24.75 4.5C11.7474 4.5 5.25 13.2304 5.25 24C5.25 34.7696 11.7474 43.5 24.75 43.5Z"
        fill="url(#paint11_radial_1970_26885)"
      />
      <path
        d="M24.75 43.5C37.7526 43.5 44.25 34.7696 44.25 24C44.25 13.2304 37.7526 4.5 24.75 4.5C11.7474 4.5 5.25 13.2304 5.25 24C5.25 34.7696 11.7474 43.5 24.75 43.5Z"
        fill="url(#paint12_radial_1970_26885)"
      />
      <path
        d="M24.75 43.5C37.7526 43.5 44.25 34.7696 44.25 24C44.25 13.2304 37.7526 4.5 24.75 4.5C11.7474 4.5 5.25 13.2304 5.25 24C5.25 34.7696 11.7474 43.5 24.75 43.5Z"
        fill="url(#paint13_radial_1970_26885)"
      />
      <path
        d="M24.75 43.5C37.7526 43.5 44.25 34.7696 44.25 24C44.25 13.2304 37.7526 4.5 24.75 4.5C11.7474 4.5 5.25 13.2304 5.25 24C5.25 34.7696 11.7474 43.5 24.75 43.5Z"
        fill="url(#paint14_radial_1970_26885)"
      />
      <path
        d="M24.75 43.5C37.7526 43.5 44.25 34.7696 44.25 24C44.25 13.2304 37.7526 4.5 24.75 4.5C11.7474 4.5 5.25 13.2304 5.25 24C5.25 34.7696 11.7474 43.5 24.75 43.5Z"
        fill="url(#paint15_radial_1970_26885)"
      />
      <path
        d="M24.75 43.5C37.7526 43.5 44.25 34.7696 44.25 24C44.25 13.2304 37.7526 4.5 24.75 4.5C11.7474 4.5 5.25 13.2304 5.25 24C5.25 34.7696 11.7474 43.5 24.75 43.5Z"
        fill="url(#paint16_radial_1970_26885)"
      />
      <path
        d="M24.75 43.5C37.7526 43.5 44.25 34.7696 44.25 24C44.25 13.2304 37.7526 4.5 24.75 4.5C11.7474 4.5 5.25 13.2304 5.25 24C5.25 34.7696 11.7474 43.5 24.75 43.5Z"
        fill="url(#paint17_radial_1970_26885)"
      />
      <path
        d="M24.75 43.5C37.7526 43.5 44.25 34.7696 44.25 24C44.25 13.2304 37.7526 4.5 24.75 4.5C11.7474 4.5 5.25 13.2304 5.25 24C5.25 34.7696 11.7474 43.5 24.75 43.5Z"
        fill="url(#paint18_radial_1970_26885)"
      />
      <path
        d="M24.75 43.5C37.7526 43.5 44.25 34.7696 44.25 24C44.25 13.2304 37.7526 4.5 24.75 4.5C11.7474 4.5 5.25 13.2304 5.25 24C5.25 34.7696 11.7474 43.5 24.75 43.5Z"
        fill="url(#paint19_radial_1970_26885)"
      />
      <path
        d="M26.25 4.5L5.25 24V19.9597L6.14205 17.6285L5.25 15.9764V12.4521L6.25219 10.0841L5.25 8.46879V6.5C5.25 5.39543 6.14543 4.5 7.25 4.5H9.24423L10.4925 5.45839L13.1908 4.5H16.7519L18.2754 5.29318L20.7168 4.5H26.25Z"
        fill="#BB1D80"
      />
      <path
        d="M26.25 4.5L5.25 24V19.9597L6.14205 17.6285L5.25 15.9764V12.4521L6.25219 10.0841L5.25 8.46879V6.5C5.25 5.39543 6.14543 4.5 7.25 4.5H9.24423L10.4925 5.45839L13.1908 4.5H16.7519L18.2754 5.29318L20.7168 4.5H26.25Z"
        fill="url(#paint20_linear_1970_26885)"
      />
      <path d="M13.3713 4.5L5.25 12.6213V8.37868L9.12868 4.5H13.3713Z" fill="#5092FF" />
      <path d="M20.8713 4.5L5.25 20.1213V15.8787L16.6287 4.5H20.8713Z" fill="#5092FF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.375 7.5C42.9963 7.5 43.5 8.00368 43.5 8.625C43.5 11.9222 40.7519 14.25 37.875 14.25C37.2537 14.25 36.75 13.7463 36.75 13.125C36.75 12.5037 37.2537 12 37.875 12C39.6135 12 41.25 10.5778 41.25 8.625C41.25 8.00368 41.7537 7.5 42.375 7.5Z"
        fill="url(#paint21_radial_1970_26885)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.375 36C15.9963 36 16.5 36.5037 16.5 37.125C16.5 40.4222 13.7519 42.75 10.875 42.75C10.2537 42.75 9.75 42.2463 9.75 41.625C9.75 41.0037 10.2537 40.5 10.875 40.5C12.6135 40.5 14.25 39.0778 14.25 37.125C14.25 36.5037 14.7537 36 15.375 36Z"
        fill="url(#paint22_radial_1970_26885)"
      />
      <circle cx="39.75" cy="42" r="1.5" fill="url(#paint23_radial_1970_26885)" />
      <circle cx="14.25" cy="30" r="1.5" fill="url(#paint24_radial_1970_26885)" />
      <circle cx="36.75" cy="4.5" r="1.5" fill="url(#paint25_radial_1970_26885)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6393 19.0293C30.3099 19.3419 30.1112 19.7428 29.9779 20.082C29.7053 20.7759 28.9217 21.1174 28.2277 20.8448C27.5338 20.5721 27.1923 19.7885 27.465 19.0946C27.654 18.6134 28.0295 17.7839 28.7803 17.0711C29.5652 16.326 30.6887 15.7773 32.2158 15.7773C35.3278 15.7773 36.5607 18.1089 36.9623 19.0658C37.2509 19.7533 36.9275 20.5445 36.24 20.8331C35.5525 21.1216 34.7613 20.7982 34.4728 20.1108C34.1858 19.427 33.6229 18.4773 32.2158 18.4773C31.397 18.4773 30.9345 18.749 30.6393 19.0293Z"
        fill="url(#paint26_radial_1970_26885)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8736 19.0293C16.5443 19.3419 16.3456 19.7428 16.2123 20.082C15.9396 20.7759 15.156 21.1174 14.4621 20.8448C13.7682 20.5721 13.4267 19.7885 13.6994 19.0946C13.8884 18.6134 14.2639 17.7839 15.0147 17.0711C15.7996 16.326 16.9231 15.7773 18.4502 15.7773C21.5622 15.7773 22.7951 18.1089 23.1967 19.0658C23.4853 19.7533 23.1619 20.5445 22.4744 20.8331C21.7869 21.1216 20.9957 20.7982 20.7071 20.1108C20.4201 19.427 19.8573 18.4773 18.4502 18.4773C17.6314 18.4773 17.1689 18.749 16.8736 19.0293Z"
        fill="url(#paint27_radial_1970_26885)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5967 29.7461C27.5967 29.1248 28.1004 28.6211 28.7217 28.6211H40.5031C41.1244 28.6211 41.6281 29.1248 41.6281 29.7461C41.6281 30.3674 41.1244 30.8711 40.5031 30.8711H28.7217C28.1004 30.8711 27.5967 30.3674 27.5967 29.7461Z"
        fill="#00A6ED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5967 29.7461C27.5967 29.1248 28.1004 28.6211 28.7217 28.6211H40.5031C41.1244 28.6211 41.6281 29.1248 41.6281 29.7461C41.6281 30.3674 41.1244 30.8711 40.5031 30.8711H28.7217C28.1004 30.8711 27.5967 30.3674 27.5967 29.7461Z"
        fill="url(#paint28_radial_1970_26885)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5967 29.7461C27.5967 29.1248 28.1004 28.6211 28.7217 28.6211H40.5031C41.1244 28.6211 41.6281 29.1248 41.6281 29.7461C41.6281 30.3674 41.1244 30.8711 40.5031 30.8711H28.7217C28.1004 30.8711 27.5967 30.3674 27.5967 29.7461Z"
        fill="url(#paint29_radial_1970_26885)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5967 29.7461C27.5967 29.1248 28.1004 28.6211 28.7217 28.6211H40.5031C41.1244 28.6211 41.6281 29.1248 41.6281 29.7461C41.6281 30.3674 41.1244 30.8711 40.5031 30.8711H28.7217C28.1004 30.8711 27.5967 30.3674 27.5967 29.7461Z"
        fill="url(#paint30_radial_1970_26885)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5967 29.7461C27.5967 29.1248 28.1004 28.6211 28.7217 28.6211H40.5031C41.1244 28.6211 41.6281 29.1248 41.6281 29.7461C41.6281 30.3674 41.1244 30.8711 40.5031 30.8711H28.7217C28.1004 30.8711 27.5967 30.3674 27.5967 29.7461Z"
        fill="url(#paint31_linear_1970_26885)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.7183 20.6172C39.7183 19.9959 40.2219 19.4922 40.8433 19.4922H40.9367C43.9044 19.4922 46.2863 21.9424 46.2026 24.9089C46.185 25.53 45.6673 26.0192 45.0462 26.0017C44.4252 25.9841 43.9359 25.4664 43.9535 24.8454C44.0014 23.1459 42.6368 21.7422 40.9367 21.7422H40.8433C40.2219 21.7422 39.7183 21.2385 39.7183 20.6172Z"
        fill="#00A6ED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.7183 20.6172C39.7183 19.9959 40.2219 19.4922 40.8433 19.4922H40.9367C43.9044 19.4922 46.2863 21.9424 46.2026 24.9089C46.185 25.53 45.6673 26.0192 45.0462 26.0017C44.4252 25.9841 43.9359 25.4664 43.9535 24.8454C44.0014 23.1459 42.6368 21.7422 40.9367 21.7422H40.8433C40.2219 21.7422 39.7183 21.2385 39.7183 20.6172Z"
        fill="url(#paint32_radial_1970_26885)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.7183 20.6172C39.7183 19.9959 40.2219 19.4922 40.8433 19.4922H40.9367C43.9044 19.4922 46.2863 21.9424 46.2026 24.9089C46.185 25.53 45.6673 26.0192 45.0462 26.0017C44.4252 25.9841 43.9359 25.4664 43.9535 24.8454C44.0014 23.1459 42.6368 21.7422 40.9367 21.7422H40.8433C40.2219 21.7422 39.7183 21.2385 39.7183 20.6172Z"
        fill="url(#paint33_radial_1970_26885)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.7183 20.6172C39.7183 19.9959 40.2219 19.4922 40.8433 19.4922H40.9367C43.9044 19.4922 46.2863 21.9424 46.2026 24.9089C46.185 25.53 45.6673 26.0192 45.0462 26.0017C44.4252 25.9841 43.9359 25.4664 43.9535 24.8454C44.0014 23.1459 42.6368 21.7422 40.9367 21.7422H40.8433C40.2219 21.7422 39.7183 21.2385 39.7183 20.6172Z"
        fill="url(#paint34_radial_1970_26885)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.7183 20.6172C39.7183 19.9959 40.2219 19.4922 40.8433 19.4922H40.9367C43.9044 19.4922 46.2863 21.9424 46.2026 24.9089C46.185 25.53 45.6673 26.0192 45.0462 26.0017C44.4252 25.9841 43.9359 25.4664 43.9535 24.8454C44.0014 23.1459 42.6368 21.7422 40.9367 21.7422H40.8433C40.2219 21.7422 39.7183 21.2385 39.7183 20.6172Z"
        fill="url(#paint35_radial_1970_26885)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.7183 20.6172C39.7183 19.9959 40.2219 19.4922 40.8433 19.4922H40.9367C43.9044 19.4922 46.2863 21.9424 46.2026 24.9089C46.185 25.53 45.6673 26.0192 45.0462 26.0017C44.4252 25.9841 43.9359 25.4664 43.9535 24.8454C44.0014 23.1459 42.6368 21.7422 40.9367 21.7422H40.8433C40.2219 21.7422 39.7183 21.2385 39.7183 20.6172Z"
        fill="url(#paint36_radial_1970_26885)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.1096 23.7524C45.7306 23.77 46.2199 24.2876 46.2024 24.9087L46.1901 25.3421C46.1032 28.4205 43.5826 30.8709 40.5029 30.8709C39.8816 30.8709 39.3779 30.3672 39.3779 29.7459C39.3779 29.1246 39.8816 28.6209 40.5029 28.6209C42.3647 28.6209 43.8885 27.1396 43.941 25.2786L43.9533 24.8452C43.9708 24.2241 44.4885 23.7349 45.1096 23.7524Z"
        fill="#00A6ED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.1096 23.7524C45.7306 23.77 46.2199 24.2876 46.2024 24.9087L46.1901 25.3421C46.1032 28.4205 43.5826 30.8709 40.5029 30.8709C39.8816 30.8709 39.3779 30.3672 39.3779 29.7459C39.3779 29.1246 39.8816 28.6209 40.5029 28.6209C42.3647 28.6209 43.8885 27.1396 43.941 25.2786L43.9533 24.8452C43.9708 24.2241 44.4885 23.7349 45.1096 23.7524Z"
        fill="url(#paint37_radial_1970_26885)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.1096 23.7524C45.7306 23.77 46.2199 24.2876 46.2024 24.9087L46.1901 25.3421C46.1032 28.4205 43.5826 30.8709 40.5029 30.8709C39.8816 30.8709 39.3779 30.3672 39.3779 29.7459C39.3779 29.1246 39.8816 28.6209 40.5029 28.6209C42.3647 28.6209 43.8885 27.1396 43.941 25.2786L43.9533 24.8452C43.9708 24.2241 44.4885 23.7349 45.1096 23.7524Z"
        fill="url(#paint38_radial_1970_26885)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.1096 23.7524C45.7306 23.77 46.2199 24.2876 46.2024 24.9087L46.1901 25.3421C46.1032 28.4205 43.5826 30.8709 40.5029 30.8709C39.8816 30.8709 39.3779 30.3672 39.3779 29.7459C39.3779 29.1246 39.8816 28.6209 40.5029 28.6209C42.3647 28.6209 43.8885 27.1396 43.941 25.2786L43.9533 24.8452C43.9708 24.2241 44.4885 23.7349 45.1096 23.7524Z"
        fill="url(#paint39_radial_1970_26885)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.1096 23.7524C45.7306 23.77 46.2199 24.2876 46.2024 24.9087L46.1901 25.3421C46.1032 28.4205 43.5826 30.8709 40.5029 30.8709C39.8816 30.8709 39.3779 30.3672 39.3779 29.7459C39.3779 29.1246 39.8816 28.6209 40.5029 28.6209C42.3647 28.6209 43.8885 27.1396 43.941 25.2786L43.9533 24.8452C43.9708 24.2241 44.4885 23.7349 45.1096 23.7524Z"
        fill="url(#paint40_radial_1970_26885)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.1096 23.7524C45.7306 23.77 46.2199 24.2876 46.2024 24.9087L46.1901 25.3421C46.1032 28.4205 43.5826 30.8709 40.5029 30.8709C39.8816 30.8709 39.3779 30.3672 39.3779 29.7459C39.3779 29.1246 39.8816 28.6209 40.5029 28.6209C42.3647 28.6209 43.8885 27.1396 43.941 25.2786L43.9533 24.8452C43.9708 24.2241 44.4885 23.7349 45.1096 23.7524Z"
        fill="url(#paint41_radial_1970_26885)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.4971 23.8382C36.4971 21.438 38.4428 19.4922 40.8431 19.4922H40.9365C41.5578 19.4922 42.0615 19.9959 42.0615 20.6172C42.0615 21.2385 41.5578 21.7422 40.9365 21.7422H40.8431C39.6855 21.7422 38.7471 22.6806 38.7471 23.8382V24.3092C38.7471 24.9305 38.2434 25.4342 37.6221 25.4342C37.0008 25.4342 36.4971 24.9305 36.4971 24.3092V23.8382Z"
        fill="#00A6ED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.4971 23.8382C36.4971 21.438 38.4428 19.4922 40.8431 19.4922H40.9365C41.5578 19.4922 42.0615 19.9959 42.0615 20.6172C42.0615 21.2385 41.5578 21.7422 40.9365 21.7422H40.8431C39.6855 21.7422 38.7471 22.6806 38.7471 23.8382V24.3092C38.7471 24.9305 38.2434 25.4342 37.6221 25.4342C37.0008 25.4342 36.4971 24.9305 36.4971 24.3092V23.8382Z"
        fill="url(#paint42_radial_1970_26885)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.4971 23.8382C36.4971 21.438 38.4428 19.4922 40.8431 19.4922H40.9365C41.5578 19.4922 42.0615 19.9959 42.0615 20.6172C42.0615 21.2385 41.5578 21.7422 40.9365 21.7422H40.8431C39.6855 21.7422 38.7471 22.6806 38.7471 23.8382V24.3092C38.7471 24.9305 38.2434 25.4342 37.6221 25.4342C37.0008 25.4342 36.4971 24.9305 36.4971 24.3092V23.8382Z"
        fill="url(#paint43_radial_1970_26885)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.4971 23.8382C36.4971 21.438 38.4428 19.4922 40.8431 19.4922H40.9365C41.5578 19.4922 42.0615 19.9959 42.0615 20.6172C42.0615 21.2385 41.5578 21.7422 40.9365 21.7422H40.8431C39.6855 21.7422 38.7471 22.6806 38.7471 23.8382V24.3092C38.7471 24.9305 38.2434 25.4342 37.6221 25.4342C37.0008 25.4342 36.4971 24.9305 36.4971 24.3092V23.8382Z"
        fill="url(#paint44_radial_1970_26885)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.4971 23.8382C36.4971 21.438 38.4428 19.4922 40.8431 19.4922H40.9365C41.5578 19.4922 42.0615 19.9959 42.0615 20.6172C42.0615 21.2385 41.5578 21.7422 40.9365 21.7422H40.8431C39.6855 21.7422 38.7471 22.6806 38.7471 23.8382V24.3092C38.7471 24.9305 38.2434 25.4342 37.6221 25.4342C37.0008 25.4342 36.4971 24.9305 36.4971 24.3092V23.8382Z"
        fill="url(#paint45_radial_1970_26885)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.4971 23.8382C36.4971 21.438 38.4428 19.4922 40.8431 19.4922H40.9365C41.5578 19.4922 42.0615 19.9959 42.0615 20.6172C42.0615 21.2385 41.5578 21.7422 40.9365 21.7422H40.8431C39.6855 21.7422 38.7471 22.6806 38.7471 23.8382V24.3092C38.7471 24.9305 38.2434 25.4342 37.6221 25.4342C37.0008 25.4342 36.4971 24.9305 36.4971 24.3092V23.8382Z"
        fill="url(#paint46_radial_1970_26885)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.5116 23.1133C42.1329 23.1133 42.6366 23.617 42.6366 24.2383V24.3092C42.6366 26.0045 41.2622 27.3789 39.5668 27.3789C37.8714 27.3789 36.4971 26.0045 36.4971 24.3092C36.4971 23.6878 37.0007 23.1842 37.6221 23.1842C38.2434 23.1842 38.7471 23.6878 38.7471 24.3092C38.7471 24.7619 39.1141 25.1289 39.5668 25.1289C40.0195 25.1289 40.3866 24.7619 40.3866 24.3092V24.2383C40.3866 23.617 40.8902 23.1133 41.5116 23.1133Z"
        fill="#00A6ED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.5116 23.1133C42.1329 23.1133 42.6366 23.617 42.6366 24.2383V24.3092C42.6366 26.0045 41.2622 27.3789 39.5668 27.3789C37.8714 27.3789 36.4971 26.0045 36.4971 24.3092C36.4971 23.6878 37.0007 23.1842 37.6221 23.1842C38.2434 23.1842 38.7471 23.6878 38.7471 24.3092C38.7471 24.7619 39.1141 25.1289 39.5668 25.1289C40.0195 25.1289 40.3866 24.7619 40.3866 24.3092V24.2383C40.3866 23.617 40.8902 23.1133 41.5116 23.1133Z"
        fill="url(#paint47_radial_1970_26885)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.5116 23.1133C42.1329 23.1133 42.6366 23.617 42.6366 24.2383V24.3092C42.6366 26.0045 41.2622 27.3789 39.5668 27.3789C37.8714 27.3789 36.4971 26.0045 36.4971 24.3092C36.4971 23.6878 37.0007 23.1842 37.6221 23.1842C38.2434 23.1842 38.7471 23.6878 38.7471 24.3092C38.7471 24.7619 39.1141 25.1289 39.5668 25.1289C40.0195 25.1289 40.3866 24.7619 40.3866 24.3092V24.2383C40.3866 23.617 40.8902 23.1133 41.5116 23.1133Z"
        fill="url(#paint48_radial_1970_26885)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.5116 23.1133C42.1329 23.1133 42.6366 23.617 42.6366 24.2383V24.3092C42.6366 26.0045 41.2622 27.3789 39.5668 27.3789C37.8714 27.3789 36.4971 26.0045 36.4971 24.3092C36.4971 23.6878 37.0007 23.1842 37.6221 23.1842C38.2434 23.1842 38.7471 23.6878 38.7471 24.3092C38.7471 24.7619 39.1141 25.1289 39.5668 25.1289C40.0195 25.1289 40.3866 24.7619 40.3866 24.3092V24.2383C40.3866 23.617 40.8902 23.1133 41.5116 23.1133Z"
        fill="url(#paint49_radial_1970_26885)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.5116 23.1133C42.1329 23.1133 42.6366 23.617 42.6366 24.2383V24.3092C42.6366 26.0045 41.2622 27.3789 39.5668 27.3789C37.8714 27.3789 36.4971 26.0045 36.4971 24.3092C36.4971 23.6878 37.0007 23.1842 37.6221 23.1842C38.2434 23.1842 38.7471 23.6878 38.7471 24.3092C38.7471 24.7619 39.1141 25.1289 39.5668 25.1289C40.0195 25.1289 40.3866 24.7619 40.3866 24.3092V24.2383C40.3866 23.617 40.8902 23.1133 41.5116 23.1133Z"
        fill="url(#paint50_radial_1970_26885)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.5116 23.1133C42.1329 23.1133 42.6366 23.617 42.6366 24.2383V24.3092C42.6366 26.0045 41.2622 27.3789 39.5668 27.3789C37.8714 27.3789 36.4971 26.0045 36.4971 24.3092C36.4971 23.6878 37.0007 23.1842 37.6221 23.1842C38.2434 23.1842 38.7471 23.6878 38.7471 24.3092C38.7471 24.7619 39.1141 25.1289 39.5668 25.1289C40.0195 25.1289 40.3866 24.7619 40.3866 24.3092V24.2383C40.3866 23.617 40.8902 23.1133 41.5116 23.1133Z"
        fill="url(#paint51_radial_1970_26885)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.5116 23.1133C42.1329 23.1133 42.6366 23.617 42.6366 24.2383V24.3092C42.6366 26.0045 41.2622 27.3789 39.5668 27.3789C37.8714 27.3789 36.4971 26.0045 36.4971 24.3092C36.4971 23.6878 37.0007 23.1842 37.6221 23.1842C38.2434 23.1842 38.7471 23.6878 38.7471 24.3092C38.7471 24.7619 39.1141 25.1289 39.5668 25.1289C40.0195 25.1289 40.3866 24.7619 40.3866 24.3092V24.2383C40.3866 23.617 40.8902 23.1133 41.5116 23.1133Z"
        fill="url(#paint52_radial_1970_26885)"
      />
      <path
        d="M27.75 24.3594C26.9216 24.3594 26.25 25.0309 26.25 25.8594C26.25 26.6878 26.9216 27.3594 27.75 27.3594C27.9954 27.3594 28.1932 27.5571 28.1932 27.8026C28.1932 28.048 27.9954 28.2458 27.75 28.2458C26.9216 28.2458 26.25 28.9173 26.25 29.7458C26.25 29.7458 26.25 29.7458 26.25 29.7458C26.25 30.5742 26.9216 31.2458 27.75 31.2458C27.9954 31.2458 28.1932 31.4436 28.1932 31.689C28.1932 31.9345 27.9954 32.1322 27.75 32.1322C26.9216 32.1322 26.25 32.8038 26.25 33.6322C26.25 34.4606 26.9216 35.1322 27.75 35.1322C29.6523 35.1322 31.1932 33.5913 31.1932 31.689C31.1932 30.9679 30.9718 30.2988 30.5932 29.7458C30.9718 29.1928 31.1932 28.5237 31.1932 27.8026C31.1932 25.9003 29.6523 24.3594 27.75 24.3594Z"
        fill="url(#paint53_radial_1970_26885)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(37.9848 14.2508) rotate(132.839) scale(52.2409)"
        >
          <stop stopColor="#FFF478" />
          <stop offset="0.474827" stopColor="#FFB02E" />
          <stop offset="1" stopColor="#F70A8D" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(26.5527 23.4922) rotate(121.67) scale(21.456)"
        >
          <stop stopOpacity="0" />
          <stop offset="0.692364" stopOpacity="0.0118229" />
          <stop offset="1" stopOpacity="0.09" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(24.7516 19.1262) rotate(90) scale(24.9137)"
        >
          <stop offset="0.823911" stopColor="#F59639" stopOpacity="0" />
          <stop offset="1" stopColor="#FF63C4" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(21.2262 19.9505) rotate(82.316) scale(2.19965 1.98832)"
        >
          <stop stopColor="#CA7A1B" stopOpacity="0.85" />
          <stop offset="0.55915" stopColor="#CA7A1B" stopOpacity="0.336184" />
          <stop offset="0.868092" stopColor="#CA7A1B" stopOpacity="0.0764313" />
          <stop offset="1" stopColor="#CA7A1B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint4_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(38.0264 30.8322) rotate(90) scale(1.30628 8.15884)"
        >
          <stop stopColor="#BB582B" stopOpacity="0.34" />
          <stop offset="0.64156" stopColor="#BB582B" stopOpacity="0.0937459" />
          <stop offset="1" stopColor="#BB582B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint5_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(39.5109 30.7453) rotate(83.4781) scale(1.25757 3.05381)"
        >
          <stop stopColor="#BB582B" stopOpacity="0.34" />
          <stop offset="0.204707" stopColor="#BB582B" stopOpacity="0.0937459" />
          <stop offset="1" stopColor="#BB582B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint6_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(37.7072 26.4061) rotate(127.999) scale(2.31897 3.02212)"
        >
          <stop stopColor="#BB582B" stopOpacity="0.47" />
          <stop offset="0.744792" stopColor="#BB582B" stopOpacity="0.105729" />
          <stop offset="1" stopColor="#BB582B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint7_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(41.6449 26.3847) rotate(-164.616) scale(1.43819 1.99532)"
        >
          <stop stopColor="#BB582B" stopOpacity="0.46" />
          <stop offset="0.743212" stopColor="#BB582B" stopOpacity="0.105729" />
          <stop offset="1" stopColor="#BB582B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint8_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(37.1465 24.232) rotate(180) scale(2.45055 3.19359)"
        >
          <stop stopColor="#BB582B" stopOpacity="0.52" />
          <stop offset="0.437811" stopColor="#BB582B" stopOpacity="0.33" />
          <stop offset="0.744792" stopColor="#BB582B" stopOpacity="0.105729" />
          <stop offset="1" stopColor="#BB582B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint9_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.9165 28.3107) rotate(-124.317) scale(1.67501 2.32388)"
        >
          <stop stopColor="#BB582B" stopOpacity="0.35" />
          <stop offset="0.743212" stopColor="#BB582B" stopOpacity="0.105729" />
          <stop offset="1" stopColor="#BB582B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint10_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.4291 23.3071) rotate(107.996) scale(1.11039 1.55502)"
        >
          <stop stopColor="#BB582B" stopOpacity="0.26" />
          <stop offset="0.634263" stopColor="#BB582B" stopOpacity="0.12" />
          <stop offset="1" stopColor="#BB582B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint11_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(38.8619 22.846) rotate(87.7094) scale(1.35173 1.893)"
        >
          <stop stopColor="#BB582B" stopOpacity="0.37" />
          <stop offset="0.567708" stopColor="#BB582B" stopOpacity="0.19" />
          <stop offset="1" stopColor="#BB582B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint12_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(19.375 18.0993) rotate(106.109) scale(1.26391 3.02303)"
        >
          <stop stopColor="#CA7A1B" stopOpacity="0.85" />
          <stop offset="0.53696" stopColor="#CA7A1B" stopOpacity="0.336184" />
          <stop offset="1" stopColor="#CA7A1B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint13_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20.3954 19.0303) rotate(120.805) scale(1.2409 2.99259)"
        >
          <stop stopColor="#CA7A1B" stopOpacity="0.85" />
          <stop offset="0.254368" stopColor="#CA7A1B" stopOpacity="0.336184" />
          <stop offset="1" stopColor="#CA7A1B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint14_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(34.9401 19.7083) rotate(118.322) scale(2.04632)"
        >
          <stop stopColor="#CA7A1B" stopOpacity="0.8" />
          <stop offset="0.695556" stopColor="#CA7A1B" stopOpacity="0.158114" />
          <stop offset="1" stopColor="#CA7A1B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint15_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(35.2086 20.3059) rotate(126.357) scale(1.85264 1.81578)"
        >
          <stop stopColor="#CA7A1B" stopOpacity="0.38" />
          <stop offset="0.556517" stopColor="#CA7A1B" stopOpacity="0.158114" />
          <stop offset="1" stopColor="#CA7A1B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint16_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(33.9354 18.6279) rotate(124.482) scale(1.19429 3.24962)"
        >
          <stop stopColor="#CA7A1B" stopOpacity="0.8" />
          <stop offset="0.727564" stopColor="#CA7A1B" stopOpacity="0.158114" />
          <stop offset="1" stopColor="#CA7A1B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint17_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(37.0067 14.7735) rotate(105.526) scale(2.34116)"
        >
          <stop stopColor="#E37847" stopOpacity="0.37" />
          <stop offset="0.427196" stopColor="#E37847" stopOpacity="0.268229" />
          <stop offset="1" stopColor="#E37847" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint18_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(32.7731 30.6285) rotate(89.2151) scale(1.63919 7.81766)"
        >
          <stop stopColor="#BB582B" stopOpacity="0.32" />
          <stop offset="0.702064" stopColor="#BB582B" stopOpacity="0.15" />
          <stop offset="1" stopColor="#BB582B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint19_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(31.4347 30.5882) rotate(91.0809) scale(1.48389 4.69325)"
        >
          <stop stopColor="#BB582B" stopOpacity="0.22" />
          <stop offset="0.702064" stopColor="#BB582B" stopOpacity="0.09" />
          <stop offset="1" stopColor="#BB582B" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint20_linear_1970_26885"
          x1="5.25"
          y1="28.418"
          x2="24.75"
          y2="6.19922"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5092FF" />
          <stop offset="0.859375" stopColor="#5092FF" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint21_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(42.5859 13.3711) rotate(-129.912) scale(4.85841 20.5786)"
        >
          <stop offset="0.359375" stopColor="#FF42B1" />
          <stop offset="0.854167" stopColor="#F70A8D" />
          <stop offset="1" stopColor="#CE336E" />
        </radialGradient>
        <radialGradient
          id="paint22_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(15.5859 41.8711) rotate(-129.912) scale(4.85841 20.5786)"
        >
          <stop offset="0.359375" stopColor="#FF42B1" />
          <stop offset="0.854167" stopColor="#F70A8D" />
          <stop offset="1" stopColor="#CE336E" />
        </radialGradient>
        <radialGradient
          id="paint23_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.5 41.25) rotate(135) scale(2.12132)"
        >
          <stop stopColor="#45C9FF" />
          <stop offset="1" stopColor="#3E80FF" />
        </radialGradient>
        <radialGradient
          id="paint24_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(15 29.25) rotate(135) scale(2.12132)"
        >
          <stop stopColor="#45C9FF" />
          <stop offset="1" stopColor="#3E80FF" />
        </radialGradient>
        <radialGradient
          id="paint25_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(37.5 3.75) rotate(135) scale(2.12132)"
        >
          <stop stopColor="#45C9FF" />
          <stop offset="1" stopColor="#3E80FF" />
        </radialGradient>
        <radialGradient
          id="paint26_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(32.2195 19.819) rotate(-91.5327) scale(9.80881 13.7499)"
        >
          <stop offset="0.166667" stopColor="#482641" />
          <stop offset="0.276042" stopColor="#503A4A" />
          <stop offset="0.401042" stopColor="#483637" />
        </radialGradient>
        <radialGradient
          id="paint27_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(18.4539 19.819) rotate(-91.5327) scale(9.80881 13.7499)"
        >
          <stop offset="0.166667" stopColor="#482641" />
          <stop offset="0.276042" stopColor="#503A4A" />
          <stop offset="0.401042" stopColor="#483637" />
        </radialGradient>
        <radialGradient
          id="paint28_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(32.9063 22.9802) rotate(9.65183) scale(6.98956 11.8358)"
        >
          <stop offset="0.328125" stopColor="#1B6AD1" />
          <stop offset="1" stopColor="#2A80F0" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint29_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.0313 24.0818) rotate(27.8973) scale(1.80332 2.59653)"
        >
          <stop stopColor="#1B6AD1" />
          <stop offset="1" stopColor="#2A80F0" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint30_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(42.422 33.0115) rotate(-92.9038) scale(6.47706 26.6526)"
        >
          <stop stopColor="#1B6AD1" />
          <stop offset="0.601914" stopColor="#2A80F0" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint31_linear_1970_26885"
          x1="34.6124"
          y1="28.6238"
          x2="34.6124"
          y2="30.8711"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A0D9FF" stopOpacity="0" />
          <stop offset="0.238015" stopColor="#A0D9FF" stopOpacity="0.413046" />
          <stop offset="0.360818" stopColor="#A0D9FF" stopOpacity="0.52" />
          <stop offset="0.465258" stopColor="#A0D9FF" stopOpacity="0.534742" />
          <stop offset="0.590242" stopColor="#A0D9FF" stopOpacity="0.411458" />
          <stop offset="0.85064" stopColor="#A0D9FF" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint32_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(33.5675 18.7865) rotate(9.29314) scale(6.63602 10.8346)"
        >
          <stop offset="0.328125" stopColor="#1B6AD1" />
          <stop offset="1" stopColor="#2A80F0" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint33_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.3391 19.7938) rotate(26.9934) scale(1.69991 2.39394)"
        >
          <stop stopColor="#1B6AD1" />
          <stop offset="1" stopColor="#2A80F0" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint34_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(42.6112 27.9596) rotate(-93.0178) scale(5.92357 25.3283)"
        >
          <stop stopColor="#1B6AD1" />
          <stop offset="0.601914" stopColor="#2A80F0" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint35_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(41.4629 24.5903) rotate(-54.7725) scale(4.63909 4.39742)"
        >
          <stop stopColor="#A0D9FF" stopOpacity="0" />
          <stop offset="0.623619" stopColor="#A0D9FF" stopOpacity="0.183577" />
          <stop offset="0.727437" stopColor="#A0D9FF" stopOpacity="0.47" />
          <stop offset="0.798816" stopColor="#A0D9FF" stopOpacity="0.5" />
          <stop offset="0.83338" stopColor="#A0D9FF" stopOpacity="0.52" />
          <stop offset="0.8682" stopColor="#A0D9FF" stopOpacity="0.46" />
          <stop offset="1" stopColor="#A0D9FF" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint36_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(41.5782 19.5233) rotate(98.9306) scale(0.415189 1.57101)"
        >
          <stop stopColor="#00A6ED" />
          <stop offset="0.739378" stopColor="#00A6ED" />
          <stop offset="1" stopColor="#00A6ED" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint37_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(32.9062 22.9802) rotate(9.65183) scale(6.98956 11.8358)"
        >
          <stop offset="0.328125" stopColor="#1B6AD1" />
          <stop offset="1" stopColor="#2A80F0" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint38_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.0312 24.0818) rotate(27.8973) scale(1.80332 2.59653)"
        >
          <stop stopColor="#1B6AD1" />
          <stop offset="1" stopColor="#2A80F0" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint39_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(42.4218 33.0115) rotate(-92.9038) scale(6.47706 26.6526)"
        >
          <stop stopColor="#1B6AD1" />
          <stop offset="0.601914" stopColor="#2A80F0" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint40_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.34 24.9338) rotate(37.0007) scale(5.90888 5.66478)"
        >
          <stop offset="0.626079" stopColor="#A0D9FF" stopOpacity="0" />
          <stop offset="0.779522" stopColor="#A0D9FF" stopOpacity="0.47" />
          <stop offset="0.798816" stopColor="#A0D9FF" stopOpacity="0.5" />
          <stop offset="0.83338" stopColor="#A0D9FF" stopOpacity="0.52" />
          <stop offset="0.8682" stopColor="#A0D9FF" stopOpacity="0.46" />
          <stop offset="1" stopColor="#A0D9FF" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint41_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(46.1155 24.2788) rotate(161.94) scale(0.414952 1.77413)"
        >
          <stop stopColor="#00A6ED" />
          <stop offset="0.628895" stopColor="#00A6ED" stopOpacity="0.537426" />
          <stop offset="1" stopColor="#00A6ED" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint42_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(31.2206 18.8481) rotate(9.87654) scale(5.7025 9.8723)"
        >
          <stop offset="0.328125" stopColor="#1B6AD1" />
          <stop offset="1" stopColor="#2A80F0" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint43_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(37.0297 19.7675) rotate(28.4569) scale(1.47797 2.15593)"
        >
          <stop stopColor="#1B6AD1" />
          <stop offset="1" stopColor="#2A80F0" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint44_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(38.9788 27.2208) rotate(-92.8366) scale(5.40588 21.7313)"
        >
          <stop stopColor="#1B6AD1" />
          <stop offset="0.601914" stopColor="#2A80F0" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint45_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(41.0561 24.1211) rotate(-130.314) scale(4.22232 4.07312)"
        >
          <stop stopColor="#A0D9FF" stopOpacity="0" />
          <stop offset="0.493221" stopColor="#A0D9FF" stopOpacity="0.0663814" />
          <stop offset="0.735331" stopColor="#A0D9FF" stopOpacity="0.47" />
          <stop offset="0.798816" stopColor="#A0D9FF" stopOpacity="0.5" />
          <stop offset="0.83338" stopColor="#A0D9FF" stopOpacity="0.52" />
          <stop offset="0.8682" stopColor="#A0D9FF" stopOpacity="0.46" />
          <stop offset="0.941594" stopColor="#A0D9FF" stopOpacity="0.203845" />
          <stop offset="1" stopColor="#A0D9FF" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint46_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(38.8841 23.7572) rotate(-158.86) scale(0.762348 1.43568)"
        >
          <stop stopColor="#00A6ED" />
          <stop offset="0.56047" stopColor="#00A6ED" stopOpacity="0.610887" />
          <stop offset="1" stopColor="#00A6ED" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint47_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(30.6753 22.6509) rotate(6.46289) scale(6.23827 7.14802)"
        >
          <stop offset="0.328125" stopColor="#1B6AD1" />
          <stop offset="1" stopColor="#2A80F0" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint48_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(39.2353 28.6615) rotate(-94.3549) scale(3.88724 23.9374)"
        >
          <stop stopColor="#1B6AD1" />
          <stop offset="0.601914" stopColor="#2A80F0" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint49_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.6285 22.8755) rotate(31.517) scale(1.17884 3.73583)"
        >
          <stop stopColor="#1B6AD1" />
          <stop offset="1" stopColor="#2A80F0" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint50_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(39.6784 23.8789) rotate(96.2035) scale(3.02299 2.69683)"
        >
          <stop stopColor="#A0D9FF" stopOpacity="0" />
          <stop offset="0.418873" stopColor="#A0D9FF" stopOpacity="0.0663814" />
          <stop offset="0.505564" stopColor="#A0D9FF" stopOpacity="0.244587" />
          <stop offset="0.583785" stopColor="#A0D9FF" stopOpacity="0.44" />
          <stop offset="0.682644" stopColor="#A0D9FF" stopOpacity="0.54" />
          <stop offset="0.725141" stopColor="#A0D9FF" stopOpacity="0.54" />
          <stop offset="0.772791" stopColor="#A0D9FF" stopOpacity="0.52" />
          <stop offset="0.816441" stopColor="#A0D9FF" stopOpacity="0.46" />
          <stop offset="0.91367" stopColor="#A0D9FF" stopOpacity="0.203845" />
          <stop offset="1" stopColor="#A0D9FF" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint51_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(41.9604 23.5846) rotate(90) scale(0.750869 1.57836)"
        >
          <stop stopColor="#00A6ED" />
          <stop offset="1" stopColor="#00A6ED" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint52_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(41.8156 23.7991) rotate(135) scale(0.628027 0.622291)"
        >
          <stop stopColor="#A0D9FF" stopOpacity="0.5" />
          <stop offset="0.369792" stopColor="#A0D9FF" stopOpacity="0.38" />
          <stop offset="0.640625" stopColor="#A0D9FF" stopOpacity="0.22" />
          <stop offset="0.796875" stopColor="#A0D9FF" stopOpacity="0.0988406" />
          <stop offset="1" stopColor="#A0D9FF" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint53_radial_1970_26885"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(26.25 29.7458) scale(8.88944 9.68649)"
        >
          <stop stopColor="#3E3537" />
          <stop offset="0.399715" stopColor="#442932" />
          <stop offset="0.675586" stopColor="#6A5662" />
        </radialGradient>
      </defs>
    </svg>
  );
}
