import { useParams } from 'react-router-dom';
import {
  Avatar,
  Button,
  Card,
  color,
  Modal,
  Icon,
  spacing,
  Divider,
  ActionList,
  usePopover
} from '@uniquegood/realworld-studio-design';
import { css } from '@emotion/react';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useModalState, useRequest } from '@/hooks';
import LoadingSpinner from '@/components/LoadingSpinner';

import pinIcon from '../assets/pinIcon.png';
import {
  Flex,
  FlexWrap,
  HideElementStyle,
  Identity,
  MarginBottom24,
  MarginLeft8,
  MarginRight24,
  MarginTop24
} from '@/styles';

import Comments from '../../components/Comments';

import { ReactComponent as ChatRegular } from '../../assets/chat_regular.svg';
import { coreApi } from '@/api';
import { timeForToday } from '../../utils';
import WritePost from '../../components/WritePost';

import Image from '../../components/Image';

interface PinnedPostProps {
  mutate(): unknown;
}

export default function PinnedPost({ mutate: mutateFeeds }: PinnedPostProps) {
  const { appId } = useParams<AppParam>();

  const { data, mutate } = useRequest<CreatorFeeds>(
    `/api/${appId}/community/creatorFeeds?page=1&take=1&isPin=true`,
    {},
    { params: { filteringBlocks: false } }
  );

  const ref = useRef<HTMLElement>(null);
  const [showDetails, setShowDetails] = useState(false);

  const { modal, openModal, closeModal } = useModalState();

  const [isOpen, setIsOpen] = useState(false);
  const handleClosePopover = useCallback(() => {
    setIsOpen(false);
  }, []);
  const { popover, anchor } = usePopover({
    positionX: 'right',
    positionY: 'bottom',
    isOpen,
    onClose: handleClosePopover
  });

  const [isEditMode, setIsEditMode] = useState(false);

  useLayoutEffect(() => {
    if (!data) return;
    if (data.contents.length === 0) return;

    if (ref.current) {
      const numberOfLines = ref.current.offsetHeight / 24;

      setShowDetails(numberOfLines < 5);
    }
  }, [data]);

  if (!data) return <LoadingSpinner />;

  if (data.contents.length === 0) return null;

  const post = data.contents[0];

  if (isEditMode)
    return (
      <WritePost
        onClose={() => setIsEditMode(false)}
        feedsType="creatorFeeds"
        post={post}
        mutate={mutate}
      />
    );

  return (
    <>
      <Card cssStyle={[cardLayout, showDetails ? Identity : hideContent]}>
        <div css={headerLayout}>
          <div css={Flex}>
            <Avatar
              source={post.writer.profileUrl}
              userName={post.writer.name}
              userEmail={timeForToday(post.createdAt)}
            />
            <img width="20px" height="20px" css={MarginLeft8} src={pinIcon} alt="핀" />
            {post.isCreator &&
              'channelType' in post.writer &&
              post.writer.channelType === 'Realworld' && (
                <Icon icon="check_circle_solid" size="20px" cssStyle={officialIconLayout} />
              )}
          </div>
          <Button
            ariaExpanded={anchor['aria-expanded']}
            ref={anchor.ref}
            icon="ellipsis_v_regular"
            type="plain"
            onClick={() => setIsOpen(!isOpen)}
          />
        </div>
        <article ref={ref} css={[MarginTop24, MarginBottom24, articleLayout]}>
          {post.content}
        </article>
        {post.images && post.images.length > 0 && (
          <div css={[FlexWrap, MarginBottom24, imageGaps]}>
            {post.images.map((image) => (
              <Image key={image.id} height="100px" file={image} />
            ))}
          </div>
        )}
        <div css={Flex}>
          <Button
            size="medium"
            cssStyle={[likeButtonStyle, MarginRight24]}
            type="plain"
            disabled
            icon={post.isLiked ? 'heart_solid' : 'heart_regular'}
          >
            {post.likeCount?.toString() ?? 0}
          </Button>
          <Button
            size="medium"
            cssStyle={likeButtonStyle}
            type="plain"
            icon={<ChatRegular />}
            onClick={() => {
              openModal({
                title: '댓글 펼쳐보기',
                size: 'medium',
                children: (
                  <Comments feedsType="creatorFeeds" feedId={post.id} mutateFeeds={mutateFeeds} />
                ),
                secondaryAction: {
                  content: '닫기',
                  onAction: closeModal
                }
              });
            }}
          >
            {post.commentCount?.toString() ?? 0}
          </Button>
        </div>
        <div css={showDetails ? HideElementStyle : fadeOutContent}>
          {/* TODO: tabIndex -1 넣기, 디자인시스템에서 해당 어트리뷰트 주입을 지원하고 있지 않음 */}
          <Button
            type="basic"
            cssStyle={buttonLayout}
            onClick={() => {
              setShowDetails(true);
            }}
          >
            더보기
          </Button>
        </div>
        <Modal {...modal} />
      </Card>
      <div {...popover}>
        <ActionList
          onActionAnyItem={handleClosePopover}
          items={[
            {
              content: '수정',
              onAction: () => {
                setIsEditMode(true);
              }
            },
            {
              content: '고정 해제',
              onAction: async () => {
                await coreApi.put(`/api/${appId}/community/creatorFeeds/${post.id}/pin`);
                mutate();
                mutateFeeds();
              }
            },
            {
              content: '삭제',
              onAction: async () => {
                await coreApi.delete(`/api/${appId}/community/creatorFeeds/${post.id}`);
                mutate();
              },
              destructive: true
            }
          ]}
        />
      </div>
      <Divider />
    </>
  );
}

const cardLayout = css`
  > :first-of-type {
    position: relative;
  }
`;

const hideContent = css`
  > :first-of-type {
    height: 190px;
    overflow: hidden;
  }
`;

const fadeOutContent = css`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 24px;

  background-image: linear-gradient(rgba(255, 255, 255, 0.5) 0%, rgb(255, 255, 255) 100%);
`;

const officialIconLayout = css`
  margin-top: 0;
  margin-left: ${spacing.margin.small};
`;

const headerLayout = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const buttonLayout = css`
  bottom: ${spacing.margin.xsmall};
  left: 42%;
  position: absolute;
`;

const likeButtonStyle = css`
  .ButtonContent .Icon {
    width: 20px;
    height: 19px;
  }

  svg {
    color: ${color.interactive_primary_default};
  }

  .Text {
    color: ${color.text_subdued};
  }

  align-items: center;
`;

const articleLayout = css`
  overflow-wrap: anywhere;
  white-space: pre-line;
`;

const imageGaps = css`
  > img:not(:last-of-type) {
    margin-right: ${spacing.margin.xsmall};
  }
`;
