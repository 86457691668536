import { color, spacing, textStyleButton } from '@uniquegood/realworld-studio-design';
import { css } from '@emotion/react';

export interface HeaderSwitchTabProps<T extends string> {
  tabs: { value: T; label: string }[];
  setSelectedTab(value: T): void;
  selectedTab: T;
}

export default function SwitchTab<T extends string>({
  tabs,
  setSelectedTab,
  selectedTab
}: HeaderSwitchTabProps<T>) {
  return (
    <div css={containerStyle} role="tablist" aria-orientation="horizontal">
      {tabs.map((tab) => (
        <button
          key={tab.value}
          type="button"
          onClick={() => setSelectedTab(tab.value)}
          css={tabButtonStyle}
          aria-pressed={tab.value === selectedTab}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
}

const containerStyle = css`
  display: flex;
  gap: ${spacing.margin.xlarge2};
`;

const tabButtonStyle = css`
  border: none;
  background: none;
  padding-bottom: 15px;
  cursor: pointer;
  ${textStyleButton}

  :focus-visible {
    box-shadow: 0 0 0 4px ${color.focused_default} inset;
    outline: none;
  }

  &[aria-pressed='true'] {
    border-bottom: 3px solid ${color.interactive_primary_default};
    color: ${color.interactive_primary_default};
    padding-bottom: 12px;
    cursor: default;
  }
`;
