import {
  Button,
  ButtonGroup,
  color,
  FormGroup,
  Icon,
  Modal
} from '@uniquegood/realworld-studio-design';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { css } from '@emotion/react';
import { useModalState } from '@/hooks';
import { MarginBottom10, TextAlignCenter } from '@/styles';
import { ClickEventName, track } from '@/track';

type GameTypeLabel = '온라인' | '오프라인';

export default function SelectGameType() {
  const { watch, setValue, getValues, control } = useFormContext<ProjectFormData>();

  const { modal, openModal, closeModal } = useModalState();

  const gameType = watch('projectType');
  // const gameType = 'Online' as Project['gameType'];

  const selectedGameTypeLabel: GameTypeLabel | undefined = useMemo(() => {
    if (!gameType) return undefined;

    if (gameType === 'Online' || gameType === 'OnlineKit') return '온라인';
    if (gameType === 'Offline' || gameType === 'OfflineKit') return '오프라인';

    setValue('projectType', 'Online');

    throw Error('예상하는 게임 타입에서 벗어난 값입니다.');
  }, [gameType]);

  function setGameTypeLabel(newGameTypeLabel: GameTypeLabel) {
    track.onClick({
      clickEventName: ClickEventName.click_gamesetting_basicinformation_button_gametype,
      params: { gameType: newGameTypeLabel }
    });

    if (newGameTypeLabel === '온라인') {
      setValue('projectType', getValues('kitRequired') ? 'OnlineKit' : 'Online');
    } else if (newGameTypeLabel === '오프라인') {
      setValue('projectType', getValues('kitRequired') ? 'OfflineKit' : 'Offline');
    }
  }

  return (
    <>
      <Modal {...modal} />
      <FormGroup label="게임 타입" isHorizontal requiredIndicator>
        <ButtonGroup segmented>
          <Button
            cssStyle={selectedGameTypeLabel === '온라인' ? undefined : defaultIconColor}
            onClick={() => setGameTypeLabel('온라인')}
            icon="mobile_alt_solid"
            type={selectedGameTypeLabel === '온라인' ? 'primary' : 'outlineBasic'}
          >
            온라인
          </Button>
          <Button
            cssStyle={selectedGameTypeLabel === '오프라인' ? undefined : defaultIconColor}
            onClick={() => setGameTypeLabel('오프라인')}
            icon="tree_solid"
            type={selectedGameTypeLabel === '오프라인' ? 'primary' : 'outlineBasic'}
          >
            오프라인
          </Button>
        </ButtonGroup>
      </FormGroup>
    </>
  );
}

const defaultIconColor = css`
  svg {
    color: ${color.icon_default};
    fill: ${color.icon_default};
  }
`;
