import { useHistory, useLocation, useParams, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  ActionList,
  Button,
  ButtonGroup,
  Card,
  color,
  Icon,
  iconSize,
  Modal,
  Popover,
  spacing,
  toast,
  Typography
} from '@uniquegood/realworld-studio-design';
import CopyToClipboard from 'react-copy-to-clipboard';
import { css } from '@emotion/react';
import { useForm } from 'react-hook-form';
import useLocalStorage from 'react-use-localstorage';
import { useModalState, useRequest, useRestrictProjectRoute } from '@/hooks';
import { keyPressHandler, makeFormData } from '@/utils';
import LoadingSpinner from '@/components/LoadingSpinner';
import { Container } from '@/components/containers';
import { BottomPadding } from '@/styles/containerStyles';
import {
  AlignItemsCenter,
  CardItemGap,
  CursorPointer,
  Flex,
  FlexWrap,
  HideElementStyle,
  JustifySpaceBetween,
  MarginRight8,
  MarginVertical24,
  PositionRelative,
  TableStyle,
  textShortening
} from '@/styles';
import { coreApi } from '@/api';
import CreateDirectoryForm from './components/CreateDirectoryForm';
import FileModifyForm from './components/FileModifyForm';

import * as icon from './assets/icon';
import { ClickEventName, PageViewEventName, track, UploadEventName } from '@/track';

type CreateDirectoryFormFields = { name: string };

export type ModifyFileFormFields = Pick<
  FileResponseModel,
  'id' | 'metadata' | 'virtualDirectory'
> & {
  type: 'metadata' | 'virtualDirectory';
};

export type FormData = CreateDirectoryFormFields & ModifyFileFormFields;

export type SortBy =
  | 'name-ascending'
  | 'name-descending'
  | 'created-date-ascending'
  | 'created-date-descending';

const sortByToLocalString: Record<SortBy, string> = {
  'name-ascending': '이름 오름차순',
  'name-descending': '이름 내림차순',
  'created-date-ascending': '생성일 오름차순',
  'created-date-descending': '생성일 내림차순'
};

interface SortFilesProps {
  files: FileResponseModel[];
  sortBy: SortBy;
}

function sortFiles({ files, sortBy }: SortFilesProps) {
  if (sortBy === 'name-ascending')
    return files.sort((a, b) =>
      (a.isDirectory ? a.fileName : a.originalName).localeCompare(
        b.isDirectory ? b.fileName : b.originalName
      )
    );
  if (sortBy === 'name-descending')
    return files.sort((a, b) =>
      (b.isDirectory ? b.fileName : b.originalName).localeCompare(
        a.isDirectory ? a.fileName : a.originalName
      )
    );

  if (sortBy === 'created-date-ascending')
    return files.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
  if (sortBy === 'created-date-descending')
    return files.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

  throw Error();
}

export default function Files() {
  const { appId, projectId } = useParams<AppParam>();

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    track.onPageView({ pageViewEventName: PageViewEventName.view_editgame_filestorage });
  }, []);

  const currentPageRoute = `/apps/${appId}/projects/${projectId}/files`;
  const [isFileUploading, setIsFileUploading] = useState(false);
  const { data: files, mutate } = useRequest<FileResponseModel[]>(
    `/apps/${appId}/projects/${projectId}/files${location.search}`
  );

  const { handleSubmit, reset, control, setError, clearErrors, formState } = useForm<FormData>();

  const [sortBy, setSortBy] = useLocalStorage<SortBy>('sortBy', 'name-ascending');

  const [sortByPopoverActive, setSortByPopoverActive] = useState(false);

  const toggleSortByPopoverActive = () => setSortByPopoverActive((popoverActive) => !popoverActive);

  const directory = useMemo(() => getDirQuery(location.search), [location.search]);

  const { modal, openModal, closeModal } = useModalState();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const redirectTo = useRestrictProjectRoute();

  const goToDir = (name: string) => () => {
    history.push(`${currentPageRoute}/?dir=${directory ? `${directory}/${name}` : name}`);
  };

  const goToUpperDir = () => {
    const lastIndex = directory.lastIndexOf('/');
    history.push(`${currentPageRoute}/?dir=${directory.substring(0, lastIndex)}`);
  };

  function toggleURLCopy() {
    toast({ message: '파일 URL을 복사했어요' });
  }

  function deleteFile({ id, isDirectory }: FileResponseModel) {
    const typeToLocalString = isDirectory ? '폴더를' : '파일을';
    openModal({
      size: 'small',
      title: `정말 ${typeToLocalString} 삭제하시겠어요?`,
      children: `${typeToLocalString} 삭제하면 더 이상 리스트에서 보여지지 않아요`,
      primaryAction: {
        type: 'destructive',
        icon: 'trash_alt_solid',
        content: '삭제',
        onAction: async () => {
          try {
            await coreApi.delete(`${currentPageRoute}/${id}`);
            await mutate();

            closeModal();
            toast({ message: `${typeToLocalString} 삭제했어요` });
          } catch {
            // Empty
          }
        }
      },
      secondaryAction: {
        content: '취소',
        onAction: closeModal
      }
    });
  }

  function createDirectory() {
    const onSubmit = handleSubmit(async ({ name }) => {
      try {
        await coreApi.post(
          `/apps/${appId}/projects/${projectId}/files/directories`,
          makeFormData({ name, dir: directory })
        );
        await mutate();
        closeModal();
        toast({ message: '폴더를 생성했어요' });
      } catch {
        // Empty
      }
    });

    reset({ name: '' });

    openModal({
      size: 'small',
      title: '새 폴더 생성',
      children: (
        <CreateDirectoryForm
          control={control}
          setError={setError}
          clearErrors={clearErrors}
          onSubmit={onSubmit}
        />
      ),
      primaryAction: {
        icon: 'save_solid',
        content: '저장',
        htmlType: 'submit',
        form: 'modal'
      },
      secondaryAction: {
        content: '취소',
        onAction: closeModal
      }
    });
  }

  function modifyFile(props: ModifyFileFormFields) {
    const onSubmit = handleSubmit(async (data) => {
      // if (disabledSubmitButton) return;

      try {
        await coreApi.put(`/apps/${appId}/projects/${projectId}/files/${props.id}`, data);
        await mutate();

        closeModal();
        toast({
          message: props.type === 'metadata' ? '파일을 저장했어요' : '파일 위치를 변경했어요'
        });
      } catch {
        // Empty
      }
    });

    reset(props);

    openModal({
      size: 'small',
      title: `파일 ${props.type === 'metadata' ? '설명 수정' : '위치 이동'}`,
      children: <FileModifyForm control={control} onSubmit={onSubmit} type={props.type} />,
      primaryAction: {
        icon: 'save_solid',
        content: '저장',
        htmlType: 'submit',
        form: 'modal'
      },
      secondaryAction: {
        content: '취소',
        onAction: closeModal
      }
    });
  }

  function uploadFile() {
    if (!fileInputRef.current) throw Error('타겟 Ref 를 연결하지 못했습니다');

    fileInputRef.current.click();
  }

  async function onFileUpload(files: FileList) {
    setIsFileUploading(true);
    await Promise.all(
      [...files].map((file) =>
        coreApi.post<FileResponseModel>(
          `/apps/${appId}/projects/${projectId}/files`,
          makeFormData({ file, dir: directory })
        )
      )
    );

    await mutate();

    track.onUpload({
      uploadEventName: UploadEventName.upload_editgame_mission_file
    });

    toast({ message: '파일을 저장했어요' });

    setIsFileUploading(false);
  }

  if (!files || redirectTo === undefined)
    return (
      <Container cssStyle={[BottomPadding, ContainerStyle]}>
        <Card header={{ prefix: <Icon icon="folder_open_solid" />, content: '파일 관리' }}>
          <LoadingSpinner />
        </Card>
      </Container>
    );

  if (typeof redirectTo === 'string') <Redirect to={redirectTo} />;

  const disabledSubmitButton = !!formState.errors.name;

  return (
    <Container cssStyle={[BottomPadding, ContainerStyle]}>
      <Card
        header={{ prefix: <Icon icon="folder_open_solid" />, content: '파일 관리' }}
        cssStyle={CardItemGap}
      >
        <div css={[Flex, FlexWrap, JustifySpaceBetween, AlignItemsCenter]}>
          <div css={MarginRight8}>
            <Typography as="span" type="heading" cssStyle={MarginRight8}>
              {directory || '/'}
            </Typography>
            <Typography as="span" type="body">
              파일 위치
            </Typography>
          </div>

          <Popover
            active={sortByPopoverActive}
            activator={
              <Button
                size="small"
                type="outlineBasic"
                icon={icon.sortBy[sortBy]}
                onClick={toggleSortByPopoverActive}
                cssStyle={[IconSortLayout, IconSortColor]}
              >
                {sortByToLocalString[sortBy]}
              </Button>
            }
            onClose={toggleSortByPopoverActive}
          >
            <ActionList
              size="small"
              cssStyle={IconSortColor}
              items={[
                {
                  prefix: icon.sortBy['name-ascending'],
                  content: '이름 오름차순',
                  onAction: () => setSortBy('name-ascending'),
                  active: sortBy === 'name-ascending'
                },
                {
                  prefix: icon.sortBy['name-descending'],
                  content: '이름 내림차순',
                  onAction: () => setSortBy('name-descending'),
                  active: sortBy === 'name-descending'
                },
                {
                  prefix: icon.sortBy['created-date-ascending'],
                  content: '생성일 오름차순',
                  onAction: () => setSortBy('created-date-ascending'),
                  active: sortBy === 'created-date-ascending'
                },
                {
                  prefix: icon.sortBy['created-date-descending'],
                  content: '생성일 내림차순',
                  onAction: () => setSortBy('created-date-descending'),
                  active: sortBy === 'created-date-descending'
                }
              ]}
            />
          </Popover>
        </div>
        <table css={[TableStyle, ExtendTableStyle, MarginVertical24]}>
          <thead>
            <tr>
              <th>이름</th>
              <th>설명</th>
              <th hidden>ID</th>
              <th>동작</th>
            </tr>
          </thead>
          <tbody>
            {directory !== '' && (
              <tr css={GoToUpperDirectoryRowStyle}>
                <td css={MarginLeft14}>
                  <Button
                    type="plain"
                    size="medium"
                    cssStyle={GoToUpperDirectoryButtonStyle}
                    onClick={goToUpperDir}
                  >
                    상위 폴더로 이동
                  </Button>
                </td>
                <td />
                <td hidden />
                <td />
              </tr>
            )}
            {files.length === 0 && (
              <tr>
                <Typography as="td" type="body" cssStyle={EmptyStateStyle}>
                  아직 올려둔 파일이 없어요
                </Typography>
                <td />
                <td />
                <td />
                <td />
              </tr>
            )}
            {sortFiles({ files, sortBy }).map((file) => (
              <tr key={file.id}>
                {file.isDirectory ? (
                  <>
                    <td aria-label={file.fileName} css={NameCellStyle}>
                      <span
                        role="button"
                        tabIndex={0}
                        onKeyPress={keyPressHandler(goToDir(file.fileName))}
                        css={[CursorPointer, NameCellStyle]}
                        onClick={goToDir(file.fileName)}
                      >
                        {icon.folder}
                        {file.fileName}
                      </span>
                    </td>
                    <td />
                    <td hidden>{file.id}</td>
                  </>
                ) : (
                  <>
                    <td css={PositionRelative}>
                      <span css={NameCellStyle}>
                        {icon.file}

                        <a
                          href={file.url}
                          target="_blank"
                          rel="noreferrer"
                          css={[linkCssStyle, textShortening]}
                        >
                          {file.originalName}
                        </a>
                      </span>
                      <CopyToClipboard text={file.url} onCopy={toggleURLCopy}>
                        <Button
                          cssStyle={LinkButtonStyle}
                          size="small"
                          type="plain"
                          accessibilityLabel="파일 URL 복사"
                          icon="link_solid"
                        />
                      </CopyToClipboard>
                    </td>
                    <td>{file.metadata}</td>
                    <td hidden>{file.id}</td>
                  </>
                )}
                <td role="group" css={ButtonGroupGap}>
                  {!file.isDirectory && (
                    <>
                      <Button
                        size="small"
                        onClick={() =>
                          modifyFile({
                            id: file.id,
                            metadata: file.metadata,
                            virtualDirectory: directory,
                            type: 'metadata'
                          })
                        }
                      >
                        수정
                      </Button>
                      <Button
                        size="small"
                        onClick={() =>
                          modifyFile({
                            id: file.id,
                            metadata: file.metadata,
                            virtualDirectory: directory,
                            type: 'virtualDirectory'
                          })
                        }
                      >
                        이동
                      </Button>
                    </>
                  )}
                  <Button size="medium" type="plainDestructive" onClick={() => deleteFile(file)}>
                    삭제
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ButtonGroup buttonSpacing="extraTight">
          <Button
            icon="upload_solid"
            size="large"
            type="primary"
            loading={isFileUploading}
            onClick={uploadFile}
          >
            파일 업로드
          </Button>
          <Button
            icon="plus_solid"
            size="large"
            onClick={() => {
              createDirectory();
              track.onClick({
                clickEventName: ClickEventName.click_editgame_mission_file_newfolder
              });
            }}
          >
            새 폴더
          </Button>
          <input
            aria-label="파일 업로드"
            multiple
            type="file"
            ref={fileInputRef}
            onChange={(event) => {
              const { files } = event.target;

              if (files) {
                onFileUpload(files);
              }
            }}
            css={HideElementStyle}
          />
        </ButtonGroup>
      </Card>
      <Modal
        {...modal}
        primaryAction={{
          ...modal.primaryAction,
          loading: formState.isSubmitting,
          disabled: disabledSubmitButton
        }}
      />
    </Container>
  );
}

export function getDirQuery(query: string) {
  const { dir } = queryString.parse(query);

  if (typeof dir === 'string') return dir;

  return '';
}

const linkCssStyle = css`
  text-decoration: underline;
  color: ${color.action_dark_blue_default};
  :hover {
    color: ${color.interactive_darkblue_hovered};
  }
`;

const ContainerStyle = css`
  min-width: 1008px;
`;

const EmptyStateStyle = css`
  && {
    padding: 16px;
  }
  color: ${color.text_disabled};
`;

const ExtendTableStyle = css`
  table-layout: fixed;
  white-space: nowrap;

  width: 100%;

  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    padding: ${spacing.margin.small2} ${spacing.margin.large};
  }

  th {
    padding: 15.25px ${spacing.margin.large};

    :nth-of-type(1) {
      width: 400px;
    }

    :nth-of-type(2),
    :nth-of-type(4) {
      width: 200px;
    }
  }
`;

const NameCellStyle = css``;

const GoToUpperDirectoryRowStyle = css`
  > td {
    padding: 3px ${spacing.margin.large};
  }
`;

const GoToUpperDirectoryButtonStyle = css`
  color: ${color.palette_primary_purple_100};
`;

const MarginLeft14 = css`
  && {
    padding-left: 14px;
  }
`;

const ButtonGroupGap = css`
  button:nth-of-type(2) {
    margin: 0 ${spacing.margin.xsmall};
  }
`;

const LinkButtonStyle = css`
  position: absolute;
  margin-left: ${spacing.margin.xsmall};
  top: ${spacing.common.medium};
`;

const IconSortLayout = css`
  .Icon {
    width: ${iconSize.medium};
    height: ${iconSize.medium};
  }
`;

const IconSortColor = css`
  svg {
    color: ${color.icon_default};
  }
`;
