/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Typography, spacing, color, shadow } from '@uniquegood/realworld-studio-design';

const CardBorder = spacing.common.small;

const MissionCardStyle = css`
  cursor: pointer;

  max-width: 160px;
  height: 246px;
  width: 100%;
  margin: 8px 0;

  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${color.surface_default_default};
  ${shadow.card};
  border-radius: ${spacing.common.small};

  img {
    width: 100%;
    height: 160px;
    object-fit: cover;
    border-top-left-radius: ${CardBorder};
    border-top-right-radius: ${CardBorder};
  }
`;

const ThumbnailGradientStyle = css`
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 89px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
  border-top-left-radius: ${spacing.common.small};
  border-top-right-radius: ${spacing.common.small};

  & > span {
    margin: 0px;
  }
  & > span:last-of-type {
    margin-left: ${spacing.common.small};
  }
`;

const MissionCardBodyStyle = css`
  padding: 6px 8px;
  margin-top: 8px;

  & > h6 {
    margin: 0 0 6px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    text-align: left;
    max-height: 40px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  & > p {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    text-align: left;
    max-height: 32px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  & > p:last-of-type {
    margin-top: ${spacing.common.xsmall};
  }
`;

const ButtonResetStyle = css`
  transition: all 150ms ease-out;
  margin: 0px;
  padding: 0px;
  border: none;
`;

const KeyboardFocusStyle = css`
  :focus-visible {
    outline: 4px solid ${color.focused_default};
  }
`;

const missionSelectedStyle = KeyboardFocusStyle;

interface Props {
  name: string;
  description: string;
  imageUrl: string;
  onClick: any;
}

const WebviewTemplate: React.FC<Props> = ({ name, description, imageUrl, onClick }) => {
  return (
    <>
      <button
        type="button"
        css={[ButtonResetStyle, MissionCardStyle, missionSelectedStyle]}
        onClick={() => onClick()}
      >
        <img alt="미션 썸네일" src={imageUrl} />
        <div css={ThumbnailGradientStyle} />

        <div css={MissionCardBodyStyle}>
          <Typography as="h6" type="button">
            {name}
          </Typography>
          <Typography as="p" type="caption" textColor="subdued">
            {description}
          </Typography>
        </div>
      </button>
    </>
  );
};

export default WebviewTemplate;
