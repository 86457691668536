import { useCallback, useState } from 'react';

/**
 * stateful value를 반환하고 이를 toggle하기위한 메모화 된 함수 집합을 반환합니다.
 * true로 설정하고 false로 설정합니다.
 */
export default function useToggle(initialState: boolean): {
  value: boolean;
  toggle: () => void;
  setTrue: () => void;
  setFalse: () => void;
} {
  const [value, setState] = useState(initialState);

  return {
    value,
    toggle: useCallback(() => setState((state) => !state), []),
    setTrue: useCallback(() => setState(true), []),
    setFalse: useCallback(() => setState(false), [])
  };
}
