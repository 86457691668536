import { ReactionCommandTooltip, ReactionConditionTooltip } from '../components/reaction/Tooltips';
import {
  IReactionCommandDefinition,
  IReactionCommandDefinitionsGroup,
  IReactionConditionDefinition
} from '../types';

export const reactionConditionDefinitions: IReactionConditionDefinition[] = [
  {
    type: 'RealWorld.Conditions.None',
    label: '실행 조건 없음',
    availableActionAdapters: ['quest', 'item', 'chatbot'],
    parameterDefinitions: [],
    icon: 'times_regular',
    tooltip: <ReactionConditionTooltip.None />
  },
  {
    type: 'RealWorld.Conditions.InputData',
    label: '특정 텍스트를 입력함',
    availableActionAdapters: ['quest', 'item', 'chatbot'],
    parameterDefinitions: [
      {
        key: 'input',
        type: 'text',
        description:
          '콤마(,)를 이용해 여러 개의 값을 지정할 수 있으며, 영문 대소문자와 띄어쓰기는 무시되어요.',
        style: 'wrap'
      }
    ],
    icon: 'text_solid',
    tooltip: <ReactionConditionTooltip.InputData />
  },
  {
    type: 'RealWorld.Conditions.QuestAccomplished',
    label: '특정 퀘스트를 완료함',
    availableActionAdapters: ['quest'],
    parameterDefinitions: [
      {
        key: 'questId',
        type: 'list',
        source: 'quests',
        description: '퀘스트 선택',
        style: 'nowrap'
      }
    ],
    icon: 'check_solid',
    tooltip: <ReactionConditionTooltip.QuestAccomplished />
  },
  {
    type: 'RealWorld.Conditions.HasItem',
    label: '특정 아이템을 보유함',
    availableActionAdapters: ['quest', 'item', 'chatbot'],
    parameterDefinitions: [
      {
        key: 'itemId',
        type: 'stringList',
        source: 'items',
        description: '아이템 선택',
        style: 'nowrap'
      }
    ],
    icon: 'gift_solid',
    tooltip: <ReactionConditionTooltip.HasItem />
  },
  {
    type: 'RealWorld.Conditions.Webhook',
    label: '웹훅',
    availableActionAdapters: ['quest', 'item', 'chatbot'],
    parameterDefinitions: [
      {
        key: 'url',
        type: 'text',
        description: '호출할 주소',
        suffix: '를',
        style: 'wrap'
      },
      {
        key: 'method',
        type: 'stringList',
        source: [
          {
            label: 'GET',
            value: 'get'
          },
          {
            label: 'POST',
            value: 'post'
          }
        ],
        description: 'HTTP 메소드',
        suffix: '으로 호출함',
        style: 'nowrap'
      }
    ],
    icon: 'code_solid',
    tooltip: <ReactionConditionTooltip.ConditionWebhook />
  }
];

export const reactionCommandDefinitionGroup: IReactionCommandDefinitionsGroup = {
  Process: {
    key: 'Process',
    label: '게임 시작 / 완료 처리',
    icon: 'flag_solid',
    items: [
      {
        type: 'RealWorld.Commands.StartScenario',
        label: '게임 시작 처리',
        icon: 'flag_solid',
        tooltip: <ReactionCommandTooltip.StartScenario />,
        availableActionAdapters: ['quest', 'item', 'chatbot'],
        parameterDefinitions: [
          {
            key: 'scenarioId',
            type: 'list',
            source: 'scenarios',
            description: '시작할 게임',
            style: 'nowrap'
          }
        ]
      },
      {
        type: 'RealWorld.Commands.AccomplishScenario2',
        label: '게임 완료 처리',
        icon: 'flag_solid',
        availableActionAdapters: ['quest', 'item', 'chatbot'],
        parameterDefinitions: [
          {
            key: 'scenarioId',
            type: 'list',
            source: 'scenarios',
            description: '완료할 게임',
            style: 'nowrap'
          }
        ],
        tooltip: <ReactionCommandTooltip.AccomplishScenario2 />
      }
    ]
  },
  View: {
    key: 'View',
    label: '화면에서 보여주기',
    icon: 'mobile_alt_solid',
    items: [
      {
        type: 'RealWorld.Commands.DisplayHtml',
        label: '내용 추가',
        icon: 'mobile_alt_solid',
        availableActionAdapters: ['quest', 'item'],
        parameterDefinitions: [
          {
            key: 'content',
            type: 'editor',
            description: '보여줄 내용',
            style: 'wrap'
          }
        ],
        tooltip: <ReactionCommandTooltip.DisplayHtml />
      },
      {
        type: 'RealWorld.Commands.PlayAudio',
        label: '오디오 플레이어 추가',
        icon: 'mobile_alt_solid',
        availableActionAdapters: ['quest', 'item'],
        parameterDefinitions: [
          {
            key: 'url',
            type: 'music',
            isHideFadeButton: true,
            description: '재생할 음성 파일의 주소',
            style: 'wrap'
          }
        ],
        tooltip: <ReactionCommandTooltip.PlayAudio />
      },
      {
        type: 'RealWorld.Commands.ShareGame',
        label: '게임 공유하기 박스 추가',
        icon: 'mobile_alt_solid',
        availableActionAdapters: ['quest'],
        parameterDefinitions: [],
        tooltip: <ReactionCommandTooltip.ShareGame />
      },
      {
        type: 'RealWorld.Commands.PlayVideo',
        label: '유튜브 재생 화면 열기',
        icon: 'mobile_alt_solid',
        availableActionAdapters: ['quest', 'item'],
        parameterDefinitions: [
          {
            key: 'youtubeVideoId',
            type: 'text',
            description: '재생할 유튜브 영상의 ID',
            style: 'wrap'
          }
        ],
        tooltip: <ReactionCommandTooltip.PlayVideo />
      },
      {
        type: 'RealWorld.Commands.GotoReview',
        label: '후기 작성 화면 열기',
        icon: 'mobile_alt_solid',
        availableActionAdapters: ['quest'],
        parameterDefinitions: [
          {
            key: 'label',
            type: 'text',
            description: '리뷰 쓰기 버튼 레이블',
            style: 'wrap'
          }
        ],
        tooltip: <ReactionCommandTooltip.GotoReview />
      }
    ]
  },
  Button: {
    key: 'Button',
    label: '버튼 추가',
    icon: 'arrow_circle_right_solid',
    items: [
      {
        type: 'RealWorld.Commands.GoBack',
        label: '돌아가기 버튼 추가',
        icon: 'arrow_circle_right_solid',
        availableActionAdapters: ['quest', 'item'],
        parameterDefinitions: [],
        tooltip: <ReactionCommandTooltip.GoBack />
      },
      {
        type: 'RealWorld.Commands.GotoMission',
        label: '특정 미션으로 이동 버튼 추가',
        icon: 'arrow_circle_right_solid',
        tooltip: <ReactionCommandTooltip.GotoMission />,
        availableActionAdapters: ['quest'],
        parameterDefinitions: [
          {
            key: 'missionId',
            type: 'list',
            source: 'missions',
            description: '이동할 미션',
            style: 'nowrap'
          }
        ]
      },
      {
        type: 'RealWorld.Commands.OpenBrowser',
        label: '브라우저 열기 버튼 추가',
        icon: 'arrow_circle_right_solid',
        availableActionAdapters: ['quest', 'item'],
        parameterDefinitions: [
          {
            key: 'label',
            type: 'text',
            description: '열기 버튼 레이블',
            suffix: '버튼을 누르면 다음의',
            style: 'wrap'
          },
          {
            key: 'url',
            type: 'text',
            description: '열 URL',
            suffix: '를',
            style: 'wrap'
          },
          {
            key: 'browserType',
            type: 'stringList',
            source: [
              {
                label: '내장 브라우저',
                value: 'internal'
              },
              {
                label: '외장 브라우저',
                value: 'external'
              }
            ],
            description: '브라우저 종류',
            suffix: '로 열기',
            style: 'wrap'
          }
        ],
        tooltip: <ReactionCommandTooltip.OpenBrowser />
      },
      {
        type: 'RealWorld.Commands.AccomplishScenario',
        label: '게임 종료하기 버튼 추가',
        icon: 'arrow_circle_right_solid',
        availableActionAdapters: ['quest', 'item'],
        parameterDefinitions: [
          {
            key: 'scenarioId',
            type: 'list',
            source: 'scenarios',
            description: '완료할 게임'
          }
        ],
        tooltip: <ReactionCommandTooltip.AccomplishScenario />
      }
    ]
  },
  Mission: {
    key: 'Mission',
    label: '미션/퀘스트 상태 변경',
    icon: 'exchange_alt_solid',
    items: [
      {
        type: 'RealWorld.Commands.RevealMission',
        label: '미션 표시',
        icon: 'exchange_alt_solid',
        availableActionAdapters: ['quest'],
        parameterDefinitions: [
          {
            key: 'missionId',
            type: 'list',
            source: 'missions',
            description: '표시할 미션'
          }
        ],
        tooltip: <ReactionCommandTooltip.RevealMission />
      },
      {
        type: 'RealWorld.Commands.AccomplishMission',
        label: '미션 완료 처리',
        icon: 'exchange_alt_solid',
        availableActionAdapters: ['quest'],
        parameterDefinitions: [
          {
            key: 'missionId',
            type: 'list',
            source: 'missions',
            description: '완료 처리할 미션',
            style: 'nowrap'
          }
        ],
        tooltip: <ReactionCommandTooltip.AccomplishMission />
      },
      {
        type: 'RealWorld.Commands.LockMission',
        label: '미션 잠금 처리',
        icon: 'exchange_alt_solid',
        availableActionAdapters: ['quest'],
        parameterDefinitions: [
          {
            key: 'missionId',
            type: 'list',
            source: 'missions',
            description: '잠금 처리할 미션',
            style: 'nowrap'
          }
        ],
        tooltip: <ReactionCommandTooltip.LockMission />
      },
      {
        type: 'RealWorld.Commands.UnlockMission',
        label: '미션 잠금 해제',
        icon: 'exchange_alt_solid',
        availableActionAdapters: ['quest'],
        parameterDefinitions: [
          {
            key: 'missionId',
            type: 'list',
            source: 'missions',
            description: '잠금 해제할 미션',
            style: 'nowrap'
          }
        ],
        tooltip: <ReactionCommandTooltip.UnlockMission />
      },
      {
        type: 'RealWorld.Commands.RevealQuest',
        label: '퀘스트 버튼 표시',
        icon: 'exchange_alt_solid',
        availableActionAdapters: ['quest'],
        parameterDefinitions: [
          {
            key: 'questId',
            type: 'list',
            source: 'quests',
            description: '표시할 퀘스트',
            style: 'nowrap'
          }
        ],
        tooltip: <ReactionCommandTooltip.RevealQuest />
      },
      {
        type: 'RealWorld.Commands.AccomplishQuest',
        label: '퀘스트 완료 처리',
        icon: 'exchange_alt_solid',
        availableActionAdapters: ['quest'],
        parameterDefinitions: [
          {
            key: 'questId',
            type: 'list',
            source: 'quests',
            description: '완료 처리할 퀘스트',
            style: 'nowrap'
          }
        ],
        tooltip: <ReactionCommandTooltip.AccomplishQuest />
      },
      {
        type: 'RealWorld.Commands.LockQuest',
        label: '퀘스트 버튼 잠금 처리',
        icon: 'exchange_alt_solid',
        availableActionAdapters: ['quest'],
        parameterDefinitions: [
          {
            key: 'questId',
            type: 'list',
            source: 'quests',
            description: '잠금 처리할 퀘스트',
            style: 'nowrap'
          }
        ],
        tooltip: <ReactionCommandTooltip.LockQuest />
      },
      {
        type: 'RealWorld.Commands.UnlockQuest',
        label: '퀘스트 버튼 잠금 해제',
        icon: 'exchange_alt_solid',
        availableActionAdapters: ['quest'],
        parameterDefinitions: [
          {
            key: 'questId',
            type: 'list',
            source: 'quests',
            description: '잠금 해제할 퀘스트',
            style: 'nowrap'
          }
        ],
        tooltip: <ReactionCommandTooltip.UnlockQuest />
      },
      {
        type: 'RealWorld.Commands.QuestLabel',
        label: '퀘스트 버튼 문구 변경',
        icon: 'exchange_alt_solid',
        availableActionAdapters: ['quest'],
        parameterDefinitions: [
          {
            key: 'questId',
            type: 'list',
            source: 'quests',
            description: '변경할 퀘스트 선택',
            suffix: '의 버튼 문구를',
            style: 'wrap'
          },
          {
            key: 'label',
            type: 'text',
            description: '버튼 문구 입력',
            suffix: '로 변경하기',
            style: 'wrap'
          }
        ],
        tooltip: <ReactionCommandTooltip.QuestLabel />
      }
    ]
  },
  Item: {
    key: 'Item',
    label: '아이템',
    icon: 'gift_solid',
    items: [
      {
        type: 'RealWorld.Commands.AcquireItem',
        label: '아이템 획득',
        icon: 'gift_solid',
        availableActionAdapters: ['quest', 'item', 'chatbot'],
        parameterDefinitions: [
          {
            key: 'itemId',
            type: 'stringList',
            source: 'items',
            description: '획득할 아이템',
            style: 'nowrap'
          },
          {
            key: 'message',
            type: 'text',
            description: '아이템 획득 메시지',
            style: 'wrap'
          }
        ],
        tooltip: <ReactionCommandTooltip.AcquireItem />
      },
      {
        type: 'RealWorld.Commands.DecreaseItem',
        label: '아이템 소모',
        icon: 'gift_solid',
        availableActionAdapters: ['quest', 'item', 'chatbot'],
        parameterDefinitions: [
          {
            key: 'itemId',
            type: 'stringList',
            source: 'items',
            description: '소모할 아이템',
            style: 'nowrap'
          }
        ],
        tooltip: <ReactionCommandTooltip.DecreaseItem />
      }
    ]
  },
  Chatbot: {
    key: 'Chatbot',
    label: '챗봇',
    icon: 'comment_dots_solid',
    items: [
      {
        type: 'RealWorld.Commands.SendChatbotMessage',
        label: '챗봇 메시지 전송',
        icon: 'comment_dots_solid',
        availableActionAdapters: ['quest', 'item', 'chatbot'],
        parameterDefinitions: [
          {
            key: '',
            type: 'chatbot-message',
            style: 'wrap'
          }
        ],
        tooltip: <ReactionCommandTooltip.SendChatbotMessage />
      },

      {
        type: 'RealWorld.Commands.MoveScene',
        label: '챗봇 장면 이동',
        icon: 'comment_dots_solid',
        availableActionAdapters: ['quest', 'item', 'chatbot'],
        parameterDefinitions: [
          {
            key: '',
            type: 'move-scene',
            style: 'wrap'
          }
        ],
        tooltip: <ReactionCommandTooltip.MoveScene />
      }
    ]
  },
  BGM: {
    key: 'BGM',
    label: 'BGM',
    icon: 'music_solid',
    items: [
      {
        type: 'RealWorld.Commands.ChangeBgm',
        label: '게임 BGM 변경',
        icon: 'music_solid',
        availableActionAdapters: ['quest'],
        parameterDefinitions: [
          {
            key: 'scenarioId',
            type: 'list',
            source: 'scenarios',
            description: '변경할 게임 선택',
            style: 'nowrap'
          },
          {
            key: 'bgmUrl',
            type: 'music',
            style: 'wrap'
          },
          // {
          //   key: 'bgmUrl',
          //   type: 'music',
          //   isHideFadeButton: false,
          //   description: '',
          //   style: 'wrap'
          // },
          {
            key: 'isFadeIn',
            type: 'musicFadeIn',
            description: ''
          },
          {
            key: 'isFadeOut',
            type: 'musicFadeOut',
            description: ''
          }
        ],
        tooltip: <ReactionCommandTooltip.ChangeBgm />
      },
      {
        type: 'RealWorld.Commands.StopBgm',
        label: '게임 BGM 정지',
        icon: 'music_solid',
        availableActionAdapters: ['quest'],
        parameterDefinitions: [
          {
            key: 'scenarioId',
            type: 'list',
            source: 'scenarios',
            description: '정지할 게임 선택',
            style: 'nowrap'
          }
        ],
        tooltip: <ReactionCommandTooltip.StopBgm />
      }
    ]
  },
  Advanced: {
    key: 'Advanced',
    label: '고급 기능',
    icon: 'magic_solid',
    items: [
      {
        type: 'RealWorld.Commands.SetStringVariableInput',
        label: '입력한 텍스트를 변수에 저장',
        icon: 'magic_solid',
        availableActionAdapters: ['quest', 'item', 'chatbot'],
        parameterDefinitions: [
          {
            key: 'name',
            type: 'text',
            description: '변수 이름 입력',
            prefix: '입력한 텍스트를',
            suffix: '로 저장한 후',
            style: 'wrap'
          },
          {
            key: 'namespace',
            type: 'stringList',
            source: [
              {
                label: '게임 내부',
                value: '{project-id}'
              }
            ],
            description: '변수 사용 범위',
            suffix: '에서 사용',
            style: 'wrap'
          }
        ],
        tooltip: <ReactionCommandTooltip.SetStringVariableInput />,
        copyable: true,
        // eslint-disable-next-line no-template-curly-in-string
        copyContent: '{{namespace}.${name}}'
      },
      {
        type: 'RealWorld.Commands.SetStringVariableValue',
        label: '특정 텍스트를 변수에 저장',
        icon: 'magic_solid',
        availableActionAdapters: ['quest', 'item', 'chatbot'],
        parameterDefinitions: [
          {
            key: 'value',
            type: 'text',
            description: '특정 값',
            prefix: '텍스트 ',
            suffix: '을 ',
            style: 'wrap'
          },
          {
            key: 'name',
            type: 'text',
            description: '변수 이름 입력',
            prefix: '',
            suffix: '로 저장한 후',
            style: 'wrap'
          },
          {
            key: 'namespace',
            type: 'stringList',
            source: [
              {
                label: '게임 내부',
                value: '{project-id}'
              }
            ],
            description: '변수 사용 범위',
            suffix: '에서 사용',
            style: 'wrap'
          }
        ],
        tooltip: <ReactionCommandTooltip.SetStringVariableValue />,
        copyable: true,
        // eslint-disable-next-line no-template-curly-in-string
        copyContent: '{{namespace}.${name}}'
      },
      {
        type: 'RealWorld.Commands.NextMission',
        label: '다음 미션 이동 / 표시 일괄 설정',
        icon: 'magic_solid',
        availableActionAdapters: ['quest'],
        parameterDefinitions: [
          {
            key: 'missionId',
            type: 'list',
            source: 'missions',
            description: '현재 미션 선택',
            prefix: '현재 미션',
            suffix: '에서',
            style: 'nowrap'
          },
          {
            key: 'findOption',
            type: 'stringList',
            source: [
              {
                label: '표시된 바로 다음 미션',
                value: 'shown'
              },
              {
                label: '바로 다음 미션',
                value: 'next'
              }
            ],
            description: '이동 옵션',
            style: 'nowrap',
            suffix: '으로 이동 후, 다다음 미션을'
          },
          {
            key: 'showNextOption',
            type: 'stringList',
            source: [
              {
                label: '그 다음 미션 표시',
                value: 'show'
              },
              {
                label: '표시하지 않음',
                value: 'notShow'
              }
            ],
            description: '다음 미션 표시 여부',
            style: 'wrap'
          }
        ],
        tooltip: <ReactionCommandTooltip.NextMission />
      },
      {
        type: 'RealWorld.Commands.Webhook',
        label: '웹훅',
        icon: 'magic_solid',
        availableActionAdapters: ['quest', 'item', 'chatbot'],
        parameterDefinitions: [
          {
            key: 'url',
            type: 'text',
            description: '웹훅 URL',
            style: 'wrap'
          },
          {
            key: 'method',
            type: 'stringList',
            source: [
              {
                label: 'GET',
                value: 'get'
              },
              {
                label: 'POST',
                value: 'post'
              }
            ],
            description: 'HTTP 메소드',
            prefix: 'HTTP 메소드',
            style: 'wrap'
          }
        ],
        tooltip: <ReactionCommandTooltip.CommandWebhook />
      },
      {
        type: 'RealWorld.Commands.AdvancedAcquireItem',
        label: '고급 아이템 획득',
        icon: 'gift_solid',
        disabled: true,
        availableActionAdapters: ['quest', 'item', 'chatbot'],
        parameterDefinitions: [
          {
            key: 'itemId',
            type: 'stringList',
            source: 'items',
            description: '획득할 아이템',
            style: 'nowrap'
          },
          {
            key: 'message',
            type: 'text',
            description: '아이템 획득 메시지',
            style: 'wrap'
          },
          {
            key: 'buttonLabel',
            type: 'text',
            description: '버튼에 보여질 텍스트',
            style: 'wrap'
          }
        ],
        tooltip: <ReactionCommandTooltip.AdvancedAcquireItem />
      },
      {
        type: 'RealWorld.Commands.ShowWebView',
        label: '웹뷰 보여주기',
        icon: 'mobile_alt_solid',
        disabled: true,
        availableActionAdapters: ['quest', 'item', 'chatbot'],
        parameterDefinitions: [
          {
            key: 'url',
            type: 'text',
            description: '보여줄 URL',
            style: 'wrap'
          }
        ]
      }
    ]
  }
};

// 아래 정의들은 기존 스튜디오에는 있었지만 현재는 쓰이지 않는 리액션들을 모아둠
export const reactionCommandDefinitions: IReactionCommandDefinition[] = [
  {
    type: 'RealWorld.Commands.GotoScenario',
    label: '[미사용] 다른 게임으로 이동',
    disabled: true,
    availableActionAdapters: ['quest', 'item'],
    parameterDefinitions: [
      {
        key: 'scenarioId',
        type: 'list',
        source: 'scenarios',
        description: '이동할 게임'
      }
    ]
  },
  {
    type: 'RealWorld.Commands.DisplayText',
    label: '[미사용] 텍스트 표시',
    disabled: true,
    availableActionAdapters: ['quest', 'item'],
    parameterDefinitions: [
      {
        key: 'content',
        type: 'text',
        description: '보여줄 텍스트'
      }
    ]
  },
  {
    type: 'RealWorld.Commands.MakeCall',
    label: '전화 걸기 버튼 표시 (관리자 전용)',
    availableActionAdapters: ['quest', 'item'],
    parameterDefinitions: [
      {
        key: 'number',
        type: 'text',
        description: '전화를 걸 번호'
      },
      {
        key: 'label',
        type: 'text',
        description: '전화 걸기 버튼 레이블'
      }
    ]
  }
];
