import {
  IAvailabel,
  IReactionCommandDefinition,
  IReactionConditionDefinition
} from '@/components/ActionHandler/types';
import { combineCommandDefinitions } from '@/components/ActionHandler/utils/combineCommandDefinitions';
import { reactionConditionDefinitions } from '@/components/ActionHandler/utils/ReactionDefinitions';

interface Props extends IAvailabel {
  type: 'condition' | 'command';
}

export default function filterReactionDefs({ type, availabelType }: Props) {
  const filter = (definition: IReactionConditionDefinition) =>
    definition.availableActionAdapters.includes(availabelType);

  return type === 'command'
    ? combineCommandDefinitions.filter(filter)
    : reactionConditionDefinitions.filter(filter);
}
