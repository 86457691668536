const inlineScriptPattern = /<\s.script(.|[\t\n\r])*?>(.*?)<\/\s*?script[^>\w]*?>/gis;
const inlineStylePattern = /<\s.style(.|[\t\n\r])*?>(.*?)<\/\s*?style[^>\w]*?>/gis;

export function haveInlineScript(defaultValue?: string) {
  const hasInlineScript =
    !!defaultValue && (defaultValue.includes('<script') || inlineScriptPattern.test(defaultValue));
  const hasInlineStyle =
    !!defaultValue && (defaultValue.includes('<style') || inlineStylePattern.test(defaultValue));

  return hasInlineScript || hasInlineStyle;
}

export async function urlToFile(url: string, type: string) {
  const res = await fetch(url);
  const buf = await res.arrayBuffer();

  return new File([buf], generateRandomFileName(), { type });
}

const generateRandomFileName = () => Math.random().toString(36).substr(2, 11);
