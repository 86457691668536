/* eslint-disable no-param-reassign */
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { authApi } from './apis/auth';
import { getAccessToken, goToLoginPageWithCurrentUriRedirect } from './auth';
import { googleMapKey, isProduction } from './env';
import { emitServerErrorToast, getUnsplashAccessKey } from './utils';

export const CORE_API_BASE_URL = isProduction
  ? 'https://api.realworld.to'
  : 'https://api-test.realworld.to';

const STUDIO_API_BASE_URL = isProduction
  ? 'https://api.studio.realworld.to'
  : 'https://api-test.studio.realworld.to';

const isRefreshApi = (config: AxiosRequestConfig) =>
  config.baseURL === CORE_API_BASE_URL && config.url === '/api/auth/refresh';

function isNotRequireAccessToken(config: AxiosRequestConfig) {
  const isVerifyEmailApi =
    config.baseURL === STUDIO_API_BASE_URL && config.url === '/auth/verifyEmail';
  const isChangeEmailVerifyApi =
    config.baseURL === STUDIO_API_BASE_URL &&
    !!config.url &&
    /^\/api\/apps\/.+\/changeEmailVerify$/.test(config.url);

  return isRefreshApi(config) || isVerifyEmailApi || isChangeEmailVerifyApi;
}

const interceptorRequest = async (config: AxiosRequestConfig) => {
  if (config.baseURL === STUDIO_API_BASE_URL && config.url === '/auth/loginCallback') {
    return config;
  }

  if (isNotRequireAccessToken(config)) return config;

  const accessToken = await getAccessToken();

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  } else {
    await logout();
    goToLoginPageWithCurrentUriRedirect();
  }

  return config;
};

const interceptorResponse = async (error: AxiosError) => {
  if (error.response?.status === 401 && isRefreshApi(error.config)) {
    await logout();
    goToLoginPageWithCurrentUriRedirect();
  } else {
    emitServerErrorToast(error);
  }

  return Promise.reject(error);
};

export const coreApi = axios.create({
  baseURL: CORE_API_BASE_URL
});

export const studioApi = axios.create({
  baseURL: STUDIO_API_BASE_URL
});

coreApi.interceptors.request.use(interceptorRequest);
coreApi.interceptors.response.use((value) => value, interceptorResponse);

studioApi.interceptors.request.use(interceptorRequest);
studioApi.interceptors.response.use((value) => value, interceptorResponse);

export const unsplashApi = axios.create({
  baseURL: 'https://api.unsplash.com',
  headers: {
    Authorization: `Client-ID ${getUnsplashAccessKey()}`
  }
});

export const googleGeocodingApi = axios.create({
  baseURL: 'https://maps.googleapis.com/maps/api/geocode/json',
  params: { key: googleMapKey }
});

export async function logout() {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('defaultChannelID');
  localStorage.removeItem('expiresAt');
  try {
    await authApi.apiAuthLogoutPost();
  } catch (error) {
    console.error('Auth서버 로그아웃 실패', error);
  }
}
