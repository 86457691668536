import React, { useRef } from 'react';

import { Prompt, PromptProps, useHistory } from 'react-router-dom';

import { Modal } from '@uniquegood/realworld-studio-design';
import { useModalState } from '@/hooks';

interface RouteModalProps {
  when: boolean;
  isValid?: boolean;
  onSubmit(e?: unknown): void;
  loading?: boolean;
}

export default function RouteModal({ when, isValid, onSubmit, loading }: RouteModalProps) {
  const { modal, openModal, closeModal } = useModalState();
  const nextPathname = useRef<string>();
  const history = useHistory();

  const handleTransition: PromptProps['message'] = (location) => {
    if (!modal.open) {
      openRouteModal();
      nextPathname.current = location.pathname;
      return false;
    }

    return true;
  };

  function openRouteModal() {
    openModal({
      title: '저장하지 않은 내용이 있어요',
      children: '페이지를 벗어나면 변경사항이 사라져요. 변경한 사항을 저장하시겠어요?',
      primaryAction: {
        content: '저장',
        icon: 'save_solid',
        onAction: async () => {
          await onSubmit();

          if (isValid === false) {
            closeModal();
            return;
          }

          pushToNextLocation();
        }
      },
      secondaryAction: {
        content: '취소',
        onAction: closeModal
      },
      leftAction: {
        content: '저장 안함',
        onAction: pushToNextLocation
      }
    });
  }

  function pushToNextLocation() {
    closeModal();
    if (nextPathname.current) {
      history.push(nextPathname.current);
    }
  }

  return (
    <>
      <Prompt when={when} message={handleTransition} />
      <Modal {...modal} primaryAction={{ ...modal.primaryAction, loading }} />
    </>
  );
}
