import { ListItem } from '@uniquegood/realworld-studio-design';
import { DragDropContext, Draggable, Droppable, DragDropContextProps } from 'react-beautiful-dnd';

import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { coreApi } from '@/api';
import { toast, reorder } from '@/utils';
import { useDidUpdateEffect } from '@/hooks';
import { ActionListBorder } from '@/styles';
import useChatbot from '../hooks/useChatbot';

export default function ChangeSceneOrder() {
  const { item, mutate } = useChatbot();
  const { appId, projectId, chatbotId } = useParams<AppParam>();

  const [scenes, setScenes] = useState<ChatScene[] | undefined>(item?.scenes);

  useDidUpdateEffect(() => {
    if (item?.scenes) {
      setScenes(scenes);
    }
  }, [item]);

  const onDragEnd: DragDropContextProps['onDragEnd'] = (result) => {
    const from = result.source.index;
    const to = result.destination?.index;

    if (typeof to !== 'number' || !scenes) throw Error('기대하지 않은 경우입니다.');

    const reordered = reorder(scenes, from, to);
    setScenes(reordered);

    coreApi
      .put(`/apps/${appId}/projects/${projectId}/chatbots/${chatbotId}/scenes/order`, {
        from,
        to
      })
      .then(() => mutate())
      .then(() => {
        toast({ message: '장면 순서를 변경했어요' });
      });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="todos">
        {(provided) => (
          <ul {...provided.droppableProps} ref={provided.innerRef} css={ActionListBorder}>
            {scenes?.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.dragHandleProps}
                    {...provided.draggableProps}
                  >
                    <ListItem key={item.id} size="big" icon="bars_solid" content={item.name} />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
}
