import React, { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import Confetti from '@pages/home/sections/components/Confetti';
import { useRequest } from '@/hooks';
import { coreApi } from '@/api';
import { ClickEventName, InputEventName, PageViewEventName, track } from '@/track';
import HuggingFaceIcon from '../../../public/assets/icons/HuggingFaceIcon';
import RequiredMarkIcon from '../../../public/assets/icons/RequiredMarkIcon';
import PartyFaceIcon from '../../../public/assets/icons/PartyFaceIcon';

export default function CreateAppPage() {
  const [createdChannel, setCreatedChannel] = useState<RealWorldApp | null>(null);
  const history = useHistory();

  useEffect(() => {
    track.onPageView({ pageViewEventName: PageViewEventName.view_newchannelpage });
  }, []);

  const { mutate } = useRequest<RealWorldApp[]>('/apps');

  const { handleSubmit, watch, formState, control, setError, clearErrors } =
    useForm<Pick<RealWorldApp, 'name' | 'description'>>();

  const [watchName] = watch(['name']);

  const timeoutId = useRef<number | null>(null);

  useEffect(() => {
    clearErrors('name');

    if (!watchName?.trim().length) {
      setError('name', { type: 'value' });
      return;
    }

    if (watchName.includes('리얼월드')) {
      setError('name', { type: 'value', message: '리얼월드와 유사한 이름은 사용할 수 없습니다' });
      return;
    }

    if (timeoutId.current) {
      window.clearTimeout(timeoutId.current);
    }

    timeoutId.current = window.setTimeout(async () => {
      const { data: isAvailableName } = await coreApi.get<boolean | CoreAPIError>(
        `/api/apps/checkName`,
        {
          params: { name: watchName }
        }
      );

      if (isAvailableName === false) {
        setError('name', { type: 'value', message: '이미 사용 중인 이름입니다' });
      }
    }, 250);
  }, [watchName]);

  const onSubmit = handleSubmit(async (data) => {
    const createdChannelData = { ...data, channelType: 'Normal' };
    const { data: createdChannel } = await coreApi.post<RealWorldApp>('/apps', createdChannelData);

    await mutate();

    setCreatedChannel(createdChannel);
  });

  const disabled = formState.errors.name?.type === 'value';

  return (
    <>
      {formState.isSubmitted ? (
        <>
          <Confetti />
          {createdChannel && (
            <div css={containerForCreated}>
              <div css={headerContainer}>
                <PartyFaceIcon />
                <div css={headerStyle}>채널 생성 완료!</div>
              </div>
              <div css={contentStyle}>
                <span css={textBold}>{createdChannel.name}</span>
                에서 <br />
                크리에이터님만의 멋진 이야기를 실현해보세요.
              </div>
              <button
                css={buttonStyle}
                type="button"
                onClick={() => history.push(`/apps/${createdChannel.id}`)}
              >
                채널로 이동하기
              </button>
            </div>
          )}
        </>
      ) : (
        <div css={container}>
          <div css={headerContainer}>
            <HuggingFaceIcon />
            <div css={headerStyle}>
              만나서 반가워요! <br />
              이제 채널을 만들어 주세요.
            </div>
          </div>
          <form onSubmit={onSubmit} css={formStyle}>
            <Controller
              control={control}
              name="name"
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <div css={formGroupStyle}>
                  <label htmlFor="channelName" css={labelStyle}>
                    <div css={requiredWrapper}>
                      채널 이름 <RequiredMarkIcon />
                    </div>
                    <div css={inputWrapper}>
                      <input
                        {...field}
                        type="text"
                        id="channelName"
                        css={fieldState.error?.message ? inputErrorStyle : inputStyle}
                        onChange={(...args) => {
                          field.onChange(...args);
                          track.onInputThrottled({
                            inputEventName: InputEventName.input_newchannelpage_channelname
                          });
                        }}
                        placeholder="예) 윤익월드"
                        maxLength={30}
                      />
                      <div css={inputCounterStyle(field.value?.length > 0)}>
                        {field.value?.length ? field.value.length : 0}/30
                      </div>
                    </div>
                  </label>
                  {fieldState.error?.message ? (
                    <div css={errorTextStyle}>{fieldState.error?.message}</div>
                  ) : (
                    <div css={helperTextStyle}>게임을 공개할 채널의 이름을 설정해주세요</div>
                  )}
                </div>
              )}
            />

            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <div css={formGroupStyle}>
                  <label htmlFor="channelName" css={labelStyle}>
                    채널 한 줄 소개
                    <div css={inputWrapper}>
                      <input
                        {...field}
                        type="text"
                        id="channelName"
                        css={inputStyle}
                        onChange={(...args) => {
                          field.onChange(...args);
                          track.onInputThrottled({
                            inputEventName: InputEventName.input_newchannelpage_channelsummery
                          });
                        }}
                        placeholder="예) 내가 만든 게임으로 세상을 윤익하게!"
                        maxLength={80}
                      />
                      <div css={inputCounterStyle(field.value?.length > 0)}>
                        {field.value?.length ? field.value.length : 0}/80
                      </div>
                    </div>
                  </label>
                  <div css={helperTextStyle}>채널을 한 마디로 표현해주세요</div>
                </div>
              )}
            />

            <button
              css={disabled ? disabledButtonStyle : buttonStyle}
              type="submit"
              disabled={disabled}
              onClick={() => {
                track.onClick({
                  clickEventName: ClickEventName.click_newchannelpage_button_start
                });
              }}
            >
              만들기
            </button>
          </form>
        </div>
      )}
    </>
  );
}

const container = css`
  padding: 24px 24px 0;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  gap: 48px;
`;

const containerForCreated = css`
  padding: 24px 24px 0;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  gap: 12px;
`;

const headerContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  max-width: 320px;
`;

const headerStyle = css`
  color: #0e1017;
  text-align: center;

  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;

  display: flex;
`;

const contentStyle = css`
  text-align: center;

  color: #0e1017;

  font-family: Pretendard sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;

  margin-bottom: 28px;
`;

const textBold = css`
  color: #7800ff;
  font-weight: 700;
`;

const formStyle = css`
  display: flex;
  flex-direction: column;
  max-width: 320px;
  width: 100%;

  gap: 16px;
`;

const formGroupStyle = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const labelStyle = css`
  display: flex;
  flex-direction: column;
  gap: 8px;

  color: #0e1017;

  font-family: Pretendard sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  width: 100%;
`;

const requiredWrapper = css`
  display: flex;
  gap: 2px;
`;

const inputWrapper = css`
  position: relative;
`;

const inputStyle = css`
  width: 100%;

  color: #0e1017;
  font-family: Pretendard sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;

  display: flex;
  height: 40px;
  padding: 0 55px 0 12px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid #e9ebf1;

  outline: none;

  &:focus {
    outline: 1px solid #7800ff;
  }

  &::placeholder {
    color: #b2b6c2;
  }
`;

const inputErrorStyle = css`
  width: 100%;

  color: #0e1017;
  font-family: Pretendard sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;

  display: flex;
  height: 40px;
  padding: 0 55px 0 12px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid #e9ebf1;

  outline: 1px solid #ee416b;

  &::placeholder {
    color: #b2b6c2;
  }
`;

const inputCounterStyle = (isValue: boolean) => css`
  color: ${isValue ? '#0e1017' : ' #B2B6C2'};

  font-family: Pretendard sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;

  position: absolute;
  top: 0;
  right: 0;
  padding: 9px;
`;

const helperTextStyle = css`
  margin-left: 12px;
  color: #797d88;

  font-family: Pretendard sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
`;

const errorTextStyle = css`
  ${helperTextStyle};
  color: #ee416b;
`;

const disabledButtonStyle = css`
  max-width: 320px;
  width: 100%;

  border: none;

  display: flex;
  justify-content: center;
  padding: 12px;
  border-radius: 8px;

  font-family: Pretendard sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;

  background: #f2f4f8;
  color: #b2b6c2;
`;

const buttonStyle = css`
  max-width: 320px;
  width: 100%;

  border: none;

  display: flex;
  justify-content: center;
  padding: 12px;
  border-radius: 8px;

  font-family: Pretendard sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;

  background: #7800ff;
  color: #ffffff;
  cursor: pointer;
`;
