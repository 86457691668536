export default function HuggingFaceIcon() {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.5325 44.9967C38.5342 44.9967 45.5308 35.5954 45.5308 23.9983C45.5308 12.4013 38.5342 3 24.5325 3C10.5308 3 3.53418 12.4013 3.53418 23.9983C3.53418 35.5954 10.5308 44.9967 24.5325 44.9967Z"
        fill="url(#paint0_radial_1970_26905)"
      />
      <path
        d="M24.5325 44.9967C38.5342 44.9967 45.5308 35.5954 45.5308 23.9983C45.5308 12.4013 38.5342 3 24.5325 3C10.5308 3 3.53418 12.4013 3.53418 23.9983C3.53418 35.5954 10.5308 44.9967 24.5325 44.9967Z"
        fill="url(#paint1_radial_1970_26905)"
      />
      <path
        d="M24.5325 44.9967C38.5342 44.9967 45.5308 35.5954 45.5308 23.9983C45.5308 12.4013 38.5342 3 24.5325 3C10.5308 3 3.53418 12.4013 3.53418 23.9983C3.53418 35.5954 10.5308 44.9967 24.5325 44.9967Z"
        fill="url(#paint2_radial_1970_26905)"
        fillOpacity="0.6"
      />
      <path
        d="M24.5325 44.9967C38.5342 44.9967 45.5308 35.5954 45.5308 23.9983C45.5308 12.4013 38.5342 3 24.5325 3C10.5308 3 3.53418 12.4013 3.53418 23.9983C3.53418 35.5954 10.5308 44.9967 24.5325 44.9967Z"
        fill="url(#paint3_radial_1970_26905)"
      />
      <path
        d="M24.5325 44.9967C38.5342 44.9967 45.5308 35.5954 45.5308 23.9983C45.5308 12.4013 38.5342 3 24.5325 3C10.5308 3 3.53418 12.4013 3.53418 23.9983C3.53418 35.5954 10.5308 44.9967 24.5325 44.9967Z"
        fill="url(#paint4_radial_1970_26905)"
      />
      <path
        d="M24.5325 44.9967C38.5342 44.9967 45.5308 35.5954 45.5308 23.9983C45.5308 12.4013 38.5342 3 24.5325 3C10.5308 3 3.53418 12.4013 3.53418 23.9983C3.53418 35.5954 10.5308 44.9967 24.5325 44.9967Z"
        fill="url(#paint5_radial_1970_26905)"
      />
      <path
        d="M24.5325 44.9967C38.5342 44.9967 45.5308 35.5954 45.5308 23.9983C45.5308 12.4013 38.5342 3 24.5325 3C10.5308 3 3.53418 12.4013 3.53418 23.9983C3.53418 35.5954 10.5308 44.9967 24.5325 44.9967Z"
        fill="url(#paint6_radial_1970_26905)"
      />
      <path
        d="M24.5325 44.9967C38.5342 44.9967 45.5308 35.5954 45.5308 23.9983C45.5308 12.4013 38.5342 3 24.5325 3C10.5308 3 3.53418 12.4013 3.53418 23.9983C3.53418 35.5954 10.5308 44.9967 24.5325 44.9967Z"
        fill="url(#paint7_radial_1970_26905)"
      />
      <path
        d="M24.5325 44.9967C38.5342 44.9967 45.5308 35.5954 45.5308 23.9983C45.5308 12.4013 38.5342 3 24.5325 3C10.5308 3 3.53418 12.4013 3.53418 23.9983C3.53418 35.5954 10.5308 44.9967 24.5325 44.9967Z"
        fill="url(#paint8_radial_1970_26905)"
      />
      <path
        d="M24.5325 44.9967C38.5342 44.9967 45.5308 35.5954 45.5308 23.9983C45.5308 12.4013 38.5342 3 24.5325 3C10.5308 3 3.53418 12.4013 3.53418 23.9983C3.53418 35.5954 10.5308 44.9967 24.5325 44.9967Z"
        fill="url(#paint9_radial_1970_26905)"
      />
      <path
        d="M24.5325 44.9967C38.5342 44.9967 45.5308 35.5954 45.5308 23.9983C45.5308 12.4013 38.5342 3 24.5325 3C10.5308 3 3.53418 12.4013 3.53418 23.9983C3.53418 35.5954 10.5308 44.9967 24.5325 44.9967Z"
        fill="url(#paint10_radial_1970_26905)"
      />
      <path
        d="M24.5325 44.9967C38.5342 44.9967 45.5308 35.5954 45.5308 23.9983C45.5308 12.4013 38.5342 3 24.5325 3C10.5308 3 3.53418 12.4013 3.53418 23.9983C3.53418 35.5954 10.5308 44.9967 24.5325 44.9967Z"
        fill="url(#paint11_radial_1970_26905)"
      />
      <path
        d="M24.5325 44.9967C38.5342 44.9967 45.5308 35.5954 45.5308 23.9983C45.5308 12.4013 38.5342 3 24.5325 3C10.5308 3 3.53418 12.4013 3.53418 23.9983C3.53418 35.5954 10.5308 44.9967 24.5325 44.9967Z"
        fill="url(#paint12_radial_1970_26905)"
      />
      <path
        d="M24.5325 44.9967C38.5342 44.9967 45.5308 35.5954 45.5308 23.9983C45.5308 12.4013 38.5342 3 24.5325 3C10.5308 3 3.53418 12.4013 3.53418 23.9983C3.53418 35.5954 10.5308 44.9967 24.5325 44.9967Z"
        fill="url(#paint13_radial_1970_26905)"
      />
      <path
        d="M24.5325 44.9967C38.5342 44.9967 45.5308 35.5954 45.5308 23.9983C45.5308 12.4013 38.5342 3 24.5325 3C10.5308 3 3.53418 12.4013 3.53418 23.9983C3.53418 35.5954 10.5308 44.9967 24.5325 44.9967Z"
        fill="url(#paint14_radial_1970_26905)"
      />
      <path
        d="M24.5325 44.9967C38.5342 44.9967 45.5308 35.5954 45.5308 23.9983C45.5308 12.4013 38.5342 3 24.5325 3C10.5308 3 3.53418 12.4013 3.53418 23.9983C3.53418 35.5954 10.5308 44.9967 24.5325 44.9967Z"
        fill="url(#paint15_radial_1970_26905)"
      />
      <path
        d="M24.5325 44.9967C38.5342 44.9967 45.5308 35.5954 45.5308 23.9983C45.5308 12.4013 38.5342 3 24.5325 3C10.5308 3 3.53418 12.4013 3.53418 23.9983C3.53418 35.5954 10.5308 44.9967 24.5325 44.9967Z"
        fill="url(#paint16_radial_1970_26905)"
      />
      <path
        d="M24.5325 44.9967C38.5342 44.9967 45.5308 35.5954 45.5308 23.9983C45.5308 12.4013 38.5342 3 24.5325 3C10.5308 3 3.53418 12.4013 3.53418 23.9983C3.53418 35.5954 10.5308 44.9967 24.5325 44.9967Z"
        fill="url(#paint17_radial_1970_26905)"
      />
      <path
        d="M24.5325 44.9967C38.5342 44.9967 45.5308 35.5954 45.5308 23.9983C45.5308 12.4013 38.5342 3 24.5325 3C10.5308 3 3.53418 12.4013 3.53418 23.9983C3.53418 35.5954 10.5308 44.9967 24.5325 44.9967Z"
        fill="url(#paint18_radial_1970_26905)"
      />
      <path
        d="M24.5325 44.9967C38.5342 44.9967 45.5308 35.5954 45.5308 23.9983C45.5308 12.4013 38.5342 3 24.5325 3C10.5308 3 3.53418 12.4013 3.53418 23.9983C3.53418 35.5954 10.5308 44.9967 24.5325 44.9967Z"
        fill="url(#paint19_radial_1970_26905)"
      />
      <path
        d="M24.5325 44.9967C38.5342 44.9967 45.5308 35.5954 45.5308 23.9983C45.5308 12.4013 38.5342 3 24.5325 3C10.5308 3 3.53418 12.4013 3.53418 23.9983C3.53418 35.5954 10.5308 44.9967 24.5325 44.9967Z"
        fill="url(#paint20_radial_1970_26905)"
      />
      <path
        d="M24.5325 44.9967C38.5342 44.9967 45.5308 35.5954 45.5308 23.9983C45.5308 12.4013 38.5342 3 24.5325 3C10.5308 3 3.53418 12.4013 3.53418 23.9983C3.53418 35.5954 10.5308 44.9967 24.5325 44.9967Z"
        fill="url(#paint21_radial_1970_26905)"
      />
      <path
        d="M24.5325 44.9967C38.5342 44.9967 45.5308 35.5954 45.5308 23.9983C45.5308 12.4013 38.5342 3 24.5325 3C10.5308 3 3.53418 12.4013 3.53418 23.9983C3.53418 35.5954 10.5308 44.9967 24.5325 44.9967Z"
        fill="url(#paint22_radial_1970_26905)"
      />
      <path
        d="M24.5325 44.9967C38.5342 44.9967 45.5308 35.5954 45.5308 23.9983C45.5308 12.4013 38.5342 3 24.5325 3C10.5308 3 3.53418 12.4013 3.53418 23.9983C3.53418 35.5954 10.5308 44.9967 24.5325 44.9967Z"
        fill="url(#paint23_radial_1970_26905)"
      />
      <path
        d="M24.5325 44.9967C38.5342 44.9967 45.5308 35.5954 45.5308 23.9983C45.5308 12.4013 38.5342 3 24.5325 3C10.5308 3 3.53418 12.4013 3.53418 23.9983C3.53418 35.5954 10.5308 44.9967 24.5325 44.9967Z"
        fill="url(#paint24_radial_1970_26905)"
      />
      <path
        d="M24.5325 44.9967C38.5342 44.9967 45.5308 35.5954 45.5308 23.9983C45.5308 12.4013 38.5342 3 24.5325 3C10.5308 3 3.53418 12.4013 3.53418 23.9983C3.53418 35.5954 10.5308 44.9967 24.5325 44.9967Z"
        fill="url(#paint25_radial_1970_26905)"
      />
      <path
        d="M24.5325 44.9967C38.5342 44.9967 45.5308 35.5954 45.5308 23.9983C45.5308 12.4013 38.5342 3 24.5325 3C10.5308 3 3.53418 12.4013 3.53418 23.9983C3.53418 35.5954 10.5308 44.9967 24.5325 44.9967Z"
        fill="url(#paint26_radial_1970_26905)"
      />
      <path
        d="M24.5325 44.9967C38.5342 44.9967 45.5308 35.5954 45.5308 23.9983C45.5308 12.4013 38.5342 3 24.5325 3C10.5308 3 3.53418 12.4013 3.53418 23.9983C3.53418 35.5954 10.5308 44.9967 24.5325 44.9967Z"
        fill="url(#paint27_radial_1970_26905)"
      />
      <path
        d="M24.5325 44.9967C38.5342 44.9967 45.5308 35.5954 45.5308 23.9983C45.5308 12.4013 38.5342 3 24.5325 3C10.5308 3 3.53418 12.4013 3.53418 23.9983C3.53418 35.5954 10.5308 44.9967 24.5325 44.9967Z"
        fill="url(#paint28_radial_1970_26905)"
      />
      <path
        d="M24.5325 44.9967C38.5342 44.9967 45.5308 35.5954 45.5308 23.9983C45.5308 12.4013 38.5342 3 24.5325 3C10.5308 3 3.53418 12.4013 3.53418 23.9983C3.53418 35.5954 10.5308 44.9967 24.5325 44.9967Z"
        fill="url(#paint29_radial_1970_26905)"
      />
      <path
        d="M24.5325 44.9967C38.5342 44.9967 45.5308 35.5954 45.5308 23.9983C45.5308 12.4013 38.5342 3 24.5325 3C10.5308 3 3.53418 12.4013 3.53418 23.9983C3.53418 35.5954 10.5308 44.9967 24.5325 44.9967Z"
        fill="url(#paint30_radial_1970_26905)"
      />
      <path
        d="M24.5325 44.9967C38.5342 44.9967 45.5308 35.5954 45.5308 23.9983C45.5308 12.4013 38.5342 3 24.5325 3C10.5308 3 3.53418 12.4013 3.53418 23.9983C3.53418 35.5954 10.5308 44.9967 24.5325 44.9967Z"
        fill="url(#paint31_radial_1970_26905)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8261 21.1781C15.5564 20.7869 16.4655 21.0617 16.8567 21.792C17.3001 22.6196 19.4211 25.5003 24.5345 25.5003C29.6479 25.5003 31.7689 22.6196 32.2123 21.792C32.6035 21.0617 33.5126 20.7869 34.2428 21.1781C34.973 21.5693 35.2479 22.4784 34.8567 23.2086C34.0143 24.781 30.9925 28.5003 24.5345 28.5003C18.0765 28.5003 15.0546 24.781 14.2123 23.2086C13.8211 22.4784 14.0959 21.5693 14.8261 21.1781Z"
        fill="url(#paint32_radial_1970_26905)"
      />
      <path
        d="M14.0069 16.7844C14.079 16.4134 14.2441 15.9588 14.558 15.6168C14.827 15.3238 15.305 14.998 16.2845 14.998C17.247 14.998 17.7373 15.3305 18.0203 15.6412C18.3475 16.0004 18.5052 16.4586 18.5566 16.7547C18.6983 17.5709 19.4749 18.1177 20.2911 17.9759C21.1073 17.8342 21.6541 17.0576 21.5123 16.2414C21.3938 15.559 21.0544 14.5171 20.2381 13.621C19.3776 12.6763 18.0778 11.998 16.2845 11.998C14.508 11.998 13.2136 12.645 12.3478 13.5882C11.5269 14.4826 11.195 15.528 11.062 16.2117C10.9039 17.0249 11.435 17.8123 12.2482 17.9705C13.0614 18.1286 13.8488 17.5975 14.0069 16.7844Z"
        fill="#583F51"
      />
      <path
        d="M14.0069 16.7844C14.079 16.4134 14.2441 15.9588 14.558 15.6168C14.827 15.3238 15.305 14.998 16.2845 14.998C17.247 14.998 17.7373 15.3305 18.0203 15.6412C18.3475 16.0004 18.5052 16.4586 18.5566 16.7547C18.6983 17.5709 19.4749 18.1177 20.2911 17.9759C21.1073 17.8342 21.6541 17.0576 21.5123 16.2414C21.3938 15.559 21.0544 14.5171 20.2381 13.621C19.3776 12.6763 18.0778 11.998 16.2845 11.998C14.508 11.998 13.2136 12.645 12.3478 13.5882C11.5269 14.4826 11.195 15.528 11.062 16.2117C10.9039 17.0249 11.435 17.8123 12.2482 17.9705C13.0614 18.1286 13.8488 17.5975 14.0069 16.7844Z"
        fill="url(#paint33_radial_1970_26905)"
      />
      <path
        d="M14.0069 16.7844C14.079 16.4134 14.2441 15.9588 14.558 15.6168C14.827 15.3238 15.305 14.998 16.2845 14.998C17.247 14.998 17.7373 15.3305 18.0203 15.6412C18.3475 16.0004 18.5052 16.4586 18.5566 16.7547C18.6983 17.5709 19.4749 18.1177 20.2911 17.9759C21.1073 17.8342 21.6541 17.0576 21.5123 16.2414C21.3938 15.559 21.0544 14.5171 20.2381 13.621C19.3776 12.6763 18.0778 11.998 16.2845 11.998C14.508 11.998 13.2136 12.645 12.3478 13.5882C11.5269 14.4826 11.195 15.528 11.062 16.2117C10.9039 17.0249 11.435 17.8123 12.2482 17.9705C13.0614 18.1286 13.8488 17.5975 14.0069 16.7844Z"
        fill="url(#paint34_radial_1970_26905)"
      />
      <path
        d="M14.0069 16.7844C14.079 16.4134 14.2441 15.9588 14.558 15.6168C14.827 15.3238 15.305 14.998 16.2845 14.998C17.247 14.998 17.7373 15.3305 18.0203 15.6412C18.3475 16.0004 18.5052 16.4586 18.5566 16.7547C18.6983 17.5709 19.4749 18.1177 20.2911 17.9759C21.1073 17.8342 21.6541 17.0576 21.5123 16.2414C21.3938 15.559 21.0544 14.5171 20.2381 13.621C19.3776 12.6763 18.0778 11.998 16.2845 11.998C14.508 11.998 13.2136 12.645 12.3478 13.5882C11.5269 14.4826 11.195 15.528 11.062 16.2117C10.9039 17.0249 11.435 17.8123 12.2482 17.9705C13.0614 18.1286 13.8488 17.5975 14.0069 16.7844Z"
        fill="url(#paint35_radial_1970_26905)"
      />
      <path
        d="M30.5069 16.7844C30.579 16.4134 30.7441 15.9588 31.058 15.6168C31.327 15.3238 31.805 14.998 32.7845 14.998C33.747 14.998 34.2373 15.3305 34.5203 15.6412C34.8475 16.0004 35.0052 16.4586 35.0566 16.7547C35.1983 17.5709 35.9749 18.1177 36.7911 17.9759C37.6073 17.8342 38.1541 17.0576 38.0123 16.2414C37.8938 15.559 37.5544 14.5171 36.7381 13.621C35.8776 12.6763 34.5778 11.998 32.7845 11.998C31.008 11.998 29.7136 12.645 28.8478 13.5882C28.0269 14.4826 27.695 15.528 27.562 16.2117C27.4039 17.0249 27.935 17.8123 28.7482 17.9705C29.5614 18.1286 30.3488 17.5975 30.5069 16.7844Z"
        fill="#583F51"
      />
      <path
        d="M30.5069 16.7844C30.579 16.4134 30.7441 15.9588 31.058 15.6168C31.327 15.3238 31.805 14.998 32.7845 14.998C33.747 14.998 34.2373 15.3305 34.5203 15.6412C34.8475 16.0004 35.0052 16.4586 35.0566 16.7547C35.1983 17.5709 35.9749 18.1177 36.7911 17.9759C37.6073 17.8342 38.1541 17.0576 38.0123 16.2414C37.8938 15.559 37.5544 14.5171 36.7381 13.621C35.8776 12.6763 34.5778 11.998 32.7845 11.998C31.008 11.998 29.7136 12.645 28.8478 13.5882C28.0269 14.4826 27.695 15.528 27.562 16.2117C27.4039 17.0249 27.935 17.8123 28.7482 17.9705C29.5614 18.1286 30.3488 17.5975 30.5069 16.7844Z"
        fill="url(#paint36_radial_1970_26905)"
      />
      <path
        d="M30.5069 16.7844C30.579 16.4134 30.7441 15.9588 31.058 15.6168C31.327 15.3238 31.805 14.998 32.7845 14.998C33.747 14.998 34.2373 15.3305 34.5203 15.6412C34.8475 16.0004 35.0052 16.4586 35.0566 16.7547C35.1983 17.5709 35.9749 18.1177 36.7911 17.9759C37.6073 17.8342 38.1541 17.0576 38.0123 16.2414C37.8938 15.559 37.5544 14.5171 36.7381 13.621C35.8776 12.6763 34.5778 11.998 32.7845 11.998C31.008 11.998 29.7136 12.645 28.8478 13.5882C28.0269 14.4826 27.695 15.528 27.562 16.2117C27.4039 17.0249 27.935 17.8123 28.7482 17.9705C29.5614 18.1286 30.3488 17.5975 30.5069 16.7844Z"
        fill="url(#paint37_radial_1970_26905)"
      />
      <path
        d="M30.5069 16.7844C30.579 16.4134 30.7441 15.9588 31.058 15.6168C31.327 15.3238 31.805 14.998 32.7845 14.998C33.747 14.998 34.2373 15.3305 34.5203 15.6412C34.8475 16.0004 35.0052 16.4586 35.0566 16.7547C35.1983 17.5709 35.9749 18.1177 36.7911 17.9759C37.6073 17.8342 38.1541 17.0576 38.0123 16.2414C37.8938 15.559 37.5544 14.5171 36.7381 13.621C35.8776 12.6763 34.5778 11.998 32.7845 11.998C31.008 11.998 29.7136 12.645 28.8478 13.5882C28.0269 14.4826 27.695 15.528 27.562 16.2117C27.4039 17.0249 27.935 17.8123 28.7482 17.9705C29.5614 18.1286 30.3488 17.5975 30.5069 16.7844Z"
        fill="url(#paint38_radial_1970_26905)"
      />
      <path
        d="M6.67356 29.044C7.27241 28.3303 8.33643 28.2373 9.05011 28.8361L13.5081 32.5768L13.1323 31.2312C12.8715 30.2975 13.417 29.3292 14.3507 29.0684C15.2894 28.8062 16.262 29.359 16.517 30.2997L17.5498 34.1095L18.8043 37.1276C18.8274 37.1831 18.8478 37.2394 18.8655 37.2962C19.3123 38.1585 19.5654 39.1419 19.5654 40.1857C19.5654 43.5997 16.8578 46.3673 13.5177 46.3673C12.0483 46.3673 10.7014 45.8317 9.65348 44.9411L2.92957 39.9949C2.141 39.4148 1.98275 38.3002 2.57869 37.5235C2.83819 37.1853 3.19709 36.9683 3.58293 36.8821L2.94397 36.3459C2.19711 35.7193 2.09969 34.6058 2.72638 33.8589C2.99265 33.5416 3.3468 33.3415 3.72337 33.2644L3.52712 33.0997C2.78025 32.473 2.68283 31.3595 3.30953 30.6126C3.93622 29.8658 5.04972 29.7684 5.79658 30.3951L6.52463 31.006C6.15848 30.4048 6.19607 29.6131 6.67356 29.044Z"
        fill="url(#paint39_radial_1970_26905)"
      />
      <path
        d="M6.67356 29.044C7.27241 28.3303 8.33643 28.2373 9.05011 28.8361L13.5081 32.5768L13.1323 31.2312C12.8715 30.2975 13.417 29.3292 14.3507 29.0684C15.2894 28.8062 16.262 29.359 16.517 30.2997L17.5498 34.1095L18.8043 37.1276C18.8274 37.1831 18.8478 37.2394 18.8655 37.2962C19.3123 38.1585 19.5654 39.1419 19.5654 40.1857C19.5654 43.5997 16.8578 46.3673 13.5177 46.3673C12.0483 46.3673 10.7014 45.8317 9.65348 44.9411L2.92957 39.9949C2.141 39.4148 1.98275 38.3002 2.57869 37.5235C2.83819 37.1853 3.19709 36.9683 3.58293 36.8821L2.94397 36.3459C2.19711 35.7193 2.09969 34.6058 2.72638 33.8589C2.99265 33.5416 3.3468 33.3415 3.72337 33.2644L3.52712 33.0997C2.78025 32.473 2.68283 31.3595 3.30953 30.6126C3.93622 29.8658 5.04972 29.7684 5.79658 30.3951L6.52463 31.006C6.15848 30.4048 6.19607 29.6131 6.67356 29.044Z"
        fill="url(#paint40_radial_1970_26905)"
      />
      <path
        d="M6.67356 29.044C7.27241 28.3303 8.33643 28.2373 9.05011 28.8361L13.5081 32.5768L13.1323 31.2312C12.8715 30.2975 13.417 29.3292 14.3507 29.0684C15.2894 28.8062 16.262 29.359 16.517 30.2997L17.5498 34.1095L18.8043 37.1276C18.8274 37.1831 18.8478 37.2394 18.8655 37.2962C19.3123 38.1585 19.5654 39.1419 19.5654 40.1857C19.5654 43.5997 16.8578 46.3673 13.5177 46.3673C12.0483 46.3673 10.7014 45.8317 9.65348 44.9411L2.92957 39.9949C2.141 39.4148 1.98275 38.3002 2.57869 37.5235C2.83819 37.1853 3.19709 36.9683 3.58293 36.8821L2.94397 36.3459C2.19711 35.7193 2.09969 34.6058 2.72638 33.8589C2.99265 33.5416 3.3468 33.3415 3.72337 33.2644L3.52712 33.0997C2.78025 32.473 2.68283 31.3595 3.30953 30.6126C3.93622 29.8658 5.04972 29.7684 5.79658 30.3951L6.52463 31.006C6.15848 30.4048 6.19607 29.6131 6.67356 29.044Z"
        fill="url(#paint41_radial_1970_26905)"
      />
      <path
        d="M6.67356 29.044C7.27241 28.3303 8.33643 28.2373 9.05011 28.8361L13.5081 32.5768L13.1323 31.2312C12.8715 30.2975 13.417 29.3292 14.3507 29.0684C15.2894 28.8062 16.262 29.359 16.517 30.2997L17.5498 34.1095L18.8043 37.1276C18.8274 37.1831 18.8478 37.2394 18.8655 37.2962C19.3123 38.1585 19.5654 39.1419 19.5654 40.1857C19.5654 43.5997 16.8578 46.3673 13.5177 46.3673C12.0483 46.3673 10.7014 45.8317 9.65348 44.9411L2.92957 39.9949C2.141 39.4148 1.98275 38.3002 2.57869 37.5235C2.83819 37.1853 3.19709 36.9683 3.58293 36.8821L2.94397 36.3459C2.19711 35.7193 2.09969 34.6058 2.72638 33.8589C2.99265 33.5416 3.3468 33.3415 3.72337 33.2644L3.52712 33.0997C2.78025 32.473 2.68283 31.3595 3.30953 30.6126C3.93622 29.8658 5.04972 29.7684 5.79658 30.3951L6.52463 31.006C6.15848 30.4048 6.19607 29.6131 6.67356 29.044Z"
        fill="url(#paint42_radial_1970_26905)"
      />
      <path
        d="M6.67356 29.044C7.27241 28.3303 8.33643 28.2373 9.05011 28.8361L13.5081 32.5768L13.1323 31.2312C12.8715 30.2975 13.417 29.3292 14.3507 29.0684C15.2894 28.8062 16.262 29.359 16.517 30.2997L17.5498 34.1095L18.8043 37.1276C18.8274 37.1831 18.8478 37.2394 18.8655 37.2962C19.3123 38.1585 19.5654 39.1419 19.5654 40.1857C19.5654 43.5997 16.8578 46.3673 13.5177 46.3673C12.0483 46.3673 10.7014 45.8317 9.65348 44.9411L2.92957 39.9949C2.141 39.4148 1.98275 38.3002 2.57869 37.5235C2.83819 37.1853 3.19709 36.9683 3.58293 36.8821L2.94397 36.3459C2.19711 35.7193 2.09969 34.6058 2.72638 33.8589C2.99265 33.5416 3.3468 33.3415 3.72337 33.2644L3.52712 33.0997C2.78025 32.473 2.68283 31.3595 3.30953 30.6126C3.93622 29.8658 5.04972 29.7684 5.79658 30.3951L6.52463 31.006C6.15848 30.4048 6.19607 29.6131 6.67356 29.044Z"
        fill="url(#paint43_radial_1970_26905)"
      />
      <path
        d="M6.67356 29.044C7.27241 28.3303 8.33643 28.2373 9.05011 28.8361L13.5081 32.5768L13.1323 31.2312C12.8715 30.2975 13.417 29.3292 14.3507 29.0684C15.2894 28.8062 16.262 29.359 16.517 30.2997L17.5498 34.1095L18.8043 37.1276C18.8274 37.1831 18.8478 37.2394 18.8655 37.2962C19.3123 38.1585 19.5654 39.1419 19.5654 40.1857C19.5654 43.5997 16.8578 46.3673 13.5177 46.3673C12.0483 46.3673 10.7014 45.8317 9.65348 44.9411L2.92957 39.9949C2.141 39.4148 1.98275 38.3002 2.57869 37.5235C2.83819 37.1853 3.19709 36.9683 3.58293 36.8821L2.94397 36.3459C2.19711 35.7193 2.09969 34.6058 2.72638 33.8589C2.99265 33.5416 3.3468 33.3415 3.72337 33.2644L3.52712 33.0997C2.78025 32.473 2.68283 31.3595 3.30953 30.6126C3.93622 29.8658 5.04972 29.7684 5.79658 30.3951L6.52463 31.006C6.15848 30.4048 6.19607 29.6131 6.67356 29.044Z"
        fill="url(#paint44_radial_1970_26905)"
      />
      <path
        d="M3.55286 36.8708C3.55286 36.8708 2.76476 37.1273 2.44022 37.7399C1.85017 38.8538 2.75612 39.8652 2.75612 39.8652L4.74134 41.3203C6.31022 42.4703 8.5013 42.211 9.75848 40.7267L3.55286 36.8708Z"
        fill="url(#paint45_radial_1970_26905)"
      />
      <path
        d="M3.55286 36.8708C3.55286 36.8708 2.76476 37.1273 2.44022 37.7399C1.85017 38.8538 2.75612 39.8652 2.75612 39.8652L4.74134 41.3203C6.31022 42.4703 8.5013 42.211 9.75848 40.7267L3.55286 36.8708Z"
        fill="url(#paint46_radial_1970_26905)"
      />
      <path
        d="M9.02637 37.6216L8.16193 39.7322C8.16193 39.7322 4.56427 37.5115 3.5799 36.8845C3.31622 36.6619 3.3338 36.7029 2.83575 36.24C2.33771 35.7771 2.04246 34.8555 2.63834 33.9714C2.89435 33.5916 3.36353 33.3252 3.74009 33.248L9.02637 37.6216Z"
        fill="url(#paint47_radial_1970_26905)"
      />
      <path
        d="M9.02637 37.6216L8.16193 39.7322C8.16193 39.7322 4.56427 37.5115 3.5799 36.8845C3.31622 36.6619 3.3338 36.7029 2.83575 36.24C2.33771 35.7771 2.04246 34.8555 2.63834 33.9714C2.89435 33.5916 3.36353 33.3252 3.74009 33.248L9.02637 37.6216Z"
        fill="url(#paint48_radial_1970_26905)"
      />
      <path
        d="M11.5967 35.6713L9.00145 37.6055L3.55738 33.0982C2.81052 32.4715 2.71309 31.358 3.33979 30.6111C3.96648 29.8642 5.07997 29.7668 5.82684 30.3935L6.51362 30.9931C7.54934 32.0703 10.051 34.2684 11.5967 35.6713Z"
        fill="url(#paint49_radial_1970_26905)"
      />
      <path
        d="M11.5967 35.6713L9.00145 37.6055L3.55738 33.0982C2.81052 32.4715 2.71309 31.358 3.33979 30.6111C3.96648 29.8642 5.07997 29.7668 5.82684 30.3935L6.51362 30.9931C7.54934 32.0703 10.051 34.2684 11.5967 35.6713Z"
        fill="url(#paint50_radial_1970_26905)"
      />
      <path
        d="M11.5967 35.6713L9.00145 37.6055L3.55738 33.0982C2.81052 32.4715 2.71309 31.358 3.33979 30.6111C3.96648 29.8642 5.07997 29.7668 5.82684 30.3935L6.51362 30.9931C7.54934 32.0703 10.051 34.2684 11.5967 35.6713Z"
        fill="url(#paint51_linear_1970_26905)"
      />
      <path
        d="M9.04683 28.8343C8.26343 28.2045 7.2209 28.3376 6.65033 29.0687C6.08791 29.7894 6.25924 30.5206 6.52732 31.0258C7.66992 32.1684 11.4261 35.5109 11.4261 35.5109L14.3624 35.9301L13.4999 32.5726C13.4999 32.5726 10.8289 30.2671 9.04683 28.8343Z"
        fill="url(#paint52_radial_1970_26905)"
      />
      <path
        d="M9.04683 28.8343C8.26343 28.2045 7.2209 28.3376 6.65033 29.0687C6.08791 29.7894 6.25924 30.5206 6.52732 31.0258C7.66992 32.1684 11.4261 35.5109 11.4261 35.5109L14.3624 35.9301L13.4999 32.5726C13.4999 32.5726 10.8289 30.2671 9.04683 28.8343Z"
        fill="url(#paint53_radial_1970_26905)"
      />
      <path
        d="M9.04683 28.8343C8.26343 28.2045 7.2209 28.3376 6.65033 29.0687C6.08791 29.7894 6.25924 30.5206 6.52732 31.0258C7.66992 32.1684 11.4261 35.5109 11.4261 35.5109L14.3624 35.9301L13.4999 32.5726C13.4999 32.5726 10.8289 30.2671 9.04683 28.8343Z"
        fill="url(#paint54_linear_1970_26905)"
      />
      <path
        d="M42.4182 29.0596C41.8194 28.346 40.7554 28.2529 40.0417 28.8517L35.5837 32.5924L35.9595 31.2468C36.2203 30.3131 35.6748 29.3448 34.7411 29.084C33.8023 28.8218 32.8298 29.3747 32.5748 30.3154L31.542 34.1251L30.2875 37.1432C30.2644 37.1987 30.244 37.255 30.2263 37.3118C29.7795 38.1742 29.5264 39.1575 29.5264 40.2013C29.5264 43.6153 32.234 46.3829 35.5741 46.3829C37.0435 46.3829 38.3904 45.8473 39.4383 44.9567L46.1622 40.0105C46.9508 39.4304 47.109 38.3158 46.5131 37.5391C46.2536 37.2009 45.8947 36.984 45.5089 36.8977L46.1478 36.3616C46.8947 35.7349 46.9921 34.6214 46.3654 33.8745C46.0991 33.5572 45.745 33.3571 45.3684 33.28L45.5647 33.1153C46.3115 32.4886 46.409 31.3751 45.7823 30.6283C45.1556 29.8814 44.0421 29.784 43.2952 30.4107L42.5672 31.0216C42.9333 30.4205 42.8957 29.6287 42.4182 29.0596Z"
        fill="url(#paint55_radial_1970_26905)"
      />
      <path
        d="M42.4182 29.0596C41.8194 28.346 40.7554 28.2529 40.0417 28.8517L35.5837 32.5924L35.9595 31.2468C36.2203 30.3131 35.6748 29.3448 34.7411 29.084C33.8023 28.8218 32.8298 29.3747 32.5748 30.3154L31.542 34.1251L30.2875 37.1432C30.2644 37.1987 30.244 37.255 30.2263 37.3118C29.7795 38.1742 29.5264 39.1575 29.5264 40.2013C29.5264 43.6153 32.234 46.3829 35.5741 46.3829C37.0435 46.3829 38.3904 45.8473 39.4383 44.9567L46.1622 40.0105C46.9508 39.4304 47.109 38.3158 46.5131 37.5391C46.2536 37.2009 45.8947 36.984 45.5089 36.8977L46.1478 36.3616C46.8947 35.7349 46.9921 34.6214 46.3654 33.8745C46.0991 33.5572 45.745 33.3571 45.3684 33.28L45.5647 33.1153C46.3115 32.4886 46.409 31.3751 45.7823 30.6283C45.1556 29.8814 44.0421 29.784 43.2952 30.4107L42.5672 31.0216C42.9333 30.4205 42.8957 29.6287 42.4182 29.0596Z"
        fill="url(#paint56_radial_1970_26905)"
      />
      <path
        d="M42.4182 29.0596C41.8194 28.346 40.7554 28.2529 40.0417 28.8517L35.5837 32.5924L35.9595 31.2468C36.2203 30.3131 35.6748 29.3448 34.7411 29.084C33.8023 28.8218 32.8298 29.3747 32.5748 30.3154L31.542 34.1251L30.2875 37.1432C30.2644 37.1987 30.244 37.255 30.2263 37.3118C29.7795 38.1742 29.5264 39.1575 29.5264 40.2013C29.5264 43.6153 32.234 46.3829 35.5741 46.3829C37.0435 46.3829 38.3904 45.8473 39.4383 44.9567L46.1622 40.0105C46.9508 39.4304 47.109 38.3158 46.5131 37.5391C46.2536 37.2009 45.8947 36.984 45.5089 36.8977L46.1478 36.3616C46.8947 35.7349 46.9921 34.6214 46.3654 33.8745C46.0991 33.5572 45.745 33.3571 45.3684 33.28L45.5647 33.1153C46.3115 32.4886 46.409 31.3751 45.7823 30.6283C45.1556 29.8814 44.0421 29.784 43.2952 30.4107L42.5672 31.0216C42.9333 30.4205 42.8957 29.6287 42.4182 29.0596Z"
        fill="url(#paint57_radial_1970_26905)"
      />
      <path
        d="M42.4182 29.0596C41.8194 28.346 40.7554 28.2529 40.0417 28.8517L35.5837 32.5924L35.9595 31.2468C36.2203 30.3131 35.6748 29.3448 34.7411 29.084C33.8023 28.8218 32.8298 29.3747 32.5748 30.3154L31.542 34.1251L30.2875 37.1432C30.2644 37.1987 30.244 37.255 30.2263 37.3118C29.7795 38.1742 29.5264 39.1575 29.5264 40.2013C29.5264 43.6153 32.234 46.3829 35.5741 46.3829C37.0435 46.3829 38.3904 45.8473 39.4383 44.9567L46.1622 40.0105C46.9508 39.4304 47.109 38.3158 46.5131 37.5391C46.2536 37.2009 45.8947 36.984 45.5089 36.8977L46.1478 36.3616C46.8947 35.7349 46.9921 34.6214 46.3654 33.8745C46.0991 33.5572 45.745 33.3571 45.3684 33.28L45.5647 33.1153C46.3115 32.4886 46.409 31.3751 45.7823 30.6283C45.1556 29.8814 44.0421 29.784 43.2952 30.4107L42.5672 31.0216C42.9333 30.4205 42.8957 29.6287 42.4182 29.0596Z"
        fill="url(#paint58_radial_1970_26905)"
      />
      <path
        d="M42.4182 29.0596C41.8194 28.346 40.7554 28.2529 40.0417 28.8517L35.5837 32.5924L35.9595 31.2468C36.2203 30.3131 35.6748 29.3448 34.7411 29.084C33.8023 28.8218 32.8298 29.3747 32.5748 30.3154L31.542 34.1251L30.2875 37.1432C30.2644 37.1987 30.244 37.255 30.2263 37.3118C29.7795 38.1742 29.5264 39.1575 29.5264 40.2013C29.5264 43.6153 32.234 46.3829 35.5741 46.3829C37.0435 46.3829 38.3904 45.8473 39.4383 44.9567L46.1622 40.0105C46.9508 39.4304 47.109 38.3158 46.5131 37.5391C46.2536 37.2009 45.8947 36.984 45.5089 36.8977L46.1478 36.3616C46.8947 35.7349 46.9921 34.6214 46.3654 33.8745C46.0991 33.5572 45.745 33.3571 45.3684 33.28L45.5647 33.1153C46.3115 32.4886 46.409 31.3751 45.7823 30.6283C45.1556 29.8814 44.0421 29.784 43.2952 30.4107L42.5672 31.0216C42.9333 30.4205 42.8957 29.6287 42.4182 29.0596Z"
        fill="url(#paint59_radial_1970_26905)"
      />
      <path
        d="M42.4182 29.0596C41.8194 28.346 40.7554 28.2529 40.0417 28.8517L35.5837 32.5924L35.9595 31.2468C36.2203 30.3131 35.6748 29.3448 34.7411 29.084C33.8023 28.8218 32.8298 29.3747 32.5748 30.3154L31.542 34.1251L30.2875 37.1432C30.2644 37.1987 30.244 37.255 30.2263 37.3118C29.7795 38.1742 29.5264 39.1575 29.5264 40.2013C29.5264 43.6153 32.234 46.3829 35.5741 46.3829C37.0435 46.3829 38.3904 45.8473 39.4383 44.9567L46.1622 40.0105C46.9508 39.4304 47.109 38.3158 46.5131 37.5391C46.2536 37.2009 45.8947 36.984 45.5089 36.8977L46.1478 36.3616C46.8947 35.7349 46.9921 34.6214 46.3654 33.8745C46.0991 33.5572 45.745 33.3571 45.3684 33.28L45.5647 33.1153C46.3115 32.4886 46.409 31.3751 45.7823 30.6283C45.1556 29.8814 44.0421 29.784 43.2952 30.4107L42.5672 31.0216C42.9333 30.4205 42.8957 29.6287 42.4182 29.0596Z"
        fill="url(#paint60_linear_1970_26905)"
      />
      <path
        d="M42.4182 29.0596C41.8194 28.346 40.7554 28.2529 40.0417 28.8517L35.5837 32.5924L35.9595 31.2468C36.2203 30.3131 35.6748 29.3448 34.7411 29.084C33.8023 28.8218 32.8298 29.3747 32.5748 30.3154L31.542 34.1251L30.2875 37.1432C30.2644 37.1987 30.244 37.255 30.2263 37.3118C29.7795 38.1742 29.5264 39.1575 29.5264 40.2013C29.5264 43.6153 32.234 46.3829 35.5741 46.3829C37.0435 46.3829 38.3904 45.8473 39.4383 44.9567L46.1622 40.0105C46.9508 39.4304 47.109 38.3158 46.5131 37.5391C46.2536 37.2009 45.8947 36.984 45.5089 36.8977L46.1478 36.3616C46.8947 35.7349 46.9921 34.6214 46.3654 33.8745C46.0991 33.5572 45.745 33.3571 45.3684 33.28L45.5647 33.1153C46.3115 32.4886 46.409 31.3751 45.7823 30.6283C45.1556 29.8814 44.0421 29.784 43.2952 30.4107L42.5672 31.0216C42.9333 30.4205 42.8957 29.6287 42.4182 29.0596Z"
        fill="url(#paint61_linear_1970_26905)"
      />
      <path
        d="M42.4182 29.0596C41.8194 28.346 40.7554 28.2529 40.0417 28.8517L35.5837 32.5924L35.9595 31.2468C36.2203 30.3131 35.6748 29.3448 34.7411 29.084C33.8023 28.8218 32.8298 29.3747 32.5748 30.3154L31.542 34.1251L30.2875 37.1432C30.2644 37.1987 30.244 37.255 30.2263 37.3118C29.7795 38.1742 29.5264 39.1575 29.5264 40.2013C29.5264 43.6153 32.234 46.3829 35.5741 46.3829C37.0435 46.3829 38.3904 45.8473 39.4383 44.9567L46.1622 40.0105C46.9508 39.4304 47.109 38.3158 46.5131 37.5391C46.2536 37.2009 45.8947 36.984 45.5089 36.8977L46.1478 36.3616C46.8947 35.7349 46.9921 34.6214 46.3654 33.8745C46.0991 33.5572 45.745 33.3571 45.3684 33.28L45.5647 33.1153C46.3115 32.4886 46.409 31.3751 45.7823 30.6283C45.1556 29.8814 44.0421 29.784 43.2952 30.4107L42.5672 31.0216C42.9333 30.4205 42.8957 29.6287 42.4182 29.0596Z"
        fill="url(#paint62_linear_1970_26905)"
      />
      <path
        d="M42.4182 29.0596C41.8194 28.346 40.7554 28.2529 40.0417 28.8517L35.5837 32.5924L35.9595 31.2468C36.2203 30.3131 35.6748 29.3448 34.7411 29.084C33.8023 28.8218 32.8298 29.3747 32.5748 30.3154L31.542 34.1251L30.2875 37.1432C30.2644 37.1987 30.244 37.255 30.2263 37.3118C29.7795 38.1742 29.5264 39.1575 29.5264 40.2013C29.5264 43.6153 32.234 46.3829 35.5741 46.3829C37.0435 46.3829 38.3904 45.8473 39.4383 44.9567L46.1622 40.0105C46.9508 39.4304 47.109 38.3158 46.5131 37.5391C46.2536 37.2009 45.8947 36.984 45.5089 36.8977L46.1478 36.3616C46.8947 35.7349 46.9921 34.6214 46.3654 33.8745C46.0991 33.5572 45.745 33.3571 45.3684 33.28L45.5647 33.1153C46.3115 32.4886 46.409 31.3751 45.7823 30.6283C45.1556 29.8814 44.0421 29.784 43.2952 30.4107L42.5672 31.0216C42.9333 30.4205 42.8957 29.6287 42.4182 29.0596Z"
        fill="url(#paint63_radial_1970_26905)"
      />
      <path
        d="M42.4182 29.0596C41.8194 28.346 40.7554 28.2529 40.0417 28.8517L35.5837 32.5924L35.9595 31.2468C36.2203 30.3131 35.6748 29.3448 34.7411 29.084C33.8023 28.8218 32.8298 29.3747 32.5748 30.3154L31.542 34.1251L30.2875 37.1432C30.2644 37.1987 30.244 37.255 30.2263 37.3118C29.7795 38.1742 29.5264 39.1575 29.5264 40.2013C29.5264 43.6153 32.234 46.3829 35.5741 46.3829C37.0435 46.3829 38.3904 45.8473 39.4383 44.9567L46.1622 40.0105C46.9508 39.4304 47.109 38.3158 46.5131 37.5391C46.2536 37.2009 45.8947 36.984 45.5089 36.8977L46.1478 36.3616C46.8947 35.7349 46.9921 34.6214 46.3654 33.8745C46.0991 33.5572 45.745 33.3571 45.3684 33.28L45.5647 33.1153C46.3115 32.4886 46.409 31.3751 45.7823 30.6283C45.1556 29.8814 44.0421 29.784 43.2952 30.4107L42.5672 31.0216C42.9333 30.4205 42.8957 29.6287 42.4182 29.0596Z"
        fill="url(#paint64_radial_1970_26905)"
      />
      <path
        d="M42.4182 29.0596C41.8194 28.346 40.7554 28.2529 40.0417 28.8517L35.5837 32.5924L35.9595 31.2468C36.2203 30.3131 35.6748 29.3448 34.7411 29.084C33.8023 28.8218 32.8298 29.3747 32.5748 30.3154L31.542 34.1251L30.2875 37.1432C30.2644 37.1987 30.244 37.255 30.2263 37.3118C29.7795 38.1742 29.5264 39.1575 29.5264 40.2013C29.5264 43.6153 32.234 46.3829 35.5741 46.3829C37.0435 46.3829 38.3904 45.8473 39.4383 44.9567L46.1622 40.0105C46.9508 39.4304 47.109 38.3158 46.5131 37.5391C46.2536 37.2009 45.8947 36.984 45.5089 36.8977L46.1478 36.3616C46.8947 35.7349 46.9921 34.6214 46.3654 33.8745C46.0991 33.5572 45.745 33.3571 45.3684 33.28L45.5647 33.1153C46.3115 32.4886 46.409 31.3751 45.7823 30.6283C45.1556 29.8814 44.0421 29.784 43.2952 30.4107L42.5672 31.0216C42.9333 30.4205 42.8957 29.6287 42.4182 29.0596Z"
        fill="url(#paint65_radial_1970_26905)"
      />
      <path
        d="M42.4182 29.0596C41.8194 28.346 40.7554 28.2529 40.0417 28.8517L35.5837 32.5924L35.9595 31.2468C36.2203 30.3131 35.6748 29.3448 34.7411 29.084C33.8023 28.8218 32.8298 29.3747 32.5748 30.3154L31.542 34.1251L30.2875 37.1432C30.2644 37.1987 30.244 37.255 30.2263 37.3118C29.7795 38.1742 29.5264 39.1575 29.5264 40.2013C29.5264 43.6153 32.234 46.3829 35.5741 46.3829C37.0435 46.3829 38.3904 45.8473 39.4383 44.9567L46.1622 40.0105C46.9508 39.4304 47.109 38.3158 46.5131 37.5391C46.2536 37.2009 45.8947 36.984 45.5089 36.8977L46.1478 36.3616C46.8947 35.7349 46.9921 34.6214 46.3654 33.8745C46.0991 33.5572 45.745 33.3571 45.3684 33.28L45.5647 33.1153C46.3115 32.4886 46.409 31.3751 45.7823 30.6283C45.1556 29.8814 44.0421 29.784 43.2952 30.4107L42.5672 31.0216C42.9333 30.4205 42.8957 29.6287 42.4182 29.0596Z"
        fill="url(#paint66_radial_1970_26905)"
      />
      <path
        d="M42.4182 29.0596C41.8194 28.346 40.7554 28.2529 40.0417 28.8517L35.5837 32.5924L35.9595 31.2468C36.2203 30.3131 35.6748 29.3448 34.7411 29.084C33.8023 28.8218 32.8298 29.3747 32.5748 30.3154L31.542 34.1251L30.2875 37.1432C30.2644 37.1987 30.244 37.255 30.2263 37.3118C29.7795 38.1742 29.5264 39.1575 29.5264 40.2013C29.5264 43.6153 32.234 46.3829 35.5741 46.3829C37.0435 46.3829 38.3904 45.8473 39.4383 44.9567L46.1622 40.0105C46.9508 39.4304 47.109 38.3158 46.5131 37.5391C46.2536 37.2009 45.8947 36.984 45.5089 36.8977L46.1478 36.3616C46.8947 35.7349 46.9921 34.6214 46.3654 33.8745C46.0991 33.5572 45.745 33.3571 45.3684 33.28L45.5647 33.1153C46.3115 32.4886 46.409 31.3751 45.7823 30.6283C45.1556 29.8814 44.0421 29.784 43.2952 30.4107L42.5672 31.0216C42.9333 30.4205 42.8957 29.6287 42.4182 29.0596Z"
        fill="url(#paint67_radial_1970_26905)"
      />
      <path
        d="M42.4182 29.0596C41.8194 28.346 40.7554 28.2529 40.0417 28.8517L35.5837 32.5924L35.9595 31.2468C36.2203 30.3131 35.6748 29.3448 34.7411 29.084C33.8023 28.8218 32.8298 29.3747 32.5748 30.3154L31.542 34.1251L30.2875 37.1432C30.2644 37.1987 30.244 37.255 30.2263 37.3118C29.7795 38.1742 29.5264 39.1575 29.5264 40.2013C29.5264 43.6153 32.234 46.3829 35.5741 46.3829C37.0435 46.3829 38.3904 45.8473 39.4383 44.9567L46.1622 40.0105C46.9508 39.4304 47.109 38.3158 46.5131 37.5391C46.2536 37.2009 45.8947 36.984 45.5089 36.8977L46.1478 36.3616C46.8947 35.7349 46.9921 34.6214 46.3654 33.8745C46.0991 33.5572 45.745 33.3571 45.3684 33.28L45.5647 33.1153C46.3115 32.4886 46.409 31.3751 45.7823 30.6283C45.1556 29.8814 44.0421 29.784 43.2952 30.4107L42.5672 31.0216C42.9333 30.4205 42.8957 29.6287 42.4182 29.0596Z"
        fill="url(#paint68_radial_1970_26905)"
      />
      <path
        d="M45.5217 36.8906C45.5217 36.8906 46.3911 37.1053 46.716 37.8607C47.2439 39.0879 46.274 39.9193 46.274 39.9193L42.4931 42.6907L41.2475 40.177L45.5217 36.8906Z"
        fill="url(#paint69_radial_1970_26905)"
      />
      <path
        d="M45.5217 36.8906C45.5217 36.8906 46.3911 37.1053 46.716 37.8607C47.2439 39.0879 46.274 39.9193 46.274 39.9193L42.4931 42.6907L41.2475 40.177L45.5217 36.8906Z"
        fill="url(#paint70_radial_1970_26905)"
      />
      <path
        d="M40.6334 37.3784C40.1488 37.7962 39.5875 38.7089 39.8538 39.0262C40.4805 39.7731 42.0334 39.6529 42.7803 39.0262L46.0518 36.4451C46.9691 35.6404 46.9492 34.5955 46.3225 33.8487C46.0563 33.5314 45.7379 33.3584 45.3613 33.2812L40.6334 37.3784Z"
        fill="url(#paint71_radial_1970_26905)"
      />
      <path
        d="M40.6334 37.3784C40.1488 37.7962 39.5875 38.7089 39.8538 39.0262C40.4805 39.7731 42.0334 39.6529 42.7803 39.0262L46.0518 36.4451C46.9691 35.6404 46.9492 34.5955 46.3225 33.8487C46.0563 33.5314 45.7379 33.3584 45.3613 33.2812L40.6334 37.3784Z"
        fill="url(#paint72_linear_1970_26905)"
      />
      <path
        d="M40.6334 37.3784C40.1488 37.7962 39.5875 38.7089 39.8538 39.0262C40.4805 39.7731 42.0334 39.6529 42.7803 39.0262L46.0518 36.4451C46.9691 35.6404 46.9492 34.5955 46.3225 33.8487C46.0563 33.5314 45.7379 33.3584 45.3613 33.2812L40.6334 37.3784Z"
        fill="url(#paint73_radial_1970_26905)"
      />
      <path
        d="M38.2897 34.8166C37.5372 35.4606 37.7871 36.4533 38.2897 37.0523C38.9164 37.7992 40.0299 37.8966 40.7768 37.2699L45.5534 33.1223C46.3003 32.4956 46.379 31.358 45.7523 30.6111C45.1256 29.8642 44.0122 29.7668 43.2653 30.3935L42.5372 31.0044C42.4442 31.1572 42.325 31.2977 42.1804 31.419L38.2897 34.8166Z"
        fill="url(#paint74_radial_1970_26905)"
      />
      <path
        d="M38.2897 34.8166C37.5372 35.4606 37.7871 36.4533 38.2897 37.0523C38.9164 37.7992 40.0299 37.8966 40.7768 37.2699L45.5534 33.1223C46.3003 32.4956 46.379 31.358 45.7523 30.6111C45.1256 29.8642 44.0122 29.7668 43.2653 30.3935L42.5372 31.0044C42.4442 31.1572 42.325 31.2977 42.1804 31.419L38.2897 34.8166Z"
        fill="url(#paint75_radial_1970_26905)"
      />
      <path
        d="M38.2897 34.8166C37.5372 35.4606 37.7871 36.4533 38.2897 37.0523C38.9164 37.7992 40.0299 37.8966 40.7768 37.2699L45.5534 33.1223C46.3003 32.4956 46.379 31.358 45.7523 30.6111C45.1256 29.8642 44.0122 29.7668 43.2653 30.3935L42.5372 31.0044C42.4442 31.1572 42.325 31.2977 42.1804 31.419L38.2897 34.8166Z"
        fill="url(#paint76_linear_1970_26905)"
      />
      <path
        d="M39.9984 28.8455C40.7192 28.2409 41.7936 28.335 42.3983 29.0557C42.9947 29.7664 42.9127 30.8237 42.2138 31.434L38.9352 34.2967C37.5467 35.509 35.3891 34.4092 35.5545 32.5734L39.9984 28.8455Z"
        fill="url(#paint77_radial_1970_26905)"
      />
      <path
        d="M39.9984 28.8455C40.7192 28.2409 41.7936 28.335 42.3983 29.0557C42.9947 29.7664 42.9127 30.8237 42.2138 31.434L38.9352 34.2967C37.5467 35.509 35.3891 34.4092 35.5545 32.5734L39.9984 28.8455Z"
        fill="url(#paint78_linear_1970_26905)"
      />
      <path
        d="M39.9984 28.8455C40.7192 28.2409 41.7936 28.335 42.3983 29.0557C42.9947 29.7664 42.9127 30.8237 42.2138 31.434L38.9352 34.2967C37.5467 35.509 35.3891 34.4092 35.5545 32.5734L39.9984 28.8455Z"
        fill="url(#paint79_radial_1970_26905)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(38.7842 13.5) rotate(131.878) scale(58.423)"
        >
          <stop stopColor="#FFF478" />
          <stop offset="0.474827" stopColor="#FFB02E" />
          <stop offset="1" stopColor="#F70A8D" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(24.5342 18.75) rotate(101.31) scale(26.7699 34.2871)"
        >
          <stop offset="0.787821" stopColor="#F59639" stopOpacity="0" />
          <stop offset="0.972509" stopColor="#FF7DCE" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(27.5342 21) rotate(135) scale(61.5183)"
        >
          <stop offset="0.314853" stopOpacity="0" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(24.5342 25.5) rotate(77.692) scale(42.2204)"
        >
          <stop offset="0.507903" stopColor="#7D6133" stopOpacity="0" />
          <stop offset="1" stopColor="#715B32" />
        </radialGradient>
        <radialGradient
          id="paint4_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25.2842 24.75) rotate(55.7131) scale(19.9703 14.4755)"
        >
          <stop stopColor="#FFB849" />
          <stop offset="1" stopColor="#FFB847" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint5_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(31.2842 27) rotate(9.86581) scale(17.5089)"
        >
          <stop stopColor="#FFA64B" />
          <stop offset="0.900412" stopColor="#FFAE46" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint6_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(26.7842 22.5) rotate(43.9708) scale(88.5794)"
        >
          <stop offset="0.185425" stopOpacity="0" />
          <stop offset="1" stopOpacity="0.4" />
        </radialGradient>
        <radialGradient
          id="paint7_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(35.6097 17.1697) rotate(39.8978) scale(2.46376 1.50803)"
        >
          <stop stopColor="#D27C1D" />
          <stop offset="1" stopColor="#CB7620" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint8_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(33.4542 14.5226) rotate(33.6901) scale(3.81764 1.72436)"
        >
          <stop stopColor="#D28118" />
          <stop offset="1" stopColor="#D68623" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint9_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(18.6366 16.2057) rotate(29.6831) scale(2.99918 2.43421)"
        >
          <stop stopColor="#BE7227" />
          <stop offset="1" stopColor="#C67A2A" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint10_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.2915 16.5966) rotate(4.8884) scale(3.97499 2.93675)"
        >
          <stop stopColor="#D07E2D" />
          <stop offset="1" stopColor="#D98833" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint11_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(11.4451 18.4465) rotate(-30.75) scale(2.39518 1.93856)"
        >
          <stop stopColor="#DA8924" />
          <stop offset="1" stopColor="#DC8924" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint12_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(18.7499 17.25) rotate(23.8682) scale(2.75037 1.73494)"
        >
          <stop stopColor="#D59A13" />
          <stop offset="1" stopColor="#D59A13" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint13_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(14.4877 19.6128) rotate(-10.3912) scale(10.6064 7.82798)"
        >
          <stop stopColor="#F1A227" />
          <stop offset="1" stopColor="#F5A72C" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint14_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(28.6146 16.1773) rotate(132.51) scale(2.66389)"
        >
          <stop stopColor="#E8942F" />
          <stop offset="1" stopColor="#FAAB4B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint15_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(29.2368 41.7412) rotate(39.9448) scale(9.75435)"
        >
          <stop stopColor="#F47360" />
          <stop offset="1" stopColor="#F47360" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint16_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(18.6806 40.3791) rotate(119.445) scale(8.08136)"
        >
          <stop stopColor="#F47360" />
          <stop offset="1" stopColor="#F47360" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint17_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.0418 32.0242) rotate(128.804) scale(10.3543)"
        >
          <stop stopColor="#FF6501" />
          <stop offset="1" stopColor="#F9883A" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint18_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(6.19484 32.6607) rotate(48.0848) scale(13.4232)"
        >
          <stop stopColor="#BC6526" />
          <stop offset="1" stopColor="#CF7432" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint19_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.4751 38.7406) rotate(-19.4401) scale(4.109 6.31601)"
        >
          <stop offset="0.31649" stopColor="#F16E5C" />
          <stop offset="1" stopColor="#BC642E" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint20_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(15.5174 32.5865) rotate(69.7435) scale(4.84 2.70164)"
        >
          <stop offset="0.297036" stopColor="#BC642E" />
          <stop offset="1" stopColor="#BC642E" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint21_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.4684 34.4455) rotate(66.8014) scale(5.65292 1.95963)"
        >
          <stop offset="0.297036" stopColor="#8C4A23" />
          <stop offset="1" stopColor="#BC642E" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint22_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(11.7423 31.448) rotate(41.9059) scale(5.41238 1.16239)"
        >
          <stop offset="0.297036" stopColor="#8C4A23" />
          <stop offset="1" stopColor="#BC642E" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint23_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(9.61775 32.9576) rotate(30.5925) scale(9.86775 5.3162)"
        >
          <stop offset="0.297036" stopColor="#BC5614" />
          <stop offset="1" stopColor="#BC642E" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint24_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(32.1863 38.5125) rotate(102.721) scale(6.64733 5.52479)"
        >
          <stop offset="0.0677083" stopColor="#7E281F" />
          <stop offset="1" stopColor="#E96C5E" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint25_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(31.9099 37.3266) rotate(108.159) scale(5.59044 2.88954)"
        >
          <stop offset="0.0677083" stopColor="#B84D2D" />
          <stop offset="0.598958" stopColor="#D5613F" />
          <stop offset="1" stopColor="#D4613F" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint26_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(32.7394 34.4998) rotate(92.4003) scale(6.6147 3.81675)"
        >
          <stop offset="0.0677083" stopColor="#B85B2C" />
          <stop offset="1" stopColor="#B85B2C" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint27_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(33.0708 34.9256) rotate(95.6196) scale(6.21294 2.37041)"
        >
          <stop offset="0.666667" stopColor="#BB5029" />
          <stop offset="1" stopColor="#D4613F" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint28_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(37.1838 33.9967) rotate(113.318) scale(6.04562 3.48839)"
        >
          <stop offset="0.0677083" stopColor="#B85B2C" />
          <stop offset="1" stopColor="#B85B2C" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint29_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(36.5138 34.6667) rotate(122.242) scale(7.35623 3.19432)"
        >
          <stop offset="0.666667" stopColor="#BB5029" />
          <stop offset="1" stopColor="#D4613F" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint30_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(10.9302 21.6609) rotate(22.1688) scale(6.19449 6.43567)"
        >
          <stop stopColor="#F97660" />
          <stop offset="1" stopColor="#F97660" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint31_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(39.5124 22.6674) rotate(173.658) scale(12.0501)"
        >
          <stop stopColor="#FE9671" />
          <stop offset="1" stopColor="#FFD15A" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint32_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(24.5345 18.9003) rotate(90) scale(10.8 11.8078)"
        >
          <stop offset="0.517632" stopColor="#4E2553" />
          <stop offset="0.702876" stopColor="#5B4852" />
          <stop offset="1" stopColor="#4E2553" />
          <stop offset="1" stopColor="#4E2553" />
        </radialGradient>
        <radialGradient
          id="paint33_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.2844 16.8403) rotate(90) scale(4.18226)"
        >
          <stop offset="0.188596" stopColor="#1D1019" />
          <stop offset="1" stopColor="#462B3F" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint34_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.2844 16.8403) rotate(90) scale(5.41126 6.14159)"
        >
          <stop offset="0.718644" stopColor="#462B3F" stopOpacity="0" />
          <stop offset="1" stopColor="#1D1019" />
        </radialGradient>
        <radialGradient
          id="paint35_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.2844 14.0605) rotate(90) scale(4.96875 10.4375)"
        >
          <stop offset="0.429103" stopColor="#462B3F" stopOpacity="0" />
          <stop offset="1" stopColor="#1D1019" />
        </radialGradient>
        <radialGradient
          id="paint36_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(32.7844 16.8403) rotate(90) scale(4.18226)"
        >
          <stop offset="0.188596" stopColor="#1D1019" />
          <stop offset="1" stopColor="#462B3F" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint37_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(32.7844 16.8403) rotate(90) scale(5.41126 6.14159)"
        >
          <stop offset="0.718644" stopColor="#462B3F" stopOpacity="0" />
          <stop offset="1" stopColor="#1D1019" />
        </radialGradient>
        <radialGradient
          id="paint38_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(32.7844 14.0605) rotate(90) scale(4.96875 10.4375)"
        >
          <stop offset="0.429103" stopColor="#462B3F" stopOpacity="0" />
          <stop offset="1" stopColor="#1D1019" />
        </radialGradient>
        <radialGradient
          id="paint39_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.7948 29.1949) rotate(117.586) scale(28.2358 28.1512)"
        >
          <stop offset="0.239583" stopColor="#FFCC42" />
          <stop offset="0.562377" stopColor="#FFB02E" />
          <stop offset="1" stopColor="#F70A8D" />
        </radialGradient>
        <radialGradient
          id="paint40_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.0894 30.1198) rotate(124.346) scale(17.8378 24.1053)"
        >
          <stop offset="0.65203" stopColor="#FFB745" stopOpacity="0" />
          <stop offset="0.855204" stopColor="#F69044" />
          <stop offset="0.930316" stopColor="#DE6A63" />
        </radialGradient>
        <radialGradient
          id="paint41_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.4091 31.3367) rotate(70.4012) scale(4.08281 2.15658)"
        >
          <stop stopColor="#EE982C" />
          <stop offset="1" stopColor="#EEA02C" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint42_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.0002 29.4149) rotate(47.2906) scale(2.13052)"
        >
          <stop stopColor="#DA8C2B" />
          <stop offset="1" stopColor="#EEA02C" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint43_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(19.5654 37.6949) rotate(90) scale(6.23023 2.81472)"
        >
          <stop stopColor="#FFDA3C" />
          <stop offset="1" stopColor="#FFDA3C" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint44_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.2873 34.0151) rotate(75.3188) scale(5.49856 2.2089)"
        >
          <stop stopColor="#FFD743" />
          <stop offset="1" stopColor="#FFD743" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint45_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(5.63742 39.3258) rotate(34.5027) scale(3.56496 1.01876)"
        >
          <stop stopColor="#FFCE43" />
          <stop offset="1" stopColor="#FFCE43" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint46_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(4.42253 36.5187) rotate(38.4319) scale(5.36433 1.32856)"
        >
          <stop offset="0.385833" stopColor="#83480A" />
          <stop offset="0.609375" stopColor="#CD7313" />
          <stop offset="1" stopColor="#FA9428" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint47_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(5.97772 35.1262) rotate(-143.922) scale(4.95431 1.00712)"
        >
          <stop stopColor="#A03B04" />
          <stop offset="1" stopColor="#FA9428" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint48_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(5.07815 36.0856) rotate(37.6781) scale(3.39762 0.891489)"
        >
          <stop stopColor="#FFCE43" />
          <stop offset="1" stopColor="#FFCE43" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint49_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(8.67065 32.7268) rotate(-140.646) scale(5.67439 1.83149)"
        >
          <stop stopColor="#A03B04" />
          <stop offset="1" stopColor="#FA9428" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint50_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(7.2617 33.5207) rotate(40.8979) scale(4.43399 1.0993)"
        >
          <stop stopColor="#FFDB3F" />
          <stop offset="1" stopColor="#FFDB3F" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint51_linear_1970_26905"
          x1="4.20714"
          y1="35.6098"
          x2="5.8605"
          y2="33.7877"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CD7313" />
          <stop offset="1" stopColor="#FA9428" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint52_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(10.3594 31.3069) rotate(39.4968) scale(5.01152 0.748398)"
        >
          <stop stopColor="#FFF478" stopOpacity="0.3" />
          <stop offset="1" stopColor="#FFF478" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint53_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.9961 30.1767) rotate(146.31) scale(3.10672 6.06208)"
        >
          <stop offset="0.532661" stopColor="#83480A" />
          <stop offset="1" stopColor="#FA9428" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint54_linear_1970_26905"
          x1="6.72402"
          y1="32.7152"
          x2="8.85616"
          y2="31.2357"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.173754" stopColor="#CD7313" />
          <stop offset="1" stopColor="#FA9428" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint55_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(49.6434 27.5457) rotate(137.605) scale(41.7451 41.6199)"
        >
          <stop offset="0.239583" stopColor="#FEB63A" />
          <stop offset="0.537866" stopColor="#FFB02E" />
          <stop offset="1" stopColor="#F70A8D" />
        </radialGradient>
        <radialGradient
          id="paint56_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(31.3006 44.4934) rotate(141.815) scale(19.201 19.3523)"
        >
          <stop offset="0.239583" stopColor="#FFB52F" stopOpacity="0" />
          <stop offset="0.526687" stopColor="#FFD142" />
          <stop offset="0.584428" stopColor="#FFD142" />
          <stop offset="1" stopColor="#FFBB30" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint57_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(33.2691 34.1693) rotate(68.724) scale(4.01072 3.91446)"
        >
          <stop stopColor="#FEB32F" />
          <stop offset="1" stopColor="#FEB32F" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint58_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(38.4674 35.3498) rotate(130.097) scale(3.65036 1.45518)"
        >
          <stop stopColor="#FFBB33" />
          <stop offset="1" stopColor="#FFBB33" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint59_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.6213 37.901) rotate(136.035) scale(3.4746 1.24778)"
        >
          <stop stopColor="#FFBB33" />
          <stop offset="1" stopColor="#FFBB33" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint60_linear_1970_26905"
          x1="29.9569"
          y1="33.3957"
          x2="33.6889"
          y2="34.3654"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D28B23" />
          <stop offset="1" stopColor="#D28B23" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint61_linear_1970_26905"
          x1="24.7032"
          y1="31.2978"
          x2="30.532"
          y2="30.1212"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.328125" stopColor="#EE891E" />
          <stop offset="0.864583" stopColor="#FA9428" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint62_linear_1970_26905"
          x1="40.8377"
          y1="40.7793"
          x2="42.7381"
          y2="43.2109"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBF45" stopOpacity="0" />
          <stop offset="1" stopColor="#FFAF4A" />
        </linearGradient>
        <radialGradient
          id="paint63_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(38.9246 35.6904) rotate(96.2892) scale(11.9311 12.0572)"
        >
          <stop offset="0.65203" stopColor="#FFB745" stopOpacity="0" />
          <stop offset="0.892907" stopColor="#F69044" />
          <stop offset="1" stopColor="#DE6A63" />
        </radialGradient>
        <radialGradient
          id="paint64_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(34.9226 31.6905) rotate(104.916) scale(2.9004 1.19092)"
        >
          <stop stopColor="#FFD445" />
          <stop offset="1" stopColor="#FFD345" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint65_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(41.6525 29.8736) rotate(70.7505) scale(1.75079 1.25379)"
        >
          <stop stopColor="#FFDA43" />
          <stop offset="1" stopColor="#FFDA43" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint66_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(45.0352 31.4203) rotate(71.6912) scale(2.19395 1.57014)"
        >
          <stop stopColor="#FFDA43" />
          <stop offset="1" stopColor="#FFDA43" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint67_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(35.2128 30.1998) rotate(78.4422) scale(2.41107 1.74397)"
        >
          <stop offset="0.328125" stopColor="#FFDA43" />
          <stop offset="0.723958" stopColor="#FFDA43" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint68_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(35.6098 32.5225) rotate(135.736) scale(1.97066 0.310597)"
        >
          <stop stopColor="#B9671B" />
          <stop offset="0.849337" stopColor="#B9671B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint69_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(43.5704 38.0041) rotate(-32.8914) scale(4.30165 1.68766)"
        >
          <stop stopColor="#A03B04" />
          <stop offset="1" stopColor="#FA9428" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint70_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(46.1366 38.5202) rotate(81.28) scale(1.20308 0.717032)"
        >
          <stop stopColor="#FFD943" />
          <stop offset="1" stopColor="#FFD943" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint71_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(43.2776 35.1227) rotate(-41.1733) scale(5.18892 1.24425)"
        >
          <stop stopColor="#A03B04" />
          <stop offset="1" stopColor="#FA9428" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint72_linear_1970_26905"
          x1="45.8031"
          y1="37.7707"
          x2="44.5123"
          y2="36.4617"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CD7313" />
          <stop offset="1" stopColor="#FA9428" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint73_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(46.1473 34.767) rotate(70.7423) scale(1.33617 1.04466)"
        >
          <stop stopColor="#FFDB3F" />
          <stop offset="1" stopColor="#FFDB3F" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint74_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.2137 33.034) rotate(-39.7323) scale(6.24959 1.33794)"
        >
          <stop stopColor="#A03B04" />
          <stop offset="1" stopColor="#FA9428" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint75_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(45.392 31.3587) rotate(65.9778) scale(1.25674 0.69891)"
        >
          <stop stopColor="#FFDB3F" />
          <stop offset="1" stopColor="#FFDB3F" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint76_linear_1970_26905"
          x1="44.9388"
          y1="35.6711"
          x2="43.2616"
          y2="33.9082"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CD7313" />
          <stop offset="1" stopColor="#FA9428" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint77_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(36.9217 29.5764) rotate(51.5132) scale(2.59387 5.49144)"
        >
          <stop offset="0.545035" stopColor="#E1A21C" />
          <stop offset="1" stopColor="#FA9428" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint78_linear_1970_26905"
          x1="41.0637"
          y1="33.9886"
          x2="39.5701"
          y2="32.7866"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CD7313" />
          <stop offset="1" stopColor="#FA9428" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint79_radial_1970_26905"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(41.8186 29.7425) rotate(72.8947) scale(1.37847 0.713228)"
        >
          <stop stopColor="#FFDB3F" />
          <stop offset="1" stopColor="#FFDB3F" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
}
