export function toggler<T extends string>(array: T[], item: T, maximum?: number) {
  const index = array.findIndex((_item) => _item === item);

  if (index === -1) return array.length === maximum ? array : [...array, item];

  return [...array.slice(0, index), ...array.slice(index + 1)];
}

export function changeSigunguCodeToBefore(code: string) {
  const doCode = code.slice(0, 2);
  const restCode = code.slice(2);

  // 강원도 => 강원특별자치도 변경에 따른 임시 수정
  if (doCode === '51') return `42${restCode}`;
  // 전라북도 => 전북특별자치도 변경에 따른 임시 수정
  if (doCode === '52') return `45${restCode}`;

  return code;
}
