import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { css } from '@emotion/react';
import { color, Icon, shadow, spacing, Typography } from '@uniquegood/realworld-studio-design';

import { ButtonResetStyle, KeyboardFocusStyle } from '@/styles/index';
import { onMobile } from '@/styles/responsive';
import {
  decodeHtml,
  MISSION_CARD_MAX_HEIGHT,
  MISSION_NAV_CARD_WIDTH
} from '@/pages/missions/utils';
import { keyPressHandler } from '@/utils';

import { defaultCoverImage } from '../../const';

interface MissionCardProps {
  hasImage: boolean;
  mission: Mission;
  isSelected: boolean;
  onClick(): void;
}

function MissionCard({ hasImage, mission, isSelected, onClick }: MissionCardProps) {
  const { appId, projectId, scenarioId } = useParams<AppParam>();
  const history = useHistory();

  const missionSelectedStyle = isSelected ? BorderStyle : KeyboardFocusStyle;

  const iconMarkup = (
    <>
      {!mission.isVisibleDefault && (
        <Icon
          icon="eye_slash_solid"
          size="20px"
          color={hasImage ? color.icon_white : color.icon_default}
        />
      )}
      {mission.isLockedDefault && (
        <Icon
          icon="lock_solid"
          size="20px"
          color={hasImage ? color.icon_white : color.icon_default}
        />
      )}
    </>
  );

  function moveToSelectMissionEdit() {
    history.push(
      `/apps/${appId}/projects/${projectId}/scenarios/${scenarioId}/missions/${mission.id}`
    );
    onClick();
  }

  return (
    <button
      type="button"
      tabIndex={isSelected ? -1 : undefined}
      aria-pressed={isSelected}
      css={[ButtonResetStyle, MissionCardStyle, missionSelectedStyle]}
      data-mission-id={mission.id}
      onClick={moveToSelectMissionEdit}
      onKeyDown={keyPressHandler(moveToSelectMissionEdit)}
    >
      {hasImage && (
        <>
          <img alt="미션 썸네일" src={mission.coverImageUrl ?? defaultCoverImage.card} />
          <div css={ThumbnailGradientStyle}>{iconMarkup}</div>
        </>
      )}
      <div css={MissionCardBodyStyle}>
        <Typography as="h6" type="button">
          {mission.name}
        </Typography>
        <Typography as="p" type="caption" textColor="subdued">
          {decodeHtml(mission.shortDescription)}
        </Typography>
        {!hasImage && <div className="list-view-icon-wrap">{iconMarkup}</div>}
      </div>
    </button>
  );
}

export default MissionCard;

const CardBorder = spacing.common.small;

const MissionCardStyle = css`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${color.surface_default_default};
  ${shadow.card};
  border-radius: ${spacing.common.small};
  width: ${MISSION_NAV_CARD_WIDTH};
  max-height: ${MISSION_CARD_MAX_HEIGHT};
  margin-bottom: ${spacing.common.xsmall};

  img {
    width: 100%;
    height: 182px;
    object-fit: cover;
    border-top-left-radius: ${CardBorder};
    border-top-right-radius: ${CardBorder};
  }

  ${onMobile} {
    margin-bottom: 0px;
  }
`;

const ThumbnailGradientStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 89px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
  border-top-left-radius: ${spacing.common.small};
  border-top-right-radius: ${spacing.common.small};
  display: flex;
  justify-content: flex-end;
  padding: ${spacing.common.small};

  & > span {
    margin: 0px;
  }
  & > span:last-of-type {
    margin-left: ${spacing.common.small};
  }
`;

const MissionCardBodyStyle = css`
  padding: 12px;

  & > h6 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    text-align: left;
    max-height: 40px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  & > p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    text-align: left;
    max-height: 32px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  & > p:last-of-type {
    margin-top: ${spacing.common.xsmall};
  }

  & > .list-view-icon-wrap {
    margin-top: ${spacing.common.small};
    display: flex;
    justify-content: flex-end;
    & > span {
      margin: 0px;
    }
    & > span:last-of-type {
      margin-left: ${spacing.common.small};
    }
  }
`;

const BorderStyle = css`
  box-shadow: 0 0 0 6px ${color.border_primary_default};
`;
