import { SelectOption } from '@uniquegood/realworld-studio-design/dist/types/atoms/Select/Select';
import { useParams } from 'react-router-dom';

import { IReactionParameter, ReactionProps } from '@/components/ActionHandler/types';

interface Props extends ReactionProps {
  source: IReactionParameter['source'];
}

type IStrictOption = Exclude<SelectOption, string>;

export const getOptionsByType = ({
  source,
  scenarios,
  missions,
  quests,
  items,
  chatbots
}: Props) => {
  const { missionId, projectId, scenarioId, questId } = useParams<AppParam>();

  if (typeof source === 'string') {
    switch (source) {
      case 'scenarios': {
        const scenariosOptions: IStrictOption[] =
          scenarios?.map((s) => ({
            label: s.name,
            value: s.id.toString()
          })) ?? [];

        return scenariosOptions;
      }

      case 'missions': {
        const missionOptions: IStrictOption[] =
          missions?.map((m) => ({
            label: m.name,
            value: m.id.toString()
          })) ?? [];
        return missionOptions;
      }

      case 'quests': {
        const questOptions: IStrictOption[] =
          quests?.map((q) => ({
            label: q.name,
            value: q.id.toString()
          })) ?? [];
        return questOptions;
      }

      case 'items': {
        const itemOptions: IStrictOption[] =
          items?.map((i) => ({
            label: i.name,
            value: i.id
          })) ?? [];
        return itemOptions;
      }

      case 'chatbots': {
        const chatbotOptions: IStrictOption[] =
          chatbots?.map((c) => ({
            label: c.name,
            value: c.id
          })) ?? [];

        return chatbotOptions;
      }

      default:
        break;
    }
  }
  if (source instanceof Array) {
    const projectRegex = /\{project-id\}/g;
    const scenarioRegex = /\{scenario-id\}/g;
    const missionRegex = /\{mission-id\}/g;
    const questRegex = /\{quest-id\}/g;
    return source.map((s) => {
      return {
        label: s.label,
        value: s.value
          .replace(projectRegex, projectId ?? '')
          .replace(scenarioRegex, scenarioId ?? '')
          .replace(missionRegex, missionId ?? '')
          .replace(questRegex, questId ?? '')
      };
    });
  }

  return [{ label: '', value: 'null' }];
};
