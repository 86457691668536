import { css } from '@emotion/react';
import { onMobile } from './responsive';

const desktopPaddingSize = 24;
const mobilePaddingSize = 16;

const desktopPaddings = css`
  padding: ${desktopPaddingSize}px ${desktopPaddingSize}px 0;
`;

const mobilePaddings = css`
  padding: ${mobilePaddingSize}px ${mobilePaddingSize}px 0;
`;

export const BottomPadding = css`
  padding-bottom: ${desktopPaddingSize}px;

  ${onMobile} {
    padding-bottom: ${mobilePaddingSize}px;
  }
`;

export const makeContainerStyle = (width: number) => {
  return css`
    ${desktopPaddings}

    max-width: ${width ? `${width + desktopPaddingSize * 2}px` : `none`};

    ${onMobile} {
      ${mobilePaddings}

      max-width: none;
    }
  `;
};

export const container960style = makeContainerStyle(960);
export const container650Style = makeContainerStyle(650);
export const container460Style = makeContainerStyle(460);
export const containerStyle = makeContainerStyle(0);
