import { generatePath, useLocation } from 'react-router-dom';

import { useParamsWithMatchPath } from '@/hooks';
import { operation, gameEdit, operationV2 } from '../pages';

const operationPages = Object.keys(operation);
const operationV2Pages = Object.keys(operationV2);
const gameEditPages = Object.keys(gameEdit);

export const paramsNotFound = { appId: undefined, projectId: undefined, pageKey: undefined };

export default function useNavigationType() {
  const location = useLocation();

  const { appId, projectId, pageKey, isV2 } = useParamsWithMatchPath();

  const parentPath = getParentPath({ appId, projectId, isV2 });

  const isLogoOnlyPage = parentPath === '/' && !location.pathname.includes('verifyContact');
  const isHomePage = !pageKey;

  function mapper(endWith: string) {
    if (projectId === undefined) return `${parentPath}`;
    return `${parentPath}/${endWith}`;
  }

  const operation = isV2 ? operationV2Pages.map(mapper) : operationPages.map(mapper);
  const gameEdit = gameEditPages.map(mapper);

  const isOperationPage =
    !isLogoOnlyPage &&
    !isHomePage &&
    operation.findIndex((pathname) => pathname === location.pathname) !== -1;
  const isGameEditPage =
    !isLogoOnlyPage &&
    !isHomePage &&
    gameEdit.findIndex((pathname) => location.pathname.startsWith(pathname)) !== -1;

  const navigation = {
    type: (isOperationPage && 'default') || (isGameEditPage && 'gameEdit') || undefined,
    paths: {
      operation,
      gameEdit
    }
  } as const;

  const topBarDefaultType = [
    `/apps/${appId}`,
    `/apps/${appId}/`,
    `/apps/${appId}/settings`,
    `/apps/${appId}/projects/create`,
    `/apps/${appId}/projectsV2/create`,
    `/apps/${appId}/payment/success`,
    `/apps/${appId}/payment/fail`,
    '/verifyContact'
  ];
  const isTopBarDefault = !isLogoOnlyPage && topBarDefaultType.includes(location.pathname);

  const topBarType =
    (isLogoOnlyPage && 'logoOnly') ||
    (isTopBarDefault && 'default') ||
    (isOperationPage && 'operation') ||
    'mission';

  return { parentPath, navigation, topBarType, pageKey, isV2 } as const;
}

function getParentPath({
  appId,
  projectId,
  isV2
}: {
  appId?: string;
  projectId?: string;
  isV2: boolean;
}) {
  if (!appId || appId === 'create') return '/';

  if (isV2) {
    const path = projectId
      ? generatePath('/apps/:appId/projectsV2/:projectId', { appId, projectId })
      : generatePath('/apps/:appId', { appId });

    return path;
  }

  const path = projectId
    ? generatePath('/apps/:appId/projects/:projectId', { appId, projectId })
    : generatePath('/apps/:appId', { appId });

  return path;
}
