import type { KeyLoader } from 'swr';
import type { SWRInfiniteConfiguration } from 'swr/infinite';
import useSWRInfinite from 'swr/infinite';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { coreApi } from '@/api';

export type Config<Data = unknown, Error = unknown> = SWRInfiniteConfiguration<
  Data,
  AxiosError<Error>
>;

export default function useRequestInfinite<Data extends Array<unknown>, Error = unknown>(
  route: KeyLoader<Data>,
  swrConfig: Config<Data, Error> = {},
  axiosConfig?: AxiosRequestConfig,
  axiosInstance = coreApi
) {
  const { pageSize } = axiosConfig?.params;

  const { data: pagingItems, ...rest } = useSWRInfinite<Data, AxiosError<Error>>(
    route,
    async (route: string) => {
      const { data } = await axiosInstance.get<Data>(route, axiosConfig);

      return data;
    },
    swrConfig
  );

  const data = pagingItems?.flatMap<Data[number]>((item) => item);

  return {
    data,
    isReachedEnd: data && data.length % pageSize > 0,
    ...rest
  };
}
