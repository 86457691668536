import { css } from '@emotion/react';
import { RadioButton, spacing } from '@uniquegood/realworld-studio-design';
import React, { SetStateAction } from 'react';

import { CursorPointer, Flex } from '@/styles';
import { ThemeStatus } from '../types';

interface RadioItemProps {
  value: ThemeStatus;
  imageUrl: string;
  checked: boolean;
  onChange: (value: SetStateAction<ThemeStatus>) => void;
  onClick?: () => unknown;
}

export default function RadioItem({
  value,
  imageUrl,
  checked,
  onChange,
  children,
  onClick
}: React.PropsWithChildren<RadioItemProps>) {
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/click-events-have-key-events
    <label css={[CursorPointer, Flex]} onClick={onClick}>
      <img alt="theme-preview" src={imageUrl} css={ImageStyle} />

      <div css={ContentWrapper}>
        <RadioButton name="status" value={value} onChange={onChange} checked={checked}>
          {/* children이 필수값이어서 Null을 반환하는 Child 를 생성 */}
          <NullChild />
        </RadioButton>
        {children}
      </div>
    </label>
  );
}

const ImageStyle = css`
  width: 180px;
  margin-right: ${spacing.common.medium};
  margin-right: ${spacing.margin.large};
`;

const ContentWrapper = css`
  > *:nth-of-type(1n) {
    margin-top: ${spacing.margin.small};
  }
`;

const NullChild = () => {
  return null;
};
