import axios from 'axios';
import { ACCOUNT_API_HOST } from '@/env';

export type Response<T> = {
  success: boolean;
  message: string;
  data: T;
};

export type NiceToken = {
  encData: string;
  integrityValue: string;
  tokenVersionId: string;
};

export const NiceAuthError = {
  //   NotFoundPhoneCertification
  // 토큰 발행 시 토큰 ID가 발급되고 그 정보를 저장하는데 콜백에서 그 정보를 찾을 수 없을 때 나오는 에러
  // InternalServerError
  // nice와의 통신 실패 또는 알 수 없는 에러
  // NotSupportedType
  // returnPath 가 지원하지 않는 path 일 때
  // NiceCertificationFailed
  // nice 본인인증 실패
  // AlreadyExistPhoneCertification
  // 이미 인증된 번호 일 때
  // AlreadyPhoneCertifiedUser
  // 이미 인증된 사용자 일 때
  // InvalidPhoneNumber
  // ^010[0-9]{8}\$  해당 정규식에 어긋날 때
  NotFoundPhoneCertification: 'NotFoundPhoneCertification',
  InternalServerError: 'InternalServerError',
  NotSupportedType: 'NotSupportedType',
  NiceCertificationFailed: 'NiceCertificationFailed',
  AlreadyExistPhoneCertification: 'AlreadyExistPhoneCertification',
  AlreadyPhoneCertifiedUser: 'AlreadyPhoneCertifiedUser',
  InvalidPhoneNumber: 'InvalidPhoneNumber'
};

export const passAuthInstance = axios.create({
  baseURL: `https://${ACCOUNT_API_HOST}`,
  withCredentials: true
});

export default async function getNiceToken(accessToken: string) {
  const { data } = await passAuthInstance.post<Response<NiceToken>>(
    '/api/auth/nice/token',
    {
      returnPath: 'Studio'
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'ngrok-skip-browser-warning': '69420'
      }
    }
  );

  return data;
}
