import { useState } from 'react';
import { css } from '@emotion/react';
import { Button, shadow, spacing } from '@uniquegood/realworld-studio-design';
import { MarginRight4, MarginTop4 } from '@/styles';

interface ImageProps extends React.ComponentPropsWithoutRef<'img'> {
  file: UserFileResponseModel;
}

export default function Image({ file, ...imgProps }: ImageProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const toggleDialog = (event?: unknown) => {
    setIsDialogOpen((isDialogOpen) => !isDialogOpen);
  };

  const handlers = {
    onClick: toggleDialog,
    onKeyDown: toggleDialog
  };

  return (
    <>
      <img css={baseImageStyle} alt={file.id} src={file.url} {...handlers} {...imgProps} />
      {isDialogOpen && (
        <div role="dialog" data-studio-overlay="true" css={imagePreviewDialog} {...handlers}>
          <div>
            <img
              alt={file.id}
              src={file.url}
              tabIndex={-1}
              onKeyDown={stopPropagation}
              onClick={stopPropagation}
            />
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div css={deleteButtonStyle} onKeyDown={stopPropagation} onClick={stopPropagation}>
              <Button
                cssStyle={[MarginRight4, MarginTop4]}
                icon="times_regular"
                type="plainWhite"
                size="medium"
                {...handlers}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function stopPropagation(event: React.KeyboardEvent | React.MouseEvent) {
  event.stopPropagation();
}

const baseImageStyle = css`
  display: block;
  flex-shrink: 0;
  object-fit: cover;
  border-radius: ${spacing.common.small};
  cursor: pointer;
  ${shadow.toast};
`;

const deleteButtonStyle = css`
  position: absolute;
  top: 0;
  height: 42px;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
  display: flex;
  align-items: baseline;
  justify-content: end;

  border-top-right-radius: ${spacing.common.small};
  border-top-left-radius: ${spacing.common.small};
`;

const imagePreviewDialog = css`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;

  /* 디자인시스템 모달 백드롭 스타일에서 스타일 시트를 가져왔습니다. */
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  animation: fadeIn 200ms 1 forwards;
  opacity: 1;
  will-change: opacity;
  cursor: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.9095 12.0003L19.6786 6.2311L20.8684 5.04138C21.0439 4.86587 21.0439 4.58067 20.8684 4.40517L19.5954 3.13219C19.4199 2.95669 19.1347 2.95669 18.9592 3.13219L12.0003 10.0911L5.04138 3.13163C4.86587 2.95612 4.58067 2.95612 4.40517 3.13163L3.13163 4.40461C2.95612 4.58011 2.95612 4.86531 3.13163 5.04081L10.0911 12.0003L3.13163 18.9592C2.95612 19.1347 2.95612 19.4199 3.13163 19.5954L4.40461 20.8684C4.58011 21.0439 4.86531 21.0439 5.04081 20.8684L12.0003 13.9095L17.7695 19.6786L18.9592 20.8684C19.1347 21.0439 19.4199 21.0439 19.5954 20.8684L20.8684 19.5954C21.0439 19.4199 21.0439 19.1347 20.8684 18.9592L13.9095 12.0003Z' fill='white' /%3E%3C/svg%3E"),
    auto;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  > div {
    position: relative;
    cursor: default;
    user-select: none;

    img {
      max-width: 80vh;
      max-height: 90vh;
      border-radius: ${spacing.common.small};
    }
  }
`;
