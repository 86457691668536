import {
  Icon,
  Typography,
  Tooltip,
  color,
  Button,
  spacing,
  textStyleBody
} from '@uniquegood/realworld-studio-design';
import React, { ReactNode } from 'react';
import { css } from '@emotion/react';

import {
  SelectOption,
  SelectProps as OriginalSelectProps
} from '@uniquegood/realworld-studio-design/dist/types/atoms/Select/Select';
import { IReactionConditionDefinition, ReactionProps } from '@/components/ActionHandler/types';
import { getOptionsByType } from '@/components/ActionHandler/utils/getOptions';
import { InputItem } from '@/components/ActionHandler/components/element';
import {
  DisplayStyle,
  ElementWrap,
  FormGroupWrap,
  LabelIcon,
  LabelTooltip,
  Suffix,
  TrashButtonWrap
} from '@/components/ActionHandler/styles';
import { conditionType, getIsFlex, getIsWidth100 } from '@/components/ActionHandler/utils';
import { MapOrEntries } from '@/hooks/useMap';
import { AlignCenterInlineFlex } from '@/styles';
import { replaceItem } from '@/utils';

interface Props extends ReactionProps {
  params: ReactionCondition;
  setParams: React.Dispatch<React.SetStateAction<ReactionCondition[]>>;
  conditionDefinition?: IReactionConditionDefinition;
  conditionIndex: number;
  removeCondition: (index: number) => void;
}

const Condition = ({
  conditionDefinition,
  params,
  setParams,
  conditionIndex,
  removeCondition,
  scenarios,
  missions,
  quests,
  items,
  chatbots
}: Props) => {
  // console.log('a', conditionDefinition);
  if (!conditionDefinition) throw new Error('새로고침 해주세요');

  const HoverNotWorking = css`
    &:hover {
      .button-trash-wrap {
        visibility: hidden;
        opacity: 0;
      }
    }
  `;

  return (
    <div css={[FormGroupWrap, conditionDefinition.type === conditionType.none && HoverNotWorking]}>
      <div css={[AlignCenterInlineFlex]}>
        <div className="button-trash-wrap" css={TrashButtonWrap}>
          <Button
            type="plainDestructive"
            icon="trash_alt_solid"
            onClick={() => removeCondition(conditionIndex)}
          />
        </div>
        {conditionDefinition.icon && (
          <Icon icon={conditionDefinition.icon} size="20px" cssStyle={LabelIcon} />
        )}
        <Typography type="button">{conditionDefinition.label}</Typography>
        {conditionDefinition.tooltip && (
          <Tooltip title={conditionDefinition.label} content={conditionDefinition.tooltip}>
            <Icon
              icon="question_circle_solid"
              size="16px"
              color={color.icon_disabled}
              cssStyle={LabelTooltip}
            />
          </Tooltip>
        )}
      </div>
      {conditionDefinition.parameterDefinitions?.map((definitionParameter, index) => {
        // eslint-disable-next-line no-underscore-dangle
        const _defaultValue = params?.parameters[definitionParameter.key];
        const defaultValue = _defaultValue === undefined ? undefined : String(_defaultValue);

        const onChange = (value: string) => {
          if (value) {
            setParams((conditions) => {
              const condition = conditions[conditionIndex];

              const parameter = {
                [definitionParameter.key]: value
              };

              return replaceItem(conditions, conditionIndex, {
                ...condition,
                parameters: { ...condition.parameters, ...parameter }
              });
            });
          }
        };

        const selectedOptions = getOptionsByType({
          scenarios,
          missions,
          quests,
          items,
          chatbots,
          source: definitionParameter.source
        });

        const customElementWidthStyle = css`
          ${(definitionParameter.prefix || definitionParameter.suffix) &&
          definitionParameter.style === 'nowrap' &&
          `width: 300px;`};
        `;

        const definitionParameterMap: MapOrEntries<string, ReactNode> = new Map([
          [
            'text',
            <InputItem
              cssStyle={customElementWidthStyle}
              params={params}
              setParams={onChange}
              defaultValue={defaultValue}
              placeholder={definitionParameter?.description}
            />
          ],
          [
            'list',
            <Select
              type="number"
              options={selectedOptions}
              cssStyle={customElementWidthStyle}
              onChange={onChange}
              value={defaultValue}
              placeholder={definitionParameter?.description}
            />
          ],
          [
            'stringList',
            <Select
              options={selectedOptions}
              cssStyle={customElementWidthStyle}
              onChange={onChange}
              value={defaultValue}
              placeholder={definitionParameter?.description}
            />
          ]
        ]);

        const element = Array.from(definitionParameterMap.entries()).find(
          ([key]) => key === definitionParameter.type
        )?.[1];

        const suffixMarkup = definitionParameter.suffix ? (
          <Typography type="body" cssStyle={Suffix} textColor="subdued">
            {definitionParameter.suffix}
          </Typography>
        ) : null;

        const isWidth100 = getIsWidth100(definitionParameter.style);
        const isFlex = getIsFlex(definitionParameter?.suffix, definitionParameter?.prefix);

        return (
          <div
            key={`${definitionParameter.key.toString() + index.toString()}`}
            className="form-element-wrap"
            css={[DisplayStyle({ isWidth100, isFlex }), ElementWrap({ isWidth100, isFlex })]}
          >
            {element}
            {suffixMarkup}
          </div>
        );
      })}
    </div>
  );
};

export default Condition;

interface SelectProps extends OriginalSelectProps {
  type?: 'string' | 'number';
  onChange(value: string | number): void;
  options: Exclude<SelectOption, string>[];
}

function Select({ type, cssStyle, value, options, placeholder, onChange }: SelectProps) {
  return (
    <select
      css={[cssStyle, SelectStyle]}
      value={value ?? ''}
      onChange={(event) =>
        onChange(type === 'number' ? parseInt(event.target.value, 10) : event.target.value)
      }
    >
      <option disabled value="">
        {placeholder}
      </option>
      {options?.map((option) => (
        <option value={option.value}>{option.label}</option>
      ))}
    </select>
  );
}

const SelectStyle = css`
  ${textStyleBody};
  width: 100%;
  min-height: 36px;
  padding: ${spacing.borderedPadding.small} ${spacing.borderedPadding.medium};

  background-color: ${color.surface_default_subdued};
  border: 1px solid ${color.border_default_subdued};
  border-radius: ${spacing.borderRadius.small};
`;
