/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css } from '@emotion/react';
import {
  FormGroup,
  color,
  spacing,
  Divider,
  CheckBox,
  Button,
  Modal
} from '@uniquegood/realworld-studio-design';
import React, { ReactNode, useMemo, useState } from 'react';

import WebviewBuilder, { ActionParameterContent } from '@packages/webview-builder';
import { useParams } from 'react-router-dom';
import { InputItem, SegmentedItem } from '@/components/ActionHandler/components/element';
import { IActionTypeDefinition } from '@/components/ActionHandler/types';
import { MapOrEntries } from '@/hooks/useMap';

import Uploader, { UploaderProps } from '@/components/Uploader';
import Location from './items/Location';
import Theme from './items/Theme';
import { useDidUpdateEffect, useModalState } from '@/hooks';
import { toast } from '@/utils';
import { coreApi, studioApi } from '@/api';
import { getAccessToken } from '@/auth';
import LocationMap from './items/LocationMap';
import { ClickEventName, SaveEventName, track } from '@/track';
import RangeInput from '@/components/RangeInput';

export interface ActionParametersEditorProps {
  actionDefinition?: IActionTypeDefinition;
  actionParameter: ActionHandler['actionParameters'];
  setActionParameters: React.Dispatch<React.SetStateAction<ActionHandler['actionParameters']>>;
  items?: GameItem[];
}
const ActionParametersEditor = ({
  actionParameter,
  setActionParameters,
  actionDefinition,
  items
}: ActionParametersEditorProps) => (
  <>
    {actionDefinition?.parameterDefinitions?.map((parameter) => {
      const key = parameter.key ?? parameter.type;
      const paramValue = actionParameter?.[key];

      const stringParamValue = paramValue?.toString();

      const onChangeValue = <T extends string | number>(value: T) => {
        setActionParameters((prev) => ({ ...prev, [key]: value }));
      };

      const onChangeCheckBox = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        setActionParameters((prev) => ({ ...prev, [key]: target.checked }));
      };

      const saveUploadFiles = (uploadFiles?: UserFileResponseModel[]) => {
        if (uploadFiles) {
          setActionParameters((prev) => ({
            ...prev,
            [key]: uploadFiles[0]?.url
          }));
        }
      };

      const customElementWidthStyle = css`
        ${(parameter.prefix || parameter.suffix) && `width: 300px;`};
      `;

      const ActionParamsMap: MapOrEntries<string, ReactNode> = new Map([
        [
          'text',
          <InputItem
            cssStyle={customElementWidthStyle}
            params={paramValue}
            setParams={onChangeValue}
            defaultValue={stringParamValue}
            placeholder={parameter?.placeholder}
          />
        ],
        [
          'html',
          <WebViewBuilderContainer
            actionParameter={actionParameter}
            setActionParameters={setActionParameters}
          />
        ],
        [
          'check',
          <CheckBox defaultChecked={!!paramValue} onChange={onChangeCheckBox}>
            {parameter?.description}
          </CheckBox>
        ],
        [
          'image',
          <UploaderWrapper
            ratio="square"
            accepts={parameter.accepts}
            maxFileSize={parameter.maxFileSize}
            useFor={parameter.useFor}
            uploadedFiles={stringParamValue ? [{ url: stringParamValue, id: '' }] : undefined}
            getUploadFiles={saveUploadFiles}
          />
        ],
        [
          'segmented',
          <SegmentedItem
            options={parameter.source}
            value={stringParamValue}
            setValue={onChangeValue}
            defaultValue={
              actionDefinition.type === 'RealWorld.ActionTypes.FrameShot' ? 'front' : undefined
            }
          />
        ],
        [
          'targetLocation',
          <>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <Location actionParameter={actionParameter} setActionParameters={setActionParameters} />
            <Divider cssStyle={DividerStyle} />
          </>
        ],
        [
          'theme',
          <>
            <SegmentedItem
              options={parameter.source}
              value={stringParamValue}
              setValue={onChangeValue}
              defaultValue={stringParamValue ?? 'default'}
            />
            {paramValue === 'custom' && (
              /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
              /* @ts-ignore */
              <Theme actionParameter={actionParameter} setActionParameters={setActionParameters} />
            )}
          </>
        ],
        [
          'targetLocationMap',
          actionParameter ? (
            <LocationMap
              actionParameter={actionParameter}
              setActionParameters={setActionParameters}
              items={items}
            />
          ) : undefined
        ],
        [
          'range',
          (paramValue === undefined || typeof paramValue === 'number') && (
            <RangeInput
              value={paramValue}
              onChange={onChangeValue}
              min={parameter.min}
              max={parameter.max}
              step={parameter.step}
              multiplyToShow={parameter.multiplyToShow}
              unit={parameter.unit}
            />
          )
        ]
      ]);

      const element = Array.from(ActionParamsMap.entries()).find(
        ([key]) => key === parameter.type
      )?.[1];

      return (
        <FormGroup
          cssStyle={FormGroupSpacing}
          key={parameter.key || parameter.type}
          label={parameter.description}
          labelTooltip={parameter?.tooltip ? { content: parameter?.tooltip } : undefined}
        >
          {element}
        </FormGroup>
      );
    })}
  </>
);

export default ActionParametersEditor;

const DividerStyle = css`
  background-color: ${color.border_default_subdued};
`;

const FormGroupSpacing = css`
  &:not(:last-of-type) {
    margin-bottom: ${spacing.common.large};
  }
`;

type WebViewBuilderContainerProps = Pick<
  ActionParametersEditorProps,
  'setActionParameters' | 'actionParameter'
>;

function WebViewBuilderContainer({
  actionParameter,
  setActionParameters
}: WebViewBuilderContainerProps) {
  const { appId, projectId } = useParams<AppParam>();
  const [webViewBuildModalIsOpen, setWebViewBuildModalIsOpen] = useState(false);
  const { modal, openModal, closeModal } = useModalState();

  const transformedActionParameter = useMemo(() => {
    if (
      actionParameter &&
      (typeof actionParameter.templateId === 'string' ||
        typeof actionParameter.content === 'string')
    )
      return {
        templateId: actionParameter.templateId ?? null,
        editedParams: actionParameter.editedParams ?? {},
        content: actionParameter.content
      };

    return null;
  }, [actionParameter]) as ActionParameterContent;

  const [data, setData] = useState(transformedActionParameter);

  const isNotModified = data === transformedActionParameter;

  // Hack: isNotModified 값 동기화를 위해 data 레피런스의 값을 transformedActionParameter 값으로 업데이트 시키기
  useDidUpdateEffect(() => {
    setData(transformedActionParameter);
  }, [transformedActionParameter]);

  function handleCloseModal() {
    if (isNotModified) {
      closeModal();
      track.onClick({
        clickEventName: ClickEventName.click_editgame_mission_quest_webviewbuilder_close,
        params: data?.templateId ? { templateId: data.templateId } : undefined
      });
      setWebViewBuildModalIsOpen(false);
    } else {
      openModal({
        title: '웹뷰 빌더에서 완료 버튼을 누르지 않았어요',
        children: '웹뷰 빌더를 벗어나면 변경사항이 사라져요. 변경한 사항을 반영하시겠어요?',
        primaryAction: {
          content: '완료',
          icon: 'check_solid',
          onAction: () => {
            setActionParameters(data ?? {});
            track.onSave({
              saveEventName: SaveEventName.save_editgame_mission_quest_webviewbuilder,
              params: { templateId: data?.templateId }
            });
            closeModal();
            setWebViewBuildModalIsOpen(false);
          }
        },
        secondaryAction: {
          content: '취소',
          onAction: closeModal
        },
        leftAction: {
          content: '반영 안함',
          onAction: () => {
            closeModal();
            setData(transformedActionParameter);
            track.onClick({
              clickEventName: ClickEventName.click_editgame_mission_quest_webviewbuilder_notapply,
              params: data?.templateId ? { templateId: data.templateId } : undefined
            });
            setWebViewBuildModalIsOpen(false);
          }
        }
      });
    }
  }

  const [accessToken, setAccessToken] = useState<string | null>(null);

  React.useEffect(() => {
    getAccessToken().then((token) => {
      setAccessToken(token);
    });
  }, []);

  const handlers = {
    onClick: handleCloseModal,
    onKeyDown: handleCloseModal
  };

  return (
    <>
      <Button
        icon="magic_solid"
        type="primary"
        size="medium"
        onClick={() => {
          setWebViewBuildModalIsOpen(true);
          track.onClick({
            clickEventName: ClickEventName.click_editgame_mission_quest_webviewbuilder,
            params: data?.templateId ? { templateId: data.templateId } : undefined
          });
        }}
      >
        웹뷰 빌더 열기
      </Button>
      {webViewBuildModalIsOpen && (
        <div css={webviewBuilderDialog} role="dialog" {...handlers}>
          <WebviewBuilder
            onKeyDown={stopPropagation}
            onClick={stopPropagation}
            data={data}
            updateData={setData}
            onClose={handleCloseModal}
            onComplete={() => {
              setActionParameters(data ?? {});
              track.onSave({
                saveEventName: SaveEventName.save_editgame_mission_quest_webviewbuilder,
                params: { templateId: data?.templateId }
              });
              setWebViewBuildModalIsOpen(false);
              toast({ message: '변경 사항이 반영이 되었어요' });
            }}
            coreApiEndPoint={coreApi.defaults.baseURL!}
            studioApiEndPoint={studioApi.defaults.baseURL!}
            appId={appId!}
            projectId={projectId!}
            token={`Bearer ${accessToken}`}
            onSelectTemplate={(templateId) => {
              track.onClick({
                clickEventName: ClickEventName.click_editgame_mission_quest_webviewbuilder_template,
                params: { templateId }
              });
            }}
            onDeSelectTemplate={(templateId) => {
              track.onClick({
                clickEventName:
                  ClickEventName.click_editgame_mission_quest_webviewbuilder_template_exit,
                params: { templateId }
              });
            }}
          />
        </div>
      )}
      <Modal {...modal} />
    </>
  );
}

// eslint-disable-next-line react/destructuring-assignment
function UploaderWrapper({ uploadedFiles, ...rest }: UploaderProps) {
  const memoedFiles = useMemo(() => uploadedFiles, [uploadedFiles?.[0].url]);
  return <Uploader {...rest} uploadedFiles={memoedFiles} />;
}

const stopPropagation = <T extends React.KeyboardEvent | React.MouseEvent>(event: T) => {
  event.stopPropagation();
};

const webviewBuilderDialog = css`
  z-index: 3;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;

  inset: 0;

  /* 디자인시스템 모달 백드롭 스타일에서 스타일 시트를 가져왔습니다. */
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  animation: fadeIn 200ms 1 forwards;
  opacity: 1;
  will-change: opacity;
  cursor: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.9095 12.0003L19.6786 6.2311L20.8684 5.04138C21.0439 4.86587 21.0439 4.58067 20.8684 4.40517L19.5954 3.13219C19.4199 2.95669 19.1347 2.95669 18.9592 3.13219L12.0003 10.0911L5.04138 3.13163C4.86587 2.95612 4.58067 2.95612 4.40517 3.13163L3.13163 4.40461C2.95612 4.58011 2.95612 4.86531 3.13163 5.04081L10.0911 12.0003L3.13163 18.9592C2.95612 19.1347 2.95612 19.4199 3.13163 19.5954L4.40461 20.8684C4.58011 21.0439 4.86531 21.0439 5.04081 20.8684L12.0003 13.9095L17.7695 19.6786L18.9592 20.8684C19.1347 21.0439 19.4199 21.0439 19.5954 20.8684L20.8684 19.5954C21.0439 19.4199 21.0439 19.1347 20.8684 18.9592L13.9095 12.0003Z' fill='white' /%3E%3C/svg%3E"),
    auto;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  > * {
    max-width: 80%;
    max-height: 90%;
    cursor: default;
    user-select: none;
  }
`;
