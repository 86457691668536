import { css } from '@emotion/react';
import {
  ActionList,
  Avatar,
  Button,
  Card,
  color,
  Icon,
  Modal,
  spacing,
  usePopover
} from '@uniquegood/realworld-studio-design';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import { Flex, FlexWrap, MarginBottom24, MarginRight24, MarginTop16 } from '@/styles';
import { coreApi } from '@/api';
import { timeForToday } from '../utils';
import WritePost from '../components/WritePost';
import { useModalState } from '@/hooks';
import Comments from './Comments';

import { ReactComponent as ChatRegular } from '../assets/chat_regular.svg';
import Image from './Image';

type PostProps =
  | {
      feedsType: 'creatorFeeds';
      post: CreatorFeedPost | UserFeedPost;
      mutate(): unknown;
    }
  | { feedsType: 'UserFeeds'; post: UserFeedPost; mutate(): unknown };

export default function Post({ feedsType, post, mutate: mutateFeeds }: PostProps) {
  const { appId } = useParams<AppParam>();

  const { mutate } = useSWRConfig();

  const [isOpen, setIsOpen] = useState(false);
  const handleClosePopover = useCallback(() => {
    setIsOpen(false);
  }, []);
  const { popover, anchor } = usePopover({
    positionX: 'right',
    positionY: 'bottom',
    isOpen,
    onClose: handleClosePopover
  });

  const [isEditMode, setIsEditMode] = useState(false);

  const { modal, openModal, closeModal } = useModalState();

  if (isEditMode)
    return (
      <WritePost
        onClose={() => setIsEditMode(false)}
        feedsType={feedsType}
        post={post}
        mutate={mutateFeeds}
      />
    );

  return (
    <Card>
      <div css={headerLayout}>
        <div css={Flex}>
          <Avatar
            source={post.writer.profileUrl}
            userName={post.writer.name}
            userEmail={timeForToday(post.createdAt)}
          />
          {post.isCreator &&
            'channelType' in post.writer &&
            post.writer.channelType === 'Realworld' && (
              <Icon icon="check_circle_solid" size="20px" cssStyle={officialIconLayout} />
            )}
        </div>

        <Button
          ariaExpanded={anchor['aria-expanded']}
          ref={anchor.ref}
          icon="ellipsis_v_regular"
          type="plain"
          onClick={() => setIsOpen(!isOpen)}
        />
        <div {...popover}>
          <ActionList
            onActionAnyItem={handleClosePopover}
            items={[
              ...(post.isCreator
                ? [
                    {
                      content: '수정',
                      onAction: () => {
                        setIsEditMode(true);
                      }
                    }
                  ]
                : []),
              ...(feedsType === 'creatorFeeds'
                ? [
                    {
                      content: '고정',
                      onAction: async () => {
                        await coreApi.put(`/api/${appId}/community/creatorFeeds/${post.id}/pin`);
                        mutate(`/api/${appId}/community/creatorFeeds?page=1&take=1&isPin=true`);
                        mutateFeeds();
                      }
                    }
                  ]
                : []),
              {
                content: '삭제',
                onAction: async () => {
                  await coreApi.delete(
                    `/api/${appId}/community/${feedsType}/${post.id}?isCreator=true`
                  );
                  mutateFeeds();
                },
                destructive: true
              }
            ]}
          />
        </div>
      </div>
      <article css={[MarginTop16, MarginBottom24, articleLayout]}>{post.content}</article>
      {post.images && post.images.length > 0 && (
        <div css={[FlexWrap, MarginBottom24, imageGaps]}>
          {post.images.map((image) => (
            <Image key={image.id} file={image} height="100px" />
          ))}
        </div>
      )}
      <div css={Flex}>
        <Button
          size="medium"
          cssStyle={[likeButtonStyle, MarginRight24]}
          type="plain"
          disabled
          icon={post.isLiked ? 'heart_solid' : 'heart_regular'}
        >
          {post.likeCount?.toString() ?? 0}
        </Button>
        <Button
          size="medium"
          cssStyle={likeButtonStyle}
          type="plain"
          icon={<ChatRegular />}
          onClick={() => {
            openModal({
              title: '댓글 펼쳐보기',
              size: 'medium',
              children: (
                <Comments feedsType="creatorFeeds" feedId={post.id} mutateFeeds={mutateFeeds} />
              ),
              secondaryAction: {
                content: '닫기',
                onAction: closeModal
              }
            });
          }}
        >
          {post.commentCount?.toString() ?? 0}
        </Button>
      </div>
      <Modal {...modal} />
    </Card>
  );
}

const officialIconLayout = css`
  margin-top: 0;
  margin-left: ${spacing.margin.small};
`;

const headerLayout = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const likeButtonStyle = css`
  .ButtonContent .Icon {
    width: 20px;
    height: 19px;
  }
  svg {
    color: ${color.interactive_primary_default};
  }

  .Text {
    color: ${color.text_subdued};
  }

  align-items: center;
`;

const articleLayout = css`
  overflow-wrap: anywhere;
  white-space: pre-line;
`;

const imageGaps = css`
  > img:not(:last-of-type) {
    margin-right: ${spacing.margin.xsmall};
  }
`;
