import { css } from '@emotion/react';
import {
  Banner,
  Button,
  spacing,
  color,
  typography,
  Typography
} from '@uniquegood/realworld-studio-design';
import { useHistory } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { Container650 } from '@/components/containers';
import { onMobile } from '@/styles/responsive';
import { studioApi } from '@/api';
import { useRequest } from '@/hooks';
import { ClickEventName, PageViewEventName, track } from '@/track';

const agreeTermsPageStyle = css`
  margin-top: 152px;

  ${onMobile} {
    margin-top: 80px;
  }

  .terms-header {
    margin-bottom: ${spacing.margin.small};
    display: flex;
    justify-content: space-between;
    margin-top: ${spacing.margin.xlarge2};

    h3 {
      margin: 0;
      font-size: ${typography.size.s3};
    }

    .rev-date {
      font-size: ${typography.size.s1};
    }
  }

  .well {
    padding: ${spacing.padding.xlarge2};
    border: 1px solid ${color.border_default_subdued};
    background-color: ${color.surface_default_subdued};

    max-height: 322px;
    border-radius: ${spacing.borderRadius.xlarge};

    white-space: pre-line;
    overflow-y: auto;

    h2 {
      &:not(:first-of-type) {
        margin-top: 1em;
      }

      margin-bottom: ${spacing.margin.xsmall};
    }

    ol {
      margin: 0;
    }

    li {
      list-style: decimal;
    }
  }

  .btn-agree-wrap {
    margin-top: ${spacing.margin.xlarge2};
    text-align: center;
  }
`;

export default function AgreeTermsPage() {
  const history = useHistory();

  useEffect(() => {
    track.onPageView({ pageViewEventName: PageViewEventName.view_agreetermpage });
  }, []);

  const { mutate } = useRequest<OnboardingStepStatus>('/api/creators/me', {}, {}, studioApi);
  const { handleSubmit, formState } = useForm();

  const onSubmit = handleSubmit(async () => {
    await studioApi.post('/auth/agreeTermsApi');
    await mutate();
    history.push('/verifyContact');
  });

  return (
    <Container650 centered>
      <form css={agreeTermsPageStyle} onSubmit={onSubmit}>
        <Banner type="informational" header="새롭게 단장한 리얼월드 스튜디오를 둘러보세요!">
          크리에이터 스페이스가 리얼월드 스튜디오로 새단장했어요. 개정된 이용 약관에 동의하고 새롭게
          바뀐 리얼월드 스튜디오에 입장해주세요!
        </Banner>
        <header className="terms-header">
          <Typography as="h1" type="heading">
            이용약관
          </Typography>
          <span className="rev-date">2021년 4월 12일 시행</span>
        </header>
        <Typography as="article" className="well" type="body">
          <Typography as="h2" id="-1-" type="subheading">
            제1조 목적
          </Typography>
          <p>
            리얼월드 스튜디오 이용약관(이하 &#39;본 약관&#39;)은 주식회사유니크굿컴퍼니(이하
            &#39;회사&#39;)와 이용자 간에 회사가 제공하는 리얼월드 스튜디오 서비스의 제공 및 이용과
            관련한 회사와 이용자의 권리, 의무 및 책임에 관한 제반 사항과 기타 필요한 사항을
            구체적으로 규정함을 목적으로 합니다.
          </p>
          <Typography as="h2" id="-2-" type="subheading">
            제2조 용어의 정의
          </Typography>
          <p>본 약관에서 사용하는 용어의 정의는 다음과 같습니다</p>
          <ol>
            <li>
              &#39;이용자&#39;란 리얼월드(
              <Button url="https://realworld.to">https://realworld.to</Button>) 서비스의 회원으로서
              본 약관에 따라 회사와 이용 계약을 체결하고 리얼월드 스튜디오 서비스를 이용하는 자를
              말합니다.
            </li>
            <li>
              &#39;본 서비스&#39;란 이용자가 리얼월드 스튜디오를 이용하여 리얼월드 경험을 제작하고
              배포하도록 하는 서비스를 말합니다.
            </li>
            <li>
              &#39;리얼월드&#39;란 회사가 제공하는 경험 플랫폼 서비스이며 이용자가 만든 경험을
              체험할 수 있는 서비스를 말합니다.
            </li>
            <li>
              &#39;경험&#39;이란 리얼월드 서비스 내에서 &#39;프로젝트&#39;나 &#39;시나리오&#39;,
              &#39;아이템&#39; 등으로 표현되는 것을 말하며 리얼월드 서비스를 이용하여 제작하게
              됩니다.
            </li>
            <li>&#39;플레이어&#39;란 리얼월드 서비스를 통해 경험을 체험하는 사람을 말합니다.</li>
            <li>
              &#39;리얼월드 채널&#39;이란 리얼월드 서비스 내에서 이용자가 제작한 경험의 권한을
              설정하는 단위를 말합니다.
            </li>
          </ol>
          <Typography as="h2" id="-3-" type="subheading">
            제3조 이용계약의 체결 및 약관의 개정
          </Typography>
          <ol>
            <li>
              본 약관은 이용자가 본 서비스의 이용을 위하여 동의를 하고 채널을 생성함으로써 효력이
              발생하며, 그에 따라 회사와 이용자 사이의 이용계약(이하 &#39;계약&#39;)이 체결됩니다.
            </li>
            <li>
              회사는 약관규제법 등 관련 법령에 위배되지 않는 범위 안에서 약관을 개정할 수 있으며,
              약관 개정 시 적용일자로부터 7일 이전부터 약관의 개정내용 및 적용일자를 리얼월드
              스튜디오를 통해 공지합니다. 다만, 약관이 이용자에게 불리하게 개정되는 경우에는
              적용일자로부터 30일 이전부터 공지하고 이용자에게 이메일 등의 수단으로 개별통지합니다.
            </li>
          </ol>
          <Typography as="h2" id="-4-" type="subheading">
            제4조 본 서비스 제공의 조건
          </Typography>
          <ol>
            <li>
              회사는 본 서비스를 제공함에 있어서 서비스의 범위, 이용 가능 시간, 이용 가능 횟수를
              지정할 수 있으며, 이와 같은 제공조건은 리얼월드 스튜디오를 통해 공지되며, 이용자는
              그와 같이 정해진 제공조건에 따라서만 서비스를 이용할 수 있습니다.
            </li>
            <li>
              회사의 서비스 제공은 어떠한 경우에도 회사와 이용자의 제휴 관계의 성립을 의미하지
              않습니다.
            </li>
            <li>
              회사는 본 서비스를 이용해 이용자가 제작한 경험에 광고를 게재할 수 있습니다. 다만, 이와
              같이 광고를 게재하고자 할 경우에는 광고 적용일 30일 전에 리얼월드 스튜디오를 통해 이를
              공지합니다.
            </li>
            <li>
              회사는 본 서비스를 이용해 이용자가 제작한 경험을 활용하여 회사의 광고 혹은 제작 사례로
              사용할 수 있습니다.
            </li>
          </ol>
          <Typography as="h2" id="-5-" type="subheading">
            제5조 리얼월드 채널의 생성
          </Typography>
          <ol>
            <li>
              이용자가 본 서비스를 이용하기 위해서는 리얼월드 채널을 생성해야 하며, 이용자는
              리얼월드 스튜디오를 이용하는 과정과 리얼월드 채널을 생성하는 과정에서 입력해야 하는
              정보를 거짓 없이 그리고 정확하게 입력해야 합니다. 이용자가 입력한 정보가 정확하지
              않거나 불충분한 경우 회사는 정보의 수정, 추가적인 정보의 제공을 요청하거나 리얼월드
              채널의 생성을 거부 혹은 이미 생성 된 리얼월드 채널을 삭제하고 계약의 체결을 거절할 수
              있습니다.
            </li>
            <li>
              회사는 다음과 같은 사실이 확인되는 경우에는 리얼월드 채널의 생성을 거부하고 계약의
              체결을 거절할 수 있습니다.
              <ul>
                <li>이용자가 입력한 정보 혹은 증빙서류에 허위의 정보가 포함되어 있는 경우</li>
                <li>이용자가 타인의 ID, 비밀번호 등을 도용하려 한 경우</li>
                <li>
                  기타 이용자의 귀책 사유로 승낙이 불가능하거나 본 약관 및 공지 등을 위반하여
                  신청하는 경우
                </li>
              </ul>
            </li>
          </ol>
          <Typography as="h2" id="-6-" type="subheading">
            제6조 이용자의 권리 및 의무
          </Typography>
          <ol>
            <li>
              이용자는 위 제4조 등 본 약관에서 허용하는 범위 내에서 본 서비스를 사용할 수 있는
              제한된 권리를 취득합니다.
            </li>
            <li>
              이용자는 본 서비스를 이용함에 있어서 다음과 같은 의무를 부담합니다.
              <ul>
                <li>
                  이용자는 리얼월드 채널의 생성 등 이용계약 체결 과정에 있어서 타인의 정보 혹은
                  허위의 정보를 제출하는 등 회사를 기망하는 행위를 하여서는 안 되며, 이용계약 체결
                  과정에서 고지되는 회사의 정책을 준수하여야 합니다.
                </li>
                <li>
                  이용자는 아래의 목적으로는 본 서비스를 이용할 수 없습니다.
                  <ul>
                    <li>
                      본 서비스를 이용하여 제작 된 경험을 리얼월드 서비스를 통해 배포함에 있어서 그
                      경험을 체험하는 이로부터 대가(금전, 정보 제공 등 일체의 유·무형 대가)를
                      제공받아서는 안 됩니다.
                    </li>
                    <li>
                      영리를 목적으로 하는 기관·단체에서 홍보나 안내 등을 목적으로 본 서비스를
                      이용할 수 없습니다.
                    </li>
                    <li>
                      본 서비스에 대한 이용법을 타인에게 가르쳐주는 경우 그 과정에서 배우는 이로부터
                      대가(금전, 정보 제공 등 일체의 유·무형 대가)를 제공받아서는 안 됩니다.
                    </li>
                    <li>
                      타인·기관·단체로부터 대가(금전, 정보 제공 등 일체의 유·무형 대가)를 제공 받고
                      경험을 제작 혹은 배포할 수 없습니다.
                    </li>
                  </ul>
                </li>
                <li>
                  이용자는 회사의 허락 없이는 본 서비스 이용 과정과 본 서비스를 이용해 만든 경험에
                  광고를 노출할 수 없습니다
                </li>
                <li>
                  이용자는 본 서비스의 제공 주체 및 제휴관계 등에 대해서 오인을 받을 수 있는 외관을
                  형성하면 안됩니다.
                </li>
                <li>
                  이용자는 본 서비스를 이용함에 있어서 이용자를 회사의 임직원 혹은 관계사(관계자)로
                  사칭하거나 이용자를 본 서비스 혹은 리얼월드 서비스의 제공자로 표시하거나 그와 같은
                  오인을 할 수 있는 외관을 형성해서는 안 되며, 이용자와 회사 사이에 제휴 관계가
                  존재하는 것으로 오인할 수 있는 외관을 형성해서도 안됩니다.
                </li>
                <li>
                  이용자는 본 서비스를 이용하거나 경험을 제작하는 데에 있어서 본 서비스를
                  모방하거나, 회사의 브랜드 등의 지적재산권을 모방, 도용하여서는 안 되며 당사의
                  가이드를 준수하여야 합니다. (별도 문의)
                </li>
                <li>
                  이용자는 다음과 같은 내용을 포함하는 리얼월드 채널과 경험을 제작할 수 없으며 이를
                  어길 경우 회사는 통보 없이 리얼월드 채널을 삭제하고 계약을 파기할 수 있습니다.
                  <ul>
                    <li>정보통신망법 상 불법적인 내용(법 제44조의7 제1항의 각호의 내용)</li>
                    <li>
                      청소년보호법 상 청소년유해매체물 혹은 방송통신심의위원회의 인터넷 내용등급기준
                      2등급 이상의 선정적, 폭력적, 혐오적, 반사회적, 비도덕적, 불법적인 내용
                    </li>
                    <li>회사 혹은 제3자의 저작권 등 지식재산권을 침해하는 내용</li>
                    <li>회사 혹은 제3자의 명예를 훼손하거나 제3자를 모욕하는 내용</li>
                    <li>타인의 초상권, 성명권 등 인격권을 침해하는 내용</li>
                    <li>기타 범죄를 교사하거나 현행법에 위반되는 내용</li>
                  </ul>
                </li>
                <li>
                  이용자는 경험을 체험하는 사람 수의 허용량을 늘이거나, 허용 저장공간을 늘이는 등
                  리얼월드 채널의 제한 사항을 초과 사용하려는 목적으로 동일하거나 유사한 경험을 여러
                  채널에 만들어서는 안됩니다
                </li>
                <li>
                  이용자는 본 서비스를 이용함에 있어서 악성코드 등 유해한 프로그램을 배포해서는
                  안됩니다.
                </li>
                <li>
                  이용자는 본 약관에서 규정하는 사항과 기타 회사가 정하여 공지한 제반 사항 및 관련
                  법령을 준수하여야 하며, 기타 회사의 업무에 방해가 되는 행위 및 회사의 명예를
                  손상시키는 행위를 해서는 안됩니다.
                </li>
              </ul>
            </li>
            <li>
              이용자는 자신의 경험을 체험하는 고객 (이하 &#39;체험고객&#39;)의 개인정보를 다음과
              같이 안전하게 관리 및 보호해야 합니다.
              <ul>
                <li>
                  이용자는 체험고객의 개인정보를 보호하여야 하며, 경험을 제공함에 있어 정보통신망
                  이용촉진 및 정보보호 등에 관한 법률 및 동법 시행령/시행규칙, 개인정보의 기술적
                  관리적 보호조치 기준 고시(방송통신위원회 고시), 개인정보보호법 및 동법
                  시행령/시행규칙, 개인정보의 안전성 확보조치 기준, 기타 관련 법령에 명시된 바에
                  따라 체험고객의 개인정보를 보호 및 관리하는 등 관계 법령을 준수하여야 합니다.
                </li>
                <li>
                  이용자는 동의 받은 이용목적 범위 내에서만 체험고객의 개인정보를 이용하고,
                  훼손·변조·침해하거나 제3자에게 개인정보를 임의로 제공하거나 공개하지 않으며, 이를
                  위해 개인정보를 취급하는 인력에게 필요한 교육을 시켜야 하며, 개인정보에 접근할 수
                  있는 인력을 최소한으로 유지해야 합니다.
                </li>
                <li>
                  이용자는 개인정보의 안전성 확보조치 기준 고시 및 개인정보의 기술적, 관리적
                  보호조치 기준 고시에서 암호화를 요구하는 개인정보에 대해 암호화하여 보관하여야
                  합니다.
                </li>
                <li>
                  이용자는 이용고객의 개인정보의 제공·이용목적이 달성된 경우 지체 없이 파기하여야
                  합니다.
                </li>
                <li>
                  이용자는 제6조 3항의 의무를 위반하여 발생한 모든 법적 책임을 부담하며, 그와
                  관련하여 회사의 귀책사유가 없었음에도 불구하고 회사를 상대로 법적 클레임이 발생할
                  경우, 자신의 비용과 노력으로 회사를 면책하고 방어해야 합니다.
                </li>
              </ul>
            </li>
          </ol>
          <Typography as="h2" id="-7-" type="subheading">
            제7조 지식재산권의 귀속
          </Typography>
          <ol>
            <li>
              회사가 제공하는 본 서비스 및 관련하여 회사가 제작한 프로그램, 디자인, 상표 등의
              지식재산권 등 제반 권리는 회사에 귀속됩니다.
            </li>
            <li>
              회사의 이용자의 본 서비스 이용에 대한 승낙은 본 서비스에 대한 본 약관에 따른 제한된
              사용권의 부여만을 의미할 뿐, 어떠한 경우에도 (그와 같은 제한된 사용권의 부여를 넘어선)
              이용자의 본 서비스와 관련된 지식재산권 혹은 관련 권리의 취득을 의미하지는 않습니다.
            </li>
          </ol>
          <Typography as="h2" id="-8-" type="subheading">
            제8조 서비스의 변경, 중단 및 제한
          </Typography>
          <ol>
            <li>회사는 회사의 정책 및 운영의 필요상 본 서비스를 변경할 수 있습니다.</li>
            <li>
              본 서비스는 기본적으로 연중무휴, 1일 24시간 운영을 원칙으로 합니다. 다만, 회사는
              다음과 같은 경우 본 서비스의 전부 혹은 일부를 중단 혹은 이용제한 할 수 있습니다.
              <ul>
                <li>국가비상사태, 정전 및 그에 준하는 상황이 발생한 경우</li>
                <li>
                  시스템 정기점건 또는 증설 및 교체, 새로운 서비스의 적용 등을 위해 필요한 경우
                </li>
                <li>본 서비스와 리얼월드 서비스 이용의 폭주 등으로 시스템에 장애가 발생한 경우</li>
                <li>
                  기타 회사의 업무상 혹은 기술상 본 서비스를 유지하기 어려운 상황이 발생한 경우
                </li>
              </ul>
            </li>
            <li>
              위 제8조 1항 및 제8조 2항에 따라서 본 서비스를 변경하거나 중단 혹은 제한할 경우 회사는
              그에 대해서 리얼월드 스튜디오를 통해 공지합니다. 본 서비스에 등록된 연락처가
              부정확하거나 사용자의 장기 부재로 인해 공지를 받지 못해 발생하는 손실에 대한 책임은
              회사가 지지 않습니다.
            </li>
            <li>
              회사는 이용자가 본 약관에서 정한 이용자의 의무를 위반한 것을 확인한 경우 본 서비스의
              제공을 중단하거나 생성한 리얼월드 채채널의 사용 제한, 정지 혹은 삭제 등 필요한 조치를
              취할 수 있으며, 필요한 경우 이용계약을 해지할 수도 있습니다.
            </li>
          </ol>
          <Typography as="h2" id="-9-" type="subheading">
            제9조 회사의 책임의 제한
          </Typography>
          <ol>
            <li>
              회사는 본 서비스를 무료로 이용하는 경우에 대하여 본 서비스의 신뢰성, 지속성, 정확성
              등을 보증하지는 않으며, 본 서비스의 사용 및 사용불능으로 인하여 이용자에게 발생한
              손해에 대해서도 책임을 지지 않습니다.
            </li>
            <li>
              회사는 본 서비스를 무료로 이용하여 기대하는 수익을 얻지 못하거나 상실한 것에 대하여
              책임을 지지 않습니다.
            </li>
            <li>
              회사는 이용자 상호 간 및 이용자와 제3자 간에 본 서비스를 매개로 발생한 분쟁에 대하여
              개입할 의무가 없으며, 해당 분쟁에서의 손해에 대한 배상 책임 또한 지지 않습니다. 만약
              이용자와 분쟁중인 제3자가 회사를 상대로 이의를 제기할 경우 이용자는 자신의 비용과
              책임으로 문제를 직접 해결하고 회사를 면책시켜야 하며 회사에 발생한 손해를 배상하여야
              합니다.
            </li>
          </ol>
          <Typography as="h2" id="-10-" type="subheading">
            제10조 개인정보의 보호, 보관 및 활용 등
          </Typography>
          <ol>
            <li>
              회사는 본 서비스를 운영함에 있어 이용자에 대한 각종 고지사항의 전달, 이용실태에 대한
              조사 등을 위하여 이용자의 연락처 등 개인정보를 보관합니다.
            </li>
            <li>
              회사는 개인정보 수집 및 이용 동의에 따라 이용자로부터 수집한 정보만을 보관하며, 회사의
              개인정보 처리방침(
              <Button url="https://realworld.to/policy.html">
                https://realworld.to/policy.html
              </Button>
              )에 따라 이를 철저히 관리합니다.
            </li>
            <li>
              회사는 본 서비스의 제공을 종국적으로 중단하거나 이용자가 개인정보 수집 및 이용동의를
              철회한 경우에는 지체 없이 이용자의 개인정보를 파기합니다.
            </li>
            <li>회사는 기타 개인정보보호 법령에 정한 내용을 준수합니다.</li>
          </ol>
          <Typography as="h2" id="-11-" type="subheading">
            제11조 이용계약의 해지
          </Typography>
          <ol>
            <li>
              회사는 이용자가 약관을 준수하지 않는 경우 계약을 해지하고 이용자의 본 서비스 이용을
              중단시킬 수 있습니다.
            </li>
            <li>
              회사는 3개월 이상 본 서비스를 이용한 기록이 없을 경우 본 서비스 이용 계약을 해지하고
              본 서비스의 제공을 중단할 수 있습니다. 이 경우 회사는 이용계약 해지에 앞서 등록한
              이메일 혹은 전화번호로 연락을 취하며, 최초 메일 발송일 혹은 최초 통화 시도일로부터
              14일 내에 이용자의 응답이 없는 경우에는 이용계약은 해지됩니다.
            </li>
          </ol>
          <Typography as="h2" id="-12-" type="subheading">
            제12조 통지의 방법
          </Typography>
          <ol>
            <li>
              회사는 이용자에게 개별적으로 통지를 해야 하는 경우, 이용자가 등록한 이메일 혹은
              휴대전화로 통지합니다.
            </li>
            <li>
              이용자는 회사의 통지를 수신할 수 있도록 이메일 및 휴대전화번호 등 회사에 제공한 자신의
              정보를 항상 최신으로 유지하여야 하며, 이를 이행하지 않아 이용자에게 발생한 불이익에
              대해서 회사는 책임을 부담하지 않습니다.
            </li>
          </ol>
          <Typography as="h2" id="-13-" type="subheading">
            제13조 양도의 금지
          </Typography>
          <p>
            이용자는 본 약관에 의해 체결된 계약 상 권리 및 의무 등 계약 상 지위를 회사의 동의 없이는
            제3자에게 양도할 수 없습니다.
          </p>
          <Typography as="h2" id="-14-" type="subheading">
            제14조 준거법 및 관할법원
          </Typography>
          <p>
            본 약관의 준거법은 대한민국의 법으로 하며, 본 서비스 이용으로 회사와 이용자 간에 발생한
            분쟁에 대해 소송이 제기되는 경우 민사소송법에 따른 법원이 관할법원이 됩니다.
          </p>
          <Typography as="h2" id="-15-" type="subheading">
            제15조 별도 계약 조건
          </Typography>
          <p>
            본 약관 이외에 회사와 이용자가 별도로 맺은 계약이 있고, 본 계약과 배치되는 부분이 있을
            경우 별도로 맺은 계약이 우선하여 적용됩니다.
          </p>
          <Typography as="h2" id="-" type="subheading">
            부칙
          </Typography>
          <p>&lt;부칙&gt; (시행일) 본 약관은 2019년 11월 18일부터 적용됩니다.</p>
        </Typography>
        <div className="btn-agree-wrap">
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            loading={formState.isSubmitting}
            onClick={() => {
              track.onClick({ clickEventName: ClickEventName.click_agreetermpage_agree });
            }}
          >
            이용약관에 동의합니다
          </Button>
        </div>
      </form>
    </Container650>
  );
}
