import { css } from '@emotion/react';
import {
  color,
  Icon,
  iconSize,
  spacing,
  Tooltip,
  Typography
} from '@uniquegood/realworld-studio-design';

export default function LabelGroup() {
  return (
    <ul css={WrapperStyle}>
      <li>
        <Label color={color.palette_primary_purple_100} />
        <Typography as="span" type="body">
          누적 플레이 인원
        </Typography>
        <Tooltip
          title="누적 플레이 인원"
          content={
            <span>
              리얼월드 앱에서 해당 게임의 ‘시작 처리’ 리액션
              <br />이 삽입된 퀘스트를 수행한 전체 인원
            </span>
          }
        >
          {questionIcon}
        </Tooltip>
      </li>
      <li>
        <Label color={color.palette_gray_shade_03} />
        <Typography as="span" type="body">
          누적 완료 인원
        </Typography>
        <Tooltip
          title="누적 완료 인원"
          content={
            <span>
              리얼월드 앱에서 해당 게임의 ‘종료 처리’ 리액션
              <br />이 삽입된 퀘스트를 수행한 전체 인원
            </span>
          }
        >
          {questionIcon}
        </Tooltip>
      </li>
      <li>
        <Label color={color.palette_secondary_yellow_100} />
        <Typography as="span" type="body">
          새 플레이 인원
        </Typography>
        <Tooltip title="새 플레이 인원" content="당일 0시부터 매시간 집계한 새 플레이 인원">
          {questionIcon}
        </Tooltip>
      </li>
      <li>
        <Label color={color.palette_secondary_blue_tint_05} />
        <Typography as="span" type="body">
          새 완료 인원
        </Typography>
        <Tooltip title="새 완료 인원" content="당일 0시부터 매시간 집계한 새 완료 인원">
          {questionIcon}
        </Tooltip>
      </li>
    </ul>
  );
}

const TypographyStyle = css`
  margin-left: 8px;
  margin-right: 2px;
`;

const WrapperStyle = css`
  list-style: none;
  display: grid;
  padding: 0;
  margin: 0;

  justify-content: start;

  grid-template-columns: repeat(4, auto);

  gap: ${spacing.margin.xlarge2};

  li {
    display: flex;
    align-items: center;

    > span {
      ${TypographyStyle}
    }
  }

  span[data-studio-tooltip-activator='true'] {
    margin-left: 3px;
  }
`;

interface LabelProps {
  color: string;
}

function Label({ color }: LabelProps) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill={color}>
      <rect width="20" height="20" rx="10" />
    </svg>
  );
}

const questionIcon = (
  <Icon icon="question_circle_solid" color={color.icon_disabled} size={iconSize.small} />
);
